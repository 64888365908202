<script setup lang="ts">
import { ref } from 'vue';

import type { InviteUser } from '@/client/api';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { BadRequestError } from '@/composables/ApiError';

import Icon from '@/components/atoms/Icon.vue';
import AddOrgForm from '@/components/molecules/Forms/AddOrgForm.vue';

const emit = defineEmits(['closeDialog', 'updateList']);

const isLoading = ref(false);
const formValid = ref(false);
const userForm = ref<InviteUser | null>(null);
const organizationStore = useOrganizationStore();

function validate(value: boolean, user: InviteUser) {
  formValid.value = value;
  userForm.value = user;
}

async function addUser() {
  if (!userForm.value) return;

  try {
    isLoading.value = true;
    const user = await organizationStore.addOrganizationUser(userForm.value);

    successMessage(`'${user?.firstname} ${user?.lastname}' was successfully invited`);

    emit('updateList');
    emit('closeDialog');
  } catch (error) {
    if (error instanceof BadRequestError) {
      errorMessage(error.message);
      return;
    }
    captureException(error, {
      message: 'Component: DAddOrgUser, Function: addUser',
      data: {
        userForm: userForm.value,
      },
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person_add" icon-folder="settings" class="q-mr-md" />
      <h5 class="q-ma-none">New User</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="row form__container">
      <AddOrgForm @validate="validate" />
    </div>
    <div class="row action__row full-width justify-end q-mt-lg">
      <q-btn
        class="btn-add"
        label="Invite User"
        :loading="isLoading"
        :disable="!formValid || isLoading"
        unelevated
        @click="addUser"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .btn-add {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
