import initApi from '@/client_heplers/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  PageDocumentOverview,
  PageVendorDetails,
  V1ApiCreateVendorApiV1VendorsPostRequest,
  V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest,
  V1ApiDeleteVendorApiV1VendorsVendorIdDeleteRequest,
  V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest,
  V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest,
  V1ApiGetVendorApiV1VendorsVendorIdGetRequest,
  V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest,
  V1ApiListVendorsApiV1VendorsGetRequest,
  V1ApiUpdateVendorApiV1VendorsVendorIdPutRequest,
  V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest,
  VendorDetails,
} from '@/client/api';
import { V1Api } from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { RequestConfig } from '@/interfaces/models/Request';
import type { AxiosError } from 'axios';

export const useVendorStore = defineStore('vendor', () => {
  const vendorsData = ref<PageVendorDetails | null>(null);
  const currentVendor = ref<VendorDetails | null>(null);
  const vendorDocumentsData = ref<PageDocumentOverview | null>(null);

  const getVendors = async (
    params: V1ApiListVendorsApiV1VendorsGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.listVendorsApiV1VendorsGet(params, options);

      if (!response) return;
      vendorsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getVendor = async (params: V1ApiGetVendorApiV1VendorsVendorIdGetRequest) => {
    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.getVendorApiV1VendorsVendorIdGet(params);

      if (!response) return;
      currentVendor.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createVendor = async (params: V1ApiCreateVendorApiV1VendorsPostRequest) => {
    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.createVendorApiV1VendorsPost(params);
      if (!response) return;
      currentVendor.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateVendor = async (params: V1ApiUpdateVendorApiV1VendorsVendorIdPutRequest) => {
    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.updateVendorApiV1VendorsVendorIdPut(params);

      if (!response) return;
      currentVendor.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteVendor = async (params: V1ApiDeleteVendorApiV1VendorsVendorIdDeleteRequest) => {
    try {
      const v1Api = await initApi(V1Api);

      await v1Api?.deleteVendorApiV1VendorsVendorIdDelete(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getVendorDocumentsList = async (
    params: V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(
        params,
        options,
      );

      if (!response) return;

      vendorDocumentsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const uploadVendorDocument = async (
    params: V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest,
  ) => {
    const formData = new FormData();
    formData.append('file', params.file);

    const config = {
      accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };

    try {
      const v1Api = await initApi(V1Api);

      const response = await v1Api?.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(
        params,
        {
          headers: config,
        },
      );

      if (!response) return null;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const downloadVendorDocument = async (
    params: V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest,
    fileName: string,
  ) => {
    try {
      const v1Api = await initApi(V1Api);
      const response =
        await v1Api?.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(
          params,
          {
            responseType: 'blob',
          },
        );

      if (!response) {
        return;
      }

      const blob = response.data as unknown as Blob;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getVendorUrl = async (
    params: V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest,
  ) => {
    try {
      const v1Api = await initApi(V1Api);
      const response =
        await v1Api?.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(params);

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteVendorDocument = async (
    params: V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest,
  ) => {
    try {
      const v1Api = await initApi(V1Api);

      await v1Api?.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getVendors,
    vendorsData,
    getVendor,
    currentVendor,
    createVendor,
    updateVendor,
    deleteVendor,
    uploadVendorDocument,
    downloadVendorDocument,
    getVendorUrl,
    getVendorDocumentsList,
    vendorDocumentsData,
    deleteVendorDocument,
  };
});
