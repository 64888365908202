<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Icon from '@/components/atoms/Icon.vue';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { DEFAULT_PAGINATION, SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { captureException } from '@/composables/Sentry';

defineProps({
  iconName: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});

const route = useRoute();
const paginationStore = usePaginationStore();
const projectsStore = useProjectsStore();

async function getAll() {
  if (!projectsStore.project) return;

  paginationStore.setFrameworkQuery(null);
  paginationStore.setStatusFilter(null);
  paginationStore.setNameFilter('');
  paginationStore.setTagFilter(null);
  paginationStore.setAssignedToMe(false);
  paginationStore.setAssignedForReview(false);
  try {
    switch (route.name) {
      case 'project-requirements':
        await projectsStore.getProjectRequirements({
          projectId: projectsStore.project.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
        });
        break;
      case 'project-controls':
        await projectsStore.getProjectControls({
          projectId: projectsStore.project?.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
        });
        break;
    }
  } catch (error) {
    captureException(error, { message: 'Component: AHeader, Function: getAll' });
  }
}

const emptyFilters = computed(() => {
  return (
    !paginationStore.nameFilter &&
    !paginationStore.tagFilter &&
    !paginationStore.statusFilter &&
    !paginationStore.assignedToMe &&
    !paginationStore.assignedToMeForReview &&
    !paginationStore.frameworkQuery
  );
});
</script>

<template>
  <div
    class="header__container row items-center q-px-md q-py-sm"
    :class="[!emptyFilters ? '' : 'active-header', projectsStore.conceptsLoading ? 'disable' : '']"
    @click="!projectsStore.conceptsLoading ? getAll() : null"
  >
    <Icon v-if="!emptyFilters" :icon-name="iconName" icon-folder="content" />
    <Icon v-else :icon-name="`${iconName}-white`" icon-folder="content" />
    <span class="q-ml-md">{{ text }}</span>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.header__container {
  cursor: pointer;
  span {
    @include paragraph-02(400, $secondary-600);
  }
}
.active-header {
  background: $secondary-500;
  span {
    color: $white;
  }
}

.active-header:hover {
  background: $secondary-300 !important;
}
.header__container:hover {
  background: $secondary-100;
}

.disable {
  opacity: 0.7;
}
</style>
