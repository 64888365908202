<script lang="ts" setup>
import { onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { NotificationStatus } from '@/client';

import { useNotificationStore } from '@/stores/NotificationStore';
import { usePaginationStore } from '@/stores/PaginationStore';

import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import ONotifications from '@/components/organisms/ONotifications.vue';

import { captureException } from '@/composables/Sentry';

const route = useRoute();
const router = useRouter();
const paginationStore = usePaginationStore();
const notificationStore = useNotificationStore();

function redirectBack() {
  router.go(-1);
}

onMounted(async () => {
  const params = {
    notificationStatus: route.query.status ? (route.query.status as NotificationStatus) : null,
  };

  try {
    await notificationStore.getNotifications(params);
  } catch (error) {
    captureException(error, {
      message: 'Component: NotificationsPage, Hook: onMounted, Method: getNotifications',
    });
  }
});

onBeforeUnmount(() => {
  paginationStore.setNameFilter('');
  paginationStore.setPage(null);
  paginationStore.setTagFilter(null);
  paginationStore.setStatusFilter(null);
  paginationStore.setFrameworkQuery(null);
  paginationStore.setAssignedToMe(false);
});
</script>

<template>
  <div class="content-wrapper">
    <div class="row items-center q-mb-md">
      <Icon
        icon-name="arrow-back"
        icon-folder="notifications"
        class="q-mr-md"
        @click="redirectBack"
      />
      <PageTitle title="Notifications" />
    </div>
    <ONotifications
      v-if="notificationStore.notificationsData"
      :data="notificationStore.notificationsData"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.content-wrapper {
  :deep(svg) {
    cursor: pointer;
  }
}

:deep(.title__container) {
  margin-bottom: 0 !important;
}

.btn__container {
  a {
    min-height: 41.6px;
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
}

:deep(button) {
  i {
    color: $primary-500;
  }
}

.search-filter__container {
  background: $white;
  margin: 0 0 16px 16px;
  padding: 16px;
  border-radius: 4px;
}

.pagination__wrapp {
  background: $white;
  margin: 16px 0 0 16px;
  padding: 16px;
  border-radius: 4px;
  span {
    @include paragraph-01(400, $common-2);
  }
}
</style>
