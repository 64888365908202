/* eslint-disable vue/one-component-per-file */
import { Node, type NodeConfig } from '@tiptap/core';
import { captureException } from '@/composables/Sentry';

import { DataHandler } from '@/components/organisms/TipTap/extensions/dataHandler';
const dataHandler = new DataHandler();

import { createApp, h } from 'vue';
import InfoBlock from '../components/InfoBlock/InfoBlock.vue';
import Icon from '@/components/atoms/Icon.vue';

import { Concept } from '@/client/api';

const createInfoBlock = (
  type: Concept,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  position: DOMRect,
  label: string,
  onClose: () => void,
  onEnter: () => void,
) => {
  const infoBlockElement = document.createElement('div');
  document.body.appendChild(infoBlockElement);

  const app = createApp({
    render() {
      return h(InfoBlock, {
        type,
        data,
        position: { top: position.bottom + window.scrollY, left: position.left + window.scrollX },
        label,
        onmouseleave: onClose,
        onmouseenter: onEnter,
      });
    },
  });

  app.mount(infoBlockElement);

  return {
    app,
    element: infoBlockElement,
  };
};

export const LinkChip = Node.create<NodeConfig>({
  name: 'linkChip',

  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  priority: 1001,

  addAttributes() {
    return {
      type: {
        default: null,
      },
      projectId: {
        default: null,
      },
      conceptId: {
        default: null,
      },
      label: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a[data-badge]',
        getAttrs: (dom) => {
          const element = dom as HTMLElement;
          return {
            type: element.getAttribute('data-concept'),
            projectId: element.getAttribute('data-project-id'),
            conceptId: element.getAttribute('data-concept-id'),
            label: element.getAttribute('data-label'),
          };
        },
      },
    ];
  },

  renderText({ node }) {
    const { type, projectId, conceptId } = node.attrs;
    const url = dataHandler.getDataUrl(type, projectId, conceptId);

    return `${url}`;
  },

  renderHTML({ node, HTMLAttributes }) {
    const { type, projectId, conceptId, label } = node.attrs;
    const url = dataHandler.getDataUrl(type, projectId, conceptId);

    return [
      'a',
      {
        ...HTMLAttributes,
        'data-badge': '',
        'data-concept': type,
        'data-project-id': projectId,
        'data-concept-id': conceptId,
        'data-label': label,
        href: url || '#',
        target: '_blank',
      },
      label,
    ];
  },

  addNodeView() {
    return ({ node, HTMLAttributes }) => {
      const type = node.attrs.type;
      const projectId = node.attrs.projectId;
      const conceptId = node.attrs.conceptId;
      const label = node.attrs.label;
      const url = dataHandler.getDataUrl(type, projectId, conceptId);

      const dom = document.createElement('a');
      dom.setAttribute('data-badge', '');
      dom.setAttribute('data-concept', type);
      dom.setAttribute('data-project-id', projectId);
      dom.setAttribute('data-concept-id', conceptId);
      dom.setAttribute('data-label', label);
      dom.setAttribute('href', url || '#');
      dom.setAttribute('target', '_blank');

      const svgContainer = document.createElement('div');

      const app = createApp(Icon, { iconName: `${type}-badge`, iconFolder: 'editor' });
      app.mount(svgContainer);

      dom.appendChild(svgContainer.firstChild || svgContainer);

      dom.appendChild(document.createTextNode(label));

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        dom.setAttribute(key, value as string);
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let infoBlock: any;
      let isMouseInsideInfoBlock = false;

      const removeInfoBlock = () => {
        if (infoBlock) {
          infoBlock.app.unmount();
          document.body.removeChild(infoBlock.element);
          infoBlock = null;
        }
      };

      let isRequestInProgress = false;

      const handleMouseOver = async () => {
        if (isRequestInProgress) {
          return;
        }

        isRequestInProgress = true;

        try {
          if (infoBlock) {
            removeInfoBlock();
          }

          const data = await dataHandler.getData(type, conceptId, projectId);

          const position = dom.getBoundingClientRect();
          infoBlock = createInfoBlock(
            type,
            data,
            position,
            label,
            () => {
              isMouseInsideInfoBlock = false;
              removeInfoBlock();
            },
            () => {
              isMouseInsideInfoBlock = true;
            },
          );
        } catch (error) {
          captureException(error, {
            message: 'File: linkChip, request: getData',
          });
        } finally {
          isRequestInProgress = false;
        }
      };

      const handleMouseOut = (event: MouseEvent) => {
        if (infoBlock) {
          const relatedTarget = event.relatedTarget as HTMLElement;
          if (!infoBlock.element.contains(relatedTarget) && relatedTarget !== dom) {
            if (!isMouseInsideInfoBlock) {
              removeInfoBlock();
            }
          }
        }
      };

      dom.addEventListener('mouseover', handleMouseOver);
      dom.addEventListener('mouseout', handleMouseOut);

      return {
        dom,
        destroy() {
          dom.removeEventListener('mouseover', handleMouseOver);
          dom.removeEventListener('mouseout', handleMouseOut);
          removeInfoBlock();
        },
      };
    };
  },
});
