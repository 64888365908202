<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { useAuthStore } from '@/stores/AuthStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { UpperCaseRoles } from '@/composables/Auth';
import { AiProjectCreationAllowed, OrgProjectCreationAllowed } from '@/composables/Config';
import { captureException } from '@/composables/Sentry';

import { ProjectType } from '@/client';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DProjectsLimit from '@/components/dialogs/DProjectsLimit.vue';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

const authStore = useAuthStore();
const projectsStore = useProjectsStore();

const activeTab = ref<ProjectType>(ProjectType.AiApplication);
const isLoading = ref(false);
const filter = ref<string | null>(null);
const showLimitDialog = ref(false);
const projectsTotal = ref(0);
const columnsNames = {
  name: 'Name',
  frameworks: 'Framework',
  project_settings: 'EU AI Act Risk Classification',
  ai_lifecycle_stage: 'AI Lifecycle Stage',
  modified_at: 'Modified',
  created_by: UpperCaseRoles.Owner,
  progress_percent: 'Progress',
};

async function changeTab(value: ProjectType) {
  activeTab.value = value;
  isLoading.value = true;

  const pagination = {
    ...DEFAULT_PAGINATION,
    projectType: value,
  };
  try {
    await projectsStore.getProjects(pagination);
  } catch (error) {
    captureException(error, {
      message: 'Component: ProjectsPage, Function: changeTab',
      data: {
        pagination: pagination,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  try {
    await projectsStore.getProjects({
      ...DEFAULT_PAGINATION,
      name: filter.value !== '' ? filter.value : null,
      projectType: activeTab.value,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: ProjectsPage, Function: filterBySearch',
    });
  }
}

function closeDialog() {
  showLimitDialog.value = false;
}

watch(
  () => projectsStore.projectsData,
  (newV) => {
    if (!newV || newV.total === null) return;

    projectsTotal.value = newV.total;
  },
);

onMounted(async () => {
  isLoading.value = true;

  const pagination = {
    ...DEFAULT_PAGINATION,
    projectType: ProjectType.AiApplication,
  };
  try {
    await projectsStore.getProjects(pagination);
  } catch (error) {
    captureException(error, {
      message: 'Component: ProjectsPage, Hook: onMounted, Method: getProjects',
      data: {
        pagination: pagination,
      },
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div class="content-wrapper">
    <div class="row items-center">
      <PageTitle title="Projects" class="margin-left" />
    </div>
    <OTabPanels
      :active-tab="activeTab"
      :default-size="true"
      :default-tab="ProjectType.AiApplication"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab content-class="my-tab" :name="ProjectType.AiApplication" label="AI Applications">
        </q-tab>
        <q-tab content-class="my-tab" name="organization" label="Organizational Projects"> </q-tab>
      </template>
      <template #panels>
        <q-tab-panel :name="ProjectType.AiApplication">
          <span class="info-text row q-mb-md"
            >Overview of your Organizations AI Applications. AI Applications represent individual
            use cases.</span
          >
          <OTable
            v-if="projectsStore.tableView"
            row-route="/projects/project-detail/"
            :data="projectsStore.projectsData?.items"
            :pagination="projectsStore.projectsData!"
            :columns-names="columnsNames"
            :projects-type="activeTab"
            :is-loading="isLoading"
            :skeleton-size="5"
            parent-el="projectsPage"
            store="projectsStore"
            action="getProjects"
            @filter-by-search="filterBySearch"
          >
            <template #header-button>
              <q-btn
                v-if="AiProjectCreationAllowed() && !isLoading"
                icon="add"
                label="New Project"
                unelevated
                to="/projects/new-project?type=ai_application"
                :disable="isLoading"
              />
              <q-btn
                v-if="!AiProjectCreationAllowed() && !isLoading"
                icon="add"
                label="New Project"
                unelevated
                :disable="isLoading"
                @click="showLimitDialog = true"
              />
            </template>
          </OTable>
          <div
            v-if="
              projectsStore.projectsData &&
              projectsStore.projectsData.items.length === 0 &&
              !isLoading
            "
            class="empty-wrapp"
          >
            <AEmptyData
              v-if="!filter"
              icon-name="folder"
              header="Projects list is empty."
              text=""
              class="full-width items-center empty__content"
            />
            <AEmptyData
              v-else
              icon-name="search"
              :header="`No results for your search.`"
              text="Try changing your search."
              class="full-width items-center empty__content"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel name="organization">
          <span class="info-text row q-mb-md"
            >Overview of your Organizational Projects. These Projects cover policies and changes
            affecting your entire Organization.</span
          >
          <OTable
            v-if="projectsStore.tableView"
            row-route="/projects/project-detail/"
            :data="projectsStore.projectsData?.items"
            :pagination="projectsStore.projectsData!"
            :columns-names="columnsNames"
            :projects-type="activeTab"
            :is-loading="isLoading"
            :skeleton-size="5"
            parent-el="projectsPage"
            store="projectsStore"
            action="getProjects"
            @filter-by-search="filterBySearch"
          >
            <template #header-button>
              <q-btn
                v-if="OrgProjectCreationAllowed() && !isLoading"
                icon="add"
                label="New Organizational Project"
                unelevated
                :disable="!authStore.user?.is_org_admin || isLoading"
                to="/projects/new-project?type=organization"
              >
                <q-tooltip
                  v-if="!authStore.user?.is_org_admin"
                  anchor="top middle"
                  self="center middle"
                  max-width="200px"
                >
                  You can’t create Organizational Projects. Contact the admin.
                </q-tooltip>
              </q-btn>
              <q-btn
                v-if="!OrgProjectCreationAllowed() && !isLoading"
                icon="add"
                label="New Organizational Project"
                unelevated
                :disable="!authStore.user?.is_org_admin || isLoading"
                @click="showLimitDialog = true"
              >
                <q-tooltip
                  v-if="!authStore.user?.is_org_admin"
                  anchor="top middle"
                  self="center middle"
                  max-width="200px"
                >
                  You can’t create Organizational Projects. Contact the admin.
                </q-tooltip>
              </q-btn>
            </template>
          </OTable>
          <div
            v-if="
              projectsStore.projectsData &&
              projectsStore.projectsData.items.length === 0 &&
              !isLoading
            "
            class="empty-wrapp"
          >
            <AEmptyData
              v-if="!filter"
              icon-name="folder"
              header="Organizational Projects list is empty."
              text=""
              class="full-width items-center empty__content"
            />
            <AEmptyData
              v-else
              icon-name="search"
              :header="`No results for your search.`"
              text="Try changing your search."
              class="full-width items-center empty__content"
            />
          </div>
        </q-tab-panel>
      </template>
    </OTabPanels>
  </div>

  <ADialog
    :show-dialog="showLimitDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showLimitDialog = false"
  >
    <DProjectsLimit
      :projects-type="
        activeTab === ProjectType.AiApplication ? 'AI Applications' : 'Organizational'
      "
      @close-dialog="closeDialog"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.content-wrapper {
  margin-bottom: 95px;
  .margin-left {
    margin-left: 15px;
  }
  .info-text {
    @include paragraph-01(400, $secondary-500);
  }
  :deep(.panels__container) {
    margin-top: 0 !important;
  }
}
.btn__container {
  a {
    min-height: 41.6px;
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
}

.search-filter__container {
  background: $white;
  margin: 0 0 16px 16px;
  padding: 16px;
  border-radius: 4px;
}

.pagination__wrapp {
  background: $white;
  margin: 16px 0 0 16px;
  padding: 16px;
  border-radius: 4px;
  span {
    @include paragraph-01(400, $common-2);
  }
}
.creation__container {
  background: $white;
  padding: 20px;
  border-radius: 5px;
  a {
    min-height: 41.6px;
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
}
.empty-wrapp {
  height: calc(100vh - 366px);
  position: relative;
  background: $white;
  border-radius: 5px;
  .empty__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

:deep(.q-table__container) {
  padding: 20px;
  border-radius: 5px;
  .q-table__middle {
    overflow: auto !important;
  }
}
</style>
