import { useAuthStore } from '@/stores/AuthStore';
import { useConfigStore } from '@/stores/ConfigStore';
import { createRouter, createWebHistory } from 'vue-router';

import { isAdmin, useAuth } from '@/composables/Auth';

import AuthLayout from '@/layouts/AuthLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import OrganizationLayout from '@/layouts/OrganizationLayout.vue';
import ProjectLayout from '@/layouts/ProjectLayout.vue';

import DashboardPage from '@/pages/DashboardPage.vue';
import ErrorPage_v1 from '@/pages/ErrorPage_v1.vue';
import ErrorPage_v2 from '@/pages/ErrorPage_v2.vue';
import LoginPage from '@/pages/LoginPage.vue';
import MobileNotSupportedPage from '@/pages/MobileNotSupportedPage.vue';
import NewProjectPage from '@/pages/NewProjectPage.vue';
import NotificationsPage from '@/pages/NotificationsPage.vue';
import OAuth2RedirectPage from '@/pages/OAuth2RedirectPage.vue';
import OrganizationPage from '@/pages/OrganizationPage.vue';
import ProjectDetailPage from '@/pages/ProjectDetailPage.vue';
import ProjectsPage from '@/pages/ProjectsPage.vue';
import TokenPage from '@/pages/TokenPage.vue';
import AssetDetailPage from '@/pages/AssetDetailPage.vue';
import TestingDetailPage from '@/pages/TestingDetailPage.vue';
import ChatPage from '@/pages/ChatPage.vue';
import NoOrganizationPage from '@/pages/NoOrganizationPage.vue';
import VendorsPage from '@/pages/VendorsPage.vue';
import VendorDetailPage from '@/pages/VendorDetailPage.vue';
import FrameworkDetailPage from '@/pages/FrameworkDetailPage.vue';
import ProblemPage from '@/pages/ProblemPage.vue';

import Controls from '@/components/ProjectDetail/Controls.vue';
import Dashboard from '@/components/ProjectDetail/Dashboard.vue';
import Requirements from '@/components/ProjectDetail/Requirements.vue';
import Risks from '@/components/ProjectDetail/Risks.vue';
import Settings from '@/components/ProjectDetail/Settings.vue';
import Evidence from '@/components/ProjectDetail/Evidence.vue';
import Assets from '@/components/ProjectDetail/Assets.vue';
import Testing from '@/components/ProjectDetail/Testing.vue';
import RiskDetailPage from '@/pages/RiskDetailPage.vue';

import Taxonomy from '@/components/Organization/Taxonomy.vue';
import Templates from '@/components/Organization/Templates.vue';
import Users from '@/components/Organization/Users.vue';
import OrganizationSettings from '@/components/Organization/Settings.vue';

import VendorsOverview from '@/components/Vendors/VendorsOverview.vue';
import OAuth2SignupRedirectPage from '@/pages/OAuth2SignupRedirectPage.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { layout: DefaultLayout },
      component: DashboardPage,
    },
    {
      path: '/chat',
      name: 'chat',
      meta: { layout: DefaultLayout },
      component: ChatPage,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { layout: DefaultLayout },
      component: DashboardPage,
    },
    {
      path: '/projects',
      name: 'projects',
      meta: { layout: DefaultLayout },
      component: ProjectsPage,
    },
    {
      path: '/projects/project-detail/:id',
      name: 'project-detail',
      meta: { layout: ProjectLayout },
      component: ProjectDetailPage,
      children: [
        {
          path: '',
          name: 'project-dashboard',
          component: Dashboard,
        },
        {
          path: 'requirements',
          name: 'project-requirements',
          component: Requirements,
        },
        {
          path: 'controls',
          name: 'project-controls',
          component: Controls,
        },
        {
          path: 'settings',
          name: 'project-settings',
          component: Settings,
        },
        {
          path: 'risks',
          name: 'project-risks',
          component: Risks,
        },
        {
          path: 'risk-detail/:riskId',
          name: 'risk-detail',
          component: RiskDetailPage,
        },
        {
          path: 'evidence',
          name: 'project-evidence',
          component: Evidence,
        },

        {
          path: 'assets',
          name: 'project-assets',
          component: Assets,
        },
        {
          path: 'asset-detail/:assetId',
          name: 'asset-detail',
          component: AssetDetailPage,
        },
        {
          path: 'testing',
          name: 'project-testing',
          component: Testing,
        },
        {
          path: 'test-detail/:testId',
          name: 'test-detail',
          component: TestingDetailPage,
        },
      ],
      beforeEnter: (to, from) => {
        to.params.activeTab = from.name as string;
      },
    },
    {
      path: '/projects/new-project',
      name: 'new-project',
      meta: { layout: DefaultLayout },
      component: NewProjectPage,
    },
    {
      path: '/notifications',
      name: 'notifications',
      meta: { layout: DefaultLayout },
      component: NotificationsPage,
    },
    {
      path: '/organization',
      name: 'organization',
      meta: { layout: OrganizationLayout },
      component: OrganizationPage,
      children: [
        {
          path: 'users',
          name: 'organization-users',
          component: Users,
          beforeEnter: async () => {
            const authStore = useAuthStore();

            if (!authStore.user) {
              await authStore.getMe();
            }

            if (!isAdmin()) {
              return { name: 'organization-templates' };
            }
          },
        },
        {
          path: 'templates',
          name: 'organization-templates',
          component: Templates,
        },
        {
          path: 'template-detail/:templateCode',
          name: 'template-detail',
          component: FrameworkDetailPage,
        },
        {
          path: 'customization',
          name: 'organization-customization',
          component: Taxonomy,
        },
        {
          path: 'settings',
          name: 'organization-settings',
          component: OrganizationSettings,
          beforeEnter: async () => {
            const authStore = useAuthStore();

            if (!authStore.user) {
              await authStore.getMe();
            }

            if (!isAdmin()) {
              return { name: 'organization-templates' };
            }
          },
        },
      ],
    },
    {
      path: '/vendors',
      name: 'vendors',
      meta: { layout: DefaultLayout },
      component: VendorsPage,
      children: [
        {
          path: '',
          name: 'vendors-overview',
          component: VendorsOverview,
          beforeEnter: async () => {
            const authStore = useAuthStore();
            const configStore = useConfigStore();

            if (!authStore.user) {
              await authStore.getMe();
            }

            if (!configStore.configData) {
              await configStore.getOrganizationConfig();
            }

            if (!configStore.configData || !authStore.user) {
              return { name: 'home' };
            }

            if (!configStore.vendorsEnabled) {
              return { name: 'home' };
            }
          },
        },
        {
          path: 'vendor-detail/:vendorId',
          name: 'vendor-detail',
          component: VendorDetailPage,
          beforeEnter: async () => {
            const authStore = useAuthStore();
            const configStore = useConfigStore();

            if (!authStore.user) {
              await authStore.getMe();
            }

            if (!configStore.configData) {
              await configStore.getOrganizationConfig();
            }

            if (!configStore.configData || !authStore.user) {
              return { name: 'home' };
            }

            if (!configStore.vendorsEnabled) {
              return { name: 'home' };
            }
          },
        },
      ],
    },
    {
      path: '/oauth2-redirect',
      name: 'oauth2-redirect',
      meta: { layout: AuthLayout },
      component: OAuth2RedirectPage,
    },
    {
      path: '/oauth2-signup-redirect',
      name: 'oauth2-signup-redirect',
      meta: { layout: AuthLayout },
      component: OAuth2SignupRedirectPage,
    },
    {
      path: '/login',
      name: 'login-page',
      meta: {
        layout: AuthLayout,
      },
      component: LoginPage,
    },
    {
      path: '/mobile-not-supported',
      name: 'mobile-not-supported',
      meta: {
        layout: AuthLayout,
      },
      component: MobileNotSupportedPage,
    },
    {
      path: '/tokens',
      name: 'tokens',
      meta: {
        layout: DefaultLayout,
      },
      component: TokenPage,
    },
    {
      path: '/service_down',
      name: 'service_down',
      component: ErrorPage_v1,
    },
    {
      path: '/not_found',
      name: 'not-found',
      meta: { layout: ErrorLayout },
      component: ErrorPage_v2,
    },
    {
      path: '/problem',
      name: 'problem',
      component: ProblemPage,
    },
    {
      path: '/no_organization',
      name: 'no-organization',
      component: NoOrganizationPage,
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      meta: { layout: DefaultLayout },
      component: ErrorPage_v2,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthStore();
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  const user = localStorage.getItem('user');
  const parsedUser = user ? JSON.parse(user) : null;
  const publicPages = [
    'login-page',
    'oauth2-redirect',
    'oauth2-signup-redirect',
    'mobile-not-supported',
    'no-organization',
    'problem',
  ];

  const toName = to.name ? to.name.toString() : null;
  const isPublicPage = toName ? publicPages.includes(toName) : false;
  document.title = 'Modulos';

  const { logout } = useAuth();

  if (
    (!access_token && !isPublicPage && !refresh_token) ||
    (parsedUser && !parsedUser.terms_accepted && !isPublicPage)
  ) {
    if (parsedUser && !parsedUser.terms_accepted && !isPublicPage) {
      logout();
      return;
    }

    await store.getAccessTokenFromRefreshToken();

    if (!access_token && !isPublicPage && !refresh_token) {
      logout();
      return;
    }

    next();
  } else if (user && !('organization' in JSON.parse(user))) {
    if (toName !== 'no-organization') {
      next({ name: 'no-organization' });
      return;
    }
  }

  next();
});

export default router;
