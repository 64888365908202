<script lang="ts" setup>
import { watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import type { FrameworkDetailed, FrameworkControl, FrameworkRequirement } from '@/client/api';

import { useConfigStore } from '@/stores/ConfigStore';
import { useFrameworkStore } from '@/stores/FrameworkStore';

import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';
import ASelectSearch from '@/components/atoms/ASelectSearch.vue';
import AFrameworkIcon from '@/components/atoms/AFrameworkIcon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import OTableV2 from '@/components/organisms/OTableV2.vue';
import DOrgRequirement from '@/components/dialogs/DOrgRequirement.vue';
import DOrgControl from '@/components/dialogs/DOrgControl.vue';

interface Select {
  value: string;
  label: string;
}

interface SelectComponent {
  selectValue: Select | null;
}

const configStore = useConfigStore();
const frameworkStore = useFrameworkStore();
const router = useRouter();
const route = useRoute();
const currentFramework = ref<FrameworkDetailed | null>(null);
const activeTab = ref<string>('requirements');
const isLoading = ref(false);
const filter = ref('');
const tags = ref<{ [key: string]: string }[]>([]);
const tagOptions = ref<{ [key: string]: string }[]>([]);
const concepts = ref<string[]>([]);
const conceptOptions = ref<string[]>([]);
const selectedRequirement = ref<FrameworkRequirement | null>(null);
const selectedControl = ref<FrameworkControl | null>(null);
const showRequirementDialog = ref(false);
const showControlDialog = ref(false);
const controlSelect = ref<SelectComponent | null>(null);
const requirementSelect = ref<SelectComponent | null>(null);

const controls = ref<FrameworkControl[]>([]);
const requirements = ref<FrameworkRequirement[]>([]);

const controlsColumnsNames = {
  name: 'Name',
  description: 'Description',
  tags: 'Tags',
};
const requirementsColumnsNames = {
  name: 'Name',
  description: 'Description',
  controls: 'Controls',
};

async function changeTab(value: string) {
  activeTab.value = value;

  resetFilters();
}

function assignTagsConcepts() {
  if (!currentFramework.value) return;

  controls.value = currentFramework.value.controls;
  requirements.value = currentFramework.value.requirements;

  const controlTags = controls.value
    .map((control) => control.tags.map((tag) => JSON.stringify(tag)))
    .flat();

  tags.value = Array.from(new Set(controlTags)).map((tag) => JSON.parse(tag));
  tagOptions.value = tags.value;

  concepts.value = Array.from(
    new Set(requirements.value.map((requirement) => requirement.controls).flat()),
  );
  conceptOptions.value = concepts.value;
}

function filterBySearch(value: string) {
  filter.value = value ? value.toLowerCase() : '';
  if (activeTab.value === 'controls') {
    filterControls();
  } else {
    filteredRequirements();
  }
}

function filterControls() {
  if (!currentFramework.value) return;

  let filteredControls = currentFramework.value.controls;

  if (controlSelect.value && controlSelect.value.selectValue) {
    const needle = controlSelect.value.selectValue.value.toLowerCase();
    filteredControls = filteredControls.filter((control) =>
      control.tags.some((tag) => tag.value.toLowerCase().indexOf(needle) > -1),
    );
  }

  if (filter.value) {
    filteredControls = filteredControls.filter(
      (control) =>
        control.name.toLowerCase().includes(filter.value) ||
        control.code.toLowerCase().includes(filter.value),
    );
  }

  controls.value = filteredControls;
}

function filteredRequirements() {
  if (!currentFramework.value) return;

  let filteredRequirements = currentFramework.value.requirements;

  if (requirementSelect.value && requirementSelect.value.selectValue) {
    const needle = requirementSelect.value.selectValue.value.toLowerCase();
    filteredRequirements = filteredRequirements.filter((requirement) =>
      requirement.controls.some((control) => control.toLowerCase().indexOf(needle) > -1),
    );
  }

  if (filter.value) {
    filteredRequirements = filteredRequirements.filter(
      (requirement) =>
        requirement.name.toLowerCase().includes(filter.value) ||
        requirement.code.toLowerCase().includes(filter.value),
    );
  }

  requirements.value = filteredRequirements;
}

function resetFilters() {
  if (!currentFramework.value) return;

  controls.value = currentFramework.value.controls;
  requirements.value = currentFramework.value.requirements;
  filter.value = '';
}

function selectControl(control: FrameworkControl) {
  selectedControl.value = control;
  showControlDialog.value = true;
}

function selectRequirement(requirement: FrameworkRequirement) {
  selectedRequirement.value = requirement;
  showRequirementDialog.value = true;
}

function navigateToControl(code: string) {
  selectedControl.value = controls.value.find((control) => control.code === code) || null;
  showControlDialog.value = true;
}

function closeDialog() {
  showControlDialog.value = false;
  showRequirementDialog.value = false;
}

function redirectBack() {
  router.go(-1);
}

watch(
  () => configStore.configData,
  async (newV) => {
    if (!newV) return;

    try {
      isLoading.value = true;
      await frameworkStore.getFramework({
        frameworkCode: route.params.templateCode as string,
        organizationId: newV.organization_id,
      });

      currentFramework.value = frameworkStore.frameworkData;

      assignTagsConcepts();
    } catch (error) {
      router.push('/organization/templates');

      captureException(error, {
        message: 'Component: DFrameworkOverview, Hook: `onMounted',
      });
    } finally {
      isLoading.value = false;
    }
  },
  {
    immediate: true,
  },
);
</script>
<template>
  <div
    v-if="currentFramework"
    class="row title__container col-12 q-mt-lg q-mb-md items-center justify-between"
  >
    <div class="row items-center">
      <Icon
        icon-name="arrow-back"
        icon-folder="notifications"
        class="q-mr-md cursor-pointer"
        @click="redirectBack"
      />
      <AFrameworkIcon :framework="currentFramework" class="q-mr-md framework-icon__container" />
      <h5 class="q-ma-none">
        {{ currentFramework ? currentFramework.name : '' }}
      </h5>
    </div>
  </div>
  <div class="row sub-title__container q-mb-lg">
    {{ currentFramework ? currentFramework.description : '' }}
  </div>
  <OTabPanels
    :active-tab="activeTab"
    :default-size="true"
    default-tab="requirements"
    @change-tab="changeTab"
  >
    <template #tabs>
      <q-tab
        content-class="my-tab"
        name="requirements"
        label="Requirements"
        class="q-px-none q-mr-md"
      >
        <span v-if="currentFramework" class="total-badge q-ml-sm">
          {{ currentFramework.number_of_requirements }}</span
        >
      </q-tab>
      <q-tab content-class="my-tab" name="controls" label="Controls" class="q-px-none">
        <span v-if="currentFramework" class="total-badge q-ml-sm">
          {{ currentFramework.number_of_controls }}</span
        >
      </q-tab>
    </template>
    <template #panels>
      <q-tab-panel name="controls" class="q-px-none">
        <OTableV2
          :data="controls"
          :columns-names="controlsColumnsNames"
          :sortable="true"
          :is-loading="isLoading"
          :skeleton-size="5"
          concept="control"
          search-placeholder="Search for Controls"
          row-click-action="selectConcept"
          @select-concept="selectControl"
          @filter-by-search="filterBySearch"
        >
          <template #header-filters>
            <ASelectSearch
              ref="controlSelect"
              :options="
                tagOptions.map((item) => ({
                  value: item.value,
                  label: item.value,
                }))
              "
              label="Tag"
              class="q-ml-md"
              :loading="isLoading"
              @update-select="filterControls"
            />
          </template>
        </OTableV2>

        <div
          v-if="controls && controls.length === 0 && !isLoading"
          class="empty__wrapp row items-center"
        >
          <AEmptyData
            icon-name="folder"
            header="Controls list is empty."
            text=""
            class="full-width items-center empty__content"
          />
        </div>
      </q-tab-panel>
      <q-tab-panel name="requirements" class="q-px-none">
        <OTableV2
          :data="requirements"
          :columns-names="requirementsColumnsNames"
          :sortable="true"
          :row-clickable="false"
          :is-loading="isLoading"
          :skeleton-size="5"
          concept="requirement"
          search-placeholder="Search for Requirements"
          row-click-action="selectConcept"
          @select-concept="selectRequirement"
          @navigate-to-control="navigateToControl"
          @filter-by-search="filterBySearch"
        >
          <template #header-filters>
            <ASelectSearch
              ref="requirementSelect"
              :options="
                conceptOptions.map((item) => ({
                  value: item,
                  label: item,
                }))
              "
              label="Tag"
              class="q-ml-md"
              :loading="isLoading"
              @update-select="filteredRequirements"
            />
          </template>
        </OTableV2>
        <div
          v-if="requirements && requirements.length === 0 && !isLoading"
          class="empty__wrapp row items-center"
        >
          <AEmptyData
            icon-name="folder"
            header="Requirements list is empty."
            text=""
            class="full-width items-center empty__content"
          />
        </div>
      </q-tab-panel>
    </template>
  </OTabPanels>

  <ADialog :show-dialog="showRequirementDialog" @hide="closeDialog">
    <DOrgRequirement
      v-if="showRequirementDialog && selectedRequirement"
      :framework-requirement="selectedRequirement"
      @close-dialog="showRequirementDialog = false"
    />
  </ADialog>
  <ADialog :show-dialog="showControlDialog" @hide="closeDialog">
    <DOrgControl
      v-if="showControlDialog && selectedControl"
      :framework-control="selectedControl"
      @close-dialog="showControlDialog = false"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.title__container {
  .framework-icon__container {
    :deep(img) {
      width: 35px;
    }
  }
}

.sub-title__container {
  min-width: 100px;
  @include paragraph-02(500, $secondary-600);
}

span {
  @include heading-04(600, $secondary-600);
}

:deep(.q-tabs) {
  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: $secondary-500;
    @include caption(400, $white);
  }
}
</style>
