<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { useAuth } from '@/composables/Auth';
import { useRouter } from 'vue-router';

import { CURRENT_YEAR } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

const { logout } = useAuth();
const router = useRouter();

const interval = ref<number | ReturnType<typeof setInterval> | undefined>(undefined);

async function checkHealth() {
  const response = await fetch(`${import.meta.env.VITE_API_URL}/api/health`);
  if (response.status === 200) {
    if (interval.value !== null) {
      clearInterval(interval.value);
    }
    router.go(-1);
  }
}

onMounted(async () => {
  checkHealth();
  interval.value = setInterval(checkHealth, 5000);
});

onBeforeUnmount(() => {
  if (interval.value !== null) {
    clearInterval(interval.value);
  }
});
</script>

<template>
  <div class="error__wrapp row items-center">
    <div class="row full-width">
      <div class="logo__container row justify-center full-width">
        <Icon icon-name="logo" icon-folder="errors" class="q-mr-sm" />
        <Icon icon-name="logo-text" icon-folder="errors" class="modulos" />
      </div>
      <div class="column items-center justify-center full-width q-mb-md">
        <div class="row q-mb-md">
          <Icon icon-name="server-down" icon-folder="errors" />
        </div>
        <div class="row q-mb-md">
          <span class="error-text">Service is down.</span>
        </div>
        <div class="row q-mb-xs">
          <span class="error-info"
            >Service is unstable at the moment, wait some minutes or, if the problem persists,
            <a href="https://www.modulos.ai/contact/" target="_blank">contact us</a>.
          </span>
        </div>
        <div class="row q-mb-xs">
          <span class="error-info">
            You can also check the
            <a href="https://status.modulos.ai/" target="_blank">Status Page</a> for further
            information.</span
          >
        </div>
        <div class="row">
          <span class="error-info q-ml-xs underline" @click="logout"> Logout</span>
        </div>
        <div class="row socials">
          <a href="https://www.linkedin.com/company/modulos-ag/" target="_blank">
            <Icon icon-name="mdi_linkedin" icon-folder="errors" class="q-mr-md" />
          </a>
          <a href="https://twitter.com/modulos_ai" target="_blank">
            <Icon icon-name="ri_twitter-x-fill" icon-folder="errors" class="q-mr-md" />
          </a>
          <a href="https://www.youtube.com/channel/UCYHty7c5Nr-HJtAQDKiJ7nw" target="_blank">
            <Icon icon-name="mdi_youtube" icon-folder="errors" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer__container row items-center">
      <span class="footer q-mr-sm">{{ `© 2023-${CURRENT_YEAR} Modulos AG.` }}</span>
      <a href="https://status.modulos.ai/" target="_blank">Status</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.error__wrapp {
  width: 100%;
  height: 100vh;
  position: relative;
  background: $secondary-50;
  .logo__container {
    padding-bottom: 100px;
    .modulos {
      margin-top: -10px;
    }
  }
  .error-text {
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -2.4px;
    color: $secondary-700;
  }
  .error-info {
    max-width: 430px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-600;
    text-align: center;
    a {
      color: $secondary-600;
      text-decoration: none;
      font-weight: 600;
    }
  }
  .socials {
    margin-top: 55px;
  }

  .footer__container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    .footer {
      @include footer(400, $secondary-600);
      line-height: 16px;
    }
    a {
      @include caption(600, $secondary-600);
      text-decoration: none;
    }
  }

  .underline {
    cursor: pointer;
    color: $secondary-600;
    text-decoration: underline;
    font-weight: 600;
  }
}
</style>
