<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, watch } from 'vue';

import { Currency } from '@/client/api';

import { useConfigStore } from '@/stores/ConfigStore';

import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { successMessage, errorMessage } from '@/composables/Notify';

import OTabPanels from '@/components/organisms/OTabPanels.vue';

interface CurrencySelect {
  label: string;
  value: Currency;
}

const configStore = useConfigStore();
const activeTab = ref<string>('general');
const isLoading = ref(false);
const currency = ref<CurrencySelect | null>(null);
const enableVendors = ref(true);
const enableUsers = ref(true);

const currencyOptions = [
  {
    label: '$- United States Dollar (USD) ',
    value: Currency.Usd,
  },
  {
    label: '€ - Euro (EUR) ',
    value: Currency.Eur,
  },
];

async function changeTab(value: string) {
  isLoading.value = true;
  activeTab.value = value;
}

async function updateOrganizationConfig() {
  if (!currency.value) return;

  isLoading.value = true;

  const params = {
    general: {
      currency: currency.value.value,
    },
    flags: {
      user_management_enabled_for_entire_organization: enableUsers.value,
      vendor_management_enabled_for_entire_organization: enableVendors.value,
    },
  };

  try {
    await configStore.updateOrganizationConfig(params);
    successMessage('Organization currency updated successfully');
  } catch (error) {
    errorMessage('Failed to update organization currency');
    captureException(error, { message: 'Component: Settings, Hook: updateOrganizationConfig' });
  } finally {
    isLoading.value = false;
  }
}

watch(
  () => configStore.configData,
  (newV) => {
    if (!newV) return;

    currency.value =
      currencyOptions.find((item) => item.value === newV.general.currency) || currencyOptions[0];
    enableUsers.value = configStore.usersEnabled;
    enableVendors.value = configStore.vendorsEnabled;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <OTabPanels
    :active-tab="activeTab"
    :default-size="true"
    default-tab="general"
    @change-tab="changeTab"
  >
    <template #tabs>
      <q-tab content-class="my-tab" name="general" label="General" class="q-pa-none" />
    </template>
    <template #panels>
      <q-tab-panel v-if="configStore.configData" name="general" class="q-px-none">
        <span class="panel__description"
          >These settings will apply to your whole organization.</span
        >
        <div class="settings__wrapp row q-mt-md">
          <div class="row col-12 q-mb-lg">
            <span class="currency-title col-12">Organization Currency</span>
            <q-select
              v-model="currency"
              outlined
              :options="currencyOptions.map((item) => ({ value: item.value, label: item.label }))"
              label="Please select"
              dense
              :disable="isLoading || !isAdmin()"
              class="col-3 q-mt-sm"
              :class="[isLoading ? 'skeleton' : '']"
            />
          </div>
          <div class="row col-12 justify-end">
            <q-btn
              label="Save"
              type="submit"
              :loading="isLoading"
              :disable="!isAdmin() || isLoading"
              class="btn-save"
              unelevated
              @click="updateOrganizationConfig"
            >
              <q-tooltip v-if="!isAdmin()" anchor="top middle" self="bottom middle">
                You are not allowed to update the organization. Contact your admin.
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-tab-panel>
    </template>
  </OTabPanels>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.title__container {
  @include paragraph-02(500, $secondary-600);
}

.panel__description {
  @include paragraph-01(400, $secondary-600);
}

.settings__wrapp {
  position: relative;
  padding: 20px;
  background: $white;
  border-radius: 5px;

  .currency-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $secondary-500;
  }

  .btn-save {
    background: $secondary-500 !important;
    text-transform: none;

    span {
      color: $white;
    }
  }
}
</style>
