<script setup lang="ts">
import { onMounted, ref } from 'vue';

import type { OrganizationUser, Roles } from '@/client/api';
import type { PropType } from 'vue';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { roleDescription, UpperCaseRoles } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';

import ATooltip from '@/components/atoms/ATooltip.vue';
import Icon from '@/components/atoms/Icon.vue';

interface Select {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
}

const props = defineProps({
  existingUsers: {
    type: Array as PropType<OrganizationUser[]>,
    default: null,
  },
});

const emit = defineEmits(['closeDialog', 'addToExisting']);

const organizationStore = useOrganizationStore();
const user = ref<Select | null>(null);
const selected = ref([]);
const roles = ref([
  UpperCaseRoles.Owner,
  UpperCaseRoles.Auditor,
  UpperCaseRoles.Editor,
  UpperCaseRoles.Reviewer,
]);
const isLoading = ref(false);

async function addUser() {
  if (!user.value) return;

  isLoading.value = true;

  const newArr = convertFirstLetterToLowercase(selected.value);
  const selectedUser = user.value;

  for (let i = 0; i < newArr.length; i++) {
    selectedUser.value.roles?.push({
      role: newArr[i],
    });
  }

  emit('addToExisting', selectedUser.value);

  isLoading.value = false;
  emit('closeDialog');
}

function convertFirstLetterToLowercase(arr: Roles[]) {
  return arr.map((role: string) =>
    role
      .split(' ')
      .map((w) => w.charAt(0).toLowerCase() + w.slice(1))
      .join(' '),
  );
}

function usersOptions() {
  if (!organizationStore.usersData) return;

  const filteredUsers = organizationStore.usersData.items.filter((user) => {
    return !props.existingUsers.some((existingUser) => existingUser.id === user.id);
  });

  return filteredUsers.map((user) => ({
    label: user.firstname + ' ' + user.lastname,
    value: user,
  }));
}

async function fetchUsers() {
  if (!organizationStore.usersData) return;

  if (organizationStore.usersData.items.length! < organizationStore.usersData.total!) {
    try {
      await organizationStore.getOrganizationUsers({
        ...DEFAULT_PAGINATION,
        size: organizationStore.usersData.size! + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, { message: 'Component: DAddCreationUser, Function: fetchUsers' });
    }
  }
}

onMounted(async () => {
  try {
    await organizationStore.getOrganizationUsers({ ...DEFAULT_PAGINATION, size: 50 });
  } catch (error) {
    captureException(error, {
      message: 'Component: DAddCreationUser, Hook: onMounted, Method: getOrganizationUsers',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person_add" icon-folder="settings" class="q-mr-md" />
      <h5 class="q-ma-none">Add User to the Project</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="column select__row">
      <div class="row items-center q-mb-sm">
        <span>User</span>
        <ATooltip
          icon-folder="settings"
          icon-name="info-medium"
          tooltip-text="Users previously added in the organisation level. "
          class="q-ml-sm"
        />
      </div>
      <div class="row">
        <q-select
          v-if="organizationStore.usersData"
          v-model="user"
          outlined
          label="Select an User"
          :options="usersOptions()"
          :disable="organizationStore.usersData.items.length === 0"
          dense
          class="col-12"
          @virtual-scroll="fetchUsers"
        />
      </div>
    </div>
    <div class="column roles__row q-my-md">
      <span class="q-mb-sm">Roles</span>
      <div class="row roles__container" :class="[user ? '' : 'disabled']">
        <div v-for="role in roles" :key="role" class="role row q-mr-sm">
          <q-checkbox v-model="selected" :val="role" :disable="!user" size="16px" />
          <span class="role__text q-mx-sm">{{ role }}</span>
          <ATooltip
            icon-folder="settings"
            icon-name="info-medium"
            :tooltip-text="roleDescription(role)"
          />
        </div>
      </div>
    </div>
    <div class="row action__row full-width justify-end q-mt-lg">
      <q-btn
        class="btn-add"
        label="Add User"
        :disable="!user || selected.length === 0 || isLoading"
        :loading="isLoading"
        unelevated
        @click="addUser"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .roles__row {
    .role {
      span {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-600;
      }
      .q-checkbox {
        :deep(.q-checkbox__bg) {
          width: 100%;
          height: 100%;
          position: unset;
        }

        :deep(.q-checkbox__inner--falsy) {
          .q-checkbox__bg {
            color: $common-1;
          }
        }
        :deep(.q-checkbox__inner--truthy) {
          .q-checkbox__bg {
            color: $secondary-800;
          }
        }
        :deep(.q-checkbox__label) {
          @include paragraph-02(600, $common-5);
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .btn-add {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
