<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';

import { Roles, TestingTestStatus } from '@/client/api';

import TestingPanel from '@/components/ProjectDetail/ProjectTesting/TestingPanel.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import Icon from '@/components/atoms/Icon.vue';
import DCreateTest from '@/components/dialogs/DCreateTest.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';

import type { SecondaryPagination } from '@/interfaces/models/Pagination';

import { userProjectRoles } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const activeTab = ref<string>('all');
const isEditor = ref(false);

const isDataLoading = ref(false);
const showCreateTest = ref(false);

const secondaryPagination: SecondaryPagination = reactive({
  search: null,
  id: null,
  status: undefined,
  assignedAs: undefined,
});

const columnsNames = {
  name: 'Name',
  description: 'Description',
  status: 'Status',
  testing_test_results: 'Result',
  last_tested: 'Last Tested',
};

async function changeTab(value: TestingTestStatus | 'all') {
  if (!projectsStore.project) return;

  activeTab.value = value;
  paginationStore.setTestResultFilter(value === 'all' ? null : value);

  try {
    isDataLoading.value = true;
    await projectsStore.getProjectTestingTests({
      projectId: projectsStore.project.id,
      resultStatus: paginationStore.testResultFilter,
      ...DEFAULT_PAGINATION,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: Testing, Function: changeTab',
    });
  } finally {
    isDataLoading.value = false;
  }
}

function closeDialog() {
  showCreateTest.value = false;
}

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    isDataLoading.value = true;
    await projectsStore.getProjectTestingTests({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });

    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
  } catch (error) {
    captureException(error, {
      message: 'Component: Testing, Hook: onMounted, Method: getProjectTestingTests',
    });
  } finally {
    isDataLoading.value = false;
  }
});

onBeforeUnmount(() => {
  paginationStore.setTestResultFilter(null);
  paginationStore.setTestStatusFilter(null);
});
</script>

<template>
  <div class="testing__wrapper">
    <OTabPanels
      :active-tab="activeTab"
      :tab-size="{ md: 12, lg: 12 }"
      default-tab="all"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab
          :content-class="`${isDataLoading ? 'skeleton' : ''} my-tab`"
          name="all"
          label="All Tests"
          class="q-mr-md col-4"
          :class="activeTab === 'all' ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== 'all' ? '5px solid #556C77' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ projectsStore.testingData?.total || 0 }}</span>
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isDataLoading ? 'skeleton' : ''} my-tab`"
          name="passed"
          label="Passed Tests"
          class="q-mr-md col-4"
          :class="activeTab === 'passed' ? activeTab : ''"
          :style="{ borderLeft: activeTab !== 'passed' ? '5px solid #77d875' : '' }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ projectsStore.testingData?.number_of_passed || 0 }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === 'passed'"
              icon-name="testing-passed-active"
              icon-folder="testing"
            />
            <Icon v-else icon-name="testing-passed" icon-folder="testing" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isDataLoading ? 'skeleton' : ''} my-tab`"
          name="failed"
          label="Failed Tests"
          class="q-mr-md col-4"
          :class="activeTab === 'failed' ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== 'failed' ? '5px solid #F96F7F' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ projectsStore.testingData?.number_of_failed || 0 }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === 'failed'"
              icon-name="testing-failed-active"
              icon-folder="testing"
            />
            <Icon v-else icon-name="testing-failed" icon-folder="testing" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isDataLoading ? 'skeleton' : ''} my-tab`"
          name="error"
          label="Error"
          class="col-4"
          :class="activeTab === 'error' ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== 'error' ? '5px solid #FFB800' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ projectsStore.testingData?.number_of_errors || 0 }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === 'error'"
              icon-name="testing-error-active"
              icon-folder="testing"
            />
            <Icon v-else icon-name="testing-error" icon-folder="testing" />
          </div>
        </q-tab>
      </template>
      <template #panels>
        <q-tab-panel name="all">
          <TestingPanel
            title=""
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isDataLoading"
            :is-editor="isEditor"
            empty-title="You don’t have any tests to review yet."
            empty-sub-title="As soon as you have some tests you can see them here."
            @create-test="showCreateTest = true"
          />
        </q-tab-panel>
        <q-tab-panel name="passed">
          <TestingPanel
            title=""
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isDataLoading"
            :is-editor="isEditor"
            empty-title="You don’t have any passed tests to review yet."
            empty-sub-title="As soon as you have passed tests you can see them here."
            @create-test="showCreateTest = true"
          />
        </q-tab-panel>
        <q-tab-panel name="failed">
          <TestingPanel
            title=""
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isDataLoading"
            :is-editor="isEditor"
            empty-title="You don’t have any failed tests to review yet."
            empty-sub-title="As soon as you have failed tests to review you can see them here."
            @create-test="showCreateTest = true"
          />
        </q-tab-panel>

        <q-tab-panel name="error">
          <TestingPanel
            title=""
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isDataLoading"
            :is-editor="isEditor"
            empty-title="You don’t have any tests with errors  yet."
            empty-sub-title="As soon as you have tests with errors  you can see them here."
            @create-test="showCreateTest = true"
          />
        </q-tab-panel>
      </template>
    </OTabPanels>
  </div>
  <ADialog
    :show-dialog="showCreateTest"
    max-width="650px !important"
    min-width="650px !important"
    min-height="auto"
    class="dialog-create-test"
    @hide="closeDialog"
  >
    <DCreateTest @close-dialog="closeDialog" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.testing__wrapper {
  position: relative;

  :deep(.q-tab) {
    padding: 0;
    .my-tab {
      height: 80px;
      padding: 4px 10px 15px;
      justify-content: flex-start;
      border: 1px solid #dde1e6;
      background: $white;
      .q-tab__label {
        font-size: 16px;
        font-weight: 400;
      }
      .q-tab__indicator {
        height: 4px;
      }
      .total__container {
        span {
          @include heading-03(600, $secondary-600);
        }
      }

      .tab-icon__container {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }
  :deep(.q-tab--active) {
    .my-tab {
      background: unset;
      border: none;
      .q-tab__label {
        color: $white;
      }
      .total__container {
        span {
          color: $white;
        }
      }
    }
  }

  .all {
    background: #556c77;
    padding-left: 5px;
    :deep(.q-tab__indicator) {
      color: #556c77 !important;
    }
  }

  .passed {
    background: #77d875;
    padding-left: 5px;
    :deep(.q-tab__indicator) {
      color: #77d875 !important;
    }
  }

  .failed {
    background: #f96f7f;
    padding-left: 5px;
    :deep(.q-tab__indicator) {
      color: #f96f7f !important;
    }
  }

  .error {
    background: #ffb800;
    padding-left: 5px;
    :deep(.q-tab__indicator) {
      color: #ffb800 !important;
    }
  }

  :deep(.q-select) {
    .q-field__inner {
      padding: 0 !important;
      border: none !important;
    }
  }

  :deep(.q-panel) {
    overflow-y: hidden;
  }
  :deep(.q-tab-panel) {
    padding: 0;

    .controls-info {
      span {
        color: $secondary-600;
        font-size: 12px;
      }
    }
  }
  .empty__container {
    min-height: 400px;
    background: $white;
  }
  :deep(tbody td) {
    width: 200px;
  }
}
</style>
