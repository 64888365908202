import defineInterceptors from '@/api/httpClient';
import * as Sentry from '@sentry/vue';
import globalAxios from 'axios';
import { createPinia } from 'pinia';
import { Loading, Notify, Quasar } from 'quasar';
import { createApp } from 'vue';

import App from '@/App.vue';
import router from './router';

import veProgress from 'vue-ellipse-progress';
import './assets/styles/style.scss';
import '/node_modules/flag-icons/css/flag-icons.min.css';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';
// Import Quasar css
import 'quasar/src/css/index.sass';

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

import 'highlight.js/styles/default.css';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import type { Event, Exception } from '@sentry/types';

const pinia = createPinia();
const app = createApp(App);

Notify.setDefaults({
  color: 'green',
  textColor: 'white',
  timeout: 4000,
  actions: [
    {
      icon: 'close',
      color: 'white',
    },
  ],
});

app.use(pinia);
app.use(router);
app.use(Quasar, {
  plugins: { Notify, Loading }, // import Quasar plugins and add here
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
    loading: {},
    dark: false,
  },
});
app.use(veProgress);
app.use(FloatingVue, {
  container: '.editor__wrapp',
});
app.use(mavonEditor);

// eslint-disable-next-line no-extra-boolean-cast
if (import.meta.env.VITE_SENTRY_ENABLED) {
  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          new RegExp(
            `^${import.meta.env.VITE_API_URL.replace(/:\//g, '://').replace(/\./g, '\\.')}`,
          ),
        ],
        router,
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        showName: false,
        showEmail: false,
        colorScheme: 'light',
        showBranding: false,
        triggerLabel: '',
        buttonLabel: 'Provide Feedback',
        submitButtonLabel: 'Send Your Feedback',
        formTitle: 'Provide Feedback',
        successMessageText: 'Thanks for your feedback!',
        onFormOpen: () => {
          const user = localStorage.getItem('user');
          if (user) {
            const parsedUser = JSON.parse(localStorage.getItem('user')!);
            Sentry.setUser({
              id: parsedUser.id,
            });
            Sentry.setContext('organization', {
              id: parsedUser.organization.id,
              name: parsedUser.organization.name,
            });
          }
        },
      }),
    ],
    beforeSend(event: Event): Event | null {
      if (event.exception && event.exception.values) {
        const exception: Exception | undefined = event.exception.values.find((ex) =>
          ex.value?.includes('Failed to fetch dynamically imported module'),
        );
        if (exception) {
          return null;
        }
      }
      return event;
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount('#app');

defineInterceptors(globalAxios, router);
