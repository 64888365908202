<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed, onMounted, ref, shallowRef } from 'vue';

import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import MarkdownItHighlight from 'markdown-it-highlightjs';
import DOMPurify from 'dompurify';

import { formatDate, statusIcon, Status, STATUS_LABEL } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';

import { useTaskStore } from '@/stores/TaskStore';

import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import SDetailPage from '@/components/skeletons/SDetailPage.vue';
import type { ControlDisplay } from '@/client';

interface Detail {
  id: number;
  name: string;
  text?: string;
  avatar?: boolean;
  icon?: string;
}

const props = defineProps({
  controlId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'openControl']);

const $q = useQuasar();
const taskStore = useTaskStore();
const requirementIsLoading = ref(false);

let details = shallowRef<Detail[] | null>(null);
let renderedMarkdown = ref(null);

function userInitials(name: string) {
  if (!taskStore.requirement) return;
  if (name === 'Created') {
    return (
      taskStore.requirement.created_by.firstname.charAt(0).toUpperCase() +
      taskStore.requirement.created_by.lastname.charAt(0).toUpperCase()
    );
  } else if (taskStore.requirement.created_by && name === 'Modified') {
    return (
      taskStore.requirement?.modified_by?.firstname?.charAt(0).toUpperCase() ||
      '' + taskStore.requirement?.modified_by?.lastname?.charAt(0).toUpperCase() ||
      ''
    );
  }
}

function openControl(id: string) {
  emit('openControl', id);
}

const sortedBadges = computed(() => {
  if (!taskStore?.requirement?.controls) return null;

  const controlsArr: ControlDisplay[] = taskStore?.requirement?.controls;

  return (
    controlsArr
      .slice()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .sort((a: any, b: any) => {
        if (a.control_code && b.control_code) {
          return a.control_code.localeCompare(b.control_code);
        } else if (a.requirement_code && b.requirement_code) {
          return a.requirement_code.localeCompare(b.requirement_code);
        }
        return 0;
      })
  );
});

onMounted(async () => {
  try {
    requirementIsLoading.value = true;

    await taskStore.getRequirement({ requirementId: props.controlId });
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequirement, Hook: onMounted, Method: getRequirement',
    });
  } finally {
    requirementIsLoading.value = false;
  }

  if (!taskStore.requirement) return;

  details.value = [
    {
      id: 0,
      name: 'Requirement code',
      text: taskStore?.requirement?.requirement_code,
    },
    {
      id: 1,
      name: 'Created',
      text:
        taskStore?.requirement?.created_by.firstname +
        ' ' +
        taskStore?.requirement?.created_by.lastname +
        ' ' +
        formatDate(taskStore?.requirement?.created_at),
      avatar: true,
    },
    {
      id: 2,
      name: 'Modified',
      text: taskStore?.requirement?.modified_at
        ? taskStore?.requirement?.modified_by?.firstname +
          ' ' +
          taskStore?.requirement?.modified_by?.lastname +
          ' ' +
          formatDate(taskStore?.requirement?.modified_at)
        : '-',
      avatar: taskStore.requirement.modified_by ? true : false,
    },
    {
      id: 3,
      name: 'Readiness',
      text:
        taskStore.requirement.status == Status.IN_REVIEW
          ? STATUS_LABEL.IN_REVIEW
          : taskStore.requirement.status == Status.IN_PROGRESS
            ? STATUS_LABEL.IN_PROGRESS
            : String(taskStore.requirement.status).charAt(0).toUpperCase() +
              String(taskStore.requirement.status).replace('_', ' ').slice(1),
      icon: statusIcon(taskStore.requirement.status),
    },
  ];

  const md = new MarkdownIt({ linkify: true, typographer: true });
  md.use(MarkdownItHighlight, { hljs });
  const purify = DOMPurify(window);
  const render = md.render(`${taskStore.requirement?.requirement_detail_content}`);
  renderedMarkdown.value = purify.sanitize(render);
});
</script>

<template>
  <div v-if="taskStore.requirement && !requirementIsLoading" class="content-wrapper row">
    <div class="top__container row col-12 q-mb-xl">
      <div class="row col-8 items-end">
        <PageTitle
          :title="taskStore.requirement.name + ` (${taskStore.requirement.requirement_code})`"
          style="margin: 0"
        />
      </div>
      <q-space />
      <q-btn icon="close" text-color="secondary" dense @click="emit('closeDialog')" />
    </div>
    <div class="requirement__page-left">
      <div class="description__container q-mb-lg">
        <div class="row items-center relative-position q-mb-lg">
          <span class="requirment__title">Requirement Description</span>
          <slot name="copy-btn" />
        </div>

        <div class="reqirement__description">{{ taskStore.requirement.description }}</div>
      </div>
      <div
        v-if="taskStore.requirement.requirement_detail_content !== ''"
        class="details__container"
      >
        <div class="row items-center relative-position q-mb-lg">
          <Icon
            icon-folder="task"
            :icon-name="$q.dark.isActive ? 'menu_book-white' : 'menu_book'"
          />
          <span class="requirment__title q-ml-sm">Details</span>
          <slot name="copy-btn" />
        </div>

        <div class="reqirement__description markdown-element" v-html="renderedMarkdown" />
      </div>
    </div>
    <q-space />
    <div class="requirement__page-right">
      <div class="requirement__details column">
        <div class="details--top__container row items-center q-mb-md">
          <Icon icon-name="info" icon-folder="colored" />
          <span class="details__title q-ml-sm">Info</span>
        </div>
        <div v-for="detail in details" :key="detail.id" class="row items-center detail-row">
          <span class="col-12 q-mb-xs">{{ detail.name }}</span>
          <div class="row full-width items-center">
            <q-avatar v-if="detail.avatar && detail.text" size="25px">
              <span>{{ userInitials(detail.name) }}</span>
            </q-avatar>
            <div class="row items-center col-10">
              <Icon
                v-if="detail.icon"
                :icon-name="detail.icon"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <span v-if="detail.text" class="text-black detail-value">{{ detail.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="control__requirements column">
        <span class="requirement__title q-my-sm">Mapped Frameworks</span>

        <div class="row">
          <q-badge
            v-for="framework in taskStore.requirement.frameworks"
            :key="framework.framework_code"
            class="framework-badge q-mr-sm q-mb-sm justify-center"
          >
            <span>{{ framework.framework_code }}</span>

            <q-tooltip anchor="top middle" self="bottom middle" class="project-tooltip">
              {{ `${framework.name}` }}
            </q-tooltip>
          </q-badge>
        </div>
      </div>
      <hr />
      <div class="control__requirements column">
        <span class="requirement__title q-my-sm">Mapped Controls</span>

        <div v-if="sortedBadges" class="row">
          <q-badge
            v-for="requirement in sortedBadges"
            :key="requirement.control_code"
            class="control-badge q-mr-sm q-mb-sm justify-center cursor-pointer"
            :class="[requirement.status]"
            :label="requirement.control_code"
            @click="openControl(requirement.id)"
          >
            <Icon
              :icon-name="statusIcon(requirement.status as Status)"
              icon-folder="status"
              icon-size="20px"
              class="q-mr-sm"
            />
          </q-badge>
        </div>
      </div>
    </div>
  </div>
  <SDetailPage v-if="requirementIsLoading" />
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.right__actions {
  margin-bottom: 20px;

  button {
    padding: 4px;
    margin-left: 20px;

    :deep(.q-btn__content) {
      line-height: 0;
    }
  }

  :deep(.q-btn-dropdown) {
    i {
      color: $black;
    }
  }
}

.top__container {
  .q-btn::before {
    box-shadow: none !important;
  }
}

.requirement__page-left {
  width: 70%;
  height: fit-content;
  border-radius: 5px;

  .description__container,
  .details__container {
    background: $white;
    padding: 22px 20px;
  }

  .requirment__title {
    @include paragraph-02(700, $secondary-600);
  }

  .requirement__details {
    @include paragraph-01(400, $common-2);

    span:first-child {
      color: $black !important;
    }
  }
}

.requirement__page-right {
  width: 28.5%;
  padding: 25px 20px 25px 35px;
  background: $white;
  border-radius: 5px;

  hr {
    border: 1px solid $secondary-100;
  }

  .requirement__details {
    @include caption(400, $common-2);

    .details__title {
      @include paragraph-02(600, $secondary-600);
    }

    span:first-child {
      margin-right: 8px;
    }

    .detail-row {
      margin-bottom: 20px;

      .detail-value {
        font-size: 14px;
        color: $common-4;
      }

      :deep(.q-btn__content) {
        span {
          text-transform: none;
        }
      }
    }

    .q-avatar {
      background: $secondary-100;
      margin-right: 5px;

      span {
        margin: 0 !important;
      }
    }
  }

  .control__description {
    .description__title {
      @include caption(400, $common-2);
    }

    p {
      @include paragraph-01(400, $secondary-600);
    }
  }

  .control__question {
    .question__title {
      @include caption(400, $common-2);
    }

    p {
      @include paragraph-01(400, $secondary-600);
    }
  }

  .control__requirements {
    .requirement__title {
      @include caption(400, $common-2);
    }

    .badge__title {
      @include paragraph-02(600, $secondary-800);
    }
    .q-badge:hover {
      opacity: 0.7;
    }
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
