<script setup lang="ts">
import { useQuasar } from 'quasar';
import { onMounted, ref, shallowRef } from 'vue';

import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import MarkdownItHighlight from 'markdown-it-highlightjs';
import DOMPurify from 'dompurify';

import type { PropType } from 'vue';

import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import type { FrameworkRequirement } from '@/client';

interface Detail {
  id: number;
  name: string;
  text?: string;
}

const props = defineProps({
  frameworkRequirement: {
    type: Object as PropType<FrameworkRequirement>,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'openControl']);

const $q = useQuasar();

let details = shallowRef<Detail[] | null>(null);
let renderedMarkdown = ref(null);

function openControl(id: string) {
  emit('openControl', id);
}

onMounted(async () => {
  details.value = [
    {
      id: 0,
      name: 'Requirement code',
      text: props.frameworkRequirement.code,
    },
  ];

  const md = new MarkdownIt({ linkify: true, typographer: true });
  md.use(MarkdownItHighlight, { hljs });
  const purify = DOMPurify(window);
  const render = md.render(`${props.frameworkRequirement.detail_content}`);
  renderedMarkdown.value = purify.sanitize(render);
});
</script>

<template>
  <div v-if="frameworkRequirement" class="content-wrapper row">
    <div class="top__container row col-12 q-mb-xl">
      <div class="row col-8 items-end">
        <PageTitle
          :title="frameworkRequirement.name + ` (${frameworkRequirement.code})`"
          style="margin: 0"
        />
      </div>
      <q-space />
      <q-btn icon="close" dense @click="emit('closeDialog')" />
    </div>
    <div class="requirement__page-left">
      <div class="description__container q-mb-lg">
        <div class="row items-center relative-position q-mb-lg">
          <span class="requirment__title">Requirement Description</span>
          <slot name="copy-btn" />
        </div>

        <div class="reqirement__description">{{ frameworkRequirement.description }}</div>
      </div>
      <div v-if="frameworkRequirement.detail_content !== ''" class="details__container">
        <div class="row items-center relative-position q-mb-lg">
          <Icon
            icon-folder="task"
            :icon-name="$q.dark.isActive ? 'menu_book-white' : 'menu_book'"
          />
          <span class="requirment__title q-ml-sm">Details</span>
          <slot name="copy-btn" />
        </div>

        <div class="reqirement__description markdown-element" v-html="renderedMarkdown" />
      </div>
    </div>
    <q-space />
    <div class="requirement__page-right">
      <div class="requirement__details column">
        <div class="details--top__container row items-center q-mb-md">
          <Icon icon-name="info" icon-folder="colored" />
          <span class="details__title q-ml-sm">Info</span>
        </div>
        <div v-for="detail in details" :key="detail.id" class="row items-center detail-row">
          <span class="col-12 q-mb-xs">{{ detail.name }}</span>
          <div class="row full-width items-center">
            <div class="row items-center col-10">
              <span v-if="detail.text" class="text-black detail-value">{{ detail.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="control__requirements column">
        <span class="requirement__title q-my-sm">Mapped Frameworks</span>

        <div class="row">
          <q-badge
            v-for="framework in frameworkRequirement.mapped_frameworks"
            :key="framework"
            class="framework-badge q-mr-sm q-mb-sm justify-center"
          >
            <span>{{ framework }}</span>
          </q-badge>
        </div>
      </div>
      <hr />
      <div class="control__requirements column">
        <span class="requirement__title q-my-sm">Mapped Controls</span>

        <div class="row">
          <q-badge
            v-for="control in frameworkRequirement.controls"
            :key="control"
            class="control-badge q-mr-sm q-mb-sm justify-center"
            :label="control"
            @click="openControl(control)"
          >
          </q-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.right__actions {
  margin-bottom: 20px;

  button {
    padding: 4px;
    margin-left: 20px;

    :deep(.q-btn__content) {
      line-height: 0;
    }
  }

  :deep(.q-btn-dropdown) {
    i {
      color: $black;
    }
  }
}

.top__container {
  .q-btn::before {
    box-shadow: none !important;
  }
}

.requirement__page-left {
  width: 70%;
  height: fit-content;
  border-radius: 5px;

  .description__container,
  .details__container {
    background: $white;
    padding: 22px 20px;
  }

  .requirment__title {
    @include paragraph-02(700, $secondary-600);
  }

  .requirement__details {
    @include paragraph-01(400, $common-2);

    span:first-child {
      color: $black !important;
    }
  }
}

.requirement__page-right {
  width: 28.5%;
  padding: 25px 20px 25px 35px;
  background: $white;
  border-radius: 5px;

  hr {
    border: 1px solid $secondary-100;
  }

  .requirement__details {
    @include caption(400, $common-2);

    .details__title {
      @include paragraph-02(600, $secondary-600);
    }

    span:first-child {
      margin-right: 8px;
    }

    .detail-row {
      margin-bottom: 20px;

      .detail-value {
        font-size: 14px;
        color: $common-4;
      }

      :deep(.q-btn__content) {
        span {
          text-transform: none;
        }
      }
    }

    .q-avatar {
      background: $secondary-100;
      margin-right: 5px;

      span {
        margin: 0 !important;
      }
    }
  }

  .control__description {
    .description__title {
      @include caption(400, $common-2);
    }

    p {
      @include paragraph-01(400, $secondary-600);
    }
  }

  .control__question {
    .question__title {
      @include caption(400, $common-2);
    }

    p {
      @include paragraph-01(400, $secondary-600);
    }
  }

  .control__requirements {
    .requirement__title {
      @include caption(400, $common-2);
    }

    .badge__title {
      @include paragraph-02(600, $secondary-800);
    }
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
