<script setup lang="ts">
import { onBeforeUnmount, nextTick, onMounted, watch } from 'vue';

import type { PropType } from 'vue';
import type { TipTapContent } from '@/interfaces/models/Editor';

import { EditorContent, useEditor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography';
import Mention from '@tiptap/extension-mention';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';

import { Markdown } from 'tiptap-markdown';
import suggestion from './extensions/suggestion';
import { LinkChip } from './extensions/linkChip';
import { TransformPasteExtension } from './extensions/regexLinkChip';

import customLink from './extensions/customLink';

import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['updateEditor']);

const props = defineProps({
  editorContent: {
    type: Object as PropType<TipTapContent>,
    default: null,
  },
  rootEl: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
  fullHeight: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Begin editing...',
  },
  viewOnly: {
    type: Boolean,
    default: false,
  },
});

const headingOptions = [
  { text: 'Heading 1', value: { level: 1 } },
  { text: 'Heading 2', value: { level: 2 } },
  { text: 'Heading 3', value: { level: 3 } },
  { text: 'Heading 4', value: { level: 4 } },
  { text: 'Heading 5', value: { level: 5 } },
  { text: 'Heading 6', value: { level: 6 } },
];

const editorExtensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3, 4, 5, 6],
    },
  }),
  Underline,
  customLink,
  Highlight,
  Typography,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Mention.configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion,
  }),
  Markdown.configure({
    html: true, // Allow HTML input/output
    tightLists: true, // No <p> inside <li> in markdown output
    tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
    bulletListMarker: '-', // <li> prefix in markdown output
    linkify: true, // Create links from "https://..." text
    breaks: true, // New lines (\n) in markdown input are converted to <br>
    transformPastedText: true, // Allow to paste markdown text in the editor
    transformCopiedText: false, // Copied text is transformed to markdown
  }),
  TransformPasteExtension,
  LinkChip,
];

const editor = useEditor({
  editable: props.editable && !props.viewOnly,
  extensions: editorExtensions,
  onUpdate() {
    const contentJSON = editor.value?.getJSON();
    const html = editor.value?.getHTML();

    const content = {
      content: html,
      json_content: contentJSON,
    };

    emit('updateEditor', content);
  },
  content: '',
});

const toggleBold = () => editor.value?.chain().focus().toggleBold().run();
const toggleItalic = () => editor.value?.chain().focus().toggleItalic().run();
// const toggleUnderline = () => editor.value?.chain().focus().toggleUnderline().run();
const toggleHeading = (level: 1 | 2 | 3 | 4 | 5 | 6) => {
  editor.value?.chain().focus().toggleHeading({ level }).run();
};

const toggleBulletList = () => editor.value?.chain().focus().toggleBulletList().run();
const toggleOrderedList = () => editor.value?.chain().focus().toggleOrderedList().run();
const toggleTaskList = () => editor.value?.chain().focus().toggleTaskList().run();
const addLink = () => {
  const url = prompt('Enter the URL');
  if (url) {
    editor.value?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }
};
const toggleTextLeft = () => editor.value?.chain().focus().setTextAlign('left').run();
const toggleTextCenter = () => editor.value?.chain().focus().setTextAlign('center').run();
const toggleTextRight = () => editor.value?.chain().focus().setTextAlign('right').run();

const isActive = (type: string, options = {}) => editor.value?.isActive(type, options) || false;

watch(
  () => props.editable,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      editor.value?.setOptions({ editable: newVal });
    }
  },
);

onMounted(async () => {
  if (!props.editorContent) return;

  await nextTick();

  editor.value?.commands.setContent(
    props.editorContent.json_content && Object.keys(props.editorContent.json_content).length > 0
      ? props.editorContent.json_content
      : props.editorContent.content,
  );
});

onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>

<template>
  <div class="editor__wrapp text-break" :class="fullHeight ? 'full-height' : ''">
    <div class="editor__container" :class="[viewOnly ? 'view-only' : '']">
      <div v-if="!viewOnly && editable" class="tiptap-toolbar">
        <q-btn-dropdown
          label=""
          flat
          dropdown-icon="null"
          menu-self="top start"
          :class="{ activeDropdown: isActive('heading', 1 | 2 | 3 | 4 | 5 | 6) }"
          size="xs"
        >
          <template #label>
            <Icon
              icon-name="heading"
              icon-folder="editor"
              :class="{ active: isActive('heading', 1 | 2 | 3 | 4 | 5 | 6) }"
            />
          </template>
          <q-list>
            <q-item
              v-for="item in headingOptions"
              :key="item.value.level"
              v-close-popup
              clickable
              :class="{ activeHeading: isActive('heading', { level: item.value.level }) }"
              @click="toggleHeading(item.value.level as 1 | 2 | 3 | 4 | 5 | 6)"
            >
              <q-item-section>
                <q-item-label>{{ item.text }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <Icon
          icon-name="bold"
          icon-folder="editor"
          :class="{ active: isActive('bold') }"
          @click="toggleBold"
        />
        <Icon
          icon-name="italic"
          icon-folder="editor"
          :class="{ active: isActive('italic') }"
          @click="toggleItalic"
        />
        <Icon
          icon-name="link"
          icon-folder="editor"
          :class="{ active: isActive('link') }"
          @click="addLink"
        />
        <div class="break-line" />
        <Icon
          icon-name="orderList"
          icon-folder="editor"
          :class="{ active: isActive('orderedList') }"
          @click="toggleOrderedList"
        />
        <Icon
          icon-name="unorderList"
          icon-folder="editor"
          :class="{ active: isActive('bulletList') }"
          @click="toggleBulletList"
        />
        <Icon
          icon-name="event_list"
          icon-folder="editor"
          :class="{ active: isActive('taskList') }"
          @click="toggleTaskList"
        />
        <div class="break-line" />
        <Icon
          icon-name="align-left"
          icon-folder="editor"
          :class="{ active: isActive('textAlign', 'left') }"
          @click="toggleTextLeft"
        />
        <Icon
          icon-name="align-center"
          icon-folder="editor"
          :class="{ active: isActive('textAlign', 'center') }"
          @click="toggleTextCenter"
        />
        <Icon
          icon-name="align-right"
          icon-folder="editor"
          :class="{ active: isActive('textAlign', 'right') }"
          @click="toggleTextRight"
        />
      </div>
      <editor-content :editor="editor" class="editor editor-styles" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.editor__wrapp {
  .disabled-info {
    font-size: 14px;
    color: $common-2;
    font-style: italic;
  }
}

.editor__container {
  width: 100%;
  position: relative;
  padding: 0;

  .tiptap-toolbar {
    display: flex;
    align-items: center;
    padding: 20px;
    background: $white;
    border: 1px solid $secondary-300;
    border-bottom: none;
    border-radius: 5px 5px 0 0;

    .break-line {
      width: 1px;
      height: 30px;
      background: $secondary-100;
      margin: 0 10px;
    }

    .q-btn-dropdown {
      background: transparent;
      color: $black;
      border: unset;
      padding: 0;
      :deep(i) {
        display: none;
      }
      .icon-wrapp {
        margin-right: 0;
      }
    }
    .activeDropdown {
      background-color: $secondary-500;
      color: $white;
    }

    .icon-wrapp {
      padding: 6px !important;
      border-radius: 3px;
      margin-right: 5px;
      cursor: pointer;
    }

    .active {
      background-color: $secondary-500;
      :deep(svg) {
        path {
          fill: $white !important;
        }
      }
    }
  }

  .tiptap-toolbar button {
    margin-right: 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #000;
    cursor: pointer;
  }

  .bubble-menu button {
    margin-right: 5px;
    padding: 2px 5px;
    border: 1px solid #ccc;
    background-color: #004085;
    cursor: pointer;
  }

  .tiptap-toolbar button.active {
    background-color: #0056b3;
    color: $white;
    border-color: #004085;
  }

  .editor {
    min-height: 100px;
    position: relative;
    overflow-y: scroll;
    resize: vertical;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    background: $white;
    border: 1px solid $secondary-300;
    border-radius: 0 0 5px 5px;

    :deep(.tiptap) {
      padding: 10px;
      outline: unset;
      border-radius: 5px;
    }
    .mention {
      background-color: var(--purple-light);
      border-radius: 0.4rem;
      box-decoration-break: clone;
      color: var(--purple);
      padding: 0.1rem 0.3rem;
    }

    :deep(ul li) {
      display: flex;
      align-items: center;
      label {
        display: flex;
        align-items: center;
      }
      div {
        p {
          margin: 0;
          margin-left: 10px !important;
        }
      }
    }
  }

  .editor::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .editor-content {
    min-height: 300px;
    padding: 10px;
    border: 1px solid #ddd;
  }
}

.full-height {
  .editor__container {
    height: 100%;
    .editor {
      height: calc(100% - 72px);
    }
  }
}

.view-only {
  border: unset !important;
  overflow: hidden;
  :deep(.editor) {
    border: none;
    resize: none;
  }
}

.fullscreen-container {
  z-index: 6000;
  resize: none;
}
</style>
