<script setup lang="ts">
import { debounce } from 'lodash';
import { useQuasar } from 'quasar';
import { onMounted, ref } from 'vue';

import type { Evidence } from '@/client/api';
import type { QInput } from 'quasar';
import type { PropType } from 'vue';

import Icon from '@/components/atoms/Icon.vue';

import { MAX_DESCRIPTION_SIZE, MAX_NAME_SIZE } from '@/composables/utils';

const emit = defineEmits(['closeDialog', 'showPreview', 'updateEvidence']);

const props = defineProps({
  evidence: {
    type: Object as PropType<Evidence>,
    required: true,
  },
});

const $q = useQuasar();
const name = ref('');
const description = ref('');
const isLoading = ref(false);
const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);
const validationPassed = ref(false);

function onSubmit() {
  $q.notify({
    icon: 'cloud_done',
    message: 'Evidence Created',
  });
}

function updateEvidence() {
  const evidence = {
    name: name.value,
    description: description.value,
  };

  emit('updateEvidence', props.evidence.id, evidence);
}

const debouncedValidation = debounce(() => {
  if (!descriptionInput.value || !nameInput.value) return;
  const isNameValid = nameInput.value.validate();
  const isDescriptionValid = descriptionInput.value.validate();

  if (isNameValid && isDescriptionValid) {
    validationPassed.value = true;
  } else {
    validationPassed.value = false;
  }
}, 100);

onMounted(() => {
  name.value = props.evidence.file_name;
  description.value = props.evidence.description;
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon
        icon-name="arrow_back"
        icon-folder="evidence"
        class="q-mr-sm cursor-pointer"
        @click="emit('showPreview')"
      />
      <h5 class="q-ma-none">{{ `Edit evidence ${evidence.file_name}` }}</h5>
    </div>
    <div class="row">
      <q-form class="row col-12" @submit="onSubmit">
        <div class="col-12 row q-mb-lg">
          <span class="q-mb-sm col-12">Evidence Name</span>
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            class="col-12"
            placeholder="Insert Name"
            no-error-icon
            :rules="[
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="col-12 row">
          <span class="col-12 q-mb-sm">Evidence Description</span>
          <q-input
            ref="descriptionInput"
            v-model="description"
            outlined
            placeholder="Insert text"
            no-error-icon
            dense
            class="col-12"
            type="textarea"
            :rules="[
              (val: string) =>
                val.length <= MAX_DESCRIPTION_SIZE ||
                `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
      </q-form>
    </div>
    <div class="row action__row full-width justify-between q-mt-lg">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('showPreview')" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[!validationPassed ? 'disable' : '']"
        :loading="isLoading"
        :disable="isLoading"
        @click="updateEvidence"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  :deep(.q-field) {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
  }
  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__native::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, $common-4);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-6);
    }
  }
  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
