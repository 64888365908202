<script lang="ts" setup>
import * as d3 from 'd3';
import { ref, onMounted, watch } from 'vue';
import type { PropType } from 'vue';
import type { StatusOverview } from '@/client/api';

import AProgressTooltip from '@/components/atoms/AProgressTooltip.vue';

interface ArcData {
  startAngle: number;
  endAngle: number;
  fillColor: string;
  showIcon: boolean;
}

const props = defineProps({
  progress: {
    type: Object as PropType<StatusOverview>,
    required: true,
  },
});

const chart = ref<HTMLDivElement>();
const outerRadius = ref(95);
const innerRadius = ref(65);

function svgToRender(color: string) {
  if (color === '#509AF4') {
    return `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="check_circle">
          <mask id="mask0_1056_11125" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect id="Bounding box" width="16" height="16" fill="white"/>
          </mask>
          <g mask="url(#mask0_1056_11125)">
          <path id="check_circle_2" d="M7.06668 11.0666L11.7667 6.36659L10.8333 5.43325L7.06668 9.19992L5.16668 7.29992L4.23334 8.23325L7.06668 11.0666ZM8.00001 14.6666C7.07779 14.6666 6.21112 14.4916 5.40001 14.1416C4.5889 13.7916 3.88334 13.3166 3.28334 12.7166C2.68334 12.1166 2.20834 11.411 1.85834 10.5999C1.50834 9.78881 1.33334 8.92214 1.33334 7.99992C1.33334 7.0777 1.50834 6.21103 1.85834 5.39992C2.20834 4.58881 2.68334 3.88325 3.28334 3.28325C3.88334 2.68325 4.5889 2.20825 5.40001 1.85825C6.21112 1.50825 7.07779 1.33325 8.00001 1.33325C8.92223 1.33325 9.7889 1.50825 10.6 1.85825C11.4111 2.20825 12.1167 2.68325 12.7167 3.28325C13.3167 3.88325 13.7917 4.58881 14.1417 5.39992C14.4917 6.21103 14.6667 7.0777 14.6667 7.99992C14.6667 8.92214 14.4917 9.78881 14.1417 10.5999C13.7917 11.411 13.3167 12.1166 12.7167 12.7166C12.1167 13.3166 11.4111 13.7916 10.6 14.1416C9.7889 14.4916 8.92223 14.6666 8.00001 14.6666ZM8.00001 13.3333C9.4889 13.3333 10.75 12.8166 11.7833 11.7833C12.8167 10.7499 13.3333 9.48881 13.3333 7.99992C13.3333 6.51103 12.8167 5.24992 11.7833 4.21659C10.75 3.18325 9.4889 2.66659 8.00001 2.66659C6.51112 2.66659 5.25001 3.18325 4.21668 4.21659C3.18334 5.24992 2.66668 6.51103 2.66668 7.99992C2.66668 9.48881 3.18334 10.7499 4.21668 11.7833C5.25001 12.8166 6.51112 13.3333 8.00001 13.3333Z" fill="white"/>
          </g>
          </g>
        </svg>

      `;
  } else if (color === '#FD956B') {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="schedule">
      <path id="Vector" d="M7.99325 1.33301C4.31325 1.33301 1.33325 4.31967 1.33325 7.99967C1.33325 11.6797 4.31325 14.6663 7.99325 14.6663C11.6799 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.6799 1.33301 7.99325 1.33301ZM7.99992 13.333C5.05325 13.333 2.66659 10.9463 2.66659 7.99967C2.66659 5.05301 5.05325 2.66634 7.99992 2.66634C10.9466 2.66634 13.3333 5.05301 13.3333 7.99967C13.3333 10.9463 10.9466 13.333 7.99992 13.333ZM8.33325 4.66634H7.33325V8.66634L10.8333 10.7663L11.3333 9.94634L8.33325 8.16634V4.66634Z" fill="white"/>
      </g>
    </svg>
    `;
  } else {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="timelapse">
      <path id="Vector" d="M10.8266 5.17301C10.0466 4.39301 9.02659 3.99967 7.99992 3.99967V7.99967L5.17325 10.8263C6.73325 12.3863 9.26659 12.3863 10.8333 10.8263C12.3933 9.26634 12.3933 6.73301 10.8266 5.17301ZM7.99992 1.33301C4.31992 1.33301 1.33325 4.31967 1.33325 7.99967C1.33325 11.6797 4.31992 14.6663 7.99992 14.6663C11.6799 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.6799 1.33301 7.99992 1.33301ZM7.99992 13.333C5.05325 13.333 2.66659 10.9463 2.66659 7.99967C2.66659 5.05301 5.05325 2.66634 7.99992 2.66634C10.9466 2.66634 13.3333 5.05301 13.3333 7.99967C13.3333 10.9463 10.9466 13.333 7.99992 13.333Z" fill="#A5B7C0"/>
      </g>
    </svg>
    `;
  }
}

function convertCountsToRadians() {
  if (!props.progress) return;

  const percentages = [
    props.progress.completed_percentage,
    props.progress.in_review_percentage,
    props.progress.in_progress_percentage,
  ];
  const colors = ['#509AF4', '#FD956B', '#E1E7EA'];
  let cumulativePercent = 0;
  const gapRadians = 0.02;
  const arcs = percentages
    .map((percent, i) => {
      if (percent === 0 || !percent) return null;
      const startAngle = (cumulativePercent / 100) * Math.PI - Math.PI / 2;
      cumulativePercent += percent;
      let endAngle = (cumulativePercent / 100) * Math.PI - Math.PI / 2;
      endAngle -= gapRadians;

      return {
        startAngle,
        endAngle,
        fillColor: colors[i],
        showIcon: percent >= 10,
      };
    })
    .filter((arc) => arc !== null);

  if (arcs.length === 0) {
    arcs.push({
      startAngle: -Math.PI / 2,
      endAngle: Math.PI / 2,
      fillColor: '#E1E7EA',
      showIcon: false,
    });
  }

  return arcs;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderChart(arcData: any) {
  if (!chart.value) return;
  d3.select(chart.value).select('svg').remove();
  const svg = d3.select(chart.value).append('svg').attr('width', 200).attr('height', 125);

  arcData.forEach((arc: ArcData) => {
    svg
      .append('path')
      .attr('transform', 'translate(100,100)')
      .attr(
        'd',
        d3.arc()({
          innerRadius: innerRadius.value,
          outerRadius: outerRadius.value,
          startAngle: arc.startAngle,
          endAngle: arc.endAngle,
        }),
      )
      .attr('fill', arc.fillColor);
  });
  const textGroup = svg.append('g').attr('transform', 'translate(100,95)');

  textGroup
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('dy', '-0.5em')
    .text(`${props.progress.completed_percentage?.toFixed(0)}%`)
    .style('font-size', '28px')
    .style('font-weight', '600')
    .style('fill', '#1F2E3C');

  textGroup
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'hanging')
    .attr('dy', '0.2em')
    .text('completed')
    .style('font-size', '12px')
    .style('font-style', 'normal')
    .style('fill', '#556C77');

  arcData.forEach((arc: ArcData) => {
    if (!arc.showIcon) return;
    const startAngleSemiCircle = arc.startAngle - Math.PI / 2;
    const endAngleSemiCircle = arc.endAngle - Math.PI / 2;
    const midpointAngleSemiCircle = (startAngleSemiCircle + endAngleSemiCircle) / 2;

    const iconRadius = (outerRadius.value + innerRadius.value) / 2;
    const iconX = 100 + iconRadius * Math.cos(midpointAngleSemiCircle);
    const iconY = 100 + iconRadius * Math.sin(midpointAngleSemiCircle);

    const adjustedX = iconX - 8;
    const adjustedY = iconY - 8;

    svg
      .append('foreignObject')
      .attr('width', 16)
      .attr('height', 16)
      .attr('x', adjustedX)
      .attr('y', adjustedY)
      .html(svgToRender(arc.fillColor));
  });
}

watch(
  () => props.progress,
  (newV) => {
    if (!newV) return;

    const arcData = convertCountsToRadians();

    renderChart(arcData);
  },
);

onMounted(() => {
  if (!props.progress) return;

  const arcData = convertCountsToRadians();

  renderChart(arcData);
});
</script>
<template>
  <div ref="chart" class="chart">
    <q-tooltip anchor="center end" self="center start" class="bg-white">
      <AProgressTooltip :progress="progress" />
    </q-tooltip>
  </div>
</template>
