<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

import { useAuth } from '@/composables/Auth';
import { CURRENT_YEAR } from '@/composables/utils';

const { logout } = useAuth();
</script>

<template>
  <div class="error__wrapp row items-center">
    <div class="row full-width">
      <div class="logo__container row justify-center full-width">
        <Icon icon-name="logo" icon-folder="errors" class="q-mr-sm" />
        <Icon icon-name="logo-text" icon-folder="errors" />
      </div>
      <div class="column items-center justify-center full-width q-mb-md">
        <div class="row q-mb-md">
          <Icon icon-name="server-down" icon-folder="errors" />
        </div>
        <div class="row q-mb-md">
          <span class="error-text">You don't have an organization.</span>
        </div>
        <div class="row q-mb-md">
          <span class="error-info"
            >Please contact your administrator to invite you to join the organization.
            <span class="q-ml-xs underline" @click="logout"> Logout</span>
          </span>
        </div>
        <div class="row socials">
          <a href="https://www.linkedin.com/company/modulos-ag/" target="_blank">
            <Icon icon-name="mdi_linkedin" icon-folder="errors" class="q-mr-md" />
          </a>
          <a href="https://twitter.com/modulos_ai" target="_blank">
            <Icon icon-name="ri_twitter-x-fill" icon-folder="errors" class="q-mr-md" />
          </a>
          <a href="https://www.youtube.com/channel/UCYHty7c5Nr-HJtAQDKiJ7nw" target="_blank">
            <Icon icon-name="mdi_youtube" icon-folder="errors" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer__container row items-center">
      <span class="footer q-mr-sm">{{ ` © 2023-${CURRENT_YEAR} Modulos AG. ` }}</span>
      <a href="https://status.modulos.ai/" target="_blank">Status</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.error__wrapp {
  width: 100%;
  height: 100vh;
  position: relative;
  background: $secondary-50;
  .logo__container {
    padding-bottom: 100px;
  }
  .error-text {
    font-size: 50px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -2.4px;
    color: $secondary-700;
  }
  .error-info {
    max-width: 430px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-600;
    text-align: center;
    a {
      color: $secondary-600;
      text-decoration: none;
      font-weight: 600;
    }
    .underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .socials {
    margin-top: 55px;
  }

  .footer__container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    .footer {
      color: $black;
      font-size: 10px;
      line-height: 16px;
    }
    a {
      @include caption(600, $secondary-600);
      text-decoration: none;
    }
  }
}
</style>
