<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import Icon from '@/components/atoms/Icon.vue';

import { captureException } from '@/composables/Sentry';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { DEFAULT_PAGINATION, SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

interface Select {
  value: string | null;
  label: string;
}

const filter = ref('');
const route = useRoute();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const organizationStore = useOrganizationStore();
const tag = ref<Select | null>(null);

const selectOptions = computed(() => {
  let options: Select[] = [];
  options.push({
    label: 'All Tags',
    value: null,
  });
  if (organizationStore.taxonomyData) {
    organizationStore.taxonomyData.tags.forEach((tag) => {
      tag.tag_values.forEach((tagValue) => {
        options.push({
          value: tag.tag_name + '=' + tagValue.tag_value,
          label: tag.tag_name + ': ' + tagValue.tag_value,
        });
      });
    });
  }
  return options;
});

async function filterData() {
  if (!projectsStore.project) return;

  paginationStore.setNameFilter(filter.value);

  try {
    switch (route.name) {
      case 'project-requirements':
        await projectsStore.getProjectRequirements({
          projectId: projectsStore.project.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
          name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
          framework: paginationStore.frameworkQuery,
          status: paginationStore.statusFilter,
        });
        break;
      case 'project-controls':
        await projectsStore.getProjectControls({
          projectId: projectsStore.project?.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
          tags: paginationStore.tagFilter ? [paginationStore.tagFilter] : undefined,
          name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
          framework: paginationStore.frameworkQuery,
          status: paginationStore.statusFilter,
          isAssignedToMe: paginationStore.assignedToMe,
          isAssignedToMeForReview: paginationStore.assignedToMeForReview,
        });
        break;
    }
  } catch (error) {
    captureException(error, { message: 'Component: MContentHeader, Function: filterData' });
  }
}

async function filterByTags() {
  if (!tag.value || !projectsStore.project) return;
  const selectedTag = tag.value.value;

  paginationStore.setTagFilter(selectedTag);

  try {
    await projectsStore.getProjectControls({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
      size: SMALLER_PAGINATION_SIZE,
      tags: !selectedTag ? undefined : [selectedTag],
      name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
      framework: paginationStore.frameworkQuery,
      status: paginationStore.statusFilter,
      isAssignedToMe: paginationStore.assignedToMe,
      isAssignedToMeForReview: paginationStore.assignedToMeForReview,
    });
  } catch (error) {
    captureException(error, { message: 'Component: MContentHeader, Function: filterByTags' });
  }
}

watch(
  () => paginationStore.nameFilter,
  (newV) => {
    filter.value = newV;
  },
  {
    immediate: true,
  },
);

watch(
  () => paginationStore.tagFilter,
  (newV) => {
    if (newV) {
      const parts = newV.split('=');
      const tagName = parts[0];
      const tagValue = parts[1];

      tag.value = { value: tagName, label: tagName + ': ' + tagValue };
    } else {
      tag.value = { value: null, label: 'All Tags' };
    }
  },
);
</script>

<template>
  <q-header bordered class="content-header text-white" :style="{ left: '230px' }">
    <q-toolbar>
      <q-input
        v-model="filter"
        borderless
        dense
        debounce="300"
        class="col-5"
        :readonly="projectsStore.conceptsLoading"
        :placeholder="
          route.name === 'project-controls' ? 'Search for Controls' : 'Search requirements'
        "
        @update:model-value="filterData"
      >
        <template #prepend>
          <Icon icon-name="search" icon-folder="content" />
        </template>
      </q-input>
      <div
        v-if="route.name === 'project-controls'"
        class="tags-filter__container col-7 row items-center q-ml-md"
      >
        <div class="tags__label row q-mr-sm">
          <Icon icon-name="filter_alt" icon-folder="content" class="q-mr-xs" />
          <span>Filters</span>
        </div>
        <q-select
          v-if="organizationStore.taxonomyData"
          v-model="tag"
          outlined
          :options="selectOptions"
          dense
          label="Select a Tag"
          class="col-10"
          :readonly="projectsStore.conceptsLoading"
          @update:model-value="filterByTags"
        >
        </q-select>
      </div>
      <q-space />
    </q-toolbar>
  </q-header>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

header {
  background: $white;
  position: absolute !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 4px 0;
  :deep(.q-toolbar) {
    min-height: 55px;
    padding: 0 20px;
    .q-field {
      max-width: 280px;
      border: 1px solid $common-1;
      border-radius: 4px;
      padding: 2px 12px;
      input::placeholder {
        color: $secondary-400;
      }
    }
    label {
      margin: 0 10px;
    }
    .tags-filter__container {
      padding: 0 20px;
      border-left: 1px solid $secondary-200;
      .tags__label {
        @include paragraph-01(400, $secondary-500);
      }
      .q-field {
        padding: 0;
        .q-field__control::before {
          border: none !important;
        }
        .q-field__label {
          color: $secondary-300;
        }
      }
    }
  }
}
</style>
