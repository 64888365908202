<template>
  <div class="dropdown-menu">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="`${item}-${index}`"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        {{ item }}
      </button>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
});

const selectedIndex = ref(0);

watch(
  () => props.items,
  () => {
    selectedIndex.value = 0;
  },
);

const onKeyDown = (e: { event: KeyboardEvent }) => {
  const event = e.event;
  if (event.key === 'ArrowUp') {
    upHandler();
    event.preventDefault();
    return true;
  }

  if (event.key === 'ArrowDown') {
    downHandler();
    event.preventDefault();
    return true;
  }

  if (event.key === 'Enter') {
    enterHandler();
    return true;
  }

  return false;
};

const upHandler = () => {
  selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length;
};

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
};

const enterHandler = () => {
  selectItem(selectedIndex.value);
};

const selectItem = (index: number) => {
  const item = props.items[index];

  if (item) {
    props.command({ id: item });
  }
};

defineExpose({
  upHandler,
  downHandler,
  enterHandler,
  selectItem,
  onKeyDown,
});
</script>

<style lang="scss">
/* Dropdown menu */
.dropdown-menu {
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow: auto;
  padding: 0.4rem;
  position: relative;

  button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 0.25rem;
    text-align: left;
    width: 100%;
    color: black;

    &:hover,
    &:hover.is-selected {
      background-color: gray;
    }

    &.is-selected {
      background-color: blue;
    }
  }
}
</style>
