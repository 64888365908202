<script setup lang="ts">
import { useQuasar } from 'quasar';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { PropType } from 'vue';
import type { RiskAggregator, UserDisplay, Control } from '@/client/api';
import { Concept } from '@/client/api';

import { successMessage } from '@/composables/Notify';
import { STATUS_LABEL, Status, statusIcon } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';

import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useRisksStore } from '@/stores/RisksStore';

import ADialog from '@/components/atoms/ADialog.vue';
import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import Icon from '@/components/atoms/Icon.vue';
import DAddRiskTag from '@/components/dialogs/DAddRiskTag.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';

interface Tag {
  tag_description: string;
  tag_value: string;
}

interface RemoveControl {
  id: string;
  name: string;
}

const emit = defineEmits(['updateCommentsAndLogs']);

const props = defineProps({
  risk: {
    type: Object as PropType<RiskAggregator>,
    required: true,
  },
  reviewers: {
    type: Array as PropType<UserDisplay[]>,
    default: () => [],
  },
  isAllowed: {
    type: Boolean,
    default: false,
  },
});

const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const risksStore = useRisksStore();
const organizationStore = useOrganizationStore();
const projectStore = useProjectsStore();
const owner = ref('');
const assignedUser = ref<UserDisplay | null>(null);
const status = ref<Status | null>(null);
const riskType = ref('');
const riskStage = ref('');
const riskLevel = ref('');
const aiStage = ref('');
const typeOptions = ref<Tag[]>([]);
const stageOptions = ref<Tag[]>([]);
const aiStageOptiopns = ref<Tag[]>([]);
const showAddTagDialog = ref(false);
const showRemoveControlDialog = ref(false);
const removeControlAssociation = ref<null | RemoveControl>(null);
const confirmationIsLoading = ref(false);

async function selectUser(user: string, id: string) {
  if (!props.risk) return;

  try {
    const params = {
      riskId: props.risk.id,
      userId: id,
    };

    await risksStore.updateRiskAssignee(params);

    successMessage(`Risk '${props.risk?.name || 'Unknown'}' was assigned to ${user}`);

    if (!risksStore.currentRisk) return;

    await risksStore.getRisk({ riskId: risksStore.currentRisk.id });
  } catch (error) {
    captureException(error, { message: 'Component: InfoRight, Function: selectUser' });
  }
}

function showAddTag() {
  showAddTagDialog.value = true;
}

function closeDialog() {
  showAddTagDialog.value = false;
}

function assignTagsValues() {
  if (!organizationStore.taxonomyData) return;
  organizationStore.taxonomyData.tags.forEach((tag) => {
    switch (tag.tag_name) {
      case 'AI System Lifecycle':
        aiStageOptiopns.value = tag.tag_values;
        break;
      case 'EU AI Act Risk Type':
        typeOptions.value = tag.tag_values;
        break;
    }
  });
}

async function updateRisk() {
  if (!props.risk) return;
  if (props.risk.is_locked) {
    $q.notify({
      color: 'red',
      textColor: 'white',
      icon: 'lock',
      message: 'Control is locked and cannot be changed.',
    });
    return;
  }

  const data = {
    risk_type: riskType.value,
    risk_level: riskLevel.value,
    risk_stage: riskStage.value,
    ai_lifecycle_stage: aiStage.value,
  };

  const params = {
    riskId: props.risk.id,
    updateRisk: data,
  };
  try {
    await risksStore.updateRisk(params);

    successMessage(`Risk '${props.risk?.name || 'Unknown'}' was updated`);
  } catch (error) {
    captureException(error, {
      message: 'Component: InfoRight, Function: updateRisk',
      data: {
        params: params,
      },
    });
  }
}

function openControl(id: string) {
  if (!projectStore.project) return;

  router.push({
    name: 'project-requirements',
    params: { id: projectStore.project.id },
    query: { control: id },
  });
}

async function confirmRemoveAssociation() {
  if (!removeControlAssociation.value || !props.risk) return;

  try {
    confirmationIsLoading.value = true;

    const params = {
      riskId: props.risk.id,
      controlId: removeControlAssociation.value.id,
    };

    await risksStore.removeControlFromRisk(params);

    successMessage(`Association with Control ${removeControlAssociation.value.name} was removed`);
    await risksStore.getRiskControls({ riskId: props.risk.id });
    emit('updateCommentsAndLogs');
    closeConfirm();
  } catch (e) {
    captureException(e, {
      message: 'Component: InfoRight, Function: confirmRemoveAssociation',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

function setRemoveControl(control: Control) {
  removeControlAssociation.value = control;
  showRemoveControlDialog.value = true;
}

function closeConfirm() {
  showRemoveControlDialog.value = false;
}

watch(
  () => props.risk,
  (newV) => {
    if (!props.risk || !newV) return;
    owner.value = newV.created_by.firstname + ' ' + newV.created_by.lastname;
    assignedUser.value = newV.assigned_to || null;
    status.value = props.risk.status;

    riskType.value = props.risk.risk_type || '';
    riskLevel.value = props.risk.risk_level || '';
    riskStage.value = props.risk.risk_stage || '';
    aiStage.value = props.risk.ai_lifecycle_stage || '';
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  if (!props.risk) return;

  try {
    await risksStore.getRiskControls({ riskId: props.risk.id });
    await organizationStore.getOrganizationTaxonomy();

    assignTagsValues();
  } catch (error) {
    captureException(error, { message: 'Component: InfoRight, Hook: onMounted' });
  }
});
</script>

<template>
  <div class="assessment-right">
    <div class="row q-mb-lg">
      <Icon icon-name="info" icon-folder="colored" />
      <span class="details__title q-ml-sm">Info</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm">Owner</span>

      <span class="q-mb-md col-12 text-black">{{ owner }}</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm col-12">Assigned To</span>
      <q-btn-dropdown
        v-if="!risk.is_locked && isAllowed"
        flat
        :label="
          risk.assigned_to
            ? risk.assigned_to.firstname + ' ' + risk.assigned_to.lastname
            : 'Please Select'
        "
        :disable="risk.is_locked || !isAllowed"
        class="q-mb-md"
      >
        <AUsersDropDown :assigned-user="assignedUser" @select-user="selectUser" />
      </q-btn-dropdown>
      <span v-else class="q-mb-md col-12 text-black">{{
        risk.assigned_to ? risk.assigned_to.firstname + ' ' + risk.assigned_to.lastname : '-'
      }}</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm col-12">Reviewer(s)</span>
      <div v-if="reviewers && reviewers.length > 0" class="row items-center q-mb-md">
        <q-badge
          v-for="user in reviewers"
          :key="user.id"
          class="q-mr-sm q-mb-sm user-badge"
          :label="user.firstname + ' ' + user.lastname"
        />
      </div>
      <span v-else class="text-black detail-value q-mb-md">-</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm col-12">Status</span>
      <div v-if="status" class="row">
        <Icon
          :icon-name="statusIcon(status)"
          icon-folder="status"
          class="q-mr-xs"
          icon-size="20px"
        />
        <span class="text-black detail-value">{{
          STATUS_LABEL[status.toUpperCase() as keyof typeof STATUS_LABEL]
        }}</span>
      </div>
    </div>
    <hr class="q-my-lg" />

    <div class="row col-12">
      <span class="select__title q-mb-sm">Risk type</span>
      <q-select
        v-if="!risk.is_locked"
        v-model="riskType"
        outlined
        label="Please Select"
        :options="typeOptions.map((tag: Tag) => tag.tag_value)"
        dense
        class="q-mb-md col-12"
        :disable="risk.is_locked || !isAllowed"
        @update:model-value="updateRisk"
      />
      <span v-else class="q-mb-md col-12 text-black">{{
        riskType !== '' && riskType ? riskType : '-'
      }}</span>
    </div>

    <div class="row col-12">
      <span class="select__title q-mb-sm">Risk Stage</span>
      <q-select
        v-if="!risk.is_locked"
        v-model="riskStage"
        outlined
        label="Please Select"
        :options="stageOptions"
        dense
        class="q-mb-md col-12"
        disable
      />
      <span v-else class="q-mb-md col-12 text-black">{{
        riskStage !== '' && riskStage ? riskStage : '-'
      }}</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm">AI lifecycle stage</span>
      <q-select
        v-if="!risk.is_locked"
        v-model="aiStage"
        outlined
        label="Please Select"
        :options="aiStageOptiopns.map((tag: Tag) => tag.tag_value)"
        dense
        class="q-mb-md col-12"
        :disable="risk.is_locked || !isAllowed"
        @update:model-value="updateRisk"
      />
      <span v-else class="q-mb-md col-12 text-black">{{
        aiStage !== '' && aiStage ? aiStage : '-'
      }}</span>
    </div>
    <div class="row col-12">
      <span class="select__title q-mb-sm">Risk Level</span>

      <span
        class="q-mb-md col-12 level-text"
        :class="
          risk.risk_rpn_value
            ? (risk.rpn_risk_level as unknown as unknown as string)
                .replaceAll(' ', '-')
                .toLowerCase()
            : ''
        "
        >{{ risk.rpn_risk_level ? risk.rpn_risk_level : '-' }}</span
      >
    </div>
    <hr />
    <div class="row controls__row col-12">
      <span class="select__title q-my-sm">Mapped Controls</span>

      <div v-if="risksStore.riskControls && risksStore.riskControls.length > 0" class="row col-12">
        <q-badge
          v-for="control in risksStore.riskControls"
          :key="control.control_code"
          class="risk-badge q-mr-sm q-mb-sm justify-center cursor-pointer"
          :class="[control.status]"
          @click="openControl(control.id)"
        >
          <Icon
            :icon-name="statusIcon(control.status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />

          <span>{{ control.control_code }}</span>

          <Icon
            icon-name="close"
            icon-folder="task"
            icon-size="12px"
            class="q-ml-sm"
            :class="[risk.is_locked ? 'cursor-not-allowed' : '']"
            @click.stop.prevent="risk.is_locked ? null : setRemoveControl(control)"
          />
        </q-badge>
      </div>
      <span v-else class="text-black col-12">-</span>
    </div>
    <hr />
    <div class="row tags__row col-12 justify-between relative-position q-mt-md">
      <div class="row col-12">
        <span class="select__title">Tags</span>
      </div>
      <div v-if="risk.tags && risk.tags.length > 0" class="row col-12 q-my-sm">
        <q-badge
          v-for="item in risk.tags"
          :key="item.name"
          class="q-mr-sm q-mb-sm general-badge"
          :label="`${item.name}: ${item.value}`"
        >
        </q-badge>
      </div>
      <span v-else class="text-black col-12">-</span>

      <q-btn
        v-if="isAllowed"
        class="btn-create full-width q-mt-sm"
        label="Update Tag(s)"
        unelevated
        :disable="risk.is_locked || !isAllowed"
        @click="showAddTag"
      />
    </div>
  </div>

  <ADialog
    :show-dialog="showAddTagDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DAddRiskTag
      :concept-name="Concept.Risk"
      :concept="risk"
      :concept-id="route.params.riskId as string"
      @close-dialog="closeDialog"
    />
  </ADialog>

  <ADialog
    v-if="removeControlAssociation"
    :show-dialog="showRemoveControlDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showRemoveControlDialog = false"
  >
    <DConfirm
      :title="`Would you like to remove the association with the Risk ${removeControlAssociation.name} ?`"
      description=""
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirmRemoveAssociation"
    />
  </ADialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.assessment-right {
  @include heading-04(600, $secondary-600);
  width: 25%;
  height: fit-content;
  border-radius: 5px;
  padding: 20px;
  background: $white;
  position: relative;

  .select__title {
    @include caption(400, $secondary-500);
  }
  .q-select .q-field__inner {
    border: none !important;
    padding: 0 !important;
  }
  hr {
    border: 1px solid $secondary-100;
  }

  .q-btn-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.24);

    :deep(.q-focus-helper) {
      display: none;
    }

    :deep(span) {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    :deep(i) {
      color: $common-3 !important;
    }
  }

  .q-btn-dropdown:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }
  :deep(.q-btn__content) {
    span {
      text-transform: none;
    }
  }
  .text-black {
    @include paragraph-01(400, $common-4);
  }
  .level-text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  .controls__row {
    .control-badge:hover {
      span {
        opacity: 0.7;
      }
    }
  }
  .tags__row {
    .general-badge {
      max-width: 100%;
      overflow: hidden;
    }
    button {
      height: 40px;
      color: $secondary-500;
      background: transparent !important;
      border: 1px solid $secondary-500;
      text-transform: none;
      border-radius: 5px;
    }
  }
  .actions {
    width: 140px;
    box-shadow:
      0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: $white;
    padding: 4px 0;
    .action__container {
      padding: 6px 12px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: $common-4;
      }
    }
    .action__container:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
  .disabled {
    opacity: 0.5;
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
