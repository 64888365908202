<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import * as d3 from 'd3';

const props = defineProps({
  chart: {
    type: Array as () => number[],
    default: () => [],
  },
});

const histogram = ref<HTMLDivElement>();
const minColor = '#80FFC2';
const mediumColor = '#FFDE32';
const maxColor = '#FF5F72';

function getColor(binMidpoint: number) {
  return d3
    .scaleLinear<string>()
    .domain([0, 0.5, 1])
    .range([minColor, mediumColor, maxColor])
    .interpolate(d3.interpolateRgb.gamma(1))(binMidpoint);
}

function renderChart() {
  if (!histogram.value) return;

  d3.select(histogram.value).select('svg').remove();

  const margin = { top: 50, right: 30, bottom: 70, left: 60 },
    width = 460,
    height = 400 - margin.top - margin.bottom;

  const svg = d3
    .select(histogram.value)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const binSize = 0.1;
  const binsCount = 10;
  const bins = new Array(binsCount).fill(0);

  // Increment the correct bin for each value
  props.chart.forEach((value) => {
    // Correct bin index calculation
    let binIndex;
    if (value === 1) {
      // If the value is exactly 1, assign it to the last bin
      binIndex = binsCount - 1;
    } else {
      // Otherwise, calculate the bin index as before
      binIndex = Math.floor(value / binSize);
    }
    bins[binIndex] = (bins[binIndex] || 0) + 1; // Increment the bin count
  });

  const x = d3
    .scaleBand()
    .range([0, width])
    .domain(bins.map((_, i) => (i * binSize + binSize / 2).toFixed(1))) // Midpoints of bins
    .padding(0.1);
  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400);

  // Y axis: scale and draw
  const y = d3
    .scaleLinear()
    .domain([0, Math.max(10, 0.5 * props.chart.length)]) // Set the domain from 0 to the length of the data array
    .range([height, 0])
    .nice(); // This will round the domain extent to nice round values

  svg
    .append('g')
    .call(
      d3
        .axisLeft(y)
        .ticks(Math.max(10, 0.5 * props.chart.length)) // Suggest D3 to create a tick for each integer value
        .tickFormat(d3.format('d')), // Format ticks as integers
    )
    .selectAll('text')
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400);

  svg
    .selectAll('rect')
    .data(bins)
    .enter()
    .append('rect')
    .attr('x', (_, i) => {
      // Ensure the value exists in the domain by using the midpoint value
      const binMidpoint = (i * binSize + binSize / 2).toFixed(1);
      return x(binMidpoint) ?? 0; // Provide 0 as a fallback
    })
    .attr('y', (d) => y(d))
    .attr('width', x.bandwidth())
    .attr('height', (d) => height - y(d))
    .attr('fill', (_, i) => {
      const binMidpoint = i * binSize + binSize / 2;
      return getColor(binMidpoint);
    });

  // Add title
  svg
    .append('text')
    .attr('x', width / 2)
    .attr('y', 0 - margin.top / 2)
    .attr('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('color', '#172733')
    .style('font-size', '16px')
    .style('font-weight', 500)
    .text('RPN Distribution');

  // Add y-axis label
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', 0 + margin.left)
    .attr('y', -margin.top / 2)
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .text('FREQUENCY');

  // Add x-axis label
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width)
    .attr('y', height + margin.bottom - 10) // Adjust this value to move the label up or down
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .text('RPN');

  // Add grid lines
  svg
    .append('g')
    .attr('class', 'grid')
    .call(
      d3
        .axisLeft(y)
        .tickSize(-width)
        .tickFormat(() => ''),
    )
    .attr('stroke', 'lightgrey')
    .attr('stroke-opacity', 0.7)
    .selectAll('.tick line')
    .attr('stroke', 'lightgrey')
    .attr('stroke-dasharray', '2,2');
}

watch(
  () => props.chart,
  () => {
    renderChart();
  },
);

onMounted(() => {
  renderChart();
});
</script>

<template>
  <div id="risks-chart" ref="histogram"></div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

#risks-chart {
  color: $white;
}
</style>
