<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

import {
  FormatNumber,
  STATUS_LABEL,
  formatDate,
  statusIcon,
  FormatFileSize,
} from '@/composables/utils';

import { vOnClickOutside } from '@vueuse/components';

import { roleDescription } from '@/composables/Auth';
import { DEFAULT_THRESHOLD, RISK_LEVEL_LABEL, RpnLevel } from '@/composables/CRisk';
import { OrganizationCurrency } from '@/composables/Config';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import { useAuthStore } from '@/stores/AuthStore';
import { useConfigStore } from '@/stores/ConfigStore';
import { useDashboardStore } from '@/stores/DashboardStore';
import { useEvidenceStore } from '@/stores/EvidenceStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useVendorStore } from '@/stores/VendorStore';

import type {
  AssetCard,
  AssetCardTypes,
  ControlWithoutComponents,
  CreationFrameworkTemplate,
  DocumentOverview,
  Evidence,
  UserInOrgList,
  ProjectAggregator,
  ProjectListings,
  Risk,
  TestingTest,
  TestingTestAndResult,
  TestingTestResult,
  UserInProjectList,
  VendorDetails,
  OrganizationUser,
} from '@/client/api';
import { ProjectType, TestingTestStatus, UserState, Concept } from '@/client/api';
import type { PropType } from 'vue';

import type { SecondaryPagination } from '@/interfaces/models/Pagination';
import type { ProjectSetting } from '@/interfaces/models/ProjectSetting';

import ADialog from '@/components/atoms/ADialog.vue';
import AFrameworkIcon from '@/components/atoms/AFrameworkIcon.vue';
import ATooltip from '@/components/atoms/ATooltip.vue';
import Icon from '@/components/atoms/Icon.vue';
import DRequestAccess from '@/components/dialogs/DRequestAccess.vue';
import STable from '@/components/skeletons/STable.vue';

import { DEFAULT_PAGINATION_SIZE, type TablePagination } from '@/interfaces/models/Pagination';
import type { PaginationData } from '../../molecules/MPagination.vue';

//cells
import IsActiveCell from './cells/IsActiveCell.vue';
import NameCell from './cells/NameCell.vue';
import FileNameCell from './cells/FileNameCell.vue';

interface QTableColumn {
  name: string;
  label: string;
  field: string | ((row: { name: string }) => unknown);
  format?: (val: unknown, row: unknown) => unknown;
  required?: boolean;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  sort?: (a: string, b: string, rowA: unknown, rowB: unknown) => number;
  headerClasses?: string;
  classes?: string;
  style?: string;
}

interface Role {
  id: string;
  role: string;
}

interface Owner {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
}

interface tableProps {
  row: ProjectAggregator;
}

interface Token {
  id?: string;
  access_token: 'string';
  token_type: 'string';
}

interface UserProject {
  id: string;
  name: string;
}

interface TagValues {
  tag_value: string;
  tag_description: string;
}

type Data =
  | ProjectAggregator
  | Token
  | UserInOrgList
  | CreationFrameworkTemplate
  | TagValues
  | ProjectListings
  | UserInProjectList
  | OrganizationUser
  | Risk
  | Evidence
  | AssetCard
  | TestingTest
  | VendorDetails
  | DocumentOverview
  | ControlWithoutComponents
  | TestingTestResult
  | TestingTestAndResult;

const componentProps = defineProps({
  data: {
    type: Array<Data>,
    default: () => [],
  },
  pagination: {
    type: Object as PropType<PaginationData>,
    default: () => null,
  },
  secondaryPagination: {
    type: Object as PropType<SecondaryPagination>,
    default: () => null,
  },
  columnsNames: {
    type: Object,
    default: () => null,
  },
  visibleColumns: {
    type: Array,
    default: () => [],
  },
  searchFilter: {
    type: Boolean,
    default: true,
  },
  searchInput: {
    type: Boolean,
    default: true,
  },
  searchPlaceholder: {
    type: String,
    default: 'Search',
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
  columnsToShow: {
    type: Number,
    default: 10,
  },
  rowRoute: {
    type: String,
    default: '',
  },
  routeQuery: {
    type: String,
    default: null,
  },
  rowClickable: {
    type: Boolean,
    default: true,
  },
  store: {
    type: String,
    default: '',
  },
  action: {
    type: String,
    default: '',
  },
  sortBy: {
    type: String,
    default: null,
  },
  disableSort: {
    type: Boolean,
    default: false,
  },
  projectsType: {
    type: String as PropType<ProjectType | AssetCardTypes>,
    default: null,
  },
  parentEl: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  skeletonSize: {
    type: Number,
    default: 5,
  },
  skeletonColumns: {
    type: Number,
    default: 6,
  },
  rowsPerPageOptions: {
    type: Array as () => number[],
    default: () => [10, 25, 50, 100],
  },
  actionInProgress: {
    type: Boolean,
  },
  havePermissions: {
    type: Boolean,
  },
  concept: {
    type: [Object as PropType<Concept>, String] as PropType<Concept | string>,
    default: null,
  },
});

const emit = defineEmits([
  'updateData',
  'editUser',
  'copyEvidenceId',
  'downloadEvidenceFile',
  'deleteEvidenceFile',
  'navigateToControl',
  'navigateToRisk',
  'openPreview',
  'downloadAsset',
  'downloadVendorDocumentFile',
  'deleteVendorDocumentFile',
  'openTestDetails',
  'editInvitedUser',
]);

const router = useRouter();

let columns: QTableColumn[] = [];
let observer: MutationObserver | null = null;
const filter = ref('');
const authStore = useAuthStore();
const configStore = useConfigStore();
const projectsStore = useProjectsStore();
const evidenceStore = useEvidenceStore();
const dashboardStore = useDashboardStore();
const organizationStore = useOrganizationStore();
const vendorStore = useVendorStore();
const paginationStore = usePaginationStore();
const loading = ref(false);
const showRequestDialog = ref(false);
const projectRequestId = ref(null);
const openEvidenceActionDialog = ref(false);
const activeRow = ref<string | null>(null);
const isDeletting = ref(false);
let abortController = ref<AbortController | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowClick(evt: object, row: any, index: number) {
  if (!componentProps.rowClickable) return;

  if (componentProps.parentEl === 'EvidencePage') {
    emit('openPreview', row);
    return;
  }

  if (componentProps.parentEl === 'tabResults') {
    emit('openTestDetails', row);
    return;
  }

  if ('read_access' in row && !row.read_access) {
    showRequestDialog.value = true;

    if ('id' in row) {
      projectRequestId.value = row.id;
    }
    return;
  }

  const item = componentProps.data[index];

  if ('id' in item && !componentProps.routeQuery) {
    const id = item.id;
    router.push(componentProps.rowRoute + id);
  }

  if ('id' in item && componentProps.routeQuery) {
    const id = item.id;

    switch (componentProps.parentEl) {
      case 'OControls':
        if ('project' in item) {
          router.push({
            path: `/projects/project-detail/${item.project.id}/controls`,
            query: {
              [componentProps.routeQuery]: id,
            },
          });
        }
        break;
      case 'ORisks':
        if ('project' in item) {
          router.push({
            path: `/projects/project-detail/${item.project.id}/risk-detail/${id}`,
          });
        }
    }
  }
}

function isSortable(key: string) {
  switch (key) {
    case 'frameworks':
    case 'rpn_risk_level':
    case 'project_settings':
    case 'progress_percent':
    case 'created_by':
    case 'controls':
    case 'risks':
    case 'roles':
    case 'project':
    case 'residual_risk':
      return false;
    case 'status':
      return componentProps.routeQuery === 'control' ? true : false;
    default:
      return true;
  }
}

function isEuAiActRisk(project_setting: ProjectSetting) {
  return project_setting.name === 'EU AI Act Risk Classification';
}

function isAllowed(key: string, row: Data) {
  switch (key) {
    case 'project_settings':
    case 'ai_lifecycle_stage':
      if ('project_type' in row && row.project_type !== ProjectType.AiApplication) return false;
      return true;
    default:
      return true;
  }
}

function setColumns() {
  if (!componentProps.data || componentProps.data.length === 0) return;

  columns = [];
  const columnsNamesKeys = Object.keys(componentProps.columnsNames);
  let columnsToAdd: QTableColumn[] = [];

  for (let i = 0; i < componentProps.data.length; i++) {
    const keys = Object.keys(componentProps.data[i] as keyof typeof Object.keys);
    keys.forEach((key) => {
      if (columnsNamesKeys.includes(key) && !columns.some((column) => column.name === key)) {
        if (!isAllowed(key, componentProps.data[i])) return;
        columnsToAdd.push({
          name: key,
          required: true,
          label: componentProps.columnsNames[key],
          align: 'left',
          field: key,
          format: (val, data) => formatRowValue(val, key, data as ProjectAggregator),
          sortable: componentProps.disableSort ? false : isSortable(key),
          headerClasses: 'text-bold',
          classes: key === 'status' ? 'td-status' : key === 'frameworks' ? 'td-frameworks' : '',
          style: 'white-space: wrap',
        });
      }
    });
  }

  columnsNamesKeys.forEach((key) => {
    const columnToAdd = columnsToAdd.find((column) => column.name === key);
    if (columnToAdd) {
      columns.push(columnToAdd);
    }
  });

  if (columnsNamesKeys.includes('last_tested')) {
    columns.push({
      name: 'last_tested',
      required: true,
      label: 'Last Tested',
      align: 'center',
      field: 'last_tested',
      sortable: false,
    });
  }

  if (columnsNamesKeys.includes('download')) {
    columns.push({
      name: 'download',
      required: true,
      label: 'Download',
      align: 'center',
      field: 'download',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('revoke')) {
    columns.push({
      name: 'revoke',
      required: true,
      label: 'Action',
      align: 'center',
      field: 'revoke',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('edit')) {
    columns.push({
      name: 'edit',
      required: true,
      label: '',
      align: 'center',
      field: 'edit',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('evidence_actions') && componentProps.havePermissions) {
    columns.push({
      name: 'evidence_actions',
      required: true,
      label: '',
      align: 'center',
      field: 'evidence_actions',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('assets_actions')) {
    columns.push({
      name: 'assets_actions',
      required: true,
      label: '',
      align: 'center',
      field: 'assets_actions',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('vendor_actions')) {
    columns.push({
      name: 'vendor_actions',
      required: true,
      label: '',
      align: 'center',
      field: 'vendor_actions',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('org_users_actions')) {
    columns.push({
      name: 'org_users_actions',
      required: true,
      label: '',
      align: 'center',
      field: 'org_users_actions',
      sortable: false,
    });
  }
  if (columnsNamesKeys.includes('org_invited_users_actions')) {
    columns.push({
      name: 'org_invited_users_actions',
      required: true,
      label: '',
      align: 'center',
      field: 'org_invited_users_actions',
      sortable: false,
    });
  }
}

function formatRowValue(val: unknown, key: string, data: ProjectAggregator) {
  if (!val && typeof val !== 'boolean' && val !== 0) return '-';
  if (
    key === 'created_at' ||
    key === 'modified_at' ||
    key === 'expires_at' ||
    key === 'last_access' ||
    key === 'activated_at'
  ) {
    return formatDate(val as string);
  } else if (key === 'firstname') {
    if (!('firstname' in data) || !('lastname' in data)) return;

    return data.firstname + ' ' + data.lastname;
  } else if (key === 'status' && componentProps.parentEl !== 'vendorsOverview') {
    return val;
  } else if (key === 'status' && componentProps.parentEl === 'testingOverview') {
    return val ? 'Enabled' : 'Disabled';
  } else if (
    (key === 'assigned_to' && typeof val === 'string') ||
    (key === 'uploaded_by' && typeof val === 'string')
  ) {
    if (!organizationStore.usersData) return '-';

    const user = organizationStore.usersHashMap[val];

    return user ? user.firstname + ' ' + user.lastname : '-';
  } else if (key === 'size') {
    return FormatFileSize(val as number);
  }
  if (typeof val === 'object') {
    switch (key) {
      case 'created_by':
      case 'assigned_to':
        return (val as Owner).firstname + ' ' + (val as Owner).lastname;
      case 'projects':
        return (val as UserProject[]).map((obj) => `“${obj.name}”`).join(', ');
      case 'project':
        return (val as ProjectAggregator).name;
      default:
        return val;
    }
  }
  if (typeof val === 'number') {
    return val;
  }

  return typeof val === 'string'
    ? val.charAt(0).toUpperCase() + val.slice(1).replaceAll('_', ' ')
    : null;
}

async function downloadFile(props: tableProps) {
  if (!props.row.id) return;
  try {
    await evidenceStore.downloadFile(props.row.id, props.row.name);
  } catch (error) {
    captureException(error, {
      message: 'Component: OTable, Function: downloadFile',
      data: {
        id: props.row.id,
        name: props.row.name,
      },
    });
  }
}

async function deleteToken(params: tableProps) {
  try {
    isDeletting.value = true;
    await authStore.deleteToken({ tokenId: params.row.id });

    successMessage('Token deleted successfully');
  } catch (error) {
    captureException(error, {
      message: 'Component: OTable, Function: deleteToken',
      data: {
        tokenId: params.row.id,
      },
    });
  } finally {
    isDeletting.value = false;
  }
}

function editUser(props: tableProps) {
  const row = props.row as unknown as UserInOrgList;
  const user = {
    label: row.firstname + ' ' + row.lastname,
    value: row.id,
    id: row.id,
    firstname: row.firstname,
    lastname: row.lastname,
    roles: row.roles,
    email: row.email,
    is_org_admin: row.is_org_admin,
    is_active: row.is_active,
    job_title: row.job_title,
  };
  emit('editUser', user);
}

function openActions(id: string) {
  activeRow.value = id;
  openEvidenceActionDialog.value = true;
}

function closeActions() {
  activeRow.value = null;
  openEvidenceActionDialog.value = false;
}

const componentPagination = ref({
  sortBy: 'name',
  descending: false,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
});

function goToPage(pageNumber: number) {
  const scope = {
    pagination: {
      page: pageNumber,
      rowsPerPage: componentPagination.value.rowsPerPage,
    },
    filter: filter.value,
  };
  getMoreData(scope);
}

async function getMoreData(value: {
  pagination: TablePagination;
  filter?: string;
  getCellValue?: (col: unknown, row: unknown) => unknown;
}) {
  if (abortController.value) {
    abortController.value.abort();
  }

  abortController.value = new AbortController();
  const signal = abortController.value.signal;

  const newPagination = {
    page: value.pagination.page ?? 1,
    size: value.pagination.rowsPerPage ?? DEFAULT_PAGINATION_SIZE,
    name: value.filter !== '' ? value.filter : null,
    sortBy: value.pagination.sortBy ? value.pagination.sortBy : null,
    descending: value.pagination.descending ? value.pagination.descending : false,
  };
  loading.value = true;

  try {
    switch (componentProps.action) {
      case 'getProjects':
        await projectsStore.getProjects(
          {
            projectType: componentProps.projectsType as ProjectType,
            ...newPagination,
          },
          { signal },
        );
        return;
      case 'getControls':
        await dashboardStore.getControls(
          {
            controlSearch: componentProps.secondaryPagination.search,
            projectIdOfControls: componentProps.secondaryPagination.id,
            controlStatus: componentProps.secondaryPagination.status,
            assignedAs: componentProps.secondaryPagination.assignedAs,
            ...newPagination,
          },
          { signal },
        );
        return;
      case 'getDashboardRisks':
        await dashboardStore.getRisks(
          {
            riskSearch: componentProps.secondaryPagination.search,
            projectIdOfRisks: componentProps.secondaryPagination.id,
            riskStatus: componentProps.secondaryPagination.status,
            assignedAs: componentProps.secondaryPagination.assignedAs,
            ...newPagination,
          },
          { signal },
        );
        return;
      case 'getProjectsUsers':
        if (!projectsStore.project) return;

        await projectsStore.getProjectsUsers(
          {
            projectId: projectsStore.project.id,
            roles: paginationStore.usersRolesFilter || [],
            ...newPagination,
          },
          true,
          { signal },
        );
        return;
      case 'getOrganizationUsers':
        emit('updateData', value, signal);
        return;
      case 'getRisks':
        if (!projectsStore.project) return;

        await projectsStore.getProjectsRisks(
          {
            projectId: projectsStore.project.id,
            ...newPagination,
            status: componentProps.secondaryPagination.status,
          },
          { signal },
        );
        return;
      case 'getEvidences': {
        if (!projectsStore.project) return;
        await projectsStore.getProjectsEvidence(
          {
            projectId: projectsStore.project.id,
            ...newPagination,
            associatedControls: paginationStore.evidenceControlsFilter
              ? paginationStore.evidenceControlsFilter.map((control) => control.id)
              : [],
            associatedRisks: paginationStore.evidenceRisksFilter
              ? paginationStore.evidenceRisksFilter.map((risk) => risk.id)
              : [],
            fileType: paginationStore.evidenceTypeFilter
              ? paginationStore.evidenceTypeFilter
              : null,
            startDate: paginationStore.evidenceTimeFilter?.from || null,
            endDate: paginationStore.evidenceTimeFilter?.to || null,
          },
          { signal },
        );
        return;
      }
      case 'getAssets':
        if (!projectsStore.project) return;

        await projectsStore.getProjectAssets(
          {
            projectId: projectsStore.project.id,
            ...newPagination,
            assetCardType: paginationStore.assetTypeFilter,
            assetCardNature: paginationStore.assetNatureFilter,
          },
          { signal },
        );
        return;
      case 'getTests':
        if (!projectsStore.project) return;
        await projectsStore.getProjectTestingTests(
          {
            projectId: projectsStore.project.id,
            resultStatus: paginationStore.testResultFilter,
            status: paginationStore.testStatusFilter,
            ...newPagination,
          },
          { signal },
        );
        return;
      case 'getTestResults':
        if (!projectsStore.project || !projectsStore.currentTest) return;
        await projectsStore.getTestingTestResults(
          {
            projectId: projectsStore.project.id,
            testingTestId: projectsStore.currentTest.id,
            resultStatus: paginationStore.testResultFilter,
            ...newPagination,
          },
          { signal },
        );
        return;
      case 'getVendors':
        await vendorStore.getVendors(
          {
            ...newPagination,
            vendorType: paginationStore.vendorTypeFilter,
            vendorStatus: paginationStore.vendorStatusFilter,
            vendorRiskLevel: paginationStore.vendorRiskLevelFilter,
          },
          { signal },
        );
        return;
      case 'getVendorDocuments':
        if (!vendorStore.currentVendor) return;
        await vendorStore.getVendorDocumentsList(
          {
            vendorId: vendorStore.currentVendor.id,
            ...newPagination,
          },
          { signal },
        );
    }
  } catch (error) {
    captureException(error, {
      message: 'Component: OTable, Function: getMoreData',
      data: {
        action: componentProps.action,
        pagination: newPagination,
      },
    });
  } finally {
    loading.value = false;
  }
}

async function changePagination() {
  if (!componentProps.pagination) return;
  componentPagination.value.page = componentProps.pagination.page
    ? componentProps.pagination.page
    : 1;

  componentPagination.value.rowsPerPage = componentProps.pagination.size
    ? componentProps.pagination.size
    : DEFAULT_PAGINATION_SIZE;

  componentPagination.value.rowsNumber = componentProps.pagination.total
    ? componentProps.pagination.total
    : 0;

  componentPagination.value.sortBy =
    'sort_by' in componentProps.pagination && componentProps.pagination.sort_by
      ? (componentProps.pagination.sort_by as string)
      : componentProps.sortBy;

  componentPagination.value.descending =
    'descending' in componentProps.pagination
      ? (componentProps.pagination.descending ?? false)
      : false;
}
function uniqueRoles(rolesArray: Role[]): Role[] {
  const unique: Record<string, boolean> = {};
  const uniqueRoles: Role[] = [];

  rolesArray.forEach((roleObject) => {
    if (!unique[roleObject.role]) {
      unique[roleObject.role] = true;
      uniqueRoles.push(roleObject);
    }
  });

  return uniqueRoles;
}

function closeDialog() {
  showRequestDialog.value = false;
}

watch(
  () => componentProps.data,
  (newV) => {
    if (!newV) return;

    setColumns();
  },
  {
    immediate: true,
  },
);

watch(
  () => componentProps.pagination,
  (newV) => {
    if (!newV) return;

    changePagination();
  },
  {
    immediate: true,
  },
);

onMounted(() => {
  observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const noAccessElements = document.querySelectorAll('.no-access');
        noAccessElements.forEach((element) => {
          const trElement = (element.parentNode as HTMLElement).parentNode?.parentNode
            ?.parentNode as HTMLElement;
          trElement?.classList.add('tr-no-access');
        });
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div>
    <div v-if="searchFilter" class="search-filter__container row justify-between">
      <div class="row col-12 justify-between">
        <div class="row items-center">
          <q-input
            v-if="searchInput"
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="searchPlaceholder"
            :class="[isLoading ? 'skeleton' : '']"
            class="q-mr-md"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
          <slot name="header-filters" />
        </div>
        <slot name="header-button" />
      </div>
      <slot name="header-details" />
    </div>
    <q-table
      v-show="data && data.length > 0"
      v-model:pagination="componentPagination"
      :rows="data"
      :filter="filter"
      :columns="columns.slice(0, columnsToShow)"
      row-key="id"
      :hide-pagination="!showPagination"
      :rows-per-page-options="rowsPerPageOptions"
      :rows-per-page-label="`Showing ${data?.length} of ${pagination ? pagination.total : 0}`"
      :loading="loading"
      binary-state-sort
      @row-click="onRowClick"
      @request="getMoreData"
    >
      <template #body-cell-name="props">
        <NameCell :table-props="props" :parent-el="parentEl" :concept="concept" />
      </template>
      <template #body-cell-file_name="props">
        <FileNameCell :props="props" />
      </template>
      <template #body-cell-firstname="props">
        <q-td :props="props">
          <div class="row items-center">
            <span class="q-mr-md">{{ props.value }}</span>
            <div class="badges__container row">
              <q-badge
                v-if="props.row.is_org_admin"
                class="role-badge q-mr-sm q-my-sm"
                label="Admin"
              />
              <q-badge
                v-if="props.row.is_super_admin"
                class="role-badge q-my-sm"
                label="Super Admin"
              />
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-description="props">
        <q-td :props="props">
          <div class="row items-center">
            <span class="text-break"> {{ props.value }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td :props="props">
          <div>
            <span v-if="parentEl === 'vendorsOverview'">{{ props.value }}</span>
            <span v-if="parentEl === 'testingOverview'">{{
              props.value ? 'Enabled' : 'Disabled'
            }}</span>
            <q-badge
              v-if="parentEl === 'tabResults' && props.value && props.value.length > 0"
              class="status-badge"
              :label="
                props.value === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed
                  : props.value === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed
                    : TestingTestStatus.Error
              "
              :class="
                props.value === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed + '-badge'
                  : props.value === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed + '-badge'
                    : TestingTestStatus.Error + '-badge'
              "
            >
              <Icon
                :icon-name="
                  props.value === TestingTestStatus.Passed
                    ? 'testing-' + TestingTestStatus.Passed + '-active'
                    : props.value === TestingTestStatus.Failed
                      ? 'testing-' + TestingTestStatus.Failed + '-active'
                      : 'testing-' + TestingTestStatus.Error + '-active'
                "
                icon-folder="testing"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
            <q-badge
              v-if="
                parentEl !== 'tabResults' &&
                parentEl !== 'vendorsOverview' &&
                parentEl !== 'testingOverview'
              "
              class="status-badge"
              :label="STATUS_LABEL[props.value.toUpperCase() as keyof typeof STATUS_LABEL]"
              :class="props.value"
            >
              <Icon
                :icon-name="statusIcon(props.value.replaceAll(' ', '_').toLowerCase())"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </div>
        </q-td>
      </template>
      <template #body-cell-last_tested="props">
        <q-td :props="props">
          <div>
            <span
              >{{
                props.row.testing_test_results && props.row.testing_test_results.length > 0
                  ? dayjs.utc(props.row.testing_test_results[0].created_at).fromNow()
                  : '-'
              }}
            </span>
          </div>
        </q-td>
      </template>
      <template #body-cell-testing_test_results="props">
        <q-td :props="props">
          <div v-if="props.value && props.value.length > 0">
            <q-badge
              class="status-badge"
              :label="
                props.value[0].status === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed
                  : props.value[0].status === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed
                    : TestingTestStatus.Error
              "
              :class="
                props.value[0].status === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed + '-badge'
                  : props.value[0].status === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed + '-badge'
                    : TestingTestStatus.Error + '-badge'
              "
            >
              <Icon
                :icon-name="
                  props.value[0].status === TestingTestStatus.Passed
                    ? 'testing-' + TestingTestStatus.Passed + '-active'
                    : props.value[0].status === TestingTestStatus.Failed
                      ? 'testing-' + TestingTestStatus.Failed + '-active'
                      : 'testing-' + TestingTestStatus.Error + '-active'
                "
                icon-folder="testing"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </div>
          <span v-else>-</span>
        </q-td>
      </template>
      <template #body-cell-asset_card_type="props">
        <q-td :props="props">
          <div>
            <q-badge
              :class="props.value === 'Model card' ? 'model-badge' : 'dataset-badge'"
              :label="props.value"
            />
          </div>
        </q-td>
      </template>
      <template #body-cell-rpn_risk_level="props">
        <q-td :props="props">
          <div v-if="props.value !== '-'" class="row">
            <q-badge
              class="justify-center"
              :label="props.value || ''"
              :class="[
                RpnLevel(props.value)
                  ? (RpnLevel(props.value) || '').replaceAll(' ', '-').toLowerCase() + '-badge'
                  : '',
              ]"
            />
          </div>
          <span v-else>{{ '-' }}</span>
        </q-td>
      </template>
      <template #body-cell-risk_rpn_value="props">
        <q-td :props="props">
          <div v-if="props.value !== '-'" class="row items-center">
            <q-badge
              class="justify-center q-mr-md"
              :label="props.value || 0"
              :class="[
                RpnLevel(props.value)
                  ? (RpnLevel(props.value) || '').replaceAll(' ', '-').toLowerCase() + '-badge'
                  : '',
              ]"
            />
            <Icon
              v-if="
                projectsStore.project &&
                props.value <= (projectsStore.project.risk_threshold || DEFAULT_THRESHOLD)
              "
              icon-name="arrow-south"
              icon-folder="risks"
            />

            <Icon
              v-if="
                projectsStore.project &&
                props.value > (projectsStore.project.risk_threshold || DEFAULT_THRESHOLD)
              "
              icon-name="arrow-north"
              icon-folder="risks"
            />
          </div>
          <span v-else>{{ '-' }}</span>
        </q-td>
      </template>
      <template #body-cell-residual_risk="props">
        <q-td :props="props">
          <div v-if="props.value !== '-'" class="row items-center">
            <q-badge
              class="justify-center q-mr-md"
              :label="props.value || 0"
              :class="[
                RpnLevel(props.value)
                  ? (RpnLevel(props.value) || '').replaceAll(' ', '-').toLowerCase() + '-badge'
                  : '',
              ]"
            />
            <Icon
              v-if="
                projectsStore.project &&
                props.value <= (projectsStore.project.risk_threshold || DEFAULT_THRESHOLD)
              "
              icon-name="arrow-south"
              icon-folder="risks"
            />

            <Icon
              v-if="
                projectsStore.project &&
                props.value > (projectsStore.project.risk_threshold || DEFAULT_THRESHOLD)
              "
              icon-name="arrow-north"
              icon-folder="risks"
            />
          </div>
          <span v-else>{{ '-' }}</span>
        </q-td>
      </template>
      <template #body-cell-project_settings="props">
        <q-td :props="props">
          <div v-if="props.value && props.value.length > 0" class="row">
            <div v-for="setting in props.value" :key="setting.name">
              <q-badge
                v-if="isEuAiActRisk(setting) && setting.selected_option"
                class="classification-badge q-mr-sm q-mb-sm"
                :class="
                  setting.selected_option === 'Unacceptable Risk'
                    ? 'very-high-badge'
                    : setting.selected_option === 'High Risk'
                      ? 'high-badge'
                      : setting.selected_option === 'Limited Risk'
                        ? 'medium-badge'
                        : setting.selected_option === 'Minimal Risk'
                          ? 'very-low-badge'
                          : 'low-badge'
                "
                :label="setting.selected_option"
              />
              <span v-else>{{ '-' }}</span>
            </div>
          </div>
          <div v-else class="row">
            <span>{{ '-' }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-risk_level="props">
        <q-td :props="props">
          <div>
            <q-badge
              v-if="props.value !== '-'"
              :class="
                props.value.toLowerCase() === RISK_LEVEL_LABEL.VERY_HIGH.toLowerCase()
                  ? 'very-high-badge'
                  : props.value === RISK_LEVEL_LABEL.HIGH
                    ? 'high-badge'
                    : props.value === RISK_LEVEL_LABEL.MEDIUM
                      ? 'medium-badge'
                      : props.value.toLowerCase() === RISK_LEVEL_LABEL.VERY_LOW.toLowerCase()
                        ? 'very-low-badge'
                        : 'low-badge'
              "
              :label="props.value"
            />
            <span v-else>-</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-frameworks="props">
        <q-td :props="props">
          <div
            v-if="
              (props.value && parentEl === 'projectsPage') ||
              (props.value && parentEl === 'OControls')
            "
            class="row flags items-center"
          >
            <div v-for="framework in props.value" :key="framework.id" class="q-mr-sm q-mb-sm">
              <AFrameworkIcon :framework="framework" />
            </div>
          </div>
          <div v-else-if="props.value" class="row">
            <q-badge
              v-for="framework in props.value"
              :key="framework.id"
              class="framework-badge q-mr-sm q-mb-sm"
            >
              <span>{{ framework.framework_code }}</span>

              <q-tooltip anchor="top middle" self="bottom middle" class="project-tooltip">
                {{ `${framework.name}` }}
              </q-tooltip>
            </q-badge>
          </div>
        </q-td>
      </template>
      <template #body-cell-controls="props">
        <q-td :props="props">
          <div v-if="props.value && props.value.length > 0" class="row">
            <q-badge
              v-for="control in props.value"
              :key="control.id"
              :label="control.control_code"
              class="control-badge q-mr-sm q-mb-sm"
              @click="emit('navigateToControl', control.id)"
            >
              <Icon
                :icon-name="statusIcon(control.status)"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <q-tooltip anchor="top middle" self="bottom middle" class="project-tooltip">
                {{ `${control.control_code} ${control.name}` }}
              </q-tooltip>
            </q-badge>
          </div>
          <div v-else class="row">
            <span>{{ '-' }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-risks="props">
        <q-td :props="props">
          <div v-if="props.value && props.value.length > 0" class="row">
            <q-badge
              v-for="risk in props.value"
              :key="risk.id"
              class="risk-badge q-mr-sm q-mb-sm"
              @click="emit('navigateToRisk', risk.id)"
            >
              <Icon
                :icon-name="statusIcon(risk.status)"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <span>{{ risk.name }}</span>
              <q-tooltip anchor="top middle" self="bottom middle" class="project-tooltip">
                {{ risk.name }}
              </q-tooltip>
            </q-badge>
          </div>
          <div v-else class="row">
            <span>{{ '-' }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-annual_contract_value="props">
        <q-td :props="props">
          <div class="row">
            <span>{{
              props.value !== '-'
                ? OrganizationCurrency(
                    configStore.configData ? configStore.configData.general.currency : '',
                  ) +
                  ' ' +
                  FormatNumber(props.value)
                : props.value
            }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-ai_lifecycle_stage="props">
        <q-td :props="props">
          <div v-if="props.value !== '-'" class="row">
            <q-badge class="lifecycle-badge" :label="props.value" />
          </div>
          <span v-else>
            {{ '-' }}
          </span>
        </q-td>
      </template>
      <template #body-cell-tag_value="props">
        <q-td :props="props">
          <div>
            <q-badge v-if="props.value" class="tag-badge" :label="props.value" />
          </div>
        </q-td>
      </template>
      <template #body-cell-roles="props">
        <q-td :props="props">
          <div v-if="props.value">
            <q-badge
              v-for="user in uniqueRoles(props.value)"
              :key="user.id"
              class="role-badge q-mr-sm q-mb-sm"
              :label="user.role"
            >
              <ATooltip
                icon-folder="settings"
                icon-name="info"
                :tooltip-text="roleDescription(user.role)"
                class="q-ml-sm"
              />
            </q-badge>
          </div>
        </q-td>
      </template>
      <template #body-cell-value="props">
        <q-td :props="props">
          <div class="row items-center">
            <span>
              {{
                props.value !== '-'
                  ? OrganizationCurrency(
                      configStore.configData ? configStore.configData.general.currency : '',
                    ) +
                    ' ' +
                    FormatNumber(props.value)
                  : props.value
              }}
              <q-tooltip
                v-if="props.value !== '-'"
                anchor="top middle"
                self="center middle"
                class="project-tooltip"
              >
                {{
                  OrganizationCurrency(
                    configStore.configData ? configStore.configData.general.currency : '',
                  ) +
                  ' ' +
                  props.value
                }}
              </q-tooltip>
            </span>
          </div>
        </q-td>
      </template>
      <template #body-cell-review_date="props">
        <q-td :props="props">
          <div class="row items-center">
            <span>{{ props.value !== '-' ? formatDate(props.value) : '-' }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-is_active="props">
        <IsActiveCell :props="props" />
      </template>
      <template #body-cell-download="props">
        <q-td :props="props">
          <div>
            <q-btn
              class="gt-xs"
              size="12px"
              flat
              dense
              round
              icon="download"
              :loading="evidenceStore.isLoading"
              :disable="evidenceStore.isLoading"
              @click="downloadFile(props)"
            />
          </div>
        </q-td>
      </template>
      <template #body-cell-revoke="props">
        <q-td :props="props">
          <div class="row justify-center">
            <q-btn
              class="gt-xs revoke-button"
              size="12px"
              flat
              dense
              round
              label="Revoke"
              :loading="isDeletting"
              :disable="isDeletting"
              @click="deleteToken(props)"
            >
              <Icon icon-name="delete" icon-folder="colored" />
            </q-btn>
          </div>
        </q-td>
      </template>
      <template #body-cell-edit="props">
        <q-td :props="props">
          <div class="row">
            <q-btn
              class="gt-xs edit-button"
              size="12px"
              flat
              dense
              round
              label=""
              @click.stop="editUser(props)"
            >
              <Icon icon-name="edit" icon-folder="settings" />
            </q-btn>
          </div>
        </q-td>
      </template>
      <template #body-cell-progress_percent="props">
        <q-td :props="props">
          <div class="row items-center">
            <q-linear-progress rounded size="6px" :value="props.value" />
            <span class="q-ml-sm">{{ (props.value * 100).toFixed(2) }}%</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-evidence_actions="props">
        <q-td :props="props">
          <Icon
            icon-name="more_vert"
            icon-folder="colored"
            class="actions-button cursor-pointer"
            @click.stop.prevent="openActions(props.row.id)"
          />
          <div
            v-if="openEvidenceActionDialog && activeRow === props.row.id"
            v-on-click-outside="closeActions"
            class="actions"
          >
            <div
              class="row action__container items-end q-mb-md"
              @click.stop.prevent="emit('copyEvidenceId', props.row.id)"
            >
              <Icon icon-name="content_copy" icon-folder="evidence" class="q-mr-sm" />
              <span>Copy link</span>
            </div>
            <div
              class="row action__container items-end q-mb-md"
              @click.stop.prevent="emit('openPreview', props.row)"
            >
              <Icon icon-name="remove_red_eye" icon-folder="evidence" class="q-mr-sm" />
              <span>Preview</span>
            </div>
            <div
              class="row action__container items-end q-mb-md"
              @click.stop.prevent="emit('downloadEvidenceFile', props.row.id, props.row.file_name)"
            >
              <Icon icon-name="file_download" icon-folder="evidence" class="q-mr-sm" />
              <span>Download</span>
            </div>
            <div
              class="row action__container items-end"
              :class="[props.row.is_locked ? 'disabled' : '']"
              @click.stop.prevent="
                props.row.is_locked ? null : emit('deleteEvidenceFile', props.row)
              "
            >
              <Icon icon-name="delete-table" icon-folder="evidence" class="q-mr-sm" />
              <span>Delete</span>
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-assets_actions="props">
        <q-td :props="props">
          <Icon
            icon-name="more_vert"
            icon-folder="colored"
            class="actions-button cursor-pointer"
            @click.stop.prevent="openActions(props.row.id)"
          />
          <div
            v-if="openEvidenceActionDialog && activeRow === props.row.id"
            v-on-click-outside="closeActions"
            class="actions"
          >
            <div
              class="row action__container items-end"
              @click.stop.prevent="emit('downloadAsset', props.row)"
            >
              <Icon icon-name="file_download" icon-folder="evidence" class="q-mr-sm" />
              <span>Download</span>
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-vendor_actions="props">
        <q-td :props="props">
          <Icon
            icon-name="more_vert"
            icon-folder="colored"
            class="actions-button cursor-pointer"
            @click.stop.prevent="openActions(props.row.id)"
          />
          <div
            v-if="openEvidenceActionDialog && activeRow === props.row.id"
            v-on-click-outside="closeActions"
            class="actions"
          >
            <div
              class="row action__container items-end q-mb-md"
              @click.stop.prevent="
                actionInProgress
                  ? null
                  : emit('downloadVendorDocumentFile', props.row.id, props.row.name)
              "
            >
              <Icon icon-name="file_download" icon-folder="evidence" class="q-mr-sm" />
              <span>Download</span>
            </div>
            <div
              class="row action__container items-end"
              :class="[props.row.is_locked ? 'disabled' : '']"
              @click.stop.prevent="
                props.row.is_locked || actionInProgress
                  ? null
                  : emit('deleteVendorDocumentFile', props.row.id)
              "
            >
              <Icon icon-name="delete-table" icon-folder="evidence" class="q-mr-sm" />
              <span>Delete</span>
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-org_users_actions="props">
        <q-td :props="props">
          <div class="row items-center justify-end">
            <q-btn
              class="gt-xs edit-button"
              size="12px"
              flat
              dense
              round
              label=""
              @click.stop="
                props.row.state !== UserState.Invited
                  ? editUser(props)
                  : emit('editInvitedUser', props.row)
              "
            >
              <Icon icon-name="edit" icon-folder="settings" />
            </q-btn>
          </div>
        </q-td>
      </template>
      <template #pagination="scope">
        <q-btn
          icon="chevron_left"
          color="grey-8"
          square
          dense
          flat
          class="chevron-button"
          :disable="scope.isFirstPage"
          @click="scope.prevPage"
        />
        <!-- Show ellipsis and first page button -->
        <template v-if="scope.pagesNumber > 5 && scope.pagination.page > 3">
          <q-btn dense flat color="primary" @click="goToPage(1)">1</q-btn>
          <q-btn dense flat>...</q-btn>
        </template>

        <!-- Show numbered buttons -->
        <template v-if="scope.pagesNumber <= 5">
          <template v-for="page in scope.pagesNumber" :key="page">
            <q-btn
              dense
              flat
              class="button-page-number 1"
              :class="page === scope.pagination.page ? 'button-page-number--active' : ''"
              @click="goToPage(page)"
            >
              {{ page }}
            </q-btn>
          </template>
        </template>
        <template v-else>
          <template v-if="scope.pagination.page <= 3">
            <template v-for="page in 5" :key="page">
              <q-btn
                dense
                flat
                class="button-page-number 2"
                :class="page === scope.pagination.page ? 'button-page-number--active' : ''"
                @click="goToPage(page)"
              >
                {{ page }}
              </q-btn>
            </template>
          </template>
          <template v-else-if="scope.pagination.page >= scope.pagesNumber - 2">
            <template v-for="page in 5" :key="page">
              <q-btn
                dense
                flat
                class="button-page-number 3"
                :class="
                  page === Math.abs(scope.pagesNumber - scope.pagination.page - 5)
                    ? 'button-page-number--active'
                    : ''
                "
                @click="goToPage(scope.pagesNumber - 5 + page)"
              >
                {{ scope.pagesNumber - 5 + page }}
              </q-btn>
            </template>
          </template>
          <template v-else>
            <template v-for="page in 5" :key="page">
              <q-btn
                dense
                flat
                class="button-page-number 4"
                :class="page === 3 ? 'button-page-number--active' : ''"
                @click="goToPage(scope.pagination.page - 3 + page)"
              >
                {{ scope.pagination.page - 3 + page }}
              </q-btn>
            </template>
          </template>
        </template>

        <!-- Show ellipsis and last page button -->
        <template v-if="scope.pagesNumber > 5 && scope.pagination.page < scope.pagesNumber - 2">
          <q-btn dense flat>...</q-btn>
          <q-btn dense flat color="primary" @click="goToPage(scope.pagesNumber)">{{
            scope.pagesNumber
          }}</q-btn>
        </template>

        <q-btn
          icon="chevron_right"
          color="grey-8"
          dense
          flat
          class="chevron-button"
          :disable="scope.isLastPage"
          @click="scope.nextPage"
        />
      </template>
      <template #bottom-row>
        <tr class="no-hover">
          <td colspan="100%" class="no-padding">
            <slot name="custom-bottom" />
          </td>
        </tr>
      </template>
      <template #loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>
    <STable v-if="isLoading" :skeleton-size="skeletonSize" :skeleton-columns="skeletonColumns" />
    <ADialog
      :show-dialog="showRequestDialog"
      max-height="auto !important"
      max-width="490px !important"
      min-height="auto !important"
      min-width="490px !important"
      @hide="closeDialog"
    >
      <DRequestAccess
        v-if="projectRequestId"
        :project-request-id="projectRequestId"
        @close-dialog="closeDialog"
      />
    </ADialog>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.search-filter__container {
  padding: 20px;
  background: $white;
  border-radius: 5px;

  :deep(button) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
  :deep(a) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }

  :deep(.q-date) {
    button {
      background: unset !important;
      text-transform: none;
    }
    a {
      background: unset !important;
      text-transform: none;
    }
  }
}

:deep(.q-table__container) {
  padding: 20px;
  border-radius: 5px;
}
:deep(.q-table__card) {
  box-shadow: none !important;
}
:deep(.q-table--horizontal-separator thead th) {
  border-bottom: 1px solid #f0f0f0 !important;
}
:deep(.text-bold) {
  color: $common-4;
  font-size: 14px !important;
}

:deep(thead tr) {
  background: $secondary-50;
  th:first-child {
    border-top-left-radius: 5px;
  }
  th:last-child {
    border-top-right-radius: 5px;
  }
  th {
    @include paragraph-01(600, $common-5);
  }
}
:deep(tbody tr) {
  position: relative !important;
}

:deep(tbody td) {
  @include paragraph-01(400, $common-5);
  max-width: 200px;
  padding: 17px;
  position: unset !important;
  white-space: normal;

  .control-badge,
  .risk-badge {
    max-width: 100px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions-button {
    width: fit-content;
  }
  .actions-button:hover {
    background: $secondary-200;
    border-radius: 50%;
  }

  .actions {
    width: 140px;
    box-shadow:
      0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: $white;
    padding: 4px 0;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    .action__container {
      padding: 6px 12px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: $common-4;
      }
    }
    .action__container:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
    .disabled {
      cursor: not-allowed;
      i {
        color: $common-1;
      }
    }
  }

  .q-linear-progress {
    width: 75px;
    color: #62a6f9 !important;
  }
}

:deep(tbody tr:hover) {
  cursor: pointer;
  background-color: $secondary-100;
  td::before {
    display: none;
  }
}
:deep(tbody .no-hover:hover) {
  cursor: default;
  background-color: $white;
  td::before {
    display: none;
  }
}

:deep(.q-table__bottom) {
  border: none;
  button {
    @include paragraph-01(400, $common-4);
    height: 35px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 4px 12px;
    margin: 0 4px;
    color: $common-4 !important;
    i {
      color: $common-4;
    }
  }
  .chevron-button {
    border: 1px solid $common-1;
    padding: 0 4px;
  }

  .button-page-number--active {
    color: $white !important;
    background: $secondary-500;
  }
  .disabled {
    cursor: not-allowed;
    i {
      color: $common-1;
    }
  }
  .button-page-number {
    padding: 4px 12px;
  }
  .q-table__control:nth-child(2) {
    position: absolute;
    left: 20px;
    .q-table__bottom-item {
      @include paragraph-01(400, $common-2);
    }
    .q-field__inner {
      padding: 0px 12px;
      border-radius: 4px;
      border: 1px solid $common-1;
    }
  }
}
.revoke-button {
  padding: 10px 15px !important;
  border: 1px solid $secondary-500 !important;
  border-radius: 5px !important;
  :deep(.q-btn__content) {
    flex-direction: row-reverse;
    span {
      @include paragraph-01(600, $secondary-500);
      margin-left: 10px;
      text-transform: none;
    }
  }
}
.edit-button {
  padding: 8px !important;
  border: none !important;
  border-radius: 5px !important;
  background: $secondary-200;
}
.overview-button {
  padding: 8px !important;
  border: 1px solid $secondary-500 !important;
  border-radius: 5px !important;
  background: transparent;
  color: $secondary-500;
  text-transform: none;
}
.name__wrapp {
  .name__container {
    width: 100%;

    .name-text {
      font-size: 14px;
      color: $common-5;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .control-code {
      @include caption(400, $common-5);
    }
  }
}

:deep(.tr-no-access) {
  background: $secondary-100;
}
.no-padding {
  padding: 0 !important;
}
.flags {
  .flag {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    background-size: cover;
  }
  :deep(img) {
    min-width: 28px;
    min-height: 28px;
    max-width: 28px;
    max-height: 28px;
    width: auto;
    height: auto;
    display: block;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  }
}
</style>
