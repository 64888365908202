<script setup lang="ts">
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';

import { VendorRiskLevel, VendorStatus, VendorType, type User } from '@/client/api';
import type { QInput, QSelect } from 'quasar';

import { useConfigStore } from '@/stores/ConfigStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useVendorStore } from '@/stores/VendorStore';

import { OrganizationCurrency } from '@/composables/Config';
import { RISK_LEVEL_LABEL } from '@/composables/CRisk';
import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_DESCRIPTION_SIZE, MAX_NAME_SIZE, ValidateURL, formatDate } from '@/composables/utils';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import ADatePicker from '@/components/atoms/ADatePicker.vue';
import ASelectSearch from '@/components/atoms/ASelectSearch.vue';
import Icon from '@/components/atoms/Icon.vue';

import countriesList from '@/utils/countries.json';

const emit = defineEmits(['closeDialog', 'updateVendor']);

interface typeSelect {
  value: VendorType | null;
  label: string;
}

interface statusSelect {
  value: VendorStatus;
  label: string;
}

interface riskSelect {
  value: VendorRiskLevel | null;
  label: string;
}

interface userSelect {
  value: string;
  label: string;
}

interface countrySelect {
  value: string;
  label: string;
}

interface SelectComponent {
  selectValue: countrySelect | null;
}

const vendorStore = useVendorStore();
const organizationStore = useOrganizationStore();
const configStore = useConfigStore();
const name = ref('');
const type = ref<typeSelect | null>(null);
const status = ref<statusSelect>({
  value: VendorStatus.InReview,
  label: 'In Review',
});
const riskLevel = ref<riskSelect | null>(null);
const description = ref('');
const websiteUrl = ref('');
const street = ref('');
const streetNumber = ref<string>('');
const postalCode = ref('');
const city = ref('');
const state = ref('');
const country = ref<countrySelect | null>(null);
const responsiblePerson = ref<userSelect | null>(null);
const reviewDate = ref<string | null>(null);
const contractValue = ref<number | null>(null);
const privacyValue = ref<string>('');
const termsValue = ref<string>('');
const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);
const websiteUrlInput = ref<QInput | null>(null);
const addressInput = ref<QInput | null>(null);
const streetInput = ref<QInput | null>(null);
const postalInput = ref<QInput | null>(null);
const cityInput = ref<QInput | null>(null);
const stateInput = ref<QInput | null>(null);
const privacyInput = ref<QInput | null>(null);
const termsInput = ref<QInput | null>(null);
const statusInput = ref<QSelect | null>(null);
const userInput = ref<QSelect | null>(null);
const countryComponent = ref<SelectComponent | null>(null);
const selected = ref(false);
const isCreating = ref(false);

const typeOptions = ref([
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Supplier',
    value: VendorType.Supplier,
  },
  {
    label: 'Contractor',
    value: VendorType.Contractor,
  },
  {
    label: 'Partner',
    value: VendorType.Partner,
  },
  {
    label: 'Data Source',
    value: VendorType.DataSource,
  },
  {
    label: 'Other',
    value: VendorType.Other,
  },
]);

const statusOptions = [
  {
    value: VendorStatus.Active,
    label: 'Active',
  },
  {
    value: VendorStatus.InReview,
    label: 'In Review',
  },
  {
    value: VendorStatus.Rejected,
    label: 'Rejected',
  },
];

const riskLevelOptions = [
  {
    value: null,
    label: 'Unset',
  },
  {
    value: VendorRiskLevel.VeryLow,
    label: RISK_LEVEL_LABEL.VERY_LOW,
  },
  {
    value: VendorRiskLevel.Low,
    label: RISK_LEVEL_LABEL.LOW,
  },
  {
    value: VendorRiskLevel.Medium,
    label: RISK_LEVEL_LABEL.MEDIUM,
  },
  {
    value: VendorRiskLevel.High,
    label: RISK_LEVEL_LABEL.HIGH,
  },
  {
    value: VendorRiskLevel.VeryHigh,
    label: RISK_LEVEL_LABEL.VERY_HIGH,
  },
];

const disableSave = ref(true);

function onReset() {
  name.value = '';
}

const isNameValid = computed(() => nameInput.value?.validate());
const isDescriptionValid = computed(() => descriptionInput.value?.validate());
const isWebsiteUrlValid = computed(() => websiteUrlInput.value?.validate());
const isAddressValid = computed(() => addressInput.value?.validate());
const isStreetValid = computed(() => streetInput.value?.validate());
const isPostalValid = computed(() => postalInput.value?.validate());
const isCityValid = computed(() => cityInput.value?.validate());
const isStateValid = computed(() => stateInput.value?.validate());
const isPrivacyValid = computed(() => privacyInput.value?.validate());
const isTermsValid = computed(() => termsInput.value?.validate());
const isStatusValid = computed(() => statusInput.value?.validate());
const isUserValid = computed(() => userInput.value?.validate());

const debouncedValidation = debounce(() => {
  validate();
}, 100);

function validate() {
  if (
    isNameValid.value &&
    isDescriptionValid.value &&
    isWebsiteUrlValid.value &&
    isAddressValid.value &&
    isStreetValid.value &&
    isPostalValid.value &&
    isCityValid.value &&
    isStateValid.value &&
    isPrivacyValid.value &&
    isTermsValid.value &&
    isStatusValid.value &&
    isUserValid.value
  ) {
    disableSave.value = false;
  } else {
    disableSave.value = true;
  }
}

async function fetchUsers() {
  if (!organizationStore.usersData) return;
  if (organizationStore.usersData.items.length! < organizationStore.usersData.total!) {
    try {
      await organizationStore.getOrganizationUsers({
        ...DEFAULT_PAGINATION,
        size: organizationStore.usersData.size! + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, {
        message: 'Component: DAddUser, Function: fetchUsers',
      });
    }
  }
}

function selectCountry(value: countrySelect) {
  if (!value) {
    country.value = null;
    return;
  }

  const selectedCountry = countriesList.find((country) => country.code === value.value);
  country.value = selectedCountry
    ? { value: selectedCountry.code, label: selectedCountry.name }
    : null;
}

function setDateFilter(date: string) {
  reviewDate.value = date;
}

function setData() {
  if (!vendorStore.currentVendor) return;

  name.value = vendorStore.currentVendor.name;
  type.value =
    typeOptions.value.find((type) => type.value === vendorStore.currentVendor?.type) || null;
  status.value =
    statusOptions.find((status) => status.value === vendorStore.currentVendor?.status) ||
    statusOptions[1];
  riskLevel.value =
    riskLevelOptions.find((risk) => risk.value === vendorStore.currentVendor?.risk_level) || null;
  description.value = vendorStore.currentVendor.description || '';
  websiteUrl.value = vendorStore.currentVendor.website_url || '';

  reviewDate.value = vendorStore.currentVendor.review_date
    ? formatDate(vendorStore.currentVendor.review_date, false)
    : '';
  contractValue.value = vendorStore.currentVendor.annual_contract_value;
  selected.value = vendorStore.currentVendor.is_subprocessor;
  privacyValue.value = vendorStore.currentVendor.privacy_policy_url || '';
  termsValue.value = vendorStore.currentVendor.terms_of_service_url || '';

  const user = organizationStore.usersHashMap[vendorStore.currentVendor?.assigned_to];

  responsiblePerson.value = {
    value: user ? user.id : '',
    label: user ? user.firstname + ' ' + user.lastname : '',
  };

  if (!vendorStore.currentVendor.address) return;

  street.value = vendorStore.currentVendor.address.street_name;
  streetNumber.value = vendorStore.currentVendor.address.number;
  postalCode.value = vendorStore.currentVendor.address.zip_code;
  city.value = vendorStore.currentVendor.address.city;
  state.value = vendorStore.currentVendor.address.state;

  const filterCountry = countriesList.find(
    (country) => country.code === vendorStore.currentVendor?.address?.country,
  );

  if (!countryComponent.value) return;

  countryComponent.value.selectValue = filterCountry
    ? { value: filterCountry.code, label: filterCountry.name }
    : null;
  country.value = filterCountry ? { value: filterCountry.code, label: filterCountry.name } : null;

  debouncedValidation();
}

async function updateVendor() {
  if (!name.value || !responsiblePerson.value || !status.value || !vendorStore.currentVendor)
    return;
  const params = {
    vendorId: vendorStore.currentVendor.id,
    vendorData: {
      name: name.value,
      type: type.value ? type.value.value : null,
      status: status.value.value,
      risk_level: riskLevel.value ? riskLevel.value.value : null,
      description: description.value,
      website_url: websiteUrl.value,
      assigned_to: responsiblePerson.value.value,
      review_date: reviewDate.value || null,
      annual_contract_value: contractValue.value || null,
      is_subprocessor: selected.value,
      privacy_policy_url: privacyValue.value,
      terms_of_service_url: termsValue.value,
      address: {
        street_name: street.value,
        number: streetNumber.value,
        zip_code: postalCode.value,
        city: city.value,
        state: state.value,
        country: country.value ? country.value.value : '',
      },
    },
  };

  try {
    isCreating.value = true;
    await vendorStore.updateVendor(params);

    successMessage('Vendor updated successfully');
    emit('updateVendor');
    emit('closeDialog');
  } catch (error) {
    errorMessage('Error updating vendor');
    captureException(error, {
      message: 'Component: DEditVendor, Function: updateVendor',
      data: params,
    });
  } finally {
    isCreating.value = false;
  }
}

onMounted(async () => {
  try {
    setData();

    await organizationStore.getOrganizationUsers({
      ...DEFAULT_PAGINATION,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: DEditVendor, Hook: onMounted, Method: getOrganizationUsers',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="storefront" icon-folder="vendors" class="q-mr-sm" />
      <h5 class="q-ma-none">Edit Vendor</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="section general__section row q-mb-md">
      <span class="section__title q-mb-lg">General</span>
      <q-form
        class="row col-12 q-col-gutter-sm items-start"
        @submit.prevent="updateVendor"
        @reset="onReset"
      >
        <div class="row col-6">
          <span class="q-mb-sm">Vendor Name*</span>
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            class="col-12"
            placeholder="Insert Name"
            :rules="[
              (val: string) => (val && val.length > 0) || 'Please type something',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="row col-6">
          <span class="q-mb-sm">Type</span>
          <q-select
            v-model="type"
            outlined
            label="Please Select"
            :options="typeOptions"
            dense
            class="col-12"
          />
        </div>
        <div class="row col-12">
          <span class="q-mb-sm">Website URL</span>
          <q-input
            ref="websiteUrlInput"
            v-model="websiteUrl"
            outlined
            dense
            type="url"
            class="col-12"
            placeholder="Insert"
            :rules="[
              ValidateURL,
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="row col-12">
          <span class="col-12 q-mb-sm">Description</span>
          <q-input
            ref="descriptionInput"
            v-model="description"
            outlined
            placeholder="Insert description"
            no-error-icon
            dense
            class="col-12"
            type="textarea"
            input-style="height: 90px"
            :rules="[
              (val: string) =>
                val.length <= MAX_DESCRIPTION_SIZE ||
                `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="row col-12 q-col-gutter-sm">
          <span class="col-12 q-mb-sm">Address</span>
          <q-input
            ref="addressInput"
            v-model="street"
            outlined
            placeholder="Street Number"
            no-error-icon
            dense
            class="col-9"
            :rules="[
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
          <q-input
            ref="streetInput"
            v-model="streetNumber"
            outlined
            placeholder="Number"
            no-error-icon
            dense
            class="col-3"
            :rules="[
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
          <q-input
            ref="postalInput"
            v-model="postalCode"
            outlined
            placeholder="Postal Code"
            no-error-icon
            dense
            class="col-6"
            :rules="[
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
          <q-input
            ref="cityInput"
            v-model="city"
            outlined
            placeholder="City"
            no-error-icon
            dense
            class="col-6"
            :rules="[
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
          <q-input
            ref="stateInput"
            v-model="state"
            outlined
            placeholder="State"
            no-error-icon
            dense
            class="col-6"
            :rules="[
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
          <ASelectSearch
            ref="countryComponent"
            :options="
              countriesList.map((item) => ({
                value: item.code,
                label: item.name,
              }))
            "
            class="select-search col-6"
            label="Country"
            @update-select="selectCountry"
          />
        </div>
      </q-form>
    </div>
    <div class="section assesment_section row q-mb-md">
      <span class="section__title q-mb-lg">Assessment</span>
      <q-form
        class="row col-12 q-col-gutter-sm items-start"
        @submit.prevent="updateVendor"
        @reset="onReset"
      >
        <div class="row col-12">
          <span class="q-mb-sm col-12">Responsible Person*</span>
          <q-select
            v-if="organizationStore.usersData"
            ref="userInput"
            v-model="responsiblePerson"
            outlined
            label="Select an User"
            :options="
              organizationStore.usersData.items.map((user: User) => ({
                label: user.firstname + ' ' + user.lastname,
                value: user.id,
              }))
            "
            :disable="organizationStore.usersData.items.length === 0"
            dense
            class="col-12"
            :rules="[(val: string) => val || 'Please type something']"
            @virtual-scroll="fetchUsers"
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="row col-6">
          <span class="col-12 q-mb-sm">Status*</span>
          <q-select
            ref="statusInput"
            v-model="status"
            outlined
            label="Please Select"
            :options="statusOptions"
            dense
            class="col-12"
            :rules="[(val: string) => val || 'Please Select something']"
            @update-model-value="debouncedValidation"
          />
        </div>
        <div class="row col-6">
          <span class="col-12 q-mb-sm">Risk Level</span>
          <q-select
            v-model="riskLevel"
            outlined
            label="Please Select"
            :options="riskLevelOptions"
            dense
            class="col-12"
          />
        </div>
        <div class="row col-6">
          <span class="col-12 q-mb-sm">Review Date</span>
          <ADatePicker
            class="col-12"
            :time="reviewDate || ''"
            :position-fixed="false"
            :enable-range="false"
            @set-date-filter="setDateFilter"
          />
        </div>
        <div class="row col-6">
          <span class="col-12 q-mb-sm">Annual Contract Value</span>
          <q-input
            v-model="contractValue"
            outlined
            type="number"
            :placeholder="`${OrganizationCurrency(
              configStore.configData ? configStore.configData.general.currency : '',
            )} Insert Value`"
            no-error-icon
            dense
            class="col-12"
          />
        </div>
        <div class="row col-12 items-center q-mb-md">
          <q-checkbox v-model="selected" size="16px" />
          <span class="role__text q-mx-sm">Subprocessor</span>
        </div>
        <div class="row col-12">
          <span class="col-12 q-mb-sm">Privacy Policy URL</span>
          <q-input
            ref="privacyInput"
            v-model="privacyValue"
            outlined
            placeholder="ex: https://aws.amazon.com/privacy"
            no-error-icon
            dense
            class="col-12"
            :rules="[
              ValidateURL,
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="row col-12">
          <span class="col-12 q-mb-sm">Terms of Service URL</span>
          <q-input
            ref="termsInput"
            v-model="termsValue"
            outlined
            placeholder="ex: https://aws.amazon.com/terms"
            no-error-icon
            dense
            class="col-12"
            :rules="[
              ValidateURL,
              (val: string) =>
                val.length <= MAX_NAME_SIZE || `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
      </q-form>
    </div>
    <div class="row action__row full-width justify-between q-mt-sm">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[disableSave ? 'disable' : '']"
        :loading="isCreating"
        :disable="disableSave || isCreating"
        @click="updateVendor"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  background: $secondary-50;
  span {
    @include caption(400, $secondary-500);
  }

  .text-bold {
    font-size: 16px;
    font-weight: 400;
    color: $secondary-600;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .disabled {
    opacity: 0.5;
  }

  .section {
    padding: 20px;
    background: $white;
    border-radius: 5px;
    .section__title {
      color: $secondary-600;
      font-size: 14px;
      font-weight: 600;
    }
    .q-checkbox {
      :deep(.q-checkbox__bg) {
        width: 100%;
        height: 100%;
        position: unset;
      }

      :deep(.q-checkbox__inner--falsy) {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      :deep(.q-checkbox__inner--truthy) {
        .q-checkbox__bg {
          color: $secondary-800;
        }
      }
      :deep(.q-checkbox__label) {
        @include paragraph-02(600, $common-5);
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }

  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field--float) {
      .q-field__label {
        transform: translateY(-45%) scale(0.75);
      }
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, #262626);
    }
    .select-search {
      height: 40px;
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
  :deep(.date-picker) {
    background: $white;
  }
}
</style>
