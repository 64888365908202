<script setup lang="ts">
import { debounce } from 'lodash';
import { ref, watch } from 'vue';

import type { QInput } from 'quasar';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';
import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_DESCRIPTION_SIZE } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  update: {
    type: String,
    required: true,
  },
});

const descriptionModel = ref('');
const descriptionInput = ref<QInput | null>(null);
const validationPassed = ref(true);
const readonly = ref(true);
const projectsStore = useProjectsStore();

const debouncedValidation = debounce(() => {
  if (!descriptionInput.value) return;
  const isNameValid = descriptionInput.value.validate();

  if (isNameValid) {
    validationPassed.value = true;
  } else {
    validationPassed.value = false;
  }
}, 100);

async function updateDescription() {
  if (readonly.value) return;
  readonly.value = true;

  if (!validationPassed.value || props.description === descriptionModel.value) {
    descriptionModel.value;
    return;
  }

  if (!projectsStore.project) return;

  try {
    let data;
    switch (props.update) {
      case 'project':
        data = await projectsStore.updateProject({
          projectId: projectsStore.project.id,
          updateProject: {
            description: descriptionModel.value,
          },
        });
        successMessage(`Project '${data?.name ?? ''}' was updated`);

        break;
    }
  } catch (error) {
    errorMessage('Failed to update description');

    captureException(error, {
      message: 'Component: APageDescription, Function: updateDescription',
      data: {
        description: descriptionModel.value,
      },
    });
  }
}

function disableReadonly() {
  readonly.value = false;
}

watch(
  () => props.description,
  (newValue) => {
    if (!newValue) return;

    descriptionModel.value = props.description;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="content columnq q-mt-lg">
    <div v-if="readonly" class="read-only__container row items-center">
      <span class="q-mr-md text-break">{{
        descriptionModel !== '' ? descriptionModel : 'Type the description'
      }}</span>
      <Icon
        v-if="isProjectOwner() || isAdmin()"
        icon-name="icon-edit"
        icon-folder="colored"
        @click="disableReadonly"
      />
    </div>
    <div v-if="!readonly" class="editable__container full-width row justify-end">
      <q-input
        ref="descriptionInput"
        v-model="descriptionModel"
        outlined
        placeholder="Type the description"
        no-error-icon
        autogrow
        dense
        :readonly="readonly"
        type="textarea"
        :rules="[
          (val: string) =>
            val.length <= MAX_DESCRIPTION_SIZE ||
            `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
        ]"
        class="project__description col-12"
        :class="{
          'project__description--editable': !readonly,
        }"
        @update:model-value="debouncedValidation"
      />
      <q-btn
        icon="check"
        :disable="!validationPassed"
        class="q-mr-sm"
        @click="updateDescription"
      ></q-btn>
      <q-btn icon="close" @click="readonly = true"></q-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.content {
  position: relative;
  .read-only__container {
    span {
      @include paragraph-01(400, $secondary-600);

      opacity: 1;
    }
    :deep(svg) {
      cursor: pointer;
    }
  }

  .project__description {
    @include paragraph-01(400, $secondary-800);
    :deep(.q-field__control) {
      padding: 0;
    }
    :deep(.q-field__control)::before {
      border: none !important;
    }
    :deep(textarea) {
      resize: none !important;
      overflow-y: scroll !important;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      padding-left: 25px;
    }
    :deep(textarea::-webkit-scrollbar) {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
  .project__description--editable {
    :deep(.q-field__control) {
      padding: 0 12px;
    }
    :deep(.q-field__control)::before {
      border: 1px solid $secondary-200 !important;
    }
    :deep(textarea) {
      padding-left: 0;
    }
  }
  :deep(button) {
    width: 32px;
    justify-content: center;
    padding: 0;

    i {
      color: $common-3;
    }
    .q-btn__content {
      flex: none;
    }
  }
  :deep(button):hover {
    background: #ebf6f4;
  }
}
</style>
