<script setup lang="ts">
import { useProjectsStore } from '@/stores/ProjectsStore';

import OMatrix from '@/components/ProjectDetail/ProjectDashboard/DOrganisms/OMatrix.vue';
import OChart from '@/components/ProjectDetail/ProjectDashboard/DOrganisms/OChart.vue';

defineProps({
  dashboardIsLoading: {
    type: Boolean,
  },
});

const projectsStore = useProjectsStore();
</script>

<template>
  <div v-if="projectsStore.dashboardData">
    <span>Risks Overview</span>
    <div class="risks-wrapp q-mt-xl row items-start justify-between">
      <div class="inherent__container column col-2" :class="[dashboardIsLoading ? 'skeleton' : '']">
        <span class="inherent__title q-mb-lg text-center">Inherent Risks</span>
        <div class="column inherent__items items-center">
          <div class="inherent__item column items-center">
            <span class="item__value very-high">{{
              projectsStore.dashboardData.risks.inherent_risks['Very High']
                ? projectsStore.dashboardData.risks.inherent_risks['Very High']
                : 0
            }}</span>
            <span class="item__name">Very High</span>
          </div>
          <div class="inherent__item column items-center">
            <span class="item__value high">{{
              projectsStore.dashboardData.risks.inherent_risks['High']
                ? projectsStore.dashboardData.risks.inherent_risks['High']
                : 0
            }}</span>
            <span class="item__name">High</span>
          </div>
          <div class="inherent__item column items-center">
            <span class="item__value medium">{{
              projectsStore.dashboardData.risks.inherent_risks['Medium']
                ? projectsStore.dashboardData.risks.inherent_risks['Medium']
                : 0
            }}</span>
            <span class="item__name">Medium</span>
          </div>
          <div class="inherent__item column items-center">
            <span class="item__value low">{{
              projectsStore.dashboardData.risks.inherent_risks['Low']
                ? projectsStore.dashboardData.risks.inherent_risks['Low']
                : 0
            }}</span>
            <span class="item__name">Low</span>
          </div>
          <div class="inherent__item column items-center">
            <span class="item__value very-low">{{
              projectsStore.dashboardData.risks.inherent_risks['Very Low']
                ? projectsStore.dashboardData.risks.inherent_risks['Very Low']
                : 0
            }}</span>
            <span class="item__name">Very Low</span>
          </div>
        </div>
      </div>
      <div
        class="matrix__container col-8 col-lg-4 q-ml-md q-mr-lg"
        :class="[dashboardIsLoading ? 'skeleton' : '']"
      >
        <OMatrix
          v-if="!dashboardIsLoading"
          :matrix="projectsStore.dashboardData.risks.risk_matrix"
        />
      </div>
      <div class="chart__container col-5" :class="[dashboardIsLoading ? 'skeleton' : '']">
        <OChart :chart="projectsStore.dashboardData.risks.rpn_distribution" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

span {
  @include heading-04(600, $secondary-600);
}

.risks-wrapp {
  padding: 40px;
  background: $white;
  border-radius: 5px;

  .inherent__container {
    padding: 20px 32.5px;
    background: $secondary-600;
    border-radius: 5px;

    span {
      @include paragraph-02(500, $white);
    }
    .inherent__items {
      .inherent__item {
        margin-bottom: 5px;
        .item__value {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }

  .matrix__container {
    min-height: 350px;
  }

  .chart__container {
    @media screen and (max-width: 1440px) {
      margin-top: 50px;
    }
  }
}
</style>
