<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { debounce } from 'lodash';

import { VendorType, VendorRiskLevel, VendorStatus } from '@/client/api';

import { captureException } from '@/composables/Sentry';
import { RISK_LEVEL_LABEL } from '@/composables/CRisk';

import { useVendorStore } from '@/stores/VendorStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useUsersStore } from '@/stores/UsersStore';

import ADialog from '@/components/atoms/ADialog.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import DCreateVendor from '@/components/dialogs/DCreateVendor.vue';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

interface typeSelect {
  value: VendorType;
  label: string;
}

interface statusSelect {
  value: VendorStatus;
  label: string;
}

interface riskSelect {
  value: VendorRiskLevel;
  label: string;
}

const vendorStore = useVendorStore();
const usersStore = useUsersStore();
const paginationStore = usePaginationStore();
const organizationStore = useOrganizationStore();
const showNewVendor = ref(false);
const filter = ref<string | null>(null);
const typeFilter = ref<typeSelect | null>(null);
const statusFilter = ref<statusSelect | null>(null);
const riskLevelFilter = ref<riskSelect | null>(null);
const isLoading = ref(false);

const typeOptions = ref([
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Supplier',
    value: VendorType.Supplier,
  },
  {
    label: 'Contractor',
    value: VendorType.Contractor,
  },
  {
    label: 'Partner',
    value: VendorType.Partner,
  },
  {
    label: 'Data Source',
    value: VendorType.DataSource,
  },
  {
    label: 'Other',
    value: VendorType.Other,
  },
]);

const statusOptions = [
  {
    value: null,
    label: 'All',
  },
  {
    value: VendorStatus.Active,
    label: 'Active',
  },
  {
    value: VendorStatus.InReview,
    label: 'In Review',
  },
  {
    value: VendorStatus.Rejected,
    label: 'Rejected',
  },
];

const riskLevelOptions = [
  {
    value: null,
    label: 'All',
  },
  {
    value: VendorRiskLevel.VeryLow,
    label: RISK_LEVEL_LABEL.VERY_LOW,
  },
  {
    value: VendorRiskLevel.Low,
    label: RISK_LEVEL_LABEL.LOW,
  },
  {
    value: VendorRiskLevel.Medium,
    label: RISK_LEVEL_LABEL.MEDIUM,
  },
  {
    value: VendorRiskLevel.High,
    label: RISK_LEVEL_LABEL.HIGH,
  },
  {
    value: VendorRiskLevel.VeryHigh,
    label: RISK_LEVEL_LABEL.VERY_HIGH,
  },
];

const columnsNames = {
  name: 'Name',
  type: 'Type',
  assigned_to: 'Assignee',
  status: 'Status',
  risk_level: 'Risk Level',
  annual_contract_value: 'Annual Contract Value',
  review_date: 'Review Date',
};

async function filterVendors() {
  try {
    isLoading.value = true;
    await vendorStore.getVendors({
      ...vendorStore.vendorsData,
      ...DEFAULT_PAGINATION,
      name: filter.value !== '' ? filter.value : null,
      vendorType: typeFilter.value ? typeFilter.value.value : null,
      vendorStatus: statusFilter.value ? statusFilter.value.value : null,
      vendorRiskLevel: riskLevelFilter.value ? riskLevelFilter.value.value : null,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorsOverview, Function: filterVendors',
    });
  } finally {
    isLoading.value = false;
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  await filterVendors();
}

async function filterByType() {
  paginationStore.setVendorTypeFilter(typeFilter.value ? typeFilter.value.value : null);

  await filterVendors();
}

async function filterByStatus() {
  paginationStore.setVendorStatusFilter(statusFilter.value ? statusFilter.value.value : null);

  await filterVendors();
}

async function filterByRiskLevel() {
  paginationStore.setVendorRiskLevelFilter(
    riskLevelFilter.value ? riskLevelFilter.value.value : null,
  );

  await filterVendors();
}

function updateList() {
  filterVendors();
}

function closeDialog() {
  showNewVendor.value = false;
}

async function getUser(id: string) {
  try {
    await usersStore.getUser({
      userId: id,
    });

    if (!organizationStore.usersData || !usersStore.currentUser) return;

    organizationStore.usersHashMap[id] = usersStore.currentUser;
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorsOverview, Function: getUser',
      data: {
        userId: id,
      },
    });
  }
}

async function getMissingUsers() {
  try {
    const missingUsersSet = new Set();
    vendorStore.vendorsData?.items.forEach((vendor) => {
      if (!organizationStore.usersHashMap[vendor.assigned_to]) {
        missingUsersSet.add(vendor.assigned_to);
      }
    });

    const missingUsersArray = Array.from(missingUsersSet);

    await Promise.all(
      missingUsersArray.map(async (userId) => {
        await getUser(userId as string);
      }),
    );
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorsOverview, Function: getMissingUsers',
    });
  }
}

const debouncedGetMissingUsers = debounce(async () => {
  await getMissingUsers();
}, 300);

watch(
  () => vendorStore.vendorsData,
  async (newV) => {
    if (!newV) return;
    debouncedGetMissingUsers();
  },
);

onMounted(async () => {
  isLoading.value = true;

  const pagination = {
    ...DEFAULT_PAGINATION,
    descending: false,
    sortBy: 'name',
  };
  try {
    await organizationStore.getOrganizationUsers({
      ...DEFAULT_PAGINATION,
      size: 50,
    });
    await vendorStore.getVendors(pagination);
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorsOverview, Hook: onMounted, Method: getVendors',
      data: {
        pagination: pagination,
      },
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <PageTitle
    title="Vendors"
    :editable="false"
    class="q-my-md"
    :class="isLoading ? 'skeleton' : ''"
  />
  <div class="row title__container q-mb-lg">Onboard, manage and monitor AI vendors.</div>
  <OTable
    row-route="/vendors/vendor-detail/"
    :data="vendorStore.vendorsData?.items"
    :pagination="vendorStore.vendorsData!"
    :columns-names="columnsNames"
    :is-loading="isLoading"
    :skeleton-size="5"
    store="vendorsStore"
    action="getVendors"
    parent-el="vendorsOverview"
    @filter-by-search="filterBySearch"
    @get-user="getUser"
  >
    <template #header-button>
      <q-btn
        icon="add"
        label="New Vendor"
        unelevated
        class="btn-new"
        :class="[isLoading ? 'skeleton' : '']"
        @click="showNewVendor = true"
      />
    </template>
    <template #header-filters>
      <q-select
        v-model="typeFilter"
        outlined
        label="Type"
        :options="typeOptions"
        dense
        class="q-mr-md"
        :class="[isLoading ? 'skeleton' : '']"
        @update:model-value="filterByType"
      />

      <q-select
        v-model="statusFilter"
        outlined
        label="Status"
        :options="statusOptions"
        dense
        class="q-mr-md"
        :class="[isLoading ? 'skeleton' : '']"
        @update:model-value="filterByStatus"
      />

      <q-select
        v-model="riskLevelFilter"
        outlined
        label="Risk Level"
        :options="riskLevelOptions"
        dense
        class="q-mr-md"
        :class="[isLoading ? 'skeleton' : '']"
        @update:model-value="filterByRiskLevel"
      />
    </template>
  </OTable>
  <div
    v-if="vendorStore.vendorsData && vendorStore.vendorsData.items.length === 0 && !isLoading"
    class="empty__wrapp row items-center q-mt-md"
  >
    <AEmptyData
      v-if="!filter && !typeFilter && !statusFilter && !riskLevelFilter"
      icon-name="folder"
      :header="`You don’t have any Vendor attached to this Organization.`"
      text="Start by adding "
      action-text="New Vendor."
      class="full-width items-center"
      @click-text="showNewVendor = true"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
  <ADialog
    :show-dialog="showNewVendor"
    max-width="650px !important"
    min-width="650px !important"
    min-height="auto"
    class="dialog-create-vendor"
    @hide="closeDialog"
  >
    <DCreateVendor @close-dialog="closeDialog" @update-list="updateList" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.title__container {
  @include paragraph-01(500, $secondary-500);
}

.q-field {
  width: 150px;
  border-radius: 4px;
  @media screen and (max-width: 1100px) {
    width: 125px;
  }
  :deep(.q-field__inner) {
    padding: 0 !important;
    .q-field__control::before {
      border: none !important;
      .q-field__native div {
        display: none !important;
      }
    }
    .q-field__label {
      color: $secondary-400;
    }
  }
}
</style>
