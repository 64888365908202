<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, onMounted } from 'vue';

import type { AssetCard } from '@/client/api';
import { AssetCardTypes, AssetCardNature, Roles, Concept } from '@/client/api';

import { UpperCaseRoles, isAdmin, userProjectRoles } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';
import { isProjectOwner } from '@/composables/CProject';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { usePaginationStore } from '@/stores/PaginationStore';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import DNewAsset from '@/components/dialogs/DNewAsset.vue';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

interface typeSelect {
  value: AssetCardTypes;
  label: string;
}

interface natureSelect {
  value: AssetCardNature;
  label: string;
}

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const showNewAssetDialog = ref(false);
const filter = ref<string | null>(null);
const typeFilter = ref<typeSelect | null>(null);
const natureFilter = ref<natureSelect | null>(null);
const assetsIsLoading = ref(false);
const isEditor = ref(false);

const typeOptions = ref([
  {
    label: 'All',
    value: null,
  },
  {
    label: 'General',
    value: AssetCardTypes.GeneralCard,
  },
  {
    label: 'Model Card',
    value: AssetCardTypes.ModelCard,
  },
  {
    label: 'Dataset Card',
    value: AssetCardTypes.DatasetCard,
  },
]);

const natureOptions = ref([
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Intangible',
    value: AssetCardNature.Intangible,
  },
  {
    label: 'Tangible',
    value: AssetCardNature.Tangible,
  },
]);

const columnsNames = {
  name: 'Name',
  asset_card_type: 'Type',
  nature: 'Nature',
  value: 'Value',
  created_at: 'Created',
  modified_at: 'Modified',
  created_by: UpperCaseRoles.Owner,
  status: 'Status',
  assets_actions: '',
};

function closeDialog() {
  showNewAssetDialog.value = false;
}

async function filterAssets() {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectAssets({
      projectId: projectsStore.project.id,
      ...projectsStore.assetsData,
      ...DEFAULT_PAGINATION,
      name: filter.value !== '' ? filter.value : null,
      assetCardType: typeFilter.value ? typeFilter.value.value : null,
      assetCardNature: natureFilter.value ? natureFilter.value.value : null,
    });
  } catch (error) {
    captureException(error, { message: 'Component: Assets, Function: filterAssets' });
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  await filterAssets();
}

async function filterByType() {
  paginationStore.setAssetTypeFilter(typeFilter.value ? typeFilter.value.value : null);

  await filterAssets();
}

async function filterByNature() {
  paginationStore.setAssetNatureFilter(natureFilter.value ? natureFilter.value.value : null);

  await filterAssets();
}

function downloadAsset(asset: AssetCard) {
  var file = new Blob([asset.content], { type: 'text' });

  var a = document.createElement('a');
  var url = URL.createObjectURL(file);
  a.href = url;
  a.download = asset.name;

  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

async function updateData() {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectAssets({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });
  } catch (error) {
    captureException(error, { message: 'Component: Assets, Function: updateData' });
  }
}

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    assetsIsLoading.value = true;
    await projectsStore.getProjectAssets({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });

    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
  } catch (error) {
    captureException(error, {
      message: 'Component: Assets, Hook: onMounted, Method: getProjectAssets',
    });
  } finally {
    assetsIsLoading.value = false;
  }
});
</script>

<template>
  <OTable
    :data="projectsStore.assetsData?.items"
    :pagination="projectsStore.assetsData!"
    :columns-names="columnsNames"
    :row-route="`/projects/project-detail/${projectsStore.project?.id}/asset-detail/`"
    :is-loading="assetsIsLoading"
    :skeleton-size="5"
    search-placeholder="Search for Asset"
    store="projectsStore"
    action="getAssets"
    :concept="Concept.AssetCard"
    @download-asset="downloadAsset"
    @filter-by-search="filterBySearch"
  >
    <template #header-button>
      <q-btn
        v-if="isProjectOwner() || isAdmin() || isEditor"
        icon="add"
        label="New Asset"
        :disable="!isProjectOwner() && !isAdmin() && !isEditor"
        unelevated
        :class="[assetsIsLoading ? 'skeleton' : '']"
        @click="showNewAssetDialog = true"
      />
    </template>
    <template #header-filters>
      <q-select
        v-model="typeFilter"
        outlined
        label="Asset Type"
        :options="typeOptions"
        dense
        :class="[assetsIsLoading ? 'skeleton' : '']"
        @update:model-value="filterByType"
      />

      <q-select
        v-model="natureFilter"
        outlined
        label="Asset Nature"
        :options="natureOptions"
        dense
        class="q-ml-md"
        :class="[assetsIsLoading ? 'skeleton' : '']"
        @update:model-value="filterByNature"
      />
    </template>
  </OTable>
  <div
    v-if="
      projectsStore.assetsData && projectsStore.assetsData.items.length === 0 && !assetsIsLoading
    "
    class="empty__wrapp row items-center q-mt-md"
  >
    <AEmptyData
      v-if="!filter && !typeFilter"
      icon-name="folder"
      :header="`You don’t have any Asset attached to this Project.`"
      :text="isAdmin() || isProjectOwner() || isEditor ? 'Start by uploading a' : ''"
      :action-text="isAdmin() || isProjectOwner() || isEditor ? 'New Asset.' : ''"
      class="full-width items-center"
      @click-text="showNewAssetDialog = true"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
  <ADialog
    :show-dialog="showNewAssetDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DNewAsset @close-dialog="closeDialog" @update-data="updateData" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.q-field {
  width: 150px;
  border-radius: 4px;
  :deep(.q-field__inner) {
    padding: 0 !important;
    .q-field__control::before {
      border: none !important;
      .q-field__native div {
        display: none !important;
      }
    }
    .q-field__label {
      color: $secondary-400;
    }
  }
}
</style>
