<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { ProjectType, type FrameworkDetailed } from '@/client/api';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { captureException } from '@/composables/Sentry';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import OTableV2 from '@/components/organisms/OTableV2.vue';

const emit = defineEmits(['change-tab']);

const organizationStore = useOrganizationStore();
const router = useRouter();
const activeTab = ref<ProjectType>(ProjectType.AiApplication);
const isLoading = ref(false);
const columnsNames = {
  name: 'Name',
  description: 'Description',
  number_of_controls: 'Controls',
  number_of_requirements: 'Requirements',
  content_overview: '',
};

async function changeTab(value: ProjectType) {
  isLoading.value = true;
  activeTab.value = value;

  emit('change-tab', value);

  try {
    await organizationStore.getOrganizationTemplates(activeTab.value);
  } catch (error) {
    captureException(error, {
      message: 'Component: Templates, Function: changeTab',
      data: {
        type: activeTab.value,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

function showFrameworkOverview(row: FrameworkDetailed) {
  router.push(`/organization/template-detail/${row.code}`);
}
onMounted(async () => {
  try {
    isLoading.value = true;
    await organizationStore.getOrganizationTemplates(activeTab.value);
  } catch (error) {
    captureException(error, {
      message: 'Component: Templates, Hook: onMounted, Method: getOrganizationTemplates',
      data: {
        type: activeTab.value,
      },
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div>
    <div class="row title__container q-mb-lg">
      Manage custom definitions and content for your organization here.
    </div>
    <OTabPanels
      :active-tab="activeTab"
      :default-size="true"
      :default-tab="ProjectType.AiApplication"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab
          content-class="my-tab"
          :name="ProjectType.AiApplication"
          label="AI Applications"
          class="q-px-none q-mr-md"
        />
        <q-tab
          content-class="my-tab"
          name="organization"
          label="Organizational Projects"
          class="q-px-none"
        />
      </template>
      <template #panels>
        <q-tab-panel :name="ProjectType.AiApplication" class="q-px-none">
          <OTableV2
            :data="organizationStore.templatesData!"
            :columns-names="columnsNames"
            :row-clickable="true"
            :search-filter="false"
            :show-pagination="true"
            :sortable="false"
            :is-loading="isLoading"
            :skeleton-size="5"
            concept="framework"
            row-click-action="showFrameworkOverview"
            @show-framework-overview="showFrameworkOverview"
          >
            <template #header-button>
              <q-btn icon="add" label="New Project" unelevated to="/projects/new-project" />
            </template>
          </OTableV2>
          <div
            v-if="
              organizationStore.templatesData &&
              organizationStore.templatesData.length === 0 &&
              !isLoading
            "
            class="empty__wrapp row items-center"
          >
            <AEmptyData
              icon-name="folder"
              header="AI Application templates list is empty."
              text="Please upload a new template."
              class="full-width items-center empty__content"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel :name="ProjectType.Organization" class="q-px-none">
          <OTableV2
            :data="organizationStore.templatesData!"
            :columns-names="columnsNames"
            :row-clickable="true"
            :search-filter="false"
            :show-pagination="true"
            :sortable="false"
            :is-loading="isLoading"
            :skeleton-size="5"
            concept="framework"
            row-click-action="showFrameworkOverview"
            @show-framework-overview="showFrameworkOverview"
          >
            <template #header-button>
              <q-btn icon="add" label="New Project" unelevated to="/projects/new-project" />
            </template>
          </OTableV2>
          <div
            v-if="
              organizationStore.templatesData &&
              organizationStore.templatesData.length === 0 &&
              !isLoading
            "
            class="empty__wrapp row items-center"
          >
            <AEmptyData
              icon-name="folder"
              header="Organization templates list is empty."
              text="Please upload a new template."
              class="full-width items-center empty__content"
            />
          </div>
        </q-tab-panel>
      </template>
    </OTabPanels>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.title__container {
  @include paragraph-02(500, $secondary-600);
}

span {
  @include heading-04(600, $secondary-600);
}
</style>
