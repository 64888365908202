<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import type { ControlDisplay, RiskDisplay, UserDisplay, UserInProjectList } from '@/client/api';
import type { PropType } from 'vue';

const emit = defineEmits(['selectItem']);

const props = defineProps({
  dataValue: {
    type: String,
    default: '',
  },
  dataArr: {
    type: Array as PropType<RiskDisplay[] | ControlDisplay[]>,
    default: () => [],
  },
  selectedArr: {
    type: Array as PropType<RiskDisplay[] | ControlDisplay[]>,
    default: () => [],
  },
  filterBy: {
    type: String,
    default: '',
  },
  searchPlaceholder: {
    type: String,
    default: 'user',
  },
});

const filter = ref('');
const selectedRisks = ref<RiskDisplay[]>([]);
const selectedControls = ref<ControlDisplay[]>([]);

function selectItem(item: ControlDisplay | RiskDisplay, filterBy: string) {
  if (filterBy === 'risks') {
    const risk = selectedRisks.value.findIndex((selItem) => selItem.id === item.id);
    if (risk >= 0) {
      selectedRisks.value.splice(risk, 1);
    } else {
      selectedRisks.value.push(item as RiskDisplay);
    }
    emit('selectItem', selectedRisks.value);
  }
  if (filterBy === 'controls') {
    const control = selectedControls.value.findIndex((selItem) => selItem.id === item.id);
    if (control >= 0) {
      selectedControls.value.splice(control, 1);
    } else {
      selectedControls.value.push(item as ControlDisplay);
    }
    emit('selectItem', selectedControls.value);
  }
}

const filteredValues = computed(() => {
  switch (props.filterBy) {
    case 'controls':
      return props.dataArr.filter((item: ControlDisplay | RiskDisplay) =>
        item.name.toLowerCase().includes(filter.value.toLowerCase()),
      ) as ControlDisplay[] | RiskDisplay[];
    case 'risks':
      return props.dataArr.filter((item: ControlDisplay | RiskDisplay) =>
        item.name.toLowerCase().includes(filter.value.toLowerCase()),
      ) as ControlDisplay[] | RiskDisplay[];
    default:
      return [];
  }
});

function isControlsData(
  data: UserDisplay[] | ControlDisplay[] | RiskDisplay[] | UserInProjectList[],
): data is ControlDisplay[] {
  if (data.length === 0) {
    return false;
  }

  return 'control_code' in data[0];
}

function isRisksData(
  data: UserDisplay[] | ControlDisplay[] | RiskDisplay[] | UserInProjectList[],
): data is RiskDisplay[] {
  if (data.length === 0) return false;

  return 'name' in data[0];
}

function isSelectedRisk(item: RiskDisplay) {
  return selectedRisks.value.some((selItem: RiskDisplay) => selItem.id === item.id);
}

function isSelectedControl(item: ControlDisplay) {
  return selectedControls.value.some((selItem: ControlDisplay) => selItem.id === item.id);
}

watch(
  () => props.selectedArr,
  (newV) => {
    if (props.filterBy === 'risks') {
      selectedRisks.value = newV as RiskDisplay[];
    } else if (props.filterBy === 'controls') {
      selectedControls.value = newV as ControlDisplay[];
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="menu-wrapper">
    <q-input
      v-model="filter"
      borderless
      dense
      debounce="300"
      :placeholder="`Search for a ${searchPlaceholder}`"
      class="q-mb-sm"
    >
      <template #append>
        <q-icon name="search" />
      </template>
    </q-input>
    <q-list
      v-if="
        (filterBy === 'risks' && isRisksData(filteredValues)) ||
        (filterBy === 'controls' && isControlsData(filteredValues))
      "
    >
      <q-item
        v-for="item in filteredValues"
        :key="item.id"
        :clickable="false"
        :class="dataValue === item.name ? 'active' : ''"
        class="q-px-none"
      >
        <q-item-section class="item-section items-center">
          <q-checkbox
            v-if="filterBy === 'risks'"
            :model-value="isSelectedRisk(item as RiskDisplay)"
            :val="item"
            size="16px"
            class="q-mr-sm"
            @update:model-value="selectItem(item, filterBy)"
          />
          <q-checkbox
            v-if="filterBy === 'controls'"
            :model-value="isSelectedControl(item as ControlDisplay)"
            :val="item"
            size="16px"
            class="q-mr-sm"
            @update:model-value="selectItem(item, filterBy)"
          />
          <q-item-label>{{ item.name }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.menu-wrapper {
  padding: 8px;

  label {
    border-radius: 2px;
    border: 1px solid $common-1;
    :deep(input) {
      padding: 0px 12px;
    }
    :deep(.q-field__append) {
      padding: 6px;
      border-left: 1px solid $common-1;
    }
  }
  :deep(.q-item__label) {
    color: $common-4;
  }
  .active {
    background: #e8e8e8;
  }
  .item-section {
    flex-direction: row !important;
    justify-content: flex-start !important;
    .q-checkbox {
      :deep(.q-checkbox__bg) {
        width: 100%;
        height: 100%;
        position: unset;
      }

      :deep(.q-checkbox__inner--falsy) {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      :deep(.q-checkbox__inner--truthy) {
        .q-checkbox__bg {
          color: $secondary-800;
        }
      }
      :deep(.q-checkbox__label) {
        @include paragraph-02(600, $common-5);
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
</style>
