<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import type { RiskTreatmentStrategy } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';
import type { PropType } from 'vue';

import { MAX_NAME_SIZE } from '@/composables/utils';

import ARiskInfo from '@/components/atoms/ARiskInfo.vue';
import Icon from '@/components/atoms/Icon.vue';
import MSlider from '@/components/molecules/MSlider.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';

import { RpnLevel } from '@/composables/CRisk';

interface Strategy extends RiskTreatmentStrategy {
  default: boolean;
}

const emit = defineEmits(['hideSidebar', 'createStrategy', 'updateStrategy']);

const props = defineProps({
  showSidebar: {
    type: Boolean,
  },
  editStrategy: {
    type: Object as PropType<Strategy | null>,
    default: () => null,
  },
  creation: {
    type: Boolean,
  },

  strategies: {
    type: Array as PropType<Strategy[]>,
    default: () => [],
  },

  isAllowed: {
    type: Boolean,
    default: false,
  },
});

const strategyName = ref('');
const sidebarImpactValue = ref<number>(0);
const sidebarLikelihoodValue = ref<number>(0);
const sidebarDValue = ref<number>(1);

const planContent = ref<TipTapContent>({
  content: '',
  json_content: {},
});
const implementationContent = ref<TipTapContent>({
  content: '',
  json_content: {},
});

function updateSliderValue(value: number, name: string) {
  if (name === 'Impact') sidebarImpactValue.value = value;
  if (name === 'Likelihood') sidebarLikelihoodValue.value = value;
  if (name === 'Detection') sidebarDValue.value = value;
}

function updatePlanContent(content: TipTapContent) {
  planContent.value = content;
}

function updateImplementationContent(content: TipTapContent) {
  implementationContent.value = content;
}

function resetValues() {
  strategyName.value = '';
  sidebarImpactValue.value = 0;
  sidebarLikelihoodValue.value = 0;
  sidebarDValue.value = 1;
  planContent.value = {
    content: '',
    json_content: {},
  };
  implementationContent.value = {
    content: '',
    json_content: {},
  };
}

function save() {
  if (props.creation) {
    const strategy = {
      name: strategyName.value,
      plan: planContent.value.content,
      json_plan: planContent.value.json_content,
      implementation: implementationContent.value.content,
      json_implementation: implementationContent.value.json_content,
      risk_impact: sidebarImpactValue.value,
      risk_likelihood: sidebarLikelihoodValue.value,
      risk_detection: sidebarDValue.value,
      risk_rpn_value: getRpn.value.toString(),
    };

    emit('createStrategy', strategy);
    emit('hideSidebar');
    resetValues();
    return;
  }

  if (!props.editStrategy) return;

  const strategy = {
    name: strategyName.value,
    plan: planContent.value.content,
    json_plan: planContent.value.json_content,
    implementation: implementationContent.value.content,
    json_implementation: implementationContent.value.json_content,
    risk_impact: sidebarImpactValue.value,
    risk_likelihood: sidebarLikelihoodValue.value,
    risk_detection: sidebarDValue.value,
    risk_rpn_value: getRpn.value.toString(),
  };

  emit('updateStrategy', props.editStrategy.id, strategy);
  emit('hideSidebar');
  resetValues();
}

const getRpn = computed(() => {
  const result = sidebarImpactValue.value * sidebarLikelihoodValue.value * sidebarDValue.value;
  return result > 0 ? result.toFixed(3) : 0;
});

watch(
  () => props.editStrategy,
  (value) => {
    if (value) {
      strategyName.value = value.name;
      sidebarImpactValue.value = value.risk_impact || 0;
      sidebarLikelihoodValue.value = value.risk_likelihood || 0;
      sidebarDValue.value = value.risk_detection || 1;
      planContent.value = {
        content: value.plan || '',
        json_content: value.json_plan || {},
      };
      implementationContent.value = {
        content: value.implementation || '',
        json_content: value.json_implementation || {},
      };
    } else {
      resetValues();
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="sidebar__wrapp" :class="[showSidebar ? 'opened' : '']">
    <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: '0' }">
      <div class="strategie__creation-sidebar">
        <div class="sidebar__header row full-width justify-between items-center q-mb-md">
          <span class="header__title">Strategy</span>
          <Icon
            icon-name="close-strategy"
            icon-folder="risks"
            clas="cursor-pointer"
            @click="emit('hideSidebar')"
          />
        </div>
        <div class="sidebar__content row full-width">
          <div class="col-12 row q-mb-sm">
            <span class="label-text q-mb-sm col-12">Name</span>
            <q-input
              ref="nameInput"
              v-model="strategyName"
              outlined
              dense
              class="col-12"
              placeholder="Strategy Name"
              no-error-icon
              :rules="[
                (val: string) => !!val || 'Please enter a name',
                (val: string) =>
                  (val && val.length <= MAX_NAME_SIZE) ||
                  `Maximum ${MAX_NAME_SIZE} characters allowed`,
              ]"
            />
          </div>
          <div class="col-12 row q-mb-md">
            <span class="label-text q-mb-sm col-12">Plan</span>
            <TipTapEditor
              v-if="showSidebar"
              :editor-content="planContent"
              root-el="Treatment"
              placeholder="Insert text"
              class="full-width"
              @update-editor="updatePlanContent"
            />
          </div>
          <div class="col-12 row q-mb-md">
            <span class="label-text q-mb-sm col-12">Implementation</span>
            <TipTapEditor
              v-if="showSidebar"
              :editor-content="implementationContent"
              root-el="Treatment"
              placeholder="Insert text"
              class="full-width"
              @update-editor="updateImplementationContent"
            />
          </div>
          <div class="col-12 row q-mb-md">
            <span class="label-text q-mb-sm col-12">Residual Risk</span>
            <div class="sliders__container col-12">
              <MSlider
                name="Impact"
                class="q-mb-xs col-12"
                tooltip-text="The potential severity of a risk's consequences."
                :slider-value="sidebarImpactValue"
                @update-value="updateSliderValue"
              />
              <MSlider
                name="Likelihood"
                class="q-mb-xs col-12"
                tooltip-text="The probability that a risk will occur."
                :slider-value="sidebarLikelihoodValue"
                @update-value="updateSliderValue"
              />
              <MSlider
                name="Detection"
                class="q-mb-xs col-12"
                tooltip-text="The capability to identify a risk early."
                :slider-value="sidebarDValue"
                @update-value="updateSliderValue"
              />
              <ARiskInfo
                info-name="Residual Risk"
                :info-value="getRpn.toString()"
                tooltip-text="Residual risk: indicates the risk that remains after treatment."
                :badge-text="RpnLevel(getRpn) || ''"
                :badge-type="
                  RpnLevel(getRpn)
                    ? (RpnLevel(getRpn) || '').toLowerCase().replaceAll(' ', '-') + '-badge'
                    : 'badge'
                "
              />
            </div>
          </div>

          <div class="col-12 row justify-center">
            <q-btn
              v-if="isAllowed"
              label="Save"
              unelevated
              class="full-width"
              :disable="!strategyName || !isAllowed"
              @click="save"
            />
          </div>
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.sidebar__wrapp {
  width: 0;
  height: calc(100vh - 8px);
  position: fixed;
  right: 4px;
  top: 4px;
  z-index: 6000;
  overflow-y: scroll;
  border-radius: 4px;
  background: $white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  scrollbar-width: none; /* for Firefox */

  .strategie__creation-sidebar {
    padding: 24px;
    .sidebar__header {
      .header__title {
        @include heading-04(600, $secondary-800);
      }
      :deep(svg) {
        cursor: pointer;
      }
    }
    .sidebar__content {
      .label-text {
        @include caption(400, $secondary-500);
      }
      :deep(.scroll-style) {
        background: $white !important;
      }
      .sliders__container {
        border-radius: 5px;
        border: 2px solid $secondary-200;
        padding: 24px 16px;
      }
      button {
        background: $secondary-500 !important;
        color: $white;
        text-transform: none;
      }
      .disabled {
        opacity: 0.4 !important;
      }
    }
  }
  :deep(.editor) {
    min-height: 250px !important;
  }
}
.sidebar__wrapp::-webkit-scrollbar {
  display: none;
}
.opened {
  width: 600px;
}
</style>
