<script lang="ts" setup>
import * as d3 from 'd3';
import { onMounted, ref, reactive, watch } from 'vue';

import { ImpactLabels, LikelihoodLabels } from '@/composables/CRisk';

const props = defineProps({
  matrix: {
    type: Array as () => number[][],
    required: true,
  },
});

const matrixEl = ref<HTMLElement | null>(null);
const tooltip = reactive({ text: '', x: 0, y: 0, visible: false });

const minColor = '#c1d7ff';
const maxColor = '#000a6a';
const maxValue = ref(0);
const minValue = ref(0);

function getColor(rowIndex: number, colIndex: number) {
  const value = props.matrix[colIndex][rowIndex];
  return d3
    .scaleLinear<string>()
    .domain([minValue.value, maxValue.value])
    .range([minColor, maxColor])
    .interpolate(d3.interpolateRgb.gamma(1))(value);
}

function findMaxValue() {
  const flattenedArray: number[] = props.matrix.flat();
  const max = Math.max(...flattenedArray);
  const min = Math.min(...flattenedArray);
  maxValue.value = max === 0 ? 1 : max;
  minValue.value = min;
}

function showTooltip(event: MouseEvent, riskNumber: number) {
  tooltip.text = `${riskNumber === 1 ? '1 inherent risk' : `${riskNumber} inherent risks`}`;
  tooltip.x = event.pageX;
  tooltip.y = event.pageY;
  tooltip.visible = true;
}

function hideTooltip() {
  tooltip.visible = false;
}
function renderMatrix() {
  d3.select(matrixEl.value).select('svg').remove();

  const svgWidth = 500;
  const svgHeight = 350;
  const leftMargin = 100;
  const bottomMargin = 30;
  const cellWidth = 70;
  const cellHeight = 52.5;

  const svg = d3
    .select(matrixEl.value)
    .append('svg')
    .attr('width', svgWidth)
    .attr('height', svgHeight);

  svg
    .append('text')
    .attr('x', leftMargin - 65)
    .attr('y', svgHeight / 2)
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90, ' + (leftMargin - 80) + ', ' + svgHeight / 2 + ')')
    .style('font-size', '14px')
    .style('fill', '#3F4252')
    .text('Impact');

  svg
    .append('text')
    .attr('x', svgWidth / 2 + 25)
    .attr('y', svgHeight - 5)
    .attr('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('fill', '#3F4252')
    .text('Likelihood');

  const defs = svg.append('defs');
  const filter = defs
    .append('filter')
    .attr('id', 'white-glow')
    .attr('x', '-50%')
    .attr('y', '-50%')
    .attr('width', '200%')
    .attr('height', '200%');

  filter
    .append('feDropShadow')
    .attr('dx', 0)
    .attr('dy', 0)
    .attr('stdDeviation', 2)
    .attr('flood-color', 'white')
    .attr('flood-opacity', '0.75');

  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attr('in', 'offsetBlur');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

  // Function to darken the cellColor for the circle background
  function darkenColor(color: string) {
    return d3.rgb(color).darker(0.5).toString();
  }

  ImpactLabels.forEach((impact, rowIndex) => {
    LikelihoodLabels.forEach((likelihood, colIndex) => {
      const cellValue = props.matrix[colIndex][rowIndex];
      const cellColor = getColor(rowIndex, colIndex);

      // Append the rectangle
      svg
        .append('rect')
        .attr('x', leftMargin + colIndex * cellWidth)
        .attr('y', (ImpactLabels.length - 1 - rowIndex) * cellHeight + 25)
        .attr('width', cellWidth)
        .attr('height', cellHeight)
        .style('stroke', 'white')
        .attr('fill', cellColor);

      // If the cell value affects color, append the circle with text
      if (cellValue !== 0) {
        const circleGroup = svg.append('g');

        circleGroup
          .append('circle')
          .attr('cx', leftMargin + colIndex * cellWidth + cellWidth / 2)
          .attr('cy', (ImpactLabels.length - 1 - rowIndex) * cellHeight + cellHeight / 2 + 25)
          .attr('r', 27 / 2) // Radius is half the diameter
          .style('fill', darkenColor(cellColor))
          .style('filter', 'url(#white-glow)')
          .on('mouseover', (event) => showTooltip(event, cellValue))
          .on('mousemove', (event) => showTooltip(event, cellValue))
          .on('mouseout', hideTooltip);

        circleGroup
          .append('text')
          .attr('x', leftMargin + colIndex * cellWidth + cellWidth / 2)
          .attr('y', (ImpactLabels.length - 1 - rowIndex) * cellHeight + cellHeight / 2 + 25)
          .attr('dy', '0.35em') // Adjust for vertical centering
          .attr('text-anchor', 'middle')
          .style('font-size', '14px')
          .style('fill', '#FFF')
          .style('font-weight', '700')
          .text(cellValue)
          .on('mouseover', (event) => showTooltip(event, cellValue))
          .on('mousemove', (event) => showTooltip(event, cellValue))
          .on('mouseout', hideTooltip);
      }
    });
  });

  ImpactLabels.forEach((impact, rowIndex) => {
    svg
      .append('text')
      .attr('x', leftMargin - 10)
      .attr('y', rowIndex * cellHeight + cellHeight / 2 + 30)
      .attr('dy', '0.32em')
      .attr('text-anchor', 'end')
      .style('font-size', '12px')
      .style('fill', '#222539')
      .text(impact);
  });

  LikelihoodLabels.forEach((likelihood, colIndex) => {
    svg
      .append('text')
      .attr('x', leftMargin + colIndex * cellWidth + cellWidth / 2)
      .attr('y', svgHeight - bottomMargin - 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', '#222539')
      .text(likelihood);
  });
}

watch(
  () => props.matrix,
  () => {
    findMaxValue();
    renderMatrix();
  },
);

onMounted(async () => {
  if (matrixEl.value) {
    findMaxValue();
    renderMatrix();
  }
});
</script>
<template>
  <div ref="matrixEl" class="col-6"></div>
  <div
    v-if="tooltip.visible"
    class="tooltip"
    :style="{
      top: tooltip.y + 'px',
      left: tooltip.x + 'px',
      display: tooltip.visible ? 'block' : 'none',
    }"
  >
    {{ tooltip.text }}
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.tooltip {
  position: absolute;
  background: $black;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  pointer-events: none;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  z-index: 10;
}
</style>
