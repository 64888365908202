<script setup lang="ts">
import { ref } from 'vue';

import Icon from '@/components/atoms/Icon.vue';

import { RpnLevel } from '@/composables/CRisk';

const values = ref(['1', '0.7', '0.5', '0.3', '0.1']);
</script>

<template>
  <div class="legend__container column">
    <span class="legend-title q-mb-sm">Legend</span>
    <div class="info__container column">
      <div class="levels__container row items-center q-mb-md">
        <span class="levels-title q-mr-sm">Risk Level: </span>
        <div class="badges__container row items-center">
          <q-badge
            v-for="item in values"
            :key="item"
            class="justify-center q-mr-sm"
            :label="RpnLevel(item) ?? ''"
            :class="[
              RpnLevel(item) ? RpnLevel(item)?.replaceAll(' ', '-').toLowerCase() + '-badge' : '',
            ]"
          />
        </div>
      </div>
      <div class="description__container row items-center">
        <div class="row items-center q-mr-md">
          <Icon icon-name="arrow-north" icon-folder="risks" />
          <span class="description-text q-ml-xs">Over the risk threshold</span>
        </div>
        <div class="row items-center">
          <Icon icon-name="arrow-south" icon-folder="risks" />
          <span class="description-text q-ml-xs">Equal or under the risk threshold</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.legend__container {
  width: fit-content;
  @include caption(400, $secondary-600);
  .info__container {
    border-radius: 5px;
    border: 1px solid #c3cfd5;
    background: rgba(255, 255, 255, 0.3);
    padding: 10px;
  }
}
</style>
