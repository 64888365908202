<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { useProjectsStore } from '@/stores/ProjectsStore';

import ContentHeader from '@/components/molecules/Content/MContentHeader.vue';
import ContentSidebar from '@/components/molecules/Content/MContentSidebar.vue';
import MPagination from '@/components/molecules/MPagination.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const selectAll = ref(false);
const route = useRoute();
const projectsStore = useProjectsStore();
</script>

<template>
  <q-layout view="lHr lpR fFf">
    <ContentHeader />
    <ContentSidebar :title="title" />
    <q-page-container :style="{ paddingLeft: '230px' }">
      <router-view :all-selected="selectAll" />
      <div
        v-if="projectsStore.requirementsData || projectsStore.controlsData"
        class="pagination__container row justify-end q-px-sm q-py-lg"
      >
        <MPagination
          v-if="
            route.name === 'project-requirements' &&
            projectsStore.requirementsData?.items &&
            projectsStore.requirementsData?.items.length > 0
          "
          :pagination="projectsStore.requirementsData"
          store="projectsStore"
          action="getProjectRequirements"
        />
        <MPagination
          v-if="
            route.name === 'project-controls' &&
            projectsStore.controlsData &&
            projectsStore.controlsData?.items &&
            projectsStore.controlsData?.items.length > 0
          "
          :pagination="projectsStore.controlsData"
          store="projectsStore"
          action="getProjectControls"
        />
      </div>
    </q-page-container>
  </q-layout>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

:deep(.q-page-container) {
  background: $white !important;
  .main__header {
    border: 1px solid rgba(0, 0, 0, 0.05);
    .q-checkbox {
      .q-checkbox__label {
        @include paragraph-01(400, $common-5);
        opacity: 0.7;
      }
      .q-checkbox__inner--falsy {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      .q-checkbox__inner--truthy {
        .q-checkbox__bg {
          color: $secondary-700;
        }
      }
    }
  }
}
</style>
