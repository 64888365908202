<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

import { useConfigStore } from '@/stores/ConfigStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { AssetCardNature, AssetCardTypes, Roles } from '@/client/api';
import type { QInput, QSelect } from 'quasar';

import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { OrganizationCurrency } from '@/composables/Config';
import { isProjectOwner } from '@/composables/CProject';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_NAME_SIZE } from '@/composables/utils';

interface Select {
  value: AssetCardTypes;
  label: string;
}

const emit = defineEmits(['closeDialog', 'updateData']);

const configStore = useConfigStore();
const projectsStore = useProjectsStore();
const name = ref('');
const assetValue = ref<number>(0);
const isLoading = ref(false);
const nameInput = ref<QInput | null>(null);
const typeInput = ref<QSelect | null>(null);
const valueInput = ref<QInput | null>(null);
const validationPassed = ref(false);
const type = ref<Select | null>(null);
const nameValidated = ref(false);
const typeValidated = ref(false);
const valueValidated = ref(false);
const isEditor = ref(false);
const nature = ref(AssetCardNature.Intangible);
const currency = ref<string | null>(null);
const typeOptions = ref([
  {
    label: 'General',
    value: AssetCardTypes.GeneralCard,
  },
  {
    label: 'Model Card',
    value: AssetCardTypes.ModelCard,
  },
  {
    label: 'Dataset Card',
    value: AssetCardTypes.DatasetCard,
  },
]);

function closeModal() {
  emit('closeDialog');
}

async function validateName() {
  if (nameInput.value) {
    const result = nameInput.value.validate();
    return (nameValidated.value = result instanceof Promise ? await result : result);
  }
  return false;
}

async function validateType() {
  if (typeInput.value) {
    const result = typeInput.value.validate();
    return (typeValidated.value = result instanceof Promise ? await result : result);
  }
  return false;
}

async function validateValue() {
  if (valueInput.value) {
    const result = valueInput.value.validate();
    return (valueValidated.value = result instanceof Promise ? await result : result);
  }
  return false;
}

async function checkValidation() {
  const isNameValid = await validateName();
  const isTypeValid = await validateType();
  const isValueValid = await validateValue();
  validationPassed.value = isNameValid && isTypeValid && isValueValid;
}

async function createAsset() {
  await checkValidation();
  if (!projectsStore.project || !type.value || !validationPassed.value) return;

  const assetCard = {
    name: name.value,
    asset_card_type: type.value.value,
    nature: nature.value,
    value: assetValue.value,
  };

  const params = {
    projectId: projectsStore.project.id,
    createAssetCard: assetCard,
  };

  try {
    isLoading.value = true;

    const asset = await projectsStore.createProjectAsset(params);

    successMessage(`Asset '${asset?.name}' created successfully`);

    emit('updateData');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DNewAsset, Function: createAsset',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

watch(
  () => configStore.configData,
  (newV) => {
    if (!newV) return;

    currency.value = OrganizationCurrency(newV.general.currency);
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  try {

    if (!projectsStore.project) return;
    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
  } catch (error) {
    captureException(error, {
      message: 'Component: DNewAsset, Hook: onMounted, Method: userProjectRoles',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Create New Asset</h5>
    </div>
    <div class="row">
      <q-form class="row col-12" @submit.prevent="createAsset">
        <div class="col-12 row q-mb-lg">
          <span class="q-mb-sm col-12">Asset Name</span>
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            class="col-12"
            placeholder="Insert Name"
            no-error-icon
            :rules="[
              (val: string) => !!val || 'Please enter a name',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="validateName"
          />
        </div>
        <div class="col-12 row q-mb-lg">
          <span class="col-12 q-mb-sm">Asset Type</span>
          <q-select
            ref="typeInput"
            v-model="type"
            outlined
            :options="typeOptions"
            dense
            label="Please select a type"
            :rules="[(val: string | null) => val !== null || 'Please select a type']"
            class="col-12"
            :error="false"
            @update:model-value="validateType"
          />
        </div>
        <div class="row col-12 q-mb-lg items-center">
          <span class="col-12">Nature</span>
          <q-radio
            v-model="nature"
            :val="AssetCardNature.Intangible"
            label="Intangible"
            color="dark"
            size="32px"
            class="q-mr-xs"
          />
          <q-radio
            v-model="nature"
            :val="AssetCardNature.Tangible"
            label="Tangible"
            color="dark"
            size="32px"
          />
        </div>
        <div class="col-12 row">
          <span class="q-mb-sm col-12">Value(optional)</span>
          <q-input
            ref="valueInput"
            v-model="assetValue"
            outlined
            dense
            type="number"
            class="col-5"
            :placeholder="currency"
            no-error-icon
            :rules="[(val: number) => val >= 0 || 'Negative numbers are not allowed']"
          />
        </div>
      </q-form>
    </div>
    <div class="row action__row full-width justify-between q-mt-xl">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="closeModal" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[!validationPassed ? 'disable' : '']"
        :loading="isLoading"
        :disable="isLoading || (!isProjectOwner() && !isAdmin() && !isEditor)"
        @click="createAsset"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  :deep(.q-field) {
    padding: 0;
  }

  :deep(.q-radio) {
    :deep(.q-radio_inner--falsy) {
      .q-radio__bg {
        color: $common-1;
      }
    }
    :deep(.q-radio__bg) {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .q-radio__label {
      @include paragraph-01(400, $common-4);
    }
  }

  .disabled {
    opacity: 0.5;
  }

  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__native::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, $common-4);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-6);
    }
  }
  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
