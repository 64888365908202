<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useAuth } from '@/composables/Auth';
import { useAuthStore } from '@/stores/AuthStore';
import { useRoute, useRouter } from 'vue-router';

import { captureException } from '@/composables/Sentry';

import ADialog from '@/components/atoms/ADialog.vue';
import DPrivacyPolicy from '@/components/dialogs/DPrivacyPolicy.vue';

const route = useRoute();
const router = useRouter();
const { logout, toProblemPage } = useAuth();
const authStore = useAuthStore();
const showConfirmDialog = ref(false);
const isLoading = ref(false);

async function confirm() {
  isLoading.value = true;

  try {
    await authStore.updateTermsAccepted({ updateUser: { terms_accepted: true } });

    const user = localStorage.getItem('user');
    if (!user) return;

    const parse = JSON.parse(user);

    if (parse && parse.terms_accepted) {
      router.push({ name: 'home' });
      return;
    }
  } catch (error) {
    toProblemPage();

    captureException(error, {
      message: 'Component: OAuth2RedirectPage, Function: confirm',
    });
  } finally {
    isLoading.value = false;
  }
}

function closeConfirm() {
  showConfirmDialog.value = false;
  logout();
}

onMounted(async () => {
  const fragment = route.hash.replace('#', '');

  if (!fragment) {
    router.push({ name: 'login-page' });
    return;
  }
  const params = new URLSearchParams(fragment);
  const error = params.get('error');

  if (error) {
    router.push({ name: 'login-page' });
    return;
  }
  const code = params.get('code');

  if (!code) {
    router.push({ name: 'login-page' });
    return;
  }
  try {
    await authStore.getTokenFromAuthCode({
      grantType: 'authorization_code',
      code,
    });

    const user = localStorage.getItem('user');

    if (!user) {
      router.push({ name: 'login-page' });
      return;
    }

    const parse = JSON.parse(user);

    if (parse && parse.terms_accepted) {
      router.push({ name: 'home' });
      return;
    } else {
      showConfirmDialog.value = true;
    }
  } catch (error) {
    toProblemPage();

    captureException(error, {
      message: 'Component: OAuth2RedirectPage, Hook: onMounted',
    });
  }
});
</script>

<template>
  <div class="spinner-container">
    <q-spinner-oval class="spinner" color="primary" size="5em" />
  </div>
  <ADialog
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DPrivacyPolicy :is-loading="isLoading" @close-dialog="closeConfirm" @confirm="confirm" />
  </ADialog>
</template>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
