<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';

import type { LifecycleStageOverview } from '@/client/api';

import { AiLifecycleDashboardContent } from '@/client/api';

import { captureException } from '@/composables/Sentry';

import { useProjectsStore } from '@/stores/ProjectsStore';

import AStage from './DAtoms/AStage.vue';
import ARow from './DAtoms/ARow.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import STable from '@/components/skeletons/STable.vue';

interface RowData {
  value: number;
  percentage: number;
}

interface Rows {
  overall: Array<number>;
  in_review: RowData[];
  in_progress: RowData[];
  completed: RowData[];
}

const activeTab = ref('controls');
const projectsStore = useProjectsStore();
const lifecycleIsLoading = ref(false);

let rows = reactive<Rows>({
  overall: [],
  completed: [],
  in_review: [],
  in_progress: [],
});

const thumbStyle: Partial<CSSStyleDeclaration> = {
  right: '4px',
  borderRadius: '5px',
  backgroundColor: '#a5b7c0',
  width: '5px',
  opacity: '0.75',
};

const barStyle: Partial<CSSStyleDeclaration> = {
  right: '2px',
  borderRadius: '9px',
  backgroundColor: '#a5b7c0',
  width: '9px',
  opacity: '0.2',
};

async function changeTab(value: string) {
  if (!projectsStore.project) return;

  const content =
    value === 'controls' ? AiLifecycleDashboardContent.Control : AiLifecycleDashboardContent.Risk;

  const params = {
    projectId: projectsStore.project.id,
    dashboardContent: content,
  };
  try {
    lifecycleIsLoading.value = true;

    await projectsStore.getProjectLifecycle(params);

    populateRows();

    activeTab.value = value;
  } catch (error) {
    captureException(error, {
      message: 'Component: DLifecycle, Function: changeTab',
      data: {
        params: params,
      },
    });
  } finally {
    lifecycleIsLoading.value = false;
  }
}

function populateRows() {
  if (!projectsStore.lifecycleData) return;

  rows.overall = [];
  rows.completed = [];
  rows.in_review = [];
  rows.in_progress = [];

  projectsStore.lifecycleData.ai_lifecycle_stages.forEach((stage: LifecycleStageOverview) => {
    const {
      in_progress,
      in_progress_percentage,
      completed,
      completed_percentage,
      in_review,
      in_review_percentage,
    } = stage.status;

    const inProgressData = {
      value: in_progress || 0,
      percentage: in_progress_percentage || 0,
    };

    const completedData = {
      value: completed || 0,
      percentage: completed_percentage || 0,
    };

    const readyData = {
      value: in_review || 0,
      percentage: in_review_percentage || 0,
    };

    const total = (in_progress || 0) + (completed || 0) + (in_review || 0);

    rows.in_progress.push(inProgressData);
    rows.completed.push(completedData);
    rows.in_review.push(readyData);
    rows.overall.push(total);
  });
}
onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    lifecycleIsLoading.value = true;
    await projectsStore.getProjectLifecycle({ projectId: projectsStore.project.id });

    populateRows();
  } catch (error) {
    captureException(error, {
      message: 'Component: DLifecycle, Hook: onMounted, Method: getProjectLifecycle',
      data: {
        projectId: projectsStore.project.id,
      },
    });
  } finally {
    lifecycleIsLoading.value = false;
  }
});
</script>

<template>
  <div v-if="projectsStore.project" class="row">
    <div class="col-12">
      <span>AI System Lifecycle</span>
    </div>
    <div class="cycle-wrapp q-mt-xl col-12">
      <OTabPanels
        :active-tab="activeTab"
        default-tab="controls"
        :default-size="true"
        @change-tab="changeTab"
      >
        <template #tabs>
          <q-tab content-class="my-tab" name="controls" label="Controls"> </q-tab>
          <q-tab content-class="my-tab" name="risks" label="Risks"> </q-tab>
        </template>
        <template #panels>
          <q-tab-panel name="controls">
            <q-scroll-area
              v-if="projectsStore.lifecycleData && !lifecycleIsLoading"
              :thumb-style="thumbStyle"
              :bar-style="barStyle"
              style="height: 425px; max-width: 100%"
              class="qm-mb-md"
            >
              <div class="stages__container row">
                <div class="stage-wrapp">
                  <AStage
                    class="stage__container row items-center"
                    name="AI Cycle Stages"
                    description="AI Lifecycle stages"
                    :stage-icon="false"
                  />
                </div>
                <div
                  v-for="(key, value) in projectsStore.lifecycleData?.ai_lifecycle_stages"
                  :key="key.stage_name"
                  :value="value"
                  class="stage-wrapp"
                  :class="[
                    key.stage_name === projectsStore.project.ai_lifecycle_stage
                      ? 'active-wrapp'
                      : '',
                  ]"
                >
                  <AStage
                    class="stage__container row"
                    :class="[
                      key.stage_name === projectsStore.project.ai_lifecycle_stage
                        ? 'active-stage'
                        : '',
                    ]"
                    :name="key.stage_name"
                    :description="key.stage_description"
                    :active="key.stage_name === projectsStore.project.ai_lifecycle_stage"
                    :icon-name="
                      key.stage_name === projectsStore.project.ai_lifecycle_stage
                        ? 'deployed-white'
                        : 'deployed_code'
                    "
                  />
                </div>
              </div>
              <div class="rows__container row">
                <div v-for="(value, key) in rows" :key="key + activeTab" class="row-wrapp col-12">
                  <ARow class="row items-center full-width" :row="key" :items="value" />
                </div>
              </div>
            </q-scroll-area>
            <STable v-if="lifecycleIsLoading" />
          </q-tab-panel>
          <q-tab-panel name="risks">
            <q-scroll-area
              v-if="projectsStore.lifecycleData && !lifecycleIsLoading"
              :thumb-style="thumbStyle"
              :bar-style="barStyle"
              style="height: 425px; max-width: 100%"
              class="qm-mb-md"
            >
              <div class="stages__container row">
                <div class="stage-wrapp">
                  <AStage
                    class="stage__container row items-center"
                    name="AI Cycle Stages"
                    description="Ai Lifecycle stages"
                  />
                </div>
                <div
                  v-for="(key, value) in projectsStore.lifecycleData?.ai_lifecycle_stages"
                  :key="key.stage_name"
                  :value="value"
                  class="stage-wrapp"
                  :class="[
                    key.stage_name === projectsStore.project.ai_lifecycle_stage
                      ? 'active-wrapp'
                      : '',
                  ]"
                >
                  <AStage
                    class="stage__container row items-center"
                    :class="[
                      key.stage_name === projectsStore.project.ai_lifecycle_stage
                        ? 'active-stage'
                        : '',
                    ]"
                    :name="key.stage_name"
                    :description="key.stage_description"
                    :active="key.stage_name === projectsStore.project.ai_lifecycle_stage"
                    :icon-name="
                      key.stage_name === projectsStore.project.ai_lifecycle_stage
                        ? 'deployed-white'
                        : 'deployed_code'
                    "
                  />
                </div>
              </div>
              <div class="rows__container row">
                <div v-for="(value, key) in rows" :key="key + activeTab" class="row-wrapp col-12">
                  <ARow class="row items-center full-width" :row="key" :items="value" />
                </div>
              </div>
            </q-scroll-area>
            <STable v-if="lifecycleIsLoading" />
          </q-tab-panel>
        </template>
      </OTabPanels>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

span {
  @include heading-04(600, $secondary-600);
}

.cycle-wrapp {
  padding: 20px;
  background: $white;
  border-radius: 5px;

  :deep(.q-tabs) {
    margin: 0;
    .q-tab {
      padding: 0;
      margin-right: 20px;
      .q-tab__content {
        padding: 4px 10px 15px;
        flex-direction: column-reverse !important;
        align-items: flex-start !important;
        .q-tab__indicator {
          height: 4px;
        }
        .full-width {
          span {
            @include heading-03(600, $secondary-600);
          }
        }
      }
    }

    .q-tabs__arrow--left,
    .q-tabs__arrow--right {
      display: none !important;
    }
  }

  .q-tab-panel {
    padding: 0;
    .stages__container {
      background: #f2f7fd;
      flex-wrap: nowrap;

      .stage-wrapp {
        background: #f2f7fd;

        .stage__container {
          height: 100px;
          width: 102px;
          margin-left: 20px;
          position: relative;
        }
      }
      .stage-wrapp:nth-child(2) {
        padding-left: 35px;
      }
      .stage-wrapp:last-child {
        padding-right: 35px;
      }
      .stage-wrapp:not(:first-child):not(:last-child) {
        background: linear-gradient(to right, rgba(241, 246, 252, 1), rgba(217, 231, 248, 1));
      }
      .stage-wrapp:not(:first-child):not(:last-child) .stage__container::after {
        content: '';
        position: absolute;
        top: 0;
        right: -69px;
        bottom: 0;
        width: 0;
        height: 0;
        margin: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 70px solid rgba(217, 231, 248, 1);
        z-index: 0;
      }

      .stage-wrapp:first-child {
        :deep(.content) {
          max-width: 57px;
          span {
            margin-bottom: 10px;
          }
        }
      }
      .stage-wrapp:not(:first-child) {
        padding-left: 70px;
        :deep(.content) {
          span {
            margin-top: 5px;
          }
        }
      }
      .active-wrapp {
        background: #9caec4 !important;

        .active-stage {
          background: #9caec4 !important;
          :deep(span) {
            color: $white !important;
          }
        }
        .active-stage::after {
          border-left: 70px solid rgba(156, 174, 196, 1) !important;
        }
      }
    }

    .rows__container {
      padding: 0 20px;
      .row-wrapp:last-child {
        :deep(.row__container) {
          border-bottom: none;
        }
      }
    }
    hr {
      border: 1px solid $secondary-100;
    }
  }
  :deep(.q-panel) {
    overflow: hidden;
  }
}
</style>
