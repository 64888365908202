<script setup lang="ts">
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import type { ControlAggregator, TestingMetric, TestingSource } from '@/client/api';
import type { Details } from '@/interfaces/models/Select';
import type { QInput } from 'quasar';
import type { PropType } from 'vue';

import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_DESCRIPTION_SIZE, MAX_NAME_SIZE, statusIcon } from '@/composables/utils';
import {
  operatorFloatOptions,
  operatorIntegerOptions,
  operatorStringOptions,
} from '@/composables/Vendors';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { useAuthStore } from '@/stores/AuthStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useTaskStore } from '@/stores/TaskStore';

import Icon from '@/components/atoms/Icon.vue';

interface Select {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
}

interface Operator {
  type: string;
  name: string;
  value: string;
}

const props = defineProps({
  control: {
    type: Object as PropType<ControlAggregator>,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'addToExisting']);

const router = useRouter();
const authStore = useAuthStore();
const tasksStore = useTaskStore();
const projectsStore = useProjectsStore();
const name = ref('');
const description = ref('');
const source = ref<TestingSource | null>(null);
const attribute = ref<TestingMetric | null>(null);
const operator = ref<Operator | null>(null);
const value = ref<string | null | number>(null);
const sourceOptions = ref<TestingSource[]>([]);
const attributeOptions = ref<TestingMetric[]>([]);
const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);
const isManual = ref(false);
const disableSave = ref(true);
const associatedTest = ref<Select | null>(null);
const isCreation = ref(false);
const isLoading = ref(false);

const debouncedValidation = debounce(() => {
  validate();
}, 100);

function switchState(state: boolean) {
  isCreation.value = state;
  name.value = '';
  disableSave.value = true;
  associatedTest.value = null;
}

function onReset() {
  name.value = '';
}

async function associateRisk() {
  if (isCreation.value) {
    await createAssociation();
  } else {
    await selectFromExisting();
  }
}

async function fetchTests(details: Details) {
  if (!projectsStore.project || !projectsStore.testingData) return;

  if (
    projectsStore.testingData.total &&
    projectsStore.testingData.size &&
    details.index === filteredassociatedTests.value.length - 1 &&
    projectsStore.testingData.items.length < projectsStore.testingData.total
  ) {
    try {
      await projectsStore.getProjectTestingTests({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        size: projectsStore.testingData.size + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, {
        message: 'Component: DControl, Function: fetchRisks',
      });
    }
  }
}

async function fetchAdditionalTests() {
  if (!projectsStore.project || !projectsStore.testingData) return;

  if (
    projectsStore.testingData.total &&
    projectsStore.testingData.size &&
    projectsStore.testingData.items.length < projectsStore.testingData.total
  ) {
    try {
      await projectsStore.getProjectTestingTests({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        size: projectsStore.testingData.size + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, {
        message: 'Component: DControl, Function: fetchAdditionalTests',
      });
    }
  }
}

function validate() {
  if (
    isNameValid.value &&
    isDescriptionValid.value &&
    source.value &&
    attribute.value &&
    operator.value &&
    value.value
  ) {
    disableSave.value = false;
  } else {
    disableSave.value = true;
  }
}

async function getSourceAttributes() {
  if (!source.value || !projectsStore.project) return;

  attribute.value = null;
  operator.value = null;
  value.value = null;

  try {
    if (!source.value) {
      throw new Error('Source is not selected');
    }

    await projectsStore.getSingleSourceAttributes({
      projectId: projectsStore.project.id,
      testingSourceId: source.value.id,
    });

    attributeOptions.value = projectsStore.currentSourceAttributes
      ? projectsStore.currentSourceAttributes.items
      : [];

    validate();
  } catch (error) {
    captureException(error, {
      message: 'Component: DCreateTest, Function: getSourceAttributes',
    });
  }
}

function updateAttribute() {
  operator.value = null;
  value.value = null;
  validate();
}

function updateOperator() {
  value.value = null;
  validate();
}

function preventInvalidInput(event: KeyboardEvent) {
  if (!operator.value || operator.value.type !== 'integer') return;

  const invalidChars = ['.', ','];
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
}

async function createAssociation() {
  if (!projectsStore.project || !isNameValid.value || !authStore.user) return;

  isLoading.value = true;

  const conditions = {
    source_id: source.value ? source.value.id : '',
    metric_id: attribute.value ? attribute.value.id : '',
    operator: operator.value ? operator.value.value : '',
    value:
      attribute.value && attribute.value.type !== 'string'
        ? Number(value.value)
        : value.value || '',
  };

  const data = {
    name: name.value,
    description: description.value,
    conditions,
    assigned_to_id: authStore.user.id,
    status: true,
  };

  try {
    const params = {
      projectId: projectsStore.project.id,
      createTestingTest: data,
    };

    const response = await projectsStore.createTestingTest(params);

    if (!response) return;

    const addParams = {
      projectId: projectsStore.project.id,
      controlId: props.control.id,
      testingTestId: response.id,
    };

    await projectsStore.associateTestToControl(addParams);

    successMessage(
      `Test ${response.name} successfully associated with Control ${props.control.control_code}`,
    );

    if (response && response.id) {
      router.push(
        `/projects/project-detail/${projectsStore.project.id}/test-detail/${response.id}`,
      );
      emit('closeDialog');
    }
  } catch (error) {
    errorMessage('Failed to associate Test.');
    captureException(error, {
      message: 'Component: DControlCreateTest, Function: createAssociation',
    });
  } finally {
    isLoading.value = false;
  }
}

async function selectFromExisting() {
  if (!associatedTest.value || !tasksStore.task || !projectsStore.project) return;

  try {
    isLoading.value = true;

    await projectsStore.associateTestToControl({
      controlId: props.control.id,
      testingTestId: associatedTest.value.value,
      projectId: projectsStore.project.id,
    });

    successMessage(
      `Test ${associatedTest.value.label} successfully associated with Control ${tasksStore.task.name}`,
    );

    associatedTest.value = null;

    await tasksStore.getControlTests({
      controlId: props.control.id,
    });

    emit('closeDialog');
  } catch (error) {
    errorMessage('Failed to associate Test.');
    captureException(error, {
      message: 'Component: DControlCreateTest, Function: selectFromExisting',
    });
  } finally {
    isLoading.value = false;
  }
}

const isNameValid = computed(() => nameInput.value?.validate());
const isDescriptionValid = computed(() => descriptionInput.value?.validate());

const filteredassociatedTests = computed(() => {
  if (!projectsStore.testingData) return [];

  const controlTests = tasksStore.controlTests;

  if (!controlTests || controlTests.length === 0) {
    return projectsStore.testingData.items.map((item) => {
      return { label: item.name, value: item.id };
    });
  }

  return projectsStore.testingData.items
    .filter((item) => {
      return !controlTests.some((controlTest) => controlTest.id === item.id);
    })
    .map((item) => {
      return { label: item.name, value: item.id };
    });
});

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    projectsStore.getProjectTestingTests({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });

    await projectsStore.getProjectTestingSources({
      projectId: projectsStore.project.id,
    });

    sourceOptions.value = projectsStore.testingSources ? projectsStore.testingSources.items : [];
  } catch (error) {
    captureException(error, {
      message: 'Component: DCreateTest, Hook: onMounted',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Associate to a Test</h5>
    </div>
    <div class="row associated__control q-mb-md">
      <span class="associated-title q-mb-sm">Control</span>
      <div class="row col-12 control__container">
        <q-badge
          class="control-badge q-mr-sm q-mb-sm justify-center"
          :class="[control.status]"
          :label="control.control_code"
        >
          <Icon
            :icon-name="statusIcon(control.status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
        </q-badge>
      </div>
    </div>
    <div class="full-width q-mb-md">
      <q-btn-group unelevated spread>
        <q-btn
          flat
          unelevated
          label="Select from existing"
          :class="[!isCreation ? 'active' : '']"
          @click="switchState(false)"
        />
        <q-btn
          flat
          unelevated
          label="Create New Test"
          :class="[isCreation ? 'active' : '']"
          @click="switchState(true)"
        />
      </q-btn-group>
    </div>
    <div class="row q-mb-sm">
      <div v-if="isCreation" class="column">
        <div class="section general__section row q-mb-md">
          <span class="section__title q-mb-lg">General</span>
          <q-form class="row col-12" @submit.prevent="createAssociation" @reset="onReset">
            <span class="q-mb-sm">Test Name*</span>
            <q-input
              ref="nameInput"
              v-model="name"
              outlined
              dense
              class="col-12"
              placeholder="Insert Name"
              :rules="[
                (val: string) => (val && val.length > 0) || 'Please type something',
                (val: string) =>
                  (val && val.length <= MAX_NAME_SIZE) ||
                  `Maximum ${MAX_NAME_SIZE} characters allowed`,
              ]"
              no-error-icon
              @update:model-value="debouncedValidation"
            />
            <span class="col-12 q-mb-sm">Description</span>
            <q-input
              ref="descriptionInput"
              v-model="description"
              outlined
              placeholder="Insert description"
              no-error-icon
              dense
              class="col-12"
              type="textarea"
              input-style="height: 90px"
              :rules="[
                (val: string) =>
                  val.length <= MAX_DESCRIPTION_SIZE ||
                  `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
              ]"
              @update:model-value="debouncedValidation"
            />
          </q-form>
        </div>
        <div class="section condition__section row q-mb-md">
          <span class="section__title q-mb-lg">Condition</span>
          <q-form
            class="row col-12 q-col-gutter-sm"
            @submit.prevent="createAssociation"
            @reset="onReset"
          >
            <span class="q-mb-sm">Source*</span>
            <q-select
              v-model="source"
              outlined
              label="Please Select"
              :options="
                sourceOptions.map((item) => ({
                  id: item.id,
                  label: item.name,
                  mode: item.mode,
                }))
              "
              dense
              class="col-12"
              :rules="[(val: string) => val || 'Please select something']"
              @update:model-value="getSourceAttributes"
            />
            <div class="row col-4">
              <span class="col-12 q-mb-sm">Attribute*</span>
              <q-select
                v-model="attribute"
                outlined
                label="Please Select"
                :options="
                  attributeOptions.map((item) => ({
                    id: item.id,
                    label: item.name,
                    type: item.type,
                  }))
                "
                dense
                :disable="!source"
                class="col-12"
                :rules="[(val: string) => val || 'Please select something']"
                @update:model-value="updateAttribute"
              />
            </div>
            <div class="row col-5">
              <span class="col-12 q-mb-sm">Operator*</span>
              <q-select
                v-model="operator"
                outlined
                label="Please Select"
                :options="
                  attribute && attribute.type === 'integer'
                    ? operatorIntegerOptions.map((item) => ({
                        value: item.value,
                        label: item.name,
                        type: item.type,
                      }))
                    : attribute && attribute.type === 'string'
                      ? operatorStringOptions.map((item) => ({
                          value: item.value,
                          label: item.name,
                          type: item.type,
                        }))
                      : operatorFloatOptions.map((item) => ({
                          value: item.value,
                          label: item.name,
                          type: item.type,
                        }))
                "
                :disable="!attribute"
                dense
                class="col-12"
                :rules="[(val: string) => val || 'Please select something']"
                @update:model-value="updateOperator"
              />
            </div>
            <div class="row col-3">
              <span class="col-12 q-mb-sm">Value*</span>
              <q-input
                v-if="operator && operator.type === 'string'"
                ref="valueInput"
                v-model="value"
                outlined
                dense
                type="text"
                :disable="!operator"
                placeholder="Insert Value"
                :rules="[
                  (val: string) => (val && val.length > 0) || 'Please type something',
                  (val: string) =>
                    (val && val.length <= MAX_NAME_SIZE) ||
                    `Maximum ${MAX_NAME_SIZE} characters allowed`,
                ]"
                no-error-icon
                @update:model-value="debouncedValidation"
              />
              <q-input
                v-else
                ref="valueInput"
                v-model="value"
                outlined
                dense
                type="number"
                :disable="!operator"
                placeholder="Insert Value"
                :rules="[
                  (val: string) => (val && val.length > 0) || 'Please type something',
                  (val: string) =>
                    (val && val.length <= MAX_NAME_SIZE) ||
                    `Maximum ${MAX_NAME_SIZE} characters allowed`,
                ]"
                no-error-icon
                @update:model-value="debouncedValidation"
                @keypress="preventInvalidInput"
              />
            </div>
          </q-form>
        </div>
        <div class="section schedule__section row q-mb-md">
          <span class="section__title q-mb-lg">Schedule</span>
          <div class="full-width q-mb-md">
            <q-btn-group unelevated spread>
              <q-btn
                flat
                unelevated
                disable
                label="Schedule Testing"
                :class="[!isManual ? 'active' : '']"
                @click="switchState(false)"
              />
              <q-btn
                flat
                unelevated
                disable
                label="Run it manually"
                :class="[isManual ? 'active' : '']"
                @click="switchState(true)"
              />
            </q-btn-group>
          </div>
          <div v-if="!isManual" class="row col-4">
            <span class="col-12 q-mb-sm">Frequency</span>
            <span class="text-bold">Daily</span>
          </div>
        </div>
      </div>
      <q-select
        v-if="projectsStore.testingData && !isCreation"
        v-model="associatedTest"
        outlined
        :options="filteredassociatedTests"
        dense
        class="col-12"
        label="Select from an existing Test"
        :disable="projectsStore.testingData.items.length === 0"
        @popup-show="fetchAdditionalTests"
        @virtual-scroll="fetchTests"
      />
    </div>
    <div class="row action__row full-width justify-between q-mt-sm">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[disableSave ? 'disable' : '']"
        :loading="isLoading"
        :disable="(disableSave && !associatedTest) || isLoading"
        @click="associateRisk"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .disabled {
    opacity: 0.5;
  }

  .section {
    padding: 20px;
    background: $white;
    border-radius: 5px;
    .section__title {
      color: $secondary-600;
      font-size: 14px;
      font-weight: 600;
    }
  }

  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, #262626);
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
