<script lang="ts" setup>
import { ref, watch } from 'vue';

import type { PropType } from 'vue';
import type { FrameworkOverview, FrameworkControl, FrameworkRequirement } from '@/client/api';

import AFrameworkIcon from '@/components/atoms/AFrameworkIcon.vue';
import STable from '@/components/skeletons/STable.vue';

interface QTableColumn {
  name: string;
  label: string;
  field: string | ((row: { name: string }) => unknown);
  format?: (val: unknown, row: unknown) => unknown;
  required?: boolean;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  sort?: (a: string, b: string, rowA: unknown, rowB: unknown) => number;
  headerClasses?: string;
  classes?: string;
  style?: string;
}

type Data = FrameworkOverview | FrameworkControl | FrameworkRequirement;

const emit = defineEmits([
  'showFrameworkOverview',
  'selectConcept',
  'navigateToControl',
  'filterBySearch',
]);

const componentProps = defineProps({
  data: {
    type: Array<Data>,
    default: () => [],
  },
  columnsNames: {
    type: Object,
    default: () => null,
  },
  searchFilter: {
    type: Boolean,
    default: true,
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
  rowClickable: {
    type: Boolean,
    default: true,
  },
  rowsPerPageOptions: {
    type: Array as () => number[],
    default: () => [5, 10, 25, 50, 100],
  },
  sortable: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: 'Search',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  skeletonSize: {
    type: Number,
    default: 5,
  },
  skeletonColumns: {
    type: Number,
    default: 6,
  },
  rowClickAction: {
    type: String as PropType<'showFrameworkOverview' | 'selectConcept'>,
    default: '',
  },
  concept: {
    type: String,
    default: '',
  },
});

let columns: QTableColumn[] = [];
const filter = ref('');
const loading = ref(false);

function setColumns() {
  if (!componentProps.data || componentProps.data.length === 0) return;

  columns = [];
  const columnsNamesKeys = Object.keys(componentProps.columnsNames);
  let columnsToAdd: QTableColumn[] = [];

  for (let i = 0; i < componentProps.data.length; i++) {
    const keys = Object.keys(componentProps.data[i] as keyof typeof Object.keys);
    keys.forEach((key) => {
      if (columnsNamesKeys.includes(key) && !columns.some((column) => column.name === key)) {
        columnsToAdd.push({
          name: key,
          required: true,
          label: componentProps.columnsNames[key],
          align: 'left',
          field: key,
          format: (val, data) => formatRowValue(val, key, data),
          sortable: componentProps.sortable,
          headerClasses: 'text-bold',
          classes: key === 'status' ? 'td-status' : key === 'frameworks' ? 'td-frameworks' : '',
          style: 'white-space: wrap',
        });
      }
    });
  }

  columnsNamesKeys.forEach((key) => {
    const columnToAdd = columnsToAdd.find((column) => column.name === key);
    if (columnToAdd) {
      columns.push(columnToAdd);
    }
  });

  if (columnsNamesKeys.includes('content_overview')) {
    columns.push({
      name: 'content_overview',
      required: true,
      label: '',
      align: 'center',
      field: 'content_overview',
      sortable: false,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatRowValue(val: any, key: string, data: any) {
  if (!val && typeof val !== 'boolean' && val !== 0) return '-';

  if (
    (key === 'name' && componentProps.concept === 'control') ||
    (key === 'name' && componentProps.concept === 'framework') ||
    (key === 'name' && componentProps.concept === 'requirement')
  ) {
    return `${val} (${data.code})`;
  }

  return val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowClick(evt: object, row: any) {
  emit(componentProps.rowClickAction, row);
}

watch(
  () => componentProps.data,
  (newV) => {
    if (!newV) return;

    setColumns();
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div>
    <div v-if="searchFilter" class="search-filter__container row justify-between">
      <div class="row col-12 justify-between">
        <div class="row items-center">
          <q-input
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="searchPlaceholder"
            :class="[isLoading ? 'skeleton' : '']"
            @update:model-value="emit('filterBySearch', filter)"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
          <slot name="header-filters" />
        </div>
      </div>
    </div>
    <q-table
      v-if="data && data.length > 0 && !isLoading"
      :rows="data"
      :filter="filter"
      :columns="columns"
      row-key="name"
      :hide-pagination="!showPagination"
      :rows-per-page-options="rowsPerPageOptions"
      rows-per-page-label="Showing records per page: "
      :loading="loading"
      binary-state-sort
      @row-click="onRowClick"
    >
      <template #body-cell-name="props">
        <q-td :props="props">
          <div class="row items-center">
            <AFrameworkIcon
              v-if="concept === 'framework'"
              :framework="props.row"
              class="q-mr-md framework-icon__container"
            />

            <span>{{ props.value }}</span>
          </div>
        </q-td>
      </template>
      <template #body-cell-content_overview="props">
        <q-td :props="props">
          <div class="row justify-center">
            <q-btn
              class="gt-xs overview-button"
              size="12px"
              flat
              dense
              round
              label="Browse Content"
              @click.stop="emit('showFrameworkOverview', props.row)"
            />
          </div>
        </q-td>
      </template>
      <template #body-cell-tags="props">
        <q-td :props="props">
          <div class="row items-center">
            <div v-for="tag in props.value" :key="tag.name" class="q-mr-xs q-mb-xs">
              <q-badge class="tag-badge" :label="`${tag.name}: ${tag.value}`" />
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-controls="props">
        <q-td :props="props">
          <div v-if="props.value && props.value.length > 0" class="row">
            <q-badge
              v-for="control in props.value"
              :key="control"
              :label="control"
              class="control-badge q-mr-sm q-mb-sm"
              @click.stop.prevent="emit('navigateToControl', control)"
            >
            </q-badge>
          </div>
          <div v-else class="row">
            <span>{{ '-' }}</span>
          </div>
        </q-td>
      </template>
      <template #pagination="scope">
        <q-pagination
          v-model="scope.pagination.page"
          :max="Math.ceil(componentProps.data.length / scope.pagination.rowsPerPage)"
          :max-pages="6"
          direction-links
        />
      </template>
    </q-table>
    <STable v-if="isLoading" :skeleton-size="skeletonSize" :skeleton-columns="skeletonColumns" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.search-filter__container {
  padding: 20px;
  background: $white;
  border-radius: 5px;

  :deep(button) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
  :deep(a) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
}

:deep(.q-table__container) {
  padding: 20px;
  border-radius: 5px;
}
:deep(.q-table__card) {
  box-shadow: none !important;
}
:deep(.q-table--horizontal-separator thead th) {
  border-bottom: 1px solid #f0f0f0 !important;
}
:deep(.text-bold) {
  color: $common-4;
  font-size: 14px !important;
}
:deep(thead tr) {
  background: $secondary-50;
  th:first-child {
    border-top-left-radius: 5px;
  }
  th:last-child {
    border-top-right-radius: 5px;
  }
  th {
    @include paragraph-01(600, $common-5);
  }
}
:deep(tbody tr) {
  position: relative !important;
}
:deep(tbody td) {
  @include paragraph-01(400, $common-5);
  max-width: 200px;
  padding: 17px;
  position: unset !important;
  white-space: normal;

  span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .control-badge,
  .risk-badge {
    max-width: 100px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .q-linear-progress {
    width: 75px;
    color: #62a6f9 !important;
  }

  .framework-icon__container {
    img {
      width: 35px;
    }
  }
}
:deep(tbody tr:hover) {
  cursor: pointer;
  background-color: $secondary-100;
  td::before {
    display: none;
  }
}
:deep(tbody .no-hover:hover) {
  cursor: default;
  background-color: $white;
  td::before {
    display: none;
  }
}
:deep(.q-table__bottom) {
  border: none;
  button {
    @include paragraph-01(400, $common-4);
    height: 35px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 4px 12px;
    margin: 0 4px;
    color: $common-4 !important;
    i {
      color: $common-4;
    }
  }

  .bg-primary {
    color: $white !important;
    background: $secondary-500 !important;
  }
  .disabled {
    cursor: not-allowed;
    i {
      color: $common-1;
    }
  }
  .button-page-number {
    padding: 4px 12px;
  }
  .q-table__control:nth-child(2) {
    position: absolute;
    left: 20px;
    .q-table__bottom-item {
      @include paragraph-01(400, $common-2);
    }
    .q-field__inner {
      padding: 0px 12px;
      border-radius: 4px;
      border: 1px solid $common-1;
    }
  }
}

.overview-button {
  padding: 8px !important;
  border: 1px solid $secondary-500 !important;
  border-radius: 5px !important;
  background: transparent;
  color: $secondary-500;
  text-transform: none;
}
</style>
