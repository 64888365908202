<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useDashboardStore } from '@/stores/DashboardStore';

import { captureException } from '@/composables/Sentry';
import { AiProjectCreationAllowed, OrgProjectCreationAllowed } from '@/composables/Config';

import Icon from '@/components/atoms/Icon.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import ProjectCard from '@/components/molecules/ProjectCardv2.vue';
import OControls from '@/components/organisms/Dashboard/OControls.vue';
import ORisks from '@/components/organisms/Dashboard/ORisks.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import SCard from '@/components/skeletons/SCard.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DProjectsLimit from '@/components/dialogs/DProjectsLimit.vue';

const dashboardStore = useDashboardStore();
const projectsStore = useProjectsStore();
const isProjectsLoading = ref(false);
const activeTab = ref('controls');
const projectsTotal = ref(0);
const showLimitDialog = ref(false);

function changeTab(value: string) {
  activeTab.value = value;
}

function closeDialog() {
  showLimitDialog.value = false;
}

watch(
  () => projectsStore.projectsData,
  (newV) => {
    if (!newV || newV.total === null) return;

    projectsTotal.value = newV.total;
  },
);

onMounted(async () => {
  try {
    isProjectsLoading.value = true;

    await dashboardStore.getDashboardProjects({
      size: 5,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: DashboardPage, Hook: onMounted, Method: getDashboardProjects',
    });
  } finally {
    isProjectsLoading.value = false;
  }
});
</script>

<template>
  <div class="dashboard__wrapper">
    <div class="row col-12">
      <OTabPanels
        :active-tab="activeTab"
        :default-size="true"
        default-tab="controls"
        class="col-12"
        @change-tab="changeTab"
      >
        <template #tabs>
          <q-tab content-class="my-tab" name="controls" label="Controls" />
          <q-tab content-class="my-tab" name="risks" label="Risks" />
        </template>
        <template #panels>
          <q-tab-panel name="controls" class="row col-12 q-pa-none">
            <OControls class="col-12" />
          </q-tab-panel>
          <q-tab-panel name="risks" class="row col-12 q-pa-none">
            <ORisks class="col-12" />
          </q-tab-panel>
        </template>
      </OTabPanels>
    </div>
    <div class="row items-center projects__header justify-between q-mb-md">
      <div class="row">
        <Icon icon-name="folder_copy" icon-folder="dashboard" class="q-mr-sm" />
        <span>Projects overview</span>
      </div>
      <div class="buttons__container row">
        <q-btn class="see-all q-mr-md" label="See all projects" unelevated to="/projects" />
        <q-btn
          v-if="AiProjectCreationAllowed() || OrgProjectCreationAllowed()"
          icon="add"
          label="New Project"
          unelevated
          to="/projects/new-project"
        />
        <q-btn
          v-if="!AiProjectCreationAllowed() && !OrgProjectCreationAllowed()"
          icon="add"
          label="New Project"
          unelevated
          @click="showLimitDialog = true"
        />
      </div>
    </div>
    <div
      v-if="
        dashboardStore.dashboardProjects &&
        dashboardStore.dashboardProjects.items.length > 0 &&
        !isProjectsLoading
      "
      class="cards__container row q-col-gutter-x-md q-col-gutter-y-md full-width"
    >
      <div
        v-for="(card, index) in dashboardStore.dashboardProjects.items"
        :key="card.id"
        class="col-12 col-sm-6 col-md-4 col-lg-3"
        :class="{ 'no-left-padding': index === 0 }"
      >
        <ProjectCard :card="card" />
      </div>
    </div>
    <div
      v-if="
        (!dashboardStore.dashboardProjects && !isProjectsLoading) ||
        (dashboardStore.dashboardProjects?.items.length === 0 && !isProjectsLoading)
      "
      class="row empty__container"
    >
      <AEmptyData
        icon-name="folder"
        header="You don’t have any projects yet."
        text="Start by creating a New Project."
        class="full-width items-center"
      />
    </div>
    <div
      v-if="isProjectsLoading"
      class="cards__container row q-col-gutter-x-md q-col-gutter-y-md full-width"
    >
      <div
        v-for="n in 4"
        :key="n"
        class="col-12 col-sm-6 col-md-4 col-lg-3"
        :class="{ 'no-left-padding': n === 1 }"
      >
        <SCard />
      </div>
    </div>
  </div>

  <ADialog
    :show-dialog="showLimitDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showLimitDialog = false"
  >
    <DProjectsLimit projects-type="AI Application and Organizational" @close-dialog="closeDialog" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.dashboard__wrapper {
  padding: 25px 52px 0;
  .projects__header {
    margin-top: 60px;
    span {
      @include heading-04(600, $secondary-600);
    }
    button {
      background: $secondary-500 !important;
      border-radius: 5px;
      text-transform: none;
    }
    a {
      background: $secondary-500 !important;
      border-radius: 5px;
      text-transform: none;
    }
    .see-all {
      background: transparent !important;
      color: $secondary-600;
      border: 1px solid $secondary-600;
    }
  }
  .empty__container {
    min-height: 400px;
    background: $white;
    margin: 20px 0;
  }
}
</style>
