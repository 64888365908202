<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useFrameworkStore } from '@/stores/FrameworkStore';

import AEmptyData from '../atoms/AEmptyData.vue';
import MFrameCardDefault from '@/components/molecules/MFrameCardDefault.vue';

const emit = defineEmits(['closeDialog', 'addToExisting']);

const router = useRouter();
const projectsStore = useProjectsStore();
const frameworksStore = useFrameworkStore();

function doesFrameworkExist(code: string) {
  return projectsStore.projectFrameworks.some((item) => item.framework_code === code);
}

function redirectTo() {
  if (!projectsStore.project) return;

  router.push({
    path: `/organization/templates`,
  });
}

const filteredFrameworks = computed(() => {
  if (!frameworksStore.frameworksData) return;

  return frameworksStore.frameworksData.filter((item) => !doesFrameworkExist(item.framework_code));
});
</script>

<template>
  <div v-if="projectsStore.project" class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">{{ `Add Framework to the ${projectsStore.project.name}` }}</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div
      v-if="frameworksStore.frameworksData && filteredFrameworks && filteredFrameworks.length > 0"
      class="cards__container row q-col-gutter-x-md q-col-gutter-y-md full-width q-mt-sm"
    >
      <div
        v-for="framework in filteredFrameworks"
        :key="framework.framework_code"
        class="col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <MFrameCardDefault :card="framework" />
      </div>
    </div>
    <AEmptyData
      v-else
      icon-name="drawer"
      :header="`There are currently no other available frameworks.`"
      text="Please upload a new framework in organization"
      action-text="settings section."
      class="full-width items-center"
      @click-text="redirectTo"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
}
</style>
