<script setup lang="ts">
import { ref, onMounted } from 'vue';

import type { Roles } from '@/client/api';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';

import { successMessage } from '@/composables/Notify';
import { roleDescription, UpperCaseRoles } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import Icon from '@/components/atoms/Icon.vue';
import ASelectSearch from '@/components/atoms/ASelectSearch.vue';
import ATooltip from '@/components/atoms/ATooltip.vue';

interface Select {
  value: string;
  label: string;
}

const emit = defineEmits(['closeDialog', 'updateData']);

const projectsStore = useProjectsStore();
const organizationStore = useOrganizationStore();
const user = ref<null | string>(null);
const selected = ref([]);
const roles = ref([
  UpperCaseRoles.Owner,
  UpperCaseRoles.Auditor,
  UpperCaseRoles.Editor,
  UpperCaseRoles.Reviewer,
]);
const isLoading = ref(false);

function convertFirstLetterToLowercase(arr: string[]): Roles[] {
  return arr.map(
    (word: string) =>
      word
        .split(' ')
        .map((w) => w.charAt(0).toLowerCase() + w.slice(1))
        .join(' ') as Roles,
  );
}

function selectUser(value: Select | null) {
  if (!value) {
    selected.value = [];
    user.value = null;
    return;
  }
  user.value = value.value;
}

async function addUser() {
  if (!projectsStore.project || !user.value) return;

  const newArr = convertFirstLetterToLowercase(selected.value);

  const params = {
    userId: user.value,
    projectId: projectsStore.project.id,
    roles: newArr,
  };

  try {
    isLoading.value = true;

    await projectsStore.updateUserRole(params);

    successMessage('User role(s) updated successfully');

    emit('updateData');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DAddUser, Function: addUser',
      data: {
        user: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  try {
    await organizationStore.getOrganizationUsers({
      ...DEFAULT_PAGINATION,
      size: 50,
      includeInactive: true,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: DAddUser, Hook: onMounted, Method: getOrganizationUsers',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person_add" icon-folder="settings" class="q-mr-md" />
      <h5 class="q-ma-none">Add User to the Project</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="column select__row">
      <div class="row items-center q-mb-sm">
        <span>User</span>
        <ATooltip
          icon-folder="settings"
          icon-name="info-medium"
          tooltip-text="Users previously added in the organisation level. "
          class="q-ml-sm"
        />
      </div>
      <div class="row">
        <ASelectSearch
          v-if="organizationStore.usersData"
          ref="userSelect"
          :options="
            organizationStore.usersData.items.map((item) => ({
              value: item.id,
              label: item.firstname + ' ' + item.lastname,
            }))
          "
          class="col-12"
          label="Select a User"
          :loading="isLoading"
          @update-select="selectUser"
        />
      </div>
    </div>
    <div class="column roles__row q-my-md">
      <span class="q-mb-sm">Roles</span>
      <div class="row roles__container" :class="[user ? '' : 'disabled']">
        <div v-for="role in roles" :key="role" class="role row q-mr-sm">
          <q-checkbox v-model="selected" :val="role" :disable="!user" size="16px" />
          <span class="role__text q-mx-sm">{{ role }}</span>
          <ATooltip
            icon-folder="settings"
            icon-name="info-medium"
            :tooltip-text="roleDescription(role)"
          />
        </div>
      </div>
    </div>
    <div class="row action__row full-width justify-end q-mt-lg">
      <q-btn
        class="btn-add"
        label="Add User"
        :disable="!user || selected.length === 0 || isLoading || (!isProjectOwner() && !isAdmin())"
        :loading="isLoading"
        unelevated
        @click="addUser"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .roles__row {
    .role {
      span {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-600;
      }
      .q-checkbox {
        :deep(.q-checkbox__bg) {
          width: 100%;
          height: 100%;
          position: unset;
        }

        :deep(.q-checkbox__inner--falsy) {
          .q-checkbox__bg {
            color: $common-1;
          }
        }
        :deep(.q-checkbox__inner--truthy) {
          .q-checkbox__bg {
            color: $secondary-800;
          }
        }
        :deep(.q-checkbox__label) {
          @include paragraph-02(600, $common-5);
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .btn-add {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
