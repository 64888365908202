<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { PropType } from 'vue';
import type { CreationFrameworkTemplate } from '@/client/api';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

const props = defineProps({
  card: {
    type: Object as PropType<CreationFrameworkTemplate>,
    required: true,
  },
});

const projectsStore = useProjectsStore();
const isLoading = ref(false);
const frameworkImage = ref<string | null>(null);

async function addFramework() {
  if (!projectsStore.project) return;
  isLoading.value = true;

  const params = {
    projectId: projectsStore.project.id,
    requestBody: [props.card.framework_code],
  };

  try {
    await projectsStore.createProjectFramework(params);
    successMessage('Framework(s) successfully added to the project');

    await projectsStore.getProjectDashboard({ projectId: projectsStore.project.id });
    await projectsStore.getProject({ projectId: projectsStore.project.id });
  } catch (error) {
    captureException(error, { message: 'Component: MFrameCardDefault, Function: addFramework' });
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  if (props.card.framework_icon_type === 'image' && props.card.framework_icon_base64) {
    frameworkImage.value = `data:image/svg+xml;base64,${props.card.framework_icon_base64}`;
  }
});
</script>

<template>
  <q-card v-if="card" class="my-card text-white column justify-between">
    <div class="top__container">
      <q-card-section class="title__section row q-pb-none q-mb-xs items-center">
        <span
          v-if="card.framework_icon_type === 'flag'"
          class="fi q-mr-sm flag"
          :class="`fi-${card.framework_icon}`"
        ></span>
        <img
          v-if="
            card.framework_icon_type === 'image' && card.framework_icon_base64 && frameworkImage
          "
          :src="frameworkImage"
          class="q-mr-sm"
        />
        <span class="text-h6 col-8">{{ card.framework_name }}</span>
      </q-card-section>
      <q-card-section v-if="card.framework_label !== ''" class="badge__section">
        <q-badge class="general-badge justify-center" :label="card.framework_label" />
      </q-card-section>
      <q-card-section class="description__section">
        <p class="description">{{ card.framework_description }}</p>
      </q-card-section>
    </div>
    <div class="bottom__container q-mt-sm">
      <q-card-section class="content__section q-pt-none column justify-between">
        <div class="project__details">
          <div class="row justify-between q-mb-lg">
            <div class="column items-start q-mb-sm">
              <Icon icon-name="icon-beenhere" icon-folder="black" />
              <span class="black-text q-mt-xs"> {{ card.number_of_requirements }} </span>
              <span>Requirements</span>
            </div>
            <div class="column items-end q-mb-sm">
              <Icon v-if="!$q.dark.isActive" icon-name="page_info" icon-folder="black" />
              <Icon v-else icon-name="page_info" icon-folder="white" />
              <span class="black-text q-mt-xs"> {{ card.number_of_controls }} </span>
              <span>Controls</span>
            </div>
          </div>
        </div>

        <div class="tasks__total row justify-end">
          <q-btn
            v-if="projectsStore.project"
            flat
            color="primary"
            label="Add Framework"
            class="full-width"
            :disable="isLoading"
            :loading="isLoading"
            @click="addFramework"
          />
        </div>
      </q-card-section>
    </div>
  </q-card>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.my-card {
  height: 100%;
  @include paragraph-01(400, #8e969d);
  color: #8e969d !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  .black-text {
    font-size: 18px;
    font-weight: 700;
    color: $secondary-800;
  }
  .text-h6 {
    font-size: 16px;
    font-weight: 600;
    color: $secondary-800;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .subheader {
    @include paragraph-01(400, $common-2);
  }
  .project__description {
    @include paragraph-01(400, $secondary-800);
  }
  hr {
    border-top: none;
    border-bottom: 1px solid #e6e6e6;
  }
  .title__section {
    position: relative;
    .flag {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      background-size: cover;
    }

    :deep(img) {
      min-width: 42px;
      min-height: 42px;
      max-width: 42px;
      max-height: 42px;
      width: auto;
      height: auto;
      display: block;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .description__section {
    @include paragraph-01(400, $secondary-600);
    padding-top: 10px !important;
  }
  .content__section {
    .tasks__total {
      :deep(a) {
        min-height: 40px;
        background: transparent;
        border: 1px solid $secondary-500;

        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
      }
      :deep(button) {
        min-height: 40px;
        background: transparent;
        border: 1px solid $secondary-500;

        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
      }
    }
  }
  .badge__section {
    padding: 0 24px 8px !important;
  }
}
</style>
