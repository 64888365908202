/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from '@/composables/Auth';
import router from '@/router';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { type Router } from 'vue-router';

const retryMap = new Map();

async function handle401Error(axios: AxiosInstance, originalConfig: any, error: any) {
  const authStore = useAuthStore();

  const token = await authStore.getAccessTokenFromRefreshToken();
  if (!token) {
    const { logout } = useAuth();
    logout();

    return;
  }

  const originalRequest = error.config;
  originalRequest.headers['Authorization'] = `Bearer ${token}`;

  return axios(originalRequest);
}

function decodeMessage(error: any) {
  const reader = new FileReader();

  reader.onload = function () {
    const result = reader.result;
    const store = useGlobalStore();

    if (typeof result === 'string') {
      const responseData = JSON.parse(result);

      store.$q.notify({
        message: responseData.detail,
        color: 'red',
      });
    } else if (result instanceof ArrayBuffer) {
      const textDecoder = new TextDecoder();
      const responseData = JSON.parse(textDecoder.decode(result));

      store.$q.notify({
        message: responseData.detail,
        color: 'red',
      });
    } else {
      console.error(
        'Error occurred while reading the Blob: Result is not a string or ArrayBuffer.',
      );
    }
  };

  reader.onerror = function () {
    console.error('Error occurred while reading the Blob.');
  };
  reader.readAsText(error.response.data);
}

async function handleError(
  axios: AxiosInstance,
  router: Router,
  originalConfig: any,
  error: any,
  showNotifications: boolean = true,
) {
  const store = useGlobalStore();

  const message = error.response.data?.detail || error.response.statusText || 'An error occurred.';

  if (error.response.status >= 500) {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/api/health`);

    if (response.status !== 200) {
      router.push('/service_down');
    }
    return Promise.reject(error);
  }

  const configId = originalConfig.url + (originalConfig.method || 'get');
  const hasRetried = retryMap.get(configId);

  if (error.response.status === 401 && !hasRetried) {
    retryMap.set(configId, true);

    return handle401Error(axios, originalConfig, error);
  } else if (error.response.status === 401 && hasRetried) {
    const { logout } = useAuth();
    retryMap.delete(configId);
    logout();

    return;
  }

  if (error.response.status === 404) {
    return Promise.reject(error);
  }

  // TODO: Remove this when stops handling errors from the interceptor.
  if (showNotifications) {
    if (typeof message === 'string') {
      store.$q.notify({
        message: message,
        color: 'red',
      });
    } else {
      decodeMessage(error);
    }
  }

  return Promise.reject(error);
}

function defineInterceptors(axios: AxiosInstance, router: Router, showNotifications = true) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (error.response) {
        return handleError(axios, router, originalConfig, error, showNotifications);
      }
      return Promise.reject(error);
    },
  );
}

export default defineInterceptors;

export function getHttpClient({ showNotifications = true }: { showNotifications?: boolean } = {}) {
  const httpClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
  });

  defineInterceptors(httpClient, router, showNotifications);

  return httpClient;
}
