<script setup lang="ts">
import { useQuasar } from 'quasar';
import { ref, onMounted, nextTick } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useRisksStore } from '@/stores/RisksStore';

import type { PropType } from 'vue';
import type { Risk } from '@/client/api';
import type { RiskTreatmentStrategy } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import { successMessage } from '@/composables/Notify';
import { DEFAULT_THRESHOLD } from '@/composables/CRisk';
import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import ARiskInfo from '@/components/atoms/ARiskInfo.vue';
import AEmptyData from '../atoms/AEmptyData.vue';
import ThresholdSlider from '@/components/Risks/ThresholdSlider.vue';
import STreatment from '@/components/sidebars/STreatment.vue';
import OMatrix from '@/components/organisms/OMatrix.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';
import ORiskStrategy from '@/components/organisms/ORiskStrategy.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';

import { ImpactLabels, LikelihoodLabels } from '@/composables/CRisk';

interface Matrix {
  renderMatrix: () => void;
}

interface Strategy extends RiskTreatmentStrategy {
  default: boolean;
}

defineProps({
  risk: {
    type: Object as PropType<Risk>,
    required: true,
  },
  isAllowed: {
    type: Boolean,
    default: false,
  },
});

const $q = useQuasar();

const projectsStore = useProjectsStore();
const risksStore = useRisksStore();
const showSidebar = ref(false);

const editor = ref<TipTapContent>({
  content: '',
  json_content: {},
});

const selectedStrategyId = ref<string | null>(null);
const selectedStrategy = ref<Strategy | null>(null);
const editStrategy = ref<Strategy | null>(null);

const creation = ref(true);

const matrixComponent = ref<Matrix | null>(null);
const impactValue = ref<number>(0);
const likelihoodValue = ref<number>(0);
const dValue = ref<number>(1);
const conclusionOpened = ref(true);
const isLoading = ref(false);
const deleteStrategyId = ref('');
const showConfirmDialog = ref(false);
const isUpdating = ref(false);
const confirmationIsLoading = ref(false);
function hideSidebar() {
  showSidebar.value = false;
}

async function openSidebar(empty: boolean, id: string = '') {
  showSidebar.value = false;

  await nextTick();

  if (empty) {
    editStrategy.value = null;
    creation.value = true;
    showSidebar.value = true;

    return;
  }

  const strategy = risksStore.riskTreatmentStrategies.find((s) => s.id === id);

  if (!strategy) return;

  editStrategy.value = { ...strategy, default: empty };
  creation.value = false;
  showSidebar.value = true;
}

function selectStrategy(id: string, selected: boolean) {
  if (!selected) {
    selectedStrategyId.value = '';
    selectedStrategy.value = null;

    return;
  }

  selectedStrategyId.value = id;

  const strategy = risksStore.riskTreatmentStrategies.find((s) => s.id === id);

  if (!strategy) return;

  selectedStrategy.value = { ...strategy, default: false };
}

async function createStrategy(strategy: Strategy) {
  if (!risksStore.currentRisk) return;

  const params = {
    riskId: risksStore.currentRisk.id,
    riskTreatmentStrategyProps: strategy,
  };

  try {
    const strategyData = await risksStore.createRiskTreatmentStrategy(params);

    if (strategyData) {
      successMessage(`Strategy ${strategyData.name} was created`);
    }

    await risksStore.getRiskTreatmentStrategy({ riskId: risksStore.currentRisk.id });
  } catch (error) {
    captureException(error, { message: 'Component: RiskTreatment, Function: createStrategy' });
  }
}

async function updateStrategy(id: string, newStrategy: Strategy) {
  if (!risksStore.currentRisk) return;
  try {
    const params = {
      strategyId: id,
      riskId: risksStore.currentRisk.id,
      riskTreatmentStrategyProps: newStrategy,
    };

    const strategy = await risksStore.updateRiskTreatmentStrategy(params);

    if (strategy) {
      successMessage(`Strategy ${strategy.name} was updated`);
    }
    await risksStore.getRiskTreatmentStrategy({ riskId: risksStore.currentRisk.id });
  } catch (e) {
    captureException(e, { message: 'Component: RiskTreatment, Function: updateStrategy' });
  }
}

async function deleteStrategy(id: string) {
  if (id === selectedStrategyId.value) {
    $q.notify({
      color: 'red',
      textColor: 'white',
      icon: 'lock',
      message: 'Strategy is selected and cannot be deleted.',
    });
    return;
  }

  deleteStrategyId.value = id;
  showConfirmDialog.value = true;
}

async function updateTreatment() {
  if (!risksStore.currentRisk) return;

  const treatmentData = {
    conclusion: editor.value.content,
    json_conclusion: editor.value.json_content,
    selected_strategy_id: selectedStrategyId.value,
  };

  const params = {
    riskId: risksStore.currentRisk.id,
    riskTreatmentProps: treatmentData,
  };
  try {
    isUpdating.value = true;

    await risksStore.updateRiskTreatment(params);

    successMessage(`Risk treatment was updated`);
  } catch (error) {
    captureException(error, {
      message: 'Component: RiskTreatment, Function: updateTreatment',
      data: {
        params: params,
      },
    });
  } finally {
    isUpdating.value = false;
  }
}

function toggleConclusion() {
  conclusionOpened.value = !conclusionOpened.value;
}

function updateEditor(value: TipTapContent) {
  editor.value = value;
}

function closeConfirm() {
  showConfirmDialog.value = false;
}

async function confirm() {
  if (!risksStore.currentRisk) return;

  try {
    confirmationIsLoading.value = true;

    const params = {
      riskId: risksStore.currentRisk.id,
      strategyId: deleteStrategyId.value,
    };

    await risksStore.deleteRiskTreatmentStrategy(params);

    successMessage(`Strategy  was deleted`);

    await risksStore.getRiskTreatmentStrategy({ riskId: risksStore.currentRisk.id });

    showConfirmDialog.value = false;
  } catch (e) {
    captureException(e, { message: 'Component: RiskTreatment, Function: confirm' });
  } finally {
    confirmationIsLoading.value = false;
  }
}

onMounted(async () => {
  if (!risksStore.currentRisk) return;

  impactValue.value = risksStore.currentRisk.risk_impact || 0;
  likelihoodValue.value = risksStore.currentRisk.risk_likelihood || 0;
  dValue.value = risksStore.currentRisk.risk_detection || 1;

  try {
    isLoading.value = true;
    await risksStore.getRiskTreatmentStrategy({ riskId: risksStore.currentRisk.id });

    if (!risksStore.riskTreatment) return;

    selectedStrategyId.value = risksStore.riskTreatment.selected_strategy_id || null;
    const selected = risksStore.riskTreatmentStrategies.find(
      (s: RiskTreatmentStrategy) => s.id === risksStore.riskTreatment?.selected_strategy_id,
    );
    if (!selected) return;

    selectedStrategy.value = { ...selected, default: false } || null;

    editor.value = {
      content: risksStore.riskTreatment.conclusion || '',
      json_content: risksStore.riskTreatment.json_conclusion || {},
    };
  } catch (error) {
    captureException(error, {
      message: 'Component: RiskTreatment, Hook: onMounted, Method: getRiskTreatmentStrategy',
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div class="treatment__wrapp column">
    <div
      class="strategies__container column full-width q-mb-md"
      :class="[isLoading ? 'skeleton-v2' : '']"
    >
      <div class="strategies__header row full-width justify-between">
        <div class="strategies__header-left column">
          <span class="strategies__title">Strategies</span>
        </div>
        <div class="strategies__header-right">
          <q-btn
            v-if="isAllowed"
            icon="add"
            label="New Strategy"
            unelevated
            @click="openSidebar(true)"
          />
        </div>
      </div>
      <div class="strategies__content full-width">
        <div class="content__header relative-position row justify-center full-width">
          <ARiskInfo
            info-name="Inherent Risk"
            :info-value="risk.risk_rpn_value || 0"
            :badge-text="risk.rpn_risk_level || ''"
            tooltip-text="The inherent risk is the risk at the assessment phase before any treatment strategies have been applied."
            :badge-type="
              risk.rpn_risk_level
                ? risk.rpn_risk_level.toLowerCase().replaceAll(' ', '-') + '-badge'
                : 'badge'
            "
          />
          <ARiskInfo
            v-if="projectsStore.project"
            class="absolute-position"
            info-name="Risk Threshold"
            :info-value="projectsStore.project.risk_threshold || DEFAULT_THRESHOLD"
            badge-text="low"
            tooltip-text="Risk threshold : indicates a risk’s tolerance level."
            badge-type="low-badge"
          />
        </div>
        <div
          class="content__main row justify-center full-width"
          :style="{
            height:
              risksStore.riskTreatmentStrategies && risksStore.riskTreatmentStrategies.length > 0
                ? '750px'
                : '500px',
          }"
        >
          <q-scroll-area
            v-if="
              risksStore.riskTreatmentStrategies && risksStore.riskTreatmentStrategies.length > 0
            "
            class="fit"
          >
            <ORiskStrategy
              v-for="strategy in risksStore.riskTreatmentStrategies"
              :key="strategy.id"
              :strategy="strategy"
              :strategy-id="strategy.id"
              :selected-strategy-id="selectedStrategyId || ''"
              :default-strategy="strategy.default"
              :strategy-name="strategy.name"
              :residual-risk="strategy.risk_rpn_value || 0"
              @select-strategy="selectStrategy"
              @open-creation="openSidebar(false, strategy.id)"
              @delete-strategy="deleteStrategy"
            />
          </q-scroll-area>
          <AEmptyData
            v-if="
              (!risksStore.riskTreatmentStrategies ||
                risksStore.riskTreatmentStrategies.length === 0) &&
              isAllowed
            "
            icon-name="drawer"
            :header="`Youd don't have any strategy.`"
            text="Start by creating "
            action-text="New Strategy."
            class="full-width items-center"
            @click-text="openSidebar(true)"
          />
          <AEmptyData
            v-if="
              (!risksStore.riskTreatmentStrategies ||
                risksStore.riskTreatmentStrategies.length === 0) &&
              !isAllowed
            "
            icon-name="drawer"
            :header="`Youd don't have any strategy.`"
            text=""
            class="full-width items-center"
          />
        </div>
      </div>
    </div>
    <div class="conclusion__container column full-width" :class="[isLoading ? 'skeleton-v2' : '']">
      <div
        class="conclusion__header row full-width justify-between items-center"
        :style="{ marginBottom: conclusionOpened ? '30px' : '0' }"
      >
        <div class="conclusion__header-left column">
          <span class="conclusion__title q-mb-md">Conclusion</span>
          <span class="conclusion__subtitle">Decide on a risk treatment strategy.</span>
        </div>
        <div class="conclusion__header-right">
          <Icon
            v-if="conclusionOpened"
            icon-folder="task"
            icon-name="arrow_up"
            class="toggle-view"
            @click="toggleConclusion"
          />
          <Icon
            v-else
            icon-folder="task"
            icon-name="arrow_down"
            class="toggle-view"
            @click="toggleConclusion"
          />
        </div>
      </div>
      <div
        class="conclusion__content row col-12 justify-between"
        :class="conclusionOpened ? 'content-opened' : ''"
      >
        <div class="conclusion__left row">
          <OMatrix
            ref="matrixComponent"
            class="matrix col-5"
            :d-value="dValue"
            :likelihood-value="likelihoodValue"
            :impact-value="impactValue"
            :impact-labels="ImpactLabels"
            :likelihood-labels="LikelihoodLabels"
            :new-strategy="selectedStrategy || null"
            :show-tooltips="true"
          />
          <div v-if="projectsStore.project" class="comparison__container full-width">
            <ThresholdSlider
              :risk-threshold="projectsStore.project.risk_threshold || DEFAULT_THRESHOLD"
              :rpn-value="selectedStrategy ? selectedStrategy.risk_rpn_value || 0 : 0"
              :comparison-value="risk.risk_rpn_value || null"
              title="Residual Risk comparison"
            />
            <div class="info__container row q-mt-xl">
              <div class="row inherent-info items-center q-mr-md">
                <div class="mark mark-inherent q-mr-sm" />
                <span class="info-text">inherent risk</span>
              </div>
              <div class="row comparison-info items-center q-mr-md">
                <div class="mark mark-comparison q-mr-sm" />
                <span class="info-text">residual risk</span>
              </div>
              <div class="row threshold-info items-center q-mr-md">
                <div class="mark mark-threshold q-mr-sm" />
                <span class="info-text">risk threshold</span>
              </div>
            </div>
          </div>
        </div>
        <div class="conclusion__right col-6 row justify-end">
          <TipTapEditor
            :editor-content="editor"
            root-el="Treatment"
            :full-height="true"
            placeholder="Explain the results of your selected risk treatment strategy…"
            class="col-12"
            @update-editor="updateEditor"
          />
        </div>
        <div class="action__container row col-12 justify-end q-mt-md">
          <q-btn
            v-if="isAllowed"
            label="Save"
            :disable="!selectedStrategy || !editor.content || !isAllowed"
            unelevated
            :loading="isUpdating"
            @click="updateTreatment"
          >
            <q-tooltip>Submit your risk treatment strategy</q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>
    <STreatment
      :show-sidebar="showSidebar"
      :creation="creation"
      :edit-strategy="editStrategy || null"
      :strategies="risksStore.riskTreatmentStrategies"
      :is-allowed="isAllowed"
      @create-strategy="createStrategy"
      @update-strategy="updateStrategy"
      @hide-sidebar="hideSidebar"
    />
    <ADialog
      :show-dialog="showConfirmDialog"
      max-height="auto !important"
      max-width="660px !important"
      min-height="auto !important"
      min-width="660px !important"
      @hide="showConfirmDialog = false"
    >
      <DConfirm
        title="Delete Strategy"
        description="Would you like to delete this Strategy?"
        button-text="Confirm"
        :loading="confirmationIsLoading"
        @close-dialog="closeConfirm"
        @confirm="confirm"
      />
    </ADialog>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.treatment__wrapp {
  min-height: 100vh;
  position: relative;

  .strategies__container {
    background: $white;
    padding: 20px;
    border-radius: 5px;
    .strategies__header {
      margin-bottom: 30px;
      &-left {
        @include heading-04(600, $secondary-600);
      }
      &-right {
        button {
          background: $secondary-500 !important;
          color: $white;
          text-transform: none;
        }
      }
    }
    .strategies__content {
      .content__header {
        margin-bottom: 40px;
        @media screen and (max-width: 1440px) {
          justify-content: flex-start;
        }
        .absolute-position {
          position: absolute;
          right: 0;
        }
      }
      .content__main {
        height: 750px;
        :deep(.q-scrollarea__content) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .conclusion__container {
    background: $white;
    padding: 20px;
    border-radius: 5px;
    .conclusion__header {
      .conclusion__subtitle {
        font-size: 16px;
        font-weight: 400;
      }
      &-left {
        @include heading-04(600, $secondary-600);
      }
      &-right {
        .toggle-view {
          cursor: pointer;
        }
      }
    }
    .conclusion__content {
      height: 0;
      overflow: hidden;
      .matrix {
        width: unset;
      }

      .comparison__container {
        background: $secondary-50;
        border-radius: 5px;
        padding: 20px;
        .info__container {
          .mark {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
          .mark-inherent {
            border: 2px solid $secondary-700;
            background: $secondary-600;
          }
          .mark-comparison {
            border: 2px solid $secondary-500;
            background: $white;
          }
          .mark-threshold {
            width: 8px;
            height: 8px;
            border: 2px solid $secondary-500;
            background: #c7ed71;
          }
          .info-text {
            @include caption(400, $secondary-500);
          }
        }
      }
      .conclusion__left {
        width: 49%;
        padding: 20px;
        border: 1px solid $secondary-300;
        border-radius: 5px;

        @media screen and (max-width: 1440px) {
          width: 100%;
          margin-bottom: 24px;
          justify-content: center;
        }
      }

      .conclusion__right {
        @media screen and (max-width: 1440px) {
          width: 100%;
        }
      }

      .action__container {
        button {
          min-width: 97px;
          background: $secondary-500 !important;
          color: $white;
          text-transform: none;
        }
      }
    }
    .content-opened {
      height: auto;
      overflow: visible;
    }
  }
}
</style>
