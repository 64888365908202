<script setup lang="ts">
import { ref } from 'vue';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import type { SecondaryPagination } from '@/interfaces/models/Pagination';
import type { PropType } from 'vue';
import { Concept } from '@/client/api';

import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';
import { captureException } from '@/composables/Sentry';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

interface Select {
  label: string;
  value: boolean | null;
}

const emit = defineEmits(['createTest']);

defineProps({
  columnsNames: {
    type: Object,
    default: () => null,
  },
  secondaryPagination: {
    type: Object as PropType<SecondaryPagination>,
    default: () => null,
  },
  isDataLoading: {
    type: Boolean,
  },
  emptyTitle: {
    type: String,
    required: true,
  },
  emptySubTitle: {
    type: String,
    required: true,
  },
  isEditor: {
    type: Boolean,
  },
});

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const searchFilter = ref('');
const statusFilter = ref<Select | null>(null);

const statusOptions = ref([
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
]);

async function updateTests() {
  try {
    await projectsStore.getProjectTestingTests({
      projectId: projectsStore.project!.id,
      ...DEFAULT_PAGINATION,
      name: searchFilter.value !== '' ? searchFilter.value : null,
      resultStatus: paginationStore.testResultFilter,
      status: statusFilter.value ? statusFilter.value.value : null,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: TestingPanel, Function: updateTests',
    });
  }
}

async function filterBySearch(value: string) {
  searchFilter.value = value;

  await updateTests();
}

async function filterByStatus(value: boolean) {
  paginationStore.setTestStatusFilter(value);

  await updateTests();
}
</script>

<template>
  <div class="wrapp">
    <OTable
      :data="projectsStore.testingData?.items"
      :columns-names="columnsNames"
      :search-filter="true"
      :pagination="projectsStore.testingData!"
      :secondary-pagination="secondaryPagination"
      :row-route="`/projects/project-detail/${projectsStore.project?.id}/test-detail/`"
      :is-loading="isDataLoading"
      :skeleton-size="1"
      :skeleton-columns="3"
      :concept="Concept.TestingTest"
      search-placeholder="Search for a Test"
      parent-el="testingOverview"
      store="projectsStore"
      action="getTests"
      @filter-by-search="filterBySearch"
    >
      <template #header-button>
        <q-btn
          v-if="isProjectOwner() || isAdmin() || isEditor"
          icon="add"
          label="New Test"
          unelevated
          :disable="isDataLoading || (!isProjectOwner() && !isAdmin() && !isEditor)"
          :class="[isDataLoading ? 'skeleton' : '']"
          @click="emit('createTest')"
        />
      </template>
      <template #header-filters>
        <q-select
          v-model="statusFilter"
          outlined
          label="Status"
          :options="statusOptions"
          dense
          :class="[isDataLoading ? 'skeleton' : '']"
          @update:model-value="filterByStatus"
        />
      </template>
    </OTable>
    <div
      v-if="projectsStore.testingData?.items?.length === 0 && !isDataLoading"
      class="row empty__container"
      :class="[!projectsStore.testingData ? 'full-height' : '']"
    >
      <AEmptyData
        v-if="!searchFilter && !statusFilter"
        icon-name="drawer"
        :header="emptyTitle"
        :text="emptySubTitle"
        class="full-width items-center q-mt-lg"
      />
      <AEmptyData
        v-else
        icon-name="search"
        :header="`No results for your search.`"
        text="Try changing your search."
        class="full-width items-center"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.q-field {
  width: 150px;
  border-radius: 4px;
  :deep(.q-field__inner) {
    padding: 0 !important;
    .q-field__control::before {
      .q-field__native div {
        display: none !important;
      }
    }
    .q-field__label {
      color: $secondary-400;
    }
  }
}

.empty__container {
  min-height: 400px;
  background: $white;
}
</style>
