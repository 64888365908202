<script setup lang="ts">
import { CURRENT_YEAR } from '@/composables/utils';

const appVersion = import.meta.env.VITE_APP_VERSION;
</script>

<template>
  <q-footer class="text-white">
    <q-toolbar class="items-center">
      <span class="q-mr-sm"
        >{{ `© 2023-${CURRENT_YEAR} Modulos AG. -` }}
        <a href="https://status.modulos.ai/" target="_blank">Status</a>
        {{ ` - Version: ${appVersion}` }}</span
      >
    </q-toolbar>
  </q-footer>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.q-footer {
  background: transparent;
  .q-toolbar {
    justify-content: center;
    align-items: center;
    min-height: unset;
    margin: 10px 0;
    span {
      @include footer(400, $secondary-600);
      line-height: 16px;
      font-size: 10px;
    }
    a {
      @include caption(600, $secondary-600);
      text-decoration: none;
    }
  }
}
</style>
