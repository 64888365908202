<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';

import { userProjectRoles, UpperCaseRoles, isAdmin } from '@/composables/Auth';
import { Status, STATUS_LABEL } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';
import { isProjectOwner } from '@/composables/CProject';

import { useProjectsStore } from '@/stores/ProjectsStore';

import type { ControlStatusFilter } from '@/client/api';
import type { SecondaryPagination } from '@/interfaces/models/Pagination';
import { Roles, Concept } from '@/client/api';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import DNewRisk from '@/components/dialogs/DNewRisk.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import SRisksOverview from '@/components/skeletons/SRisksOverview.vue';
import RiskLevelLegend from '@/components/Risks/RiskLevelLegend.vue';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

const projectsStore = useProjectsStore();
// const riskLevel = ref('Risk Level');
const status = ref('All');
const filter = ref('');
// const risksOptions = ['Very High', 'High', 'Medium', 'Low', 'Very Low'];
const statusOptions = [
  STATUS_LABEL.ALL,
  STATUS_LABEL.IN_REVIEW,
  STATUS_LABEL.IN_PROGRESS,
  STATUS_LABEL.COMPLETED,
  STATUS_LABEL.OUT_OF_SCOPE,
];
const showDialog = ref(false);
const isEditor = ref(false);
const risksIsLoading = ref(false);

const secondaryPagination: SecondaryPagination = reactive({
  search: null,
  id: null,
  status: undefined,
  assignedAs: undefined,
});

const columnsNames = {
  name: 'Name',
  created_by: UpperCaseRoles.Owner,
  assigned_to: 'Assigned To',
  status: 'Status',
  risk_rpn_value: 'Inherent Risk',
  residual_risk: 'Residual Risk',
  ai_lifecycle_stage: 'AI Lifecycle',
};

function closeDialog() {
  showDialog.value = false;
}

async function filterBySearch(value: string) {
  if (!projectsStore.project) return;

  filter.value = value;

  await filterByStatus();
}

async function filterByStatus() {
  if (!projectsStore.project || !projectsStore.risksData) return;

  try {
    if (status.value === STATUS_LABEL.IN_REVIEW) {
      secondaryPagination.status = Status.IN_REVIEW;

      await projectsStore.getProjectsRisks({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        status: Status.IN_REVIEW,
        name: filter.value !== '' ? filter.value : null,
      });
      return;
    } else if (status.value === STATUS_LABEL.IN_PROGRESS) {
      secondaryPagination.status = Status.IN_PROGRESS;

      await projectsStore.getProjectsRisks({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        status: Status.IN_PROGRESS,
        name: filter.value !== '' ? filter.value : null,
      });
      return;
    } else if (status.value === STATUS_LABEL.ALL) {
      secondaryPagination.status = undefined;

      await projectsStore.getProjectsRisks({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        status: null,
        name: filter.value !== '' ? filter.value : null,
      });
      return;
    }

    secondaryPagination.status = status.value
      .replaceAll(' ', '_')
      .toLowerCase() as ControlStatusFilter;

    await projectsStore.getProjectsRisks({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
      status: status.value.replaceAll(' ', '_').toLowerCase(),
      name: filter.value !== '' ? filter.value : null,
    });
  } catch (error) {
    captureException(error, { message: 'Component: Risks, Function: filterByStatus' });
  }
}

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    risksIsLoading.value = true;
    await projectsStore.getProjectsRisks({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: Risks, Hook: onMounted, Method: getProjectsRisks',
    });
  } finally {
    risksIsLoading.value = false;
  }

  const userRoles = await userProjectRoles(projectsStore.project.id);

  isEditor.value = userRoles?.includes(Roles.Editor) || false;
});
</script>

<template>
  <div
    v-if="projectsStore.risksData && !risksIsLoading"
    class="risks__overview row full-width items-center"
  >
    <div class="overview-left col-9 row justify-between items-center">
      <span class="overview__title col-2">Risks Overview</span>
      <div class="risks__container col-10 row items-center justify-between">
        <div
          v-for="(value, key) in projectsStore.risksData.risk_overview_information"
          :key="key"
          class="q-mr-xl column items-center risk"
        >
          <span
            class="risk-number q-mb-xs"
            :class="(key as unknown as unknown as string).replaceAll(' ', '-').toLowerCase()"
            >{{ value }}</span
          >
          <span class="risk-title">{{ key }}</span>
        </div>
      </div>
    </div>
    <q-space />
    <div class="overview-right">
      <Icon icon-name="risks_overview" icon-folder="risks" />
    </div>
  </div>
  <SRisksOverview v-if="risksIsLoading" />
  <OTable
    :row-route="`/projects/project-detail/${projectsStore.project?.id}/risk-detail/`"
    :data="projectsStore.risksData?.items"
    :pagination="projectsStore.risksData!"
    :secondary-pagination="secondaryPagination"
    :columns-names="columnsNames"
    :is-loading="risksIsLoading"
    :skeleton-size="5"
    :concept="Concept.Risk"
    store="projectsStore"
    action="getRisks"
    @filter-by-search="filterBySearch"
  >
    <template #header-filters>
      <!-- <q-select
        v-model="riskLevel"
        outlined
        :options="risksOptions"
        dense
        class="q-mx-md"
        disable
      /> -->
      <q-select
        v-model="status"
        outlined
        :options="statusOptions"
        dense
        class="q-mr-md"
        :class="[risksIsLoading ? 'skeleton' : '']"
        @update:model-value="filterByStatus"
      />
    </template>
    <template #header-button>
      <q-btn
        v-if="projectsStore.project && (isProjectOwner() || isAdmin() || isEditor)"
        icon="add"
        label="New Risk"
        unelevated
        :disable="(!isProjectOwner() && !isAdmin() && !isEditor) || risksIsLoading"
        @click="showDialog = true"
      >
        <q-tooltip
          v-if="!isProjectOwner() && !isAdmin() && isEditor"
          anchor="top middle"
          self="bottom middle"
        >
          You don't have permission. Contact the admin.
        </q-tooltip>
      </q-btn>
    </template>
  </OTable>
  <div
    v-if="projectsStore.risksData && projectsStore.risksData.items.length === 0 && !risksIsLoading"
    class="empty__wrapp row items-center q-mt-md"
  >
    <AEmptyData
      v-if="!filter && status === 'Status'"
      icon-name="folder"
      :header="`You don’t have any Risk attached to this Project.`"
      text="Start by creating a "
      action-text="New Risk."
      class="full-width items-center"
      @click-text="showDialog = true"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
  <RiskLevelLegend class="q-mt-lg" />

  <ADialog
    :show-dialog="showDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DNewRisk @close-dialog="closeDialog" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.risks__overview {
  padding: 25px 20px;
  background: $secondary-600;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 20px;

  .overview__title {
    color: $white;
    font-size: 16px;
    font-weight: 500;
  }
  .risk-number {
    font-size: 24px;
    font-weight: 700;
  }
  .risk-title {
    @include caption(600, $white);
    text-transform: uppercase;
  }
}

.q-select {
  :deep(.q-field__inner) {
    border: none !important;
    padding: 0 !important;
  }
}
</style>
