import { DashboardApi } from '@/client/api';
import initApi from '@/client_heplers/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  AssignedControls,
  AssignedRisks,
  DashboardApiGetControlsApiDashboardAssignedControlsGetRequest,
  DashboardApiGetProjectsApiDashboardProjectsGetRequest,
  DashboardApiGetRisksApiDashboardAssignedRisksGetRequest,
  ProjectsDashboard,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { RequestConfig } from '@/interfaces/models/Request';
import type { AxiosError } from 'axios';

export const useDashboardStore = defineStore('dashboard', () => {
  const dashboardProjects = ref<null | ProjectsDashboard>(null);
  const controls = ref<null | AssignedControls>(null);
  const risks = ref<null | AssignedRisks>(null);
  const isLoading = ref(false);

  const getDashboardProjects = async (
    params: DashboardApiGetProjectsApiDashboardProjectsGetRequest,
  ) => {
    try {
      const dashboardApi = await initApi(DashboardApi);
      const response = await dashboardApi?.getProjectsApiDashboardProjectsGet(params);

      if (!response) return;

      dashboardProjects.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getControls = async (
    params: DashboardApiGetControlsApiDashboardAssignedControlsGetRequest = {},
    options?: RequestConfig,
  ) => {
    try {
      const controlsApi = await initApi(DashboardApi);
      const response = await controlsApi?.getControlsApiDashboardAssignedControlsGet(
        params,
        options,
      );

      if (!response) return;

      controls.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getRisks = async (
    params: DashboardApiGetRisksApiDashboardAssignedRisksGetRequest = {},
    options?: RequestConfig,
  ) => {
    try {
      const controlsApi = await initApi(DashboardApi);
      const response = await controlsApi?.getRisksApiDashboardAssignedRisksGet(params, options);

      if (!response) return;

      risks.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const setLoading = (state: boolean) => {
    isLoading.value = state;
  };

  return {
    getDashboardProjects,
    dashboardProjects,
    getControls,
    controls,
    getRisks,
    risks,
    isLoading,
    setLoading,
  };
});
