<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import type { UserDisplay } from '@/client/api';
import type { PropType } from 'vue';

import Icon from '@/components/atoms/Icon.vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

const emit = defineEmits(['selectUser', 'selectUsers']);

const props = defineProps({
  assignedUser: {
    type: Object as PropType<UserDisplay | null>,
    default: () => null,
  },
  searchPlaceholder: {
    type: String,
    default: 'user',
  },
  selectedUsersArr: {
    type: Array as PropType<UserDisplay[]>,
    default: () => [],
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
});

const projectsStore = useProjectsStore();
const filter = ref('');
const selectedUsers = ref<UserDisplay[]>([]);

function selectUser(user: string, id: string) {
  emit('selectUser', user, id);
}

function selectUsers(item: UserDisplay) {
  const index = selectedUsers.value.findIndex((selItem: UserDisplay) => selItem.id === item.id);
  if (index >= 0) {
    selectedUsers.value.splice(index, 1);
  } else {
    selectedUsers.value.push(item);
  }
  emit('selectUsers', selectedUsers.value);
}

const filteredValues = computed(() => {
  if (!projectsStore.projectUsers) {
    return [];
  }

  return projectsStore.projectUsers.items.filter(
    (user: UserDisplay) =>
      user.firstname.toLowerCase().includes(filter.value.toLowerCase()) ||
      user.lastname.toLowerCase().includes(filter.value.toLowerCase()),
  );
});

function isSelectedUser(item: UserDisplay) {
  return selectedUsers.value.some((selItem: UserDisplay) => selItem.id === item.id);
}

watch(
  () => props.selectedUsersArr,
  (newV) => {
    selectedUsers.value = newV;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="menu-wrapper">
    <q-input
      v-model="filter"
      borderless
      dense
      debounce="300"
      :placeholder="`Search for a ${searchPlaceholder}`"
      class="q-mb-sm"
    >
      <template #append>
        <q-icon name="search" />
      </template>
    </q-input>
    <q-list>
      <q-item
        v-for="user in filteredValues"
        :key="user.id"
        v-close-popup
        clickable
        :class="assignedUser?.id === user.id ? 'active' : ''"
        @click="
          assignedUser?.id === user.id
            ? null
            : !multiselect
              ? selectUser(user.firstname + ' ' + user.lastname, user.id)
              : selectUsers(user)
        "
      >
        <q-item-section v-if="!multiselect">
          <Icon v-if="$q.dark.isActive" icon-name="icon-person" icon-folder="white" />
          <Icon v-else icon-name="icon-person" icon-folder="colored" />
          <q-item-label>{{ user.firstname + ' ' + user.lastname }}</q-item-label>
        </q-item-section>
        <q-item-section v-else class="item-section">
          <q-checkbox
            :model-value="isSelectedUser(user)"
            :val="user"
            size="16px"
            class="q-mr-sm"
            @update:model-value="selectUsers(user)"
          />
          <q-item-label>{{ user.firstname + ' ' + user.lastname }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.menu-wrapper {
  padding: 8px;

  label {
    border-radius: 2px;
    border: 1px solid $common-1;
    :deep(input) {
      padding: 0px 12px;
    }
    :deep(.q-field__append) {
      padding: 6px;
      border-left: 1px solid $common-1;
    }
  }
  :deep(.q-item__label) {
    color: $common-4;
  }
  .active {
    background: #e8e8e8;
  }
  .item-section {
    flex-direction: row !important;
    justify-content: flex-start !important;
    .q-checkbox {
      :deep(.q-checkbox__bg) {
        width: 100%;
        height: 100%;
        position: unset;
      }

      :deep(.q-checkbox__inner--falsy) {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      :deep(.q-checkbox__inner--truthy) {
        .q-checkbox__bg {
          color: $secondary-800;
        }
      }
      :deep(.q-checkbox__label) {
        @include paragraph-02(600, $common-5);
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
</style>
