<script setup lang="ts">
import { onMounted, reactive, ref, computed } from 'vue';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import RiskPanel from '@/components/organisms/Dashboard/Panels/RiskPanel.vue';

import { captureException } from '@/composables/Sentry';

import { Assignment } from '@/client/api';
import type { SecondaryPagination } from '@/interfaces/models/Pagination';
import { SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { useDashboardStore } from '@/stores/DashboardStore';

interface SelectValue {
  value: string | null;
  label: string;
}

const dashboardStore = useDashboardStore();
const filter = ref(null);
const activeTab = ref<Assignment>(Assignment.Assigned);
const projectName = ref<SelectValue>({
  value: null,
  label: 'All',
});
const isRisksLoading = ref(false);
const isDataLoading = ref(false);

const secondaryPagination: SecondaryPagination = reactive({
  search: null,
  id: null,
  status: undefined,
  assignedAs: undefined,
});

const columnsNames = {
  name: 'Name',
  project: 'Project',
  status: 'Status',
  assigned_to: 'Assignee',
  modified_at: 'Modified',
};

async function changeTab(value: Assignment) {
  isRisksLoading.value = true;
  isDataLoading.value = true;

  const params = {
    riskSearch: filter.value || null,
    projectIdOfRisks: projectName.value?.value,
    assignedAs: value,
    size: SMALLER_PAGINATION_SIZE,
  };
  try {
    activeTab.value = value;
    secondaryPagination.assignedAs = value;
    await dashboardStore.getRisks(params);
  } catch (error) {
    captureException(error, {
      message: 'Component: ORisks, Function: changeTab',
      data: {
        params: params,
      },
    });
  } finally {
    isRisksLoading.value = false;
    isDataLoading.value = false;
  }
}

async function inputFilter() {
  const params = {
    riskSearch: filter.value || null,
    projectIdOfRisks: projectName.value?.value,
    assignedAs: activeTab.value,
  };
  try {
    isDataLoading.value = true;

    await dashboardStore.getRisks(params);
    secondaryPagination.search = filter.value;
  } catch (error) {
    captureException(error, {
      message: 'Component: ORisks, Function: inputFilter',
      data: {
        params: params,
      },
    });
  } finally {
    isDataLoading.value = false;
  }
}

async function selectFilter() {
  const selectedProject = projectName.value;

  if (!selectedProject) return;

  const params = {
    riskSearch: filter.value || null,
    projectIdOfRisks: selectedProject.value,
    assignedAs: activeTab.value,
  };

  try {
    await dashboardStore.getRisks(params);
    secondaryPagination.id = selectedProject ? selectedProject.value : null;
  } catch (error) {
    captureException(error, {
      message: 'Component: ORisks, Function: selectFilter',
      data: {
        params: params,
      },
    });
  }
}

const projectsWithAssignedRisks = computed(() => {
  if (!dashboardStore.risks) return;

  const assignedRisks = dashboardStore.risks.projects_with_assigned_risks.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  assignedRisks.unshift({ label: 'All', value: null });

  return assignedRisks;
});

onMounted(async () => {
  try {
    isRisksLoading.value = true;
    isDataLoading.value = true;

    await dashboardStore.getRisks({
      size: SMALLER_PAGINATION_SIZE,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: ORisks, Hook: onMounted, Method: getRisks',
    });
  } finally {
    isRisksLoading.value = false;
    isDataLoading.value = false;
  }
});
</script>

<template>
  <div class="risks__wrapper">
    <div
      v-if="
        (dashboardStore.risks && dashboardStore.risks.nr_risks_assigned! > 0) ||
        (dashboardStore.risks && dashboardStore.risks.nr_risks_assigned_for_review! > 0) ||
        isRisksLoading
      "
      class="row search-filter__container q-mb-md items-end"
    >
      <div class="row q-mr-md col-3">
        <span class="col-12 q-mb-sm">Project</span>
        <q-select
          v-if="dashboardStore.risks && dashboardStore.risks.projects_with_assigned_risks"
          v-model="projectName"
          outlined
          :options="projectsWithAssignedRisks"
          dense
          class="q-mr-md col-12"
          :class="[isRisksLoading ? 'skeleton' : '']"
          @update:model-value="selectFilter"
        />
      </div>
      <div class="row q-mr-md col-3">
        <q-input
          v-model="filter"
          class="col-12"
          borderless
          dense
          debounce="300"
          placeholder="Search for a Risk"
          @update:model-value="inputFilter"
        >
          <template #append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <OTabPanels
      v-if="
        (dashboardStore.risks && dashboardStore.risks.nr_risks_assigned! > 0) ||
        (dashboardStore.risks && dashboardStore.risks.nr_risks_assigned_for_review! > 0) ||
        isRisksLoading ||
        isDataLoading
      "
      :active-tab="activeTab"
      :tab-size="{ md: 12, lg: 12 }"
      default-tab="assigned"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab
          :content-class="`${isRisksLoading ? 'skeleton' : ''} my-tab`"
          name="assigned"
          label="Assigned Risks"
          class="q-mr-md"
          :class="activeTab === Assignment.Assigned ? activeTab : ''"
          :style="{ borderLeft: activeTab !== Assignment.Assigned ? '5px solid #556c77' : '' }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.risks?.nr_risks_assigned }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.Assigned"
              icon-name="dashboard-assigned-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-assigned" icon-folder="dashboard" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isRisksLoading ? 'skeleton' : ''} my-tab`"
          name="assigned_for_review"
          label="Assigned Risks to Review"
          class="q-mr-md"
          :class="activeTab === Assignment.AssignedForReview ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== Assignment.AssignedForReview ? '5px solid #fd956b' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.risks?.nr_risks_assigned_for_review }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.AssignedForReview"
              icon-name="dashboard-review-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-review" icon-folder="dashboard" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isRisksLoading ? 'skeleton' : ''} my-tab`"
          name="review_rejected"
          label="Rejected by Reviewer"
          :class="activeTab === Assignment.ReviewRejected ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== Assignment.ReviewRejected ? '5px solid #f96f7f' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.risks?.nr_risks_rejected_by_reviewer }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.ReviewRejected"
              icon-name="dashboard-rejected-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-rejected" icon-folder="dashboard" />
          </div>
        </q-tab>
      </template>
      <template #panels>
        <q-tab-panel name="assigned">
          <RiskPanel
            title="Last assigned risks"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-risks-loading="isRisksLoading"
            empty-title="You don’t have any assigned risks yet."
            empty-sub-title="As soon as you have assigned risks you can see them here."
          />
        </q-tab-panel>
        <q-tab-panel name="assigned_for_review">
          <RiskPanel
            title="Last assigned risks to review"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-risks-loading="isRisksLoading"
            empty-title="You don’t have any assigned risks to review yet."
            empty-sub-title="As soon as you have assigned risks to review you can see them here."
          />
        </q-tab-panel>
        <q-tab-panel name="review_rejected">
          <RiskPanel
            title="Last rejected risks to review"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-risks-loading="isRisksLoading"
            empty-title="You don’t have any rejected by review risks yet."
            empty-sub-title="As soon as you have rejected by review risks you can see them here."
          />
        </q-tab-panel>
      </template>
    </OTabPanels>
    <div
      v-if="
        (!dashboardStore.risks && !isRisksLoading) ||
        (dashboardStore.risks?.items.length === 0 &&
          dashboardStore.risks?.nr_risks_assigned === 0 &&
          dashboardStore.risks?.nr_risks_assigned_for_review === 0 &&
          !isRisksLoading &&
          !isDataLoading)
      "
      class="row empty__container"
      :class="[!dashboardStore.risks ? 'full-height' : '']"
    >
      <AEmptyData
        v-if="!filter"
        icon-name="drawer"
        header="You don’t have any assigned risks yet."
        text="As soon as you have assigned risks you can see them here."
        class="full-width items-center q-mt-lg"
      />
      <AEmptyData
        v-else
        icon-name="search"
        :header="`No results for your search.`"
        text="Try changing your search."
        class="full-width items-center"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.risks__wrapper {
  width: 48%;
  min-height: 320px;
  padding: 26px;
  position: relative;
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.05);

  .search-filter__container {
    span {
      font-size: 14px;
      color: $common-2;
    }
  }
  :deep(.q-tab) {
    padding: 0;
    .q-tab__content {
      height: 80px;
      padding: 4px 10px 15px;
      justify-content: flex-start;
      border: 1px solid #dde1e6;

      .q-tab__label {
        font-size: 16px;
        font-weight: 400;
      }
      .q-tab__indicator {
        height: 4px;
      }
      .total__container {
        span {
          @include heading-03(600, $secondary-600);
        }
      }

      .tab-icon__container {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }

  :deep(.q-tab--active) {
    .my-tab {
      border: none;
      .q-tab__label {
        color: $white;
      }
      .total__container {
        span {
          color: $white;
        }
      }
    }
  }

  .assigned {
    background: #556c77;
    :deep(.q-tab__indicator) {
      color: #556c77 !important;
    }
  }
  .assigned_for_review {
    background: #fd956b;
    :deep(.q-tab__indicator) {
      color: #fd956b !important;
    }
  }

  .review_rejected {
    background: #f96f7f;
    :deep(.q-tab__indicator) {
      color: #f96f7f !important;
    }
  }

  :deep(.q-select) {
    .q-field__inner {
      padding: 0 !important;
      border: none !important;
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  :deep(.q-tab-panel) {
    padding: 0;

    .risks-info {
      span {
        color: $secondary-600;
        font-size: 12px;
      }
    }
    .q-table__container {
      padding: 0;
    }
  }

  .empty__container {
    height: calc(100% - 36px) !important;
  }
  :deep(tbody td) {
    width: 200px;
  }
}
</style>
