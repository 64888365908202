<script setup lang="ts">
import { useQuasar } from 'quasar';
import { vOnClickOutside } from '@vueuse/components';

import { useAuthStore } from '@/stores/AuthStore';

import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['hide']);

const $q = useQuasar();
const authStore = useAuthStore();

function hide() {
  emit('hide');
}

function copyToken() {
  if (!authStore.tokenData) return;

  navigator.clipboard.writeText(authStore.tokenData?.access_token);

  $q.notify({
    icon: 'cloud_done',
    message: 'Copied',
  });
}
</script>

<template>
  <q-card v-on-click-outside="hide" class="dialog__container">
    <q-card-section>
      <div class="row">
        <p>
          <b>Important Notice:</b> The token displayed below can only be shown once. For your
          security, please copy and store it in a safe and secure location immediately. Failure to
          do so may result in the inability to retrieve or use the token in the future. Handle with
          care!
        </p>
      </div>
      <div class="row items-center justify-between q-mt-sm">
        <span><b>Token:</b> {{ authStore.tokenData?.access_token }}</span>
        <Icon icon-name="copy-btn" icon-folder="colored" @click="copyToken" />
      </div>
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.dialog__container {
  position: fixed;
  max-width: 300px;
  max-height: 250px;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 9999;
  span {
    @include paragraph-01(400, $secondary-800);
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p {
    @include paragraph-01(400, $secondary-800);
  }
  :deep(svg) {
    width: 20px;
    cursor: pointer;
  }
  :deep(svg:active) {
    transform: scale(0.9);
  }
}
</style>
