import { useGlobalStore } from '@/stores/GlobalStore';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { EvidencesApi } from '@/client/api';
import initApi from '@/client_heplers/utils';

import type {
  EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest,
  EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest,
  EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest,
  EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest,
  EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest,
  EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest,
} from '@/client/api';

import { getHttpClient } from '@/api/httpClient';
import { handleApiError } from '@/composables/ApiError';
import { AxiosError } from 'axios';

interface Evidence {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  file_name: string;
  description: string;
}

export const useEvidenceStore = defineStore('evidence', () => {
  const isLoading = ref(false);
  const controlFiles = ref([]);

  const getEvidence = async (params: EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      const response = await evidencesApi?.getEvidenceApiEvidencesEvidenceIdGet(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const uploadEvidence = async (
    projectID: string,
    evidence: Evidence | null,
    file: File,
    componentId: string | null,
  ) => {
    const store = useGlobalStore();
    const formData = new FormData();
    formData.append('file', file);

    const config = {
      accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };

    let url = '';
    if (evidence) {
      componentId
        ? (url = `/api/projects/${projectID}/evidence?component_id=${componentId}&file_name=${
            evidence ? evidence.file_name : ''
          }&description=${evidence ? evidence.description : ''}`)
        : (url = `/api/projects/${projectID}/evidence?file_name=${
            evidence ? evidence.file_name : ''
          }&description=${evidence ? evidence.description : ''}`);
    } else {
      componentId
        ? (url = `/api/projects/${projectID}/evidence?component_id=${componentId}`)
        : (url = `/api/projects/${projectID}/evidence`);
    }

    try {
      const httpClient = getHttpClient({ showNotifications: false });

      const response = await httpClient.post(url, formData, { headers: config });

      const data = await response.data;
      store.$q.notify({
        message: 'Evidence uploaded successfully',
        color: 'green',
        icon: 'cloud_done',
      });
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        handleApiError(error);
      }
    }
  };

  const downloadFile = async (id: string, name: string) => {
    isLoading.value = true;
    try {
      const evidencesApi = await initApi(EvidencesApi);
      const response = await evidencesApi?.downloadEvidenceApiEvidencesEvidenceIdDownloadGet(
        {
          evidenceId: id,
        },
        {
          responseType: 'blob',
        },
      );

      if (!response) {
        return;
      }

      const blob = response.data as unknown as Blob;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateEvidence = async (
    params: EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest,
  ) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      const response = await evidencesApi?.updateEvidenceApiEvidencesEvidenceIdPatch(params);

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getEvidenceUrl = async (
    params: EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest,
  ) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      const response = await evidencesApi?.getEvidenceUrlApiEvidencesEvidenceIdUrlGet(params);

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteEvidenceFromComponent = async (
    params: EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest,
  ) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      await evidencesApi?.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteArtifact = async (
    params: EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest,
  ) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      await evidencesApi?.deleteEvidenceApiEvidencesEvidenceIdDelete(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const addEvidenceToComponent = async (
    params: EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest,
  ) => {
    try {
      const evidencesApi = await initApi(EvidencesApi);
      const response =
        await evidencesApi?.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(
          params,
        );

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getEvidence,
    uploadEvidence,
    downloadFile,
    deleteArtifact,
    deleteEvidenceFromComponent,
    isLoading,
    controlFiles,
    addEvidenceToComponent,
    updateEvidence,
    getEvidenceUrl,
  };
});
