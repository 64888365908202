<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, watch, computed, onMounted } from 'vue';

import { useTaskStore } from '@/stores/TaskStore';
import { useEvidenceStore } from '@/stores/EvidenceStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import type { Evidence, EvidenceUpdate, EvidencePreview } from '@/client/api';
import { Roles } from '@/client/api';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DNewEvidence from '@/components/dialogs/DNewEvidence.vue';
import DEditEvidence from '@/components/dialogs/DEditEvidence.vue';
import DEvidencePreview from '@/components/dialogs/DEvidencePreview.vue';

interface Select {
  value: Evidence;
  label: string;
}

const emit = defineEmits(['update']);

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
});

const store = useEvidenceStore();
const taskStore = useTaskStore();
const projectsStore = useProjectsStore();
const description = ref('');
const evidence = ref<Select | null>(null);
const evidencePeview = ref<Evidence | null>(null);
const controlFiles = ref<Evidence[]>([]);
const showNewEvidenceDialog = ref(false);
const showEvidencePreview = ref(false);
const showEditEvidenceDialog = ref(false);
const isLoading = ref(false);
const isEditor = ref(false);

async function setEvidencePreview(file: Evidence) {
  evidencePeview.value = file;
  showEvidencePreview.value = true;
}

async function deleteArtifact(id: string) {
  if (!taskStore.task) return;

  const params = {
    evidenceId: id,
    componentId: props.component.id,
  };

  try {
    isLoading.value = true;

    await store.deleteEvidenceFromComponent(params);
    successMessage('Evidence removed from component');

    await taskStore.getControl({ controlId: taskStore.task.id });
    emit('update', '', props.component.id, 'evidence');
  } catch (error) {
    captureException(error, {
      message: 'Component: evidence, Function: deleteArtifact',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

async function addEvidence() {
  if (!projectsStore.project || !evidence.value || !taskStore.task) return;

  const params = {
    evidenceId: evidence.value.value.id,
    componentId: props.component.id,
  };

  try {
    const evidenceData = await store.addEvidenceToComponent(params);
    evidence.value = null;

    if (evidenceData) {
      successMessage(`Evidence '${evidenceData.file_name}' added to component successfully`);
    }

    await taskStore.getControl({ controlId: taskStore.task.id });
    emit('update', '', props.component.id, 'evidence');
  } catch (error) {
    captureException(error, {
      message: 'Component: evidence, Function: addEvidence',
      data: {
        params: params,
      },
    });
  }
}

async function updateData() {
  if (!taskStore.task) return;

  await taskStore.getControl({ controlId: taskStore.task.id });
  emit('update', '', props.component.id, 'evidence');
}

async function updateEvidence(id: string, evidence: EvidenceUpdate) {
  if (!taskStore.task) return;

  const params = {
    evidenceId: id,
    evidenceUpdate: evidence,
  };
  try {
    const evidenceData = await store.updateEvidence(params);

    if (evidenceData) {
      successMessage(`Evidence '${evidenceData.file_name}' updated successfully`);
    }

    await taskStore.getControl({ controlId: taskStore.task.id });
    emit('update', '', props.component.id, 'evidence');
    closeDialog();
  } catch (error) {
    captureException(error, {
      message: 'Component: evidence, Function: updateEvidence',
      data: {
        params: params,
      },
    });
  }
}

function showEdit() {
  showEvidencePreview.value = false;
  showEditEvidenceDialog.value = true;
}

function showEvidencePreviewDialog() {
  showEvidencePreview.value = true;
  showEditEvidenceDialog.value = false;
}

function closeDialog() {
  showNewEvidenceDialog.value = false;
  showEvidencePreview.value = false;
  showEditEvidenceDialog.value = false;
}

const filteredArray = computed(() => {
  return projectsStore.projectEvidenceList.filter(
    (obj2: EvidencePreview) =>
      !controlFiles.value.some((obj1: EvidencePreview) => obj1.id === obj2.id),
  );
});

watch(
  () => props.component,
  async (newV) => {
    if (newV) {
      if (!projectsStore.project) return;

      try {
        await projectsStore.getProjectsEvidenceList({ projectId: projectsStore.project.id });
        controlFiles.value = props.component.evidences;
        if (props.component.result) {
          description.value = props.component.result;
        }
      } catch (error) {
        captureException(error, {
          message: 'Component: evidence, Hook: onMounted, Function: getProjectsEvidenceList',
        });
      }
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

onMounted(async () => {
  if (!projectsStore.project) return;

  const userRoles = await userProjectRoles(projectsStore.project.id);

  isEditor.value = userRoles?.includes(Roles.Editor) || false;
});
</script>
<template>
  <div>
    <div v-if="component" class="component__container row">
      <div class="row col-12 items-center relative-position">
        <Icon icon-folder="task" icon-name="bar_chart" />
        <span class="component__title">Evidence</span>
      </div>

      <div class="row col-12 items-center relative-position q-my-lg">
        <span class="component__description">Upload or attach relevant evidence here</span>
      </div>

      <div class="row col-12 position-relative">
        <div
          v-if="taskStore.task && !taskStore.task.is_locked"
          class="full-width row items-center justify-between"
        >
          <q-btn
            v-if="isAdmin() || isProjectOwner() || isEditor"
            icon="upload"
            label="Upload New Evidence"
            unelevated
            class="col-5"
            @click="showNewEvidenceDialog = true"
          />
          <span v-if="isAdmin() || isProjectOwner() || isEditor" class="q-mt-sm text-center"
            >or</span
          >
          <q-select
            v-if="projectsStore.projectEvidenceList"
            v-model="evidence"
            outlined
            :options="
              filteredArray.map((item) => {
                return { label: item.file_name, value: item };
              })
            "
            :disable="filteredArray.length === 0"
            dense
            class="col-5"
            label="Select from the existing evidence"
            @update:model-value="addEvidence"
          />
          <slot name="copy-btn" />
        </div>
        <div v-else class="full-width">
          <p v-if="component.result !== '' && taskStore.task?.is_locked" class="q-my-lg">
            {{ component.result }}
          </p>
          <p
            v-if="component.result === '' && taskStore.task?.is_locked"
            class="q-my-lg default-text"
          >
            No evidence description provided
          </p>
        </div>
      </div>
      <div class="row col-12 q-mt-xl">
        <div v-if="controlFiles && controlFiles.length > 0" class="list__container col-12">
          <div v-for="file in controlFiles" :key="file.id" class="list__item">
            <div class="row">
              <q-item-section>
                <span class="file-name"> {{ file.file_name }} </span>
              </q-item-section>
              <q-item-section top side>
                <div class="text-grey-8 q-gutter-xs">
                  <q-btn
                    class="gt-xs"
                    size="12px"
                    flat
                    dense
                    round
                    @click="setEvidencePreview(file)"
                  >
                    <Icon icon-name="visibility" icon-folder="task" />
                  </q-btn>
                  <q-btn
                    class="gt-xs"
                    size="12px"
                    :loading="isLoading"
                    :disable="isLoading"
                    flat
                    dense
                    round
                    @click="deleteArtifact(file.id)"
                  >
                    <Icon icon-name="delete" icon-folder="colored" />
                  </q-btn>
                </div>
              </q-item-section>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <ADialog
      v-if="evidencePeview"
      :show-dialog="showEvidencePreview"
      max-height="auto !important"
      max-width="660px !important"
      min-height="auto !important"
      min-width="660px !important"
      @hide="showEvidencePreview = false"
    >
      <DEvidencePreview
        :evidence="evidencePeview"
        @close-dialog="closeDialog"
        @show-edit="showEdit"
      />
    </ADialog>
    <ADialog
      :show-dialog="showNewEvidenceDialog"
      max-height="auto !important"
      max-width="660px !important"
      min-height="auto !important"
      min-width="660px !important"
      @hide="showNewEvidenceDialog = false"
    >
      <DNewEvidence
        :component-id="component.id"
        @close-dialog="closeDialog"
        @update-data="updateData"
      />
    </ADialog>
    <ADialog
      v-if="evidencePeview"
      :show-dialog="showEditEvidenceDialog"
      max-height="auto !important"
      max-width="660px !important"
      min-height="auto !important"
      min-width="660px !important"
      @hide="showEditEvidenceDialog = false"
    >
      <DEditEvidence
        :component-id="component.id"
        :evidence="evidencePeview"
        @close-dialog="closeDialog"
        @update-evidence="updateEvidence"
        @show-preview="showEvidencePreviewDialog"
      />
    </ADialog>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/style';
.component__container {
  p {
    @include paragraph-01(400, $common-4);
  }
  .default-text {
    font-style: italic;
    color: $common-2;
  }
  span {
    @include paragraph-01(400, $secondary-500);
  }
  hr {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .list__container {
    .list__item {
      .file-name {
        @include paragraph-01(600, $common-5);
      }

      :deep(button) {
        background: $white !important;
      }
    }
  }
  :deep(.q-field) {
    .q-field__label {
      @include paragraph-01(normal, $secondary-700);
    }
  }

  :deep(.q-field__label) {
    left: 50%;
    transform: translateX(-50%);
  }

  :deep(textarea::placeholder) {
    @include paragraph-01(400, $common-1);
  }
  .position-relative {
    position: relative;
  }
  .remark-snippet {
    color: $black;
    font-family: monospace;
  }

  :deep(button) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
    color: $white;
    i {
      @media screen and (max-width: 1440px) {
        display: none;
      }
    }
  }
}
</style>
