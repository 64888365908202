import { ControlsApi, RequirementsApi } from '@/client/api';
import initApi from '@/client_heplers/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  Control,
  Requirement,
  ControlAggregator,
  RequirementAggregator,
  Risk,
  TestingTestAndResult,
  ControlsApiGetControlApiControlsControlIdGetRequest,
  ControlsApiUpdateControlApiControlsControlIdPatchRequest,
  ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest,
  RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest,
  ControlsApiGetControlAssistantGuidanceApiControlsControlIdGuidanceAssistantGetRequest,
  ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest,
  ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest,
  ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest,
  ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest,
  ControlsApiGetControlApiV1ControlsControlIdGetRequest,
  RequirementsApiGetRequirementApiV1RequirementsRequirementIdGetRequest,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';

import type { AxiosError } from 'axios';

export const useTaskStore = defineStore('task', () => {
  const task = ref<ControlAggregator | null>(null);
  const requirement = ref<RequirementAggregator | null>(null);
  const controlRisks = ref<Risk[] | null>(null);
  const controlTests = ref<TestingTestAndResult[] | null>(null);
  const controlLight = ref<Control | null>(null);
  const requirementLight = ref<Requirement | null>(null);
  const isLoading = ref(false);

  const getControl = async (
    params: ControlsApiGetControlApiControlsControlIdGetRequest,
    reassign: boolean = true,
  ) => {
    try {
      isLoading.value = true;
      const controlsApi = await initApi(ControlsApi);
      const response = await controlsApi?.getControlApiControlsControlIdGet(params);
      if (!response) return;

      if (reassign) {
        task.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const getControlLight = async (
    params: ControlsApiGetControlApiV1ControlsControlIdGetRequest,
    reassign: boolean = true,
  ) => {
    try {
      isLoading.value = true;
      const controlsApi = await initApi(ControlsApi);
      const response = await controlsApi?.getControlApiV1ControlsControlIdGet(params);
      if (!response) return;

      if (reassign) {
        controlLight.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateTask = async (params: ControlsApiUpdateControlApiControlsControlIdPatchRequest) => {
    try {
      const controlsApi = await initApi(ControlsApi);
      isLoading.value = true;
      const response = await controlsApi?.updateControlApiControlsControlIdPatch(params);
      if (!response) return;

      task.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateTaskAssignee = async (
    params: ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);
      const response =
        await controlsApi?.updateControlAssigneeApiControlsControlIdAssigneePatch(params);
      if (!response) return;

      task.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getRequirement = async (
    params: RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest,
    reassign: boolean = true,
  ) => {
    try {
      const requirementsApi = await initApi(RequirementsApi);
      const response = await requirementsApi?.getRequirementApiRequirementsRequirementIdGet(params);
      if (!response) return;

      if (reassign) {
        requirement.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getRequirementLight = async (
    params: RequirementsApiGetRequirementApiV1RequirementsRequirementIdGetRequest,
    reassign: boolean = true,
  ) => {
    try {
      const requirementsApi = await initApi(RequirementsApi);
      const response =
        await requirementsApi?.getRequirementApiV1RequirementsRequirementIdGet(params);
      if (!response) return;

      if (reassign) {
        requirementLight.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getControlAssistantGuidance = async (
    params: ControlsApiGetControlAssistantGuidanceApiControlsControlIdGuidanceAssistantGetRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);
      const response =
        await controlsApi?.getControlAssistantGuidanceApiControlsControlIdGuidanceAssistantGet(
          params,
        );

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const addRiskToControl = async (
    params: ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);

      await controlsApi?.addRiskToControlApiControlsControlIdRisksRiskIdPut(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getControlRisks = async (
    params: ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);

      const response = await controlsApi?.getControlRisksApiControlsControlIdRisksGet(params);

      if (!response) return;

      controlRisks.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getControlTests = async (
    params: ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);

      const response =
        await controlsApi?.getTestingTestsByControlApiControlsControlIdTestsGet(params);

      if (!response) return;

      controlTests.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const removeRiskFromControl = async (
    params: ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest,
  ) => {
    try {
      const controlsApi = await initApi(ControlsApi);

      await controlsApi?.removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getControl,
    getControlAssistantGuidance,
    getRequirement,
    isLoading,
    requirement,
    task,
    updateTask,
    updateTaskAssignee,
    addRiskToControl,
    getControlRisks,
    controlRisks,
    removeRiskFromControl,
    getControlTests,
    controlTests,
    getControlLight,
    controlLight,
    getRequirementLight,
    requirementLight,
  };
});
