import { ref } from 'vue';
import { defineStore } from 'pinia';
import initApi from '@/client_heplers/utils';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { ConfigApi } from '@/client/api';
import type { OrganizationConfig, PatchOrganizationConfig } from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { AxiosError } from 'axios';

export const useConfigStore = defineStore('config', () => {
  const configData = ref<OrganizationConfig | null>(null);
  const vendorsEnabled = ref<boolean>(false);
  const usersEnabled = ref<boolean>(false);

  const getOrganizationConfig = async () => {
    try {
      const configApi = await initApi(ConfigApi);
      const user = localStorage.getItem('user');

      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;

      const params = {
        organizationId: organization_id,
      };

      const response =
        await configApi?.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(params);

      const organizationStore = useOrganizationStore();

      await organizationStore.getOrganizationQouta(params);

      if (!response) return;

      configData.value = response.data;
      vendorsEnabled.value =
        response.data.flags?.vendor_management_enabled_for_entire_organization ?? true;
      usersEnabled.value =
        response.data.flags?.user_management_enabled_for_entire_organization ?? true;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateOrganizationConfig = async (data: PatchOrganizationConfig) => {
    try {
      const configApi = await initApi(ConfigApi);
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;

      const params = {
        organizationId: organization_id,
        patchOrganizationConfig: data,
      };

      const response =
        await configApi?.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(params);

      if (!response) return;

      configData.value = response.data;
      vendorsEnabled.value =
        response.data.flags?.vendor_management_enabled_for_entire_organization ?? false;
      usersEnabled.value =
        response.data.flags?.user_management_enabled_for_entire_organization ?? false;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    configData,
    getOrganizationConfig,
    updateOrganizationConfig,
    vendorsEnabled,
    usersEnabled,
  };
});
