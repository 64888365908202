<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import * as d3 from 'd3';

interface DataItem {
  month: string;
  Error: number;
  Failed: number;
  Passed: number;
}

const chart = ref<HTMLElement | null>(null);

const data = ref<DataItem[]>([
  { month: 'Jan', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Feb', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Mar', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Apr', Error: 0, Failed: 0, Passed: 0 },
  { month: 'May', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Jun', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Jul', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Aug', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Sep', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Oct', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Nov', Error: 0, Failed: 0, Passed: 0 },
  { month: 'Dec', Error: 0, Failed: 0, Passed: 0 },
]);

function drawChart() {
  if (!chart.value) return;

  d3.select(chart.value).selectAll('svg').remove();

  const margin = { top: 10, right: 0, bottom: 60, left: 25 };
  const width = chart.value.clientWidth;
  const height = 200 - margin.bottom;

  const svg = d3
    .select(chart.value)
    .append('svg')
    .attr('width', width)
    .attr('height', height + margin.bottom + margin.top);

  const x0 = d3
    .scaleBand()
    .rangeRound([0, width])
    .paddingInner(0.5)
    .paddingOuter(0.5)
    .domain(data.value.map((d) => d.month));

  const numberOfBarsPerGroup = 3;
  const barWidth = 9;
  const totalBarsWidth = numberOfBarsPerGroup * barWidth;
  const spaceAvailable = x0.bandwidth();
  const paddingBetweenBars = (spaceAvailable - totalBarsWidth) / numberOfBarsPerGroup;
  const paddingRatio = paddingBetweenBars / (barWidth + paddingBetweenBars);

  const x1 = d3
    .scaleBand()
    .padding(paddingRatio)
    .domain(['Error', 'Failed', 'Passed'])
    .rangeRound([0, x0.bandwidth()]);

  const y = d3.scaleLinear().rangeRound([height, 0]).domain([0, 40]);
  // .domain([0, d3.max(data.value, (d) => Math.max(d.Error, d.Failed, d.Passed)) || 0]);

  const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

  g.append('g')
    .selectAll('g')
    .data(data.value)
    .enter()
    .append('g')
    .attr('transform', (d) => `translate(${x0(d.month)},0)`)
    .selectAll('rect')
    .data((d) =>
      (['Error', 'Failed', 'Passed'] as (keyof DataItem)[]).map((key) => ({ key, value: d[key] })),
    )
    .enter()
    .append('rect')
    .attr('x', (d) => x1(d.key) || 0)
    .attr('y', (d) => y(Number(d.value)))
    .attr('width', x1.bandwidth())
    .attr('height', (d) => height - y(Number(d.value)))
    .attr('fill', (d) => {
      switch (d.key) {
        case 'Error':
          return '#FFAA34';
        case 'Failed':
          return '#F96F7F';
        case 'Passed':
          return '#52C41A';
        default:
          return '#52C41A';
      }
    });

  g.append('g')
    .attr('class', 'axis')
    .attr('transform', `translate(25,${height + 5})`)
    .call(d3.axisBottom(x0))
    .selectAll('text')
    .style('text-anchor', 'end')
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .attr('dx', '-.8em')
    .attr('dy', '.15em');

  const yAxis = g
    .append('g')
    .attr('class', 'axis')
    .attr('transform', `translate(0,0)`)
    .call(d3.axisLeft(y).ticks(5, 's'));

  yAxis
    .selectAll('text')
    .style('color', '#6E7880')
    .style('font-size', '12px')
    .style('font-weight', 400);

  yAxis
    .append('text')
    .attr('x', 2)
    .attr('y', y(y.ticks().pop() || 0) + 0.5)
    .attr('dy', '0.32em');

  g.selectAll('.axis path').style('stroke', 'transparent');

  g.selectAll('.axis line').style('stroke', 'transparent');

  svg
    .append('circle')
    .attr('cx', width / 2 - 43)
    .attr('cy', height + 46)
    .attr('r', 5)
    .attr('fill', '#FFAA34');
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width / 2)
    .attr('y', height + 50)
    .style('fill', '#76919E')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .text('Error');

  svg
    .append('circle')
    .attr('cx', width / 2 + 15)
    .attr('cy', height + 46)
    .attr('r', 5)
    .attr('fill', '#F96F7F');
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width / 2 + 65)
    .attr('y', height + 50)
    .style('fill', '#76919E')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .text('Failed');

  svg
    .append('circle')
    .attr('cx', width / 2 + 80)
    .attr('cy', height + 46)
    .attr('r', 5)
    .attr('fill', '#52C41A');
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width / 2 + 135)
    .attr('y', height + 50)
    .style('fill', '#76919E')
    .style('font-size', '12px')
    .style('font-weight', 400)
    .text('Passed');

  svg
    .append('g')
    .attr('class', 'grid')
    .call(
      d3
        .axisLeft(y)
        .tickSize(-width)
        .tickFormat(() => ''),
    )
    .attr('stroke', 'lightgrey')
    .attr('stroke-opacity', 0.1)
    .selectAll('.tick line')
    .attr('stroke', 'lightgrey');
}

onMounted(() => {
  drawChart();
});
</script>

<template>
  <div id="testing-chart" ref="chart"></div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

#testing-chart {
  width: 100%;
  color: $white;
}
</style>
