<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useQuasar } from 'quasar';
import MarkdownIt from 'markdown-it';
import MarkdownItHighlight from 'markdown-it-highlightjs';
import MarkdownItCodeCopy from 'markdown-it-code-copy';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';

import Icon from '@/components/atoms/Icon.vue';

import { useTaskStore } from '@/stores/TaskStore';
import GuidanceAssistant from '@/components/molecules/Task/guidance_assistant.vue';

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  expanded: {
    type: Boolean,
    default: false,
  },
});

const $q = useQuasar();
const renderedMarkdown = ref(null);
const opened = ref(false);
const taskStore = useTaskStore();

function markdown() {
  const md = new MarkdownIt({ linkify: true, typographer: true });
  md.use(MarkdownItHighlight, { hljs });
  md.use(MarkdownItCodeCopy);
  const purify = DOMPurify(window);

  if (props.component.component_specifics) {
    const render = md.render(props.component.component_specifics.component_content);
    renderedMarkdown.value = purify.sanitize(render);
  } else {
    const render = md.render(props.component.configuration.component_content);
    renderedMarkdown.value = purify.sanitize(render);
  }
}

function extractTextFromElement(element: Node) {
  let text = '';
  for (const node of element.childNodes) {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      text += extractTextFromElement(node);
    }
  }
  return text;
}

function toggleView() {
  opened.value = !opened.value;
}

onMounted(async () => {
  await markdown();

  opened.value = props.expanded;

  const allCopyBtns = document.querySelectorAll('.markdown-it-code-copy');
  for (let i = 0; i < allCopyBtns.length; i++) {
    const codeElement = allCopyBtns[i].parentElement?.querySelector('code');
    if (codeElement) {
      const codeText = extractTextFromElement(codeElement);
      allCopyBtns[i].addEventListener('click', () => {
        navigator.clipboard.writeText(codeText);
        $q.notify({
          icon: 'cloud_done',
          message: 'Copied',
        });
      });
    }
  }
});
</script>
<template>
  <div>
    <div v-if="component" class="component__container row">
      <div class="row col-12 items-center justify-between position-relative q-mb-md">
        <div class="row">
          <Icon
            icon-folder="task"
            :icon-name="$q.dark.isActive ? 'menu_book-white' : 'menu_book'"
          />
          <span v-if="component.name" class="component__title">{{ component.name }}</span>
          <span v-if="component.component_name" class="component__title">{{
            component.component_name
          }}</span>
        </div>
        <Icon
          v-if="opened"
          icon-folder="task"
          icon-name="arrow_up"
          class="toggle-view"
          @click="toggleView"
        />
        <Icon
          v-else
          icon-folder="task"
          icon-name="arrow_down"
          class="toggle-view"
          @click="toggleView"
        />
      </div>

      <div class="row col-12 position-relative">
        <div
          class="content markdown-element full-width"
          :class="opened ? 'content-opened' : ''"
          v-html="renderedMarkdown"
        ></div>
      </div>
    </div>

    <GuidanceAssistant v-if="taskStore.task && taskStore.task.control_code === 'MCF-35'" />
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/style';

:deep(textarea::placeholder) {
  @include paragraph-01(400, $common-1);
}
.position-relative {
  position: relative;
  .toggle-view {
    cursor: pointer;
  }
}
:deep(.content) {
  overflow: hidden;
  height: 0;
  img {
    max-width: 100%;
  }
  .markdown-it-code-copy {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 5px;
  }
  .markdown-it-code-copy:hover {
    background-color: $primary-200;
  }
  .markdown-it-code-copy::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-image: url('../../../assets/icons/svg/colored/copy-btn.svg');
  }
}
.content-opened {
  height: auto;
}
</style>
