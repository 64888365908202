/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTaskStore } from '@/stores/TaskStore';
import { useRisksStore } from '@/stores/RisksStore';
import { useAssetsStore } from '@/stores/AssetsStore';
import { useEvidenceStore } from '@/stores/EvidenceStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useFrameworkStore } from '@/stores/FrameworkStore';
import { useConfigStore } from '@/stores/ConfigStore';

import { Concept } from '@/client/api';
import { captureException } from '@/composables/Sentry';

import { NotFoundError } from '@/composables/ApiError';

const baseUrl = `${import.meta.env.VITE_API_URL}`;

interface IDataFetcher {
  fetchData(params: object): Promise<any>;
  getUrl(projectId: string, conceptId: string): string;
}

class FrameworkFetcher implements IDataFetcher {
  async fetchData(params: { frameworkCode: string; organizationId: string }): Promise<any> {
    try {
      return await useFrameworkStore().getFramework(params);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: ControlFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/organization/template-detail/${conceptId}`;
  }
}

class ControlFetcher implements IDataFetcher {
  async fetchData(params: { controlId: string }): Promise<any> {
    try {
      return await useTaskStore().getControlLight(params, false);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: ControlFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/controls?control=${conceptId}`;
  }
}

class RequirementFetcher implements IDataFetcher {
  async fetchData(params: { requirementId: string }): Promise<any> {
    try {
      return await useTaskStore().getRequirementLight(params, false);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: RequirementFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/requirements?requirement=${conceptId}`;
  }
}

class RiskFetcher implements IDataFetcher {
  async fetchData(params: { riskId: string }): Promise<any> {
    try {
      return await useRisksStore().getLightRisk(params);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: RiskFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/risk-detail/${conceptId}`;
  }
}

class AssetCardFetcher implements IDataFetcher {
  async fetchData(params: { assetCardId: string }): Promise<any> {
    try {
      return await useAssetsStore().getAsset(params, false);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: AssetCardFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/asset-detail/${conceptId}`;
  }
}

class EvidenceFetcher implements IDataFetcher {
  async fetchData(params: { evidenceId: string }): Promise<any> {
    try {
      return await useEvidenceStore().getEvidence(params);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: EvidenceFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/evidence?evidence=${conceptId}`;
  }
}

class TestingTestFetcher implements IDataFetcher {
  async fetchData(params: { projectId: string; testingTestId: string }): Promise<any> {
    try {
      return await useProjectsStore().getProjectTestingTest(params, false);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      }
      captureException(error, {
        message: 'Function: TestingTestFetcher fetchData - Tiptap Extensions: dataHandler',
      });
    }
  }

  getUrl(projectId: string, conceptId: string): string {
    return `${baseUrl}/projects/project-detail/${projectId}/test-detail/${conceptId}`;
  }
}

class DataHandler {
  private editorData: Map<string, any> = new Map();
  private fetcherMapping: { [key: string]: IDataFetcher };

  constructor() {
    this.fetcherMapping = {
      [Concept.Framework]: new FrameworkFetcher(),
      [Concept.Control]: new ControlFetcher(),
      [Concept.Requirement]: new RequirementFetcher(),
      [Concept.Risk]: new RiskFetcher(),
      [Concept.AssetCard]: new AssetCardFetcher(),
      evidence: new EvidenceFetcher(),
      [Concept.TestingTest]: new TestingTestFetcher(),
    };
  }

  private getFetcher(type: Concept | string): IDataFetcher {
    const fetcher = this.fetcherMapping[type];
    if (!fetcher) {
      throw new Error(`Unknown data type: ${type}`);
    }
    return fetcher;
  }

  public async getData(type: Concept | string, conceptId: string, projectId: string) {
    const projectsStore = useProjectsStore();

    if (type !== Concept.Framework && projectId !== projectsStore.project?.id) {
      return null;
    }

    if (this.editorData.has(conceptId)) {
      return this.editorData.get(conceptId);
    }

    const fetcher = this.getFetcher(type);
    const params = this.constructParams(type, projectId, conceptId);
    const data = await fetcher.fetchData(params);
    this.editorData.set(conceptId, data);
    return data;
  }

  private constructParams(type: Concept | string, projectId: string, conceptId: string): object {
    if (type === Concept.Framework) {
      const configStore = useConfigStore();

      return { frameworkCode: conceptId, organizationId: configStore.configData?.organization_id };
    }
    if (type === Concept.TestingTest) {
      return { projectId, testingTestId: conceptId };
    }
    const idKey = this.getIdKey(type);
    return { [idKey]: conceptId };
  }

  private getIdKey(type: Concept | string): string {
    switch (type) {
      case Concept.Control:
        return 'controlId';
      case Concept.Requirement:
        return 'requirementId';
      case Concept.Risk:
        return 'riskId';
      case Concept.AssetCard:
        return 'assetCardId';
      case 'evidence':
        return 'evidenceId';
      default:
        throw new Error(`Unknown data type: ${type}`);
    }
  }

  public getDataUrl(type: Concept, projectId: string, conceptId: string) {
    const fetcher = this.getFetcher(type);
    return fetcher.getUrl(projectId, conceptId);
  }
}

export { DataHandler };
