import { useProjectsStore } from '@/stores/ProjectsStore';

export function currentAssignee(id: string) {
  const projectStore = useProjectsStore();

  if (!projectStore.projectUsers) return '';

  const assignee = projectStore.projectUsers.items.find((user) => user.id === id) || '';

  if (!assignee) return '';

  const text = assignee.firstname + ' ' + assignee.lastname;

  return text;
}

export function formatString(string: string) {
  const firstChar = string.charAt(0).toUpperCase();
  return firstChar + string.slice(1).replace(/_/g, ' ');
}
