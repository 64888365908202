<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, reactive, watch, onMounted } from 'vue';

import type { UserInOrgList } from '@/client/api';
import type { TablePagination } from '@/interfaces/models/Pagination';
import { UserState } from '@/client/api';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import DEditOrgUser from '@/components/dialogs/DEditOrgUser.vue';
import DAddOrgUser from '@/components/dialogs/DAddOrgUser.vue';
import DInvitedUser from '@/components/dialogs/DInvitedUser.vue';

import { captureException } from '@/composables/Sentry';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

const organizationStore = useOrganizationStore();

const filter = ref('');
const status = ref('All');
const statusOptions = ref(['All', UserState.Activated, UserState.Deactivated, UserState.Invited]);
const showEditDialog = ref(false);
const showConfirmDialog = ref(false);
const showAddDialog = ref(false);
const showInvitedDialog = ref(false);
const usersIsLoading = ref(false);
const selectedUser = ref<null | UserInOrgList>(null);
const tablePagination = ref<TablePagination | null>(null);

const userColumns = reactive({
  firstname: 'Name',
  email: 'Email',
  job_title: 'Job Title',
  is_active: 'Status',
  activated_at: 'Active since',
  last_access: 'Last access',
});

function editUser(user: UserInOrgList) {
  selectedUser.value = user;
  showEditDialog.value = true;
}

function editInvitedUser(user: UserInOrgList) {
  selectedUser.value = user;
  showInvitedDialog.value = true;
}

async function filterByStatus() {
  await getUsers();
}

async function updateData(
  value: {
    pagination: TablePagination;
    filter?: string;
    getCellValue?: (col: unknown, row: unknown) => unknown;
  },
  signal: AbortSignal,
) {
  tablePagination.value = value.pagination;
  filter.value = value.filter || '';

  await getUsers(signal);
}

function openUserInfo(user: UserInOrgList) {
  selectedUser.value = user;
  showInvitedDialog.value = true;
}

function updateSelected(user: UserInOrgList) {
  selectedUser.value = user;
  getUsers();
}

function editSave() {
  showEditDialog.value = false;
  getUsers();
}

async function getUsers(signal?: AbortSignal) {
  try {
    usersIsLoading.value = true;
    await organizationStore.getOrganizationUsers(
      {
        ...organizationStore.usersData,
        ...DEFAULT_PAGINATION,
        ...tablePagination.value,
        size: tablePagination.value?.rowsPerPage || DEFAULT_PAGINATION_SIZE,
        name: filter.value !== '' ? filter.value : null,
        includeInactive: true,
        state: status.value === 'All' ? null : status.value,
      },
      { signal },
    );
  } catch (error) {
    captureException(error, {
      message: 'Component: Users, Hook: onMounted',
    });
  } finally {
    usersIsLoading.value = false;
  }
}

function closeDialog() {
  showConfirmDialog.value = false;
  showAddDialog.value = false;
  showEditDialog.value = false;
  showInvitedDialog.value = false;
  selectedUser.value = null;
}

watch(
  () => organizationStore.usersData,
  (newV) => {
    if (newV && newV.is_org_admin) {
      Object.assign(userColumns, { org_users_actions: '' });
    }
  },
);

onMounted(async () => {
  getUsers();
});
</script>

<template>
  <div>
    <OTable
      :data="organizationStore.usersData?.items"
      :pagination="organizationStore.usersData!"
      :columns-names="userColumns"
      :row-clickable="false"
      :is-loading="usersIsLoading"
      :skeleton-size="4"
      search-placeholder="Search user"
      sort-by="firstname"
      store="organizationStore"
      action="getOrganizationUsers"
      parent-el="org-users-page"
      @open-user-info="openUserInfo"
      @edit-user="editUser"
      @edit-invited-user="editInvitedUser"
      @update-data="updateData"
    >
      <template #header-filters>
        <q-select
          v-model="status"
          outlined
          :options="statusOptions"
          dense
          class="q-mr-md"
          :class="[usersIsLoading ? 'skeleton' : '']"
          @update:model-value="filterByStatus"
        />
      </template>
      <template #header-button>
        <q-btn
          v-if="organizationStore.usersData"
          class="btn-new"
          icon="add"
          label="New User"
          unelevated
          :disable="!organizationStore.usersData.is_org_admin"
          @close-dialog="closeDialog"
          @click="showAddDialog = true"
        />
      </template>
    </OTable>

    <div
      v-if="
        organizationStore.usersData &&
        organizationStore.usersData.items.length === 0 &&
        !usersIsLoading
      "
      class="empty__wrapp row items-center q-mt-md"
    >
      <AEmptyData
        v-if="!filter && !status"
        icon-name="folder"
        :header="`You don’t have any Users in this Organization.`"
        text="Start by adding a User"
        class="full-width items-center"
      />
      <AEmptyData
        v-else
        icon-name="search"
        :header="`No results for your search.`"
        text="Try changing your search."
        class="full-width items-center"
      />
    </div>

    <ADialog
      :show-dialog="showAddDialog"
      max-width="560px !important"
      min-width="560px !important"
      min-height="auto"
      @close-dialog="closeDialog"
      @hide="closeDialog"
    >
      <DAddOrgUser @close-dialog="closeDialog" @update-list="getUsers" />
    </ADialog>

    <ADialog
      :show-dialog="showEditDialog"
      max-width="660px !important"
      min-width="660px !important"
      min-height="auto"
      @hide="closeDialog"
    >
      <DEditOrgUser
        v-if="selectedUser"
        :edited-user="selectedUser"
        @close-dialog="closeDialog"
        @save="editSave"
        @update-selected-user="updateSelected"
      />
    </ADialog>

    <ADialog
      :show-dialog="showInvitedDialog"
      max-width="560px !important"
      min-width="560px !important"
      min-height="auto"
      @hide="closeDialog"
    >
      <DInvitedUser
        v-if="selectedUser"
        :info-user="selectedUser"
        @close-dialog="closeDialog"
        @update-users="getUsers"
      />
    </ADialog>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.btn-new {
  background: $secondary-500;
  text-transform: none;
}

.q-select {
  width: 160px;
  :deep(.q-field__inner) {
    border: none !important;
    padding: 0 !important;
  }
}
</style>
