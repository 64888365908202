<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onMounted(() => {
  if (window.innerWidth >= 600) {
    router.push({ name: 'login-page' });
  }
});
</script>
<template>
  <q-page class="mobile-not-supported-container row flex-center">
    <div>
      <h1 class="text-center">Mobile not supported!</h1>
      <div class="text-center">
        Looks like you're on a mobile device. Our application is built for desktop use and does not
        support mobile platforms. We appreciate your understanding.
      </div>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.t-and-cs {
  opacity: 0.4;
  display: block;
  a {
    color: $primary-900;
  }
}

.mobile-not-supported-container {
  height: 100vh;
  color: $white;
  background: transparent url('@/assets/images/login-bg.png') center/cover !important;

  h1 {
    @include heading-01(600, $secondary-800);
    color: $primary-300 !important;
    width: 100%;
  }
}
</style>
