<script setup lang="ts">
import { watch, ref } from 'vue';

import type { PropType } from 'vue';
import type { AssetCard, Status } from '@/client/api';

import { statusIcon, STATUS_LABEL } from '@/composables/utils';
import { OrganizationCurrency } from '@/composables/Config';
import { formatString } from '../utils/utils';

import { useConfigStore } from '@/stores/ConfigStore';

import Icon from '@/components/atoms/Icon.vue';

defineProps({
  data: {
    type: Object as PropType<AssetCard>,
    required: true,
  },
});

const configStore = useConfigStore();
const currency = ref('');

watch(
  () => configStore.configData,
  (newV) => {
    if (!newV) return;

    currency.value = OrganizationCurrency(newV.general.currency);
  },
  {
    immediate: true,
  },
);
</script>
<template>
  <div class="row col-12">
    <div class="row col-12 q-mb-md">
      <q-badge
        class="status-badge"
        :label="STATUS_LABEL[data.status.toUpperCase() as keyof typeof STATUS_LABEL]"
        :class="data.status"
      >
        <Icon
          :icon-name="statusIcon(data.status.replaceAll(' ', '_').toLowerCase() as Status)"
          icon-folder="status"
          icon-size="20px"
          class="q-mr-sm"
        />
      </q-badge>
    </div>
    <span class="title q-mb-md">{{ data.name }}</span>
    <div class="row items-center col-12">
      <span class="dark q-mr-md"
        >Type:
        <b>{{ formatString(data.asset_card_type) }}</b>
      </span>
      <span class="dark q-mr-xs"
        >Value:
        <b>{{ currency + data.value }}</b>
      </span>
    </div>
    <div v-if="data.description" class="row col-12 items-center q-mb-sm">
      <p>{{ data.description }}</p>
    </div>
    <div class="row col-12 items-center">
      <p>
        Uploaded by <b>{{ data.created_by.firstname + ' ' + data.created_by.lastname }}</b>
      </p>
    </div>
  </div>
</template>
