<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['clickText']);

defineProps({
  iconName: {
    type: String,
    default: null,
  },
  header: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  actionText: {
    type: String,
    default: null,
  },
});
</script>

<template>
  <div class="column justify-center">
    <Icon v-if="iconName" :icon-name="iconName" icon-folder="empty" />
    <slot name="icon-slot" />
    <h4 class="q-my-sm">{{ header }}</h4>
    <div class="row">
      <span>{{ text }}</span>
      <div v-if="actionText" class="action__container" @click="emit('clickText')">
        <span class="action-text">{{ `&nbsp;${actionText}` }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
h4 {
  @include paragraph-02(600, $secondary-400);
}

span {
  @include paragraph-01(400, $secondary-400);
}

.action__container {
  padding-bottom: 0.5px;
  border-bottom: 2px solid $black;
  cursor: pointer;
}
</style>
