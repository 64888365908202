<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import Icon from '@/components/atoms/Icon.vue';

import { CURRENT_YEAR } from '@/composables/utils';

const isLoading = ref(false);

const router = useRouter();

const path = `${import.meta.env.VITE_API_URL}/api/auth/authorize`;

function loginTo() {
  window.location.href = path;
}

onMounted(() => {
  if (window.innerWidth < 600) {
    router.push({ name: 'mobile-not-supported' });
  }
});
</script>

<template>
  <q-page class="login-container row">
    <div class="login-left row col-4 content-between full-height">
      <div class="logo__container row full-width justify-center items-center col-12">
        <Icon icon-name="modulos-logo" icon-folder="drawer" class="q-mr-md q-mt-sm" />

        <Icon icon-name="icon-modulos-logo" icon-folder="black" />
      </div>
      <div class="row full-width justify-center col-12">
        <q-card class="login-card full-width">
          <q-card-section class="text-center">
            <h1>Welcome</h1>
          </q-card-section>

          <q-card-section>
            <div class="col-10 q-my-md">
              <q-btn label="Login" class="full-width" :loading="isLoading" @click="loginTo" />
            </div>
          </q-card-section>

          <q-q-card-section class="text-center t-and-cs">
            <p>
              By continuing in you agree to Modulos's
              <a href="https://www.modulos.ai/terms-and-conditions" target="_blank"
                >Terms and Conditions</a
              >
              and
              <a href="https://www.modulos.ai/privacy-policy/" target="_blank">Privacy Policy</a>
            </p>
          </q-q-card-section>
        </q-card>
      </div>
      <div class="footer text-center full-width col-12">
        {{ ` © 2023-${CURRENT_YEAR} Modulos AG.` }}
      </div>
    </div>
    <div class="login-right row col-8">
      <div class="title__container col-12 row items-end q-mb-xl">
        <h1 class="title q-mr-sm q-my-none">AI Governance Platform</h1>
      </div>
      <div class="journey__container col-12 q-mb-xl">
        <span class="journey-start">Your journey to <b>AI compliance</b> starts here. </span>
      </div>
      <div class="trusted-by row col-12 justify-start">
        <div class="trusts col-12 row items-center justify-start">
          <h4 class="q-my-none col-12 q-mb-xl">Trusted by</h4>
          <Icon icon-name="pwc" icon-folder="login" class="q-mr-xl" />
          <Icon icon-name="scsk" icon-folder="login" class="q-mr-xl" />
          <Icon icon-name="mh" icon-folder="login" class="q-mr-xl" />
          <Icon icon-name="serai" icon-folder="login" class="q-mr-xl" />
          <Icon icon-name="utbd" icon-folder="login" class="q-mr-xl" />
          <Icon icon-name="eth" icon-folder="login" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.login-container {
  height: 100vh;
  color: $black;
  background: transparent url('@/assets/images/login-bg.png') center/cover !important;
}
.login-left {
  background: $white;
  padding: 98px 98px 30px;
  @media screen and (max-width: 1024px) {
    padding: 58px 28px 30px;
  }

  .login-card {
    box-shadow: none !important;
    h1 {
      @include heading-01(600, $secondary-800);
      color: $secondary-600 !important;
    }

    .q-gutter-md {
      margin-bottom: 20px;
    }

    .q-mt-md {
      margin-top: 20px;
    }

    :deep(label) {
      .q-field__control::before {
        border: 1px solid $secondary-600 !important;
      }
    }

    button {
      @include paragraph-01(400, $white);
      height: 42px;
      background: $secondary-500 !important;
      text-transform: none;
      border-radius: 5px;
    }
  }
  .footer {
    @include caption(600, $secondary-600);
  }
}
.login-right {
  padding: 100px 0px 30px 180px;
  @media screen and (max-width: 1024px) {
    padding: 200px 155px 30px 125px;
  }
  .title__container {
    h1 {
      @include heading-01(600, $white);
      letter-spacing: -1.28px;
    }
  }
  .journey__container {
    max-width: 600px;
    .journey-start {
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: 70px;
      background: var(--linear, linear-gradient(39deg, #20dee7 0%, #79efc7 100%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (max-width: 1024px) {
        font-size: 52px;
        line-height: 50px;
      }
    }
  }
  .trusted-by {
    height: fit-content;
    h4 {
      @include heading-04(700, $white);
    }
  }
}

.t-and-cs {
  opacity: 0.4;
  display: block;
  a {
    color: $primary-900;
  }
}
</style>
