<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useQuasar } from 'quasar';

import type { PropType } from 'vue';
import type { CreationFrameworkTemplate } from '@/client/api';

import ATooltip from '@/components/atoms/ATooltip.vue';

const props = defineProps({
  card: {
    type: Object as PropType<CreationFrameworkTemplate>,
    required: true,
  },
  defaultCard: {
    type: Boolean,
  },
  showTooltip: {
    type: Boolean,
  },

  showCheckbox: {
    type: Boolean,
    default: false,
  },
  cardSelectable: {
    type: Boolean,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const propCard = props.card;
const emit = defineEmits(['selected', 'selectScope']);
const $q = useQuasar();
const frameworkImage = ref<string | null>(null);

const cardSelected = ref(false);

function resetCheckbox() {
  cardSelected.value = false;

  emit('selectScope', false, props.card.framework_code);
}

function toggleCheckbox(value: boolean) {
  emit('selectScope', value, props.card.framework_code, props.card);
}

onMounted(() => {
  if (props.card.framework_icon_type === 'image' && props.card.framework_icon_base64) {
    frameworkImage.value = `data:image/svg+xml;base64,${props.card.framework_icon_base64}`;
  }
});

defineExpose({
  propCard,
  cardSelected,
  resetCheckbox,
});
</script>

<template>
  <q-card
    class="my-card text-white column justify-between"
    :class="[cardSelected ? 'card-selected' : '', cardSelectable ? 'card-selectable' : '']"
  >
    <div>
      <q-card-section class="title__section row items-center">
        <div class="row items-center col-12">
          <span
            v-if="card.framework_icon_type === 'flag'"
            class="fi flag"
            :class="`fi-${card.framework_icon}`"
          ></span>
          <img
            v-if="
              card.framework_icon_type === 'image' && card.framework_icon_base64 && frameworkImage
            "
            :src="frameworkImage"
            class="q-mr-sm"
          />
          <span class="text-h6 col-8 q-ml-sm">{{ card.framework_name }}</span>
        </div>
        <ATooltip
          v-if="showTooltip && $q.dark.isActive"
          icon-folder="white"
          icon-name="icon-hint"
          tooltip-text="Some text as content of Tooltip"
        />
        <ATooltip
          v-if="showTooltip && !$q.dark.isActive"
          icon-folder="black"
          icon-name="icon-hint"
          tooltip-text="Some text as content of Tooltip"
        />
        <div class="title__section-utils row items-center">
          <q-checkbox
            v-if="showCheckbox"
            v-model="cardSelected"
            :disable="isLoading"
            @update:model-value="toggleCheckbox"
          />
        </div>
      </q-card-section>
      <q-card-section v-if="card.framework_label !== ''" class="badge__section">
        <q-badge class="general-badge justify-center" :label="card.framework_label" />
      </q-card-section>
      <q-card-section
        class="content__section q-pt-none column justify-between items-between"
        :class="defaultCard ? 'items-center' : ''"
      >
        <p>{{ card.framework_description }}</p>

        <img v-if="defaultCard" src="../../assets/images/empy-card.png" alt="card-empty" />
      </q-card-section>
    </div>
    <q-card-section class="bottom__section">
      <div v-if="!defaultCard" class="scopes__container row justify-between">
        <div v-if="card.number_of_requirements" class="tasks__total row items-center">
          <span>Requirements</span>
          <div class="total">
            {{
              card.number_of_requirements < 10
                ? '0' + card.number_of_requirements
                : card.number_of_requirements
            }}
          </div>
        </div>
        <div v-if="card.number_of_controls" class="tasks__total row items-center">
          <span>Controls</span>
          <div class="total">
            {{
              card.number_of_controls < 10 ? '0' + card.number_of_controls : card.number_of_controls
            }}
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.my-card {
  @include paragraph-01(400, #9b9b9b);
  height: 100%;
  color: #9b9b9b !important;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
  border: 2px solid transparent;
  transition: all 0.1s ease;

  .title__section {
    position: relative;
    .flag {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      background-size: cover;
    }
    .text-h6 {
      @include paragraph-02(500, $common-4);
      margin-right: 5px;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    :deep(img) {
      min-width: 42px;
      min-height: 42px;
      max-width: 42px;
      max-height: 42px;
      width: auto;
      height: auto;
      display: block;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }

    &-utils {
      position: absolute;
      right: 0;
      .q-checkbox {
        :deep(.q-checkbox__inner--falsy) {
          .q-checkbox__bg {
            color: $common-1;
          }
        }
        :deep(.q-checkbox__inner--truthy) {
          .q-checkbox__bg {
            color: $secondary-800;
          }
        }
      }
    }
  }

  .content__section {
    .q-chip {
      margin-left: unset;

      background: $secondary-100;
      :deep(.q-chip__content) {
        @include caption(400, $secondary-500);
      }
    }
    #suggested {
      max-width: 111px;
      margin: 0 0 15px 0;
      background: $secondary-100;
      :deep(.q-chip__content) {
        @include caption(400, $secondary-500);
      }
    }
  }
}

.bottom__section {
  .tasks__total {
    margin-top: 25px;
    position: relative;
    span {
      @include paragraph-01(400, $secondary-500);
      margin-right: 5px;
    }
    .total {
      border-radius: 10px;
      background: $secondary-50;
      padding: 1px 8px;
      letter-spacing: 1px;
    }
    :deep(button) {
      position: absolute;
      right: 0;
      border-radius: 5px;
      span {
        @include paragraph-01(700, $secondary-500);
        text-transform: none;
      }
    }
  }
}

.my-card:hover {
  height: 105%;
  transform: translateY(-2.5%);
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
}
.card-selectable {
  cursor: pointer;
}
.card-selected {
  border: 1px solid $secondary-500 !important;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08) !important;
}
</style>
