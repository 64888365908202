<script setup lang="ts">
import { computed } from 'vue';

import type { PropType } from 'vue';

import { Concept } from '@/client/api';

import { ItemBorder } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

const props = defineProps({
  tableProps: {
    type: Object,
    required: true,
  },
  parentEl: {
    type: String,
    required: true,
  },
  concept: {
    type: [Object as PropType<Concept>, String] as PropType<Concept | string>,
    required: true,
  },
});

const uniqueCode = computed(() => {
  return props.concept === Concept.Control
    ? props.tableProps.row.control_code
    : props.concept === Concept.Requirement
      ? props.tableProps.row.requirement_code
      : props.concept === Concept.Framework
        ? props.tableProps.row.framework_code
        : props.tableProps.row.unique_code
          ? props.tableProps.row.unique_code
          : '';
});
</script>

<template>
  <q-td
    :props="tableProps"
    :style="{
      'border-left': tableProps.row.status ? `4px solid ${ItemBorder(tableProps.row.status)}` : '',
    }"
  >
    <div v-if="parentEl !== 'projectsPage'" class="row items-center name__wrapp">
      <Icon
        v-if="'read_access' in tableProps.row && !tableProps.row.read_access"
        icon-name="lock"
        icon-folder="colored"
        class="q-mr-sm"
        :class="['read_access' in tableProps.row && !tableProps.row.read_access ? 'no-access' : '']"
      />

      <div class="row name__container">
        <span class="name-text">{{ tableProps.row.name }}</span>
        <span class="col-12 unique-code">{{ uniqueCode }}</span>
      </div>
      <q-tooltip
        v-if="'read_access' in tableProps.row && !tableProps.row.read_access"
        anchor="center end"
        self="center start"
        class="project-tooltip"
      >
        {{ 'You don’t have access to this project. Contact the admin.' }}
      </q-tooltip>
    </div>
    <div v-else-if="parentEl === 'projectsPage'" class="row">
      <div class="col-12 q-mb-xs row items-center">
        <Icon
          v-if="'read_access' in tableProps.row && !tableProps.row.read_access"
          icon-name="lock"
          icon-folder="colored"
          class="q-mr-sm"
          :class="[
            'read_access' in tableProps.row && !tableProps.row.read_access ? 'no-access' : '',
          ]"
        />

        <span>{{ tableProps.row.name }}</span>
        <q-tooltip
          v-if="'read_access' in tableProps.row && !tableProps.row.read_access"
          anchor="center end"
          self="center start"
          class="project-tooltip"
        >
          {{ 'You don’t have access to this project. Contact the admin.' }}
        </q-tooltip>
      </div>
      <div class="row col-12">
        <div class="row items-center q-mr-sm">
          <Icon icon-name="page_info" icon-folder="table" class="q-mr-xs" />
          <span>{{ tableProps.row.n_controls }}</span>
        </div>
        <div class="row items-center q-mr-sm">
          <Icon icon-name="beenhere" icon-folder="table" class="q-mr-xs" />
          <span>{{ tableProps.row.n_requirements }}</span>
        </div>
        <div class="row items-center">
          <Icon icon-name="file_present" icon-folder="table" class="q-mr-xs" />
          <span>{{ tableProps.row.n_artifacts }}</span>
        </div>
      </div>
    </div>
  </q-td>
</template>
