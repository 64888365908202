import { useGlobalStore } from '@/stores/GlobalStore';

export const successMessage = (message: string) => {
  const store = useGlobalStore();

  store.$q.notify({
    message: message,
  });
};

export const errorMessage = (message: string, color: string = 'red') => {
  const store = useGlobalStore();

  store.$q.notify({
    message: message,
    color: color,
  });
};
