<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import type { TipTapContent } from '@/interfaces/models/Editor';
import { onMounted, shallowRef } from 'vue';

import { useTaskStore } from '@/stores/TaskStore';

import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update']);

const taskStore = useTaskStore();
const editor = shallowRef<TipTapContent>({
  content: '',
  json_content: {},
});

function updateEditor(value: TipTapContent) {
  emit('update', value, props.component.id, 'notes');
}

onMounted(() => {
  editor.value = {
    content: props.component.result,
    json_content: props.component.json_result,
  };
});
</script>
<template>
  <div v-if="component" class="component__container row">
    <div class="row col-12 items-center relative-position justify-between"></div>

    <div class="row col-12 position-relative q-mt-md">
      <div class="full-width content">
        <div v-show="taskStore.task">
          <TipTapEditor
            :editor-content="editor"
            :editable="!taskStore.task?.is_locked"
            root-el="Control"
            :full-height="true"
            @update-editor="updateEditor"
          />
        </div>
      </div>
    </div>
    <slot name="update"> </slot>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/style';
:deep(textarea::placeholder) {
  @include paragraph-01(400, $common-1);
}
.position-relative {
  position: relative;
}
.remark-snippet {
  color: $black;
  font-family: monospace;
}

.component__container {
  .toggle-view {
    cursor: pointer;
  }
  p {
    @include paragraph-01(400, $common-4);
  }
  .default-text {
    font-style: italic;
    color: $common-2;
  }
}
</style>
