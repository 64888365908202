import { Link } from '@tiptap/extension-link';

const CustomLink = Link.extend({
  parseHTML() {
    return [
      {
        tag: 'a:not([data-badge])',
      },
    ];
  },
});

export default CustomLink;
