<script setup lang="ts"></script>

<template>
  <div class="row col-12 left__details q-mb-md">
    <div class="row col-12 overview__container q-mb-lg">
      <q-skeleton animation="wave" width="24px" height="24px" class="q-mr-xs bg-blue-grey-1" />

      <q-skeleton
        animation="wave"
        width="150px"
        height="30px"
        type="text"
        class="overview__title q-ml-sm bg-blue-grey-1"
      />
    </div>
    <div class="control__question col-12 column">
      <q-skeleton
        animation="wave"
        width="75px"
        height="30px"
        type="text"
        class="bg-blue-grey-1 question__title q-my-md"
      />
      <q-skeleton animation="wave" width="200px" height="30px" type="text" class="bg-blue-grey-1" />
    </div>
    <div class="bg-blue-grey-1 control__description column">
      <q-skeleton
        animation="wave"
        width="75px"
        height="30px"
        type="text"
        class="bg-blue-grey-1 description__title q-my-md"
      />
      <q-skeleton animation="wave" width="250px" height="30px" type="text" class="bg-blue-grey-1" />
    </div>
  </div>
</template>
