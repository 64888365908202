<script setup lang="ts"></script>

<template>
  <q-card class="my-card text-white">
    <q-card-section class="title__section row q-pb-none q-mb-xs items-center">
      <q-skeleton animation="wave" class="text-h6 bg-blue-grey-1" type="text" width="100px" />
    </q-card-section>
    <q-card-section class="info__section row q-pb-none q-mb-xs justify-between items-center">
      <div class="column items-start q-mb-sm">
        <q-skeleton
          animation="wave"
          type="text"
          class="q-mr-sm bg-blue-grey-1"
          width="100px"
        ></q-skeleton>
        <q-skeleton
          animation="wave"
          type="text"
          class="black-text bg-blue-grey-1"
          width="120px"
        ></q-skeleton>
      </div>
      <div class="column items-start q-mb-sm">
        <q-skeleton
          animation="wave"
          type="text"
          class="q-mr-sm bg-blue-grey-1"
          width="100px"
        ></q-skeleton>
        <q-skeleton
          animation="wave"
          type="text"
          class="black-text bg-blue-grey-1"
          width="120px"
        ></q-skeleton>
      </div>
    </q-card-section>
    <hr />
    <q-card-section class="row justify-between">
      <q-skeleton animation="wave" width="100px" class="bg-blue-grey-1"></q-skeleton>
      <q-skeleton animation="wave" width="80px" class="bg-blue-grey-1"></q-skeleton>
    </q-card-section>
    <q-card-section class="row justify-center">
      <q-skeleton animation="wave" type="circle" size="100px" class="bg-blue-grey-1" />
    </q-card-section>
    <q-card-section>
      <q-skeleton
        animation="wave"
        type="text"
        class="text-uppercase bg-blue-grey-1"
        width="125px"
      ></q-skeleton>
      <div class="flags row items-center q-mt-sm">
        <q-skeleton
          v-for="n in 2"
          :key="n"
          animation="wave"
          type="circle"
          size="40px"
          class="q-mr-sm bg-blue-grey-1"
        />
      </div>
    </q-card-section>
    <q-card-section class="row justify-center">
      <q-skeleton animation="wave" width="90%" height="40px" type="QBtn" class="bg-blue-grey-1" />
    </q-card-section>
  </q-card>
</template>
