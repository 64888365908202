<script lang="ts" setup>
import * as d3 from 'd3';
import { ref, onMounted, watch } from 'vue';
import type { PropType } from 'vue';
import type { StatusOverview } from '@/client/api';

import AProgressTooltip from '@/components/atoms/AProgressTooltip.vue';

interface ArcData {
  startAngle: number;
  endAngle: number;
  fillColor: string;
  showIcon: boolean;
}

const props = defineProps({
  progress: {
    type: Object as PropType<StatusOverview>,
    required: true,
  },
  empty: {
    type: Boolean,
    default: false,
  },
});

const chart = ref<HTMLDivElement>();
const outerRadius = ref(50);
const innerRadius = ref(40);

function convertCountsToRadians() {
  const percentages = [
    props.progress.completed_percentage,
    props.progress.in_review_percentage,
    props.empty ? 100 : props.progress.in_progress_percentage,
  ];
  const colors = ['#509AF4', '#FD956B', '#E1E7EA'];
  let cumulativePercent = 0;
  let totalPercentage = percentages.reduce((acc, val) => (acc ?? 0) + (val ?? 0), 0);
  const radiansPerPercent = (Math.PI * 2) / (totalPercentage ?? 1);
  const gapRadians = 0.02;
  const arcs = percentages
    .map((percent, i) => {
      if (percent === 0 || !percent) return null;

      const startAngle = cumulativePercent * radiansPerPercent - Math.PI / 2;
      cumulativePercent += percent;
      let endAngle = cumulativePercent * radiansPerPercent - Math.PI / 2;
      endAngle -= gapRadians;

      return {
        startAngle,
        endAngle,
        fillColor: colors[i % colors.length],
        showIcon: percent >= 10,
      };
    })
    .filter((arc) => arc !== null);

  if (arcs.length === 0) {
    arcs.push({
      startAngle: -Math.PI / 2,
      endAngle: Math.PI / 2,
      fillColor: '#E1E7EA',
      showIcon: false,
    });
  }

  return arcs;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderChart(arcData: any) {
  if (!chart.value) return;
  d3.select(chart.value).select('svg').remove();
  const svg = d3.select(chart.value).append('svg').attr('width', 100).attr('height', 100);

  arcData.forEach((arc: ArcData, index: number) => {
    svg
      .append('path')
      .attr('transform', 'translate(50,50)')
      .attr(
        'd',
        d3.arc()({
          innerRadius: innerRadius.value,
          outerRadius: outerRadius.value,
          startAngle: arc.startAngle,
          endAngle: arc.endAngle,
        }),
      )
      .attr('fill', arc.fillColor);

    const calculatePosition = (angle: number, radius: number) => {
      return {
        x: 50 + radius * Math.cos(angle),
        y: 50 + radius * Math.sin(angle),
      };
    };

    if (index < arcData.length - 1) {
      const endPos = calculatePosition(arc.endAngle, (outerRadius.value + innerRadius.value) / 2);

      const borderWidth = 1;
      svg
        .append('line')
        .attr('x1', endPos.x)
        .attr('y1', endPos.y)
        .attr('x2', endPos.x)
        .attr('y2', endPos.y)
        .attr('stroke', 'white')
        .attr('stroke-width', borderWidth);
    }
  });
  const textGroup = svg.append('g').attr('transform', 'translate(50,45)');

  textGroup
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('dy', '0em')
    .text(`${props.progress.completed_percentage?.toFixed(0)}%`)
    .style('font-size', '24px')
    .style('font-weight', '700')
    .style('fill', '#1F2E3C');

  textGroup
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'hanging')
    .attr('dy', '0.8em')
    .text('completed')
    .style('font-size', '12px')
    .style('font-style', 'normal')
    .style('fill', '#556C77');
}

watch(
  () => props.progress,
  () => {
    const arcData = convertCountsToRadians();

    renderChart(arcData);
  },
);

onMounted(() => {
  const arcData = convertCountsToRadians();

  renderChart(arcData);
});
</script>
<template>
  <div ref="chart" class="chart">
    <q-tooltip anchor="center end" self="center start" class="bg-white">
      <AProgressTooltip :progress="progress" />
    </q-tooltip>
  </div>
</template>
