<script setup lang="ts">
import { ref, computed } from 'vue';

import type { PropType } from 'vue';
import type { TestingTest } from '@/client/api';

import { statusIcon, Status } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';
import { successMessage, errorMessage } from '@/composables/Notify';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import { useProjectsStore } from '@/stores/ProjectsStore';

import Icon from '@/components/atoms/Icon.vue';
import ATooltip from '@/components/atoms/ATooltip.vue';
import ASelectSearch from '@/components/atoms/ASelectSearch.vue';

interface Select {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
  status: Status;
}

interface SelectComponent {
  clearFilter: () => void;
}

const props = defineProps({
  test: {
    type: Object as PropType<TestingTest>,
    required: true,
  },
});

const emit = defineEmits(['closeDialog']);

const projectsStore = useProjectsStore();
const selectedControl = ref<Select | null>(null);
const isLoading = ref(false);
const selectComponent = ref<SelectComponent | null>(null);

async function associateControl() {
  if (!selectedControl.value || !projectsStore.project || !selectComponent.value) return;

  try {
    isLoading.value = true;

    await projectsStore.associateTestToControl({
      testingTestId: props.test.id,
      controlId: selectedControl.value.value,
      projectId: projectsStore.project.id,
    });

    successMessage(
      `Control ${selectedControl.value.label} successfully associated with Test ${props.test.name}`,
    );

    selectedControl.value = null;
    selectComponent.value.clearFilter();

    await projectsStore.getTestingTestControls({
      projectId: projectsStore.project.id as string,
      testingTestId: props.test.id as string,
      ...DEFAULT_PAGINATION,
    });

    emit('closeDialog');
  } catch (error) {
    errorMessage('Failed to associate Control.');
    captureException(error, {
      message: 'Component: DTestCreateControl, Function: selectFromExisting',
    });
  } finally {
    isLoading.value = false;
  }
}

function selectControl(value: Select | null) {
  if (!value) {
    selectedControl.value = null;
    return;
  }
  selectedControl.value = value;
}

const filteredAssociatedControls = computed(() => {
  if (!projectsStore.testingTestControls || !projectsStore.controlsDataLight) return [];

  const testControls = projectsStore.testingTestControls;

  if (!testControls || testControls.length === 0) {
    return projectsStore.controlsDataLight.items;
  }

  return projectsStore.controlsDataLight.items
    .filter((item) => {
      return !testControls.some((controlTest) => controlTest.id === item.id);
    })
    .map((item) => {
      return item;
    });
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Associate to Control</h5>
    </div>
    <div class="row q-mb-sm">
      <div class="row items-center q-mb-md">
        <span class="select-title q-mr-xs">Control</span>
        <ATooltip
          icon-folder="dashboard"
          icon-name="QuestionCircle"
          tooltip-text="Only completed controls will be listed."
        />
      </div>

      <ASelectSearch
        ref="selectComponent"
        :options="
          filteredAssociatedControls.map((item) => ({
            value: item.id,
            label: item.control_code + ' - ' + item.name,
            status: item.status,
          }))
        "
        class="col-12"
        label="Select Control"
        :disable="filteredAssociatedControls && filteredAssociatedControls.length === 0"
        :loading="isLoading"
        @update-select="selectControl"
      />
      <div
        v-if="filteredAssociatedControls && filteredAssociatedControls.length === 0"
        class="empty-list row items-center justify-center"
      >
        Only completed controls can be associated. You do not have any completed controls at the
        moment.
      </div>
      <div class="chips__container q-mt-sm row col-12">
        <q-badge
          v-if="selectedControl"
          class="control-badge q-mr-sm q-mb-sm cursor-pointer"
          :class="[selectedControl.status]"
        >
          <Icon
            :icon-name="statusIcon(selectedControl.status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
          <span>{{ selectedControl.label }}</span>

          <q-tooltip anchor="top middle" self="center middle">
            {{ selectedControl.label }}
          </q-tooltip>
        </q-badge>
      </div>
    </div>
    <div class="row action__row full-width justify-end q-mt-sm">
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :loading="isLoading"
        :disable="!selectedControl || isLoading"
        @click="associateControl"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .select-title {
    @include caption(400, $secondary-500);
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }

  .q-btn-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.24);
    padding: 4px 12px;

    :deep(.q-focus-helper) {
      display: none;
    }
    :deep(.q-btn__content) {
      @include paragraph-01(400, $common-6);
      justify-content: space-between;
      text-transform: none;
    }

    :deep(i) {
      color: $common-3 !important;
    }
  }

  .q-btn-dropdown:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .empty-list {
    padding: 12px;
    border-radius: 4px;
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    color: $secondary-300;
  }

  .chips__container {
    .control-badge {
      flex-direction: row;
    }
  }
}
</style>
