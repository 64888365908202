<script setup lang="ts">
import { ref, watch } from 'vue';

import type { FrameworkDisplay } from '@/client/api';

import { Status, STATUS_LABEL } from '@/composables/utils';

import Header from '@/components/atoms/Content/Sidebar/AHeader.vue';
import Section from '@/components/atoms/Content/Sidebar/ASection.vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const projectsStore = useProjectsStore();
const activeStatus = ref('');
const activeFramework = ref('');

const items = ref([
  {
    id: 0,
    filter: {
      type: 'isAssignedToMe',
      text: 'Assigned Control',
      route: 'project-controls',
      pagination: 'assignedToMe',
    },
  },
  {
    id: 1,
    filter: {
      type: 'isAssignedToMeForReview',
      text: 'Assigned For Review',
      route: 'project-controls',
      pagination: 'assignedToMeForReview',
    },
  },
  {
    id: 2,
    title: 'Frameworks',
    data: [] as FrameworkDisplay[],
  },
  {
    id: 3,
    title: 'Readiness',
    items: [
      { id: 0, icon: 'check_circle', text: STATUS_LABEL.COMPLETED, value: Status.COMPLETED },
      { id: 1, icon: 'ready', text: STATUS_LABEL.IN_REVIEW, value: Status.IN_REVIEW },
      { id: 2, icon: 'in_progress', text: STATUS_LABEL.IN_PROGRESS, value: Status.IN_PROGRESS },
      { id: 3, icon: 'out_of_scope', text: STATUS_LABEL.OUT_OF_SCOPE, value: Status.OUT_OF_SCOPE },
    ],
  },
]);

watch(
  () => projectsStore.projectFrameworks,
  (value) => {
    items.value[2].data = value as FrameworkDisplay[];
  },
  { immediate: true },
);
</script>

<template>
  <q-drawer show-if-above :width="230" :breakpoint="500" bordered side="left">
    <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: '0' }">
      <Header icon-name="page_info" :text="title" />
      <Section
        v-for="item in items"
        :key="item.id"
        :title="item.title"
        :items="item.items ? item.items : []"
        :filter="item.filter"
        :data="item.data ? item.data : []"
        :active-status="activeStatus"
        :active-framework="activeFramework"
      />
    </q-scroll-area>
  </q-drawer>
</template>

<style scoped lang="scss">
:deep(aside) {
  position: absolute !important;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.section__container:last-child {
  border-bottom: none;
}
</style>
