<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue';

import { formatDate } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

import { useOrganizationStore } from '@/stores/OrganizationStore';

interface TagValues {
  tag_value: string;
  tag_description: string;
}

interface Taxonomy {
  tag_name: string;
  tag_values: TagValues[];
}

const organizationStore = useOrganizationStore();

const activeElement = ref('');
const tableData = ref<TagValues[] | null>(null);
const isLoading = ref(false);
const skeletonSize = ref(7);
const columnsNames = reactive({
  tag_value: 'Name',
  tag_description: 'Description',
});

function selectElement(item: Taxonomy) {
  activeElement.value = item.tag_name;
  tableData.value = item.tag_values;
}

onMounted(async () => {
  try {
    isLoading.value = true;
    await organizationStore.getOrganizationTaxonomy();

    if (!organizationStore.taxonomyData) return;

    tableData.value = organizationStore.taxonomyData.tags[0].tag_values;
    activeElement.value = organizationStore.taxonomyData.tags[0].tag_name;
  } catch (error) {
    captureException(error, {
      message: 'Component: Taxonomy, Hook: onMounted, Method: getOrganizationTaxonomy',
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div>
    <div class="row title__container q-mb-lg">
      <span>Set up your organization's required and optional taxonomies and tags here.</span>
    </div>
    <div class="row">
      <div v-if="organizationStore.taxonomyData && !isLoading" class="taxonomy__left">
        <ul class="column">
          <li
            v-for="item in organizationStore.taxonomyData.tags"
            :key="item.tag_name"
            class="q-mb-lg"
            :class="[activeElement === item.tag_name ? 'li-active' : '']"
            @click="selectElement(item)"
          >
            {{ item.tag_name }}
          </li>
        </ul>
      </div>
      <div v-if="isLoading" class="taxonomy__left">
        <ul class="column">
          <q-skeleton
            v-for="item in skeletonSize"
            :key="item"
            class="q-mb-lg bg-blue-grey-1"
            animation="wave"
            :width="`calc(100% - 50px - ${item * 10}px)`"
            height="20px"
          />
        </ul>
      </div>
      <q-space />
      <div class="taxonomy__right col-9">
        <OTable
          v-if="!isLoading && tableData"
          :data="tableData"
          :columns-names="columnsNames"
          :row-clickable="false"
          :search-filter="false"
          :show-pagination="false"
          :disable-sort="true"
          :is-loading="isLoading"
          :skeleton-size="skeletonSize"
          :skeleton-columns="2"
          store="organizationStore"
          action="getOrganizationUsers"
          class="col-9"
        >
          <template #custom-bottom>
            <div class="custom-bottom full-width row justify-end">
              <div class="bottom-info row items-center q-pa-xs">
                <Icon icon-name="table-info" icon-folder="colored" class="q-mr-sm" />
                <span class="info-text">
                  {{
                    `Approved by ${
                      organizationStore.taxonomyData?.user.firstname +
                      ' ' +
                      organizationStore.taxonomyData?.user.lastname
                    } at ${formatDate(organizationStore.taxonomyData!.created_at)}`
                  }}</span
                >
              </div>
            </div>
          </template>
        </OTable>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.title__container {
  @include paragraph-02(500, $secondary-600);
}
.taxonomy__left {
  width: 22.5%;
  height: fit-content;
  background: $white;
  border-radius: 5px;
  padding: 20px 0 20px 24px;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      color: $secondary-600;
      font-size: 16px;
      line-height: 22px;
      border-right: 3px solid transparent;
      padding-right: 50px;
    }
    .li-active {
      color: $secondary-700;
      font-weight: 700;
      border-right: 3px solid $secondary-600;
    }
    li:hover {
      cursor: pointer;
      color: $secondary-700;
      font-weight: 700;
      border-right: 3px solid $secondary-600;
    }
  }
}

.taxonomy__right {
  .custom-bottom {
    padding: 20px 0 0;
    .bottom-info {
      background: #e8f2ff;
      border-radius: 4px;
      .info-text {
        @include paragraph-01(400, #6d96c7);
        max-width: unset !important;
      }
    }
  }
}
</style>
