<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import ControlPanel from '@/components/organisms/Dashboard/Panels/ControlPanel.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';

import { Assignment, ControlStatusFilter } from '@/client/api';
import { SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';
import type { SecondaryPagination } from '@/interfaces/models/Pagination';

import { STATUS_LABEL } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';

import { useDashboardStore } from '@/stores/DashboardStore';

interface SelectValue {
  value: string | null;
  label: string;
}

interface ControlSelectValue {
  value: ControlStatusFilter;
  label: string;
}

const dashboardStore = useDashboardStore();
const filter = ref(null);
const activeTab = ref<Assignment>(Assignment.Assigned);
const status = ref<ControlSelectValue>({
  value: ControlStatusFilter.All,
  label: STATUS_LABEL.ALL,
});
const projectName = ref<SelectValue>({
  value: null,
  label: STATUS_LABEL.ALL,
});
const isControlsLoading = ref(false);
const isDataLoading = ref(false);
const statusOptions = [
  {
    value: ControlStatusFilter.All,
    label: STATUS_LABEL.ALL,
  },
  {
    value: ControlStatusFilter.InReview,
    label: STATUS_LABEL.IN_REVIEW,
  },
  {
    value: ControlStatusFilter.InProgress,
    label: STATUS_LABEL.IN_PROGRESS,
  },
  {
    value: ControlStatusFilter.Completed,
    label: STATUS_LABEL.COMPLETED,
  },
];
const secondaryPagination: SecondaryPagination = reactive({
  search: null,
  id: null,
  status: undefined,
  assignedAs: undefined,
});

const columnsNames = {
  name: 'Name',
  project: 'Project',
  frameworks: 'Frameworks',
  status: 'Status',
  modified_at: 'Modified',
};

async function changeTab(value: Assignment) {
  isControlsLoading.value = true;
  isDataLoading.value = true;

  const params = {
    controlSearch: filter.value || null,
    projectIdOfControls: projectName.value?.value,
    controlStatus: status.value?.value,
    assignedAs: value,
    size: SMALLER_PAGINATION_SIZE,
  };

  try {
    await dashboardStore.getControls(params);
    activeTab.value = value;
    secondaryPagination.assignedAs = value;
  } catch (error) {
    captureException(error, {
      message: 'Component: OControls, function: changeTab',
      data: {
        params: params,
      },
    });
  } finally {
    isControlsLoading.value = false;
    isDataLoading.value = false;
  }
}

async function inputFilter() {
  const params = {
    controlSearch: filter.value || null,
    projectIdOfControls: projectName.value?.value,
    controlStatus: status.value?.value,
    assignedAs: activeTab.value,
  };
  try {
    isDataLoading.value = true;

    await dashboardStore.getControls(params);
    secondaryPagination.search = filter.value;
  } catch (error) {
    captureException(error, {
      message: 'Component: OControls, function: inputFilter',
      data: {
        params: params,
      },
    });
  } finally {
    isDataLoading.value = false;
  }
}

async function selectFilter() {
  const selectedProject = projectName.value;

  if (!selectedProject) return;

  const params = {
    controlSearch: filter.value || null,
    projectIdOfControls: selectedProject.value,
    controlStatus: status.value?.value,
    assignedAs: activeTab.value,
  };
  try {
    isDataLoading.value = true;

    await dashboardStore.getControls(params);
    secondaryPagination.id = selectedProject ? selectedProject.value : null;
  } catch (error) {
    captureException(error, {
      message: 'Component: OControls, function: selectFilter',
      data: {
        params: params,
      },
    });
  } finally {
    isDataLoading.value = false;
  }
}

const projectsWithAssignedControls = computed(() => {
  if (!dashboardStore.controls) return;

  const assignedControls = dashboardStore.controls?.projects_with_assigned_controls?.map(
    (project) => ({
      label: project.name,
      value: project.id,
    }),
  );

  assignedControls.unshift({ label: STATUS_LABEL.ALL, value: null });

  return assignedControls;
});

onMounted(async () => {
  try {
    isControlsLoading.value = true;
    isDataLoading.value = true;
    await dashboardStore.getControls({
      size: SMALLER_PAGINATION_SIZE,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: OControls, Hook: onMounted, Method: getControls',
    });
  } finally {
    isControlsLoading.value = false;
    isDataLoading.value = false;
  }
});
</script>

<template>
  <div class="controls__wrapper">
    <div
      v-if="
        (dashboardStore.controls && dashboardStore.controls.nr_controls_assigned! > 0) ||
        (dashboardStore.controls && dashboardStore.controls.nr_controls_assigned_for_review! > 0) ||
        isControlsLoading
      "
      class="row search-filter__container q-mb-md items-end"
    >
      <div class="row q-mr-md col-3">
        <span class="col-12 q-mb-sm">Project</span>
        <q-select
          v-model="projectName"
          outlined
          :options="projectsWithAssignedControls"
          dense
          class="col-12"
          :class="[isControlsLoading ? 'skeleton' : '']"
          @update:model-value="selectFilter"
        />
      </div>
      <div class="row q-mr-md col-3">
        <span class="col-12 q-mb-sm">Status</span>
        <q-select
          v-model="status"
          outlined
          :options="statusOptions.map((item) => ({ value: item.value, label: item.label }))"
          dense
          class="col-12"
          :class="[isControlsLoading ? 'skeleton' : '']"
          @update:model-value="selectFilter"
        />
      </div>
      <div class="row col-3">
        <q-input
          v-model="filter"
          class="col-12"
          borderless
          dense
          debounce="300"
          placeholder="Search for a control"
          :class="[isControlsLoading ? 'skeleton' : '']"
          @update:model-value="inputFilter"
        >
          <template #append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <OTabPanels
      v-if="
        (dashboardStore.controls && dashboardStore.controls.nr_controls_assigned! > 0) ||
        (dashboardStore.controls && dashboardStore.controls.nr_controls_assigned_for_review! > 0) ||
        isControlsLoading ||
        isDataLoading
      "
      :active-tab="activeTab"
      :tab-size="{ md: 12, lg: 12 }"
      default-tab="assigned"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab
          :content-class="`${isControlsLoading ? 'skeleton' : ''} my-tab`"
          name="assigned"
          label="Assigned Controls"
          class="q-mr-md"
          :class="activeTab === Assignment.Assigned ? activeTab : ''"
          :style="{ borderLeft: activeTab !== Assignment.Assigned ? '5px solid #556c77' : '' }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.controls?.nr_controls_assigned }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.Assigned"
              icon-name="dashboard-assigned-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-assigned" icon-folder="dashboard" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isControlsLoading ? 'skeleton' : ''} my-tab`"
          name="assigned_for_review"
          label="Controls to Review"
          class="q-mr-md"
          :class="activeTab === Assignment.AssignedForReview ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== Assignment.AssignedForReview ? '5px solid #fd956b' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.controls?.nr_controls_assigned_for_review }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.AssignedForReview"
              icon-name="dashboard-review-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-review" icon-folder="dashboard" />
          </div>
        </q-tab>
        <q-tab
          :content-class="`${isControlsLoading ? 'skeleton' : ''} my-tab`"
          name="review_rejected"
          label="Rejected by Reviewer"
          :class="activeTab === Assignment.ReviewRejected ? activeTab : ''"
          :style="{
            borderLeft: activeTab !== Assignment.ReviewRejected ? '5px solid #f96f7f' : '',
          }"
        >
          <div class="row total__container full-width items-center">
            <span>{{ dashboardStore.controls?.nr_controls_rejected_by_reviewer }}</span>
          </div>
          <div class="tab-icon__container">
            <Icon
              v-if="activeTab === Assignment.ReviewRejected"
              icon-name="dashboard-rejected-active"
              icon-folder="dashboard"
            />
            <Icon v-else icon-name="dashboard-rejected" icon-folder="dashboard" />
          </div>
        </q-tab>
      </template>
      <template #panels>
        <q-tab-panel name="assigned">
          <ControlPanel
            title="Last assigned controls"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isControlsLoading"
            empty-title="You don’t have any assigned controls yet."
            empty-sub-title="As soon as you have assigned controls you can see them here."
          />
        </q-tab-panel>
        <q-tab-panel name="assigned_for_review">
          <ControlPanel
            title="Last assigned controls to review"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isControlsLoading"
            empty-title="You don’t have any assigned controls to review yet."
            empty-sub-title="As soon as you have assigned controls to review you can see them here."
          />
        </q-tab-panel>

        <q-tab-panel name="review_rejected">
          <ControlPanel
            title="Last rejected controls to review"
            :filter="filter"
            :project-name="projectName"
            :columns-names="columnsNames"
            :secondary-pagination="secondaryPagination"
            :is-data-loading="isDataLoading"
            :is-controls-loading="isControlsLoading"
            empty-title="You don’t have any rejected by review controls yet."
            empty-sub-title="As soon as you have rejected by review controls you can see them here."
          />
        </q-tab-panel>
      </template>
    </OTabPanels>
    <div
      v-if="
        (!dashboardStore.controls && !isControlsLoading) ||
        (dashboardStore.controls?.items.length === 0 &&
          dashboardStore.controls?.nr_controls_assigned === 0 &&
          dashboardStore.controls?.nr_controls_assigned_for_review === 0 &&
          !isControlsLoading &&
          !isDataLoading)
      "
      class="row empty__container"
      :class="[!dashboardStore.controls ? 'full-height' : '']"
    >
      <AEmptyData
        v-if="!filter"
        icon-name="drawer"
        :header="`You don’t have any assigned controls yet.`"
        text="As soon as you have assigned controls you can see them here."
        class="full-width items-center q-mt-lg"
      />
      <AEmptyData
        v-else
        icon-name="search"
        :header="`No results for your search.`"
        text="Try changing your search."
        class="full-width items-center"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.controls__wrapper {
  width: 48%;
  min-height: 320px;
  padding: 26px;
  position: relative;
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.05);

  .search-filter__container {
    span {
      font-size: 14px;
      color: $common-2;
    }
  }
  :deep(.q-tab) {
    padding: 0;
    .my-tab {
      height: 80px;
      padding: 4px 10px 15px;
      justify-content: flex-start;
      border: 1px solid #dde1e6;
      .q-tab__label {
        font-size: 16px;
        font-weight: 400;
      }
      .q-tab__indicator {
        height: 4px;
      }
      .total__container {
        span {
          @include heading-03(600, $secondary-600);
        }
      }

      .tab-icon__container {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }
  :deep(.q-tab--active) {
    .my-tab {
      border: none;
      .q-tab__label {
        color: $white;
      }
      .total__container {
        span {
          color: $white;
        }
      }
    }
  }

  .assigned {
    background: #556c77;
    :deep(.q-tab__indicator) {
      color: #556c77 !important;
    }
  }
  .assigned_for_review {
    background: #fd956b;
    :deep(.q-tab__indicator) {
      color: #fd956b !important;
    }
  }

  .review_rejected {
    background: #f96f7f;
    :deep(.q-tab__indicator) {
      color: #f96f7f !important;
    }
  }

  :deep(.q-select) {
    .q-field__inner {
      padding: 0 !important;
      border: none !important;
    }
  }

  :deep(.q-panel) {
    overflow-y: hidden;
  }
  :deep(.q-tab-panel) {
    padding: 0;

    .controls-info {
      span {
        color: $secondary-600;
        font-size: 12px;
      }
    }
    .q-table__container {
      padding: 0;
    }
  }
  .empty__container {
    height: calc(100% - 36px) !important;
  }
  :deep(tbody td) {
    width: 200px;
  }
}
</style>
