<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore';
import { useRisksStore } from '@/stores/RisksStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import type { UserDisplay, RiskComponent } from '@/client/api';
import { Roles, Concept } from '@/client/api';

import { successMessage } from '@/composables/Notify';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { RISK_DETAIL_NAVIGATION, RISK_COMPONENTS } from '@/composables/CRisk';
import { Status } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';
import { isProjectOwner } from '@/composables/CProject';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';

import RiskTreatment from '@/components/Risks/RiskTreatment.vue';
import RiskAssessment from '@/components/Risks/RiskAssessment.vue';
import RiskComments from '@/components/Risks/RiskComments.vue';
import DRequestReview from '@/components/dialogs/DRequestReview.vue';
import DRequestReassign from '@/components/dialogs/DRequestReassign.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import SDetailPage from '@/components/skeletons/SDetailPage.vue';
import InfoRight from '@/components/Risks/InfoRight.vue';

interface Result {
  text: string;
  treatmentDecision: boolean;
}

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const risksStore = useRisksStore();
const projectStore = useProjectsStore();
const activeTab = ref('risk');
const showRequestModal = ref(false);
const showReassignModal = ref(false);
const showConfirmDialog = ref(false);
const showReopenDialog = ref(false);
const riskIsLoading = ref(false);
const reviewers = ref<UserDisplay[]>([]);
const isEditor = ref(false);
const isAuditor = ref(false);
const activeNavigation = ref('Assessment');
const confirmationIsLoading = ref(false);
const navigationItems = ref([
  {
    icon: 'assessment',
    title: RISK_DETAIL_NAVIGATION.ASSESSMENT,
    disabled: false,
  },
  {
    icon: 'treatment',
    title: RISK_DETAIL_NAVIGATION.TREATMENT,
    disabled: true,
    badge: true,
    inBeta: true,
    tooltip: {
      text: 'This section is disabled until the assessment is completed and Evaluation set to "Begin risk treatment".',
    },
  },
]);

function changeTab(value: string) {
  activeTab.value = value;
}

function closeConfirm() {
  showConfirmDialog.value = false;
}

function closeReopen() {
  showReopenDialog.value = false;
}

function cancelReview() {
  showConfirmDialog.value = true;
}

async function confirm() {
  if (!projectStore.project || !risksStore.currentRisk) return;

  try {
    const params = {
      objectId: risksStore.currentRisk.id,
      projectId: projectStore.project.id,
      concept: Concept.Risk,
    };

    confirmationIsLoading.value = true;
    await projectStore.conceptCancelReview(params);

    successMessage('Review(s) request for status change cancelled successfully');

    await risksStore.getRisk({ riskId: risksStore.currentRisk.id });
    closeConfirm();
  } catch (e) {
    captureException(e, { message: 'Component: RiskDetailPage, Function: confirm' });
  } finally {
    confirmationIsLoading.value = false;
  }
}

async function confirmReopen() {
  if (!projectStore.project || !risksStore.currentRisk || !authStore.user) return;

  const data = {
    requested_status: Status.IN_PROGRESS,
    comment: '',
    reviewer_ids: [authStore.user.id],
  };

  try {
    confirmationIsLoading.value = true;

    const params = {
      projectId: projectStore.project.id,
      objectId: risksStore.currentRisk.id,
      concept: Concept.Risk,
      reviewRequest: data,
      forceUpdateStatus: true,
    };

    await projectStore.conceptRequestReview(params);
    await risksStore.getRisk({ riskId: risksStore.currentRisk.id });
    closeReopen();
  } catch (e) {
    captureException(e, { message: 'Component: RiskDetailPage, Function: confirmReopen' });
  } finally {
    confirmationIsLoading.value = false;
  }
}

async function updateConcept() {
  if (!risksStore.currentRisk) return;

  try {
    await risksStore.getRisk({ riskId: risksStore.currentRisk.id });

    activeTab.value = 'risk';
  } catch (error) {
    captureException(error, { message: 'Component: RiskDetailPage, Function: updateConcept' });
  }
}

function showRequestDialog() {
  showRequestModal.value = true;
}

function hideRequestDialog() {
  showRequestModal.value = false;
  showReassignModal.value = false;
}

function closeDialog() {
  showRequestModal.value = false;
  showReassignModal.value = false;
}

function reassignReviewer() {
  showReassignModal.value = true;
}

async function updateCommentsAndLogs() {
  if (!projectStore.project || !risksStore.currentRisk) return;

  try {
    const params = {
      objectId: risksStore.currentRisk.id,
      projectId: projectStore.project.id,
      objectType: Concept.Risk,
    };

    await projectStore.getComments(params);

    const logParams = {
      objectId: risksStore.currentRisk.id,
      projectId: projectStore.project.id,
    };

    await projectStore.getProjectLogs(logParams);
  } catch (error) {
    captureException(error, {
      message: 'Component: RiskDetailPage, Function: updateCommentsAndLogs',
    });
  }
}

const reviewMessage = computed(() => {
  if (!risksStore.currentRisk || !risksStore.currentRisk.pending_review) return '<span>-</span>';

  return `<span><b>${risksStore.currentRisk.pending_review.created_by.firstname}</b> <b>${risksStore.currentRisk.pending_review.created_by.lastname}</b>
  requested to change status from <b>${risksStore.currentRisk.pending_review.old_status}</b> to <b>${risksStore.currentRisk.pending_review.requested_status}</b>.</span>`;
});

const isAllowed = computed(() => {
  return isAdmin() || isProjectOwner() || isEditor.value;
});

const isTreatmentEnabled = computed(() => {
  if (!risksStore.currentRisk) return false;

  const evaluationComponent =
    risksStore.currentRisk.components.find(
      (component: RiskComponent) => component.name === RISK_COMPONENTS.EVALUATION,
    ) || null;

  if (!evaluationComponent || !evaluationComponent.result) return false;

  return (
    risksStore.currentRisk?.status === Status.COMPLETED &&
    evaluationComponent &&
    (evaluationComponent.result as Result).treatmentDecision
  );
});

watch(
  () => risksStore.currentRisk,
  async (newV) => {
    if (!risksStore.currentRisk || !newV) return;

    try {
      riskIsLoading.value = true;

      reviewers.value = risksStore.currentRisk?.reviewers || [];

      navigationItems.value[1].disabled = !isTreatmentEnabled.value;

      if (isTreatmentEnabled.value) {
        await risksStore.getRiskTreatment({ riskId: risksStore.currentRisk.id });
        activeNavigation.value = RISK_DETAIL_NAVIGATION.TREATMENT;
      } else {
        activeNavigation.value = RISK_DETAIL_NAVIGATION.ASSESSMENT;
      }
    } catch (error) {
      captureException(error, {
        message: 'Component: RiskDetailPage, Watch: risksStore.currentRisk',
      });
    } finally {
      riskIsLoading.value = false;
    }
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  if (!route.params.riskId || typeof route.params.riskId !== 'string') {
    router.push({ name: 'project-risks' });
    return;
  }

  try {
    riskIsLoading.value = true;
    await risksStore.getRisk({ riskId: route.params.riskId });

    reviewers.value = risksStore.currentRisk?.reviewers || [];
  } catch (error) {
    captureException(error, { message: 'Component: RiskDetailPage, Hook: onMounted' });
  } finally {
    riskIsLoading.value = false;
  }

  if (!projectStore.project || !risksStore.currentRisk) return;

  try {
    const userRoles = await userProjectRoles(projectStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
    isAuditor.value = userRoles?.includes(Roles.Auditor) || false;

    await projectStore.getProjectsEvidenceList({ projectId: projectStore.project.id });

    await updateCommentsAndLogs();
  } catch (error) {
    captureException(error, { message: 'Component: RiskDetailPage, Hook: onMounted' });
  }
});

onUnmounted(() => {
  risksStore.resetRisk();
  projectStore.resetCommentsAndLogs();
});
</script>
<template>
  <PageTitle
    v-if="risksStore.currentRisk"
    :title="risksStore.currentRisk.name"
    :editable="!risksStore.currentRisk.is_locked && isAllowed"
  />
  <OTabPanels
    v-if="risksStore.currentRisk && !riskIsLoading"
    :active-tab="activeTab"
    default-tab="risk"
    :default-size="true"
    @change-tab="changeTab"
  >
    <template #tabs>
      <q-tab content-class="my-tab" name="risk" label="Risk"> </q-tab>
      <q-tab content-class="my-tab" name="comments_logs" label="Comments and Logs">
        <span
          v-if="projectStore.projectLogs.length > 0 || projectStore.comments.length > 0"
          class="total-badge q-ml-sm"
        >
          {{
            projectStore.projectLogs.length + projectStore.comments.length < 10
              ? '0' + (projectStore.projectLogs.length + projectStore.comments.length)
              : projectStore.projectLogs.length + projectStore.comments.length
          }}</span
        >
      </q-tab>
    </template>
    <template #panels>
      <q-tab-panel name="risk">
        <div
          v-if="
            (risksStore.currentRisk.status === Status.IN_REVIEW &&
              activeTab === 'control' &&
              isAdmin()) ||
            (risksStore.currentRisk.status === Status.IN_REVIEW &&
              activeTab === 'control' &&
              isProjectOwner()) ||
            (risksStore.currentRisk.status === Status.IN_REVIEW &&
              activeTab === 'control' &&
              isEditor)
          "
          class="row col-12 notification__wrapp q-mb-md items-center justify-between"
        >
          <div class="notification__left row items-center">
            <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
            <span class="notification__message"
              >This control is under review by
              <b>{{
                reviewers.map((user: UserDisplay) => user.firstname + ' ' + user.lastname).join(',')
              }}</b
              >.</span
            >
          </div>
          <div class="notification__right">
            <q-btn label="Cancel Request" unelevated @click="cancelReview">
              <template #default>
                <Icon icon-name="cancel-review" icon-folder="white" class="q-mr-sm" />
              </template>
            </q-btn>
          </div>
        </div>

        <div
          v-if="
            risksStore.currentRisk &&
            authStore.user &&
            risksStore.currentRisk.status === 'in_review' &&
            reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id)
          "
          class="row col-12 notification__wrapp q-mb-md items-center justify-between"
        >
          <div class="notification__left row items-center">
            <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
            <div class="column">
              <span class="notification__message assign-text"
                >Status Change Request assigned to you.</span
              >
              <span class="notification__message" v-html="reviewMessage" />
            </div>
          </div>
          <div class="notification__right">
            <q-btn label="Review Request" unelevated @click="changeTab('comments_logs')" />
          </div>
        </div>

        <div
          v-if="
            risksStore.currentRisk &&
            authStore.user &&
            risksStore.currentRisk.status !== 'in_review'
          "
          class="action-row__container row col-12 justify-between"
        >
          <div class="navigation__container col-6 row items-center">
            <div
              v-for="item in navigationItems"
              :key="item.icon"
              class="row item-wrapp items-center q-mx-lg"
              :class="[
                activeNavigation === item.title ? 'active' : '',
                item.disabled ? 'disabled' : '',
              ]"
              @click="item.disabled ? null : (activeNavigation = item.title)"
            >
              <div class="icon__container row items-center justify-center">
                <Icon
                  v-if="activeNavigation === item.title"
                  :icon-name="item.icon + '-white'"
                  icon-folder="risks"
                />
                <Icon v-else :icon-name="item.icon" icon-folder="risks" />
              </div>
              <div class="navigation-title q-ma-xs">{{ item.title }}</div>
              <q-badge v-if="item.inBeta" class="beta-badge">beta</q-badge>
              <q-badge
                v-if="item.badge && isTreatmentEnabled"
                class="recommended-badge justify-center q-ml-sm"
                label="recommended"
              />

              <q-tooltip
                v-if="item.tooltip && item.disabled"
                anchor="top middle"
                self="bottom middle"
              >
                {{ item.tooltip.text }}
              </q-tooltip>
            </div>
          </div>
          <div class="action__container row col-6 justify-end">
            <q-btn class="btn-export q-mr-md" label="Export" unelevated disable>
              <Icon icon-name="icon-export" icon-folder="colored" />
            </q-btn>

            <q-btn
              v-if="
                activeNavigation === RISK_DETAIL_NAVIGATION.ASSESSMENT &&
                risksStore.currentRisk.status === Status.IN_PROGRESS &&
                isAllowed
              "
              class="q-ml-md request-button"
              unelevated
              :disable="!isAllowed"
              label="Request status change"
              @click="showRequestDialog"
            />
            <q-btn
              v-if="
                activeNavigation === RISK_DETAIL_NAVIGATION.ASSESSMENT &&
                risksStore.currentRisk.status === Status.COMPLETED &&
                isAllowed
              "
              class="q-ml-md reopen-button"
              unelevated
              :disable="!isAllowed"
              label="Reopen the Assessment"
              @click="showReopenDialog = true"
            />
            <q-btn
              v-if="activeNavigation === RISK_DETAIL_NAVIGATION.TREATMENT"
              class="q-ml-md request-button"
              unelevated
              disable
              label="Request Review"
            />
          </div>
        </div>

        <div class="risk-wrapp row justify-between">
          <RiskAssessment
            v-if="activeNavigation === RISK_DETAIL_NAVIGATION.ASSESSMENT"
            :risk="risksStore.currentRisk"
            :reviewers="reviewers"
            :risk-is-loading="riskIsLoading"
            :is-allowed="isAllowed"
            class="risk-left"
          />
          <RiskTreatment
            v-if="activeNavigation === RISK_DETAIL_NAVIGATION.TREATMENT"
            :risk="risksStore.currentRisk"
            :is-allowed="isAllowed"
            class="risk-left treatment-left"
          />
          <InfoRight
            v-if="activeNavigation === RISK_DETAIL_NAVIGATION.ASSESSMENT"
            :risk="risksStore.currentRisk"
            :reviewers="reviewers"
            :is-allowed="isAllowed"
            @update-comments-and-logs="updateCommentsAndLogs"
          />
        </div>
      </q-tab-panel>

      <q-tab-panel name="comments_logs">
        <div class="risk-wrapp row justify-between">
          <RiskComments
            ref="commentsAndLogs"
            :risk="risksStore.currentRisk"
            :reviewers="reviewers"
            class="risk-left"
            @update-concept="updateConcept"
            @reassign-reviewer="reassignReviewer"
          />
          <InfoRight
            :risk="risksStore.currentRisk"
            :reviewers="reviewers"
            :is-auditor="isAuditor"
            :is-editor="isEditor"
          />
        </div>
      </q-tab-panel>
    </template>
  </OTabPanels>

  <SDetailPage v-if="riskIsLoading" />
  <ADialog
    v-if="risksStore.currentRisk"
    :show-dialog="showRequestModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="hideRequestDialog"
  >
    <DRequestReview
      :concept="risksStore.currentRisk"
      :concept-type="'risk' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    v-if="risksStore.currentRisk"
    :show-dialog="showReassignModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="hideRequestDialog"
  >
    <DRequestReassign
      :concept="risksStore.currentRisk"
      :concept-type="'risk' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DConfirm
      title="Cancel Request"
      description="Would you like to cancel the Status Change Request?"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirm"
    />
  </ADialog>

  <ADialog
    :show-dialog="showReopenDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showReopenDialog = false"
  >
    <DConfirm
      title="Reopen Risk Assessment"
      description="Are you sure you want to Reopen your Risk Assessment?"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeReopen"
      @confirm="confirmReopen"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

span {
  @include heading-04(600, $secondary-600);
}

.title__container {
  max-width: 50%;
}

:deep(.q-tabs) {
  margin: 0;
  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .q-tab {
    padding: 0;
    .q-tab__content {
      padding: 4px 10px 15px;
      .q-tab__indicator {
        height: 4px;
      }
      .full-width {
        span {
          @include heading-03(600, $secondary-600);
        }
      }
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  .q-tabs__arrow--left,
  .q-tabs__arrow--right {
    display: none !important;
  }
}

.notification__wrapp {
  padding: 10px 10px 10px 18px;
  border-radius: 4px;
  border: 1px solid #fd956b;
  background: #fff8e6;
  .notification__left {
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .assign-text {
      font-size: 16px;
    }
  }
  .notification__right {
    button {
      background: $secondary-500 !important;
      color: $white;
      text-transform: none;
      :deep(.q-btn__content) {
        flex-direction: row-reverse;
      }
    }
  }
}
.action-row__container {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 24px 20px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .navigation__container {
    .item-wrapp {
      cursor: pointer;
      .icon__container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $white;
        margin-right: 10px;
        border: 1px solid $primary-500;
      }
      .navigation-title {
        font-size: 16px;
        font-weight: 600;
        color: $primary-500;
      }
    }
    .disabled {
      opacity: 1 !important;
      .icon__container {
        opacity: 0.6;
      }
      .navigation-title {
        font-weight: 400;
        opacity: 0.6;
      }
    }
    .active {
      .icon__container {
        background: $primary-500;
        border: unset;
      }
    }
  }

  .request-button {
    min-height: 40px;
    background: $secondary-500 !important;
    color: $white;
    text-transform: none;
  }
  .reopen-button {
    min-height: 40px;
    background: transparent !important;
    color: $secondary-500;
    border: 1px solid $secondary-500;
    border-radius: 5px;
    text-transform: none;
  }
}

:deep(.q-tab-panels) {
  overflow: visible;
  .q-panel {
    overflow: visible;
  }
}

.q-tab-panel {
  padding: 0;
}

.content-wrapper {
  padding: 16px 0 !important;
}

.risk-left {
  width: 73%;
  .coomments__wrapp {
    padding: 0 !important;
  }
}
.treatment-left {
  width: 100%;
}
</style>
