import { useOrganizationStore } from '@/stores/OrganizationStore';

export function OrganizationCurrency(currency: string): string {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '€';
  }
}

export function AiProjectCreationAllowed(): boolean {
  const organizationStore = useOrganizationStore();

  if (!organizationStore.organizationQuota) return false;

  const plan_qouta_number = organizationStore.organizationQuota.plan_quota.projects;
  const used_ai_qouta_number =
    organizationStore.organizationQuota.used_quota.projects_ai_application;

  return used_ai_qouta_number < plan_qouta_number;
}

export function OrgProjectCreationAllowed(): boolean {
  const organizationStore = useOrganizationStore();

  if (!organizationStore.organizationQuota) return false;

  const plan_qouta_number = organizationStore.organizationQuota.plan_quota.projects;
  const used_org_qouta_number =
    organizationStore.organizationQuota.used_quota.projects_organization;

  return used_org_qouta_number < plan_qouta_number;
}
