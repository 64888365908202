<script setup lang="ts">
import type {
  AssetCardPage,
  AssignedRisks,
  CustomNotificationPage,
  CustomProjectPage,
  EvidencePage,
  PageControlList,
  PageDocumentOverview,
  PageRequirementAggregator,
  PageTestingTest,
  PageVendorDetails,
  RiskWithOverviewPage,
  TestingTestAndResultPage,
  TestingTestResultPage,
  UserOrgPage,
  UserProjectPage,
} from '@/client';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';
import { useNotificationStore } from '@/stores/NotificationStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { captureException } from '@/composables/Sentry';

export type PaginationData =
  | AssetCardPage
  | PageControlList
  | PageRequirementAggregator
  | CustomNotificationPage
  | CustomProjectPage
  | EvidencePage
  | RiskWithOverviewPage
  | UserOrgPage
  | AssignedRisks
  | UserProjectPage
  | PageTestingTest
  | PageVendorDetails
  | PageDocumentOverview
  | TestingTestResultPage
  | TestingTestAndResultPage;

const props = defineProps({
  pagination: {
    type: Object as () => PaginationData,
    default: () => null,
  },
  store: {
    type: String,
    default: '',
  },
  action: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['updateSpecific']);

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const notificationStore = useNotificationStore();

function nextPage() {
  emit('updateSpecific', false);
  // TODO: Fix this
  const page = props.pagination.page ?? 0;
  paginationStore.setPage(page + 1);
  newPagination(page + 1);
}

function prevPage() {
  emit('updateSpecific', false);
  // TODO: Fix this
  const page = props.pagination.page ?? 2;
  paginationStore.setPage(page - 1);
  newPagination(page - 1);
}

function toPage(page: number) {
  emit('updateSpecific', false);
  paginationStore.setPage(page);
  newPagination(page);
}

async function newPagination(page: number) {
  if (page === props.pagination.page) {
    return;
  }

  const pagination = {
    ...DEFAULT_PAGINATION,
    page,
    size: props.pagination.size || 10,
    tags: paginationStore.tagFilter ? [paginationStore.tagFilter] : undefined,
    name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
    status: paginationStore.statusFilter,
    framework: paginationStore.frameworkQuery,
    isAssignedToMe: paginationStore.assignedToMe,
    isAssignedToMeForReview: paginationStore.assignedToMeForReview,
  };

  const paginationWithoutStatus = {
    ...pagination,
    status: null,
  };

  try {
    switch (props.action) {
      case 'getProjectRequirements':
        if (!projectsStore.project) return;

        return await projectsStore.getProjectRequirements({
          projectId: projectsStore.project.id,
          ...pagination,
        });
      case 'getProjectControls':
        if (!projectsStore.project) return;

        return await projectsStore.getProjectControls({
          projectId: projectsStore.project?.id,
          ...pagination,
        });
      case 'getNotifications':
        return await notificationStore
          .getNotifications({
            notificationStatus: pagination.status,
            ...paginationWithoutStatus,
          })
          .catch((error) => {
            captureException(error, {
              message: 'Component: MPagination, Function: newPagination',
              data: {
                pagination: paginationWithoutStatus,
              },
            });
          });
    }
  } catch (error) {
    captureException(error, {
      message: 'Component: MPagination, Function: newPagination',
      data: {
        pagination: pagination,
      },
    });
  }
}
</script>

<template>
  <div class="pagination__container row">
    <q-btn
      icon="chevron_left"
      color="grey-8"
      square
      dense
      flat
      class="chevron-button"
      :disable="pagination.page === 1"
      @click="prevPage"
    />
    <div v-if="pagination.pages && pagination.page && pagination.pages > 5 && pagination.page > 3">
      <q-btn
        dense
        flat
        color="primary"
        :class="pagination.page === 1 ? 'button-page-number--active' : ''"
        @click="toPage(1)"
        >1</q-btn
      >
      <q-btn dense flat>...</q-btn>
    </div>

    <div v-if="pagination.pages && pagination.pages <= 5" class="row">
      <div v-for="page in pagination.pages" :key="page">
        <q-btn
          dense
          flat
          class="button-page-number"
          :class="page === pagination.page ? 'button-page-number--active' : ''"
          @click="toPage(page)"
        >
          {{ page }}
        </q-btn>
      </div>
    </div>
    <div v-else>
      <div v-if="pagination.page && pagination.page <= 3" class="row">
        <div v-for="page in 5" :key="page">
          <q-btn
            dense
            flat
            class="button-page-number"
            :class="page === pagination.page ? 'button-page-number--active' : ''"
            @click="toPage(page)"
          >
            {{ page }}
          </q-btn>
        </div>
      </div>
      <div
        v-else-if="pagination.page && pagination.pages && pagination.page >= pagination.pages - 2"
        class="row"
      >
        <div v-for="page in 5" :key="page">
          <q-btn
            dense
            flat
            class="button-page-number"
            :class="
              page === Math.abs(pagination.pages - pagination.page - 5)
                ? 'button-page-number--active'
                : ''
            "
            @click="pagination.pages ? toPage(pagination.pages - 5 + page) : null"
          >
            {{ pagination.pages - 5 + page }}
          </q-btn>
        </div>
      </div>
      <div v-else-if="pagination.page" class="row">
        <div v-for="page in 5" :key="page">
          <q-btn
            dense
            flat
            class="button-page-number"
            :class="page === 3 ? 'button-page-number--active' : ''"
            @click="pagination.page ? toPage(pagination.page - 3 + page) : null"
          >
            {{ pagination.page - 3 + page }}
          </q-btn>
        </div>
      </div>
    </div>

    <div
      v-if="
        pagination.pages &&
        pagination.page &&
        pagination.pages > 5 &&
        pagination.page < pagination.pages - 2
      "
    >
      <q-btn dense flat>...</q-btn>
      <q-btn
        dense
        flat
        color="primary"
        :class="pagination.pages === pagination.page ? 'button-page-number--active' : ''"
        @click="pagination.pages ? toPage(pagination.pages) : null"
        >{{ pagination.pages }}</q-btn
      >
    </div>

    <q-btn
      icon="chevron_right"
      color="grey-8"
      dense
      flat
      class="chevron-button"
      :disable="pagination.page === pagination.pages"
      @click="nextPage"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.pagination__container {
  button {
    @include paragraph-01(400, $common-4);
    height: 35px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 4px 12px;
    margin: 0 4px;
    color: $common-4 !important;
    :deep(i) {
      color: $common-4;
    }
  }
  .chevron-button {
    border: 1px solid $common-1;
    padding: 0 4px;
  }
  .button-page-number--active {
    color: $white !important;
    background: $secondary-500;
  }
  .disabled {
    i {
      color: $common-1;
    }
  }
}
</style>
