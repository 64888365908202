<script setup lang="ts"></script>

<template>
  <div class="item__container q-py-md row items-start">
    <div class="icon__container q-pt-sm">
      <q-skeleton
        width="20px"
        height="20px"
        animation="wave"
        type="circle"
        class="bg-blue-grey-1"
      />
    </div>
    <div class="item__info column col-10 q-ml-lg q-pt-sm">
      <div class="row q-mb-sm">
        <q-skeleton
          animation="wave"
          type="text"
          width="150px"
          height="30px"
          class="bg-blue-grey-1"
        />

        <div class="item__codes q-ml-md row">
          <q-skeleton
            v-for="badge in 1"
            :key="badge"
            width="50px"
            height="30px"
            animation="wave"
            class="q-mr-sm bg-blue-grey-1"
          />
        </div>
      </div>
      <q-skeleton animation="wave" type="text" width="350px" height="20px" class="bg-blue-grey-1" />
      <q-skeleton animation="wave" type="text" width="250px" height="20px" class="bg-blue-grey-1" />
      <div class="row justify-between q-mt-sm">
        <div class="column">
          <q-skeleton
            animation="wave"
            type="text"
            width="150px"
            height="30px"
            class="bg-blue-grey-1"
          />
          <div class="item__badges">
            <q-skeleton
              v-for="badge in 1"
              :key="badge"
              width="75px"
              height="30px"
              animation="wave"
              class="q-mr-sm q-mb-sm bg-blue-grey-1"
            />
          </div>
        </div>
        <div class="control__tags column">
          <q-skeleton animation="wave" type="text" width="50px" class="bg-blue-grey-1" />
          <div class="tags__container row q-mt-sm">
            <q-skeleton
              v-for="badge in 6"
              :key="badge"
              width="100px"
              height="30px"
              animation="wave"
              class="q-mr-sm q-mb-sm bg-blue-grey-1"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="counter__container column justify-center items-center q-pt-sm">
      <div class="row items-center">
        <q-skeleton width="16px" height="16px" class="q-mr-sm bg-blue-grey-1" />
        <q-skeleton animation="wave" type="text" width="15px" class="bg-blue-grey-1" />
      </div>
      <div class="row items-center q-mt-sm">
        <q-skeleton width="16px" height="16px" class="q-mr-sm bg-blue-grey-1" />
        <q-skeleton animation="wave" type="text" width="15px" class="bg-blue-grey-1" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.item__container {
  padding-left: 35px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .icon__container {
    width: 20px;
    svg {
      margin-top: 3px;
    }
  }

  :deep(.q-checkbox__inner--falsy) {
    .q-checkbox__bg {
      color: $common-1;
    }
  }

  :deep(.q-checkbox__inner--truthy) {
    .q-checkbox__bg {
      color: $secondary-700;
    }
  }

  .item__info {
    .item__title {
      @include paragraph-02(600, $common-5);
    }

    .item__description {
      @include paragraph-01(400, $common-5);
      opacity: 0.7;
      margin-bottom: 20px !important;
    }

    .secondary__text {
      @include caption(400, $secondary-400);
    }

    .control__tags {
      .tags__title {
        @include caption(400, $secondary-400);
      }
      .tags__container {
        .q-badge {
          @include caption(400, $white);
          max-height: 33.6px;
          padding: 8px !important;
          background: $secondary-300;
        }
      }
    }

    .item__badges {
      .q-badge {
        flex-direction: row-reverse;
        padding: 6px !important;
      }

      .q-badge:hover {
        opacity: 0.7;
      }
    }

    .item__codes {
      .q-badge {
        background: #6d96c7;
        border: none;
        color: $white;
        padding: 6px !important;
      }
    }
  }

  .counter__container {
    position: absolute;
    right: 40px;

    span {
      @include paragraph-02(600, $secondary-600);
    }
  }
}

.item__container:hover {
  background: rgba(240, 243, 244, 0.7);
}
</style>
