<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['confirm', 'closeDialog']);
defineProps({
  projectRequestId: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: 'Default Title',
  },
  description: {
    type: String,
    default: 'Default Description',
  },
  buttonText: {
    type: String,
    default: 'Default',
  },
  cancelText: {
    type: String,
    default: 'Cancel',
  },
  loading: {
    type: Boolean,
  },
  customIcon: {
    type: Boolean,
  },
});

async function confirm() {
  emit('confirm');
}
</script>

<template>
  <div class="wrapp column">
    <div class="row col-12 justify-center">
      <div class="lock__container row items-center justify-center">
        <Icon v-if="!customIcon" icon-name="report_problem" icon-folder="evidence" />
        <slot v-else name="custom-icon" />
      </div>
    </div>
    <div class="row col-12 justify-center">
      <h3>{{ title }}</h3>
    </div>
    <div class="row col-12 justify-center q-mb-sm">
      <p class="text-center">{{ description }}</p>
    </div>
    <div class="row col-12 justify-center">
      <q-btn class="btn-cancel" :label="cancelText" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-confirm"
        :label="buttonText"
        unelevated
        :disable="loading"
        :loading="loading"
        @click="confirm"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  padding: 10px 0;

  .lock__container {
    width: 64px;
    height: 64px;
    background: #ffecee;
    border-radius: 50%;
  }

  h3 {
    max-width: 400px;
    @include heading-03(700, $common-4);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    @include paragraph-02(400, $common-2);
  }
  .btn-cancel {
    padding: 10px 0;
    min-width: 200px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    margin-right: 10px;
    border-radius: 5px;
  }
  .btn-confirm {
    padding: 10px 0;
    min-width: 200px;
    color: $white;
    background: #f96f7f !important;
    text-transform: none;
    border-radius: 5px;
  }
}
</style>
