<script setup lang="ts">
import { debounce } from 'lodash';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import type { QInput } from 'quasar';

import Icon from '@/components/atoms/Icon.vue';

import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';
import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_NAME_SIZE } from '@/composables/utils';

import { useAssetsStore } from '@/stores/AssetsStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useRisksStore } from '@/stores/RisksStore';

const projectsStore = useProjectsStore();
const risksStore = useRisksStore();
const assetsStore = useAssetsStore();
const route = useRoute();
const routeName = route.name;

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

const name = ref('');
const nameInput = ref<QInput | null>(null);
const contentEditable = ref(false);
const validationPassed = ref(true);

const debouncedValidation = debounce(() => {
  if (!nameInput.value) return;
  const isNameValid = nameInput.value.validate();

  if (isNameValid) {
    validationPassed.value = true;
  } else {
    validationPassed.value = false;
  }
}, 100);

async function updateTitle() {
  try {
    let data;
    switch (routeName) {
      case 'project-dashboard':
        if (!projectsStore.project) return;
        data = await projectsStore.updateProject({
          projectId: projectsStore.project.id,
          updateProject: {
            name: name.value,
          },
        });

        successMessage(`Project '${data?.name ?? ''}' was updated`);

        break;
      case 'risk-detail':
        if (!risksStore.currentRisk) return;

        data = await risksStore.updateRisk({
          riskId: risksStore.currentRisk.id,
          updateRisk: {
            name: name.value,
          },
        });

        successMessage(`Risk '${data?.name ?? ''}' was updated`);

        break;
      case 'asset-detail':
        if (!assetsStore.currentAsset) return;
        data = await assetsStore.updateAsset({
          assetCardId: assetsStore.currentAsset.id,
          updateAssetCard: {
            name: name.value,
          },
        });

        successMessage(`Asset '${data?.name ?? ''}' was updated`);

        break;
      case 'test-detail':
        if (!projectsStore.currentTest || !projectsStore.project) return;

        data = await projectsStore.updateTestingTest({
          projectId: projectsStore.project.id,
          testingTestId: projectsStore.currentTest.id,
          updateTestingTest: {
            name: name.value,
          },
        });

        successMessage(`Test '${data?.name ?? ''}' was updated`);

        break;
    }
    contentEditable.value = false;
  } catch (error) {
    errorMessage('Failed to update title');

    captureException(error, {
      message: 'Component: PageTitle, Function: updateTitle',
      data: {
        name: name.value,
      },
    });
  }
}

watch(
  () => props.title,
  (newValue) => {
    name.value = newValue;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="title__container row">
    <h1 v-if="!contentEditable">{{ title }}</h1>
    <q-btn
      v-if="!contentEditable && editable"
      flat
      :disable="!isAdmin() && !isProjectOwner()"
      @click="contentEditable = true"
    >
      <Icon icon-name="icon-edit" icon-folder="colored" />
      <q-tooltip v-if="!isAdmin() && !isProjectOwner()" anchor="top middle" self="bottom middle">
        You don't have permission. Contact the admin.
      </q-tooltip>
    </q-btn>
    <div class="content col-12">
      <q-input
        v-if="contentEditable"
        ref="nameInput"
        v-model="name"
        outlined
        dense
        placeholder="Enter new name..."
        no-error-icon
        :rules="[
          (val: string) => (val && val.length > 0) || 'Please type something',
          (val: string) =>
            (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
        ]"
        @update:model-value="debouncedValidation"
      />

      <div v-if="contentEditable" class="button__container row items-center">
        <q-btn icon="check" :disable="!validationPassed" class="q-mr-md" @click="updateTitle" />
        <q-btn icon="close" @click="contentEditable = false" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.title__container {
  margin-bottom: 10px;
  position: relative;
  h1 {
    @include heading-02(600, $secondary-700);
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    margin: 0 5px 0 0;
  }
  :deep(label) {
    input {
      @include heading-02(600, $secondary-600);
    }
    .q-field__control:before {
      border: 1px solid $secondary-200 !important;
    }
  }
  :deep(button) {
    width: 32px;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    z-index: 5;

    i {
      color: $common-3;
    }
    .q-btn__content {
      flex: none;
    }
  }
  :deep(button):hover {
    background: #ebf6f4;
  }
  .content {
    .button__container {
      position: absolute;
      right: 0;
      bottom: -25px;
    }
  }
}
</style>
