<script setup lang="ts">
import { ref, computed } from 'vue';

import type { PropType } from 'vue';

import type { UserInOrgList } from '@/client/api';
import { UserState } from '@/client/api';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import EditOrgUserForm from '@/components/molecules/Forms/EditOrgUserForm.vue';

const emit = defineEmits(['closeDialog', 'save', 'updateSelectedUser']);

const props = defineProps({
  editedUser: {
    type: Object as PropType<UserInOrgList>,
    required: true,
  },
});

const organizationStore = useOrganizationStore();
const isLoading = ref(false);
const formValid = ref(false);
const userForm = ref<UserInOrgList | null>(null);
const showConfirmDialog = ref(false);
const isLoadingActivation = ref(false);

function validate(value: boolean, user: UserInOrgList) {
  formValid.value = value;
  userForm.value = user;
}

function closeDialog() {
  showConfirmDialog.value = false;
}

async function saveChanges() {
  if (!userForm.value) return;
  try {
    isLoading.value = true;

    await organizationStore.updateUserAdmin((props.editedUser as UserInOrgList).id, userForm.value);

    successMessage(`User information for '${userForm.value.firstname}' has been updated`);
    emit('save');
  } catch (error) {
    captureException(error, {
      message: 'Component: DEditOrgUser, Function: saveChanges',
      data: {
        userForm: userForm.value,
        editedUser: props.editedUser,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

async function confirm() {
  try {
    isLoadingActivation.value = true;

    const status = {
      is_active: !props.editedUser.is_active,
    };

    let response;

    const action = status.is_active ? UserState.Activated : UserState.Deactivated;

    response = await organizationStore.updateUserStatus(props.editedUser.id, status);

    successMessage(`User “${props.editedUser.firstname}“ is successfully ${action}`);

    emit('updateSelectedUser', response);
    showConfirmDialog.value = false;
  } catch (error) {
    captureException(error, {
      message: 'Component: DEditOrgUser, Function: confirm',
      data: {
        userForm: userForm.value,
        editedUser: props.editedUser,
      },
    });
  } finally {
    isLoadingActivation.value = false;
    showConfirmDialog.value = false;
  }
}

const isAllowed = computed(() => {
  return isAdmin();
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person_add" icon-folder="settings" class="q-mr-md" />
      <h5 class="q-ma-none">Edit User</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="row form__container">
      <EditOrgUserForm :edited-user="editedUser" @validate="validate" />
    </div>
    <div class="row action__row full-width justify-end q-mt-lg">
      <q-btn
        v-if="editedUser.is_active"
        class="btn-activation q-mr-sm"
        label="Deactivate User"
        :loading="isLoading"
        unelevated
        :disable="!isAllowed"
        @click="showConfirmDialog = true"
      >
        <Icon icon-folder="organization" icon-name="cancel-invitation" class="q-mr-sm" />
      </q-btn>
      <q-btn
        v-else
        class="btn-activation q-mr-sm"
        label="Activate User"
        :loading="isLoading"
        unelevated
        :disable="!isAllowed"
        @click="showConfirmDialog = true"
      >
        <Icon icon-folder="organization" icon-name="person" class="q-mr-sm" />
      </q-btn>
      <q-btn
        class="btn-add"
        label="Save"
        :loading="isLoading"
        :disable="!formValid || !isAllowed"
        unelevated
        @click="saveChanges"
      />
    </div>
    <ADialog
      :show-dialog="showConfirmDialog"
      max-width="460px !important"
      min-width="460px !important"
      min-height="auto"
      @hide="closeDialog"
    >
      <DConfirm
        :title="
          !editedUser.is_active
            ? `Would you like to activate the ${editedUser?.firstname} ${editedUser?.lastname}?`
            : `Would you like to deactivate the ${editedUser?.firstname} ${editedUser?.lastname}?`
        "
        :description="
          !editedUser.is_active
            ? 'By reactivating this user, you will restore their platform access.'
            : `Deactivating a user's platform access will invalidate any of their API tokens (if existent).`
        "
        button-text="Confirm"
        :custom-icon="true"
        @close-dialog="closeDialog"
        @confirm="confirm"
      >
        <template #custom-icon>
          <Icon v-if="!editedUser.is_active" icon-name="activate" icon-folder="organization" />
          <Icon v-else icon-name="deactivate-alert" icon-folder="organization" />
        </template>
      </DConfirm>
    </ADialog>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .btn-activation {
    color: $secondary-500;
    border: 1px solid $secondary-500;
    background: transparent !important;
    text-transform: none;
    :deep(.q-btn__content) {
      flex-direction: row-reverse;
    }
  }
  .btn-add {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
