<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { debounce } from 'lodash';

import type { QInput } from 'quasar';

import { successMessage } from '@/composables/Notify';
import { MAX_NAME_SIZE } from '@/composables/utils';

import { useRisksStore } from '@/stores/RisksStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

const emit = defineEmits(['closeDialog', 'addToExisting']);

const router = useRouter();
const name = ref('');
const nameInput = ref<QInput | null>(null);
const disableSave = ref(true);
const risksStore = useRisksStore();
const projectsStore = useProjectsStore();
const isLoading = ref(false);

function onReset() {
  name.value = '';
}

const debouncedValidation = debounce(() => {
  if (!nameInput.value) return;

  if (isNameValid.value) {
    disableSave.value = false;
  } else {
    disableSave.value = true;
  }
}, 100);

async function newRisk() {
  if (!projectsStore.project || !isNameValid.value) return;

  isLoading.value = true;

  const data = {
    name: name.value,
  };

  try {
    const params = {
      projectId: projectsStore.project.id,
      createRisk: data,
    };

    const response = await risksStore.createRisk(params);

    successMessage(`Risk ${response?.name} created successfully`);

    if (response && response.id) {
      router.push(
        `/projects/project-detail/${projectsStore.project.id}/risk-detail/${response.id}`,
      );
      emit('closeDialog');
    }
  } finally {
    isLoading.value = false;
  }
}

const isNameValid = computed(() => nameInput.value?.validate());
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Create New Risk</h5>
    </div>
    <div class="row">
      <span class="risk-name q-mb-sm">Risk Name*</span>
      <q-form class="row col-12" @submit.prevent="newRisk" @reset="onReset">
        <div class="col-12">
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            placeholder="Insert Name"
            :rules="[
              (val: string) => (val && val.length > 0) || 'Please type something',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
          />
        </div>
      </q-form>
    </div>
    <div class="row action__row full-width justify-between q-mt-sm">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[disableSave ? 'disable' : '']"
        :loading="isLoading"
        :disable="disableSave || isLoading"
        @click="newRisk"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .disabled {
    opacity: 0.5;
  }
  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-1);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-1);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-1);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, $common-4);
    }
  }
  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
