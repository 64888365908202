<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { watch, ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { usePaginationStore } from '@/stores/PaginationStore';

import type { NotificationStatus } from '@/client';

import { captureException } from '@/composables/Sentry';

import { DEFAULT_PAGINATION, SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DProjectControl from '@/components/dialogs/DProjectControl.vue';
import DProjectRequirement from '@/components/dialogs/DProjectRequirement.vue';
import MContentMainItem from '@/components/molecules/Content/MContentMainItem.vue';
import SContentMainItem from '@/components/skeletons/SContentMainItem.vue';

defineProps({
  allSelected: {
    type: Boolean,
  },
});

const route = useRoute();
const router = useRouter();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const showControlDialog = ref(false);
const showRequirementDialog = ref(false);

function closeControlDialog() {
  showControlDialog.value = false;
}

function closeRequirementDialog() {
  showRequirementDialog.value = false;
}

function openRequirement(id: string) {
  if (!projectsStore.project) return;

  router.push({
    name: 'project-controls',
    params: { id: projectsStore.project.id },
    query: { requirement: id },
  });
}

function openControl(id: string) {
  if (!projectsStore.project) return;

  router.push({
    name: 'project-controls',
    params: { id: projectsStore.project.id },
    query: { control: id },
  });
}

const emptyFilters = computed(() => {
  return (
    !paginationStore.nameFilter &&
    !paginationStore.tagFilter &&
    !paginationStore.statusFilter &&
    !paginationStore.assignedToMe &&
    !paginationStore.assignedToMeForReview &&
    !paginationStore.frameworkQuery
  );
});

const onlySearchFilter = computed(() => {
  return (
    paginationStore.nameFilter &&
    !paginationStore.tagFilter &&
    !paginationStore.statusFilter &&
    !paginationStore.assignedToMe &&
    !paginationStore.assignedToMeForReview &&
    !paginationStore.frameworkQuery
  );
});

watch(
  () => route.query.control,
  (newControlId, oldControlId) => {
    if (!newControlId || newControlId === oldControlId || showControlDialog.value) return;
    showRequirementDialog.value = false;
    showControlDialog.value = true;
  },
  {
    immediate: true,
  },
);

watch(
  () => route.query.requirement,
  (newRequirementId, oldRequirementId) => {
    if (!newRequirementId || newRequirementId === oldRequirementId || showRequirementDialog.value)
      return;
    showControlDialog.value = false;
    showRequirementDialog.value = true;
  },
  {
    immediate: true,
  },
);

watch([showControlDialog, showRequirementDialog], ([newFirstValue, newSecondValue]) => {
  if (!projectsStore.project) return;

  if (!newFirstValue && !newSecondValue) {
    router.push({
      name: 'project-controls',
      params: { id: projectsStore.project.id },
    });
  }
});

onMounted(async () => {
  if (!projectsStore.project) return;

  paginationStore.setNameFilter(route.query.search as string);
  paginationStore.setStatusFilter(route.query.status as NotificationStatus);
  paginationStore.setAssignedToMe(Boolean(route.query.assigned as string));
  paginationStore.setAssignedForReview(Boolean(route.query.assigned_for_review as string));

  try {
    await projectsStore.getProjectControls({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
      name: route.query.search as string,
      status: route.query.status as string,
      size: SMALLER_PAGINATION_SIZE,
      tags: paginationStore.tagFilter ? [paginationStore.tagFilter] : undefined,
      framework: paginationStore.frameworkQuery,
      isAssignedToMe: Boolean(route.query.assigned as string),
      isAssignedToMeForReview: Boolean(route.query.assigned_for_review as string),
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: Controls, Hook: onMounted, Method: getProjectControls',
    });
  }
});
</script>
<template>
  <div
    v-if="projectsStore.controlsData && !projectsStore.conceptsLoading"
    class="data__container column"
  >
    <MContentMainItem
      v-for="item in projectsStore.controlsData.items"
      :key="item.id"
      :data="item"
      :all-selected="allSelected"
      :badges="item.requirements"
      :frameworks="item.frameworks"
      :tags="item.tags"
      secondary-text="Related requirements"
      @open-requirement="openRequirement"
      @open-control="openControl"
    />
    <ADialog :show-dialog="showControlDialog" @hide="closeControlDialog">
      <DProjectControl
        v-if="showControlDialog"
        :control-id="route.query.control as string"
        @close-dialog="showControlDialog = false"
        @open-requirement="openRequirement"
      />
    </ADialog>

    <ADialog :show-dialog="showRequirementDialog" @hide="closeRequirementDialog">
      <DProjectRequirement
        v-if="showRequirementDialog"
        :control-id="route.query.requirement as string"
        @close-dialog="showRequirementDialog = false"
        @open-control="openControl"
      />
    </ADialog>
  </div>
  <div v-if="projectsStore.conceptsLoading" class="data__container column">
    <SContentMainItem v-for="item in 5" :key="item" />
  </div>
  <div
    v-if="
      projectsStore.controlsData &&
      projectsStore.controlsData.items.length === 0 &&
      !projectsStore.conceptsLoading
    "
    class="empty__wrapp row items-center q-mt-xl"
  >
    <AEmptyData
      v-if="emptyFilters"
      icon-name="folder"
      :header="`You don’t have any Control attached to this Project.`"
      text="Start by adding a Framework."
      action-text=""
      class="full-width items-center"
    />
    <AEmptyData
      v-if="!onlySearchFilter && !emptyFilters"
      icon-name="filter"
      header="There is no controls for the selected filter(s)."
      text="Try changing your filter."
      class="full-width items-center"
    />
    <AEmptyData
      v-if="onlySearchFilter"
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
</template>

<style lang="scss" scoped>
.prev-btn,
.next-btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
.prev-btn {
  left: 20px;
}
.next-btn {
  right: 20px;
}
</style>
