<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

import type { TestingTestResult } from '@/client/api';
import { TestingTestStatus } from '@/client/api';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { captureException } from '@/composables/Sentry';
import { TESTING_STATUS_LABEL } from '@/composables/utils';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import DTestDetails from '@/components/dialogs/DTestDetails.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

interface Select {
  value: TestingTestStatus;
  label: string;
}

const projectStore = useProjectsStore();
const paginationStore = usePaginationStore();
const filter = ref<string | null>(null);
const resultFilter = ref<Select | null>(null);
const dateFilter = ref(null);
const timeFilter = ref(null);
const resultOptions = ref([
  {
    value: null,
    label: 'All',
  },
  {
    value: TestingTestStatus.Passed,
    label: TESTING_STATUS_LABEL.PASSED,
  },
  {
    value: TestingTestStatus.Failed,
    label: TESTING_STATUS_LABEL.FAILED,
  },
  {
    value: TestingTestStatus.Error,
    label: TESTING_STATUS_LABEL.ERROR,
  },
]);
const showTestDetails = ref(false);
const resultsLoading = ref(false);
const selectedTest = ref<TestingTestResult | null>(null);

const columnsNames = {
  created_at: 'Date',
  status: 'Result',
};

async function filterResults() {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    resultsLoading.value = true;

    await projectStore.getTestingTestResults({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      status: paginationStore.testResultFilter,
      ...DEFAULT_PAGINATION,
      sortBy: 'created_at',
      descending: true,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: TabResults, Function: filterResults',
    });
  } finally {
    resultsLoading.value = false;
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  await filterResults();
}

async function filterByResult() {
  if (!resultFilter.value) return;

  paginationStore.setTestResultFilter(resultFilter.value.value);

  await filterResults();
}

function closeDialog() {
  showTestDetails.value = false;
}

async function openDetails(row: TestingTestResult) {
  selectedTest.value = row;
  showTestDetails.value = true;
}

onMounted(async () => {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    resultsLoading.value = true;
    await projectStore.getTestingTestResults({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      ...DEFAULT_PAGINATION,
      sortBy: 'created_at',
      descending: true,
    });
  } finally {
    resultsLoading.value = false;
  }
});

onBeforeUnmount(() => {
  paginationStore.setTestResultFilter(null);
});
</script>

<template>
  <OTable
    :data="projectStore.testingTestResults?.items"
    :pagination="projectStore.testingTestResults!"
    :columns-names="columnsNames"
    :is-loading="resultsLoading"
    :skeleton-size="5"
    :search-input="false"
    sort-by="created_at"
    parent-el="tabResults"
    store="projectStore"
    action="getTestResults"
    @filter-by-search="filterBySearch"
    @open-test-details="openDetails"
  >
    <template #header-filters>
      <q-select
        v-model="resultFilter"
        outlined
        label="Result"
        :options="
          resultOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))
        "
        dense
        :class="[resultsLoading ? 'skeleton' : '']"
        @update:model-value="filterByResult"
      />
    </template>
  </OTable>
  <div
    v-if="
      projectStore.testingTestResults &&
      projectStore.testingTestResults.items.length === 0 &&
      !resultsLoading
    "
    class="empty__wrapp row items-center q-mt-md"
  >
    <AEmptyData
      v-if="!filter && !resultFilter && !dateFilter && !timeFilter"
      icon-name="folder"
      :header="`You don't have any Results related to this Test.`"
      text=""
      class="full-width items-center"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
  <ADialog
    v-if="selectedTest"
    :show-dialog="showTestDetails"
    max-height="auto !important"
    max-width="800px !important"
    min-height="auto !important"
    min-width="800px !important"
    class="dialog-test-details"
    @hide="showTestDetails = false"
  >
    <DTestDetails :selected-test="selectedTest" @close-dialog="closeDialog" />
  </ADialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.q-field {
  width: 150px;
  border-radius: 4px;
  :deep(.q-field__inner) {
    padding: 0 !important;
    .q-field__control::before {
      border: none !important;
      .q-field__native div {
        display: none !important;
      }
    }
    .q-field__label {
      color: $secondary-400;
    }
  }
}
</style>
