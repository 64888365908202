<script setup lang="ts">
import type { PropType } from 'vue';
import { Concept } from '@/client/api';
import type {
  FrameworkDetailed,
  Control,
  Requirement,
  Risk,
  AssetCard,
  TestingTest,
  Evidence,
} from '@/client/api';

import ControlConcept from './concepts/ControlConcept.vue';
import RequirementConcept from './concepts/RequirementConcept.vue';
import EvidenceConcept from './concepts/EvidenceConcept.vue';
import TestConcept from './concepts/TestConcept.vue';
import AssetConcept from './concepts/AssetConcept.vue';
import RiskConcept from './concepts/RiskConcept.vue';
import FrameworkConcept from './concepts/FrameworkConcept.vue';

type Data = FrameworkDetailed | Control | Requirement | AssetCard | TestingTest | Risk | Evidence;

const emits = defineEmits(['mouseleave', 'mouseenter']);

defineProps({
  type: {
    type: String,
    required: true,
  },
  data: {
    type: Object as PropType<Data>,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
});

function handleMouseLeave() {
  emits('mouseleave');
}

function handleMouseEnter() {
  emits('mouseenter');
}
</script>

<template>
  <div
    v-if="data"
    class="info-block"
    :class="`block-${type}`"
    :style="{ top: `${position.top}px`, left: `${position.left}px` }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div v-if="type === Concept.Framework" class="row col-12">
      <FrameworkConcept :data="data as FrameworkDetailed" :type="type" :label="label" />
    </div>
    <div v-if="type === Concept.Control" class="row col-12">
      <ControlConcept :data="data as Control" :type="type" :label="label" />
    </div>
    <div v-if="type === Concept.Requirement" class="row col-12">
      <RequirementConcept :data="data as Requirement" :type="type" :label="label" />
    </div>
    <div v-if="type === Concept.AssetCard" class="row col-12">
      <AssetConcept :data="data as AssetCard" />
    </div>
    <div v-if="type === Concept.TestingTest" class="row col-12">
      <TestConcept :data="data as TestingTest" />
    </div>
    <div v-if="type === Concept.Risk" class="row col-12">
      <RiskConcept :data="data as Risk" />
    </div>
    <div v-if="type === 'evidence'" class="row col-12">
      <EvidenceConcept :data="data as Evidence" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.info-block {
  max-width: 300px;
  position: absolute;
  background-color: $white;
  border: 1px solid $secondary-50;
  border-radius: 4px;
  padding: 10px;
  z-index: 9999;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  :deep(.title) {
    font-size: 14px;
    font-weight: 600;
    color: $secondary-600;
    margin-bottom: 8px;
  }

  :deep(.dark) {
    font-size: 14px;
    font-weight: 400;
    color: $secondary-600;
  }

  :deep(p) {
    font-size: 14px;
    font-weight: 400;
    color: $secondary-500;
    margin: 0;
  }
}

.block-risk {
  min-width: 400px;
}
</style>
