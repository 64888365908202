<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

import { useAuthStore } from '@/stores/AuthStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';

import { errorMessage, successMessage } from '@/composables/Notify';
import { isAdmin } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';
import { ContentTooLargeError, BadRequestError } from '@/composables/ApiError';
import { BytesToMb, FileRejected, MAX_FILE_SIZE } from '@/composables/utils';

import { ProjectType } from '@/client/api';

import PageRoute from '@/components/atoms/PageRoute.vue';

const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const template = ref(null);
const isUploading = ref(false);
const activeType = ref<ProjectType>(ProjectType.AiApplication);

const pageTitle = computed(() => {
  switch (route.name) {
    case 'organization-users':
      return 'User Management';
    case 'organization-templates':
      return 'Frameworks';
    case 'organization-customization':
      return 'Customization';
    case 'organization-settings':
      return 'Settings';
    default:
      return '';
  }
});

async function uploadTemplate(file: File) {
  isUploading.value = true;

  try {
    await organizationStore.uploadOrganizationTemplate(file);

    successMessage('Templates successfully uploaded');

    await organizationStore.getOrganizationTemplates(activeType.value);
    template.value = null;
  } catch (error) {
    if (error instanceof ContentTooLargeError) {
      $q.notify({
        color: 'red',
        icon: 'error',
        message: 'File size is too large',
      });

      return;
    } else if (error instanceof BadRequestError) {
      errorMessage(error.message);
      return;
    }
    captureException(error, { message: 'Component: OrganizationPage, Function: uploadTemplate' });
  } finally {
    isUploading.value = false;
  }
}

function changeType(value: ProjectType) {
  activeType.value = value;
}

const isAllowed = computed(() => {
  return isAdmin();
});

watch(
  () => authStore.user,
  (newV) => {
    if (!newV || route.name !== 'organization') return;

    if (newV.is_org_admin || newV.is_super_admin) {
      router.push('/organization/users');
      return;
    }
    if (!newV.is_super_admin && !newV.is_org_admin) {
      router.push('/organization/templates');
      return;
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div v-if="authStore.user" class="content-wrapper">
    <PageRoute />
    <div v-if="route.name !== 'template-detail'" class="title__container row q-my-md">
      <span class="title-h1">{{ pageTitle }}</span>
      <div v-if="route.name === 'organization-templates'" class="upload__container">
        <q-file
          v-model="template"
          class="file-uploader q-mb-sm"
          outlined
          dense
          label=""
          label-slot
          :disable="isUploading || !isAllowed"
          :max-file-size="MAX_FILE_SIZE"
          @rejected="FileRejected"
          @update:model-value="uploadTemplate"
        >
          <template #prepend>
            <q-icon name="upload" />
          </template>
          <slot name="label">
            <div class="file-label">
              <span>Upload Template</span>
            </div>
          </slot>
        </q-file>
        <div class="details__container row col-12 justify-end">
          <span>{{ `Maximum file size ${BytesToMb(MAX_FILE_SIZE)}MB` }}</span>
        </div>
      </div>
    </div>

    <router-view @change-tab="changeType" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.title__container {
  position: relative;
  .title-h1 {
    @include heading-02(600, $secondary-700);
  }
  span {
    @include heading-04(600, $secondary-600);
  }
}
.btn-scope {
  background: $secondary-500 !important;
  text-transform: none;
  position: absolute;
  top: 0;
  right: 30px;
}

.upload__container {
  position: absolute;
  top: 0;
  right: 30px;
  .file-uploader {
    cursor: pointer;
    :deep(.q-field__control-container) {
      padding-top: 10px !important;
      flex-direction: column;
      align-items: center;
    }

    :deep(.q-field__inner) {
      background: $secondary-500;
      border-radius: 5px;
    }

    :deep(.q-field__control::before) {
      border: none !important;
    }

    :deep(.q-field__control::after) {
      border: none !important;
    }

    :deep(.q-field__native) {
      display: none;
    }

    i {
      color: $white;
    }

    .file-label {
      span {
        @include paragraph-01(400, $white);
      }
    }
  }

  .details__container {
    span {
      @include caption(400, $secondary-400);
    }
  }
}
</style>
