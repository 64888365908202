<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { PropType } from 'vue';
import type { StatusOverview } from '@/client/api';

import Icon from '@/components/atoms/Icon.vue';

const props = defineProps({
  progress: {
    type: Object as PropType<StatusOverview>,
    required: true,
  },
});

const ready = ref(0);
const notReady = ref(0);
const completed = ref(0);

onMounted(() => {
  const inProgressPercentage = props.progress.in_progress_percentage || 0;
  const completedPercentage = props.progress.completed_percentage || 0;
  const inReviewPercentage = props.progress.in_review_percentage || 0;

  const totalCount = inProgressPercentage + completedPercentage + inReviewPercentage;

  completed.value = completedPercentage !== 0 ? (completedPercentage / totalCount) * 100 : 0;
  ready.value = inReviewPercentage !== 0 ? (inReviewPercentage / totalCount) * 100 : 0;
  notReady.value = inProgressPercentage !== 0 ? (inProgressPercentage / totalCount) * 100 : 0;
});
</script>

<template>
  <div class="wrapp row">
    <span class="tooltip-title col-12 q-mb-sm">Progress breakdown</span>
    <div class="tooltip-item q-mb-sm row col-12">
      <Icon icon-name="check_circle" icon-folder="status" class="q-mr-sm" />
      <span
        ><b>{{ completed > 0 ? completed.toFixed(1) : 0 }}%</b> completed</span
      >
    </div>
    <div class="tooltip-item q-mb-sm row col-12">
      <Icon icon-name="ready" icon-folder="status" class="q-mr-sm" />
      <span
        ><b>{{ ready > 0 ? ready.toFixed(1) : 0 }}%</b> in review</span
      >
    </div>
    <div class="tooltip-item q-mb-sm row col-12">
      <Icon icon-name="in_progress" icon-folder="status" class="q-mr-sm" />
      <span
        ><b>{{ notReady > 0 ? notReady.toFixed(1) : 0 }}%</b> in progress</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.wrapp {
  width: 160px;
  height: 116px;
  background: $white;
  .tooltip-title {
    font-size: 12px;
    font-weight: 400;
    color: $common-2;
  }
  .tooltip-item {
    color: $secondary-600;
    font-size: 12px;
    span {
      font-weight: 400;
    }
  }
}
.wrapp::before {
  content: '';
  position: absolute;
  top: 0;
  left: -14px;
  bottom: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid $white;
  z-index: 0;
}
</style>
