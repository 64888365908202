<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { useQuasar } from 'quasar';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { Evidence, EvidenceUpdate } from '@/client/api';
import { Roles } from '@/client/api';
import { type Pagination } from '@/interfaces/models/Pagination';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import { useEvidenceStore } from '@/stores/EvidenceStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import ADialog from '@/components/atoms/ADialog.vue';
import Icon from '@/components/atoms/Icon.vue';
import DAdvancedSearch from '@/components/dialogs/DAdvancedSearch.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import DEditEvidence from '@/components/dialogs/DEditEvidence.vue';
import DEvidencePreview from '@/components/dialogs/DEvidencePreview.vue';
import DNewEvidence from '@/components/dialogs/DNewEvidence.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import AEmptyData from '../atoms/AEmptyData.vue';

const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const evidenceStore = useEvidenceStore();

const showAdvancedDialog = ref(false);
const showNewEvidenceDialog = ref(false);
const showEvidencePreview = ref(false);
const showConfirmDialog = ref(false);
const filter = ref<string | null>(null);
const evidencePeview = ref<Evidence | null>(null);
const selectedEvidence = ref<Evidence | null>(null);
const typeFilter = ref<string | null>(null);
const showEditEvidenceDialog = ref(false);
const evidenceIsLoading = ref(false);
const confirmationIsLoading = ref(false);
const isEditor = ref(false);
const isAuditor = ref(false);

const columnsNames = {
  file_name: 'Name',
  file_type: 'Type',
  file_size: 'Size',
  controls: 'Control(s)',
  risks: 'Risk(s)',
  created_at: 'Uploaded at',
  created_by: 'Uploaded by',
  evidence_actions: '',
};

async function updateEvidence(id: string, evidence: EvidenceUpdate) {
  if (!projectsStore.project) return;

  const params = {
    evidenceId: id,
    evidenceUpdate: evidence,
  };

  try {
    const evidenceData = await evidenceStore.updateEvidence(params);

    successMessage(`Evidence '${evidenceData?.file_name}' updated successfully`);

    await projectsStore.getProjectsEvidence({ projectId: projectsStore.project.id });

    closeDialog();
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: updateEvidence',
      data: {
        params: params,
      },
    });
  }
}

function openPreview(evidence: Evidence) {
  if (!projectsStore.project) return;

  router.push({
    name: 'project-evidence',
    params: { id: projectsStore.project.id },
    query: { evidence: evidence.id },
  });
}

function showEdit() {
  showEvidencePreview.value = false;
  showEditEvidenceDialog.value = true;
}

function showEvidencePreviewDialog() {
  showEvidencePreview.value = true;
  showEditEvidenceDialog.value = false;
}

function showConfirm(evidence: Evidence) {
  showConfirmDialog.value = true;
  selectedEvidence.value = evidence;
}

function closeConfirm() {
  closeDialog();
  selectedEvidence.value = null;
}

function closeDialog() {
  showAdvancedDialog.value = false;
  showNewEvidenceDialog.value = false;
  showConfirmDialog.value = false;
}

async function filterEvidence() {
  if (!projectsStore.project) {
    return;
  }

  if (!projectsStore.evidenceData) {
    return;
  }

  try {
    await projectsStore.getProjectsEvidence({
      projectId: projectsStore.project.id,
      ...projectsStore.evidenceData,
      ...DEFAULT_PAGINATION,
      name: filter.value !== '' ? filter.value : null,
      associatedControls: paginationStore.evidenceControlsFilter
        ? paginationStore.evidenceControlsFilter.map((control) => control.id)
        : [],
      associatedRisks: paginationStore.evidenceRisksFilter
        ? paginationStore.evidenceRisksFilter.map((risk) => risk.id)
        : [],
      fileType: paginationStore.evidenceTypeFilter,
      startDate: paginationStore.evidenceTimeFilter?.from || null,
      endDate: paginationStore.evidenceTimeFilter?.to || null,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: filterEvidence',
    });
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  await filterEvidence();
}

async function filterByType() {
  if (!projectsStore.project) return;

  paginationStore.setEvidenceTypeFilter(typeFilter.value === 'All' ? null : typeFilter.value);

  await filterEvidence();
}

async function advanceSearch(pagination: Pagination) {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectsEvidence({
      projectId: projectsStore.project.id,
      ...pagination,
      name: filter.value !== '' ? filter.value : null,
      fileType: paginationStore.evidenceTypeFilter,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: advanceSearch',
      data: {
        pagination: pagination,
      },
    });
  }
}

function copyId(id: string) {
  if (!projectsStore.project) return;

  const baseUrl = `${import.meta.env.VITE_API_URL}`;
  navigator.clipboard.writeText(
    `${baseUrl}/projects/project-detail/${projectsStore.project.id}/evidence?evidence=${id}`,
  );
  $q.notify({
    icon: 'cloud_done',
    message: 'Evidence ID copied',
  });
}

async function downloadFile(id: string, fileName: string) {
  try {
    await evidenceStore.downloadFile(id, fileName);
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: downloadFile',
      data: {
        id: id,
        fileName: fileName,
      },
    });
  }
}

async function deleteEvidence() {
  if (!projectsStore.project || !selectedEvidence.value) return;
  try {
    await evidenceStore.deleteArtifact({ evidenceId: selectedEvidence.value.id });

    successMessage('Evidence deleted successfully');

    await projectsStore.getProjectsEvidence({ projectId: projectsStore.project.id });
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: deleteEvidence',
    });
  } finally {
    selectedEvidence.value = null;
  }
}

async function confirm() {
  if (!selectedEvidence.value) return;

  try {
    confirmationIsLoading.value = true;
    await deleteEvidence();
    closeDialog();
    selectedEvidence.value = null;
  } finally {
    confirmationIsLoading.value = false;
  }
}

function navigateToControl(id: string) {
  router.push({
    path: `/projects/project-detail/${projectsStore.project?.id}/controls`,
    query: {
      control: id,
    },
  });
}

function navigateToRisk(id: string) {
  router.push({
    path: `/projects/project-detail/${projectsStore.project?.id}/risk-detail/${id}`,
  });
}

async function updateData() {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectsEvidence({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });
    await projectsStore.getEvidenceFileTypes({ projectId: projectsStore.project.id });
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Function: updateData',
    });
  }
}

const emptyFilters = computed(() => {
  return (
    !typeFilter.value &&
    !filter.value &&
    !paginationStore.evidenceControlsFilter &&
    !paginationStore.evidenceRisksFilter &&
    !paginationStore.evidenceTimeFilter
  );
});

watch(
  () => route.query.evidence,
  async (newId, oldId) => {
    if (!newId || newId === oldId || showEvidencePreview.value) return;

    const evidenceData = await evidenceStore.getEvidence({
      evidenceId: newId as string,
    });

    if (!evidenceData) return;

    evidencePeview.value = evidenceData;

    showEvidencePreviewDialog();
  },
  {
    immediate: true,
  },
);

watch([showEvidencePreview, showEditEvidenceDialog], ([newFirstValue, newSecondValue]) => {
  if (!projectsStore.project) return;

  if (!newFirstValue && !newSecondValue) {
    router.push({
      name: 'project-evidence',
      params: { id: projectsStore.project.id },
    });
  }
});

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    evidenceIsLoading.value = true;
    await projectsStore.getProjectsEvidence({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
    });
    await projectsStore.getEvidenceFileTypes({ projectId: projectsStore.project.id });

    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
    isAuditor.value = userRoles?.includes(Roles.Auditor) || false;
  } catch (error) {
    captureException(error, {
      message: 'Component: Evidence, Hook: onMounted',
    });
  } finally {
    evidenceIsLoading.value = false;
  }
});

onUnmounted(() => {
  paginationStore.setEvidenceControlsFilter(null);
  paginationStore.setEvidenceRisksFilter(null);
  paginationStore.setEvidenceTimeFilter(null);
});
</script>

<template>
  <OTable
    :data="projectsStore.evidenceData?.items"
    :pagination="projectsStore.evidenceData!"
    :columns-names="columnsNames"
    :is-loading="evidenceIsLoading"
    :skeleton-size="5"
    :have-permissions="isAdmin() || isProjectOwner() || isEditor || isAuditor"
    concept="evidence"
    sort-by="file_name"
    parent-el="EvidencePage"
    search-placeholder="Search for Evidence"
    store="projectsStore"
    action="getEvidences"
    @copy-evidence-id="copyId"
    @download-evidence-file="downloadFile"
    @delete-evidence-file="showConfirm"
    @navigate-to-control="navigateToControl"
    @navigate-to-risk="navigateToRisk"
    @filter-by-search="filterBySearch"
    @open-preview="openPreview"
  >
    <template #header-button>
      <q-btn
        v-if="isAdmin() || isProjectOwner() || isEditor"
        icon="add"
        label="New Evidence"
        unelevated
        :class="[evidenceIsLoading ? 'skeleton' : '']"
        @click="showNewEvidenceDialog = true"
      />
    </template>
    <template #header-filters>
      <q-select
        v-model="typeFilter"
        outlined
        label="Type"
        :options="['All', ...projectsStore.projectEvidenceFilesTypes]"
        dense
        :class="[evidenceIsLoading ? 'skeleton' : '']"
        @update:model-value="filterByType"
      />
      <div
        class="advanced-search row q-ml-lg"
        :class="[evidenceIsLoading ? 'skeleton' : '']"
        @click="showAdvancedDialog = true"
      >
        <Icon icon-name="filter_alt" icon-folder="content" class="q-mr-xs" />
        <span>Advanced Search</span>
      </div>
    </template>
    <template
      v-if="
        paginationStore.evidenceRisksFilter ||
        paginationStore.evidenceControlsFilter ||
        paginationStore.evidenceTimeFilter
      "
      #header-details
    >
      <span class="header-details q-mt-md">Additional filters are active</span>
    </template>
  </OTable>
  <div
    v-if="
      projectsStore.evidenceData &&
      projectsStore.evidenceData.items.length === 0 &&
      !evidenceIsLoading
    "
    class="empty__wrapp row items-center q-mt-md"
  >
    <AEmptyData
      v-if="emptyFilters"
      icon-name="folder"
      :header="`You don’t have any Evidence attached to this Project.`"
      :text="isAdmin() || isProjectOwner() || isEditor ? 'Start by uploading a' : ''"
      :action-text="isAdmin() || isProjectOwner() || isEditor ? 'New Evidence.' : ''"
      class="full-width items-center"
      @click-text="showNewEvidenceDialog = true"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
  <ADialog
    :show-dialog="showAdvancedDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showAdvancedDialog = false"
  >
    <DAdvancedSearch @close-dialog="closeDialog" @set-filters="advanceSearch" />
  </ADialog>
  <ADialog
    :show-dialog="showNewEvidenceDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showNewEvidenceDialog = false"
  >
    <DNewEvidence @close-dialog="closeDialog" @update-data="updateData" />
  </ADialog>
  <ADialog
    v-if="evidencePeview"
    :show-dialog="showEvidencePreview"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showEvidencePreview = false"
  >
    <DEvidencePreview
      :evidence="evidencePeview"
      @close-dialog="showEvidencePreview = false"
      @show-edit="showEdit"
    />
  </ADialog>

  <ADialog
    v-if="evidencePeview"
    :show-dialog="showEditEvidenceDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showEditEvidenceDialog = false"
  >
    <DEditEvidence
      :evidence="evidencePeview"
      @close-dialog="showEditEvidenceDialog = false"
      @update-evidence="updateEvidence"
      @show-preview="showEvidencePreviewDialog"
    />
  </ADialog>

  <ADialog
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DConfirm
      title="Delete file"
      :description="`Would you like to permanently delete ${selectedEvidence?.file_name}?`"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirm"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.advanced-search {
  @include paragraph-01(500, $secondary-500);
  cursor: pointer;
}

.q-field {
  width: 150px;
  border-radius: 4px;
  :deep(.q-field__inner) {
    padding: 0 !important;
    .q-field__control::before {
      border: none !important;
      .q-field__native div {
        display: none !important;
      }
    }
    .q-field__label {
      color: $secondary-400;
    }
  }
}

.header-details {
  @include paragraph-01(500, $secondary-500);
  cursor: pointer;
}

:deep(.q-table__container) {
  .q-table__middle {
    overflow: visible !important;
  }
}
</style>
