<script setup lang="ts">
import { ref, watch } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

import type { PropType } from 'vue';
import type { RiskTreatmentStrategy } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import Icon from '@/components/atoms/Icon.vue';
import ARiskInfo from '@/components/atoms/ARiskInfo.vue';
import MPlan from '@/components/molecules/RiskTreatment/MPlan.vue';
import MImplementation from '@/components/molecules/RiskTreatment/MImplementation.vue';
import MResidual from '@/components/molecules/RiskTreatment/MResidual.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';

import { RpnLevel } from '@/composables/CRisk';

interface Strategy extends RiskTreatmentStrategy {
  default: boolean;
}

const emit = defineEmits(['openCreation', 'deleteStrategy', 'selectStrategy']);

const props = defineProps({
  strategy: {
    type: Object as PropType<Strategy | RiskTreatmentStrategy>,
    default: () => null,
  },
  defaultStrategy: {
    type: Boolean,
    default: false,
  },
  strategyId: {
    type: String,
    default: '',
  },
  selectedStrategyId: {
    type: String,
    default: '',
  },

  strategyName: {
    type: String,
    default: '',
  },
  residualRisk: {
    type: [String, Number],
    default: '',
  },
});

const selected = ref(false);
const showActions = ref(false);

const planContent = ref<TipTapContent>({
  content: '',
  json_content: {},
});
const implementationContent = ref<TipTapContent>({
  content: '',
  json_content: {},
});

function hideActions() {
  showActions.value = false;
}

watch(
  () => props.strategy,
  (newV) => {
    if (!newV) return;

    planContent.value = {
      content: newV.plan || '',
      json_content: newV.json_plan || {},
    };

    implementationContent.value = {
      content: newV.implementation || '',
      json_content: newV.json_implementation || {},
    };
  },
  {
    immediate: true,
  },
);

watch(
  () => props.selectedStrategyId,
  (newV) => {
    if (newV !== props.strategyId) {
      selected.value = false;
    } else {
      selected.value = true;
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div
    class="strategy__container row q-mr-md"
    :class="[selectedStrategyId === strategyId ? 'selected' : '']"
  >
    <div class="strategy__header relative-position row justify-center col-12">
      <span class="strategy__name">{{ strategyName }}</span>
      <div class="actions-wrapp">
        <Icon
          icon-name="more_vert"
          icon-folder="colored"
          @click.stop.prevent="showActions = true"
        />
        <div
          v-if="showActions"
          v-on-click-outside="hideActions"
          class="actions__container row items-center"
        >
          <Icon
            icon-name="edit"
            icon-folder="risks"
            icon-size="20px"
            class="q-mr-sm"
            @click.stop.prevent="emit('openCreation')"
          />
          <Icon
            icon-name="delete"
            icon-folder="risks"
            icon-size="20px"
            @click.stop.prevent="emit('deleteStrategy', strategyId)"
          />
        </div>
      </div>
    </div>
    <div class="strategy-steps__container full-width">
      <MPlan
        :class="[defaultStrategy || strategy.plan === '' ? 'default__container' : '']"
        @click="emit('openCreation')"
      >
        <template v-if="defaultStrategy || strategy.plan === ''" #content-slot>
          <div class="plan__content column items-center full-width q-mt-md">
            <span class="content__text text-center q-mb-sm">Add your risk strategy.</span>
            <Icon icon-name="add_circle_outline" icon-folder="risks" />
          </div>
        </template>
        <template v-else #content-slot>
          <div class="plan__content full-width q-mt-lg row justify-center">
            <TipTapEditor
              :editor-content="planContent"
              :view-only="true"
              root-el="Treatment"
              placeholder="Insert text"
              class="full-width"
            />
          </div>
        </template>
      </MPlan>
      <MImplementation
        :class="[defaultStrategy || strategy.implementation === '' ? 'default__container' : '']"
        @click="emit('openCreation')"
      >
        <template v-if="defaultStrategy || strategy.implementation === ''" #content-slot>
          <div class="plan__content column items-center col-12 q-mt-md">
            <span class="content__text full-width text-center q-mb-lg"
              >Plan and document the strategy implementation.</span
            >
            <Icon icon-name="add_circle_outline" icon-folder="risks" />
          </div>
        </template>
        <template v-else #content-slot>
          <div class="plan__content col-12 q-mt-sm row justify-center">
            <TipTapEditor
              :editor-content="implementationContent"
              :view-only="true"
              root-el="Treatment"
              placeholder="Insert text"
              class="full-width"
            />
          </div>
        </template>
      </MImplementation>
      <MResidual @click="emit('openCreation')">
        <template #content-slot>
          <div class="plan__content row full-width q-mt-lg">
            <ARiskInfo
              info-name="Residual Risk"
              :info-value="residualRisk"
              class="full-width"
              :badge-text="RpnLevel(residualRisk) || ''"
              tooltip-text="Residual risk: indicates the risk that remains after treatment "
              :badge-type="
                RpnLevel(residualRisk)
                  ? (RpnLevel(residualRisk) || '').toLowerCase().replaceAll(' ', '-') + '-badge'
                  : 'badge'
              "
            />
          </div>
        </template>
      </MResidual>
      <div class="row full-width justify-center q-mt-md">
        <q-toggle
          v-model="selected"
          size="lg"
          color="white"
          @update:model-value="emit('selectStrategy', strategyId, selected)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.strategy__container {
  max-height: 700px;
  min-width: 380px;
  max-width: 380px;
  border-radius: 5px;
  background: $primary-50;
  padding: 16px 12px 0;
  .strategy__header {
    margin-bottom: 30px;
    .strategy__name {
      @include heading-05(700, #21272a);
      cursor: pointer;
    }
    .actions-wrapp {
      cursor: pointer;
      position: absolute;
      right: 12px;
      :deep(svg:active) {
        scale: 0.8;
      }
      .actions__container {
        padding: 8px;
        position: absolute;
        bottom: -40px;
        border-radius: 5px;
        background: $secondary-500;
        flex-wrap: nowrap;
        z-index: 1000;
      }
    }
  }
  .strategy-steps__container {
    .plan__content {
      span {
        color: $common-4;
        font-size: 14px;
      }
      .content__text {
        :deep(*) {
          color: $secondary-600 !important;
        }
      }
    }
    :deep(.q-toggle__inner--truthy) {
      .q-toggle__track {
        background: $black !important;
        opacity: 1;
      }
    }
    :deep(.q-toggle__track) {
      background: #bfbfbf;
    }
  }
}

.selected {
  background: #fbf3de;
}
</style>
