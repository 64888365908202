<script setup lang="ts">
import * as d3 from 'd3';
import { onMounted, ref, computed, watchEffect, watch } from 'vue';

import type { PropType } from 'vue';
import type { RiskTreatmentStrategy } from '@/client/api';

interface Strategy extends RiskTreatmentStrategy {
  default: boolean;
}

const emit = defineEmits(['updateSlider']);

const props = defineProps({
  impactValue: {
    type: Number,
    default: 0,
  },
  likelihoodValue: {
    type: Number,
    default: 0,
  },
  dValue: {
    type: Number,
    default: 1,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  impactLabels: {
    type: Array as () => string[],
    default: () => [],
  },
  likelihoodLabels: {
    type: Array as () => string[],
    default: () => [],
  },
  newStrategy: {
    type: Object as PropType<Strategy | null>,
    default: () => null,
  },
  showTooltips: {
    type: Boolean,
    default: false,
  },
});

const matrix = ref<HTMLElement | null>(null);

let isDragging = ref(false);
let offsetX = 0;
let offsetY = 0;
const minColor = '#b4De4a';
const mediumColor = '#ffc700';
const maxColor = '#ff1100';
const nCols = 5;
let newStrategyCircleId = ref<string | null>(null);

const dragCircleStyles = computed(() => {
  if (!props.editable) {
    return {
      fill: '#334C63',
      stroke: 'black',
      cursor: 'not-allowed',
    };
  }
  return {
    fill: '#334C63',
    stroke: 'black',
    cursor: 'grab',
  };
});

function getColor(rowIndex: number, colIndex: number) {
  const x = (rowIndex * 1 + 0.5) / nCols;
  const y = (colIndex * 1 + 0.5) / nCols;
  const detection = props.newStrategy ? props.newStrategy.risk_detection : props.dValue;
  const value = x * y * (detection ?? 1);
  return d3
    .scaleLinear<string>()
    .domain([0, 0.2, 1])
    .range([minColor, mediumColor, maxColor])
    .interpolate(d3.interpolateRgb.gamma(1))(value);
}

function renderMatrix() {
  d3.select(matrix.value).select('svg').remove();

  const svgWidth = 500;
  const svgHeight = 350;
  const leftMargin = 100;
  const bottomMargin = 30;
  const cellWidth = 70;
  const cellHeight = 52.5;

  const svg = d3
    .select(matrix.value)
    .append('svg')
    .attr('width', svgWidth)
    .attr('height', svgHeight)
    .style('overflow', 'visible');

  svg
    .append('text')
    .attr('x', leftMargin - 65)
    .attr('y', svgHeight / 2)
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90, ' + (leftMargin - 80) + ', ' + svgHeight / 2 + ')')
    .style('font-size', '14px')
    .style('fill', '#3F4252')
    .text('Impact');

  svg
    .append('text')
    .attr('x', svgWidth / 2 + 25)
    .attr('y', svgHeight - 5)
    .attr('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('fill', '#3F4252')
    .text('Likelihood');

  props.impactLabels.forEach((impact, rowIndex) => {
    props.likelihoodLabels.forEach((likelihood, colIndex) => {
      svg
        .append('rect')
        .attr('id', '#rect-' + rowIndex + '-' + colIndex)
        .attr('x', leftMargin + colIndex * cellWidth)
        .attr('y', (props.impactLabels.length - 1 - rowIndex) * cellHeight + 25)
        .attr('width', cellWidth)
        .attr('height', cellHeight)
        .style('stroke', 'white')
        .attr('fill', getColor(rowIndex, colIndex));
    });

    svg
      .append('text')
      .attr('x', leftMargin - 10)
      .attr('y', rowIndex * cellHeight + cellHeight / 2 + 30)
      .attr('dy', '0.32em')
      .attr('text-anchor', 'end')
      .style('font-size', '12px')
      .style('fill', '#222539')
      .text(impact);
  });

  props.likelihoodLabels.forEach((likelihood, colIndex) => {
    svg
      .append('text')
      .attr('x', leftMargin + colIndex * cellWidth + cellWidth / 2)
      .attr('y', svgHeight - bottomMargin - 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', '#222539')
      .text(likelihood);
  });

  if (props.newStrategy) {
    const circleRadius = 10.5;
    const xPosition =
      leftMargin +
      (props.newStrategy.risk_likelihood ?? 0) * (cellWidth * props.likelihoodLabels.length);
    const yPosition =
      svgHeight -
      bottomMargin -
      35 -
      (props.newStrategy.risk_impact ?? 0) * (cellHeight * props.impactLabels.length);

    newStrategyCircleId.value = `circle-${props.newStrategy.id}`;

    const newCircle = svg
      .append('circle')
      .attr('id', newStrategyCircleId.value)
      .attr('cx', xPosition)
      .attr('cy', yPosition)
      .attr('r', circleRadius);

    newCircle.style('fill', 'white');
    newCircle.style('stroke', 'black');
    newCircle.style('cursor', 'not-allowed');

    if (props.showTooltips) {
      const tooltip = svg.append('g').attr('class', `new-strategy-tooltip`).style('opacity', 1);

      tooltip
        .append('rect')
        .attr('width', 110)
        .attr('height', 35)
        .style('fill', 'black')
        .style('stroke-width', 1)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('x', xPosition - 55)
        .attr('y', yPosition - 50);

      tooltip
        .append('text')
        .attr('x', xPosition)
        .attr('y', yPosition - 28)
        .attr('text-anchor', 'middle')
        .style('fill', 'white')
        .text('Residual Risk');
    }
  }

  const circleRadius = 10.5;
  const xPosition =
    leftMargin + props.likelihoodValue * (cellWidth * props.likelihoodLabels.length);
  const yPosition =
    svgHeight - bottomMargin - 35 - props.impactValue * (cellHeight * props.impactLabels.length);

  const circle = svg
    .append('circle')
    .attr('cx', xPosition)
    .attr('cy', yPosition)
    .attr('r', circleRadius);

  circle.style('fill', '#334C63');

  if (props.showTooltips && props.newStrategy) {
    const tooltip = svg.append('g').attr('class', `old-strategy-tooltip`).style('opacity', 1);

    tooltip
      .append('rect')
      .attr('width', 110)
      .attr('height', 35)
      .style('fill', 'black')
      .style('stroke-width', 1)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('x', xPosition - 55)
      .attr('y', yPosition - 50);

    tooltip
      .append('text')
      .attr('x', xPosition)
      .attr('y', yPosition - 28)
      .attr('text-anchor', 'middle')
      .style('fill', 'white')
      .text('Inherent Risk');
  }

  watchEffect(() => {
    // Update styles dynamically
    Object.entries(dragCircleStyles.value).forEach(([key, value]) => {
      circle.style(key, value);
    });
  });

  watchEffect(() => {
    const x = leftMargin + props.likelihoodValue * (cellWidth * props.likelihoodLabels.length);
    const y =
      svgHeight - bottomMargin - 35 - props.impactValue * (cellHeight * props.impactLabels.length);

    circle.attr('cx', x).attr('cy', y);
  });

  circle.on('mousedown', startDragging);
}

const handleMouseMove = (event: MouseEvent) => {
  if (isDragging.value) {
    const rect = (matrix.value as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left - offsetX;
    const y = event.clientY - rect.top - offsetY;

    emit('updateSlider', x, y);
    renderMatrix();
  }
};

const startDragging = (event: MouseEvent) => {
  if (!props.editable) return;

  const rect = (matrix.value as HTMLElement).getBoundingClientRect();
  const circleX = 100 + props.likelihoodValue * (70 * (props.likelihoodLabels.length - 1));
  const circleY = 350 - 30 - props.impactValue * (52.5 * (props.impactLabels.length - 1));

  offsetX = event.clientX - rect.left - circleX;
  offsetY = event.clientY - rect.top - circleY;

  isDragging.value = true;

  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('mouseup', stopDragging);
};

const stopDragging = () => {
  isDragging.value = false;

  window.removeEventListener('mousemove', handleMouseMove);
  window.removeEventListener('mouseup', stopDragging);

  const dragCircle = d3.select(matrix.value).select('circle[fill="white"][stroke="black"]');
  dragCircle.on('mousedown', startDragging);
};

watch(
  () => props.newStrategy,
  (newV) => {
    if (matrix.value && newV) {
      renderMatrix();
    } else {
      if (newStrategyCircleId.value) {
        const svg = d3.select(matrix.value).select('svg');
        svg.select(`#${newStrategyCircleId.value}`).remove();
        svg.selectAll(`.new-strategy-tooltip`).remove();
        newStrategyCircleId.value = null;
        renderMatrix();
      }
    }
  },
);

onMounted(() => {
  if (matrix.value) {
    renderMatrix();

    const dragCircle = d3.select(matrix.value).select('circle[fill="white"][stroke="black"]');

    dragCircle.on('mousedown', startDragging);
  }
});

defineExpose({
  renderMatrix,
});
</script>

<template>
  <div ref="matrix" class="relative-position"></div>
</template>
