<script setup lang="ts">
import { debounce } from 'lodash';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import type { ControlAggregator } from '@/client/api';
import type { Details } from '@/interfaces/models/Select';
import type { QInput } from 'quasar';
import type { PropType } from 'vue';

import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';
import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_NAME_SIZE, statusIcon } from '@/composables/utils';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useRisksStore } from '@/stores/RisksStore';
import { useTaskStore } from '@/stores/TaskStore';

import Icon from '@/components/atoms/Icon.vue';

interface Select {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
}

const props = defineProps({
  control: {
    type: Object as PropType<ControlAggregator>,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'addToExisting']);

const router = useRouter();
const name = ref('');
const nameInput = ref<QInput | null>(null);
const disableSave = ref(true);
const risksStore = useRisksStore();
const tasksStore = useTaskStore();
const projectsStore = useProjectsStore();
const associatedRisk = ref<Select | null>(null);
const isCreation = ref(false);
const isLoading = ref(false);

const debouncedValidation = debounce(() => {
  if (nameInput.value === null) return;

  if (isNameValid.value) {
    disableSave.value = false;
  } else {
    disableSave.value = true;
  }
}, 100);

function switchState(state: boolean) {
  isCreation.value = state;
  name.value = '';
  disableSave.value = true;
  associatedRisk.value = null;
}

function onReset() {
  name.value = '';
}

async function createAssociation() {
  if (!projectsStore.project || !isNameValid.value) return;

  isLoading.value = true;

  const data = {
    name: name.value,
  };

  try {
    const params = {
      projectId: projectsStore.project.id,
      createRisk: data,
    };

    const response = await risksStore.createRisk(params);

    if (!response) return;

    const addParams = {
      riskId: response.id,
      controlId: props.control.id,
    };

    await tasksStore.addRiskToControl(addParams);

    successMessage(
      `Risk ${response.name} successfully assigned to Control ${props.control.control_code}`,
    );

    if (response && response.id) {
      router.push(
        `/projects/project-detail/${projectsStore.project.id}/risk-detail/${response.id}`,
      );
      emit('closeDialog');
    }
  } catch (error) {
    captureException(error, {
      message: 'Component: DControlCreateRisk, Function: createAssociation',
    });
  } finally {
    isLoading.value = false;
  }
}

async function selectFromExisting() {
  if (!associatedRisk.value || !tasksStore.task) return;

  try {
    await tasksStore.addRiskToControl({
      controlId: props.control.id,
      riskId: associatedRisk.value.value,
    });

    successMessage(
      `Risk ${associatedRisk.value.label} successfully assigned to Control ${tasksStore.task.name}`,
    );

    associatedRisk.value = null;

    await tasksStore.getControlRisks({
      controlId: props.control.id,
    });
  } catch (error) {
    errorMessage('Failed to associate Risk.');
    captureException(error, {
      message: 'Component: DControlCreateRisk, Function: selectFromExisting',
    });
  }
}

async function associateRisk() {
  if (isCreation.value) {
    await createAssociation();
  } else {
    await selectFromExisting();
  }
}

async function fetchRisks(details: Details) {
  if (!projectsStore.project || !projectsStore.risksData) return;

  if (
    projectsStore.risksData.total &&
    projectsStore.risksData.size &&
    details.index === filteredAssociatedRisks.value.length - 1 &&
    projectsStore.risksData.items.length < projectsStore.risksData.total
  ) {
    try {
      await projectsStore.getProjectsRisks({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        size: projectsStore.risksData.size + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, {
        message: 'Component: DControl, Function: fetchRisks',
      });
    }
  }
}

async function fetchAdditionalRisks() {
  if (!projectsStore.project || !projectsStore.risksData) return;

  if (
    projectsStore.risksData.total &&
    projectsStore.risksData.size &&
    projectsStore.risksData.items.length < projectsStore.risksData.total
  ) {
    try {
      await projectsStore.getProjectsRisks({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        size: projectsStore.risksData.size + DEFAULT_PAGINATION_SIZE,
      });
    } catch (error) {
      captureException(error, {
        message: 'Component: DControl, Function: fetchAdditionalRisks',
      });
    }
  }
}

const isNameValid = computed(() => nameInput.value?.validate());

const filteredAssociatedRisks = computed(() => {
  if (!projectsStore.risksData) return [];

  const controlRisks = tasksStore.controlRisks;

  if (!controlRisks || controlRisks.length === 0) {
    return projectsStore.risksData.items.map((item) => {
      return { label: item.name, value: item.id };
    });
  }

  return projectsStore.risksData.items
    .filter((item) => {
      return !controlRisks.some((controlRisk) => controlRisk.id === item.id);
    })
    .map((item) => {
      return { label: item.name, value: item.id };
    });
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Associate to a Risk</h5>
    </div>
    <div class="row associated__control q-mb-md">
      <span class="associated-title q-mb-sm">Associated Control</span>
      <div class="row col-12 control__container">
        <q-badge
          class="control-badge q-mr-sm q-mb-sm justify-center"
          :class="[control.status]"
          :label="control.control_code"
        >
          <Icon
            :icon-name="statusIcon(control.status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
        </q-badge>
      </div>
    </div>
    <div class="full-width q-mb-md">
      <q-btn-group unelevated spread>
        <q-btn
          flat
          unelevated
          label="Select from existing"
          :class="[!isCreation ? 'active' : '']"
          @click="switchState(false)"
        />
        <q-btn
          flat
          unelevated
          label="Create New Risk"
          :class="[isCreation ? 'active' : '']"
          @click="switchState(true)"
        />
      </q-btn-group>
    </div>
    <div class="row q-mb-sm">
      <q-form
        v-if="isCreation"
        class="row col-12"
        @submit.prevent="createAssociation"
        @reset="onReset"
      >
        <div class="col-12">
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            placeholder="Insert Name"
            :rules="[
              (val: string) => (val && val.length > 0) || 'Please type something',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
          />
        </div>
      </q-form>
      <q-select
        v-if="projectsStore.risksData && !isCreation"
        v-model="associatedRisk"
        outlined
        :options="filteredAssociatedRisks"
        :disable="!isProjectOwner() && !isAdmin()"
        dense
        class="col-12"
        label="Please Select"
        @popup-show="fetchAdditionalRisks"
        @virtual-scroll="fetchRisks"
      />
    </div>
    <div class="row action__row full-width justify-between q-mt-sm">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-save"
        label="Save"
        unelevated
        :class="[disableSave ? 'disable' : '']"
        :loading="isLoading"
        :disable="(disableSave && !associatedRisk) || isLoading"
        @click="associateRisk"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .disabled {
    opacity: 0.5;
  }

  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-1);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-1);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-1);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, #262626);
    }
  }

  .q-select {
    margin-bottom: 20px;
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-1);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-1);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-1);
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
