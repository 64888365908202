<script setup lang="ts">
import { useProjectsStore } from '@/stores/ProjectsStore';

import { RpnLevel, DEFAULT_THRESHOLD } from '@/composables/CRisk';

import ATooltip from '@/components/atoms/ATooltip.vue';

const projectsStore = useProjectsStore();
</script>

<template>
  <div v-if="projectsStore.project" class="threshold__container row items-center">
    <div class="row items-center">
      <span class="threshold-title">Risk Level Threshold</span>
      <ATooltip
        icon-folder="settings"
        icon-name="info-medium"
        tooltip-text="Risk Threshold for this project can be edit only by the Owner under Project Settings"
        class="q-ml-sm"
      />
    </div>
    <span class="threshold-value q-mx-md">{{
      projectsStore.project.risk_threshold || DEFAULT_THRESHOLD
    }}</span>

    <q-badge
      class="justify-center"
      :label="RpnLevel(projectsStore.project.risk_threshold || DEFAULT_THRESHOLD) || ''"
      :class="[
        RpnLevel(projectsStore.project.risk_threshold || DEFAULT_THRESHOLD)
          ? (RpnLevel(projectsStore.project.risk_threshold || DEFAULT_THRESHOLD) || '')
              .replaceAll(' ', '-')
              .toLowerCase() + '-badge'
          : '',
      ]"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.threshold__container {
  .threshold-title {
    @include paragraph-01(700, $secondary-600);
  }

  .threshold-value {
    font-size: 14px;
    font-weight: 700;
    color: $common-4;
  }
}
</style>
