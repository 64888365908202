import { defineStore } from 'pinia';
import { ref } from 'vue';

import { FrameworksApi, OrganizationsApi, UsersApi } from '@/client/api';
import initApi from '@/client_heplers/utils';

import type {
  InviteUser,
  FrameworkOverview,
  OrganizationQuota,
  OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest,
  OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest,
  ProjectType,
  ResponseGetUserApiUsersUserIdGet,
  Tags,
  UpdateUserOID,
  UserInOrgList,
  UserOrgPage,
  UserActivationStatus,
  UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest,
  UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest,
} from '@/client/api';
import type { AxiosError } from 'axios';

import { handleApiError } from '@/composables/ApiError';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';
import type { RequestConfig } from '@/interfaces/models/Request';

interface OrganzationUsersMap {
  [userId: string]: UserInOrgList | ResponseGetUserApiUsersUserIdGet;
}

export const useOrganizationStore = defineStore('organization', () => {
  const templatesData = ref<FrameworkOverview[] | null>(null);
  const usersData = ref<UserOrgPage | null>(null);
  const usersHashMap = ref<OrganzationUsersMap>({});
  const isLoading = ref(false);
  const taxonomyData = ref<Tags | null>(null);
  const organizationQuota = ref<OrganizationQuota | null>(null);

  const getOrganizationUsers = async (
    pagination: Omit<
      OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest,
      'organizationId'
    > = DEFAULT_PAGINATION,
    options?: RequestConfig,
  ) => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: organization_id,
        ...pagination,
      };

      const response =
        await organizationsApi?.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(
          params,
          options,
        );
      if (!response) return;

      usersData.value = response.data;

      const userHashMap: OrganzationUsersMap = response.data.items.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {} as OrganzationUsersMap);
      usersHashMap.value = userHashMap;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getOrganizationTemplates = async (type: ProjectType) => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const frameworksApi = await initApi(FrameworksApi);

      const params = {
        organizationId: organization_id,
        projectType: type,
      };

      const response =
        await frameworksApi?.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(
          params,
        );
      if (!response) return;

      templatesData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteOrganizationTemplate = async (framework_code: string) => {
    isLoading.value = true;

    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        frameworkCode: framework_code,
        organizationId: organization_id,
      };

      await organizationsApi?.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const uploadOrganizationTemplate = async (file: File) => {
    const user = localStorage.getItem('user');
    if (!user) {
      return;
    }

    try {
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      await organizationsApi?.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(
        { organizationId: organization_id, file: file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateUserAdmin = async (id: string, form: UpdateUserOID) => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: organization_id,
        userId: id,
        updateUserOID: form,
      };

      await organizationsApi?.updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const addOrganizationUser = async (form: InviteUser) => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: organization_id,
        inviteUser: form,
      };

      const response =
        await organizationsApi?.postUsersApiOrganizationsOrganizationIdUsersPost(params);

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const cancelUserInvitations = async (
    params: UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest,
  ) => {
    try {
      const usersApi = await initApi(UsersApi);

      await usersApi?.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const resendUserInvite = async (
    params: UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest,
  ) => {
    try {
      const usersApi = await initApi(UsersApi);

      await usersApi?.resendUserInviteApiUsersUserIdResendInvitePost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateUserStatus = async (updatingUserId: string, status: UserActivationStatus) => {
    try {
      if (!organizationQuota.value) return;

      const organization_id = organizationQuota.value.organization_id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: organization_id,
        userId: updatingUserId,
        userActivationStatus: status,
      };

      const response =
        await organizationsApi?.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(
          params,
        );

      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getOrganizationTaxonomy = async () => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      if (!organization_id) return;

      const params = {
        organizationId: organization_id,
      };

      const response = await organizationsApi?.getTagsApiOrganizationsOrganizationIdTagsGet(params);

      if (!response) return;

      taxonomyData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getOrganizationQouta = async (
    data: OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest,
  ) => {
    try {
      const organizationApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: data.organizationId,
      };

      const response =
        await organizationApi?.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(params);

      if (!response) return;

      organizationQuota.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getOrganizationTemplates,
    templatesData,
    getOrganizationUsers,
    usersData,
    updateUserAdmin,
    addOrganizationUser,
    deleteOrganizationTemplate,
    uploadOrganizationTemplate,
    isLoading,
    getOrganizationTaxonomy,
    taxonomyData,
    getOrganizationQouta,
    organizationQuota,
    usersHashMap,
    updateUserStatus,
    resendUserInvite,
    cancelUserInvitations,
  };
});
