<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import { DEFAULT_THRESHOLD } from '@/composables/CRisk';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import { Roles } from '@/client/api';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useConfigStore } from '@/stores/ConfigStore';

import { PROJECT_FRAMEWORKS } from '@/composables/CProject';
import { RpnLevel } from '@/composables/CRisk';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import DEditUser from '@/components/dialogs/DEditUser.vue';
import DAddUser from '@/components/dialogs/DAddUser.vue';
import MSlider from '@/components/molecules/MSlider.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

interface EditUser {
  id: string;
  role: string;
}

interface Select {
  value: string;
  label: string;
  roles: EditUser[];
}

interface Setting {
  name: string;
  options: string[];
  selected_option: Select;
}

interface RoleSelect {
  value: Roles;
  label: string;
}

const router = useRouter();
const paginationStore = usePaginationStore();
const projectsStore = useProjectsStore();
const organizationStore = useOrganizationStore();
const configStore = useConfigStore();
const activeTab = ref('');
const filter = ref('');
const showDialog = ref(false);
const editedUser = ref<null | Select>(null);
const showAlert = ref(false);
const controlId = ref<string | null>(null);
const showAddDialog = ref(false);

const columnsNames = {
  firstname: 'Name',
  roles: 'Role(s)',
  created_at: 'Created At',
  last_access: 'Last Access',
  edit: '',
};

const stage = ref<Select | null>(null);
const projectSettings = ref<Setting[] | null>([]);
const stagesOptions = ref<Select[]>([]);
const isSaving = ref(false);
const thresholdValue = ref(DEFAULT_THRESHOLD);
const confirmationIsLoading = ref(false);
const roleFilter = ref<RoleSelect | null>(null);
const isLoading = ref(false);
const usersLoading = ref(false);
const roleOptions = ref([
  { value: null, label: 'All' },
  { value: Roles.Owner, label: 'Owner' },
  { value: Roles.Reviewer, label: 'Reviewer' },
  { value: Roles.Editor, label: 'Editor' },
  { value: Roles.Auditor, label: 'Auditor' },
]);

const includeMff1 = computed(() => {
  if (!projectsStore.project || !projectsStore.projectFrameworks) return false;

  return projectsStore.projectFrameworks.some((setting) => {
    return setting.framework_code === PROJECT_FRAMEWORKS.MFF1;
  });
});

async function submitForm() {
  isSaving.value = true;
  try {
    if (!projectSettings.value || !projectsStore.project) return;

    const settingsToSend = projectSettings.value.map((setting) => ({
      ...setting,
      selected_option: setting.selected_option
        ? setting.selected_option.value || setting.selected_option
        : null,
    }));

    const data = {
      ai_lifecycle_stage: stage.value ? stage.value.value : null,
      project_settings: settingsToSend,
      risk_threshold: thresholdValue.value,
    };

    const project = await projectsStore.updateProject({
      projectId: projectsStore.project.id,
      updateProject: data,
    });

    if (project) {
      successMessage(`Project '${project.name}' was updated`);
    }
  } catch (error) {
    captureException(error, {
      message: 'Component: Settings, Function: submitForm',
    });
  } finally {
    isSaving.value = false;
  }
}

function changeTab(value: string) {
  activeTab.value = value;
}

function editUser(user: Select) {
  editedUser.value = user;
  showDialog.value = true;
}

async function closeDialog() {
  showDialog.value = false;

  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectsUsers({
      projectId: projectsStore.project.id,
      ...projectsStore.projectUsers,
      ...DEFAULT_PAGINATION,
      sortBy: 'firstname',
    });
  } catch (error) {
    captureException(error, { message: 'Component: Settings, Function: closeDialog' });
  }
}

async function updateList() {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectsUsers({
      projectId: projectsStore.project.id,
      ...projectsStore.projectUsers,
      ...DEFAULT_PAGINATION,
      sortBy: 'firstname',
    });
  } catch (error) {
    captureException(error, { message: 'Component: ProjectDetailPage, Function: updateList' });
  }
}

function setOptions() {
  if (!organizationStore.taxonomyData) return;

  const stagesIndex = organizationStore.taxonomyData.tags.findIndex(
    (item) => item.tag_name === 'AI System Lifecycle',
  );

  stagesOptions.value = organizationStore.taxonomyData.tags[stagesIndex].tag_values.map((item) => ({
    value: item.tag_value,
    label: item.tag_value,
    roles: [],
  }));
}

function openAlert() {
  showAlert.value = true;
}

function closeAlert() {
  showAlert.value = false;
}

async function deleteProject() {
  if (!projectsStore.project) return;

  try {
    confirmationIsLoading.value = true;
    await projectsStore.deleteProject({ projectId: projectsStore.project.id });

    successMessage(`Project was deleted`);

    router.push('/projects');

    closeAlert();
  } catch (error) {
    captureException(error, { message: 'Component: Settings, Function: deleteProject' });
  } finally {
    confirmationIsLoading.value = false;
  }
}

function redirectTo() {
  if (!projectsStore.project) return;

  router.push({
    path: `/projects/project-detail/${projectsStore.project.id}/controls`,
    query: {
      control: controlId.value,
    },
  });
}

async function updateSliderValue(value: number) {
  thresholdValue.value = value;
}

async function filterUsers() {
  if (!projectsStore.project) return;

  const params = {
    projectId: projectsStore.project.id,
    ...projectsStore.projectUsers,
    ...DEFAULT_PAGINATION,
    roles: roleFilter.value ? [roleFilter.value.value] : [],
    name: filter.value !== '' ? filter.value : null,
    sortBy: 'firstname',
  };

  usersLoading.value = true;

  try {
    await projectsStore.getProjectsUsers(params);
  } catch (error) {
    captureException(error, { message: 'Component: Settings, Function: filterUsers' });
  } finally {
    usersLoading.value = false;
  }
}

async function filterBySearch(value: string) {
  filter.value = value;

  await filterUsers();
}

async function filterByRole() {
  paginationStore.setUsersRolesFilter(roleFilter.value ? [roleFilter.value.value] : []);

  await filterUsers();
}

const userAllowed = computed(() => {
  if (!projectsStore.project) return false;

  return isAdmin() || isProjectOwner();
});

watch(
  () => projectsStore.project,
  (newV) => {
    if (!newV) return;
    /* @ts-expect-error SDK does not generate types correctly */
    projectSettings.value = newV.project_settings || [];
    thresholdValue.value = newV.risk_threshold || DEFAULT_THRESHOLD;
  },
  {
    immediate: true,
  },
);

watch(
  () => organizationStore.taxonomyData,
  () => setOptions(),
  { immediate: true },
);

watch(includeMff1, async (newV) => {
  if (newV) {
    if (!projectsStore.project) return;

    try {
      const response = await projectsStore.getProjectControls({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        name: 'MCF-150',
      });
      if (!response || !response.items[0]) return;

      controlId.value = response.items[0].id;
    } catch (error) {
      captureException(error, { message: 'Component: Settings, Watch: includeMff1' });
    }
  }
});

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    isLoading.value = true;

    if (includeMff1.value) {
      const response = await projectsStore.getProjectControls({
        projectId: projectsStore.project.id,
        ...DEFAULT_PAGINATION,
        name: 'MCF-150',
      });

      if (!response || !response.items[0]) return;

      controlId.value = response.items[0].id;
    }

    setOptions();

    if (!projectsStore.project) return;

    stage.value = projectsStore.project.ai_lifecycle_stage
      ? {
          label: projectsStore.project.ai_lifecycle_stage,
          value: projectsStore.project.ai_lifecycle_stage,
          roles: [],
        }
      : null;
  } catch (error) {
    captureException(error, { message: 'Component: Settings, Hook: onMounted' });
  } finally {
    isLoading.value = false;
  }
});

onBeforeUnmount(() => {
  paginationStore.setUsersRolesFilter(null);
});
</script>
<template>
  <OTabPanels
    v-if="userAllowed"
    :active-tab="activeTab"
    :default-tab="'general-settings'"
    :default-size="true"
    @change-tab="changeTab"
  >
    <template #tabs>
      <q-tab content-class="my-tab" name="general-settings" label="General Settings"> </q-tab>
      <q-tab
        content-class="my-tab"
        name="users-access"
        label="User Access"
        :disable="!projectsStore.projectUsers"
      >
      </q-tab>
      <q-tab content-class="my-tab" name="danger-zone" label="Danger Zone"> </q-tab>
    </template>
    <template #action-row>
      <q-btn
        v-if="
          (configStore.usersEnabled && activeTab === 'users-access') ||
          (isAdmin() && activeTab === 'users-access')
        "
        class="btn-scope"
        icon="add"
        label="Add User to the Project"
        unelevated
        @click="showAddDialog = true"
      />
    </template>
    <template #panels>
      <q-tab-panel name="general-settings">
        <q-form @submit.prevent="submitForm">
          <div class="settings__wrapp row">
            <span class="settings__title">Set and review your project’s general setting here.</span>
            <div class="row col-12 q-mb-md">
              <span class="select__title q-mb-sm col-12">AI Lifecycle Stage</span>
              <q-select
                v-model="stage"
                outlined
                :options="stagesOptions"
                label="Please select a lifecycle stage"
                dense
                :disable="!userAllowed"
                class="col-3"
                :class="[isLoading ? 'skeleton' : '']"
              />
            </div>
            <div class="col-12 q-mb-sm">
              <div v-for="select in projectSettings" :key="select.name" class="row col-12 q-mb-sm">
                <span class="select__title q-mb-sm col-12">{{ select.name }}</span>
                <q-select
                  v-model="select.selected_option"
                  outlined
                  :options="select.options.map((item) => ({ value: item, label: item }))"
                  dense
                  :disable="!userAllowed"
                  class="col-3"
                  :class="[isLoading ? 'skeleton' : '']"
                  label="Please select a risk classification"
                />
              </div>
            </div>

            <div class="row col-12 q-mb-md q-mt-lg items-center">
              <MSlider
                name="Risk Level Threshold"
                class="col-4"
                tooltip-text="Risk level threshold is the expected risk level limit for
                this project risks. This is only editable by owners."
                :class="[isLoading ? 'skeleton' : '']"
                :disable="!userAllowed"
                :slider-value="thresholdValue"
                @update-value="updateSliderValue"
              />
              <q-badge
                class="justify-center q-ml-lg q-mt-md"
                :label="RpnLevel(thresholdValue) || ''"
                :class="[
                  RpnLevel(thresholdValue)
                    ? (RpnLevel(thresholdValue) || '').replaceAll(' ', '-').toLowerCase() + '-badge'
                    : '',
                  isLoading ? 'skeleton' : '',
                ]"
              />
            </div>

            <div v-if="includeMff1 && controlId" class="col-10 row items-center q-mb-md">
              <span class="risk-text q-mr-sm q-mb-sm"
                >Explain and document your reasoning for your chosen EU AI Act Risk Classification
                in this control</span
              >
              <q-badge
                class="control-badge cursor-pointer q-mb-sm"
                label="MCF-150"
                @click="redirectTo"
              >
                <Icon
                  icon-name="in_progress"
                  icon-folder="status"
                  icon-size="20px"
                  class="q-mr-sm"
                />
              </q-badge>
            </div>

            <q-btn
              label="Save Changes"
              type="submit"
              :loading="isSaving"
              :disable="isLoading || isSaving || !userAllowed"
              class="btn-save"
              unelevated
            >
              <q-tooltip v-if="!userAllowed" anchor="top middle" self="bottom middle">
                You are not allowed to update the project. Contact your admin.
              </q-tooltip>
            </q-btn>
          </div>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="users-access" class="user-tab">
        <OTable
          :data="projectsStore.projectUsers?.items"
          :pagination="projectsStore.projectUsers!"
          :columns-names="columnsNames"
          :row-clickable="false"
          :skeleton-size="5"
          :is-loading="isLoading || usersLoading"
          search-placeholder="Search user"
          sort-by="firstname"
          store="projectsStore"
          action="getProjectsUsers"
          @edit-user="editUser"
          @filter-by-search="filterBySearch"
        >
          <template #header-filters>
            <q-select
              v-model="roleFilter"
              outlined
              label="Roles"
              :options="roleOptions"
              dense
              class="role-select"
              :class="[isLoading ? 'skeleton' : '']"
              @update:model-value="filterByRole"
            />
          </template>
        </OTable>
        <div
          v-if="
            projectsStore.projectUsers &&
            projectsStore.projectUsers.items.length === 0 &&
            !isLoading &&
            !usersLoading
          "
          class="empty__wrapp row items-center q-mt-md"
        >
          <AEmptyData
            v-if="!roleFilter"
            icon-name="folder"
            :header="`You don’t have any Users in this Project.`"
            text="Start by adding a User"
            class="full-width items-center"
          />
          <AEmptyData
            v-else
            icon-name="search"
            :header="`No results for your search.`"
            text="Try changing your search."
            class="full-width items-center"
          />
        </div>
      </q-tab-panel>
      <q-tab-panel name="danger-zone">
        <div class="danger__wrapp row items-center justify-between">
          <div class="danger__left column">
            <span class="danger__title">Delete this Project</span>
            <span class="danger__description"
              >Consider your record keeping obligations before proceeding with deletion.</span
            >
          </div>
          <div class="danger__right">
            <q-btn label="Delete Project" unelevated :disable="!userAllowed" @click="openAlert">
              <q-tooltip
                v-if="!userAllowed"
                anchor="top middle"
                self="center middle"
                max-width="200px"
              >
                You are not allowed to delete the project. Contact your admin..
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-tab-panel>
    </template>
  </OTabPanels>
  <ADialog
    :show-dialog="showDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DEditUser v-if="editedUser" :edited-user="editedUser" @close-dialog="closeDialog" />
  </ADialog>
  <ADialog
    :show-dialog="showAlert"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeAlert"
  >
    <DConfirm
      v-if="projectsStore.project"
      title="Delete Project"
      :description="`Would you like to permanently delete project ${projectsStore.project.name}?`"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @confirm="deleteProject"
      @close-dialog="closeAlert"
    />
  </ADialog>
  <ADialog
    :show-dialog="showAddDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showAddDialog = false"
  >
    <DAddUser @close-dialog="showAddDialog = false" @update-data="updateList" />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

span {
  @include heading-04(600, $secondary-600);
}

.btn-scope {
  color: $white;
  background: $secondary-500 !important;
  text-transform: none;
  position: absolute;
  top: 0px;
  right: 0;
}

:deep(.q-tabs) {
  margin: 0;
  .q-tab {
    padding: 0;
    .q-tab__content {
      padding: 4px 10px 15px;
      flex-direction: column-reverse !important;
      align-items: flex-start !important;
      .q-tab__indicator {
        height: 4px;
      }
      .full-width {
        span {
          @include heading-03(600, $secondary-600);
        }
      }
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  .q-tabs__arrow--left,
  .q-tabs__arrow--right {
    display: none !important;
  }
}

:deep(.q-tab-panel) {
  padding: 0;

  .settings__wrapp {
    padding: 20px;
    background: $white;
    border-radius: 5px;
    position: relative;
    span {
      font-size: 14px;
      font-weight: 400;
      color: $secondary-500;
    }
    .settings__title {
      @include paragraph-01(400, $secondary-600);
      margin-bottom: 30px;
    }
    .q-select .q-field__inner {
      border: none !important;
      padding: 0 !important;
    }
    .q-field__native {
      span {
        color: $secondary-600;
      }
    }
    .risk-text {
      color: $secondary-400;
      font-size: 14px;
    }

    .btn-save {
      background: $secondary-500 !important;
      text-transform: none;
      position: absolute;
      bottom: 20px;
      right: 20px;

      span {
        color: $white;
      }
    }
  }

  .danger__wrapp {
    padding: 20px;
    background: $white;
    border: 1px solid #f96f7f;
    border-radius: 5px;
    .danger__title {
      @include heading-05(600, $secondary-500);
      margin-bottom: 10px;
    }
    .danger__description {
      @include paragraph-01(400, $secondary-500);
    }
    .danger__right {
      .q-btn {
        padding: 10px 24px;
        background: transparent !important;
        text-transform: none;
        border: 1px solid #f96f7f;
        span {
          @include paragraph-01(600, #f96f7f);
        }
      }
    }
  }
}
.user-tab {
  :deep(.role-select) {
    width: 150px;
    border-radius: 4px;
    .q-field__inner {
      padding: 0 !important;
      .q-field__control::before {
        border: none !important;
        .q-field__native div {
          display: none !important;
        }
      }
      .q-field__label {
        color: $secondary-400;
      }
    }
  }
}
</style>
