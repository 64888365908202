<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { PropType } from 'vue';

import type { ControlAggregator, RiskAggregator, Tag, TagValues } from '@/client/api';
import { Concept } from '@/client/api';

import { captureException } from '@/composables/Sentry';
import { successMessage } from '@/composables/Notify';

import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useRisksStore } from '@/stores/RisksStore';
import { useTaskStore } from '@/stores/TaskStore';

import Icon from '@/components/atoms/Icon.vue';

interface Type {
  label: string;
  value: string;
}

interface ExistingTag {
  name: string;
  value: string;
}

const emit = defineEmits(['closeDialog']);

const props = defineProps({
  conceptName: {
    type: String as PropType<Concept>,
    required: true,
  },
  concept: {
    type: Object as PropType<RiskAggregator | ControlAggregator>,
    required: true,
  },
  conceptId: {
    type: String,
    required: true,
  },
});

const organizationStore = useOrganizationStore();
const risksStore = useRisksStore();
const taskStore = useTaskStore();
const isLoading = ref(false);
const type = ref<Type>({
  label: 'All',
  value: 'All',
});
const selectedArr = ref<ExistingTag[]>([]);
const tagOptions = ref<ExistingTag[]>([]);

const thumbStyle: Partial<CSSStyleDeclaration> = {
  right: '4px',
  borderRadius: '5px',
  backgroundColor: '#a5b7c0',
  width: '5px',
  opacity: '0.75',
};

const barStyle: Partial<CSSStyleDeclaration> = {
  right: '2px',
  borderRadius: '9px',
  backgroundColor: '#a5b7c0',
  width: '9px',
  opacity: '0.2',
};

// function selectName(tagsArr: ExistingTag[]) {
//   const itemsToAdd: ExistingTag[] = [];
//   const itemsToRemove: number[] = [];

//   tagsArr.forEach((tag) => {
//     const index = selectedArr.value.findIndex((item) => item.value === tag.value);

//     if (index !== -1) {
//       itemsToRemove.push(index);
//     } else {
//       itemsToAdd.push(tag);
//     }
//   });

//   itemsToRemove.reverse().forEach((index) => {
//     selectedArr.value.splice(index, 1);
//   });

//   selectedArr.value.push(...itemsToAdd);
// }

const typeOptions = computed(() => {
  let options = [{ label: 'All', value: 'All' }];

  if (organizationStore.taxonomyData?.tags) {
    options = options.concat(
      organizationStore.taxonomyData.tags
        .filter((tag) => tag.tag_name !== 'EU AI Act Risk Classification')
        .map((tag) => ({
          label: tag.tag_name,
          value: tag.tag_name,
        })),
    );
  }

  return options;
});

function setTagOptions() {
  if (!organizationStore.taxonomyData) return [];

  const isTagSelected = (tagName: string, tagValue: string) => {
    const exist = props.concept.tags.some(
      (existingTag: ExistingTag) => existingTag.value === tagValue,
    );

    const isAlreadyIncluded = selectedArr.value.some((item) => item.value === tagValue);
    if (exist && !isAlreadyIncluded) {
      selectedArr.value.push({ name: tagName, value: tagValue });
    }
  };

  if (type.value.value !== 'All') {
    const tags: Tag[] = organizationStore.taxonomyData.tags.filter((tag: Tag) => {
      if (tag.tag_name === 'EU AI Act Risk Classification') return;
      return tag.tag_name === type.value.value;
    });

    tagOptions.value = tags[0].tag_values.map((tag: TagValues) => {
      isTagSelected(tags[0].tag_name, tag.tag_value);
      return {
        name: tags[0].tag_name,
        value: tag.tag_value,
      };
    });
  } else {
    organizationStore.taxonomyData.tags.forEach((tag: Tag) => {
      if (tag.tag_name === 'EU AI Act Risk Classification') return;

      tagOptions.value.push(
        ...tag.tag_values.map((tagValue: TagValues) => {
          isTagSelected(tag.tag_name, tagValue.tag_value);
          return {
            name: tag.tag_name,
            value: tagValue.tag_value,
          };
        }),
      );
    });
  }
}

function removeSelectedTag(item: ExistingTag) {
  selectedArr.value = selectedArr.value.filter((tag) => tag.value !== item.value);
}

function isSelected(option: ExistingTag) {
  return selectedArr.value.some((selected) => selected.value === option.value);
}

function toggleSelection(option: ExistingTag) {
  let index = selectedArr.value.findIndex((selected) => selected.value === option.value);
  if (index > -1) {
    selectedArr.value.splice(index, 1);
  } else {
    selectedArr.value.push(option);
  }
}

async function addRiskTags() {
  isLoading.value = true;

  try {
    const data = {
      tags: selectedArr.value,
    };

    try {
      if (props.conceptName === Concept.Risk) {
        const params = {
          riskId: props.concept.id,
          updateRisk: data,
        };
        const risk = await risksStore.updateRisk(params);

        successMessage(`Risk '${risk?.name ?? ''}' was updated`);
      } else if (props.conceptName === Concept.Control) {
        const params = {
          controlId: props.concept.id,
          updateControl: data,
        };
        const control = await taskStore.updateTask(params);

        successMessage(`Control '${control?.name ?? ''}' was updated`);
      }
    } catch (error) {
      captureException(error, {
        message: 'Component: DAddRiskTag, Function: addRiskTags',
        data: {
          tags: selectedArr.value,
        },
      });
    }
  } finally {
    isLoading.value = false;
    emit('closeDialog');
  }
}

watch(
  () => type.value,
  () => {
    setTagOptions();
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div v-if="organizationStore.taxonomyData" class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="shoppingmode" icon-folder="colored" class="q-mr-sm" />
      <h5 class="q-ma-none">{{ `Update Tag(s) for ${conceptName} ${concept.name}` }}</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="row description__row q-mb-md">
      <span>{{ `Select tag(s) and add to the ${conceptName}` }}</span>
    </div>
    <div class="row select__row q-mb-md">
      <span class="select__title q-mb-sm">Type</span>
      <q-select v-model="type" outlined :options="typeOptions" dense class="q-mb-md col-12">
      </q-select>
    </div>
    <div class="row select__row q-mb-sm">
      <span class="select__title q-mb-sm">Name</span>
      <q-select
        v-model="selectedArr"
        outlined
        label="Select Tag by Name"
        :options="tagOptions"
        dense
        multiple
        class="q-mb-md col-12"
        hide-selected
      >
        <template #option="scope">
          <q-item v-bind="scope.itemProps">
            <q-checkbox
              :model-value="isSelected(scope.opt)"
              dense
              class="q-mr-sm"
              @update:model-value="toggleSelection(scope.opt)"
            />
            <q-item-section>
              <q-item-label>{{ scope.opt.value }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <hr />
    <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      style="height: 95px; max-width: 100%"
      class="q-my-md"
    >
      <div v-if="selectedArr.length > 0" class="row tags__row">
        <q-badge
          v-for="item in selectedArr"
          :key="item.name"
          class="q-mr-sm q-mb-sm general-badge"
          :label="`${item.name}: ${item.value}`"
          @click="removeSelectedTag(item)"
        >
          <Icon
            icon-name="icon-cancel"
            icon-folder="white"
            class="q-ml-xs cursor-pointer"
            icon-size="12px"
          />
        </q-badge>
      </div>
    </q-scroll-area>

    <div v-if="selectedArr.length > 0" class="row info__row">
      <span>{{ `${selectedArr.length} selected` }}</span>
      <div class="row q-ml-md items-center cursor-pointer" @click="selectedArr = []">
        <Icon icon-name="clear-all" icon-folder="colored" class="q-mr-xs" />
        <span class="bold">Clear All</span>
      </div>
    </div>

    <div class="row action__row full-width justify-end q-mt-md">
      <q-btn
        class="btn-add"
        label="Update Tag(s)"
        :loading="isLoading"
        unelevated
        :disable="!type"
        @click="addRiskTags"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .description__row {
    @include paragraph-01(400, $secondary-600);
  }
  .select__row {
    .select__title {
      @include caption(400, $secondary-500);
    }
  }

  .info__row {
    span {
      @include paragraph-01(400, $secondary-500);
    }
    .bold {
      color: $secondary-400 !important;
      font-weight: 700;
    }
  }

  hr {
    width: 100%;
    border: 1px solid $secondary-200;
  }
  .btn-add {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
