import { ref } from 'vue';
import { defineStore } from 'pinia';
import initApi from '@/client_heplers/utils';

import { OrganizationsApi, FrameworksApi } from '@/client/api';
import type {
  CreationFrameworkTemplate,
  FrameworkDetailed,
  ProjectType,
  FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { AxiosError } from 'axios';

export const useFrameworkStore = defineStore('framework', () => {
  const frameworksData = ref<CreationFrameworkTemplate[] | null>(null);
  const frameworkData = ref<FrameworkDetailed | null>(null);

  const getFrameworks = async (type: ProjectType) => {
    try {
      const organizationApi = await initApi(OrganizationsApi);
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;

      const params = {
        organizationId: organization_id,
        projectType: type,
      };

      const response =
        await organizationApi?.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(
          params,
        );

      if (!response) return;

      frameworksData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getFramework = async (
    params: FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest,
  ) => {
    try {
      const frameworksApi = await initApi(FrameworksApi);

      const response =
        await frameworksApi?.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(
          params,
        );

      if (!response) return;

      frameworkData.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    frameworksData,
    getFrameworks,
    frameworkData,
    getFramework,
  };
});
