<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import type { PropType } from 'vue';

import { useAuthStore } from '@/stores/AuthStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { successMessage } from '@/composables/Notify';
import { roleDescription, UpperCaseRoles } from '@/composables/Auth';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import type { Roles } from '@/client/api';

import ATooltip from '@/components/atoms/ATooltip.vue';
import Icon from '@/components/atoms/Icon.vue';

interface EditUser {
  id: string;
  role: string;
}

interface Select {
  value: string;
  label: string;
  roles: EditUser[];
}

const emit = defineEmits(['closeDialog']);

const props = defineProps({
  editedUser: {
    type: Object as PropType<Select>,
    default: null,
  },
});

const authStore = useAuthStore();
const projectsStore = useProjectsStore();
const user = ref<null | Select>(null);
const selected = ref<Roles[]>([]);
const roles = ref([
  UpperCaseRoles.Owner,
  UpperCaseRoles.Auditor,
  UpperCaseRoles.Editor,
  UpperCaseRoles.Reviewer,
]);
const isLoading = ref(false);

async function updateUser() {
  if (!projectsStore.project || !user.value) return;

  isLoading.value = true;

  const newArr = convertFirstLetterToLowercase(selected.value);
  const params = {
    userId: user.value.value,
    projectId: projectsStore.project.id,
    roles: newArr,
  };

  try {
    await projectsStore.updateUserRole(params);

    successMessage('User role(s) updated successfully');

    if (authStore.user && authStore.user.id === user.value.value) {
      authStore.getMe();
    }

    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DEditUser, Function: updateUser',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

async function removeAccess() {
  if (!projectsStore.project || !user.value) return;

  isLoading.value = true;
  selected.value = [];

  const params = {
    userId: user.value.value,
    projectId: projectsStore.project.id,
    roles: selected.value,
  };

  try {
    await projectsStore.updateUserRole(params);

    successMessage('User role(s) updated successfully');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DEditUser, Function: removeAccess',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

function convertFirstLetterToLowercase(arr: string[]): Roles[] {
  return arr.map(
    (word: string) =>
      word
        .split(' ')
        .map((w) => w.charAt(0).toLowerCase() + w.slice(1))
        .join(' ') as Roles,
  );
}

function capitalizeFirstLetter(role: string) {
  return role.charAt(0).toUpperCase() + role.slice(1);
}

function selectUser(value: Select) {
  const roles = value.roles.map((user) => capitalizeFirstLetter(user.role));

  selected.value = roles.filter((role: string) => roles.includes(role)) as Roles[];
}

const isSelectedMe = computed(() => {
  if (!user.value) return;

  const meString = localStorage.getItem('user');

  if (!meString) return;

  const me = JSON.parse(meString);

  return user.value.value === me.id;
});

onMounted(() => {
  user.value = props.editedUser;
  selectUser(props.editedUser);
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person_add" icon-folder="settings" class="q-mr-md" />
      <h5 class="q-ma-none">Edit User Access</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="column select__row">
      <div class="row items-center q-mb-sm">
        <span>User</span>
        <ATooltip
          icon-folder="settings"
          icon-name="info-medium"
          tooltip-text="Users previously added in the organisation level. "
          class="q-ml-sm"
        />
      </div>
      <div class="row">
        <q-select
          v-if="projectsStore.projectUsers"
          v-model="user"
          outlined
          label="Select an User"
          :options="
            projectsStore.projectUsers.items.map((user) => ({
              label: user.firstname + ' ' + user.lastname,
              value: user.id,
              roles: user.roles,
            }))
          "
          dense
          class="col-12"
          @update:model-value="selectUser"
        />
      </div>
    </div>
    <div class="column roles__row q-my-md">
      <span class="q-mb-sm">Roles</span>
      <div class="row roles__container" :class="[user ? '' : 'disabled']">
        <div v-for="role in roles" :key="role" class="role row q-mr-sm">
          <q-checkbox
            v-model="selected"
            :val="role"
            :disable="!user || (isSelectedMe && role === UpperCaseRoles.Owner)"
            size="16px"
          />
          <span class="role__text q-mx-sm">{{ role }}</span>
          <ATooltip
            icon-folder="settings"
            icon-name="info-medium"
            :tooltip-text="roleDescription(role)"
          />
        </div>
      </div>
    </div>
    <div class="row action__row full-width justify-end q-mt-lg">
      <q-btn
        v-if="isProjectOwner() || isAdmin()"
        class="btn-remove q-mr-md"
        label="Remove user from project"
        :disable="!user || isLoading || isSelectedMe"
        :loading="isLoading"
        unelevated
        @click="removeAccess"
      />
      <q-btn
        class="btn-save"
        label="Save"
        :disable="!user || selected.length === 0 || isLoading || (!isProjectOwner() && !isAdmin())"
        :loading="isLoading"
        unelevated
        @click="updateUser"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  .roles__row {
    .role {
      span {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-600;
      }
      .q-checkbox {
        :deep(.q-checkbox__bg) {
          width: 100%;
          height: 100%;
          position: unset;
        }

        :deep(.q-checkbox__inner--falsy) {
          .q-checkbox__bg {
            color: $common-1;
          }
        }
        :deep(.q-checkbox__inner--truthy) {
          .q-checkbox__bg {
            color: $secondary-800;
          }
        }
        :deep(.q-checkbox__label) {
          @include paragraph-02(600, $common-5);
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .btn-remove {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
