<script setup lang="ts">
import type { Concept, RiskAggregator, UserDisplay } from '@/client/api';
import type { PropType } from 'vue';

import { useAuthStore } from '@/stores/AuthStore';

import OComments from '@/components/organisms/OComments.vue';

import { Status } from '@/composables/utils';

const emit = defineEmits(['updateConcept', 'reassignReviewer']);

const props = defineProps({
  risk: {
    type: Object as PropType<RiskAggregator>,
    required: true,
  },
  riskIsLoading: {
    type: Boolean,
  },
  reviewers: {
    type: Array as PropType<UserDisplay[]>,
    default: () => [],
  },
});

const authStore = useAuthStore();
</script>
<template>
  <div>
    <OComments
      v-if="props.risk && authStore.user"
      :object-type="'risk' as Concept"
      :reviewer="
        reviewers &&
        reviewers.length > 0 &&
        reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id)
      "
      :in-review="props.risk.status === Status.IN_REVIEW"
      :object-id="props.risk.id"
      :editable="!props.risk.is_locked"
      class="coomments__wrapp"
      @update-concept="emit('updateConcept')"
      @reassign-reviewer="emit('reassignReviewer')"
    />
  </div>
</template>
