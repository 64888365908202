<script setup lang="ts">
import { ref } from 'vue';

import type { PropType } from 'vue';
import type { UserInOrgList } from '@/client/api';
import { UserInviteStatus } from '@/client/api';

import { useOrganizationStore } from '@/stores/OrganizationStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { lastInvite, formatDate } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';

const emit = defineEmits(['closeDialog', 'updateUsers']);

const props = defineProps({
  infoUser: {
    type: Object as PropType<UserInOrgList>,
    required: true,
  },
});

const isLoading = ref(false);
const organizationStore = useOrganizationStore();
const showConfirmResend = ref(false);
const showConfirmCancell = ref(false);

async function resendInvitation() {
  const params = {
    userId: props.infoUser.id,
  };

  try {
    isLoading.value = true;

    await organizationStore.resendUserInvite(params);
    successMessage(
      `Invitation to “${props.infoUser.firstname} ${props.infoUser.lastname}“ is successfully resent.`,
    );
    emit('updateUsers');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DInvitedUser, Function: resendInvitation',
      data: params,
    });
  } finally {
    isLoading.value = false;
  }
}

async function cancelInvitation() {
  const params = {
    userId: props.infoUser.id,
  };

  try {
    isLoading.value = true;

    await organizationStore.cancelUserInvitations(params);
    successMessage(
      `Invitation to “${props.infoUser.firstname} ${props.infoUser.lastname}“ is successfully cancelled.`,
    );
    emit('updateUsers');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DInvitedUser, Function: cancelInvitation',
      data: params,
    });
  } finally {
    isLoading.value = false;
  }
}

function closeDialog() {
  showConfirmResend.value = false;
  showConfirmCancell.value = false;
}
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="person" icon-folder="organization" class="q-mr-md" />
      <h5 class="q-ma-none">User</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="row">
      <div class="row col-12 q-mb-lg q-col-gutter-sm">
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Name</span>
          <span class="col-12 text-value">{{ infoUser.firstname }}</span>
        </div>
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Last Name</span>
          <span class="col-12 text-value">{{ infoUser.lastname }}</span>
        </div>
      </div>
      <div class="row col-12 q-mb-lg q-col-gutter-sm">
        <div class="row col-4">
          <span class="col-12 q-mb-sm">E-mail</span>
          <span class="col-12 text-value"
            >{{ infoUser.email }}

            <q-tooltip anchor="top middle" self="center middle" class="project-tooltip">
              {{ infoUser.email }}
            </q-tooltip>
          </span>
        </div>
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Job Title</span>
          <span class="col-12 text-value">{{ infoUser.job_title || '-' }}</span>
        </div>
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Organization Admin</span>
          <span class="col-12 text-value">{{ infoUser.is_org_admin ? 'yes' : 'no' }}</span>
        </div>
      </div>
      <div class="row col-12 q-mb-lg q-col-gutter-sm">
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Invitation Sent</span>
          <span v-if="infoUser.invites" class="col-12 text-value">{{
            formatDate(lastInvite(infoUser.invites).created_at)
          }}</span>
          <span v-else>-</span>
        </div>
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Invitation Expires At</span>
          <span v-if="infoUser.invites" class="col-12 text-value">{{
            formatDate(lastInvite(infoUser.invites).expires_at)
          }}</span>
          <span v-else>-</span>
        </div>
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Status</span>
          <q-badge
            v-if="lastInvite(infoUser.invites)"
            :class="'invitation-' + lastInvite(infoUser.invites).status + '-badge'"
            :label="lastInvite(infoUser.invites).status"
          >
            <Icon
              v-if="lastInvite(infoUser.invites).status === UserInviteStatus.Pending"
              icon-name="invitation-pending"
              icon-folder="table"
              class="q-mr-xs"
            />
            <Icon
              v-if="lastInvite(infoUser.invites).status === UserInviteStatus.Cancelled"
              icon-name="invitation-cancelled"
              icon-folder="table"
              class="q-mr-xs"
            />
            <q-tooltip anchor="top middle" self="center middle" class="project-tooltip">
              {{
                lastInvite(infoUser.invites).status === UserInviteStatus.Pending
                  ? 'Pending Invitation'
                  : 'Cancelled Invitation'
              }}
            </q-tooltip>
          </q-badge>
          <span v-else>-</span>
        </div>
      </div>
    </div>
    <div class="row action__row full-width items-center justify-end q-mt-lg">
      <q-btn
        v-if="
          lastInvite(infoUser.invites) &&
          lastInvite(infoUser.invites).status !== UserInviteStatus.Cancelled
        "
        class="btn-invitation q-mr-md"
        label="Cancel Invitation"
        :loading="isLoading"
        :disable="isLoading"
        unelevated
        @click="showConfirmCancell = true"
      >
        <Icon icon-name="cancel-invitation" icon-folder="organization" class="q-mr-sm" />
      </q-btn>
      <q-btn
        class="btn-invitation"
        label="Resend Invitation"
        :loading="isLoading"
        :disable="isLoading"
        unelevated
        @click="showConfirmResend = true"
      >
        <Icon icon-name="forward_to_inbox" icon-folder="organization" class="q-mr-sm" />
      </q-btn>
    </div>
    <ADialog
      :show-dialog="showConfirmCancell"
      max-width="460px !important"
      min-width="460px !important"
      min-height="auto"
      @hide="closeDialog"
    >
      <DConfirm
        :title="`Would you like to cancel the invitation to ${infoUser?.firstname} ${infoUser?.lastname}?`"
        :description="'This will cancel the invitation link.'"
        button-text="Confirm"
        :custom-icon="true"
        :loding="isLoading"
        @close-dialog="closeDialog"
        @confirm="cancelInvitation"
      >
        <template #custom-icon>
          <Icon icon-name="cancel-inv-alert" icon-folder="organization" />
        </template>
      </DConfirm>
    </ADialog>
    <ADialog
      :show-dialog="showConfirmResend"
      max-width="460px !important"
      min-width="460px !important"
      min-height="auto"
      @hide="closeDialog"
    >
      <DConfirm
        :title="`Would you like to resend the invitation to ${infoUser?.firstname} ${infoUser?.lastname}?`"
        :description="`After sending this invitation the invited user will be listed as pending.`"
        button-text="Confirm"
        :custom-icon="true"
        :loding="isLoading"
        @close-dialog="closeDialog"
        @confirm="resendInvitation"
      >
        <template #custom-icon>
          <Icon icon-name="forward_to_inbox" icon-folder="organization" />
        </template>
      </DConfirm>
    </ADialog>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    font-weight: 400;
    color: $secondary-500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .text-value {
    color: $common-5;
  }

  .btn-invitation {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    :deep(.q-btn__content) {
      flex-direction: row-reverse;
    }
  }
}
</style>
