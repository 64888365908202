<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import type { CustomNotificationPage, Notification } from '@/client/api';

import { NotificationStatus } from '@/client/api';

import { useNotificationStore } from '@/stores/NotificationStore';
import { usePaginationStore } from '@/stores/PaginationStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import MNotification from '@/components/molecules/MNotification.vue';
import MPagination from '@/components/molecules/MPagination.vue';
import { DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

interface NotificationElement {
  selectCheckbox: () => void;
  unselectCheckbox: () => void;
}

const props = defineProps({
  topActions: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object as () => CustomNotificationPage,
    default: null,
  },
});

const paginationStore = usePaginationStore();
const notificationStore = useNotificationStore();
const route = useRoute();
const filter = ref('');
const selectAll = ref(false);
const showAll = ref(route.query.status ? false : true);
const isLoading = ref(false);
const showButtons = ref(false);
const elementsPerPage = ref('6/page');
const selectedItems = ref<string[]>([]);
const todayNotification = ref<NotificationElement | null>(null);
const yestedayNotification = ref<NotificationElement | null>(null);
const oldNotification = ref<NotificationElement | null>(null);
const isUpdating = ref(false);

async function updateSize() {
  const params = {
    name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
    page: 1,
    size: Number(elementsPerPage.value.replace('/page', '')),
  };

  await notificationStore.getNotifications(params);
}

function toggleAll() {
  if (!props.data) return;

  showButtons.value = false;

  selectedItems.value = selectAll.value
    ? props.data?.items.map((item: Notification) => item.id)
    : [];

  if (selectAll.value) {
    toggleToday('select');
    toggleYesterday('select');
    toggleOlder('select');
  } else {
    toggleToday('unselect');
    toggleYesterday('unselect');
    toggleOlder('unselect');
  }
}

function uncheckAll() {
  if (!props.data) return;

  selectedItems.value = [];

  toggleToday('unselect');
  toggleYesterday('unselect');
  toggleOlder('unselect');
}

function toggleToday(value: string) {
  if (!todayNotification.value) return;
  if (Array.isArray(todayNotification.value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    todayNotification.value.forEach((notification: any) => {
      if (value === 'select') {
        notification.selectCheckbox();
      } else {
        notification.unselectCheckbox();
      }
    });
  }
}

function toggleYesterday(value: string) {
  if (!yestedayNotification.value) return;
  if (Array.isArray(yestedayNotification.value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yestedayNotification.value.forEach((notification: any) => {
      if (value === 'select') {
        notification.selectCheckbox();
      } else {
        notification.unselectCheckbox();
      }
    });
  }
}

function toggleOlder(value: string) {
  if (!oldNotification.value) return;
  if (Array.isArray(oldNotification.value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oldNotification.value.forEach((notification: any) => {
      if (value === 'select') {
        notification.selectCheckbox();
      } else {
        notification.unselectCheckbox();
      }
    });
  }
}

async function showSpecific(status: NotificationStatus | null) {
  isLoading.value = true;
  try {
    if (status === null) {
      showAll.value = true;
      paginationStore.setStatusFilter(null);
      paginationStore.setPage(1);

      const params = {
        name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
        page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
        size: Number(elementsPerPage.value.replace('/page', '')),
      };

      await notificationStore.getNotifications(params);
    } else {
      showAll.value = false;
      paginationStore.setStatusFilter(status);
      paginationStore.setPage(1);

      const params = {
        notificationStatus: status,
        name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
        page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
        size: Number(elementsPerPage.value.replace('/page', '')),
      };

      await notificationStore.getNotifications(params);
    }
  } catch (error) {
    captureException(error, {
      message: 'Component: ONotifications, Function: showSpecific',
    });
  } finally {
    isLoading.value = false;
  }
}

async function inputFilter() {
  selectAll.value = false;

  paginationStore.setNameFilter(filter.value);
  paginationStore.setPage(1);

  const params = {
    notificationStatus: paginationStore.statusFilter,
    name: filter.value !== '' ? filter.value : null,
    page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
    size: Number(elementsPerPage.value.replace('/page', '')),
  };

  try {
    await notificationStore.getNotifications(params);
  } catch (error) {
    captureException(error, {
      message: 'Component: ONotifications, Function: inputFilter',
      data: {
        params: params,
      },
    });
  }
}

async function bulkUpdate(status: NotificationStatus) {
  try {
    isUpdating.value = true;

    const params = {
      notificationStatus: status,
      notificationIdList: { ids: selectedItems.value },
    };

    try {
      await notificationStore.updateBulkNotifications(params);

      successMessage(`Notification updated as ${status} successfully`);
    } catch (error) {
      captureException(error, {
        message: 'Component: ONotifications, Function: bulkUpdate',
        data: {
          params: params,
        },
      });
    }
  } finally {
    isUpdating.value = false;
  }

  if (!showButtons.value) {
    selectAll.value = false;
    toggleAll();
  } else {
    showButtons.value = false;
    uncheckAll();
  }

  if (status == NotificationStatus.Read) {
    const params = {
      notificationStatus: paginationStore.statusFilter,
      name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
      page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
      size: notificationStore.notificationsData?.size ?? DEFAULT_PAGINATION_SIZE,
    };

    try {
      await notificationStore.getNotifications(params);
    } catch (error) {
      captureException(error, {
        message: 'Component: ONotifications, Function: bulkUpdate',
        data: {
          params: params,
        },
      });
    }
  } else {
    paginationStore.setStatusFilter(null);
    paginationStore.setPage(1);

    const params = {
      notificationStatus: paginationStore.statusFilter,
      name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
      page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
      size: notificationStore.notificationsData?.size ?? DEFAULT_PAGINATION_SIZE,
    };

    try {
      await notificationStore.getNotifications(params);
    } catch (error) {
      captureException(error, {
        message: 'Component: ONotifications, Function: bulkUpdate',
        data: {
          params: params,
        },
      });
    }
  }
}

function selectNotification(id: string, selected: boolean) {
  if (selected) {
    selectedItems.value.push(id);
  } else {
    selectedItems.value = selectedItems.value.filter((item) => item !== id);
  }

  showButtons.value = selectedItems.value.length > 0;
  selectAll.value = false;
}

const todayItems = computed(() => {
  if (!notificationStore.notificationsData) return;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return notificationStore.notificationsData.items.filter((item) => {
    const createdAt = new Date(item.created_at);
    createdAt.setHours(0, 0, 0, 0);
    return createdAt.getTime() === today.getTime();
  });
});

const yesterdayItems = computed(() => {
  if (!notificationStore.notificationsData) return;

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  return notificationStore.notificationsData.items.filter((item) => {
    const createdAt = new Date(item.created_at);
    createdAt.setHours(0, 0, 0, 0);
    return createdAt.getTime() === yesterday.getTime();
  });
});

const olderItems = computed(() => {
  if (!notificationStore.notificationsData) return;

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  return notificationStore.notificationsData.items.filter((item) => {
    const createdAt = new Date(item.created_at);
    createdAt.setHours(0, 0, 0, 0);
    return createdAt.getTime() < yesterday.getTime();
  });
});
</script>

<template>
  <div v-if="data">
    <div class="search-filter__container row justify-between q-mb-md">
      <div v-if="topActions" class="filters-sorts__container row items-center">
        <q-btn-group unelevated class="q-mr-md">
          <q-btn
            label="All"
            :class="[showAll ? 'active' : '']"
            :loading="isLoading"
            :disable="isLoading"
            @click="showSpecific(null)"
          />
          <q-btn
            label="Unread"
            :class="[showAll ? '' : 'active']"
            :loading="isLoading"
            :disable="isLoading"
            @click="showSpecific(NotificationStatus.Unread)"
          />
        </q-btn-group>

        <q-input
          v-model="filter"
          borderless
          dense
          debounce="300"
          placeholder="Search"
          class="q-mr-md"
          @update:model-value="inputFilter"
        >
          <template #append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <div class="notifications__container q-mb-md">
      <div
        v-if="data.items.length > 0"
        class="notifications__header row justify-between items-center"
      >
        <div class="header__left row">
          <q-checkbox
            v-model="selectAll"
            :label="selectAll ? '' : 'Select all'"
            size="16px"
            class="q-mr-md"
            @update:model-value="toggleAll"
          />
          <q-btn
            v-if="selectAll || showButtons"
            label="Mark as read"
            class="q-mr-md"
            unelevated
            :loading="isUpdating"
            :disable="isUpdating"
            @click="bulkUpdate(NotificationStatus.Read)"
          >
            <Icon icon-name="check-light" icon-folder="notifications" />
          </q-btn>
          <q-btn
            v-if="selectAll || showButtons"
            label="Delete"
            unelevated
            @click="bulkUpdate(NotificationStatus.Archived)"
          >
            <Icon icon-name="delete-light" icon-folder="notifications" />
          </q-btn>
        </div>
        <div class="new__notifactions row items-center">
          <Icon icon-name="icon-dot" icon-folder="black" class="q-mr-sm" />
          <span>
            {{ data.nr_unread_notifications }}
            {{
              data.nr_unread_notifications === 1 ? 'unread notification' : 'unread notifications'
            }}
          </span>
        </div>
      </div>
      <div v-if="data.items.length > 0" class="sections">
        <div v-if="todayItems && todayItems.length > 0" class="section">
          <div class="section__title">Today</div>
          <div class="notifications__list column">
            <MNotification
              v-for="item in todayItems"
              :key="item.id"
              ref="todayNotification"
              :data="item"
              @select-notification="selectNotification"
            />
          </div>
        </div>
        <div v-if="yesterdayItems && yesterdayItems.length > 0" class="section column">
          <div class="section__title">Yesterday</div>
          <div class="notifications__list column">
            <MNotification
              v-for="item in yesterdayItems"
              :key="item.id"
              ref="yestedayNotification"
              :data="item"
              @select-notification="selectNotification"
            />
          </div>
        </div>
        <div v-if="olderItems && olderItems.length > 0" class="section column">
          <div class="section__title">Older</div>
          <div class="notifications__list column">
            <MNotification
              v-for="item in olderItems"
              :key="item.id"
              ref="oldNotification"
              :data="item"
              @select-notification="selectNotification"
            />
          </div>
        </div>
      </div>
      <div v-else class="full-width row justify-center">
        <AEmptyData
          v-if="!filter"
          icon-name="folder"
          :header="`You don’t have any notifications yet.`"
          text=""
          action-text=""
          class="full-width items-center"
        >
        </AEmptyData>
        <AEmptyData
          v-else
          icon-name="search"
          :header="`No results for your search.`"
          text="Try changing your search."
          class="full-width items-center"
        />
      </div>
    </div>
    <div v-if="data && data.items.length > 0" class="pagination__wrapp row justify-between">
      <div class="row items-center">
        <span class="q-mr-md">Row per page</span>
        <q-select
          v-model="elementsPerPage"
          outlined
          :options="['6/page', '9/page', '12/page', '24/page']"
          dense
          @update:model-value="updateSize"
        />
      </div>
      <MPagination
        :pagination="data"
        store="notificationStore"
        action="getNotifications"
        @update-specific="selectAll = false"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.search-filter__container {
  padding: 20px;
  background: $white;
  border-radius: 5px;

  :deep(.q-btn-group) {
    height: 100%;
    button {
      border: 2px solid $common-1;
      background: transparent !important;
      color: $black;
      font-size: 14px;
      .q-focus-helper {
        border-radius: unset !important;
      }
    }
    button:first-child {
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    button:last-child {
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .active {
      border: none;
      background: $secondary-800 !important;
      color: $white;
    }
  }

  :deep(button) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
  :deep(a) {
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
  :deep(.q-select) {
    .q-field__inner {
      padding: 0 !important;
      border: none !important;
    }
  }
}
.notifications__container {
  background: $white;
  padding: 34px 0;
  border-radius: 5px;
  .notifications__header {
    padding: 0 30px 20px 30px;
    .q-checkbox {
      :deep(.q-checkbox__bg) {
        width: 100%;
        height: 100%;
        position: unset;
      }

      :deep(.q-checkbox__inner--falsy) {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      :deep(.q-checkbox__inner--truthy) {
        .q-checkbox__bg {
          color: $secondary-800;
        }
      }
      :deep(.q-checkbox__label) {
        @include paragraph-02(600, $common-5);
        font-size: 16px;
        margin-left: 8px;
      }
    }
    span {
      color: $common-5;
      opacity: 0.7;
      font-size: 14px;
      font-weight: 700;
    }
    .header__left {
      button {
        border: 1px solid $secondary-500;
        border-radius: 5px;
        :deep(.q-btn__content) {
          flex-direction: row-reverse;
          svg {
            margin-right: 8px;
          }
          span {
            @include paragraph-01(600, $secondary-500);
            text-transform: none;
          }
        }
      }
    }
  }
  .sections {
    .section {
      .section__title {
        padding: 20px 30px;
        @include caption(600, $black);
        text-transform: uppercase;
      }
    }
    .section:not(:last-child) {
      border-bottom: 1px solid #e1e7ea;
    }
  }
  b {
    @include paragraph-02(600, $secondary-800);
    margin: 0 auto;
  }
}
.pagination__wrapp {
  padding: 20px;
  background: $white;
  border-radius: 5px;
  span {
    color: $common-2;
    font-size: 14px;
  }
}
</style>
