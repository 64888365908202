<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

import { useNotificationStore } from '@/stores/NotificationStore';
import { usePaginationStore } from '@/stores/PaginationStore';

import { statusIcon } from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import type { PropType } from 'vue';
import type { Notification } from '@/client/api';

import { Concept, NotificationStatus } from '@/client/api';

import Icon from '@/components/atoms/Icon.vue';
import { DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

const props = defineProps({
  data: {
    type: Object as PropType<Notification>,
    default: null,
  },
});

const emit = defineEmits(['selectNotification']);

const router = useRouter();
const paginationStore = usePaginationStore();
const notificationStore = useNotificationStore();
const selected = ref(false);
const hovered = ref(false);
const isUpdating = ref(false);

function selectCheckbox() {
  selected.value = true;
}

function unselectCheckbox() {
  selected.value = false;
}

function getTimeAgo(created_at: string) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const createdAt = dayjs.utc(created_at).tz(userTimezone);
  const now = dayjs().tz(userTimezone);
  const diffDuration = dayjs.duration(now.diff(createdAt));

  if (diffDuration.asDays() >= 1) {
    return `${Math.floor(diffDuration.asDays())} day${diffDuration.asDays() >= 2 ? 's' : ''} ago`;
  } else if (diffDuration.asHours() >= 1) {
    return `${Math.floor(diffDuration.asHours())} hour${diffDuration.asHours() >= 2 ? 's' : ''} ago`;
  } else if (diffDuration.asMinutes() >= 1) {
    return `${Math.floor(diffDuration.asMinutes())} minute${diffDuration.asMinutes() >= 2 ? 's' : ''} ago`;
  } else {
    return `${Math.floor(diffDuration.asSeconds())} second${diffDuration.asSeconds() >= 2 ? 's' : ''} ago`;
  }
}

async function updateNotification(status: NotificationStatus) {
  try {
    const params = {
      notificationId: props.data.id,
      notificationStatus: status,
    };
    isUpdating.value = true;

    await notificationStore.updateNotification(params);

    successMessage(`Notification updated as ${status} successfully`);

    const getParams = {
      notificationStatus: paginationStore.statusFilter,
      name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
      page: paginationStore.pageFilter ? paginationStore.pageFilter : 1,
      size: notificationStore.notificationsData?.size || DEFAULT_PAGINATION_SIZE,
    };

    await notificationStore.getNotifications(getParams);
  } catch (error) {
    captureException(error, {
      message: 'Component: MNotification, Function: updateNotification',
    });
  } finally {
    isUpdating.value = false;
  }
}

function navigateTo() {
  if (!props.data.object) return;

  if (isNotificationProject(props.data)) {
    router.push({
      path: `/projects/project-detail/${props.data.object.id}/settings`,
    });
  }
  if (isNotificationControl(props.data)) {
    router.push({
      path: `/projects/project-detail/${props.data.object.project_id}/controls`,
      query: {
        [props.data.object_type as string]: props.data.object.id,
      },
    });
  }
  if (isNotificationRisk(props.data)) {
    router.push({
      path: `/projects/project-detail/${props.data.object.project_id}/risk-detail/${props.data.object.id}`,
    });
  }
  if (isNotificationAsset(props.data)) {
    router.push({
      path: `/projects/project-detail/${props.data.object.project_id}/asset-detail/${props.data.object.id}`,
    });
  }
  if (isNotificationTestTesting(props.data)) {
    router.push({
      path: `/projects/project-detail/${props.data.object.project_id}/test-detail/${props.data.object.id}`,
    });
  }
  updateNotification(NotificationStatus.Read);
}

function selectNotification() {
  emit('selectNotification', props.data.id, selected.value);
}

function isNotificationAsset(data: Notification) {
  return data.object_type === Concept.AssetCard;
}

function isNotificationControl(data: Notification) {
  return data.object_type === Concept.Control;
}

function isNotificationRisk(data: Notification) {
  return data.object_type === Concept.Risk;
}

function isNotificationProject(data: Notification) {
  return data.object_type === Concept.Project;
}

function isNotificationTestTesting(data: Notification) {
  return data.object_type === Concept.TestingTest;
}

defineExpose({
  selectCheckbox,
  unselectCheckbox,
});
</script>

<template>
  <div
    v-if="data && data.object"
    class="notification__container row justify-between items-center full-width"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="navigateTo"
  >
    <div class="notification__left row items-start col-8">
      <div class="small-items row items-center">
        <Icon
          v-if="data.status === NotificationStatus.Unread"
          icon-name="icon-dot"
          icon-folder="black"
          class="q-mr-sm"
        />
        <q-checkbox
          v-model="selected"
          class="q-mr-sm"
          size="16px"
          :style="{ marginLeft: data.status === NotificationStatus.Unread ? 0 : '18px' }"
          @update:model-value="selectNotification"
        />
        <Icon
          v-if="data.object && data.object.status"
          :icon-name="statusIcon(data.object.status)"
          icon-folder="status"
          icon-size="20px"
          class="q-mr-sm"
        />
      </div>
      <div class="notification__info row col-8">
        <b class="q-mb-sm col-12">{{ data.content }}</b>
        <span v-if="isNotificationControl(data)" class="col-12">{{
          data.object.name + ' ' + data.object.control_code
        }}</span>
        <span v-else class="col-12">{{ data.object.name }}</span>
      </div>
    </div>
    <div class="notification__right row items-center justify-between">
      <div class="badges__container row">
        <q-badge class="notification-badge q-mr-sm" :label="data.tag as string" />
      </div>
      <span v-if="!hovered">{{ getTimeAgo(data.created_at) }}</span>
      <div v-if="hovered" class="row">
        <q-btn
          v-if="data.status === NotificationStatus.Unread"
          class="q-mr-md"
          unelevated
          :loading="isUpdating"
          :disable="isUpdating"
          @click.stop="updateNotification(NotificationStatus.Read)"
        >
          <Icon icon-name="check" icon-folder="notifications" />
        </q-btn>
        <q-btn unelevated>
          <Icon
            icon-name="delete"
            icon-folder="notifications"
            :loading="isUpdating"
            :disable="isUpdating"
            @click.stop="updateNotification(NotificationStatus.Archived)"
          />
        </q-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.notification__container {
  cursor: pointer;
  padding: 10px 30px 10px 27px;
  border-left: 3px solid transparent;
  b {
    color: $common-5;
    font-size: 16px;
  }
  span {
    color: $common-5;
    font-size: 14px;
    opacity: 0.7;
  }
  .q-checkbox {
    :deep(.q-checkbox__bg) {
      width: 100%;
      height: 100%;
      position: unset;
    }
    :deep(.q-checkbox__inner--falsy) {
      .q-checkbox__bg {
        color: $common-1;
      }
    }
    :deep(.q-checkbox__inner--truthy) {
      .q-checkbox__bg {
        color: $secondary-800;
      }
    }
    :deep(.q-checkbox__label) {
      @include paragraph-02(600, $common-5);
      font-size: 16px;
    }
  }

  .notification__right {
    min-width: 250px;
    button {
      padding: 8px;
      background: $secondary-200;
    }
  }
}

.notification__container:hover {
  background: #e1e7ea;
  border-left: 3px solid #6d96c7;
}
</style>
