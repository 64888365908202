<script setup lang="ts">
import { useQuasar } from 'quasar';
import type { PropType } from 'vue';

import type { TestingTestResult } from '@/client/api';
import { TestingTestStatus } from '@/client/api';

import { formatDate } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['closeDialog']);

const props = defineProps({
  selectedTest: {
    type: Object as PropType<TestingTestResult>,
    required: true,
  },
});

const $q = useQuasar();

function copyDetails() {
  const textToCopy = props.selectedTest.details || '';

  navigator.clipboard.writeText(textToCopy);

  $q.notify({
    icon: 'cloud_done',
    message: 'Copied',
  });
}
</script>

<template>
  <div v-if="selectedTest" class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Result Details</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="section result__section row col-12 q-mb-md">
      <div class="col-12 q-mb-md">
        <span class="section__title">Last Result</span>
      </div>
      <div class="col-12 row result__wrapp q-mb-md">
        <div class="row result-item">
          <span class="item-title col-12 q-mb-sm">Value</span>
          <span class="item-value">{{ selectedTest.value || '-' }}</span>
        </div>
        <div class="row result-item">
          <span class="item-title col-12 q-mb-sm">Result</span>
          <span class="item-value">
            <q-badge
              class="status-badge"
              :label="
                selectedTest.status === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed
                  : selectedTest.status === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed
                    : TestingTestStatus.Error
              "
              :class="
                selectedTest.status === TestingTestStatus.Passed
                  ? TestingTestStatus.Passed + '-badge'
                  : selectedTest.status === TestingTestStatus.Failed
                    ? TestingTestStatus.Failed + '-badge'
                    : TestingTestStatus.Error + '-badge'
              "
            >
              <Icon
                :icon-name="
                  selectedTest.status === TestingTestStatus.Passed
                    ? 'testing-' + TestingTestStatus.Passed + '-active'
                    : selectedTest.status === TestingTestStatus.Failed
                      ? 'testing-' + TestingTestStatus.Failed + '-active'
                      : 'testing-' + TestingTestStatus.Error + '-active'
                "
                icon-folder="testing"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </span>
        </div>
      </div>
      <div class="row col-12 result-timestamp q-mb-lg">
        <span class="timestamp__title col-12 q-mb-md">Timestamp</span>
        <div class="row items-center">
          <Icon icon-name="schedule" icon-folder="testing" />
          <span class="timestamp__text q-ml-sm">
            {{ selectedTest.created_at ? formatDate(selectedTest.created_at) : '-' }}
          </span>
        </div>
      </div>
      <div class="row col-12 result-log">
        <div class="row col-12 q-mb-md items-center">
          <span class="log__title q-mr-sm">Test Execution Log</span>
        </div>
        <div class="row log__container">
          <span class="log__text">
            {{ selectedTest.details ? selectedTest.details : '-' }}
          </span>
          <q-btn
            unelevated
            class="copy-btn row justify-center items-center q-pa-none"
            @click="copyDetails"
          >
            <Icon icon-name="content_copy" icon-folder="testing" />
            <q-tooltip>Copy log information</q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  .section {
    border-radius: 5px;
    box-shadow: 0px 3px -1px 8px rgba(0, 0, 0, 0.05);
    .section__title {
      @include heading-05(700, $secondary-600);
    }
  }
  .result__section {
    .result-item {
      padding: 0 12px;
      .item-title {
        @include paragraph-01(600, $secondary-500);
      }
      .item-value {
        @include paragraph-01(600, $secondary-800);
      }
    }
    .result-item:first-child {
      padding-left: 0;
      border-right: 2px solid var(--Secondary-100, #e1e7ea);
    }

    .result-timestamp {
      .timestamp__title {
        @include paragraph-01(600, $secondary-500);
      }
      .timestamp__text {
        @include paragraph-01(600, $secondary-800);
      }
    }

    .result-log {
      .log__title {
        color: $secondary-500;
        font-size: 14px;
        font-weight: 600;
      }

      .arrow__container {
        :deep(svg path) {
          fill: #a5b7c0;
        }
      }

      .log__container {
        padding: 10px 100px 10px 20px;
        border-radius: 10px;
        background: rgba(240, 243, 244, 0.5);
        position: relative;

        .log__text {
          @include paragraph-01(400, $secondary-500);
        }
        .copy-btn {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}
</style>
