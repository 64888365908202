<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

import type { PropType } from 'vue';
import type { Evidence, ControlDisplay, RiskDisplay } from '@/client/api';
import { Roles } from '@/client/api';

import { formatDate, statusIcon, Status } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useEvidenceStore } from '@/stores/EvidenceStore';

import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['closeDialog', 'showEdit']);
const imgUrl = ref<string | null>(null);

const props = defineProps({
  evidence: {
    type: Object as PropType<Evidence>,
    required: true,
  },
});

const evidenceStore = useEvidenceStore();
const projectsStore = useProjectsStore();
const controlsOpened = ref(false);
const risksOpened = ref(false);
const isEditor = ref(false);
const isAuditor = ref(false);
const isLoading = ref(false);

function toggleControlsView() {
  controlsOpened.value = !controlsOpened.value;
}

function toggleRisksView() {
  risksOpened.value = !risksOpened.value;
}

async function downloadFile() {
  isLoading.value = true;
  try {
    await evidenceStore.downloadFile(props.evidence.id, props.evidence.file_name);
  } catch (error) {
    captureException(error, {
      message: 'Component: DEvidencePreview, Function: downloadFile',
    });
  } finally {
    isLoading.value = false;
  }
}

const lockingControlsAndRisks = computed(() => {
  const firstArrayStrings = props.evidence.locking_controls_and_risks.controls.map(
    (control: ControlDisplay) => `${control.control_code} ${control.name}`,
  );

  const secondArray = props.evidence.locking_controls_and_risks.risks.map(
    (risk: RiskDisplay) => `${risk.name}`,
  );

  const combinedArray = firstArrayStrings.concat(secondArray);

  return combinedArray.join(', ');
});

const fileName = computed(() => {
  if (props.evidence.file_type.includes('pdf')) {
    return 'pdf';
  } else if (props.evidence.file_type.includes('cod')) {
    return 'cod';
  } else if (props.evidence.file_type.includes('iso')) {
    return 'iso';
  } else if (props.evidence.file_type.includes('java')) {
    return 'java';
  } else if (props.evidence.file_type.includes('python')) {
    return 'python';
  } else if (props.evidence.file_type.includes('xls')) {
    return 'xls';
  } else if (props.evidence.file_type.includes('xml')) {
    return 'xml';
  } else {
    return 'unknown';
  }
});

onMounted(async () => {
  try {
    const response = await evidenceStore.getEvidenceUrl({ evidenceId: props.evidence.id });
    const url = response?.url ?? null;
    imgUrl.value = url;

    if (!projectsStore.project) return;

    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
    isAuditor.value = userRoles?.includes(Roles.Auditor) || false;
  } catch (error) {
    captureException(error, {
      message: 'Component: DEvidencePreview, Hook: onMounted, Method: getEvidenceUrl',
    });
  }
});
</script>

<template>
  <div class="wrapp row">
    <div class="row header__row col-12 q-mb-md items-center justify-between">
      <h5 class="q-ma-none">{{ evidence.file_name }}</h5>
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div v-if="evidence.is_locked" class="locked__container row col-12 items-center q-mb-md">
      <Icon icon-name="locked" icon-folder="evidence" />
      <p class="col-11 q-ml-md q-my-none">
        Evidence is frozen because the following items are in review or completed:
        <b>{{ lockingControlsAndRisks + '.' }}</b>
      </p>
    </div>
    <div class="row info__row col-12 q-mb-lg">
      <div class="col-5 row">
        <div class="col-6 info__container row q-mb-md">
          <span class="col-12 q-mb-xs">Created by</span>
          <span>{{ evidence.created_by.firstname + ' ' + evidence.created_by.lastname }}</span>
        </div>
        <div class="col-6 info__container row q-mb-md">
          <span class="col-12 q-mb-xs">Type</span>
          <span class="col-12 type-text">{{ evidence.file_type }}</span>
        </div>
        <div class="col-6 info__container row q-mb-md">
          <span class="col-12 q-mb-xs">Last updated</span>
          <span>{{ evidence.modified_at ? formatDate(evidence.modified_at) : '-' }}</span>
        </div>
        <div class="col-6 info__container row q-mb-md">
          <span class="col-12 q-mb-xs">Size</span>
          <span>{{ evidence.file_size }}</span>
        </div>
      </div>
      <q-space />
      <div class="col-6 info__container row q-mb-md">
        <div class="col-12 row">
          <span class="col-12 q-mb-xs">Description</span>
          <span class="text-break">{{ evidence.description ? evidence.description : '-' }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="imgUrl && evidence.file_type.includes('image')"
      class="row img__container col-12 q-mb-md justify-center"
    >
      <img :src="imgUrl" alt="evidence-img" />
    </div>
    <div
      v-if="fileName && !evidence.file_type.includes('image')"
      class="file__container row justify-center col-12 q-mb-md"
    >
      <Icon :icon-name="fileName" icon-folder="evidence" />
    </div>
    <div class="row controls__row col-12 q-mb-md">
      <div class="controls__row-header col-12 row items-center justify-between q-mb-md">
        <span>Related Control(s)</span>
        <Icon
          v-if="controlsOpened"
          icon-folder="task"
          icon-name="arrow_up"
          class="toggle-view"
          @click="toggleControlsView"
        />
        <Icon
          v-else
          icon-folder="task"
          icon-name="arrow_down"
          class="toggle-view"
          @click="toggleControlsView"
        />
      </div>
      <div
        v-if="controlsOpened"
        class="cotrols__row-content row col-12"
        :class="controlsOpened ? 'content-opened' : ''"
      >
        <q-badge
          v-for="control in evidence.controls"
          :key="control.id"
          class="control-badge q-mr-sm q-mb-sm"
          :label="control.control_code + ' ' + control.name"
        >
          <Icon
            :icon-name="statusIcon(control.status as Status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
        </q-badge>
      </div>
    </div>
    <div class="row risks__row col-12 q-mb-lg">
      <div class="risks__row-header col-12 row items-center justify-between q-mb-md">
        <span>Related Risk(s)</span>
        <Icon
          v-if="risksOpened"
          icon-folder="task"
          icon-name="arrow_up"
          class="toggle-view"
          @click="toggleRisksView"
        />
        <Icon
          v-else
          icon-folder="task"
          icon-name="arrow_down"
          class="toggle-view"
          @click="toggleRisksView"
        />
      </div>
      <div
        v-if="risksOpened"
        class="cotrols__row-content row col-12"
        :class="risksOpened ? 'content-opened' : ''"
      >
        <q-badge
          v-for="risk in evidence.risks"
          :key="risk.id"
          class="control-badge q-mr-sm q-mb-sm"
          :label="risk.name"
        >
          <Icon
            :icon-name="statusIcon(risk.status as Status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
        </q-badge>
      </div>
    </div>
    <div class="row action__row col-12 justify-center">
      <q-btn
        v-if="isAdmin() || isProjectOwner() || isEditor"
        class="btn-edit q-mr-md"
        label="Edit"
        icon="edit"
        :disable="!isProjectOwner() && isAdmin() && isEditor"
        unelevated
        @click="emit('showEdit')"
      >
        <q-tooltip
          v-if="!isProjectOwner() && !isAdmin() && !isEditor"
          anchor="top middle"
          self="bottom middle"
        >
          You don't have permission. Contact the admin.
        </q-tooltip>
      </q-btn>
      <q-btn
        class="btn-download"
        label="Download"
        unelevated
        :disable="isLoading"
        @click="downloadFile"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .info__row {
    .info__container {
      padding: 0 10px;
      border-left: 1px solid $secondary-300;
      span:first-child {
        @include caption(400, $secondary-500);
      }
      span:last-child {
        font-size: 14px;
        color: $secondary-800;
      }
      .type-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .img__container {
    img {
      max-width: 220px;
      max-height: 220px;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
  .locked__container {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    padding: 10px 15px;
    border-radius: 4px;
    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .controls__row,
  .risks__row {
    padding-bottom: 10px;

    &-content {
      overflow: hidden;
      height: 0;
    }
    .content-opened {
      height: auto;
    }
  }
  .controls__row {
    border-bottom: 1px solid $secondary-200;
  }
  .toggle-view {
    cursor: pointer;
  }

  .action__row {
    .btn-edit {
      min-width: 280px;
      min-height: 44px;
      color: $secondary-500;
      border: 1px solid $secondary-500;
      background: transparent !important;
      text-transform: none;
    }
    .btn-download {
      min-width: 280px;
      min-height: 44px;
      color: $white;
      background: $secondary-500 !important;
      text-transform: none;
    }
  }
}
</style>
