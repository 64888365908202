<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { captureException } from '@/composables/Sentry';

import { ProjectType } from '@/client/api';

import DLifecycle from './ProjectDashboard/DLifecycle.vue';
import DRisks from './ProjectDashboard/DRisks.vue';
import DFrameworks from './ProjectDashboard/DFrameworks.vue';

const projectsStore = useProjectsStore();
const dashboardIsLoading = ref(false);

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    dashboardIsLoading.value = true;
    await projectsStore.getProjectDashboard({ projectId: projectsStore.project.id });
  } catch (error) {
    captureException(error, {
      message: 'Component: Dashboard, Hook: onMounted, Method: getProjectDashboard ',
    });
  } finally {
    dashboardIsLoading.value = false;
  }
});
</script>
<template>
  <div class="wrapper">
    <DLifecycle
      v-if="projectsStore.project?.project_type !== ProjectType.Organization"
      class="q-my-xl"
    />
    <DRisks class="q-my-xl" :dashboard-is-loading="dashboardIsLoading" />
    <DFrameworks
      v-if="projectsStore.project"
      :project-is-loading="projectsStore.projectIsLoading"
    />
  </div>
</template>
