<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import type { NavigationGuardNext } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore';
import { useConfigStore } from '@/stores/ConfigStore';
import { useFrameworkStore } from '@/stores/FrameworkStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';

import type { CreationFrameworkTemplate, OrganizationUser, ProjectType } from '@/client/api';
import { Roles } from '@/client/api';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import Step2Form from '@/components/molecules/Forms/Step2Form.vue';
import ProjectTypeCard from '@/components/molecules/ProjectTypeCard.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import DAddCreationUser from '@/components/dialogs/DAddCreationUser.vue';
import DEditCreationUser from '@/components/dialogs/DEditCreationUser.vue';

interface EditUser {
  id: string;
  role: Roles;
}

interface Select {
  value: string;
  label: string;
  roles: EditUser[];
}

const router = useRouter();
const authStore = useAuthStore();
const projectsStore = useProjectsStore();
const frameworksStore = useFrameworkStore();
const configStore = useConfigStore();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stepper = ref<any>(null);
const step = ref(1);
const isLoading = ref(false);
const step3Cards = ref<CreationFrameworkTemplate[] | null>(null);
const selectedScopes = ref<string[]>([]);
const showAddDialog = ref(false);
const showEditDialog = ref(false);
const editedUser = ref<null | Select>(null);
const showConfirmDialog = ref(false);
const nextRoute = ref<NavigationGuardNext | null>(null);
const guard = ref(true);

const form = reactive({
  name: '',
  business: '',
  type: '',
  policy: '',
  description: '',
});
const columnsNames = {
  firstname: 'User',
  roles: 'Role(s)',
  created_at: 'Created At',
  las_access: 'Last Acess',
  edit: '',
};

const creationUsers = ref<OrganizationUser[]>([]);

const stepValidated = computed(() => {
  if (step.value === 1) {
    return form.name !== '';
  }
  return true;
});

function resetSelection() {
  if (!step3Cards.value) return;
  if (Array.isArray(step3Cards.value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    step3Cards.value.forEach((card: any) => {
      card.resetCheckbox();
    });
  }
}

async function nextStep() {
  if (step.value === 2) {
    isLoading.value = true;
    try {
      await frameworksStore.getFrameworks(form.type as ProjectType);
    } catch (error) {
      captureException(error, { message: 'Component: OStepper, Function: nextStep' });
    } finally {
      isLoading.value = false;
    }
  }
  stepper.value.next();
}

function onInput(key: string, value: string) {
  form[key as keyof typeof form] = value;
}

function selectScope(value: boolean, name: string) {
  if (!value) {
    selectedScopes.value = selectedScopes.value.filter((item: string) => item !== name);
    return;
  }
  selectedScopes.value.push(name);
}

function closeDialog() {
  showAddDialog.value = false;
  showEditDialog.value = false;
}

function addUser(user: OrganizationUser) {
  creationUsers.value.push(user);
}

function editUser(user: Select) {
  editedUser.value = user;
  showEditDialog.value = true;
}

function updateUser(user: OrganizationUser) {
  const index = creationUsers.value.findIndex((obj) => obj.id === user.id);
  if (index !== -1) {
    creationUsers.value[index] = user;
  }
}

function removeUser(user: OrganizationUser) {
  creationUsers.value = creationUsers.value.filter((obj) => obj.id !== user.id);
}

async function createProject() {
  isLoading.value = true;

  try {
    const userRoles: { [key: string]: Roles[] } = {};

    creationUsers.value.forEach((item) => {
      const id = item.id;
      const roles = [...new Set(item.roles?.map((role: EditUser) => role.role))];

      if (!userRoles[id]) {
        userRoles[id] = roles || [];
      } else {
        userRoles[id] = userRoles[id].concat(roles || []);
      }
    });
    const data = {
      name: form.name,
      description: form.description,
      project_type: form.type as ProjectType,
      user_roles: userRoles,
    };

    const project = await projectsStore.createProject({ createProject: data });

    successMessage(`Project '${project?.name ?? ''}' was created`);

    if (projectsStore.project && selectedScopes.value.length !== 0) {
      const params = {
        projectId: projectsStore.project.id,
        requestBody: selectedScopes.value,
      };

      await projectsStore.createProjectFramework(params);
    }

    guard.value = false;
    nextRoute.value = null;
    router.push({ name: 'project-dashboard', params: { id: projectsStore.project?.id } });
  } catch (error) {
    captureException(error, {
      message: 'Component: OStepper, Function: createProject',
    });
  } finally {
    isLoading.value = false;
  }
}

function confirmLeave() {
  if (!nextRoute.value) return;

  showConfirmDialog.value = false;
  nextRoute.value();
}

watch(
  () => authStore.user,
  (newV) => {
    creationUsers.value = [];

    if (!newV) return;
    const owner = newV;
    if ('roles' in owner) {
      const hasOwnerRole = owner.roles?.some((role) => role.role === Roles.Owner);
      if (!hasOwnerRole) {
        owner.roles?.push({
          id: '0',
          role: Roles.Owner,
          user_id: newV.id,
          project_id: '',
        });
      }
      creationUsers.value.push(owner);
    }
  },
  {
    immediate: true,
  },
);

onBeforeRouteLeave((to, from, next) => {
  if (guard.value) {
    showConfirmDialog.value = true;
    nextRoute.value = next;
    return;
  }

  next();
});
</script>

<template>
  <div>
    <q-stepper ref="stepper" v-model="step" animated flat header-class="stepper__header" keep-alive>
      <q-step id="step-1" :name="1" title="Settings" prefix="1" :done="step > 1">
        <div class="title__container row items-center justify-between q-mb-lg">
          <span>Give your project a unique name and description.</span>
        </div>
        <div class="content__container row">
          <div class="form__container col-12">
            <Step2Form @on-input="onInput" />
          </div>
        </div>
      </q-step>

      <q-step id="step-2" :name="2" title="Users" prefix="2" :done="step > 2">
        <div class="title__container row items-center justify-between q-mb-lg">
          <span
            >Add users to your project and assign their roles. You can always add more users later
            and change their roles.</span
          >
          <div class="row items-center">
            <q-btn
              v-if="configStore.usersEnabled || isAdmin()"
              flat
              color="primary"
              label="Add User to the Project"
              @click="showAddDialog = true"
            >
              <Icon icon-name="add-user" icon-folder="colored" class="q-mr-sm" />
            </q-btn>
          </div>
          <ADialog
            :show-dialog="showAddDialog"
            max-height="auto !important"
            max-width="500px !important"
            min-height="auto !important"
            min-width="500px !important"
            @hide="closeDialog"
          >
            <DAddCreationUser
              :existing-users="creationUsers"
              @close-dialog="closeDialog"
              @add-to-existing="addUser"
            />
          </ADialog>
        </div>
        <OTable
          :data="creationUsers"
          :columns-names="columnsNames"
          :row-clickable="false"
          :search-filter="false"
          :show-pagination="false"
          @edit-user="editUser"
        >
        </OTable>
        <ADialog
          :show-dialog="showEditDialog"
          max-width="560px !important"
          min-width="560px !important"
          min-height="auto"
          @hide="closeDialog"
        >
          <DEditCreationUser
            v-if="editedUser"
            :edited-user="editedUser"
            :existing-users="creationUsers"
            @update-user="updateUser"
            @remove-user="removeUser"
            @close-dialog="closeDialog"
          />
        </ADialog>
      </q-step>

      <q-step id="step-3" :name="3" title="Frameworks" prefix="3">
        <div
          v-if="projectsStore.projectsData && projectsStore.projectsData.items.length === 0"
          class="title__container row items-center justify-between"
        >
          <span> In order to have a compliant AI use the suggested frameworks.</span>
          <div class="row items-center">
            <q-btn flat color="primary" label="Reset selection" @click="resetSelection">
              <Icon icon-name="reset_all" icon-folder="colored" />
            </q-btn>
          </div>
        </div>
        <div
          v-if="frameworksStore.frameworksData && frameworksStore.frameworksData.length > 0"
          class="cards__container row q-col-gutter-x-md q-col-gutter-y-md"
        >
          <div
            v-for="card in frameworksStore.frameworksData"
            :key="card.framework_code"
            class="card-wrapper col-4"
          >
            <ProjectTypeCard
              v-if="card"
              ref="step3Cards"
              :card="card"
              :show-checkbox="true"
              :is-loading="isLoading"
              @select-scope="selectScope"
            />
          </div>
        </div>
        <AEmptyData
          v-else
          icon-name="drawer"
          :header="`There are currently no available frameworks of type ${form.type.replaceAll(
            '_',
            ' ',
          )}.`"
          text="Please upload a new framework in organization settings section."
          class="empty__wrapp full-width items-center"
        />
      </q-step>

      <template #navigation>
        <q-stepper-navigation>
          <q-btn
            flat
            color="primary"
            :label="step === 1 ? 'Cancel' : 'Back'"
            @click="step === 1 ? router.push('/projects') : stepper?.previous()"
          />
          <q-btn
            color="primary"
            class="q-ml-sm"
            unelevated
            :disable="!stepValidated || isLoading"
            :loading="isLoading"
            :label="step === 3 ? 'Create Project' : 'Next'"
            @click="step !== 3 ? nextStep() : createProject()"
          />
        </q-stepper-navigation>
      </template>
    </q-stepper>
    <ADialog
      :show-dialog="showConfirmDialog"
      max-height="auto !important"
      max-width="500px !important"
      min-height="auto !important"
      min-width="500px !important"
      @hide="showConfirmDialog = false"
    >
      <DConfirm
        title="Are you sure you want to leave?"
        description="You will lose all the data you have entered."
        button-text="Confirm"
        @confirm="confirmLeave"
        @close-dialog="showConfirmDialog = false"
      />
    </ADialog>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.q-stepper {
  background: transparent !important;
  :deep(.q-stepper__step-content) {
    padding: 0 0 20px;
  }
  :deep(.q-stepper__tab) {
    color: $common-1 !important;
    padding: 0 !important;
  }
  :deep(.q-stepper__tab--active) {
    color: $primary-500 !important;
    .q-stepper__dot {
      background: $secondary-600 !important;
    }
    .q-stepper__dot::before {
      background: $secondary-600;
    }
  }
  :deep(.q-stepper__content) {
    span {
      @include paragraph-01(400, $common-2);
    }
  }
  :deep(.q-stepper__tab--done) {
    color: $primary-500 !important;
    .q-stepper__label::after {
      background: $secondary-600;
    }
    .q-stepper__dot::before {
      background: $secondary-600;
    }
    .q-stepper__dot {
      background: transparent !important;
      border: 1px solid $primary-500;
      span {
        color: $primary-500 !important;
      }
    }
  }

  .q-stepper__nav {
    display: flex;
    justify-content: flex-end;
    button {
      min-width: 75px;
      text-transform: unset;
      border-radius: 5px;
    }
    button:first-child {
      background: transparent !important;
      color: $secondary-500 !important;
    }
    button:last-child {
      background: $secondary-500 !important;
      color: $white;
    }
  }

  :deep(.q-stepper__step-inner) {
    padding: 26px 0 18px 0;
  }
  :deep(.stepper__header) {
    max-width: 30%;
  }
  :deep(.q-separator) {
    background: transparent !important;
  }

  #step-3 {
    .title__container {
      padding-right: 24px;
      :deep(button) {
        border-radius: 5px;
        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
        .q-btn__content {
          flex-direction: row-reverse;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
    .cards__container {
      margin-top: 18px;
      .card-wrapper {
        max-width: 300px;
        min-height: 250px;
        .q-card {
          border: 1px solid transparent;
        }
      }
    }
  }

  #step-2 {
    .title__container {
      padding-right: 24px;
      :deep(button) {
        border-radius: 5px;
        border: 1px solid $secondary-500;
        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
        .q-btn__content {
          flex-direction: row-reverse;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  #step-1 {
    .content__container {
      padding: 30px;
      background: $white;
      border-radius: 10px;
      .type__container {
        margin-right: 45px;
        span {
          @include paragraph-01(400, $common-2);
        }
        .q-card {
          max-width: 280px;
          box-shadow: unset;
          border-radius: 10px;
          margin-top: 12px;
          border: 1px solid #e7eaee;
          .q-card__section {
            padding: 18px;
            .text-h6 {
              @include heading-05(700, $common-4);
              margin-bottom: 20px;
              letter-spacing: -0.32px;
            }
            p {
              @include paragraph-01(400, $common-2);
            }
          }
        }
      }
      .form__container {
        background: $white;
      }
    }
  }
}
</style>
