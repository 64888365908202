<script setup lang="ts">
import { UserState, UserInviteStatus } from '@/client/api';

import { lastInvite } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

defineProps({
  props: {
    type: Object,
    required: true,
  },
});

function isDateExpired(dateString: string) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  return inputDate < currentDate;
}

function isPendingInvitation(status: UserInviteStatus) {
  return status === UserInviteStatus.Pending;
}
</script>

<template>
  <q-td :props="props">
    <q-badge
      v-if="props.row.state !== UserState.Invited"
      :class="props.row.state + '-badge'"
      :label="props.row.state"
    >
      <Icon v-if="props.row.is_active" icon-name="active" icon-folder="table" class="q-mr-sm" />
      <Icon v-else icon-name="deactivated" icon-folder="table" class="q-mr-sm" />
    </q-badge>
    <q-badge
      v-if="props.row.state === UserState.Invited && lastInvite(props.row.invites)"
      :class="'invitation-' + lastInvite(props.row.invites).status + '-badge'"
      :label="props.row.state"
    >
      <Icon
        v-if="
          isPendingInvitation(lastInvite(props.row.invites).status) &&
          !isDateExpired(lastInvite(props.row.invites).expires_at)
        "
        icon-name="invitation-pending"
        icon-folder="table"
        class="q-mr-sm"
      />
      <Icon
        v-if="
          isPendingInvitation(lastInvite(props.row.invites).status) &&
          isDateExpired(lastInvite(props.row.invites).expires_at)
        "
        icon-name="invitation-expired"
        icon-folder="table"
        class="q-mr-sm"
      />
      <Icon
        v-if="lastInvite(props.row.invites).status === UserInviteStatus.Cancelled"
        icon-name="invitation-cancelled"
        icon-folder="table"
        class="q-mr-sm"
      />
      <q-tooltip anchor="top middle" self="center middle" class="project-tooltip">
        {{
          isPendingInvitation(lastInvite(props.row.invites).status) &&
          !isDateExpired(lastInvite(props.row.invites).expires_at)
            ? 'Pending Invitation'
            : isPendingInvitation(lastInvite(props.row.invites).status) &&
                isDateExpired(lastInvite(props.row.invites).expires_at)
              ? 'Expired Invitation'
              : 'Cancelled Invitation'
        }}
      </q-tooltip>
    </q-badge>
  </q-td>
</template>
