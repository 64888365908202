<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['confirm', 'closeDialog']);

defineProps({
  isLoading: {
    type: Boolean,
  },
});

async function confirm() {
  emit('confirm');
}
</script>

<template>
  <div class="wrapp column">
    <div class="row col-12 justify-center">
      <div class="lock__container row items-center justify-center">
        <Icon icon-name="report_problem" icon-folder="evidence" />
      </div>
    </div>
    <div class="row col-12 justify-center">
      <h3>Privacy & Policy</h3>
    </div>
    <div class="row col-12 justify-center q-mb-sm t-and-cs">
      <p>
        Please read and accept our
        <a href="https://www.modulos.ai/terms-and-conditions" target="_blank"
          >Terms and Conditions</a
        >
        and
        <a href="https://www.modulos.ai/privacy-policy/" target="_blank">Privacy Policy</a>
        to continue with your sign-up process. These documents explain your rights and obligations
        regarding our service.
      </p>
    </div>
    <div class="row col-12 justify-center">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-confirm"
        label="Accept"
        :loading="isLoading"
        :disable="isLoading"
        unelevated
        @click="confirm"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  padding: 10px 0;

  .lock__container {
    width: 64px;
    height: 64px;
    background: #ffecee;
    border-radius: 50%;
  }

  h3 {
    @include heading-03(700, $common-4);
  }

  .t-and-cs {
    opacity: 0.4;
    display: block;
    p {
      text-align: center;
    }
    a {
      color: $primary-900;
    }
  }
  .btn-cancel {
    padding: 10px 0;
    min-width: 200px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    margin-right: 10px;
    border-radius: 5px;
  }
  .btn-confirm {
    padding: 10px 0;
    min-width: 200px;
    color: $white;
    background: #f96f7f !important;
    text-transform: none;
    border-radius: 5px;
  }
}
</style>
