<script setup lang="ts">
import { ref } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';

const emit = defineEmits(['closeDialog']);

const props = defineProps({
  projectRequestId: {
    type: String,
    required: true,
  },
});

const projectsStore = useProjectsStore();
const isLoading = ref(false);

async function requestAccess() {
  isLoading.value = true;

  try {
    await projectsStore.projectRequestAccess({ projectId: props.projectRequestId });

    successMessage('Request sent successfully');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequestAccess, Function: requestAccess',
      data: {
        projectId: props.projectRequestId,
      },
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <div class="wrapp column">
    <div class="row col-12 justify-center">
      <div class="lock__container row items-center justify-center">
        <Icon icon-name="lock-500" icon-folder="colored" />
      </div>
    </div>
    <div class="row col-12 justify-center">
      <h3>You don’t have access to this project</h3>
    </div>
    <div class="row col-12 justify-center q-mb-sm">
      <p>Contact the Admin and request access.</p>
    </div>
    <div class="row col-12 justify-center">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-request"
        label="Request Access"
        unelevated
        :loading="isLoading"
        :disable="isLoading"
        @click="requestAccess"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  padding: 10px 0;

  .lock__container {
    width: 64px;
    height: 64px;
    background: $secondary-50;
    border-radius: 50%;
  }

  h3 {
    @include heading-03(700, $common-4);
  }
  p {
    @include paragraph-02(400, $common-2);
  }
  .btn-cancel {
    padding: 10px 0;
    min-width: 200px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    margin-right: 10px;
  }
  .btn-request {
    padding: 10px 0;
    min-width: 200px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
