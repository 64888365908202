<script setup lang="ts">
import { ref, watch, nextTick } from 'vue';
import VueSlider from 'vue-3-slider-component';

import type { Styles, Value } from 'vue-3-slider-component';
import type { PropType } from 'vue';

interface Mark {
  [key: string]: string | MarkOption;
}

interface MarkOption {
  label: Value;
  style?: Styles;
  labelStyle?: Styles;
}

const props = defineProps({
  sliderValue: {
    type: Number,
    required: true,
  },
  threshold: {
    type: Number,
    required: true,
  },
  comparisonValue: {
    type: [Number, null] as PropType<number | null>,
    required: true,
  },
});

const value = ref(0);

const processStyle = {
  backgroundColor: '$secondary-500',
};
const dotStyle = {
  backgroundColor: 'white',
  boxShadow: 'unset',
  border: '2px solid $secondary-500',
};

const marks: Mark = {
  0: {
    label: '0',
    labelStyle: {
      color: '#262626',
    },
  },
  1: {
    label: '1',
    labelStyle: {
      color: '#262626',
    },
  },
};
const stepActiveStyle = {
  display: 'block',
  height: '10px',
  backgroundColor: 'gray',
};

async function addThresholdClass(value: number) {
  marks[value] = {
    label: 'threshold',
    labelStyle: {
      color: '#76919E',
    },
  };

  await nextTick();

  const markLabels = document.querySelectorAll('.vue-slider-mark-label');

  markLabels.forEach((label) => {
    if (label.firstChild && label.firstChild.nodeValue === 'threshold') {
      label.parentElement?.classList.add('mark-threshold');
    }
  });
}

watch(
  () => props.sliderValue,
  (newValue) => {
    value.value = newValue;
    addThresholdClass(props.threshold);
  },
  {
    immediate: true,
  },
);

watch(
  () => props.threshold,
  (newValue) => {
    if (newValue) {
      addThresholdClass(newValue);
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.comparisonValue,
  (newValue) => {
    if (newValue) {
      marks[newValue] = {
        label: '',
        labelStyle: {
          width: '10px',
          height: '10px',
          top: '-15px',
          border: '2px solid #334C63',
          borderRadius: '50%',
          background: '#556C77',
        },
      };
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <VueSlider
    v-model="value"
    :step-active-style="stepActiveStyle"
    :process-style="processStyle"
    :marks="marks"
    :min="0"
    :max="1"
    :interval="0.001"
    tooltip="none"
    disabled
    :dot-style="dotStyle"
    width="260px"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.vue-slider-disabled {
  opacity: 1 !important;
}

:deep(.mark-threshold) {
  width: 4px !important;
  height: 100% !important;
  background: #c7ed71 !important;
  border-radius: 50% !important;
  border: 4px solid rgb(118, 145, 158) !important;
  z-index: 100 !important;
  .vue-slider-mark-label {
    margin-top: 25px;
  }
}

:deep(.mark-threshold::before) {
  content: '';
  position: absolute;
  width: 1px;
  height: 20px;
  border-left: 2px dashed $secondary-400;
  left: 23%;
  top: -25px;
}

:deep(.mark-threshold::after) {
  content: '';
  position: absolute;
  width: 1px;
  height: 20px;
  border-left: 2px dashed grey;
  left: 23%;
  bottom: -25px;
}

:deep(.vue-slider-mark-step-active) {
  height: unset !important;
  background-color: unset !important;
}
</style>
