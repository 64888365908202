<script setup lang="ts">
import { onMounted, ref, type PropType } from 'vue';

import { formatDate } from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import type { Comment, Concept, ReviewStatusDecision } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import { useAuthStore } from '@/stores/AuthStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import Icon from '@/components/atoms/Icon.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';

const props = defineProps({
  comment: {
    type: Object as PropType<Comment> | null,
    default: null,
  },
  objectId: {
    type: String,
    default: null,
  },
  objectType: {
    type: String as PropType<Concept>,
    default: null,
  },
});

const emit = defineEmits(['updateConcept', 'reassignReviewer']);

const authStore = useAuthStore();
const projectsStore = useProjectsStore();
const isLoading = ref(false);
const message = ref<TipTapContent>({
  content: '',
  json_content: {},
});
const editor = ref<TipTapContent>({
  content: '',
  json_content: {},
});

function userInitials(comment: Comment) {
  return (
    comment.created_by.firstname.charAt(0).toUpperCase() +
    comment.created_by.lastname.charAt(0).toUpperCase()
  );
}

async function makeDecision(decision: ReviewStatusDecision) {
  if (!projectsStore.project) return;
  isLoading.value = true;

  const params = {
    projectId: projectsStore.project.id,
    objectId: props.objectId,
    reviewDecision: {
      decision,
      decision_comment: editor.value.content,
      json_decision_comment: editor.value.json_content,
    },
    concept: props.objectType,
  };
  try {
    await projectsStore.conceptMakeDicision(params);

    successMessage(`You ${decision} the status change`);

    emit('updateConcept');
  } catch (error) {
    captureException(error, {
      message: 'Component: MComment, Function: makeDecision',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

function updateEditor(value: TipTapContent) {
  editor.value = value;
}

onMounted(() => {
  if (!props.comment) {
    message.value = {
      content: '',
      json_content: {},
    };
    return;
  }

  message.value = {
    content: props.comment.content,
    json_content: props.comment.json_content,
  };
});
</script>

<template>
  <div
    class="row justify-start full-width comment__container"
    :class="[!comment || comment.comment_type === 'review' ? 'review-comment' : '']"
  >
    <q-avatar>
      <span>
        {{
          comment
            ? userInitials(comment)
            : authStore.user?.firstname.charAt(0).toUpperCase() ||
              '' + authStore.user?.lastname.charAt(0).toUpperCase() ||
              ''
        }}
      </span>
    </q-avatar>
    <div class="comment__section column q-ml-md">
      <div class="section__top row justify-between items-center">
        <div>
          <span class="username q-mr-md">{{
            comment
              ? comment.created_by.firstname + ' ' + comment.created_by.lastname
              : authStore.user?.firstname + ' ' + authStore.user?.lastname
          }}</span>
          <span v-if="comment" class="date">{{ formatDate(comment.created_at) }}</span>
        </div>
      </div>
      <div class="section__bottom row">
        <TipTapEditor
          v-if="comment"
          :editor-content="message"
          class="q-mb-md col-12 editor-styles"
          :editable="false"
          :view-only="true"
        />
        <div v-else class="review__container row col-12 relative-position">
          <span class="col-12 q-mb-md">Review</span>
          <TipTapEditor
            :editor-content="editor"
            class="q-mb-md col-12"
            @update-editor="updateEditor"
          />
          <div class="decision__container row col-12 justify-end items-center">
            <q-btn
              label="Edit Reviewer(s)"
              dense
              unelevated
              class="q-mr-md btn-reassign"
              :disable="isLoading"
              @click="emit('reassignReviewer')"
            >
              <Icon icon-name="icon-reassign" icon-folder="colored" class="q-mr-sm" />
            </q-btn>
            <q-btn
              icon="check"
              label="Approve"
              dense
              unelevated
              class="q-mr-md btn-approve"
              :disable="isLoading"
              @click="makeDecision('approved')"
            />
            <q-btn
              icon="close"
              label="Reject"
              dense
              unelevated
              :disable="isLoading"
              @click="makeDecision('rejected')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.comment__container {
  margin-bottom: 20px;
  .q-avatar {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    background: $secondary-100;
    span {
      @include caption(400, $common-2);
    }
  }
  .comment__section {
    width: calc(100% - 41px);
    .section__top {
      position: relative;
      padding: 8px 20px;
      background: $primary-100;
      border: 1px solid $primary-300;
      border-radius: 5px 5px 0px 0px;
      z-index: 5;
      .username {
        @include paragraph-01(400, $black);
      }
      .date {
        @include paragraph-01(400, $secondary-500);
      }
    }
    // .section__top::after {
    //   content: '';
    //   position: absolute;
    //   top: 5px;
    //   left: -10px;
    //   width: 0;
    //   height: 0;
    //   border-left: 17px solid $primary-100;
    //   border-bottom: 17px solid transparent;
    //   border-right: 17px solid transparent;
    //   transform: rotate(-45deg);
    // }
    .section__bottom {
      position: relative;
      padding: 20px;
      background: rgba(255, 255, 255, 1);
      border-radius: 0px 0px 4px 4px;
      border-right: 1px solid $primary-300;
      border-bottom: 1px solid $primary-300;
      border-left: 1px solid $primary-300;
      z-index: 10;
      p {
        @include paragraph-01(400, $secondary-600);
      }
      .review__container {
        span {
          color: $secondary-500;
        }
        .decision__container {
          button {
            min-height: 40px;
            min-width: 175px;
          }
          .btn-reassign {
            background: transparent !important;
            color: $secondary-500;
            text-transform: none;
            border: 1px solid $secondary-500;
            :deep(.q-btn__content) {
              flex-direction: row-reverse;
            }
          }
          .btn-approve {
            background: #10b981 !important;
            color: $white;
            text-transform: none;
          }
          button:last-child {
            background: #f87171 !important;
            color: $white;
            text-transform: none;
          }
        }
      }
    }
  }
}
.review-comment {
  .comment__section {
    background: #fff8e6;
    .section__top {
      background: #fff8e6;
      border: 1px solid #fd956b;
    }
    // .section__top::after {
    //   border-left: 17px solid #fff8e6;
    // }
    .section__bottom {
      background: #fffdf5;
      border-right: 1px solid #fd956b;
      border-bottom: 1px solid #fd956b;
      border-left: 1px solid #fd956b;
    }
  }
}
</style>
