<script setup lang="ts">
import { useDashboardStore } from '@/stores/DashboardStore';

import type { SecondaryPagination } from '@/interfaces/models/Pagination';

import type { PropType } from 'vue';
import { Concept } from '@/client/api';

import AEmptyData from '@/components/atoms/AEmptyData.vue';
import Icon from '@/components/atoms/Icon.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

interface SelectValue {
  value: string | null;
  label: string;
}

defineProps({
  title: {
    type: String,
    required: true,
  },
  columnsNames: {
    type: Object,
    default: () => null,
  },
  filter: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  projectName: {
    type: Object as PropType<SelectValue>,
    required: true,
  },
  secondaryPagination: {
    type: Object as PropType<SecondaryPagination>,
    default: () => null,
  },
  isDataLoading: {
    type: Boolean,
  },
  isRisksLoading: {
    type: Boolean,
  },
  emptyTitle: {
    type: String,
    required: true,
  },
  emptySubTitle: {
    type: String,
    required: true,
  },
});

const dashboardStore = useDashboardStore();
</script>

<template>
  <div class="risks-info row items-center q-mb-md">
    <Icon icon-name="error_outline" icon-folder="dashboard" class="q-mr-sm" />
    <span>{{ title }}</span>
  </div>
  <OTable
    :data="dashboardStore?.risks?.items"
    :row-route="`/projects/project-detail/${projectName ? projectName : null}/risk-detail/`"
    :columns-names="columnsNames"
    :search-filter="false"
    :pagination="dashboardStore.risks!"
    :secondary-pagination="secondaryPagination"
    :is-loading="isRisksLoading"
    :skeleton-size="1"
    :skeleton-columns="3"
    route-query="risk"
    parent-el="ORisks"
    store="dashboardStore"
    action="getDashboardRisks"
    :concept="Concept.Risk"
  >
  </OTable>
  <div
    v-if="dashboardStore?.risks?.items.length === 0 && !isRisksLoading"
    class="row"
    :class="[!dashboardStore.risks ? 'full-height' : '']"
  >
    <AEmptyData
      v-if="!filter"
      icon-name="drawer"
      :header="emptyTitle"
      :text="emptySubTitle"
      class="full-width items-center q-mt-lg"
    />
    <AEmptyData
      v-else
      icon-name="search"
      :header="`No results for your search.`"
      text="Try changing your search."
      class="full-width items-center"
    />
  </div>
</template>
