<script setup lang="ts">
import { Status } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

interface RowData {
  value: number;
  percentage: number;
}

defineProps({
  row: {
    type: String,
    default: '',
  },
  items: {
    type: Array as () => RowData[] | number[],
    default: () => [],
  },
});

function statusClass(name: string) {
  switch (name) {
    case 'overall':
      return 'text-black';
    case Status.COMPLETED:
      return 'completed';
    case Status.IN_REVIEW:
      return 'in_review';
    case Status.IN_PROGRESS:
      return 'in_progress';
    default:
      return '';
  }
}
</script>

<template>
  <div class="row row__container">
    <div class="title__container column">
      <Icon
        v-if="statusClass(row) !== '' && statusClass(row) !== 'text-black'"
        :icon-name="statusClass(row)"
        icon-folder="dashboard"
        class="q-mb-xs"
      />
      <span>{{ row.replaceAll('_', ' ') }}</span>
    </div>
    <div v-for="item in items" :key="row + item" class="row item__container column items-center">
      <span :class="statusClass(row)"> {{ typeof item !== 'number' ? item.value : item }}</span>
      <span v-if="row" class="item__percentage q-mt-xs">
        {{
          typeof item !== 'number'
            ? `(${
                item.percentage !== 0 && item.percentage !== 100
                  ? item.percentage.toFixed(1)
                  : item.percentage
              }%)`
            : ''
        }}</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.row__container {
  border-bottom: 1px solid $secondary-300;
  flex-wrap: nowrap;

  .title__container,
  .item__container {
    width: 102px;
    padding: 20px 0;
    .item__percentage {
      font-size: 14px;
      color: $secondary-300;
    }
  }
  .item__container:not(:first-child):not(:nth-child(2)) {
    margin-left: 90px;
  }
  .item__container {
    margin-left: 20px;
    span {
      font-size: 20px;
      font-weight: 700;
    }
    .text-black {
      color: $secondary-800;
    }
  }
  .item__container:nth-child(2) {
    margin-left: 55px;
  }
  span {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.96px;
    color: $secondary-500;
  }
}
</style>
