<script setup lang="ts">
import { debounce } from 'lodash';
import { useQuasar } from 'quasar';
import { ref } from 'vue';

import { captureException } from '@/composables/Sentry';

import { useEvidenceStore } from '@/stores/EvidenceStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  BytesToMb,
  FileRejected,
  MAX_DESCRIPTION_SIZE,
  MAX_FILE_SIZE,
  MAX_NAME_SIZE,
} from '@/composables/utils';

import type { QInput } from 'quasar';

import Icon from '@/components/atoms/Icon.vue';
import { ContentTooLargeError } from '@/composables/ApiError';

const emit = defineEmits(['closeDialog', 'updateData']);

const props = defineProps({
  componentId: {
    type: String,
    default: null,
  },
});

const $q = useQuasar();
const evidenceStore = useEvidenceStore();
const projectsStore = useProjectsStore();

const name = ref('');
const description = ref('');
const artifact = ref<File | null>(null);
const isLoading = ref(false);
const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);
const validationPassed = ref(false);

function onSubmit() {
  $q.notify({
    icon: 'cloud_done',
    message: 'Evidence Created',
  });
}

function closeModal() {
  emit('closeDialog');
}

async function createEvidence() {
  if (!projectsStore.project || !artifact.value) return;
  const evidence = {
    file_name: name.value,
    description: description.value,
  };

  try {
    isLoading.value = true;

    await evidenceStore.uploadEvidence(
      projectsStore.project.id,
      evidence,
      artifact.value,
      props.componentId,
    );

    emit('updateData');
    emit('closeDialog');
  } catch (error) {
    if (error instanceof ContentTooLargeError) {
      $q.notify({
        color: 'red',
        icon: 'error',
        message: 'File size is too large',
      });
    }
    captureException(error, {
      message: 'Component: DNewEvidence, Function: createEvidence',
      data: {
        evidence: evidence,
        artifact: artifact.value,
        componentId: props.componentId,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

const debouncedValidation = debounce(() => {
  if (!descriptionInput.value || !nameInput.value) return;
  const isNameValid = nameInput.value.validate();
  const isDescriptionValid = descriptionInput.value.validate();

  if (isNameValid && isDescriptionValid) {
    validationPassed.value = true;
  } else {
    validationPassed.value = false;
  }
}, 100);
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="upload" icon-folder="evidence" class="q-mr-sm" />
      <h5 class="q-ma-none">Add New Evidence</h5>
    </div>
    <div class="row">
      <q-form class="row col-12" @submit="onSubmit">
        <div class="row col-12 q-mb-xl">
          <!-- <q-file v-model="artifact" /> -->
          <q-file
            v-model="artifact"
            class="file-uploader col-12 q-mb-sm"
            outlined
            dense
            label=""
            label-slot
            :max-file-size="MAX_FILE_SIZE"
            @rejected="FileRejected"
          >
            <slot name="label">
              <div class="file-label">
                <span>Drop or</span> <span class="browse">browse</span> <span> file to upload</span>
              </div>
            </slot>
          </q-file>
          <div class="details__container row col-12 justify-end">
            <span>{{ `Maximum file size ${BytesToMb(MAX_FILE_SIZE)}MB` }}</span>
          </div>
          <!-- <div v-else class="artifact__container row col-12 items-center justify-between">
            <span class="artifact__name"> {{ artifact.name }} </span>
            <Icon icon-name="delete" icon-folder="evidence" @click="artifact = null" />
          </div> -->
        </div>
        <div class="col-12 row q-mb-lg">
          <span class="q-mb-sm col-12">Evidence Name</span>
          <q-input
            ref="nameInput"
            v-model="name"
            outlined
            dense
            class="col-12"
            placeholder="Insert Name"
            no-error-icon
            :rules="[
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
        <div class="col-12 row">
          <span class="col-12 q-mb-sm">Evidence Description</span>
          <q-input
            ref="descriptionInput"
            v-model="description"
            outlined
            placeholder="Insert text"
            no-error-icon
            dense
            class="col-12"
            type="textarea"
            :rules="[
              (val: string) =>
                val.length <= MAX_DESCRIPTION_SIZE ||
                `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
            ]"
            @update:model-value="debouncedValidation"
          />
        </div>
      </q-form>
    </div>
    <div class="row action__row full-width justify-between q-mt-lg">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="closeModal" />
      <q-btn
        class="btn-save"
        label="Upload"
        unelevated
        :class="[!validationPassed ? 'disable' : '']"
        :loading="isLoading"
        :disable="isLoading || !validationPassed || !artifact"
        @click="createEvidence"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  :deep(.q-field) {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
  }
  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__native::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, $common-4);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    .file-uploader {
      cursor: pointer;
      :deep(.q-field__control-container) {
        padding-top: 20px !important;
        flex-direction: column-reverse;
        align-items: center;
      }

      :deep(.q-field__control::before) {
        border: 1px dashed #96cebf !important;
      }

      :deep(.q-field__control::after) {
        border: none !important;
      }

      :deep(.q-field__native) {
        min-height: unset;
        justify-content: center;
      }

      .file-label {
        color: $secondary-700;

        .browse {
          font-weight: 600;
          color: $black;
        }
      }
    }
    .artifact__container {
      padding: 10px 20px;
      background: $primary-50;
      .artifact__name {
        max-width: 500px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        color: $common-5;
      }
      svg {
        cursor: pointer;
      }
    }
  }

  .details__container {
    span {
      @include caption(400, $secondary-400);
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
