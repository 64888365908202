<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';

import type { AssetCardTypes, UserDisplay, AssetCardNature } from '@/client/api';
import { Concept, Roles } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import { useAuthStore } from '@/stores/AuthStore';
import { useConfigStore } from '@/stores/ConfigStore';
import { useAssetsStore } from '@/stores/AssetsStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { Status, statusIcon, STATUS_LABEL, FormatNumber } from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { OrganizationCurrency } from '@/composables/Config';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import Icon from '@/components/atoms/Icon.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';
import OComments from '@/components/organisms/OComments.vue';
import DRequestReview from '@/components/dialogs/DRequestReview.vue';
import DRequestReassign from '@/components/dialogs/DRequestReassign.vue';
import DEditAsset from '@/components/dialogs/DEditAsset.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import SDetailPage from '@/components/skeletons/SDetailPage.vue';

const route = useRoute();
const authStore = useAuthStore();
const configStore = useConfigStore();
const assetsStore = useAssetsStore();
const projectStore = useProjectsStore();
const activeTab = ref('asset');
const type = ref<AssetCardTypes | string>('');
const assetNature = ref<AssetCardNature | string>('');
const assetValue = ref<number>(0);
const currency = ref<string>('');
const owner = ref('');
const assignedUser = ref<UserDisplay | null>(null);
const status = ref<Status | null>(null);
const showRequestModal = ref(false);
const showReassignModal = ref(false);
const showConfirmDialog = ref(false);
const isLoading = ref(false);
const assetIsLoading = ref(false);
const reviewers = ref<UserDisplay[]>([]);
const confirmationIsLoading = ref(false);
const showEditAsset = ref(false);
const isEditor = ref(false);
const editor = ref<TipTapContent>({
  content: '',
  json_content: {},
});

function changeTab(value: string) {
  activeTab.value = value;
}

async function updateAssetContent() {
  if (!assetsStore.currentAsset) return;

  isLoading.value = true;
  try {
    const asset = await assetsStore.updateAsset({
      assetCardId: assetsStore.currentAsset.id,
      updateAssetCard: { ...editor.value },
    });

    successMessage(`Asset content '${asset?.name ?? ''}' was successfully updated`);
  } catch (error) {
    captureException(error, {
      message: 'Component: AssetDetailPage, Function: updateAssetContent',
      data: {
        assetId: assetsStore.currentAsset.id,
        updateAssetCard: {
          content: editor.value,
        },
      },
    });
  } finally {
    isLoading.value = false;
  }
}

async function selectUser(user: string, id: string) {
  if (!assetsStore.currentAsset) return;
  try {
    const asset = await assetsStore
      .updateAssetAssignee({
        assetCardId: assetsStore.currentAsset.id,
        userId: id,
      })
      .catch((error) => {
        captureException(error, {
          message: 'Component: AssetDetailPage, Function: selectUser',
          data: {
            assetCardId: assetsStore.currentAsset?.id,
            userId: id,
          },
        });
      });

    successMessage(
      `Asset '${asset?.name ?? ''}' was successfully assigned to ${asset?.assigned_to?.firstname ?? ''} ${asset?.assigned_to?.lastname ?? ''}`,
    );

    await assetsStore.getAsset({
      assetCardId: route.params.assetId as string,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: AssetDetailPage, Function: selectUser',
    });
  }
}

function capitalizeFirstLetter(role: string) {
  return role.charAt(0).toUpperCase() + role.slice(1);
}

function downloadAsset() {
  if (!assetsStore.currentAsset) return;
  const asset = assetsStore.currentAsset;

  var file = new Blob([asset.content], { type: 'text' });

  var a = document.createElement('a');
  var url = URL.createObjectURL(file);
  a.href = url;
  a.download = asset.name;

  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

function updateEditor(value: TipTapContent) {
  editor.value = value;
}

function closeConfirm() {
  showConfirmDialog.value = false;
}

function closeDialog() {
  showRequestModal.value = false;
  showEditAsset.value = false;
  showReassignModal.value = false;
}

function cancelReview() {
  showConfirmDialog.value = true;
}

function reassignReviewer() {
  showReassignModal.value = true;
}

async function confirm() {
  if (!projectStore.project || !assetsStore.currentAsset) return;

  try {
    confirmationIsLoading.value = true;

    const params = {
      objectId: assetsStore.currentAsset.id,
      projectId: projectStore.project.id,
      concept: Concept.AssetCard,
    };

    await projectStore.conceptCancelReview(params);

    successMessage('Review(s) request for status change cancelled successfully');

    await assetsStore.getAsset({
      assetCardId: route.params.assetId as string,
    });
    await updateCommentsAndLogs();
    closeConfirm();
  } catch (e) {
    captureException(e, {
      message: 'Component: AssetDetailPage, Function: confirm',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

async function updateConcept() {
  try {
    await assetsStore.getAsset({
      assetCardId: route.params.assetId as string,
    });
    await updateCommentsAndLogs();
  } catch (error) {
    captureException(error, {
      message: 'Component: AssetDetailPage, Function: updateConcept',
    });
  }
}

async function updateCommentsAndLogs() {
  if (!projectStore.project || !assetsStore.currentAsset) return;

  try {
    const params = {
      objectId: assetsStore.currentAsset.id,
      projectId: projectStore.project.id,
      objectType: Concept.AssetCard,
    };

    await projectStore.getComments(params);

    const logParams = {
      objectId: assetsStore.currentAsset.id,
      projectId: projectStore.project.id,
    };

    await projectStore.getProjectLogs(logParams);
  } catch (error) {
    captureException(error, {
      message: 'Component: AssetDetailPage, Function: updateCommentsAndLogs',
    });
  }
}

const reviewMessage = computed(() => {
  if (!assetsStore.currentAsset || !assetsStore.currentAsset.pending_review)
    return '<span>-</span>';

  return `<span><b>${assetsStore.currentAsset.pending_review.created_by.firstname}</b> <b>${assetsStore.currentAsset.pending_review.created_by.lastname}</b>
  requested to change status from <b>${assetsStore.currentAsset.pending_review.old_status}</b> to <b>${assetsStore.currentAsset.pending_review.requested_status}</b>.</span>`;
});

watch(
  () => assetsStore.currentAsset,
  (newV) => {
    if (!assetsStore.currentAsset || !newV) return;
    type.value = newV.asset_card_type;
    assetNature.value = newV.nature ? newV.nature : '-';
    assetValue.value = newV.value ? newV.value : 0;
    owner.value = newV.created_by.firstname + ' ' + newV.created_by.lastname;
    assignedUser.value = newV.assigned_to || null;
    status.value = assetsStore.currentAsset.status;
    reviewers.value = assetsStore.currentAsset?.reviewers || [];
  },
  {
    immediate: true,
  },
);

watch(
  () => configStore.configData,
  (newV) => {
    if (!newV) return;

    currency.value = OrganizationCurrency(newV.general.currency);
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  try {
    assetIsLoading.value = true;
    await assetsStore.getAsset({
      assetCardId: route.params.assetId as string,
    });

    if (!projectStore.project) return;

    const userRoles = await userProjectRoles(projectStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;

    if (!assetsStore.currentAsset) return;

    reviewers.value = assetsStore.currentAsset.reviewers || [];

    await updateCommentsAndLogs();
    editor.value = {
      content: assetsStore.currentAsset.content,
      json_content: assetsStore.currentAsset.json_content,
    };
  } catch (error) {
    captureException(error, {
      message: 'Component: AssetDetailPage, Hook: onMounted',
    });
  } finally {
    assetIsLoading.value = false;
  }
});

onUnmounted(() => {
  projectStore.resetCommentsAndLogs();
});
</script>
<template>
  <PageTitle
    v-if="assetsStore.currentAsset && !assetIsLoading"
    :title="assetsStore.currentAsset.name"
    :editable="
      (!assetsStore.currentAsset.is_locked && isProjectOwner()) ||
      (!assetsStore.currentAsset.is_locked && isAdmin()) ||
      (!assetsStore.currentAsset.is_locked && isEditor)
    "
  />
  <div
    v-if="
      (assetsStore.currentAsset &&
        assetsStore.currentAsset.status === Status.IN_REVIEW &&
        activeTab === 'control' &&
        isAdmin()) ||
      (assetsStore.currentAsset &&
        assetsStore.currentAsset.status === Status.IN_REVIEW &&
        activeTab === 'control' &&
        isProjectOwner()) ||
      (assetsStore.currentAsset &&
        assetsStore.currentAsset.status === Status.IN_REVIEW &&
        activeTab === 'control' &&
        isEditor)
    "
    class="row col-12 notification__wrapp q-mb-md items-center justify-between"
  >
    <div class="notification__left row items-center">
      <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
      <span class="notification__message"
        >This control is under review by
        <b>{{
          reviewers.map((user: UserDisplay) => user.firstname + ' ' + user.lastname).join(',')
        }}</b
        >.</span
      >
    </div>
    <div class="notification__right">
      <q-btn label="Cancel Request" unelevated @click="cancelReview">
        <template #default>
          <Icon icon-name="cancel-review" icon-folder="white" class="q-mr-sm" />
        </template>
      </q-btn>
    </div>
  </div>
  <div
    v-if="
      assetsStore.currentAsset &&
      authStore.user &&
      assetsStore.currentAsset.status === Status.IN_REVIEW &&
      reviewers &&
      reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id)
    "
    class="row col-12 notification__wrapp q-mb-md items-center justify-between"
  >
    <div class="notification__left row items-center">
      <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
      <div class="column">
        <span class="notification__message assign-text"
          >Status Change Request assigned to you.</span
        >
        <span class="notification__message" v-html="reviewMessage" />
      </div>
    </div>
    <div class="notification__right">
      <q-btn label="Review Request" unelevated @click="changeTab('comments_logs')" />
    </div>
  </div>
  <div class="assessment__wrapp row justify-between">
    <div v-if="assetsStore.currentAsset && !assetIsLoading" class="assessment-left row">
      <OTabPanels
        :active-tab="activeTab"
        default-tab="asset"
        :default-size="true"
        class="full-width"
        @change-tab="changeTab"
      >
        <template #tabs>
          <q-tab content-class="my-tab" name="asset" label="Asset"> </q-tab>
          <q-tab content-class="my-tab" name="comments_logs" label="Comments and Logs">
            <span
              v-if="
                (projectStore.projectLogs.length > 0 || projectStore.comments.length > 0) &&
                !isLoading
              "
              class="total-badge q-ml-sm"
            >
              {{
                projectStore.projectLogs.length + projectStore.comments.length < 10
                  ? '0' + (projectStore.projectLogs.length + projectStore.comments.length)
                  : projectStore.projectLogs.length + projectStore.comments.length
              }}</span
            >
          </q-tab>
        </template>
        <template #panels>
          <q-tab-panel name="asset">
            <div class="asset-content__container">
              <div class="q-mb-lg">
                <span class="left__title"
                  >Please fill in the dataset card/model card information.</span
                >
              </div>
              <TipTapEditor
                :editor-content="editor"
                :editable="
                  (!assetsStore.currentAsset.is_locked && isProjectOwner()) ||
                  (!assetsStore.currentAsset.is_locked && isAdmin()) ||
                  (!assetsStore.currentAsset.is_locked && isEditor)
                "
                root-el="Asset"
                @update-editor="updateEditor"
              />
              <div class="row action__row col-12 justify-between q-mt-lg">
                <q-btn
                  class="btn-download"
                  label="Download File"
                  unelevated
                  @click="downloadAsset"
                />
                <q-btn
                  v-if="isProjectOwner() || isAdmin() || isEditor"
                  class="btn-save"
                  label="Save"
                  unelevated
                  :disable="
                    isLoading ||
                    assetsStore.currentAsset.is_locked ||
                    (!isProjectOwner() && !isAdmin() && !isEditor)
                  "
                  :loading="isLoading"
                  @click="updateAssetContent"
                />
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="comments_logs">
            <OComments
              v-if="assetsStore.currentAsset && authStore.user"
              :object-type="'asset_card'"
              :reviewer="
                reviewers &&
                reviewers.length > 0 &&
                reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id)
              "
              :in-review="assetsStore.currentAsset.status === Status.IN_REVIEW"
              :object-id="assetsStore.currentAsset.id"
              :editable="!assetsStore.currentAsset.is_locked"
              class="coomments__wrapp"
              @update-concept="updateConcept"
              @reassign-reviewer="reassignReviewer"
            />
          </q-tab-panel>
        </template>
      </OTabPanels>
    </div>

    <div v-if="assetsStore.currentAsset && !assetIsLoading" class="assessment-right">
      <div class="top-actions__container row items-center">
        <q-btn
          v-if="
            assetsStore.currentAsset.status !== Status.IN_REVIEW &&
            (isProjectOwner() || isAdmin() || isEditor)
          "
          class="q-ml-md"
          unelevated
          :disable="!isProjectOwner() && !isAdmin() && !isEditor"
          label="Request status change"
          @click="showRequestModal = true"
        />
      </div>
      <div class="row q-mb-lg">
        <Icon icon-name="info" icon-folder="colored" />
        <span class="details__title q-ml-sm">Info</span>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm">Type</span>

        <span class="q-mb-md col-12 text-black">{{
          capitalizeFirstLetter((type as string).replaceAll('_', ' '))
        }}</span>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm">Nature</span>

        <span class="q-mb-md col-12 text-black">{{
          capitalizeFirstLetter((assetNature as string).replaceAll('_', ' '))
        }}</span>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm col-12">Value</span>
        <div class="q-mb-md">
          <span class="text-black">{{ currency + ' ' + FormatNumber(assetValue) }} </span>
          <q-tooltip
            v-if="assetValue"
            anchor="top middle"
            self="center middle"
            class="project-tooltip"
          >
            {{ currency + ' ' + assetValue }}
          </q-tooltip>
        </div>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm">Owner</span>

        <span class="q-mb-md col-12 text-black">{{ owner }}</span>
      </div>
      <div class="row col-12 q-mb-md">
        <span class="select__title q-mb-sm col-12">Status</span>
        <div v-if="status" class="row">
          <Icon
            :icon-name="statusIcon(status)"
            icon-folder="status"
            class="q-mr-xs"
            icon-size="20px"
          />
          <span class="text-black detail-value">{{
            STATUS_LABEL[status.toUpperCase() as keyof typeof STATUS_LABEL]
          }}</span>
        </div>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm col-12">Assigned To</span>
        <q-btn-dropdown
          v-if="!assetsStore.currentAsset.is_locked && (isProjectOwner() || isAdmin() || isEditor)"
          flat
          :label="
            assetsStore.currentAsset.assigned_to
              ? assetsStore.currentAsset.assigned_to.firstname +
                ' ' +
                assetsStore.currentAsset.assigned_to.lastname
              : 'Please Select'
          "
          :disable="
            assetsStore.currentAsset.is_locked || (!isProjectOwner() && !isAdmin() && !isEditor)
          "
          class="q-mb-md"
        >
          <AUsersDropDown :assigned-user="assignedUser" @select-user="selectUser" />
        </q-btn-dropdown>
        <span v-else class="q-mb-md col-12 text-black">{{
          assetsStore.currentAsset.assigned_to
            ? assetsStore.currentAsset.assigned_to?.firstname +
              ' ' +
              assetsStore.currentAsset.assigned_to?.lastname
            : '-'
        }}</span>
      </div>
      <div class="row col-12">
        <span class="select__title q-mb-sm col-12">Reviewer(s)</span>
        <div v-if="reviewers && reviewers.length > 0" class="row items-center q-mb-md">
          <q-badge
            v-for="user in reviewers"
            :key="user.id"
            class="q-mr-sm q-mb-sm user-badge"
            :label="user.firstname + ' ' + user.lastname"
          />
        </div>
        <span v-else class="text-black detail-value q-mb-md">-</span>
      </div>
      <div class="row col-12 justify-end q-mt-md">
        <q-btn
          v-if="!assetsStore.currentAsset.is_locked && (isProjectOwner() || isAdmin() || isEditor)"
          class="submit-btn full-width"
          label="Edit Info"
          unelevated
          :loading="isLoading"
          :disable="(!isProjectOwner() && !isAdmin() && !isEditor) || isLoading"
          @click="showEditAsset = true"
        />
      </div>
    </div>
    <SDetailPage v-if="assetIsLoading" />
  </div>
  <ADialog
    v-if="assetsStore.currentAsset"
    :show-dialog="showRequestModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DRequestReview
      :concept="assetsStore.currentAsset"
      :concept-type="'asset_card' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    v-if="assetsStore.currentAsset"
    :show-dialog="showReassignModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DRequestReassign
      :concept="assetsStore.currentAsset"
      :concept-type="'asset_card' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    v-if="assetsStore.currentAsset"
    :show-dialog="showEditAsset"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showEditAsset = false"
  >
    <DEditAsset :asset="assetsStore.currentAsset" @close-dialog="closeDialog" />
  </ADialog>
  <ADialog
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DConfirm
      title="Cancel Request"
      description="Would you like to cancel the Status Change Request?"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirm"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.notification__wrapp {
  padding: 10px 10px 10px 18px;
  border-radius: 4px;
  border: 1px solid #fd956b;
  background: #fff8e6;
  .notification__left {
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .assign-text {
      font-size: 16px;
    }
  }
  .notification__right {
    button {
      background: $secondary-500 !important;
      color: $white;
      text-transform: none;
      :deep(.q-btn__content) {
        flex-direction: row-reverse;
      }
    }
  }
}

span {
  @include heading-04(600, $secondary-600);
}

.title__container {
  max-width: 50%;
}

.assessment-left {
  width: 73%;
  height: fit-content;

  .q-tab-panel {
    padding: 0;

    .asset-content__container {
      padding: 20px;
      background: $white;
      border-radius: 5px;
    }

    .left__title {
      font-size: 14px;
      font-weight: 400;
      color: $secondary-600;
    }

    .action__row {
      .btn-download {
        color: $secondary-500;
        background: transparent !important;
        border: 1px solid $secondary-500;
        text-transform: none;
      }
      .btn-save {
        color: $white;
        background: $secondary-500 !important;
        text-transform: none;
      }
    }
  }
}

.assessment-right {
  width: 25%;
  height: fit-content;
  border-radius: 5px;
  padding: 20px;
  margin-top: 60px;
  background: $white;
  position: relative;

  .top-actions__container {
    position: absolute;
    right: 0;
    top: -60px;
    button {
      text-transform: unset;
      border-radius: 5px;
    }
    // button:first-child {
    //   background: transparent !important;
    //   color: $secondary-500 !important;
    //   border: 1px solid $secondary-500;
    // }
    button:last-child {
      background: $secondary-500 !important;
      color: $white;
    }
  }
  .select__title {
    @include caption(400, $secondary-500);
  }
  .q-select .q-field__inner {
    border: none !important;
    padding: 0 !important;
  }
  hr {
    border: 1px solid $secondary-100;
  }

  .q-btn-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.24);

    :deep(.q-focus-helper) {
      display: none;
    }

    :deep(span) {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    :deep(i) {
      color: $common-3 !important;
    }
  }

  .q-btn-dropdown:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }
  :deep(.q-btn__content) {
    span {
      text-transform: none;
    }
  }
  .text-black {
    @include paragraph-01(400, $common-4);
  }
  .level-text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .actions {
    width: 140px;
    box-shadow:
      0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: $white;
    padding: 4px 0;
    .action__container {
      padding: 6px 12px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: $common-4;
      }
    }
    .action__container:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
  .disabled {
    opacity: 0.5;
  }

  .submit-btn {
    height: 40px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    border-radius: 5px;
  }
}

:deep(.q-tabs) {
  margin: 0;

  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .q-tab {
    padding: 0;
    .q-tab__content {
      padding: 4px 10px 15px;
      .q-tab__indicator {
        height: 4px;
      }
      .full-width {
        span {
          @include heading-03(600, $secondary-600);
        }
      }
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  .q-tabs__arrow--left,
  .q-tabs__arrow--right {
    display: none !important;
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}

.content-wrapper {
  padding: 16px 0 !important;
}
</style>
