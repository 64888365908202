<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { cloneDeep } from 'lodash';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { Roles } from '@/client/api';

import { statusIcon } from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';

import type {
  AssetCard,
  Concept,
  ControlAggregator,
  RiskAggregator,
  UserDisplay,
} from '@/client/api';

import type { PropType } from 'vue';

import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import Icon from '@/components/atoms/Icon.vue';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

const emit = defineEmits(['closeDialog', 'updateConcept']);
const props = defineProps({
  concept: {
    type: Object as PropType<ControlAggregator | RiskAggregator | AssetCard>,
    required: true,
  },
  conceptType: {
    type: String as PropType<Concept>,
    required: true,
  },
});

const projectsStore = useProjectsStore();

const isLoading = ref(false);
const comment = ref('');
const reviewers = ref<UserDisplay[]>([]);

async function selectUsers(users: UserDisplay[]) {
  reviewers.value = users;
}

function removeChip(id: string) {
  const userIndex = reviewers.value.findIndex((user) => user.id === id);
  if (userIndex !== -1) {
    reviewers.value.splice(userIndex, 1);
  }
}

async function requestReview() {
  if (!projectsStore.project) return;

  try {
    isLoading.value = true;

    const selectedReviewersIds = reviewers.value.map((user: UserDisplay) => user.id);

    const data = {
      comment: comment.value,
      reviewer_ids: selectedReviewersIds,
    };

    const params = {
      projectId: projectsStore.project.id,
      objectId: props.concept.id,
      concept: props.conceptType,
      updateReviewRequest: data,
    };

    await projectsStore.conceptUpdateReview(params);

    successMessage('You successfully reassigned reviewer(s) for status change');

    emit('updateConcept');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequestReassign, Function: requestReview',
    });
  } finally {
    isLoading.value = false;
  }
}

function closeModal() {
  emit('closeDialog');
}

onMounted(async () => {
  const pagination = {
    ...DEFAULT_PAGINATION,
    size: 50,
    roles: [Roles.Reviewer, Roles.Owner],
  };

  if (!projectsStore.project) return;

  const params = {
    projectId: projectsStore.project.id,
    ...pagination,
  };

  try {
    const response = await projectsStore.getProjectsUsers(params, false);

    if (!response) return;

    reviewers.value = cloneDeep(props.concept.reviewers || []);
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequestReassign, Hook: onMounted, Function getProjectsUsers',
      data: {
        params: params,
      },
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">Edit Reviewer(s)</h5>
    </div>
    <div class="row col-12 q-mb-md status__container">
      <span class="select__title q-mb-sm col-12">Status Change*</span>
      <div class="row col-12">
        <div class="row col-6 items-center justify-between">
          <div class="row col-10 justify-center items-center">
            <Icon
              v-if="concept.pending_review"
              :icon-name="statusIcon(concept.pending_review.old_status)"
              icon-folder="status"
              class="q-mr-sm"
              icon-size="20px"
            />
            <span class="text-black">{{
              String(concept.pending_review?.old_status).charAt(0).toUpperCase() +
              String(concept.pending_review?.old_status).replaceAll('_', ' ').slice(1)
            }}</span>
          </div>
          <Icon icon-name="arrow_forward" icon-folder="colored" />
        </div>
        <div class="row col-6 justify-center items-center">
          <Icon
            v-if="concept.pending_review"
            :icon-name="statusIcon(concept.pending_review.requested_status)"
            icon-folder="status"
            class="q-mr-sm"
            icon-size="20px"
          />
          <span class="text-black">{{
            String(concept.pending_review?.requested_status).charAt(0).toUpperCase() +
            String(concept.pending_review?.requested_status).replaceAll('_', ' ').slice(1)
          }}</span>
        </div>
      </div>
    </div>
    <div class="row col-12 q-mb-md reviewers__container">
      <span class="select__title q-mb-sm col-12">Reviewer(s)*</span>
      <q-btn-dropdown
        flat
        label="Select Reviewer(s)
        "
        class="q-mb-sm col-12"
      >
        <AUsersDropDown multiselect :selected-users-arr="reviewers" @select-users="selectUsers" />
      </q-btn-dropdown>
      <div class="reviewers__container">
        <q-badge
          v-for="user in reviewers"
          :key="user.id"
          class="reviewer-badge q-mr-sm q-mb-sm justify-center"
        >
          <span>{{ user.firstname + ' ' + user.lastname }}</span>

          <Icon
            icon-name="icon-remove-badge"
            icon-folder="black"
            class="q-ml-sm"
            @click="removeChip(user.id)"
          />
        </q-badge>
      </div>
    </div>
    <div class="row col-12 q-mb-md">
      <span class="col-12 q-mb-sm">Comment</span>
      <q-input
        v-model="comment"
        outlined
        placeholder="Insert text"
        no-error-icon
        dense
        class="col-12"
        type="textarea"
      />
    </div>
    <div class="row action__row full-width justify-between q-mt-lg">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="closeModal" />
      <div class="row items-center">
        <q-btn
          class="btn-save"
          label="Submit"
          unelevated
          :loading="isLoading"
          :disable="isLoading || reviewers.length === 0"
          @click="requestReview"
        >
          <Icon icon-folder="task" icon-name="info" class="q-ml-xs" />

          <q-tooltip anchor="bottom end" self="center middle" max-width="200px">
            Submit this status update for review.
          </q-tooltip>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  :deep(.q-field) {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
  }

  .reviewers__container {
    .reviewer-badge {
      :deep(svg) {
        cursor: pointer;
      }
    }
    .q-btn-dropdown {
      border: 1px solid rgba(0, 0, 0, 0.24);

      :deep(.q-focus-helper) {
        display: none;
      }

      :deep(span) {
        color: $common-6 !important;
        text-transform: none !important;
      }

      :deep(i) {
        color: $common-3 !important;
      }
      :deep(.q-btn__content) {
        justify-content: space-between !important;
      }
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
