<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

import { useAuthStore } from '@/stores/AuthStore';

const emit = defineEmits(['closeDialog']);

defineProps({
  projectsType: {
    type: String,
    default: '',
  },
});

const authStore = useAuthStore();
</script>

<template>
  <div class="wrapp column">
    <div class="row col-12 justify-center">
      <div class="lock__container row items-center justify-center">
        <Icon icon-name="lock-500" icon-folder="colored" />
      </div>
    </div>
    <div class="row col-12 justify-center text-center">
      <h3>
        {{ `You have reached the limit of ${projectsType} Project(s).` }}
      </h3>
    </div>
    <div class="row col-12 justify-center text-center q-mb-sm">
      <p>Contact sales to upgrade your plan and be able to create New Projects.</p>
    </div>
    <div class="row col-12 justify-center">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        v-if="authStore.user"
        class="btn-request"
        label="Contact Sales"
        unelevated
        :href="`mailto:${authStore.user.email}`"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  padding: 10px 0;

  .lock__container {
    width: 64px;
    height: 64px;
    background: $secondary-50;
    border-radius: 50%;
  }

  h3 {
    @include heading-03(700, $secondary-700);
  }
  p {
    @include paragraph-02(400, #8c8c8c);
  }
  .btn-cancel {
    padding: 10px 0;
    min-width: 200px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    margin-right: 10px;
  }
  .btn-request {
    padding: 10px 0;
    min-width: 200px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
