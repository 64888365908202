<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { cloneDeep } from 'lodash';
import type { PropType } from 'vue';

interface Select {
  value: string;
  label: string;
}

const emit = defineEmits(['selectFilter', 'clearFilter', 'updateSelect']);
const props = defineProps({
  options: {
    type: Array as PropType<Select[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  disable: {
    type: Boolean,
    default: false,
  },
});

const selectValue = ref<Select | null>(null);
const selectOptions = ref<unknown[]>([]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterOptions(val: string, update: any) {
  update(() => {
    const needle = val.toLowerCase();
    selectOptions.value = props.options.filter((v) => v.label.toLowerCase().indexOf(needle) > -1);
  });
}

function clearFilter() {
  selectValue.value = null;
  emit('updateSelect', selectValue.value);
}

onMounted(() => {
  selectOptions.value = cloneDeep(props.options);
});

defineExpose({
  selectValue,
  selectOptions,
  filterOptions,
  clearFilter,
});
</script>

<template>
  <div class="row">
    <q-select
      v-model="selectValue"
      filled
      use-input
      hide-selected
      fill-input
      input-debounce="0"
      :label="label"
      clearable
      :options="selectOptions"
      dense
      :disable="disable"
      class="select-search col-12"
      :class="[isLoading ? 'skeleton' : '']"
      @filter="filterOptions"
      @update:model-value="emit('updateSelect', selectValue)"
      @clear="clearFilter"
    >
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<style scoped lang="scss">
.select-search {
  :deep(.q-field__inner) {
    padding: 0;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    .q-field__control {
      background: none !important;
    }
  }
  :deep(button) {
    background: unset !important;
  }
}
</style>
