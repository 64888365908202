import { ref } from 'vue';

export const operatorFloatOptions = ref([
  { type: 'float', name: '> Greater than', value: 'gt' },
  { type: 'float', name: 'Greater than or equal to', value: 'gte' },
  { type: 'float', name: '= Equal to', value: 'eq' },
  { type: 'float', name: '< Less than', value: 'lt' },
  { type: 'float', name: 'Less than or equal to', value: 'lte' },
]);

export const operatorIntegerOptions = ref([
  { type: 'integer', name: '> Greater than', value: 'gt' },
  { type: 'integer', name: 'Greater than or equal to', value: 'gte' },
  { type: 'integer', name: '= Equal to', value: 'eq' },
  { type: 'integer', name: '< Less than', value: 'lt' },
  { type: 'integer', name: 'Less than or equal to', value: 'lte' },
]);

export const operatorStringOptions = ref([
  { type: 'string', name: '= Equal to', value: 'eq' },
  { type: 'string', name: '≠ Not equal to', value: 'ne' },
]);
