import initApi from '@/client_heplers/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { handleApiError } from '@/composables/ApiError';
import type { AxiosError } from 'axios';

import { OrganizationsApi, ProjectsApi, UsersApi } from '@/client/api';

import type {
  AssetCardPage,
  Comment,
  Control,
  ControlDisplay,
  CustomProjectPage,
  EvidencePage,
  EvidencePreview,
  FrameworkAggregator,
  FrameworkDisplay,
  Logs,
  OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest,
  PageControl,
  PageControlList,
  PageRequirementAggregator,
  PageTestingMetric,
  PageTestingSource,
  ProjectAggregator,
  ProjectAiSystemLifecycleDashboard,
  ProjectCreator,
  ProjectDashboard,
  ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest,
  ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest,
  ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest,
  ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest,
  ProjectsApiCreateProjectApiProjectsPostRequest,
  ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest,
  ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest,
  ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest,
  ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest,
  ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest,
  ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest,
  ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest,
  ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest,
  ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest,
  ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest,
  ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest,
  ProjectsApiGetProjectApiProjectsProjectIdGetRequest,
  ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest,
  ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest,
  ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest,
  ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest,
  ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest,
  ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest,
  ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest,
  ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest,
  ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest,
  ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest,
  ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest,
  ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest,
  ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest,
  ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest,
  ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest,
  ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest,
  ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest,
  ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest,
  ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest,
  ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest,
  ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest,
  ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest,
  ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest,
  ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest,
  ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest,
  RiskDisplay,
  RiskWithOverviewPage,
  TestingMetric,
  TestingTest,
  TestingTestAndResultPage,
  TestingTestResult,
  TestingTestResultPage,
  UserProjectPage,
  UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest,
} from '@/client/api';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';
import type { RequestConfig } from '@/interfaces/models/Request';

export const useProjectsStore = defineStore('projects', () => {
  const project = ref<ProjectCreator | ProjectAggregator | null>(null);
  const projectFrameworks = ref<FrameworkAggregator[] | FrameworkDisplay[]>([]);
  const projectsData = ref<CustomProjectPage | null>(null);
  const dashboardData = ref<ProjectDashboard | null>(null);
  const lifecycleData = ref<ProjectAiSystemLifecycleDashboard | null>(null);
  const requirementsData = ref<PageRequirementAggregator | null>(null);
  const controlsData = ref<PageControlList | null>(null);
  const controlsDataLight = ref<PageControl | null>(null);
  const activeTab = ref('dashboard');
  const tableView = ref<boolean>(true);
  const projectUsers = ref<UserProjectPage | null>(null);
  const risksData = ref<RiskWithOverviewPage | null>(null);
  const evidenceData = ref<EvidencePage | null>(null);
  const projectControlsWithEvidence = ref<ControlDisplay[]>([]);
  const projectRisksWithEvidence = ref<RiskDisplay[]>([]);
  const projectEvidenceFilesTypes = ref<string[]>([]);
  const projectEvidenceList = ref<EvidencePreview[]>([]);
  const assetsData = ref<AssetCardPage | null>(null);
  const testingData = ref<TestingTestAndResultPage | null>(null);
  const currentTest = ref<TestingTest | null>(null);
  const testingSources = ref<PageTestingSource | null>(null);
  const currentSourceAttributes = ref<PageTestingMetric | null>(null);
  const currentSourceAttribute = ref<TestingMetric | null>(null);
  const currentTestResult = ref<TestingTestResult | null>(null);
  const testingTestResults = ref<TestingTestResultPage | null>(null);
  const testingTestControls = ref<Control[] | null>(null);
  const projectLogs = ref<Logs[]>([]);
  const comments = ref<Comment[]>([]);
  const projectIsLoading = ref(false);
  const conceptsLoading = ref(false);

  const getProjects = async (
    pagination: Omit<
      OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest,
      'organizationId'
    > = DEFAULT_PAGINATION,
    options?: RequestConfig,
  ) => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        return;
      }
      const organization_id = JSON.parse(user).organization.id;
      const organizationsApi = await initApi(OrganizationsApi);

      const params = {
        organizationId: organization_id,
        ...pagination,
      };

      const response = await organizationsApi?.getProjectsApiOrganizationsOrganizationIdProjectsGet(
        params,
        options,
      );

      if (!response) return;

      projectsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createProject = async (params: ProjectsApiCreateProjectApiProjectsPostRequest) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.createProjectApiProjectsPost(params);

      if (!response) return;

      project.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createProjectFramework = async (
    params: ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.createProjectFrameworkApiProjectsProjectIdFrameworksPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProject = async (params: ProjectsApiGetProjectApiProjectsProjectIdGetRequest) => {
    try {
      projectIsLoading.value = true;
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getProjectApiProjectsProjectIdGet(params);

      if (!response) return;
      project.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      projectIsLoading.value = false;
    }
  };

  const updateProject = async (
    params: ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest,
  ) => {
    try {
      if (!project.value) return;

      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.updateProjectApiProjectsProjectIdPatch(params);

      if (!response) return;

      project.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteProject = async (
    params: ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.deleteProjectApiProjectsProjectIdDelete(params);

      project.value = null;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectFrameworks = async (
    params: ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getProjectFrameworksApiProjectsProjectIdFrameworksGet(params);

      if (!response) return;

      projectFrameworks.value = response.data.items;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectDashboard = async (
    params: ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getProjectDashboardApiProjectsProjectIdDashboardGet(params);
      if (!response) return;

      dashboardData.value = response.data;
      projectFrameworks.value = response.data.frameworks;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectLifecycle = async (
    params: ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(
          params,
        );

      if (!response) return;

      lifecycleData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectRequirements = async (
    params: ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest,
  ) => {
    try {
      conceptsLoading.value = true;

      const projectsApi = await initApi(ProjectsApi);

      const response =
        await projectsApi?.getProjectRequirementsApiProjectsProjectIdRequirementsGet(params);

      if (!response) return;

      requirementsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      conceptsLoading.value = false;
    }
  };

  const getProjectControls = async (
    params: ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest,
  ) => {
    try {
      conceptsLoading.value = true;

      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getProjectControlsApiProjectsProjectIdControlsGet(params);

      if (!response) return;

      controlsData.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      conceptsLoading.value = false;
    }
  };

  const getProjectControlsLight = async (
    params: ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest,
  ) => {
    try {
      conceptsLoading.value = true;

      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getProjectControlsApiV1ProjectsProjectIdControlsGet(params);

      if (!response) return;

      controlsDataLight.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      conceptsLoading.value = false;
    }
  };

  const getControlByCode = async (
    params: ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest,
  ) => {
    try {
      const controlsApi = await initApi(ProjectsApi);
      const response =
        await controlsApi?.getControlByCodeApiProjectsProjectIdControlsControlCodeGet(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsUsers = async (
    params: ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest,
    reassign: boolean = true,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getUserOfProjectApiProjectsProjectIdUsersGet(
        params,
        options,
      );

      if (!response) return;

      if (reassign) {
        projectUsers.value = response.data;
      }

      return response.data.items;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsRisks = async (
    params: ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getProjectRisksListApiProjectsProjectIdRisksListGet(
        params,
        options,
      );

      if (!response) return;

      risksData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsEvidence = async (
    params: ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(
        params,
        options,
      );
      if (!response) return;

      evidenceData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsEvidenceList = async (
    params: ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getEvidenceListApiProjectsProjectIdEvidenceListGet(params);
      if (!response) return;

      projectEvidenceList.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsControlsWithEvidence = async (
    params: ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(
          params,
        );
      if (!response) return;

      projectControlsWithEvidence.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectsRisksWithEvidence = async (
    params: ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(
          params,
        );
      if (!response) return;

      projectRisksWithEvidence.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getEvidenceFileTypes = async (
    params: ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(params);
      if (!response) return;

      projectEvidenceFilesTypes.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectLogs = async (
    params: ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getLogsApiProjectsProjectIdLogsObjectIdGet(params);
      if (!response) return;

      projectLogs.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getComments = async (
    params: ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);

      const response =
        await projectsApi?.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(params);
      if (!response) return;

      comments.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const postComment = async (
    params: ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const resetCommentsAndLogs = () => {
    comments.value = [];
    projectLogs.value = [];
  };

  const getProjectAssets = async (
    params: ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(
        params,
        options,
      );
      if (!response) return;

      assetsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createProjectAsset = async (
    params: ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.createAssetCardApiProjectsProjectIdAssetCardsPost(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const projectRequestAccess = async (
    params: ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.requestAccessApiProjectsProjectIdRequestAccessPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectTestingTests = async (
    params: ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(
          params,
          options,
        );
      if (!response) return;

      testingData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getProjectTestingTest = async (
    params: ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest,
    reassign: boolean = true,
  ) => {
    currentTest.value = null;
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(params);
      if (!response) return;

      if (reassign) {
        currentTest.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createTestingTest = async (
    params: ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(params);
      if (!response) return;

      currentTest.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const associateTestToControl = async (
    params: ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(
          params,
        );
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateTestingTest = async (
    params: ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(
          params,
        );
      if (!response) return;

      currentTest.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const runTestingTest = async (
    params: ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(
          params,
        );
      if (!response) return;

      currentTestResult.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getTestingTestControls = async (
    params: ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(
          params,
        );
      if (!response) return;

      testingTestControls.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const removeTestingTestControl = async (
    params: ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getTestingTestResults = async (
    params: ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest,
    options?: RequestConfig,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(
          params,
          options,
        );
      if (!response) return;

      testingTestResults.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getSingleTestResult = async (
    params: ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(
          params,
        );
      if (!response) return;

      currentTestResult.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const setCurrentTestResult = (result: TestingTestResult | null) => {
    currentTestResult.value = result;
  };

  const getProjectTestingSources = async (
    params: ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(params);
      if (!response) return;

      testingSources.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getSingleSourceAttributes = async (
    params: ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(
          params,
        );

      if (!response) return;

      currentSourceAttributes.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getSourceAttribute = async (
    params: ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response =
        await projectsApi?.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(
          params,
        );

      if (!response) return;

      currentSourceAttribute.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateUserRole = async (params: UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest) => {
    try {
      const usersApi = await initApi(UsersApi);
      await usersApi?.patchUserRolesApiUsersUserIdRolesPatch(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const conceptRequestReview = async (
    params: ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const conceptUpdateReview = async (
    params: ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const conceptCancelReview = async (
    params: ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const conceptMakeDicision = async (
    params: ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest,
  ) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      await projectsApi?.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const exportProjectPdf = async (id: string) => {
    try {
      const projectsApi = await initApi(ProjectsApi);
      const response = await projectsApi?.getProjectPdfApiProjectsProjectIdExportGet(
        { projectId: id },
        {
          responseType: 'blob',
        },
      );

      if (!response) {
        return;
      }

      const data = response.data as unknown as Blob;

      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${project.value?.name || ''}.pdf`;

      document.body.appendChild(link);

      link.click();

      link.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const setView = (view: boolean) => {
    tableView.value = view;
  };

  const setActiveTab = (tab: string) => {
    activeTab.value = tab;
  };

  const getCreatedByUserId = (project: ProjectCreator | ProjectAggregator): string | undefined => {
    if ('created_by' in project) {
      return project.created_by.id;
    }

    return undefined;
  };

  return {
    activeTab,
    assetsData,
    comments,
    conceptCancelReview,
    conceptMakeDicision,
    conceptRequestReview,
    conceptsLoading,
    conceptUpdateReview,
    controlsData,
    createProject,
    createProjectAsset,
    createProjectFramework,
    createTestingTest,
    currentSourceAttribute,
    currentSourceAttributes,
    currentTest,
    currentTestResult,
    dashboardData,
    deleteProject,
    evidenceData,
    exportProjectPdf,
    getComments,
    getControlByCode,
    getCreatedByUserId,
    getEvidenceFileTypes,
    getProject,
    getProjectAssets,
    getProjectControls,
    getProjectDashboard,
    getProjectFrameworks,
    getProjectLifecycle,
    getProjectLogs,
    getProjectRequirements,
    getProjects,
    getProjectsControlsWithEvidence,
    getProjectsEvidence,
    getProjectsEvidenceList,
    getProjectsRisks,
    getProjectsRisksWithEvidence,
    getProjectsUsers,
    getProjectTestingSources,
    getProjectTestingTest,
    getProjectTestingTests,
    getSingleSourceAttributes,
    getSingleTestResult,
    getSourceAttribute,
    getTestingTestResults,
    lifecycleData,
    postComment,
    project,
    projectControlsWithEvidence,
    projectEvidenceFilesTypes,
    projectEvidenceList,
    projectFrameworks,
    projectIsLoading,
    projectLogs,
    projectRequestAccess,
    projectRisksWithEvidence,
    projectsData,
    projectUsers,
    requirementsData,
    resetCommentsAndLogs,
    risksData,
    runTestingTest,
    setActiveTab,
    setCurrentTestResult,
    setView,
    tableView,
    testingData,
    testingSources,
    testingTestResults,
    updateProject,
    updateTestingTest,
    updateUserRole,
    associateTestToControl,
    getTestingTestControls,
    testingTestControls,
    removeTestingTestControl,
    getProjectControlsLight,
    controlsDataLight,
  };
});
