<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { useQuasar } from 'quasar';
import { computed, onMounted, ref } from 'vue';

import type { QSelect } from 'quasar';

import type { ControlAggregator, ControlDisplay, RiskDisplay } from '@/client/api';

import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { captureException } from '@/composables/Sentry';

import ADatePicker from '@/components/atoms/ADatePicker.vue';
import ADropDown from '@/components/atoms/ADropDown.vue';
import Icon from '@/components/atoms/Icon.vue';

interface Date {
  from: string;
  to: string;
}

const emit = defineEmits(['setFilters', 'closeDialog']);

const $q = useQuasar();
const controls = ref<ControlDisplay[]>([]);
const selectedControls = ref<ControlAggregator[]>([]);
const controlSelect = ref<QSelect | null>(null);
const risks = ref<RiskDisplay[]>([]);
const selectedRisks = ref<RiskDisplay[]>([]);
const riskSelect = ref<QSelect | null>(null);
const tag = ref('');
// const tagSelect = ref<QSelect | null>(null);
// const tagsOptions = ref([]);
const stage = ref('');
// const stageSelect = ref<QSelect | null>(null);
// const stagesOptions = ref([]);
const timeframe = ref<Date | null>(null);

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const isLoading = ref(false);

function onReset() {
  selectedControls.value = [];
  selectedRisks.value = [];
  tag.value = '';
  timeframe.value = null;
  stage.value = '';
}

function resetFilters() {
  selectedControls.value = [];
  selectedRisks.value = [];
  tag.value = '';
  timeframe.value = null;
  stage.value = '';
}

function onSubmit() {
  $q.notify({
    icon: 'cloud_done',
    message: 'Risk Created',
  });
}

function selectControls(arr: ControlAggregator[]) {
  selectedControls.value = arr;
}

function selectRisks(arr: RiskDisplay[]) {
  selectedRisks.value = arr;
}

async function setFilters() {
  paginationStore.setEvidenceControlsFilter(
    selectedControls.value.length > 0 ? selectedControls.value : null,
  );
  paginationStore.setEvidenceRisksFilter(
    selectedRisks.value.length > 0 ? selectedRisks.value : null,
  );

  paginationStore.setEvidenceTimeFilter(timeframe.value ? timeframe.value : null);

  const filtersData = {
    associatedControls: selectedControls.value.map((control) => control.id),
    associatedRisks: selectedRisks.value.map((risk) => risk.id),
    startDate: timeframe.value ? timeframe.value.from : null,
    endDate: timeframe.value ? timeframe.value.to : null,
  };

  emit('setFilters', filtersData);
  emit('closeDialog');
}

function setDateFilter(date: Date) {
  timeframe.value = date;
}

function removeChip(id: string) {
  const controlIndex = selectedControls.value.findIndex((control) => control.id === id);
  const riskIndex = selectedRisks.value.findIndex((risk) => risk.id === id);

  if (controlIndex !== -1) {
    selectedControls.value.splice(controlIndex, 1);
  }

  if (riskIndex !== -1) {
    selectedRisks.value.splice(riskIndex, 1);
  }
}

const someValues = computed(() => {
  return (
    selectedControls.value.length !== 0 ||
    selectedRisks.value.length !== 0 ||
    tag.value !== '' ||
    timeframe.value !== null ||
    stage.value !== ''
  );
});

onMounted(async () => {
  if (!projectsStore.project) return;

  isLoading.value = true;

  try {
    await projectsStore.getProjectsControlsWithEvidence({ projectId: projectsStore.project.id });
    await projectsStore.getProjectsRisksWithEvidence({ projectId: projectsStore.project.id });

    controls.value = projectsStore.projectControlsWithEvidence;
    risks.value = projectsStore.projectRisksWithEvidence;
    selectedControls.value = paginationStore.evidenceControlsFilter
      ? cloneDeep(paginationStore.evidenceControlsFilter)
      : [];
    selectedRisks.value = paginationStore.evidenceRisksFilter
      ? cloneDeep(paginationStore.evidenceRisksFilter)
      : [];
    timeframe.value = paginationStore.evidenceTimeFilter
      ? {
          from: paginationStore.evidenceTimeFilter ? paginationStore.evidenceTimeFilter.from : '',
          to: paginationStore.evidenceTimeFilter ? paginationStore.evidenceTimeFilter.to : '',
        }
      : null;
  } catch (error) {
    captureException(error, { message: 'Component: DAdvancedSearch, Hook: onMounted' });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div class="wrapp column">
    <div v-if="!isLoading" class="advance__container column">
      <div class="row header__row q-mb-md items-center justify-between">
        <div class="header-left row items center">
          <Icon icon-name="search" icon-folder="evidence" class="q-mr-sm" />
          <h5 class="q-ma-none">Advanced Search</h5>
        </div>
        <q-btn icon="close" flat dense @click="emit('closeDialog')" />
      </div>
      <div class="row">
        <q-form class="row col-12" @submit="onSubmit" @reset="onReset">
          <div class="col-12 row justify-between items-start q-col-gutter-md q-ml-none">
            <div v-if="controls && controls.length > 0" class="col-6 row q-pl-none">
              <span class="q-mb-sm col-12">Control(s)</span>
              <q-btn-dropdown ref="controlSelect" flat label="Select Control" class="col-12">
                <ADropDown
                  filter-by="controls"
                  search-placeholder="control"
                  :data-arr="controls"
                  :selected-arr="selectedControls"
                  @select-item="selectControls"
                />
              </q-btn-dropdown>
              <div class="chips__container q-mt-sm row col-12">
                <q-badge
                  v-for="control in selectedControls"
                  :key="control.id"
                  class="general-badge q-mr-sm q-mb-sm"
                  :label="control.name"
                >
                  <Icon
                    icon-name="icon-cancel"
                    icon-folder="evidence"
                    class="q-ml-sm"
                    @click="removeChip(control.id)"
                  />
                </q-badge>
              </div>
            </div>
            <div
              v-if="risks && risks.length > 0"
              class="col-6 row"
              :class="controls.length === 0 ? 'q-pl-none' : ''"
            >
              <span class="q-mb-sm col-12">Risk(s)</span>
              <q-btn-dropdown ref="riskSelect" flat label="Select Risk" class="col-12">
                <ADropDown
                  filter-by="risks"
                  search-placeholder="risk"
                  :data-arr="risks"
                  :selected-arr="selectedRisks"
                  @select-item="selectRisks"
                />
              </q-btn-dropdown>
              <div class="chips__container q-mt-sm row col-12">
                <q-badge
                  v-for="risk in selectedRisks"
                  :key="risk.id"
                  class="general-badge q-mr-sm q-mb-sm"
                  :label="risk.name"
                >
                  <Icon
                    icon-name="icon-cancel"
                    icon-folder="evidence"
                    class="q-ml-sm"
                    @click="removeChip(risk.id)"
                  />
                </q-badge>
              </div>
            </div>
            <div class="col-12 row q-pl-none">
              <span class="col-12 q-mb-sm">Timeframe</span>
              <ADatePicker
                class="col-6"
                :timeframe="timeframe ? timeframe : undefined"
                position-fixed
                @set-date-filter="setDateFilter"
              />
            </div>
          </div>
        </q-form>
      </div>
      <div class="row action__row full-width justify-between q-mt-md">
        <q-btn
          class="btn-cancel"
          label="Clean Filters"
          unelevated
          :disable="!someValues"
          @click="resetFilters"
        />
        <q-btn
          class="btn-save"
          label="Apply"
          unelevated
          :class="[!someValues ? 'disable' : '']"
          :loading="isLoading"
          :disable="isLoading"
          @click="setFilters"
        />
      </div>
    </div>
    <div v-else class="row justify-center">
      <q-spinner-oval size="3em" color="primary" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    @include caption(400, $secondary-500);
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }
  :deep(.q-field) {
    padding: 0;
  }
  :deep(.q-field__bottom) {
    display: none;
  }

  .disabled {
    opacity: 0.5;
  }
  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field__native::placeholder) {
      @include paragraph-01(400, $common-6);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, $common-4);
    }
  }
  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}

.q-btn-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.24);
  padding: 4px 12px;

  :deep(.q-focus-helper) {
    display: none;
  }
  :deep(.q-btn__content) {
    @include paragraph-01(400, $common-6);
    justify-content: space-between;
    text-transform: none;
  }

  :deep(i) {
    color: $common-3 !important;
  }
}

.q-btn-dropdown:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}
</style>
