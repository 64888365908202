<script setup lang="ts">
import { ref, watch } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

import type { PropType } from 'vue';

import Icon from '@/components/atoms/Icon.vue';

interface Date {
  from: string;
  to: string;
}

const emit = defineEmits(['setDateFilter']);

const props = defineProps({
  timeframe: {
    type: Object as PropType<Date>,
    default: () => null,
  },
  time: {
    type: String,
    default: '',
  },
  positionFixed: {
    type: Boolean,
  },
  enableRange: {
    type: Boolean,
    default: true,
  },
});

const date = ref<Date | string | null>(null);
const showCalendar = ref(false);

function closeCalendar() {
  showCalendar.value = false;
}

function convertFromISOFormat(isoDateString: string): string {
  return dayjs.utc(isoDateString).format('YYYY-MM-DD');
}

function setDate() {
  if (typeof date.value === 'string' && props.enableRange) {
    emit('setDateFilter', {
      from: date.value,
      to: date.value,
    });
    return;
  }

  emit('setDateFilter', date.value);
}

watch(
  () => props.timeframe,
  (newV) => {
    if (!newV) {
      date.value = null;
      return;
    }
    if (typeof newV === 'string') {
      date.value = convertFromISOFormat(newV);
      return;
    } else {
      date.value = {
        from: convertFromISOFormat(newV.from),
        to: convertFromISOFormat(newV.to),
      };
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.time,
  (newV) => {
    if (props.enableRange) return;

    if (!newV) {
      date.value = null;
      return;
    }
    date.value = convertFromISOFormat(newV);
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div
    class="wrapp date-picker row items-center justify-between"
    :class="[positionFixed ? 'position-fixed' : '']"
    @click="showCalendar = true"
  >
    <span
      v-if="typeof date === 'object'"
      class="date-range"
      :class="[!date ? 'placeholder-color' : '']"
      >{{ date ? `${date.from} - ${date.to}` : 'Select Date(s)' }}</span
    >
    <span v-else class="date-range" :class="[!date ? 'placeholder-color' : '']">{{
      date ? date : 'Select Date(s)'
    }}</span>
    <Icon icon-name="calendar" icon-folder="evidence" class="q-ml-sm" />
    <q-date
      v-if="showCalendar"
      v-model="date"
      v-on-click-outside="closeCalendar"
      minimal
      :range="enableRange"
      mask="YYYY-MM-DD"
      @update:model-value="setDate"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  height: 40px !important;
  padding: 10px 12px;
  border: 1px solid $common-1;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  span {
    font-size: 14px;
    color: $common-4;
  }
  .placeholder-color {
    color: $common-6;
  }

  .q-date {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 999;
    :deep(.q-date__today) {
      font-weight: 700 !important;
      border: 1px solid rgba(0, 0, 0, 0.6) !important;
    }
    :deep(.q-date__range) {
      span {
        color: $black !important;
      }
      .q-date__today {
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
      }
    }
    :deep(.q-date__edit-range) {
      color: #59af97 !important;
      span {
        color: #59af97 !important;
      }
    }
    :deep(.q-date__edit-range-from) {
      color: #59af97 !important;
      span {
        color: #59af97 !important;
      }
    }
    :deep(.q-date__edit-range-to) {
      color: #59af97 !important;
      span {
        color: #59af97 !important;
      }
    }
    :deep(.q-date__edit-range-from-to) {
      color: #59af97 !important;
      span {
        color: #59af97 !important;
      }
    }
    :deep(.bg-primary) {
      background: $black !important;
    }
    :deep(.text-primary::before) {
      color: $black !important;
      opacity: 0.1;
    }
  }
}

.position-fixed {
  .q-date {
    position: fixed;
    transform: translateX(-50%);
    top: unset;
    left: 50%;
  }
}
</style>
