<script setup lang="ts">
import { useQuasar } from 'quasar';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, shallowRef, watch, computed, onUnmounted } from 'vue';

import type { ComputedRef } from 'vue';
import type { UserDisplay, Risk, Component } from '@/client/api';
import { Roles, Concept, TestingTestStatus } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import { DEFAULT_PAGINATION, DEFAULT_PAGINATION_SIZE } from '@/interfaces/models/Pagination';

import { userProjectRoles, isAdmin } from '@/composables/Auth';
import {
  formatDate,
  statusIcon,
  Status,
  STATUS_LABEL,
  TESTING_TEST_STATUS,
} from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isProjectOwner } from '@/composables/CProject';

import { useAuthStore } from '@/stores/AuthStore';
import { useTaskStore } from '@/stores/TaskStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import AFrameworkIcon from '@/components/atoms/AFrameworkIcon.vue';
import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import OComments from '@/components/organisms/OComments.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import DRequestReview from '@/components/dialogs/DRequestReview.vue';
import DRequestReassign from '@/components/dialogs/DRequestReassign.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import DControlCreateRIsk from '@/components/dialogs/DControlCreateRIsk.vue';
import DControlCreateTest from '@/components/dialogs/DControlCreateTest.vue';
import DAddRiskTag from '@/components/dialogs/DAddRiskTag.vue';
import SDetailPage from '@/components/skeletons/SDetailPage.vue';

import Guidance from '@/components/molecules/Task/guidance.vue';
import Evidence from '@/components/molecules/Task/evidence.vue';
import Notes from '@/components/molecules/Task/notes.vue';

interface SelectedComponent {
  id: string;
  result: string;
  json_result: object;
}

interface Detail {
  id: number;
  name: string;
  text?: string | null | ComputedRef<string>;
  avatar?: boolean;
  badge?: string | null;
  dropdown?: boolean;
  select?: boolean;
  icon?: string | null;
  useFunction?: (user: string, id: string) => void;
  assignee?: string;
  disable?: boolean;
  label?: string;
  selectIcon?: boolean;
  statusIcon?: boolean;
  items?: UserDisplay[];
}

interface RemoveRisk {
  id: string;
  name: string;
}

const props = defineProps({
  controlId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'openRequirement']);

const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const taskStore = useTaskStore();
const authStore = useAuthStore();
const projectStore = useProjectsStore();
const components = ref<SelectedComponent[]>([]);
const activeTab = ref('control');
const showRequestModal = ref(false);
const showReassignModal = ref(false);
const showConfirmDialog = ref(false);
const isEditor = ref(false);
const controlIsLoading = ref(false);
const reviewers = ref<UserDisplay[]>([]);
const isUpdating = ref(false);
const showRiskCreation = ref(false);
const showTestCreation = ref(false);
const removeRiskAssociation = ref<null | RemoveRisk>(null);
const showRemoveRiskDialog = ref(false);
const confirmationIsLoading = ref(false);
const showAddTagDialog = ref(false);
const tagsExpanded = ref(false);
const requirementsExpanded = ref(false);
// const controlComponents = ref([]);
const guidanceComponent = ref<Component | null>(null);
const evidenceComponent = ref<Component | null>(null);
const notesComponent = ref<Component | null>(null);
const notesElement = ref(null);
const testErrorOpened = ref(false);
const testFailedOpened = ref(false);

let details = shallowRef<Detail[] | null>(null);
const status = ref<string | null>(null);

function toggleTestError() {
  testErrorOpened.value = !testErrorOpened.value;
}

function toggleTestFailed() {
  testFailedOpened.value = !testFailedOpened.value;
}

async function updateTask() {
  if (!taskStore.task) return;
  if (taskStore.task.status !== Status.IN_PROGRESS) {
    $q.notify({
      color: 'red',
      textColor: 'white',
      icon: 'lock',
      message: 'Control is locked and cannot be changed.',
    });
    return;
  }

  const data = {
    components: components.value,
  };

  const params = {
    controlId: taskStore.task.id,
    updateControl: data,
  };

  try {
    isUpdating.value = true;

    const control = await taskStore.updateTask(params);

    successMessage(`Control '${control?.name}' was updated`);
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Function: updateTask',
      data: {
        params: params,
      },
    });
  } finally {
    isUpdating.value = false;
  }
}

const assignedUser = ref('');
const assignLabel = ref('Please select');

function userInitials(name: string) {
  if (!taskStore.task) return;

  if (name === 'Created') {
    return (
      taskStore.task?.created_by.firstname.charAt(0).toUpperCase() +
      taskStore.task?.created_by.lastname.charAt(0).toUpperCase()
    );
  } else if (taskStore.task.modified_by && name === 'Modified') {
    return (
      taskStore.task?.modified_by?.firstname?.charAt(0).toUpperCase() +
      taskStore.task?.modified_by?.lastname?.charAt(0).toUpperCase()
    );
  }
}

async function selectUser(user: string, id: string) {
  if (!taskStore.task || !projectStore.project) return;

  try {
    assignedUser.value = user;
    assignLabel.value = user;

    const params = {
      controlId: taskStore.task.id,
      userId: id,
    };
    const control = await taskStore.updateTaskAssignee(params);

    successMessage(
      `Control '${control?.name}' was successfully assigned to ${control?.assigned_to?.firstname} ${control?.assigned_to?.lastname}`,
    );
  } catch (error) {
    captureException(error, { message: 'Component: DControl, Function: selectUser' });
  }
}

function copyId(id: string) {
  const textToCopy = `import os
from modulos_client import Modulos

client = Modulos(
    # This is the default and can be omitted
    api_key=os.environ.get("MODULOS_API_KEY"),
)
# If file_name is an empty string, the file name will be the original one in
# path_to_file.
client.evidence.upload.artifact_file(
    path_to_file="replace/path/to/file",
    project_id="${taskStore.task?.project.id}",
    component_id="${id}",
    file_name="",
    description="",
)`;

  navigator.clipboard.writeText(textToCopy);

  $q.notify({
    icon: 'cloud_done',
    message: 'Copied',
  });
}

function openRequirement(id: string) {
  emit('openRequirement', id);
}

function openRisk(riskId: string) {
  if (!projectStore.project) return;

  router.push(`/projects/project-detail/${projectStore.project.id}/risk-detail/${riskId}`);
}

function openTest(testId: string) {
  if (!projectStore.project) return;

  router.push(`/projects/project-detail/${projectStore.project.id}/test-detail/${testId}`);
}

function showAddTag() {
  showAddTagDialog.value = true;
}

function changeTab(value: string) {
  updateNotes();
  updateEvidence();
  activeTab.value = value;
}

function closeDialog() {
  showRequestModal.value = false;
  showRiskCreation.value = false;
  showAddTagDialog.value = false;
  showReassignModal.value = false;
  showTestCreation.value = false;
}

function reassignReviewer() {
  showReassignModal.value = true;
}

function closeConfirm() {
  showConfirmDialog.value = false;
  showRemoveRiskDialog.value = false;
}

function cancelReview() {
  showConfirmDialog.value = true;
}

async function confirm() {
  if (!projectStore.project || !taskStore.task) return;

  const params = {
    objectId: taskStore.task.id,
    projectId: projectStore.project.id,
    concept: Concept.Control,
  };

  try {
    confirmationIsLoading.value = true;

    await projectStore.conceptCancelReview(params);

    successMessage('Review(s) request for status change cancelled successfully');

    await taskStore.getControl({ controlId: taskStore.task.id });
    await updateCommentsAndLogs();
    closeConfirm();
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Function: confirm',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

function setRemoveRisk(risk: Risk) {
  removeRiskAssociation.value = risk;
  showRemoveRiskDialog.value = true;
}

async function confirmRemoveAssociation() {
  if (!taskStore.task || !removeRiskAssociation.value) return;

  try {
    confirmationIsLoading.value = true;

    const params = {
      controlId: taskStore.task.id,
      riskId: removeRiskAssociation.value.id,
    };

    await taskStore.removeRiskFromControl(params);

    successMessage(`Association with Risk ${removeRiskAssociation.value.name} was removed`);

    await taskStore.getControlRisks({ controlId: taskStore.task.id });
    await updateCommentsAndLogs();
    closeConfirm();
  } catch (e) {
    captureException(e, {
      message: 'Component: DControl, Function: confirmRemoveAssociation',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

async function updateConcept() {
  if (!taskStore.task) return;

  try {
    await taskStore.getControl({ controlId: taskStore.task.id });
    await updateCommentsAndLogs();
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Function: updateConcept',
    });
  }
}

function componentUpdate(value: TipTapContent, id: string, component: string) {
  if (component === 'evidence') {
    updateEvidence();
  } else if (component === 'notes') {
    updateNotes();
  }

  const componentData: SelectedComponent = {
    id: id,
    result: value.content,
    json_result: value.json_content || {},
  };

  const index = components.value.findIndex((item: SelectedComponent) => item.id === id);
  if (index !== -1) {
    components.value[index] = componentData;
  } else {
    components.value.push(componentData);
  }
}

function updateEvidence() {
  if (!taskStore.task) return;

  evidenceComponent.value =
    taskStore.task.components.find(
      (component: Component) => component.component_type === 'evidence',
    ) || null;
}

function updateNotes() {
  if (!taskStore.task) return;

  notesComponent.value =
    taskStore.task.components.find(
      (component: Component) => component.component_type === 'notes',
    ) || null;
}

function setComponents() {
  if (!taskStore.task) return;

  guidanceComponent.value =
    taskStore.task.components.find(
      (component: Component) => component.component_type === 'guidance',
    ) || null;
  evidenceComponent.value =
    taskStore.task.components.find(
      (component: Component) => component.component_type === 'evidence',
    ) || null;
  notesComponent.value =
    taskStore.task.components.find(
      (component: Component) => component.component_type === 'notes',
    ) || null;
}

async function updateCommentsAndLogs() {
  if (!projectStore.project) return;

  const params = {
    objectId: props.controlId,
    projectId: projectStore.project.id,
    objectType: Concept.Control,
  };

  try {
    await projectStore.getComments(params);

    const logsParams = {
      objectId: props.controlId,
      projectId: projectStore.project.id,
    };

    await projectStore.getProjectLogs(logsParams);
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Function: updateCommentsAndLogs',
    });
  }
}

const reviewMessage = computed(() => {
  if (!taskStore.task || !taskStore.task.pending_review) return '<span>-</span>';

  return `<span><b>${taskStore.task.pending_review.created_by.firstname}</b> <b>${taskStore.task.pending_review.created_by.lastname}</b>
  requested to change status from <b>${taskStore.task.pending_review.old_status}</b> to <b>${taskStore.task.pending_review.requested_status}</b>.</span>`;
});

const errorTests = computed(() => {
  if (!taskStore.controlTests) return [];

  return taskStore.controlTests.filter(
    (test) =>
      test.testing_test_results &&
      test.testing_test_results.length > 0 &&
      test.testing_test_results[0].status === TestingTestStatus.Error,
  );
});

const failedTests = computed(() => {
  if (!taskStore.controlTests) return [];

  return taskStore.controlTests.filter(
    (test) =>
      test.testing_test_results &&
      test.testing_test_results.length > 0 &&
      test.testing_test_results[0].status === TestingTestStatus.Failed,
  );
});

watch(
  () => taskStore.task,
  (newValue) => {
    if (!newValue || !taskStore.task) return;

    status.value =
      taskStore.task.status === Status.IN_REVIEW
        ? STATUS_LABEL.IN_REVIEW
        : taskStore.task.status === Status.IN_PROGRESS
          ? STATUS_LABEL.IN_PROGRESS
          : String(taskStore.task.status).charAt(0).toUpperCase() +
            String(taskStore.task.status).replaceAll('_', ' ').slice(1);
    assignedUser.value =
      taskStore.task.assigned_to?.firstname + ' ' + taskStore.task.assigned_to?.lastname;
    assignLabel.value = taskStore.task.assigned_to?.firstname
      ? taskStore.task.assigned_to?.firstname + ' ' + taskStore.task.assigned_to?.lastname
      : 'Please select';
    reviewers.value = taskStore.task.reviewers || [];

    details.value = [
      {
        id: 1,
        name: 'Created',
        text:
          taskStore?.task.created_by.firstname +
          ' ' +
          taskStore?.task.created_by.lastname +
          ' ' +
          formatDate(taskStore?.task.created_at),
        avatar: true,
      },
      {
        id: 2,
        name: 'Readiness',
        text:
          String(taskStore.task.status).charAt(0).toUpperCase() +
          String(taskStore.task.status).replaceAll('_', ' ').slice(1),
        statusIcon: true,
      },
      {
        id: 3,
        name: 'Review by',
        items: reviewers.value || [],
      },
    ];
  },
  {
    immediate: true,
    deep: true,
  },
);

onMounted(async () => {
  try {
    controlIsLoading.value = true;

    if (route.query.control && typeof route.query.control === 'string') {
      await taskStore.getControl({ controlId: route.query.control });
    } else {
      await taskStore.getControl({ controlId: props.controlId });
    }

    setComponents();
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Hook: onMounted',
    });
  } finally {
    controlIsLoading.value = false;
  }

  if (!projectStore.project || !taskStore.task) return;
  try {
    const userRoles = await userProjectRoles(projectStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;

    await taskStore.getControlRisks({ controlId: taskStore.task.id });
    await taskStore.getControlTests({ controlId: taskStore.task.id });
    await projectStore.getProjectsRisks({
      projectId: projectStore.project.id,
      ...DEFAULT_PAGINATION,
      size: DEFAULT_PAGINATION_SIZE,
    });
    await updateCommentsAndLogs();
    await projectStore.getProjectsEvidence({ projectId: projectStore.project.id });
  } catch (error) {
    captureException(error, {
      message: 'Component: DControl, Hook: onMounted',
    });
  }
});

onUnmounted(() => {
  projectStore.resetCommentsAndLogs();
});
</script>

<template>
  <div
    v-if="taskStore.task && authStore.user && !controlIsLoading"
    class="content-wrapper row col-12"
  >
    <div class="top__container row col-12 q-mb-xl q-px-md">
      <div class="row col-8 items-end">
        <PageTitle :title="taskStore.task.name" style="margin: 0" />
        <q-badge
          :key="taskStore.task.control_code"
          class="q-ml-sm control-badge"
          :class="[taskStore.task.status]"
          :label="taskStore.task.control_code"
        >
          <Icon
            :icon-name="statusIcon(taskStore.task.status)"
            icon-folder="status"
            icon-size="20px"
            class="q-mr-sm"
          />
        </q-badge>
      </div>
      <q-space />
      <q-btn icon="close" text-color="secondary" dense @click="emit('closeDialog')" />
    </div>
    <div
      v-if="
        (taskStore.task.status === Status.IN_REVIEW && activeTab === 'control' && isAdmin()) ||
        (taskStore.task.status === Status.IN_REVIEW &&
          activeTab === 'control' &&
          isProjectOwner()) ||
        (taskStore.task.status === Status.IN_REVIEW && activeTab === 'control' && isEditor)
      "
      class="row col-12 notification__wrapp q-mb-md q-py-md q-px-lg items-center justify-between"
    >
      <div class="notification__left row items-center">
        <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
        <span class="notification__message"
          >This control is under review by
          <b>{{
            reviewers.map((user: UserDisplay) => user.firstname + ' ' + user.lastname).join(',')
          }}</b
          >.</span
        >
      </div>
      <div class="notification__right">
        <q-btn label="Cancel Request" unelevated @click="cancelReview">
          <template #default>
            <Icon icon-name="cancel-review" icon-folder="white" class="q-mr-sm" />
          </template>
        </q-btn>
      </div>
    </div>
    <div
      v-if="
        taskStore.task.status === Status.IN_REVIEW &&
        reviewers &&
        reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id) &&
        activeTab === 'control'
      "
      class="row col-12 notification__wrapp q-mb-md q-py-md q-px-lg items-center justify-between"
    >
      <div class="notification__left row items-center">
        <Icon icon-name="union" icon-folder="task" class="q-mr-sm" />
        <div class="column">
          <span class="notification__message assign-text"
            >Status Change Request assigned to you.</span
          >
          <span class="notification__message" v-html="reviewMessage" />
        </div>
      </div>
      <div class="notification__right">
        <q-btn label="Review Request" unelevated @click="changeTab('comments_logs')"> </q-btn>
      </div>
    </div>
    <div
      v-if="errorTests.length > 0"
      class="row col-12 test-notification__wrapp error-wrapp justify-between q-mb-md q-py-md q-px-lg"
      :class="[testErrorOpened ? 'error-opened' : '']"
    >
      <div class="notification__left row items-center">
        <div class="row col-12">
          <Icon icon-name="error-test" icon-folder="task" class="q-mr-sm" />
          <span class="notification__message assign-text"
            >{{
              `There ${errorTests.length === 1 ? 'is' : 'are'} ${errorTests.length} related ${errorTests.length === 1 ? 'test' : 'tests'} with errors`
            }}.</span
          >
        </div>
        <ul>
          <li v-for="test in errorTests" :key="test.id" class="q-mb-sm">
            {{ `"${test.name}" related to his control has error(s).` }}
            <span class="test-link" @click="openTest(test.id)">Check Now</span>
          </li>
        </ul>
      </div>
      <div class="notification__right">
        <Icon
          v-if="testErrorOpened"
          icon-folder="task"
          icon-name="arrow_up"
          class="toggle-view cursor-pointer"
          @click="toggleTestError"
        />
        <Icon
          v-else
          icon-folder="task"
          icon-name="arrow_down"
          class="toggle-view cursor-pointer"
          @click="toggleTestError"
        />
      </div>
    </div>
    <div
      v-if="failedTests.length > 0"
      class="row col-12 test-notification__wrapp failed-wrapp justify-between q-mb-xl q-py-md q-px-lg"
      :class="[testFailedOpened ? 'failed-opened' : '']"
    >
      <div class="notification__left row items-center">
        <div class="row col-12">
          <Icon icon-name="failed-test" icon-folder="task" class="q-mr-sm" />
          <span class="notification__message assign-text">{{
            `There ${failedTests.length === 1 ? 'is' : 'are'} ${failedTests.length} failed ${failedTests.length === 1 ? 'test' : 'tests'}.`
          }}</span>
        </div>
        <ul>
          <li v-for="test in failedTests" :key="test.id" class="q-mb-sm">
            {{ `"${test.name}" related to his control failed.` }}
            <span class="test-link" @click="openTest(test.id)">Check Now</span>
          </li>
        </ul>
      </div>
      <div class="notification__right">
        <Icon
          v-if="testFailedOpened"
          icon-folder="task"
          icon-name="arrow_up"
          class="toggle-view cursor-pointer"
          @click="toggleTestFailed"
        />
        <Icon
          v-else
          icon-folder="task"
          icon-name="arrow_down"
          class="toggle-view cursor-pointer"
          @click="toggleTestFailed"
        />
      </div>
    </div>
    <div class="wrapp col-12 row">
      <div class="task__page-left">
        <OTabPanels
          :active-tab="activeTab"
          :default-size="true"
          default-tab="control"
          @change-tab="changeTab"
        >
          <template #tabs>
            <q-tab content-class="my-tab" name="control" label="Control"> </q-tab>
            <q-tab content-class="my-tab" name="report" label="Report"> </q-tab>
            <q-tab content-class="my-tab" name="comments_logs" label="Comments and Logs">
              <span
                v-if="projectStore.projectLogs.length > 0 || projectStore.comments.length > 0"
                class="total-badge q-ml-sm"
              >
                {{
                  projectStore.projectLogs.length + projectStore.comments.length < 10
                    ? '0' + (projectStore.projectLogs.length + projectStore.comments.length)
                    : projectStore.projectLogs.length + projectStore.comments.length
                }}</span
              >
            </q-tab>
          </template>
          <template #panels>
            <q-tab-panel name="control" class="row col-12">
              <div class="row col-12 left__details q-mb-lg">
                <div class="row col-12 overview__container q-mb-lg">
                  <Icon icon-name="task-overview" icon-folder="task" />
                  <span class="overview__title q-ml-sm">Overview</span>
                </div>
                <div class="control__question col-12 column">
                  <span class="question__title q-my-md">Question</span>
                  <p>{{ taskStore.task.question }}</p>
                </div>
                <div class="control__description column">
                  <span class="description__title q-my-md">Description</span>
                  <p>{{ taskStore.task.description }}</p>
                </div>
              </div>
              <div class="row components__container col-12 column">
                <Guidance
                  v-if="guidanceComponent"
                  :component="guidanceComponent"
                  class="q-mb-md"
                  @update="componentUpdate"
                >
                  <template #copy-btn>
                    <q-btn
                      unelevated
                      class="copy-btn row justify-center items-center"
                      @click="copyId(guidanceComponent.id)"
                    >
                      <Icon icon-name="icon-code" icon-folder="white" />
                      <q-tooltip>Copy code to programmatically upload files</q-tooltip>
                    </q-btn>
                  </template>
                  <template
                    v-if="
                      (!taskStore.task.is_locked && isProjectOwner()) ||
                      (!taskStore.task.is_locked && isAdmin()) ||
                      (!taskStore.task.is_locked && isEditor)
                    "
                    #update
                  >
                    <div class="row col-12 justify-end q-mt-md">
                      <q-btn
                        :loading="isUpdating"
                        label="Submit"
                        unelevated
                        :disable="isUpdating"
                        @click="updateTask"
                      />
                    </div>
                  </template>
                </Guidance>
                <Evidence
                  v-if="evidenceComponent"
                  :component="evidenceComponent"
                  class="q-mb-md"
                  @update="componentUpdate"
                >
                  <template #copy-btn>
                    <q-btn
                      unelevated
                      class="copy-btn row justify-center items-center"
                      @click="copyId(evidenceComponent.id)"
                    >
                      <Icon icon-name="icon-code" icon-folder="white" />
                      <q-tooltip>Copy code to programmatically upload files</q-tooltip>
                    </q-btn>
                  </template>
                  <template
                    v-if="
                      (!taskStore.task.is_locked && isProjectOwner()) ||
                      (!taskStore.task.is_locked && isAdmin()) ||
                      (!taskStore.task.is_locked && isEditor)
                    "
                    #update
                  >
                    <div class="row col-12 justify-end q-mt-md">
                      <q-btn
                        :loading="isUpdating"
                        label="Submit"
                        unelevated
                        :disable="isUpdating"
                        @click="updateTask"
                      />
                    </div>
                  </template>
                </Evidence>
              </div>
            </q-tab-panel>
            <q-tab-panel name="report" class="row col-12 components__container">
              <Notes
                v-if="notesComponent"
                ref="notesElement"
                :component="notesComponent"
                class="q-mb-md col-12"
                @update="componentUpdate"
              >
                <template #copy-btn>
                  <q-btn
                    unelevated
                    class="copy-btn row justify-center items-center"
                    @click="copyId(notesComponent.id)"
                  >
                    <Icon icon-name="icon-code" icon-folder="white" />
                    <q-tooltip>Copy code to programmatically upload files</q-tooltip>
                  </q-btn>
                </template>
                <template
                  v-if="
                    (!taskStore.task.is_locked && isProjectOwner()) ||
                    (!taskStore.task.is_locked && isAdmin()) ||
                    (!taskStore.task.is_locked && isEditor)
                  "
                  #update
                >
                  <div class="row col-12 justify-end q-mt-md">
                    <q-btn
                      :loading="isUpdating"
                      label="Submit"
                      unelevated
                      :disable="isUpdating"
                      @click="updateTask"
                    />
                  </div>
                </template>
              </Notes>
            </q-tab-panel>
            <q-tab-panel name="comments_logs" class="row col-12">
              <OComments
                :object-type="'control'"
                :reviewer="
                  reviewers &&
                  reviewers.length > 0 &&
                  reviewers.map((user: UserDisplay) => user.id).includes(authStore.user.id)
                "
                :in-review="taskStore.task.status === Status.IN_REVIEW"
                :object-id="taskStore.task.id"
                :editable="!taskStore.task.is_locked"
                @update-concept="updateConcept"
                @reassign-reviewer="reassignReviewer"
              />
            </q-tab-panel>
          </template>
        </OTabPanels>
      </div>
      <q-space />
      <div class="task__page-right">
        <div class="top-actions__container row items-center">
          <!-- <q-btn flat label="Risk Association" /> -->
          <q-btn class="btn-export q-mr-md" label="Export" unelevated disable>
            <Icon icon-name="icon-export" icon-folder="colored" />
          </q-btn>
          <q-btn
            v-if="
              taskStore.task.status !== Status.IN_REVIEW &&
              (isAdmin() || isProjectOwner() || isEditor)
            "
            class="q-ml-md request-btn"
            unelevated
            :disable="!isProjectOwner() && !isAdmin() && !isEditor"
            label="Request status change"
            @click="showRequestModal = true"
          />
        </div>
        <div class="task__details column q-mb-lg">
          <div class="details--top__container row items-center q-mb-md">
            <Icon icon-name="info" icon-folder="colored" />
            <span class="details__title q-ml-sm">Info</span>
          </div>
          <div v-for="detail in details" :key="detail.id" class="row items-center detail-row">
            <span class="col-12 q-mb-xs">{{ detail.name }}</span>
            <div class="row full-width items-center">
              <q-avatar v-if="detail.avatar && detail.text" size="25px">
                <span>{{ userInitials(detail.name) }}</span>
              </q-avatar>
              <div class="row items-center col-10">
                <Icon
                  v-if="detail.statusIcon"
                  :icon-name="statusIcon(taskStore.task.status)"
                  icon-folder="status"
                  class="q-mr-xs"
                  icon-size="20px"
                />
                <span v-if="detail.text" class="text-black detail-value">{{ detail.text }}</span>
                <div v-if="detail.items && detail.items.length > 0" class="items__container">
                  <q-badge
                    v-for="item in detail.items"
                    :key="item.id"
                    class="q-mr-sm q-mb-sm user-badge"
                    :label="item.firstname + ' ' + item.lastname"
                  />
                </div>
                <span
                  v-if="detail.items && detail.items.length === 0"
                  class="text-black detail-value"
                  >-</span
                >
              </div>
            </div>
          </div>
          <div class="row assignee-row">
            <span class="col-12 q-mb-xs">Assigned to</span>
            <q-btn-dropdown
              v-if="!taskStore.task.is_locked && (isProjectOwner() || isAdmin() || isEditor)"
              flat
              :label="assignLabel"
              :disable="taskStore.task.is_locked || (!isProjectOwner() && !isAdmin() && !isEditor)"
              class="col-12"
            >
              <AUsersDropDown
                :assigned-user="taskStore.task.assigned_to || null"
                @select-user="selectUser"
              />
            </q-btn-dropdown>
            <span v-else class="text-black detail-value">{{
              taskStore.task.assigned_to
                ? taskStore.task.assigned_to.firstname + ' ' + taskStore.task.assigned_to.lastname
                : '-'
            }}</span>
          </div>
        </div>
        <hr />
        <div class="control__requirements column q-mb-lg">
          <span class="requirement__title q-my-sm">Mapped Frameworks</span>
          <div class="row">
            <AFrameworkIcon
              v-for="framework in taskStore.task.frameworks"
              :key="framework.id"
              :framework="framework"
              class="q-mr-sm q-mb-sm"
            />
          </div>
        </div>
        <hr />
        <div class="control__requirements column">
          <span class="requirement__title q-my-sm">{{
            `Mapped Requirements (${taskStore.task.requirements.length})`
          }}</span>
          <div
            class="row requirements__container items-center q-mb-sm"
            :class="[requirementsExpanded ? 'requirements__expanded' : '']"
          >
            <q-badge
              v-for="requirement in taskStore.task.requirements"
              :key="requirement.id"
              class="requirement-badge cursor-pointer q-mr-sm q-mb-sm"
              :label="requirement.requirement_code"
              :class="[requirement.status]"
              @click="openRequirement(requirement.id)"
            >
              <Icon
                :icon-name="statusIcon(requirement.status as Status)"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </div>
          <div class="row arrow__container col-12 justify-center">
            <Icon
              v-if="!requirementsExpanded"
              icon-folder="task"
              icon-name="arrow_down"
              class="cursor-pointer"
              @click="requirementsExpanded = true"
            />
            <Icon
              v-else
              icon-folder="task"
              icon-name="arrow_up"
              class="cursor-pointer"
              @click="requirementsExpanded = false"
            />
          </div>
        </div>
        <hr />
        <div class="control__risks column q-mb-lg">
          <div class="row col-12 justify-between q-my-sm">
            <span class="associated__title">Associated Risks</span>
          </div>
          <div
            v-if="taskStore.controlRisks && taskStore.controlRisks.length > 0"
            class="risks__container col-12 q-mb-md"
          >
            <q-badge
              v-for="risk in taskStore.controlRisks"
              :key="risk.id"
              class="risk-badge q-mr-sm q-mb-sm cursor-pointer"
              :class="[risk.status]"
              @click="openRisk(risk.id)"
            >
              <Icon
                :icon-name="statusIcon(risk.status)"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <span>{{ risk.name }}</span>
              <Icon
                icon-name="close"
                icon-folder="task"
                icon-size="12px"
                class="q-ml-sm"
                :class="[taskStore.task.is_locked ? 'cursor-not-allowed' : '']"
                @click.stop.prevent="
                  taskStore.task && taskStore.task.is_locked ? null : setRemoveRisk(risk)
                "
              />
              <q-tooltip anchor="top middle" self="center middle">
                {{ risk.name }}
              </q-tooltip>
            </q-badge>
          </div>
          <div v-if="isProjectOwner() || isAdmin() || isEditor" class="actions__container col-12">
            <q-btn
              class="btn-create full-width"
              label="Associate to a Risk"
              unelevated
              :disable="(!isProjectOwner() && !isAdmin() && !isEditor) || taskStore.task.is_locked"
              @click="showRiskCreation = true"
            />
          </div>
        </div>
        <hr />
        <div
          v-if="
            (taskStore.controlTests && taskStore.controlTests.length > 0) ||
            taskStore.task.status === Status.COMPLETED
          "
          class="control__tests column q-mb-lg"
        >
          <div class="row col-12 justify-between q-my-sm">
            <span class="associated__title">Associated Tests</span>
          </div>
          <div class="tests__container col-12">
            <q-badge
              v-for="test in taskStore.controlTests"
              :key="test.id"
              class="cursor-pointer q-mr-md q-mb-sm"
              :class="`${TESTING_TEST_STATUS(test.testing_test_results && test.testing_test_results.length > 0 ? test.testing_test_results[0].status : 'passed')}-badge`"
              :label="test.name"
              @click="openTest(test.id)"
            >
              <Icon
                :icon-name="`testing-${TESTING_TEST_STATUS(test.testing_test_results && test.testing_test_results.length > 0 ? test.testing_test_results[0].status : 'passed')}-active`"
                icon-folder="testing"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </div>

          <div
            v-if="
              taskStore.task.status === Status.COMPLETED &&
              (isProjectOwner() || isAdmin() || isEditor)
            "
            class="actions__container col-12"
          >
            <q-btn
              class="btn-create full-width"
              label="Associate to a Test"
              unelevated
              :disable="
                (!isProjectOwner() && !isAdmin() && !isEditor) ||
                taskStore.task.status !== Status.COMPLETED
              "
              @click="showTestCreation = true"
            />
          </div>
        </div>
        <hr
          v-if="
            (taskStore.controlTests && taskStore.controlTests.length > 0) ||
            taskStore.task.status === Status.COMPLETED
          "
        />
        <div class="control__tags column">
          <span class="tags__title q-my-sm">{{ `TAGS (${taskStore.task.tags.length})` }}</span>
          <div class="tags__container row" :class="[tagsExpanded ? 'tags__expanded' : '']">
            <q-badge
              v-for="tag in taskStore.task.tags"
              :key="tag.name + tag.value"
              class="general-badge q-mr-sm q-mb-sm"
              >{{ `${tag.name}:` }}&nbsp;<b>{{ tag.value }}</b></q-badge
            >
          </div>
          <div class="row arrow__container col-12 justify-center">
            <Icon
              v-if="!tagsExpanded"
              icon-folder="task"
              icon-name="arrow_down"
              class="cursor-pointer"
              @click="tagsExpanded = true"
            />
            <Icon
              v-else
              icon-folder="task"
              icon-name="arrow_up"
              class="cursor-pointer"
              @click="tagsExpanded = false"
            />
          </div>
          <q-btn
            v-if="isProjectOwner() || isAdmin() || isEditor"
            class="btn-create full-width q-mt-sm"
            label="Update Tag(s)"
            unelevated
            :disable="taskStore.task.is_locked || (!isProjectOwner() && !isAdmin() && !isEditor)"
            @click="showAddTag"
          />
        </div>
      </div>
    </div>
  </div>
  <SDetailPage v-if="controlIsLoading" />
  <ADialog
    v-if="taskStore.task"
    :show-dialog="showRequestModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DRequestReview
      :concept="taskStore.task"
      :concept-type="'control' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    v-if="taskStore.task"
    :show-dialog="showReassignModal"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DRequestReassign
      :concept="taskStore.task"
      :concept-type="'control' as Concept"
      @close-dialog="closeDialog"
      @update-concept="updateConcept"
    />
  </ADialog>
  <ADialog
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DConfirm
      title="Cancel Request"
      description="Would you like to cancel the Status Change Request?"
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirm"
    />
  </ADialog>
  <ADialog
    :show-dialog="showRiskCreation"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showRiskCreation = false"
  >
    <DControlCreateRIsk
      v-if="taskStore.task"
      :control="taskStore.task"
      @close-dialog="closeDialog"
    />
  </ADialog>

  <ADialog
    :show-dialog="showTestCreation"
    max-height="auto !important"
    max-width="600px !important"
    min-height="auto !important"
    min-width="600px !important"
    @hide="showTestCreation = false"
  >
    <DControlCreateTest
      v-if="taskStore.task"
      :control="taskStore.task"
      @close-dialog="closeDialog"
    />
  </ADialog>

  <ADialog
    v-if="removeRiskAssociation"
    :show-dialog="showRemoveRiskDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showRemoveRiskDialog = false"
  >
    <DConfirm
      :title="`Would you like to remove the association with the Risk ${removeRiskAssociation.name} ?`"
      description=""
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirmRemoveAssociation"
    />
  </ADialog>

  <ADialog
    v-if="taskStore.task"
    :show-dialog="showAddTagDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="closeDialog"
  >
    <DAddRiskTag
      :concept-name="Concept.Control"
      :concept="taskStore.task"
      :concept-id="controlId"
      @close-dialog="closeDialog"
    />
  </ADialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.right__actions {
  margin-bottom: 20px;

  button {
    padding: 4px;
    margin-left: 20px;

    :deep(.q-btn__content) {
      line-height: 0;
    }
  }

  :deep(.q-btn-dropdown) {
    i {
      color: $black;
    }
  }
}

.top__container {
  .q-btn::before {
    box-shadow: none !important;
  }
}

.notification__wrapp {
  border-radius: 4px;
  border: 1px solid #fd956b;
  background: #fff8e6;
  .notification__left {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    .assign-text {
      font-size: 16px;
    }
  }
  .notification__right {
    button {
      background: $secondary-500 !important;
      color: $white;
      text-transform: none;
      :deep(.q-btn__content) {
        flex-direction: row-reverse;
      }
    }
  }
}

.test-notification__wrapp {
  max-height: 50px !important;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #fd956b;
  background: #fff8e6;
  .notification__left {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    .assign-text {
      font-size: 16px;
    }
  }
  .notification__right {
    button {
      background: $secondary-500 !important;
      color: $white;
      text-transform: none;
      :deep(.q-btn__content) {
        flex-direction: row-reverse;
      }
    }
  }
  .test-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.error-opened,
.failed-opened {
  max-height: unset !important;
}

.error-wrapp {
  background: #fffbe6;
  border: 1px solid #ffb800;
}

.failed-wrapp {
  background: #fff1f0;
  border: 1px solid #f96f7f;
}

.task__page-left {
  width: 70%;
  border-radius: 5px;
  @media screen and (max-width: 1440px) {
    width: 59.5%;
  }

  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .left__details {
    padding: 20px;
    background: $white;

    .overview__container {
      .overview__title {
        @include heading-04(600, $secondary-600);
      }
    }

    .control__description {
      .description__title {
        @include heading-05(700, $secondary-600);
      }

      p {
        @include paragraph-01(400, $secondary-600);
      }
    }

    .control__question {
      .question__title {
        @include heading-05(700, $secondary-600);
      }

      p {
        @include paragraph-01(400, $secondary-600);
      }
    }

    .control__readiness {
      .readiness__title {
        @include heading-05(700, $secondary-600);
      }

      label {
        width: 200px !important;
      }
    }
  }

  .task__details {
    @include paragraph-01(400, $common-2);

    span:first-child {
      color: $black !important;
    }
  }

  .components__container {
    .copy-btn {
      // position: absolute;
      // right: 0;
      padding: 10px 16px;
      color: $black;
      background: $secondary-500;
      font-size: 0.8em;
      border-radius: 5px;

      :deep(.q-btn__content span) {
        max-width: 100px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    button {
      background: $secondary-500 !important;
      color: $white;
      border-radius: 5px;
      text-transform: none;
      padding: 4px 24px;
    }
  }

  .actions__container,
  .submit__container {
    button {
      background: $secondary-500 !important;
      color: $white;
      border-radius: 5px;
      text-transform: none;
      padding: 4px 24px;
    }
  }
}

.task__page-right {
  width: 28.5%;
  height: fit-content;
  margin-top: 70px;
  padding: 25px 20px 25px 35px;
  background: $white;
  border-radius: 5px;
  position: relative;
  @media screen and (max-width: 1440px) {
    width: 40%;
  }

  .top-actions__container {
    position: absolute;
    right: 0;
    top: -60px;
    button {
      text-transform: unset;
      border-radius: 5px;
    }
    // button:first-child {
    //   background: transparent !important;
    //   color: $secondary-500 !important;
    //   border: 1px solid $secondary-500;
    // }
    .request-btn {
      background: $secondary-500 !important;
      color: $white;
    }
  }

  hr {
    border: 1px solid $secondary-100;
  }

  .task__details {
    @include caption(400, $common-2);

    .details__title {
      @include paragraph-02(600, $secondary-600);
    }

    span:first-child {
      margin-right: 8px;
    }

    .detail-row {
      margin-bottom: 20px;

      .detail-value {
        font-size: 14px;
        color: $common-4;
      }
    }

    .assignee-row {
      .detail-value {
        font-size: 14px;
        color: $common-4;
      }

      :deep(.q-btn__content) {
        span {
          text-transform: none;
        }
      }
      .q-btn-dropdown {
        border: 1px solid rgba(0, 0, 0, 0.24);

        :deep(.q-focus-helper) {
          display: none;
        }

        :deep(span) {
          color: rgba(0, 0, 0, 0.87) !important;
        }

        :deep(i) {
          color: $common-3 !important;
        }
        :deep(.q-btn__content) {
          justify-content: space-between !important;
        }
      }

      .q-btn-dropdown:hover {
        border: 1px solid rgba(0, 0, 0, 1);
      }
    }

    .q-avatar {
      background: $secondary-100;
      margin-right: 5px;

      span {
        margin: 0 !important;
      }
    }
  }

  .control__requirements {
    .requirement__title {
      @include caption(400, $common-2);
    }

    .requirements__container {
      max-height: 33px;
      overflow: hidden;
    }

    .requirements__expanded {
      max-height: unset;
    }

    .badge__title {
      @include paragraph-02(600, $secondary-800);
    }
    .q-badge:hover {
      opacity: 0.7;
    }

    :deep(img) {
      width: 32px;
    }
  }

  .control__risks,
  .control__tests {
    .associated__title {
      @include caption(400, #8c8c8c);
    }
    .actions__container {
      button {
        height: 40px;
        color: $secondary-500;
        background: transparent !important;
        border: 1px solid $secondary-500;
        text-transform: none;
        border-radius: 5px;
      }
      :deep(.q-field__label) {
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
    .risks__container {
      .q-badge:hover {
        span {
          opacity: 0.7;
        }
      }
    }
  }

  .control__tags {
    .tags__title {
      @include caption(400, $common-2);
    }
    .tags__container {
      max-height: 30px;
      overflow: hidden;
    }
    .tags__expanded {
      max-height: unset;
    }
    .btn-create {
      height: 40px;
      color: $secondary-500;
      background: transparent !important;
      border: 1px solid $secondary-500;
      text-transform: none;
      border-radius: 5px;
    }
  }

  .arrow__container {
    :deep(svg path) {
      fill: #a5b7c0;
    }
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
