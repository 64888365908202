<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import type {
  ControlAggregator,
  ControlList,
  FrameworkListDisplay,
  RequirementAggregator,
} from '@/client/api';
import type { PropType } from 'vue';

import { Status, ItemBorder } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

interface Badge {
  id: string;
  name: string;
  status: string;
  control_code?: string;
  framework_code?: string;
  requirement_code?: string;
}

interface Tag {
  name: string;
  value: string;
}

const emit = defineEmits(['openControl', 'openRequirement']);

const props = defineProps({
  data: {
    type: Object as PropType<RequirementAggregator | ControlAggregator | ControlList>,
    required: true,
  },
  allSelected: {
    type: Boolean,
  },
  frameworks: {
    type: Array as PropType<FrameworkListDisplay[]>,
    default: () => [],
  },
  badges: {
    type: Array as PropType<Badge[]>,
    default: () => [],
  },
  tags: {
    type: Array as PropType<Tag[]>,
    default: () => [],
  },
  secondaryText: {
    type: String,
    default: '',
  },
});

const select = ref(false);
const route = useRoute();
const projectsStore = useProjectsStore();

function openDialog() {
  if (!projectsStore.project) return;

  if (route.name === 'project-controls') {
    emit('openControl', props.data.id);
  } else {
    emit('openRequirement', props.data.id);
  }
}

async function openControl(id: string) {
  emit('openControl', id);
}

async function openRequirement(id: string) {
  emit('openRequirement', id);
}

function getTitle(data: RequirementAggregator | ControlAggregator | ControlList = props.data) {
  if ('control_code' in data) {
    return `${data.name} (${data.control_code})`;
  } else if ('requirement_code' in data) {
    return `${data.name} (${data.requirement_code})`;
  }
}

function isControl(
  data: RequirementAggregator | ControlAggregator | ControlList,
): data is ControlAggregator {
  return 'n_comments' in data;
}

const sortedBadges = computed(() => {
  return props.badges.slice().sort((a: Badge, b: Badge) => {
    if (a.control_code && b.control_code) {
      return a.control_code.localeCompare(b.control_code);
    } else if (a.requirement_code && b.requirement_code) {
      return a.requirement_code.localeCompare(b.requirement_code);
    }
    return 0;
  });
});

watch(
  () => props.allSelected,
  (value) => {
    select.value = value;
  },
);

defineExpose({
  openControl,
});
</script>

<template>
  <div
    class="item__container q-py-md row items-start"
    :style="{ 'border-left': `4px solid ${ItemBorder(props.data.status)}` }"
    @click="openDialog"
  >
    <!-- <q-checkbox v-model="select" class="q-mr-sm" /> -->
    <div class="icon__container q-pt-sm">
      <Icon
        v-if="data.status === Status.COMPLETED"
        icon-name="check_circle"
        icon-folder="status"
        icon-size="20px"
      />
      <Icon
        v-if="data.status === Status.IN_REVIEW"
        icon-name="ready"
        icon-folder="status"
        icon-size="20px"
      />
      <Icon
        v-if="data.status === Status.IN_PROGRESS"
        icon-name="in_progress"
        icon-folder="status"
        icon-size="20px"
      />
      <Icon
        v-if="data.status === Status.OUT_OF_SCOPE"
        icon-name="out_of_scope"
        icon-folder="status"
        icon-size="20px"
      />
    </div>
    <div class="item__info column col-10 q-ml-lg q-pt-sm">
      <div class="row">
        <p class="item__title">
          {{ getTitle() }}
        </p>
        <div class="item__codes q-ml-md">
          <q-badge
            v-for="badge in frameworks"
            :key="badge.framework_code"
            class="framework-badge q-mr-sm q-mb-sm"
          >
            <span>{{ badge.framework_code }}</span>

            <q-tooltip anchor="top middle" self="bottom middle" class="project-tooltip">
              {{ `${badge.name}` }}
            </q-tooltip></q-badge
          >
        </div>
      </div>
      <p class="item__description">{{ data.description }}</p>
      <div class="row justify-between">
        <div class="column">
          <span class="secondary__text q-mb-sm">{{ secondaryText.toUpperCase() }}</span>
          <div class="item__badges">
            <q-badge
              v-for="badge in sortedBadges"
              :key="badge.requirement_code || badge.control_code"
              class="requirement-badge q-mr-sm q-mb-sm"
              :class="[
                badge.status === Status.COMPLETED
                  ? Status.COMPLETED
                  : badge.status === Status.IN_REVIEW
                    ? Status.IN_REVIEW
                    : badge.status === Status.IN_PROGRESS
                      ? Status.IN_PROGRESS
                      : Status.OUT_OF_SCOPE,
              ]"
              :label="badge.requirement_code || badge.control_code"
              @click.stop.prevent="
                badge.control_code ? openControl(badge.id) : openRequirement(badge.id)
              "
            >
              <Icon
                v-if="badge.status === Status.COMPLETED"
                icon-name="check_circle"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <Icon
                v-else-if="badge.status === Status.IN_REVIEW"
                icon-name="ready"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <Icon
                v-else-if="badge.status === Status.IN_PROGRESS"
                icon-name="in_progress"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
              <Icon
                v-else-if="badge.status === Status.OUT_OF_SCOPE"
                icon-name="out_of_scope"
                icon-folder="status"
                icon-size="20px"
                class="q-mr-sm"
              />
            </q-badge>
          </div>
        </div>
        <div v-if="isControl(data)" class="control__tags column">
          <span class="tags__title q-mb-sm">TAGS</span>
          <div class="tags__container row">
            <q-badge
              v-for="tag in tags"
              :key="tag.name"
              class="general-badge q-mr-sm q-mb-sm text-uppercase"
              >{{ tag.name }}:&nbsp;<b>{{ tag.value }}</b></q-badge
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isControl(data)"
      class="counter__container column justify-center items-center q-pt-sm"
    >
      <!-- <Icon icon-name="task" icon-folder="colored" /> -->
      <div class="row items-center">
        <Icon icon-name="task-empty" icon-folder="colored" icon-size="16px" />
        <span class="q-ml-sm">{{ data.n_artifacts }}</span>
      </div>
      <div class="row items-center">
        <Icon icon-name="chat" icon-folder="colored" icon-size="16px" />
        <span class="q-ml-sm">{{ data.n_comments }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.item__container {
  padding-left: 35px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .icon__container {
    width: 20px;
    svg {
      margin-top: 3px;
    }
  }

  :deep(.q-checkbox__inner--falsy) {
    .q-checkbox__bg {
      color: $common-1;
    }
  }

  :deep(.q-checkbox__inner--truthy) {
    .q-checkbox__bg {
      color: $secondary-700;
    }
  }

  .item__info {
    .item__title {
      @include paragraph-02(600, $common-5);
    }

    .item__description {
      @include paragraph-01(400, $common-5);
      opacity: 0.7;
      margin-bottom: 20px !important;
    }

    .secondary__text {
      @include caption(400, $secondary-400);
    }

    .control__tags {
      .tags__title {
        @include caption(400, $secondary-400);
      }
    }

    .item__badges {
      .q-badge:hover {
        opacity: 0.7;
      }
    }
  }

  .counter__container {
    position: absolute;
    right: 40px;

    span {
      @include paragraph-02(600, $secondary-600);
    }
  }
}

.item__container:hover {
  background: rgba(240, 243, 244, 0.7);
}
</style>
