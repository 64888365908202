<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { PropType } from 'vue';
import type { FrameworkDisplay } from '@/client/api';

import Icon from '@/components/atoms/Icon.vue';
import MCircularProgress from '@/components/molecules/MCircularProgress.vue';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { usePaginationStore } from '@/stores/PaginationStore';

const props = defineProps({
  card: {
    type: Object as PropType<FrameworkDisplay>,
    required: true,
  },
});

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const frameworkImage = ref<string | null>(null);

onMounted(() => {
  if (props.card.icon_type === 'image' && props.card.icon_base64) {
    frameworkImage.value = `data:image/svg+xml;base64,${props.card.icon_base64}`;
  }
});
</script>

<template>
  <q-card v-if="card" class="my-card text-white column justify-between">
    <div class="top__sections-wrapp">
      <q-card-section class="title__section row q-pb-none q-mb-xs items-center">
        <span
          v-if="card.icon_type === 'flag'"
          class="fi q-mr-sm flag"
          :class="`fi-${card.icon}`"
        ></span>
        <img
          v-if="card.icon_type === 'image' && card.icon_base64 && frameworkImage"
          :src="frameworkImage"
          class="q-mr-sm"
        />
        <span class="text-h6 col-8">{{ card.name }}</span>
      </q-card-section>
      <q-card-section class="description__section row q-pb-none q-mb-xs items-center">
        <p>{{ card.description }}</p>
      </q-card-section>
    </div>
    <div class="bot__sections-wrapp">
      <q-card-section class="row justify-center">
        <MCircularProgress :progress="card.control_status_overview" />
      </q-card-section>
      <q-card-section class="content__section q-pt-none q-mt-lg column justify-between">
        <div class="project__details">
          <div class="row justify-between q-mb-lg">
            <div class="column items-start q-mb-sm">
              <Icon v-if="!$q.dark.isActive" icon-name="check_circle" icon-folder="black" />
              <Icon v-else icon-name="check_circle" icon-folder="white" />
              <span class="black-text">{{
                card.requirement_status_overview.completed +
                `/${
                  card.requirement_status_overview.in_progress +
                  card.requirement_status_overview.in_review +
                  card.requirement_status_overview.completed
                }`
              }}</span>
              <span>Requirements</span>
            </div>
            <div class="column items-end q-mb-sm">
              <Icon v-if="!$q.dark.isActive" icon-name="page_info" icon-folder="black" />
              <Icon v-else icon-name="page_info" icon-folder="white" />
              <span class="black-text">{{
                card.control_status_overview.completed +
                `/${
                  card.control_status_overview.in_progress +
                  card.control_status_overview.in_review +
                  card.control_status_overview.completed
                }`
              }}</span>
              <span>Controls</span>
            </div>
          </div>
        </div>
        <div class="tasks__total row justify-end">
          <q-btn
            v-if="projectsStore.project"
            flat
            color="primary"
            label="See details"
            class="full-width"
            :to="{
              path: `/projects/project-detail/${projectsStore.project.id}/requirements`,
              query: {
                framework: card.framework_code,
              },
            }"
            @click="paginationStore.setFrameworkQuery(card.framework_code)"
          />
        </div>
      </q-card-section>
    </div>
  </q-card>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.my-card {
  height: 100%;
  @include paragraph-01(400, #8e969d);
  color: #8e969d !important;
  box-shadow: none !important;
  .black-text {
    @include paragraph-01(600, $secondary-800);
  }
  .text-h6 {
    @include paragraph-02(500, $secondary-800);
    margin-right: 5px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  hr {
    border-top: none;
    border-bottom: 1px solid #e6e6e6;
  }
  .title__section {
    position: relative;
    .flag {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      background-size: cover;
    }
    :deep(img) {
      min-width: 42px;
      min-height: 42px;
      max-width: 42px;
      max-height: 42px;
      width: auto;
      height: auto;
      display: block;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .description__section {
    p {
      @include paragraph-01(400, $secondary-600);
    }
  }
  .content__section {
    .tasks__total {
      :deep(a) {
        min-height: 40px;
        background: transparent;
        border: 1px solid $secondary-500;

        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
      }
      :deep(button) {
        min-height: 40px;
        background: transparent;
        border: 1px solid $secondary-500;

        span {
          @include paragraph-01(500, $secondary-500);
          text-transform: none;
        }
      }
    }
  }
}
</style>
