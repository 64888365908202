import { computed } from 'vue';

export const ImpactLabels = ['very high', 'high', 'medium', 'low', 'very low'];
export const LikelihoodLabels = ['very low', 'low', 'medium', 'high', 'very high'];
export const DEFAULT_THRESHOLD = 0.3;

export const RISK_LEVEL_LABEL = {
  VERY_LOW: 'Very Low',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  VERY_HIGH: 'Very High',
} as const;

export const RISK_DETAIL_NAVIGATION = {
  ASSESSMENT: 'Assessment',
  TREATMENT: 'Treatment',
} as const;

export const TREAT_DECISION = {
  TREAT: 'treat',
  DONT_TREAT: 'dontTreat',
} as const;

export const RISK_COMPONENTS = {
  EVALUATION: 'Evaluation',
} as const;

export const RpnLevel = (value: string | number) => {
  const getResidualLevel = computed(() => {
    if (value === '-') return null;

    const result = Number(value);

    if (result === null) {
      return null;
    } else if (result < 0.2) {
      return RISK_LEVEL_LABEL.VERY_LOW;
    } else if (result < 0.4) {
      return RISK_LEVEL_LABEL.LOW;
    } else if (result < 0.6) {
      return RISK_LEVEL_LABEL.MEDIUM;
    } else if (result < 0.8) {
      return RISK_LEVEL_LABEL.HIGH;
    } else {
      return RISK_LEVEL_LABEL.VERY_HIGH;
    }
  });

  return getResidualLevel.value;
};
