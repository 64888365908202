<script setup lang="ts">
import { useRoute } from 'vue-router';

import type { FrameworkDisplay } from '@/client/api';
import type { PropType } from 'vue';

import { Status, STATUS_LABEL } from '@/composables/utils';
import { captureException } from '@/composables/Sentry';

import Icon from '@/components/atoms/Icon.vue';

import { DEFAULT_PAGINATION, SMALLER_PAGINATION_SIZE } from '@/interfaces/models/Pagination';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

interface Item {
  id: number;
  icon: string;
  text: string;
  value: string;
}

interface Filter {
  type: string;
  text: string;
  route: string;
  pagination: string;
}

defineProps({
  title: {
    type: String,
    default: null,
  },
  items: {
    type: Array as PropType<Item[]>,
    required: true,
  },
  data: {
    type: Array as PropType<FrameworkDisplay[]>,
    required: true,
  },
  filter: {
    type: Object as PropType<Filter>,
    default: null,
  },
});

const route = useRoute();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();

function filterByFramework(code: string) {
  paginationStore.setFrameworkQuery(code);
  filterData();
}

function filterByStatus(status: string) {
  if (status === STATUS_LABEL.IN_REVIEW) {
    paginationStore.setStatusFilter(Status.IN_REVIEW);
    filterData();
    return;
  } else if (status === STATUS_LABEL.IN_PROGRESS) {
    paginationStore.setStatusFilter(Status.IN_PROGRESS);
    filterData();
    return;
  }

  paginationStore.setStatusFilter(status as Status);
  filterData();
}

function filterByAssigned(assigned: boolean) {
  paginationStore.setAssignedToMe(assigned);
  paginationStore.setAssignedForReview(false);
  filterData();
}

function filterByReview(assigned: boolean) {
  paginationStore.setAssignedToMe(false);
  paginationStore.setAssignedForReview(assigned);
  filterData();
}

async function filterData() {
  if (!projectsStore.project) return;

  try {
    switch (route.name) {
      case 'project-requirements':
        await projectsStore.getProjectRequirements({
          projectId: projectsStore.project.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
          name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
          framework: paginationStore.frameworkQuery,
          status: paginationStore.statusFilter,
        });
        break;
      case 'project-controls':
        await projectsStore.getProjectControls({
          projectId: projectsStore.project?.id,
          ...DEFAULT_PAGINATION,
          size: SMALLER_PAGINATION_SIZE,
          tags: paginationStore.tagFilter ? [paginationStore.tagFilter] : undefined,
          name: paginationStore.nameFilter !== '' ? paginationStore.nameFilter : null,
          framework: paginationStore.frameworkQuery,
          status: paginationStore.statusFilter,
          isAssignedToMe: paginationStore.assignedToMe,
          isAssignedToMeForReview: paginationStore.assignedToMeForReview,
        });
        break;
    }
  } catch (error) {
    captureException(error, { message: 'Component: ASection, Function: filterData' });
  }
}
</script>

<template>
  <div class="section__container column q-px-none" :class="[title ? 'q-pt-sm' : '']">
    <span v-if="title" class="q-mb-sm q-mx-md">{{ title }}</span>
    <q-list v-if="items.length > 0" class="items-list">
      <q-item
        v-for="item in items"
        :key="item.id"
        class="row items-center q-px-md q-py-sm"
        :class="[
          paginationStore.statusFilter === item.value ? 'active-item' : '',
          projectsStore.conceptsLoading ? 'disable' : '',
        ]"
        clickable
        @click="
          paginationStore.statusFilter === item.value || projectsStore.conceptsLoading
            ? null
            : filterByStatus(item.value)
        "
      >
        <Icon
          v-if="paginationStore.statusFilter !== item.value"
          :icon-name="item.icon"
          icon-folder="status"
          icon-size="20px"
        />
        <Icon v-else :icon-name="`${item.icon}-white`" icon-folder="status" icon-size="20px" />
        <span class="q-ml-md">{{ item.text }}</span>
      </q-item>
    </q-list>
    <q-list v-if="data.length > 0 && projectsStore.project" class="items-list">
      <q-item
        v-for="item in data"
        :key="item.id"
        class="row items-center q-px-md q-py-sm"
        :class="[
          paginationStore.frameworkQuery === item.framework_code ? 'active-item' : '',
          projectsStore.conceptsLoading ? 'disable' : '',
        ]"
        clickable
        @click="
          paginationStore.frameworkQuery === item.framework_code || projectsStore.conceptsLoading
            ? null
            : filterByFramework(item.framework_code)
        "
      >
        <Icon
          v-if="paginationStore?.frameworkQuery !== item.framework_code"
          icon-name="beenhere"
          icon-folder="content"
        />
        <Icon v-else icon-name="beenhere-white" icon-folder="content" />
        <span class="q-ml-md">{{ item.name }}</span>
      </q-item>
    </q-list>
    <q-item
      v-if="filter && filter.type === 'isAssignedToMe' && filter.route === route.name"
      class="row items-center q-px-md q-py-sm"
      :class="[
        paginationStore.assignedToMe ? 'active-item' : '',
        projectsStore.conceptsLoading ? 'disable' : '',
      ]"
      clickable
      @click="
        paginationStore.assignedToMe || projectsStore.conceptsLoading
          ? null
          : filterByAssigned(true)
      "
    >
      <Icon
        v-if="!paginationStore?.assignedToMe"
        icon-name="account_circle"
        icon-folder="content"
      />
      <Icon v-else icon-name="account_circle-white" icon-folder="content" />
      <span class="q-ml-md">{{ filter.text }}</span>
    </q-item>
    <q-item
      v-if="filter && filter.type === 'isAssignedToMeForReview' && filter.route === route.name"
      class="row items-center q-px-md q-py-sm"
      :class="[
        paginationStore.assignedToMeForReview ? 'active-item' : '',
        projectsStore.conceptsLoading ? 'disable' : '',
      ]"
      clickable
      @click="
        paginationStore.assignedToMeForReview || projectsStore.conceptsLoading
          ? null
          : filterByReview(true)
      "
    >
      <Icon
        v-if="!paginationStore?.assignedToMeForReview"
        icon-name="account_circle"
        icon-folder="content"
      />
      <Icon v-else icon-name="account_circle-white" icon-folder="content" />
      <span class="q-ml-md">{{ filter.text }}</span>
    </q-item>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.section__container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  span {
    @include paragraph-03(600, $secondary-700);
  }
  .items-list {
    padding: 0;
    margin: 0;
  }
  .q-item {
    min-height: auto;
    span {
      @include paragraph-01(400, $secondary-600);
    }
  }
  .active-item {
    background: $secondary-500;
    span {
      color: $white;
    }
  }
  .active-item:hover {
    background: $secondary-300 !important;
  }
  .q-item:hover {
    cursor: pointer;
    background: $secondary-100;
  }

  .disable {
    opacity: 0.7;
  }
}
</style>
