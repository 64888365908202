<script setup lang="ts">
import { onMounted, ref } from 'vue';

import type { PropType } from 'vue';
import type { FrameworkControl, ControlComponent } from '@/client/api';

import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import Guidance from '@/components/molecules/Task/guidance.vue';

const props = defineProps({
  frameworkControl: {
    type: Object as PropType<FrameworkControl>,
    required: true,
  },
});

const emit = defineEmits(['closeDialog', 'openRequirement']);

const guidanceComponent = ref<ControlComponent | null>(null);

function openRequirement(id: string) {
  emit('openRequirement', id);
}

function setGuidanceComponent() {
  const guidance = props.frameworkControl.components.find(
    (component: ControlComponent) => component.component_specifics.component_type === 'guidance',
  );

  if (guidance) {
    guidanceComponent.value = guidance;
  }
}

onMounted(() => {
  setGuidanceComponent();
});
</script>

<template>
  <div class="content-wrapper row col-12">
    <div class="top__container row col-12 q-mb-xl q-px-md">
      <div class="row col-8 items-end">
        <PageTitle :title="frameworkControl.name" style="margin: 0" />
        <q-badge
          :key="frameworkControl.code"
          class="q-ml-sm control-badge"
          :label="frameworkControl.code"
        >
        </q-badge>
      </div>
      <q-space />
      <q-btn icon="close" dense @click="emit('closeDialog')" />
    </div>
    <div class="wrapp col-12 row">
      <div class="task__page-left">
        <div class="row col-12 left__details q-mb-md">
          <div class="row col-12 overview__container q-mb-lg">
            <Icon icon-name="task-overview" icon-folder="task" />
            <span class="overview__title q-ml-sm">Overview</span>
          </div>
          <div class="control__question col-12 column">
            <span class="question__title q-my-md">Question</span>
            <p>{{ frameworkControl.question }}</p>
          </div>
          <div class="control__description column">
            <span class="description__title q-my-md">Description</span>
            <p>{{ frameworkControl.description }}</p>
          </div>
        </div>
        <div class="row components__container col-12 column">
          <Guidance
            v-if="guidanceComponent"
            :component="guidanceComponent"
            :expanded="true"
            class="q-mb-md"
          />
        </div>
      </div>
      <q-space />
      <div class="task__page-right">
        <div class="task__details column q-mb-md">
          <div class="details--top__container row items-center">
            <Icon icon-name="info" icon-folder="colored" />
            <span class="details__title q-ml-sm">Info</span>
          </div>
        </div>
        <div class="control__requirements column">
          <span class="requirement__title q-my-sm">Mapped Frameworks</span>
          <div class="row">
            <q-badge
              v-for="framework in frameworkControl.mapped_frameworks"
              :key="framework"
              class="framework-badge q-mr-sm q-mb-sm justify-center"
            >
              <span>{{ framework }}</span>
            </q-badge>
          </div>
        </div>
        <hr />
        <div class="control__requirements column">
          <span class="requirement__title q-my-sm">{{
            `Mapped Requirements (${frameworkControl.mapped_requirements?.length})`
          }}</span>
          <div class="row requirements__container items-center q-mb-sm">
            <q-badge
              v-for="requirement in frameworkControl.mapped_requirements"
              :key="requirement"
              class="requirement-badge q-mr-sm q-mb-sm"
              :label="requirement"
              @click="openRequirement(requirement)"
            >
            </q-badge>
          </div>
        </div>
        <hr />
        <div class="control__tags column">
          <span class="tags__title q-my-sm">{{ `TAGS (${frameworkControl.tags.length})` }}</span>
          <div class="tags__container row">
            <q-badge
              v-for="tag in frameworkControl.tags"
              :key="tag.name"
              class="general-badge q-mr-sm q-mb-sm"
              >{{ `${tag.name}:` }}&nbsp;<b>{{ tag.value }}</b></q-badge
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.right__actions {
  margin-bottom: 20px;

  button {
    padding: 4px;
    margin-left: 20px;

    :deep(.q-btn__content) {
      line-height: 0;
    }
  }

  :deep(.q-btn-dropdown) {
    i {
      color: $black;
    }
  }
}

.top__container {
  .q-btn::before {
    box-shadow: none !important;
  }
}

.task__page-left {
  width: 70%;
  border-radius: 5px;
  @media screen and (max-width: 1440px) {
    width: 59.5%;
  }

  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .left__details {
    padding: 20px;
    background: $white;

    .overview__container {
      .overview__title {
        @include heading-04(600, $secondary-600);
      }
    }

    .control__description {
      .description__title {
        @include heading-05(700, $secondary-600);
      }

      p {
        @include paragraph-01(400, $secondary-600);
      }
    }

    .control__question {
      .question__title {
        @include heading-05(700, $secondary-600);
      }

      p {
        @include paragraph-01(400, $secondary-600);
      }
    }

    .control__readiness {
      .readiness__title {
        @include heading-05(700, $secondary-600);
      }

      label {
        width: 200px !important;
      }
    }
  }

  .task__details {
    @include paragraph-01(400, $common-2);

    span:first-child {
      color: $black !important;
    }
  }

  .components__container {
    .copy-btn {
      // position: absolute;
      // right: 0;
      padding: 10px 16px;
      color: $black;
      background: $secondary-500;
      font-size: 0.8em;
      border-radius: 5px;

      :deep(.q-btn__content span) {
        max-width: 100px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    button {
      background: $secondary-500 !important;
      color: $white;
      border-radius: 5px;
      text-transform: none;
      padding: 4px 24px;
    }
  }

  .actions__container,
  .submit__container {
    button {
      background: $secondary-500 !important;
      color: $white;
      border-radius: 5px;
      text-transform: none;
      padding: 4px 24px;
    }
  }
}

.task__page-right {
  width: 28.5%;
  height: fit-content;
  padding: 25px 20px 25px 35px;
  background: $white;
  border-radius: 5px;
  position: relative;
  @media screen and (max-width: 1440px) {
    width: 40%;
  }

  .top-actions__container {
    position: absolute;
    right: 0;
    top: -60px;
    button {
      text-transform: unset;
      border-radius: 5px;
    }
    .request-btn {
      background: $secondary-500 !important;
      color: $white;
    }
  }

  hr {
    border: 1px solid $secondary-100;
  }

  .task__details {
    @include caption(400, $common-2);

    .details__title {
      @include paragraph-02(600, $secondary-600);
    }

    span:first-child {
      margin-right: 8px;
    }
  }

  .control__requirements {
    .requirement__title {
      @include caption(400, $common-2);
    }

    .badge__title {
      @include paragraph-02(600, $secondary-800);
    }

    :deep(img) {
      width: 32px;
    }
  }

  .control__tags {
    .tags__title {
      @include caption(400, $common-2);
    }
    .btn-create {
      height: 40px;
      color: $secondary-500;
      background: transparent !important;
      border: 1px solid $secondary-500;
      text-transform: none;
      border-radius: 5px;
    }
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
