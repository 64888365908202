<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useFrameworkStore } from '@/stores/FrameworkStore';

import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import DAddProjectFramework from '@/components/dialogs/DAddProjectFramework.vue';
import MFrameCard from '@/components/molecules/MFrameCard.vue';
import SCard from '@/components/skeletons/SCard.vue';

defineProps({
  projectIsLoading: {
    type: Boolean,
  },
});

const projectsStore = useProjectsStore();
const frameworksStore = useFrameworkStore();
const showAddDialog = ref(false);
const frameworksIsLoading = ref(false);

function closeDialog() {
  showAddDialog.value = false;
}

onMounted(async () => {
  if (!projectsStore.project) return;
  try {
    frameworksIsLoading.value = true;
    await frameworksStore.getFrameworks(projectsStore.project.project_type);
  } catch (error) {
    captureException(error, {
      message: 'Component: DFrameworks, Hook: onMounted, Method: getFrameworks',
    });
  } finally {
    frameworksIsLoading.value = false;
  }
});
</script>

<template>
  <div>
    <div class="row col-12 justify-between">
      <span>Frameworks</span>
      <q-btn
        v-if="isAdmin() || isProjectOwner()"
        class="btn-add"
        icon="add"
        label="Add Framework"
        unelevated
        @click="showAddDialog = true"
      />
    </div>
    <div
      v-if="
        projectsStore.dashboardData &&
        projectsStore.dashboardData.frameworks &&
        !frameworksIsLoading &&
        !projectIsLoading
      "
      class="cards__container row q-col-gutter-x-md q-col-gutter-y-md full-width q-my-md"
    >
      <div
        v-for="card in projectsStore.dashboardData.frameworks"
        :key="card.id"
        class="col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <MFrameCard :card="card" />
      </div>
    </div>
    <div
      v-if="frameworksIsLoading || projectIsLoading"
      class="cards__container row q-col-gutter-x-md q-col-gutter-y-md full-width q-my-md"
    >
      <div v-for="n in 4" :key="n" class="col-12 col-sm-6 col-md-4 col-lg-3">
        <SCard />
      </div>
    </div>
    <div
      v-if="
        projectsStore.dashboardData &&
        projectsStore.dashboardData.frameworks.length === 0 &&
        !frameworksIsLoading &&
        !projectIsLoading
      "
      class="empty__wrapp row items-center q-mt-md"
    >
      <AEmptyData
        icon-name="folder"
        :header="`You don’t have any Framework attached to this Project.`"
        text="Start by adding a Framework."
        action-text=""
        class="full-width items-center"
      />
    </div>
    <ADialog
      :show-dialog="showAddDialog"
      max-height="auto !important"
      max-width="90% !important"
      min-height="auto !important"
      min-width="90% !important"
      @hide="closeDialog"
    >
      <DAddProjectFramework @close-dialog="closeDialog" />
    </ADialog>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

span {
  @include heading-04(600, $secondary-600);
}

.btn-add {
  color: $white;
  background: $secondary-500 !important;
  text-transform: none;
}
</style>
