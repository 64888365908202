<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Roles } from '@/client/api';

import { useOrganizationStore } from '@/stores/OrganizationStore';
import { usePaginationStore } from '@/stores/PaginationStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useConfigStore } from '@/stores/ConfigStore';

import { formatDate } from '@/composables/utils';
import { successMessage, errorMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { ForbiddenError } from '@/composables/ApiError';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import { ProjectType } from '@/client/api';

import ADialog from '@/components/atoms/ADialog.vue';
import APageDescription from '@/components/atoms/APageDescription.vue';
import ATooltip from '@/components/atoms/ATooltip.vue';
import Icon from '@/components/atoms/Icon.vue';
import PageRoute from '@/components/atoms/PageRoute.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import DAddProjectFramework from '@/components/dialogs/DAddProjectFramework.vue';
import DAddUser from '@/components/dialogs/DAddUser.vue';
import DNewAsset from '@/components/dialogs/DNewAsset.vue';
import DNewEvidence from '@/components/dialogs/DNewEvidence.vue';
import MSemiCircularProgress from '@/components/molecules/MSemiCircularProgress.vue';
import RiskThreshold from '@/components/Risks/RiskThreshold.vue';
import ContentLayout from '@/layouts/ContentLayout.vue';

const route = useRoute();
const router = useRouter();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const organizationStore = useOrganizationStore();
const configStore = useConfigStore();

const showAddDialog = ref(false);
const showAddNew = ref(false);
const showAddFramework = ref(false);
const showNewEvidenceDialog = ref(false);
const showNewAssetDialog = ref(false);
const activeTab = ref('dashboard');
const projectIsLoading = ref(false);
const exporting = ref(false);
const isEditor = ref(false);

async function getProject() {
  try {
    projectIsLoading.value = true;

    const id = route.params.id;

    if (!id || typeof id !== 'string') return;

    await projectsStore.getProject({ projectId: id });
  } catch (error) {
    router.push({ name: 'projects' });

    if (error instanceof ForbiddenError) {
      errorMessage('Youd don`t have access to read this project.');
      return;
    }

    captureException(error, {
      message: 'Component: ProjectDetailPage, Method: getProject',
    });
  } finally {
    projectIsLoading.value = false;
  }
}

getProject();

async function closeDialog() {
  showAddDialog.value = false;
  showAddFramework.value = false;
  showNewEvidenceDialog.value = false;
  showNewAssetDialog.value = false;
}

function addItem(item: string) {
  if (item === 'framework') {
    showAddFramework.value = true;
  } else if (item === 'evidence') {
    showNewEvidenceDialog.value = true;
  } else if (item === 'asset') {
    showNewAssetDialog.value = true;
  } else if (item === 'user') {
    showAddDialog.value = true;
  }

  showAddNew.value = false;
}

async function closeAddDialog() {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectsUsers({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
      size: 50,
    });
  } catch (error) {
    captureException(error, { message: 'Component: ProjectDetailPage, Function: closeAddDialog' });
  }

  showAddDialog.value = false;
  router.push({
    path: `/projects/project-detail/${projectsStore.project.id}/settings`,
  });
}

function closeEvidenceDialog() {
  if (!projectsStore.project) return;

  showNewEvidenceDialog.value = false;
  router.push({
    path: `/projects/project-detail/${projectsStore.project.id}/evidence`,
  });
}

function closeAssetDialog() {
  if (!projectsStore.project) return;

  showNewAssetDialog.value = false;
  router.push({
    path: `/projects/project-detail/${projectsStore.project.id}/assets`,
  });
}

async function exportProjectPdf() {
  if (!projectsStore.project) return;

  try {
    exporting.value = true;

    await projectsStore.exportProjectPdf(projectsStore.project.id);

    successMessage('Project exported successfully');
  } catch (e) {
    errorMessage('Project export failed');
    captureException(e, { message: 'Component: ProjectDetailPage, Function: exportProjectPdf' });
  } finally {
    exporting.value = false;
  }
}

const pageTitle = computed(() => {
  switch (route.name) {
    case 'project-frameworks':
      return 'Project Frameworks';
    case 'project-requirements':
      return 'Project Requirements';
    case 'project-controls':
      return 'Project Controls';
    case 'project-settings':
      return 'Settings';
    case 'project-risks':
      return 'Risks';
    case 'project-evidence':
      return 'Evidence Browser';
    case 'project-assets':
      return 'Assets';
    case 'project-testing':
      return 'Testing';
    default:
      return 'Frameworks';
  }
});

const stageDescription = computed(() => {
  if (!organizationStore.taxonomyData || !projectsStore.dashboardData) return;
  let description;
  organizationStore.taxonomyData.tags.some((tag) => {
    const value = tag.tag_values.find(
      (v) => v.tag_value === projectsStore.dashboardData?.general_info.ai_lifecycle_stage,
    );
    if (value) {
      description = value.tag_description;
      return true;
    }
  });

  return description;
});

watch(
  () => route.name,
  async () => {
    projectIsLoading.value = true;

    try {
      const id = route.params.id;

      if (!id || typeof id !== 'string') return;

      if (route.name === 'project-dashboard') {
        await projectsStore.getProject({ projectId: id });
      }
    } catch (error) {
      router.push({ name: 'projects' });
      captureException(error, { message: 'Component: ProjectDetailPage, Watch: route.name' });
    } finally {
      projectIsLoading.value = false;
    }
  },
);

onMounted(async () => {
  const tab = localStorage.getItem('project-active-tab');

  if (tab) activeTab.value = tab;

  projectIsLoading.value = true;
  try {
    const id = route.params.id;

    if (!id || typeof id !== 'string') return;

    await projectsStore.getProject({ projectId: id });

    if (!projectsStore.project) return;

    const userRoles = await userProjectRoles(projectsStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;
  } catch (error) {
    router.push({ name: 'projects' });
    captureException(error, {
      message: 'Component: ProjectDetailPage, Hook: onMounted',
    });
  } finally {
    projectIsLoading.value = false;
  }

  try {
    if (!projectsStore.project) return;

    await projectsStore.getProjectFrameworks({ projectId: projectsStore.project.id });
    await organizationStore.getOrganizationTaxonomy();
    await projectsStore.getProjectsUsers({
      projectId: projectsStore.project.id,
      ...DEFAULT_PAGINATION,
      sortBy: 'firstname',
      size: 50,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: ProjectDetailPage, Hook: onMounted',
    });
  } finally {
    projectIsLoading.value = false;
  }
});

onBeforeUnmount(() => {
  paginationStore.setNameFilter('');
  paginationStore.setPage(null);
  paginationStore.setTagFilter(null);
  paginationStore.setStatusFilter(null);
  paginationStore.setFrameworkQuery(null);
  paginationStore.setAssignedToMe(false);
});
</script>

<template>
  <div
    v-if="projectsStore.project"
    class="content-wrapper"
    :style="{ 'margin-bottom': route.name === 'project-risks' ? '95px' : '0' }"
  >
    <PageRoute class="q-mb-md" />
    <div
      v-if="route.name === 'project-dashboard'"
      class="row col-12 add-new__container justify-between items-center q-mt-md q-mb-lg"
    >
      <PageTitle
        :title="projectsStore.project?.name || ''"
        :editable="true"
        class="col-6"
        :class="[projectIsLoading ? 'skeleton-v2' : '']"
      />
      <div class="add-new__wrapp">
        <q-btn
          class="btn-export q-mr-md"
          label="Export"
          unelevated
          :disable="exporting || projectIsLoading"
          :loading="exporting"
          @click="exportProjectPdf"
        >
          <Icon icon-name="icon-export" icon-folder="colored" />
        </q-btn>

        <q-btn
          v-if="isAdmin() || isProjectOwner() || isEditor"
          class="btn-add"
          :icon-right="showAddNew ? 'expand_less' : 'expand_more'"
          label="Add New"
          unelevated
          :disable="projectIsLoading"
          @click="showAddNew = !showAddNew"
        />
        <div v-if="showAddNew" class="new-dropdown__container column">
          <div
            v-if="isAdmin() || isProjectOwner()"
            class="dropdown-item row items-center"
            @click="addItem('framework')"
          >
            <Icon icon-name="dropdown-1" icon-folder="dashboard" />
            <span class="item-text q-ml-sm">Framework</span>
          </div>
          <div
            v-if="isAdmin() || isProjectOwner()"
            class="dropdown-item row items-center"
            @click="addItem('evidence')"
          >
            <Icon icon-name="dropdown-2" icon-folder="dashboard" />
            <span class="item-text q-ml-sm">Evidence</span>
          </div>
          <div
            v-if="isAdmin() || isProjectOwner() || isEditor"
            class="dropdown-item row items-center"
            @click="addItem('asset')"
          >
            <Icon icon-name="dropdown-3" icon-folder="dashboard" />
            <span class="item-text q-ml-sm">Asset</span>
          </div>
          <div
            v-if="
              (configStore.usersEnabled && isAdmin()) ||
              (configStore.usersEnabled && isProjectOwner()) ||
              isAdmin()
            "
            class="dropdown-item row items-center"
            @click="addItem('user')"
          >
            <Icon icon-name="dropdown-4" icon-folder="dashboard" />
            <span class="item-text q-ml-sm">User</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="route.name === 'project-dashboard'" class="row details__wrapp justify-between">
      <div class="col-8 col-lg-7 project__details q-px-md q-pb-md q-pt-none">
        <div class="row q-pr-lg">
          <div class="detail-item column q-pr-md">
            <span class="q-mb-sm">Owner</span>
            <div class="row items-center" :class="[projectIsLoading ? 'skeleton' : '']">
              <Icon icon-name="user-image" icon-folder="colored" />
              <span class="black-text q-ml-sm">{{
                projectsStore.dashboardData?.general_info.created_by.firstname +
                ' ' +
                projectsStore.dashboardData?.general_info.created_by.lastname
              }}</span>
            </div>
          </div>
          <div class="detail-item column q-px-md">
            <span class="q-mb-sm">Last updated</span>
            <div class="row items-center" :class="[projectIsLoading ? 'skeleton' : '']">
              <Icon icon-name="calendar_today" icon-folder="colored" />
              <span class="black-text q-ml-sm"
                >{{
                  projectsStore.dashboardData?.general_info.modified_at
                    ? formatDate(projectsStore.dashboardData?.general_info.modified_at)
                    : '-'
                }}
              </span>
            </div>
          </div>
          <div
            v-if="projectsStore.project?.project_type !== ProjectType.Organization"
            class="detail-item column q-px-md items-start"
          >
            <span class="q-mb-sm">AI Lifecycle Stage</span>
            <div class="row items-center" :class="[projectIsLoading ? 'skeleton' : '']">
              <q-badge
                class="q-mr-xs lifecycle-badge"
                :label="
                  projectsStore.dashboardData?.general_info.ai_lifecycle_stage
                    ? projectsStore.dashboardData?.general_info.ai_lifecycle_stage
                    : 'N/A'
                "
              />
              <ATooltip
                icon-folder="settings"
                icon-name="info-medium"
                :tooltip-text="
                  stageDescription
                    ? stageDescription
                    : 'Set the AI lifecycle stage in the project settings'
                "
              />
            </div>
          </div>
        </div>
        <APageDescription
          :description="projectsStore.project?.description || ''"
          update="project"
          :class="[projectIsLoading ? 'skeleton' : '']"
        />
      </div>
      <div class="row col-3 col-lg-2 project__progress">
        <div class="circular-progress__container col-12 row q-pa-md justify-center">
          <span class="progress__title col-12">Progress</span>
          <MSemiCircularProgress
            v-if="projectsStore.dashboardData && projectsStore.dashboardData.project_progress"
            :progress="projectsStore.dashboardData.project_progress || 0"
            :class="[projectIsLoading ? 'skeleton' : '']"
          />
        </div>
      </div>
      <div class="evidence__container column col-3 col-lg-2 q-pa-md">
        <span class="evidence__title q-mb-md">Evidence</span>
        <div
          class="evidence__total row justify-between items-end"
          :class="[projectIsLoading ? 'skeleton' : '']"
        >
          <span>total</span>
          <span>{{ projectsStore.dashboardData?.evidence.total_evidence }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="
        route.name !== 'risk-detail' &&
        route.name !== 'project-dashboard' &&
        route.name !== 'asset-detail' &&
        route.name !== 'test-detail'
      "
      class="panels__container row q-mb-md"
      :class="[route.name === 'project-risks' ? 'justify-between' : '']"
    >
      <span>{{ pageTitle }}</span>
      <RiskThreshold v-if="route.name === 'project-risks'" />
    </div>
    <div v-if="route.name === 'project-requirements' || route.name === 'project-controls'">
      <ContentLayout
        :title="route.name === 'project-requirements' ? 'All Requirements' : 'All controls'"
      />
    </div>
    <router-view v-else />
  </div>
  <div v-else class="spinner-wrapp row justify-center q-mt-xl">
    <q-spinner-oval size="5em" color="secondary" />
  </div>
  <ADialog
    :show-dialog="showAddDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showAddDialog = false"
  >
    <DAddUser @close-dialog="closeDialog" @update-data="closeAddDialog" />
  </ADialog>
  <ADialog
    :show-dialog="showAddFramework"
    max-height="auto !important"
    max-width="90% !important"
    min-height="auto !important"
    min-width="90% !important"
    @hide="showAddFramework = false"
  >
    <DAddProjectFramework @close-dialog="closeDialog" />
  </ADialog>
  <ADialog
    :show-dialog="showNewEvidenceDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showNewEvidenceDialog = false"
  >
    <DNewEvidence @close-dialog="closeDialog" @update-data="closeEvidenceDialog" />
  </ADialog>
  <ADialog
    :show-dialog="showNewAssetDialog"
    max-height="auto !important"
    max-width="500px !important"
    min-height="auto !important"
    min-width="500px !important"
    @hide="showNewAssetDialog = false"
  >
    <DNewAsset @close-dialog="closeDialog" @update-data="closeAssetDialog" />
  </ADialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.details__wrapp {
  @media screen and (max-width: 1440px) {
    justify-content: space-between;
  }
}

.add-new__container {
  .add-new__wrapp {
    position: relative;
    .btn-add {
      color: $white;
      background: $secondary-500 !important;
      text-transform: none;
    }
    .new-dropdown__container {
      min-width: 135px;
      background: $white;
      border-radius: 4px;
      padding: 4px 0;
      box-shadow:
        0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      position: absolute;
      top: 40px;
      right: 0;
      z-index: 99;
      .dropdown-item {
        cursor: pointer;
        padding: 5px 15px;
        span {
          font-size: 14px;
          color: $secondary-700;
        }
      }

      .dropdown-item:hover {
        background: #f5f5f5;
      }
      .disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
}

.project__details {
  @include caption(400, #8a939a);
  background: $white;
  border-radius: 10px;
  margin-right: 16px;
  padding: 20px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .detail-item:nth-child(2) {
    border-left: 1px solid $secondary-100;
  }
  .detail-item:not(:first-child):not(:last-child) {
    border-right: 1px solid $secondary-100;
  }

  .detail-item {
    span {
      @include caption(400, $secondary-600);
      opacity: 0.5;
    }

    .black-text {
      @include paragraph-01(400, $secondary-600);
      opacity: 1;
    }
  }

  .project__description {
    @include paragraph-02(400, $secondary-800);
    margin-top: 20px;
  }
}
.q-linear-progress {
  width: 50%;
}
.project__progress {
  background: $white;
  border-radius: 10px;
  margin-right: 16px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 1440px) {
    margin-right: 0;
  }
  .circular-progress__container {
    position: relative;
    .progress__title {
      @include paragraph-02(500, $common-4);
      text-transform: none;
    }
    :deep(.ep-container) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -25%);
    }
  }
}
.linear-progress__container {
  .progress__group {
    max-height: 25px;
    span {
      @include paragraph-01(400, $common-4);
      text-wrap: nowrap;
      max-width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .progress-1 {
      color: $primary-500 !important;
    }
    .progress-2 {
      color: $primary-400 !important;
    }
    .progress-3 {
      color: #96cebf !important;
    }
  }
  .chevron-button {
    top: 20% !important;
  }
  .chevron-left {
    left: 3.5% !important;
    @media screen and (max-width: 1025px) {
      left: 0.5% !important;
    }
  }
  .chevron-right {
    right: -2% !important;
    @media screen and (max-width: 1025px) {
      right: -5% !important;
    }
  }
}

.evidence__container {
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 1440px) {
    margin-top: 20px;
  }

  span {
    @include caption(600, $secondary-600);
    text-transform: uppercase;
  }
  .evidence__title {
    @include paragraph-02(500, $common-4);
    text-transform: none;
  }
  .evidence__total {
    padding-bottom: 5px;
    border-bottom: 1px solid $secondary-200;
    span {
      letter-spacing: 0.96px;
    }
    span:last-child {
      @include heading-01(700, $secondary-600);
    }
  }
}
.panels__container {
  position: relative;
  :deep(.title__container) {
    margin: 0 !important;
  }
  .title-h1 {
    @include heading-02(600, $secondary-700);
  }
  span {
    @include heading-02(600, $secondary-700);
  }
}

.spinner-wrapp {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
