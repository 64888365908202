<script setup lang="ts"></script>
<template>
  <div class="plan__container column full-width">
    <div class="plan__header row justify-center full-width">
      <q-badge class="strategy-badge justify-center" label="Residual Risk" />
    </div>
    <slot name="content-slot" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.plan__container {
  padding: 18px 16px 20px;
  border-radius: 5px;
  border: 1px solid $secondary-400;
  background: $white;
  cursor: pointer;
}
</style>
