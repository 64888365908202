/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueRenderer } from '@tiptap/vue-3';
import tippy, { type Instance as TippyInstance } from 'tippy.js';

import { useProjectsStore } from '@/stores/ProjectsStore';

import MentionList from '../components/MentionList.vue';

export default {
  items: ({ query }: { query: string }) => {
    const projectsStore = useProjectsStore();
    if (!projectsStore.projectUsers) return [];

    const users = projectsStore.projectUsers.items;

    return users
      .filter(
        (user) =>
          user.firstname.toLowerCase().startsWith(query.toLowerCase()) ||
          user.lastname.toLowerCase().startsWith(query.toLowerCase()),
      )
      .slice(0, 5)
      .map((user) => `${user.firstname} ${user.lastname}`);
  },

  render: () => {
    let component: VueRenderer | null = null;
    let popup: TippyInstance | null = null;

    return {
      onStart: (props: any) => {
        component = new VueRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        const tippyInstances = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element ? component.element : undefined,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });

        popup = tippyInstances[0];
      },

      onUpdate: (props: any) => {
        component?.updateProps(props);

        if (!props.clientRect || !popup) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: () => props.clientRect as DOMRect,
        });
      },

      onKeyDown: (props: any) => {
        if (!popup) {
          return;
        }

        if (props.event.key === 'Escape') {
          popup.hide();
          return true;
        }

        return component?.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        if (!popup) {
          return;
        }

        popup.destroy();
        component?.destroy();
      },
    };
  },
};
