<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { cloneDeep } from 'lodash';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { Roles, Status } from '@/client/api';

import { statusIcon, STATUS_LABEL } from '@/composables/utils';
import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { isAdmin } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';

import type {
  AssetCard,
  Concept,
  ControlAggregator,
  RiskAggregator,
  UserDisplay,
} from '@/client/api';

import type { PropType } from 'vue';

import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import Icon from '@/components/atoms/Icon.vue';
import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

interface Select {
  value: Status;
  label: string;
}

const emit = defineEmits(['closeDialog', 'updateConcept']);
const props = defineProps({
  concept: {
    type: Object as PropType<ControlAggregator | RiskAggregator | AssetCard>,
    required: true,
  },
  conceptType: {
    type: String as PropType<Concept>,
    required: true,
  },
});

const projectsStore = useProjectsStore();

const isLoading = ref(false);
const comment = ref('');
const reviewers = ref<UserDisplay[]>([]);
const status = ref<Select | null>(null);
const statusOptions = ref([
  { value: Status.InProgress, label: STATUS_LABEL.IN_PROGRESS },
  { value: Status.Completed, label: STATUS_LABEL.COMPLETED },
  { value: Status.OutOfScope, label: STATUS_LABEL.OUT_OF_SCOPE },
]);

async function selectUsers(users: UserDisplay[]) {
  reviewers.value = users;
}

function removeChip(id: string) {
  const userIndex = reviewers.value.findIndex((user) => user.id === id);

  if (userIndex !== -1) {
    reviewers.value.splice(userIndex, 1);
  }
}

async function requestReview(force: boolean = false) {
  if (!projectsStore.project || !status.value) return;

  try {
    isLoading.value = true;

    const data = {
      requested_status: status.value.value,
      comment: comment.value,
      reviewer_ids: force ? [] : reviewers.value.map((user) => user.id),
    };

    const params = {
      projectId: projectsStore.project.id,
      objectId: props.concept.id,
      concept: props.conceptType,
      reviewRequest: data,
      forceUpdateStatus: force,
    };

    await projectsStore.conceptRequestReview(params);

    successMessage(
      force
        ? 'Force update for status change was successful'
        : 'You successfully requested review(s) for status change',
    );

    emit('updateConcept');
    emit('closeDialog');
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequestReview, Function: requestReview',
    });
  } finally {
    isLoading.value = false;
  }
}

function closeModal() {
  emit('closeDialog');
}

const filteredOptions = computed(() => {
  return statusOptions.value.filter((item) => item.value !== props.concept.status);
});

onMounted(async () => {
  const pagination = {
    ...DEFAULT_PAGINATION,
    size: 50,
    roles: [Roles.Reviewer, Roles.Owner],
  };

  if (!projectsStore.project) return;

  const params = {
    projectId: projectsStore.project.id,
    ...pagination,
  };

  try {
    const response = await projectsStore.getProjectsUsers(params, false);

    if (!response) return;

    reviewers.value = cloneDeep(props.concept.reviewers || []);
  } catch (error) {
    captureException(error, {
      message: 'Component: DRequestReview, Hook: onMounted, Function getProjectsUsers',
      data: {
        params: params,
      },
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <h5 class="q-ma-none">
        {{ `Request Status Change for ${concept.name}` }}
      </h5>
      <!-- <h5 class="q-ma-none">{{ `Request risk treatment review` }}</h5> -->
    </div>
    <div class="row col-12 q-mb-md status__container">
      <span class="select__title q-mb-sm col-12">Status Change*</span>
      <div class="row col-12">
        <div class="row col-6 items-center">
          <div class="row col-10 justify-center items-center">
            <Icon
              :icon-name="statusIcon(concept.status)"
              icon-folder="status"
              class="q-mr-sm"
              icon-size="20px"
            />
            <span class="text-black">{{
              String(concept.status).charAt(0).toUpperCase() +
              String(concept.status).replaceAll('_', ' ').slice(1)
            }}</span>
          </div>
          <Icon icon-name="arrow_forward" icon-folder="colored" />
        </div>
        <div class="row col-6">
          <q-select
            v-model="status"
            outlined
            label="Select"
            :options="filteredOptions.map((item) => ({ value: item.value, label: item.label }))"
            dense
            class="col-12"
          >
            <template #option="scope">
              <q-item v-bind="scope.itemProps" class="row items-center">
                <Icon
                  :icon-name="statusIcon(scope.opt.value as Status)"
                  icon-folder="status"
                  icon-size="20px"
                />
                <span class="q-ml-sm">{{ scope.opt.label }}</span>
              </q-item>
            </template></q-select
          >
        </div>
      </div>
    </div>
    <div class="row col-12 q-mb-md reviewers__container">
      <span class="select__title q-mb-sm col-12">Reviewer(s)*</span>
      <q-btn-dropdown
        flat
        label="Select Reviewer(s)
        "
        class="q-mb-sm col-12"
      >
        <AUsersDropDown
          filter-by="users"
          multiselect
          :selected-users-arr="reviewers"
          @select-users="selectUsers"
        />
      </q-btn-dropdown>
      <div class="reviewers__container">
        <q-badge
          v-for="user in reviewers"
          :key="user.id"
          class="reviewer-badge q-mr-sm q-mb-sm justify-center"
        >
          <span>{{ user.firstname + ' ' + user.lastname }}</span>

          <Icon
            icon-name="icon-remove-badge"
            icon-folder="black"
            class="q-ml-sm"
            @click="removeChip(user.id)"
          />
        </q-badge>
      </div>
    </div>
    <div class="row col-12 q-mb-md">
      <span class="col-12 q-mb-sm">Comment</span>
      <q-input
        v-model="comment"
        outlined
        placeholder="Insert text"
        no-error-icon
        dense
        class="col-12"
        type="textarea"
      />
    </div>
    <div class="row action__row full-width justify-between q-mt-lg">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="closeModal" />
      <div class="row items-center">
        <q-btn
          v-if="isProjectOwner() || isAdmin()"
          class="btn-save q-mr-sm"
          label="Force Update"
          unelevated
          :loading="isLoading"
          :disable="isLoading || !status"
          @click="requestReview(true)"
        >
          <Icon icon-folder="task" icon-name="info" class="q-ml-xs" />

          <q-tooltip anchor="bottom end" self="center middle" max-width="200px">
            Forces a status update without a review process. You can include a comment to document
            your decision.
          </q-tooltip>
        </q-btn>
        <q-btn
          class="btn-save"
          label="Submit"
          unelevated
          :loading="isLoading"
          :disable="isLoading || reviewers.length === 0 || !status"
          @click="requestReview(false)"
        >
          <Icon icon-folder="task" icon-name="info" class="q-ml-xs" />

          <q-tooltip anchor="bottom end" self="center middle" max-width="200px">
            Submit this status update for review.
          </q-tooltip>
          <!-- <q-tooltip v-else anchor="bottom end" self="center middle" max-width="200px">
            Feature in progress.
          </q-tooltip> -->
        </q-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  span {
    font-size: 14px;
    color: $secondary-500;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  :deep(.q-field) {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
  }

  .reviewers__container {
    .reviewer-badge {
      :deep(svg) {
        cursor: pointer;
      }
    }
    .q-btn-dropdown {
      border: 1px solid rgba(0, 0, 0, 0.24);

      :deep(.q-focus-helper) {
        display: none;
      }

      :deep(span) {
        color: $common-6 !important;
        text-transform: none !important;
      }

      :deep(i) {
        color: $common-3 !important;
      }
      :deep(.q-btn__content) {
        justify-content: space-between !important;
      }
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    min-width: 95px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}
</style>
