<script setup lang="ts">
import { ref, watch } from 'vue';

import ATooltip from '@/components/atoms/ATooltip.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  sliderValue: {
    type: Number,
    default: 0,
  },
  disable: {
    type: Boolean,
  },
  tooltipText: {
    type: String,
    default: '',
  },
  showBadge: {
    type: Boolean,
  },
  badgeValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['updateValue']);

const slider = ref(0);

function onInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const rawValue = inputElement.value;

  const match = rawValue.match(/^\d*\.?\d{0,3}$/);

  if (match || rawValue === '' || rawValue === '.') {
    const value = parseFloat(rawValue);

    if (!isNaN(value) && value >= 0 && value <= 1) {
      slider.value = value;
      emit('updateValue', slider.value, props.name);
    }

    if (value > 1) {
      slider.value = 0;
    }
  } else {
    slider.value = 0;
  }
}

watch(
  () => props.sliderValue,
  (newValue) => {
    slider.value = newValue;
  },
  {
    immediate: true,
  },
);
</script>
<template>
  <div class="row slider__container justify-between">
    <div class="row col-12 q-mb-sm">
      <span class="slider__title">{{ name }}</span>
      <ATooltip
        icon-folder="settings"
        icon-name="info-medium"
        :tooltip-text="tooltipText"
        class="q-ml-sm"
      />
    </div>
    <div class="row items-center col-12">
      <q-slider
        v-model="slider"
        :marker-labels="[
          {
            value: 0,
            label: 0,
            classes: 'text-black',
          },
          {
            value: 1,
            label: 1,
            classes: 'text-black',
          },
        ]"
        color="grey-5"
        track-color="grey-1"
        :class="[showBadge ? 'col-7' : 'col-9']"
        :min="0"
        :max="1"
        :step="0.001"
        :disable="disable"
        @change="emit('updateValue', slider, name)"
      />
      <div class="row value__container items-center">
        <input
          v-model="slider"
          :min="0"
          :max="1"
          :step="0.001"
          type="number"
          :disabled="disable"
          inputmode="decimal"
          class="input__container row items-center q-ml-lg justify-center text-center"
          :class="[disable ? 'disabled' : '']"
          @input="onInput"
        />
        <q-badge
          v-if="showBadge"
          class="justify-center q-ml-lg"
          :label="badgeValue || ''"
          :class="[
            badgeValue ? (badgeValue || '').replaceAll(' ', '-').toLowerCase() + '-badge' : '',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.slider__container {
  .slider__title {
    @include paragraph-01(600, $secondary-700);
  }
  .value__container {
    margin-top: -20px;
    .input__container {
      width: fit-content;
      max-height: 40px;
      padding: 8px 15px;
      color: $common-4;
      font-size: 14px;
      border-radius: 2px;
      border: 1px solid $common-1;
    }
    .disabled {
      border: unset;
      opacity: 1 !important;
      cursor: unset !important;
    }
  }
  .q-slider {
    padding-left: 7px;
    :deep(.text-black) {
      color: $common-4 !important;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
