<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type {
  TestingSource,
  TestingMetric,
  UserInProjectList,
  Control,
  UserDisplay,
} from '@/client/api';
import { Roles, TestingTestStatus, Concept, ControlStatusFilter } from '@/client/api';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { statusIcon, formatDate } from '@/composables/utils';
import { isAdmin, userProjectRoles } from '@/composables/Auth';
import { isProjectOwner } from '@/composables/CProject';
import { errorMessage, successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import {
  operatorFloatOptions,
  operatorIntegerOptions,
  operatorStringOptions,
} from '@/composables/Vendors';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import TabResults from '@/components/ProjectDetail/ProjectTesting/TabResults.vue';
import TestingChart from '@/components/ProjectDetail/ProjectTesting/TestingChart.vue';
import ADialog from '@/components/atoms/ADialog.vue';
import AUsersDropDown from '@/components/atoms/AUsersDropDown.vue';
import Icon from '@/components/atoms/Icon.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
// import OComments from '@/components/organisms/OComments.vue';
import SDetailPage from '@/components/skeletons/SDetailPage.vue';
import DEditTest from '@/components/dialogs/DEditTest.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';
import DTestCreateControl from '@/components/dialogs/DTestCreateControl.vue';

const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const projectStore = useProjectsStore();
const activeTab = ref('test');
const group = ref([false]);
const assignedUser = ref<UserDisplay | null>(null);
// const tagsExpanded = ref(false);
const logsExpanded = ref(false);
const showEditTest = ref(false);
const source = ref<TestingSource | null>(null);
const attribute = ref<TestingMetric | null>(null);
const isEditor = ref(false);
const removeControlAssociation = ref<Control | null>(null);
const showRemoveControlDialog = ref(false);
const showAssociateToControl = ref(false);
const isLoading = ref(false);
const testIsLoading = ref(false);
const runningTest = ref(false);
const confirmationIsLoading = ref(false);

const options = ref([
  {
    label: '',
    value: true,
  },
]);

async function changeTab(value: string) {
  activeTab.value = value;
}

async function selectUser(user: string, id: string) {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    const params = {
      projectId: projectStore.project.id,
      testingTestId: projectStore.currentTest.id,
      updateTestingTest: {
        assigned_to_id: id,
      },
    };

    isLoading.value = true;

    await projectStore.updateTestingTest(params);

    successMessage(`${projectStore.currentTest.name} assigned to ${user}`);
  } catch (error) {
    errorMessage('Failed to update test');

    captureException(error, {
      message: 'Component: TestingDetailPage, Function: selectUser',
    });
  } finally {
    isLoading.value = false;
  }
}
// async function updateConcept() {
//   if (!projectStore.project) return;

//   try {
//     await projectStore.getProjectTestingTest({
//       projectId: route.params.id as string,
//       testingTestId: route.params.testId as string,
//     });
//     // await updateCommentsAndLogs();
//   } catch (error) {
//     captureException(error, {
//       message: 'Component: TestingDetailPage, Function: updateConcept',
//     });
//   }
// }

async function updateCommentsAndLogs() {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    const params = {
      objectId: projectStore.currentTest.id,
      projectId: projectStore.project.id,
      objectType: Concept.TestingTest,
    };

    await projectStore.getComments(params);

    const logParams = {
      objectId: projectStore.currentTest.id,
      projectId: projectStore.project.id,
    };

    await projectStore.getProjectLogs(logParams);
  } catch (error) {
    captureException(error, {
      message: 'Component: TestingDetailPage, Function: updateCommentsAndLogs',
    });
  }
}

async function getAttribute() {
  if (!source.value || !projectStore.project || !projectStore.currentTest) return;

  try {
    await projectStore.getSourceAttribute({
      projectId: projectStore.project.id,
      testingSourceId: source.value.id,
      testingMetricId: projectStore.currentTest.conditions.metric_id,
    });

    attribute.value = projectStore.currentSourceAttribute || null;
  } catch (error) {
    captureException(error, {
      message: 'Component: TestingDetailPage, Function: getAttribute',
    });
  }
}

async function setData() {
  if (!projectStore.currentTest || !projectStore.project) return;

  try {
    group.value[0] = projectStore.currentTest.status || false;

    await projectStore.getProjectTestingSources({
      projectId: projectStore.project.id,
    });

    if (!projectStore.testingSources) return;

    const specificSource = projectStore.testingSources.items.find(
      (item) => item.id === projectStore.currentTest?.conditions.source_id,
    );

    source.value = specificSource || null;

    await getAttribute();

    if (!projectStore.projectUsers) return;

    const assignee =
      projectStore.projectUsers.items.find(
        (user: UserInProjectList) => user.id === projectStore.currentTest?.assigned_to_id,
      ) || '';
    if (!assignee) return;

    assignedUser.value = assignee;
  } catch (error) {
    captureException(error, {
      message: 'Component: DCreateTest, Hook: onMounted',
    });
  }
}

async function runTest() {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    runningTest.value = true;

    await projectStore.runTestingTest({
      projectId: projectStore.project.id,
      testingTestId: projectStore.currentTest.id,
    });

    successMessage('Test executed successfully');

    await projectStore.getTestingTestResults({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      descending: true,
      sortBy: 'created_at',
    });
  } catch (error) {
    errorMessage('Failed to execute test');

    captureException(error, {
      message: 'Component: TestingDetailPage, Function: runTest',
    });
  } finally {
    runningTest.value = false;
  }
}

async function toggleTest() {
  if (!projectStore.project || !projectStore.currentTest) return;

  try {
    isLoading.value = true;

    const params = {
      projectId: projectStore.project.id,
      testingTestId: projectStore.currentTest.id,
      updateTestingTest: {
        status: group.value[0] || false,
      },
    };

    await projectStore.updateTestingTest(params);

    successMessage(`Test successfully ${group.value[0] ? 'enabled' : 'disabled'}.`);
  } catch (error) {
    errorMessage(`Failed to ${group.value ? 'enable' : 'disable'} test.`);

    captureException(error, {
      message: 'Component: TestingDetailPage, Function: toggleTest',
    });
  } finally {
    isLoading.value = false;
  }
}

function copyDetails() {
  if (!projectStore.currentTestResult) return;

  const textToCopy = projectStore.currentTestResult.details || '';

  navigator.clipboard.writeText(textToCopy);

  $q.notify({
    icon: 'cloud_done',
    message: 'Copied',
  });
}

function openControl(controlId: string) {
  if (!projectStore.project) return;

  router.push({
    path: `/projects/project-detail/${projectStore.project.id}/controls`,
    query: {
      control: controlId,
    },
  });
}

function setRemoveControl(control: Control) {
  removeControlAssociation.value = control;
  showRemoveControlDialog.value = true;
}

async function confirmRemoveAssociation() {
  if (!projectStore.currentTest || !projectStore.project || !removeControlAssociation.value) return;

  try {
    confirmationIsLoading.value = true;

    const params = {
      projectId: projectStore.project.id,
      controlId: removeControlAssociation.value.id,
      testingTestId: projectStore.currentTest.id as string,
    };

    await projectStore.removeTestingTestControl(params);

    successMessage(`Association with Control ${removeControlAssociation.value.name} was removed`);

    await projectStore.getTestingTestControls({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      ...DEFAULT_PAGINATION,
    });
    closeConfirm();
  } catch (e) {
    errorMessage('Failed to remove association with Control');
    captureException(e, {
      message: 'Component: TestingDetailPage, Function: confirmRemoveAssociation',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

function closeDialog() {
  showEditTest.value = false;
  showAssociateToControl.value = false;
}

function closeConfirm() {
  showRemoveControlDialog.value = false;
  removeControlAssociation.value = null;
}

const resultStatus = computed(() => {
  if (!projectStore.currentTestResult) return '';

  return projectStore.currentTestResult.status === TestingTestStatus.Passed
    ? TestingTestStatus.Passed
    : projectStore.currentTestResult.status === TestingTestStatus.Failed
      ? TestingTestStatus.Failed
      : TestingTestStatus.Error;
});

const fullOperatorName = computed(() => {
  if (!attribute.value) return '-';

  if (attribute.value.type === 'string') {
    return operatorStringOptions.value.find(
      (item) => item.value === projectStore.currentTest?.conditions.operator,
    )?.name;
  } else if (attribute.value.type === 'integer') {
    return operatorIntegerOptions.value.find(
      (item) => item.value === projectStore.currentTest?.conditions.operator,
    )?.name;
  } else {
    return operatorFloatOptions.value.find(
      (item) => item.value === projectStore.currentTest?.conditions.operator,
    )?.name;
  }
});

watch(
  () => projectStore.projectUsers,
  (newV) => {
    if (!newV) return;

    const assignee =
      newV.items.find(
        (user: UserInProjectList) => user.id === projectStore.currentTest?.assigned_to_id,
      ) || '';
    if (!assignee) return;

    assignedUser.value = assignee;
  },
);

onMounted(async () => {
  if (!projectStore.project) return;
  try {
    testIsLoading.value = true;

    await projectStore.getProjectTestingTest({
      projectId: route.params.id as string,
      testingTestId: route.params.testId as string,
    });

    setData();

    const userRoles = await userProjectRoles(projectStore.project.id);

    isEditor.value = userRoles?.includes(Roles.Editor) || false;

    if (!projectStore.currentTest) return;

    await projectStore.getTestingTestControls({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      ...DEFAULT_PAGINATION,
    });

    await projectStore.getTestingTestResults({
      projectId: projectStore.project.id as string,
      testingTestId: projectStore.currentTest.id as string,
      descending: true,
      size: 1,
      sortBy: 'created_at',
    });

    if (projectStore.testingTestResults && projectStore.testingTestResults.items.length > 0) {
      projectStore.setCurrentTestResult(projectStore.testingTestResults.items[0]);
    }

    await projectStore.getProjectControlsLight({
      projectId: projectStore.project.id,
      ...DEFAULT_PAGINATION,
      status: ControlStatusFilter.Completed,
      size: 100,
    });

    await updateCommentsAndLogs();
  } catch (error) {
    captureException(error, {
      message: 'Component: TestingDetailPage, Hook: onMounted',
    });
  } finally {
    testIsLoading.value = false;
  }
});

onBeforeUnmount(() => {
  projectStore.setCurrentTestResult(null);
  projectStore.resetCommentsAndLogs();
});
</script>
<template>
  <PageTitle
    v-if="projectStore.currentTest && !testIsLoading"
    :title="projectStore.currentTest.name"
    :editable="isProjectOwner() || isAdmin() || isEditor"
  />
  <div class="test__wrapp row justify-between">
    <div v-if="projectStore.currentTest && !testIsLoading" class="test-left row">
      <OTabPanels
        :active-tab="activeTab"
        default-tab="test"
        :default-size="true"
        class="full-width"
        @change-tab="changeTab"
      >
        <template #tabs>
          <q-tab content-class="my-tab" name="test" label="Test" />
          <q-tab content-class="my-tab" name="results" label="Results" />
          <!-- <q-tab content-class="my-tab" name="comments_logs" label="Comments and Logs">
            <span
              v-if="
                (projectStore.projectLogs.length > 0 || projectStore.comments.length > 0) &&
                !testIsLoading
              "
              class="total-badge q-ml-sm"
            >
              {{
                projectStore.projectLogs.length + projectStore.comments.length < 10
                  ? '0' + (projectStore.projectLogs.length + projectStore.comments.length)
                  : projectStore.projectLogs.length + projectStore.comments.length
              }}</span
            >
          </q-tab> -->
        </template>
        <template #panels>
          <q-tab-panel name="test">
            <div class="test-content__container row">
              <div class="section description__section row col-12 q-mb-md">
                <div class="col-12 q-mb-md">
                  <span class="section__title">Description</span>
                </div>
                <div class="col-12 q-mb-lg">
                  <span class="description__text text-break">
                    {{ projectStore.currentTest.description }}
                  </span>
                </div>
                <div class="row col-12 items-center">
                  <Icon icon-name="schedule" icon-folder="testing" />
                  <span class="details__text q-ml-sm">-</span>
                </div>
              </div>
              <div class="section condition__section row col-12 q-mb-md">
                <div class="col-12 q-mb-md">
                  <span class="section__title">Condition</span>
                </div>
                <div class="col-12 row conditions__wrapp">
                  <div class="row col-3 condition-item">
                    <span class="item-title col-12 q-mb-xs">Source</span>
                    <span class="item-value">{{
                      source ? `${source.name}(${source.mode})` : '-'
                    }}</span>
                  </div>
                  <div class="row col-3 condition-item">
                    <span class="item-title col-12 q-mb-xs">Attribute</span>
                    <span class="item-value">{{ attribute ? attribute.name : '-' }}</span>
                  </div>
                  <div class="row col-3 condition-item">
                    <span class="item-title col-12 q-mb-xs">Operator</span>
                    <span class="item-value">{{ fullOperatorName }}</span>
                  </div>
                  <div class="row col-3 condition-item">
                    <span class="item-title col-12 q-mb-xs">Value</span>
                    <span class="item-value">{{
                      projectStore.currentTest.conditions.value || '-'
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="section result__section row col-12 q-mb-md">
                <div class="col-12 q-mb-md">
                  <span class="section__title">Last Result</span>
                </div>
                <div class="col-12 row result__wrapp q-mb-md">
                  <div class="row result-item">
                    <span class="item-title col-12 q-mb-sm">Value</span>
                    <span class="item-value">
                      {{
                        projectStore.currentTestResult && projectStore.currentTestResult.value
                          ? projectStore.currentTestResult.value
                          : '-'
                      }}
                    </span>
                  </div>
                  <div class="row result-item">
                    <span class="item-title col-12 q-mb-sm">Result</span>
                    <span class="item-value">
                      <q-badge
                        v-if="projectStore.currentTestResult"
                        :class="`${resultStatus}-badge`"
                        :label="resultStatus"
                      >
                        <Icon
                          :icon-name="`testing-${resultStatus}-active`"
                          icon-folder="testing"
                          icon-size="20px"
                          class="q-mr-sm"
                        />
                      </q-badge>
                    </span>
                  </div>
                </div>
                <div class="row col-12 result-timestamp q-mb-lg">
                  <span class="timestamp__title col-12 q-mb-md">Timestamp</span>
                  <div class="row items-center">
                    <Icon icon-name="schedule" icon-folder="testing" />
                    <span class="timestamp__text q-ml-sm">
                      {{
                        projectStore.currentTestResult && projectStore.currentTestResult.created_at
                          ? formatDate(projectStore.currentTestResult.created_at)
                          : '-'
                      }}
                    </span>
                  </div>
                </div>
                <div class="row col-12 result-log">
                  <div class="row col-12 q-mb-md items-center">
                    <span
                      class="log__title q-mr-sm cursor-pointer"
                      @click="logsExpanded = !logsExpanded"
                      >Test Execution Log</span
                    >
                    <div class="row arrow__container justify-center">
                      <Icon
                        v-if="!logsExpanded"
                        icon-folder="task"
                        icon-name="arrow_down"
                        class="cursor-pointer"
                        @click="logsExpanded = true"
                      />
                      <Icon
                        v-else
                        icon-folder="task"
                        icon-name="arrow_up"
                        class="cursor-pointer"
                        @click="logsExpanded = false"
                      />
                    </div>
                  </div>
                  <div
                    class="row log__container items-center"
                    :class="[logsExpanded ? 'log__expanded' : '']"
                  >
                    <span class="log__text">
                      {{
                        projectStore.currentTestResult && projectStore.currentTestResult.details
                          ? projectStore.currentTestResult.details
                          : '-'
                      }}
                    </span>
                    <q-btn
                      unelevated
                      class="copy-btn row justify-center items-center q-pa-none"
                      @click="copyDetails"
                    >
                      <Icon icon-name="content_copy" icon-folder="testing" />
                      <q-tooltip>Copy log information</q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </div>
              <div class="section history__section row col-12">
                <div class="col-12">
                  <span class="section__title">Test Results History</span>
                  <TestingChart class="q-mt-md" />
                </div>
                <div class="row col-12 justify-end">
                  <q-btn
                    flat
                    unelevated
                    label="See all results"
                    class="btn-all"
                    @click="changeTab('results')"
                  />
                </div>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="results">
            <TabResults />
          </q-tab-panel>
          <!-- <q-tab-panel name="comments_logs">
            <OComments
              v-if="projectStore.currentTest"
              :object-type="'testing_test'"
              :object-id="projectStore.currentTest.id"
              class="coomments__wrapp"
              @update-concept="updateConcept"
            />
          </q-tab-panel> -->
        </template>
      </OTabPanels>
    </div>

    <div v-if="projectStore.currentTest && !testIsLoading" class="test-right">
      <div class="top-actions__container row items-center">
        <q-btn
          v-if="isProjectOwner() || isAdmin() || isEditor"
          class="q-ml-md edit-btn"
          icon="edit"
          unelevated
          :disable="!isProjectOwner() && !isAdmin() && !isEditor"
          label="Edit"
          @click="showEditTest = true"
        />
        <q-btn
          class="q-ml-md test-btn"
          unelevated
          :disable="
            isLoading ||
            runningTest ||
            !projectStore.currentTest ||
            !projectStore.currentTest.status
          "
          label="Test Now"
          :loading="runningTest"
          @click="runTest"
        />
      </div>
      <div class="row q-mb-lg">
        <Icon icon-name="info" icon-folder="colored" />
        <span class="details__title q-ml-sm">Info</span>
      </div>
      <div class="row status__container col-12 q-mb-lg">
        <span class="select__title q-mb-md col-12">Status</span>
        <div class="row items-center">
          <q-option-group
            v-if="(isAdmin() || isProjectOwner() || isEditor) && !testIsLoading"
            v-model="group"
            :options="options"
            color="black"
            type="toggle"
            class="status-toggle"
            :disable="isLoading || runningTest || (!isEditor && !isProjectOwner() && !isAdmin())"
            @update:model-value="toggleTest"
          />
          <span class="status__text">{{ group ? 'Enabled' : 'Disabled' }}</span>
        </div>
      </div>
      <div class="row col-12 q-mb-md">
        <span class="select__title q-mb-sm col-12">Assigned To</span>
        <q-btn-dropdown
          v-if="assignedUser && (isAdmin() || isProjectOwner() || isEditor)"
          flat
          :label="
            assignedUser ? `${assignedUser.firstname} ${assignedUser.lastname}` : 'Please Select'
          "
          :disable="isLoading || runningTest || (!isEditor && !isProjectOwner() && !isAdmin())"
          class="q-mb-md"
        >
          <AUsersDropDown :assigned-user="assignedUser" @select-user="selectUser" />
        </q-btn-dropdown>
      </div>
      <hr />
      <div class="test__controls column q-mt-md">
        <div class="row col-12 justify-between q-my-sm">
          <span class="associated__title">Associated Controls</span>
        </div>
        <div
          v-if="projectStore.testingTestControls && projectStore.testingTestControls.length > 0"
          class="controls__container col-12 q-mb-md"
        >
          <q-badge
            v-for="control in projectStore.testingTestControls"
            :key="control.id"
            class="control-badge q-mr-sm q-mb-sm cursor-pointer"
            :class="[control.status]"
            @click="openControl(control.id)"
          >
            <Icon
              :icon-name="statusIcon(control.status)"
              icon-folder="status"
              icon-size="20px"
              class="q-mr-sm"
            />
            <span>{{ control.control_code }}</span>
            <Icon
              v-if="isProjectOwner() || isAdmin() || isEditor"
              icon-name="close"
              icon-folder="task"
              icon-size="12px"
              class="q-ml-sm"
              @click.stop.prevent="setRemoveControl(control)"
            />
            <q-tooltip anchor="top middle" self="center middle">
              {{ control.control_code }}
            </q-tooltip>
          </q-badge>
        </div>
        <span v-else class="detail-text q-mb-md">-</span>
        <div
          v-if="isAdmin() || isProjectOwner() || isEditor"
          class="actions__container col-12 q-mb-sm"
        >
          <q-btn
            class="btn-create full-width q-mb-md"
            label="Associate to a Control"
            unelevated
            @click="showAssociateToControl = true"
          />
        </div>
      </div>
      <!-- <hr /> -->
      <!-- <div class="test__tags column">
        <span class="tags__title q-my-sm">TAGS</span>
        <div class="tags__container row" :class="[tagsExpanded ? 'tags__expanded' : '']">
          <q-badge
            v-for="tag in taskStore.task.tags"
            :key="tag.name"
            class="general-badge q-mr-sm q-mb-sm"
            >{{ `${tag.name}:` }}&nbsp;<b>{{ tag.value }}</b></q-badge
          >
        </div>
        <div v-if="tagsExpanded" class="row arrow__container col-12 justify-center">
          <Icon
            v-if="!tagsExpanded"
            icon-folder="task"
            icon-name="arrow_down"
            class="cursor-pointer"
            @click="tagsExpanded = true"
          />
          <Icon
            v-else
            icon-folder="task"
            icon-name="arrow_up"
            class="cursor-pointer"
            @click="tagsExpanded = false"
          />
        </div>
        <q-btn class="btn-create full-width q-mt-sm" label="Update Tag(s)" unelevated disable />
      </div> -->
    </div>
    <SDetailPage v-if="testIsLoading" />
  </div>
  <ADialog
    :show-dialog="showEditTest"
    max-width="650px !important"
    min-width="650px !important"
    min-height="auto"
    class="dialog-create-test"
    @hide="closeDialog"
  >
    <DEditTest @close-dialog="closeDialog" />
  </ADialog>
  <ADialog
    v-if="removeControlAssociation"
    :show-dialog="showRemoveControlDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showRemoveControlDialog = false"
  >
    <DConfirm
      :title="`Would you like to remove the association with the Control ${removeControlAssociation.name} ?`"
      description=""
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="confirmRemoveAssociation"
    />
  </ADialog>
  <ADialog
    :show-dialog="showAssociateToControl"
    max-height="auto !important"
    max-width="600px !important"
    min-height="auto !important"
    min-width="600px !important"
    @hide="showAssociateToControl = false"
  >
    <DTestCreateControl
      v-if="projectStore.currentTest"
      :test="projectStore.currentTest"
      @close-dialog="closeDialog"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.title__container {
  max-width: 50%;
}

.test-left {
  width: 73%;
  height: fit-content;

  .q-tab-panel {
    padding: 0;

    .test-content__container {
      .section {
        padding: 20px;
        background: $white;
        border-radius: 5px;
        box-shadow: 0px 3px -1px 8px rgba(0, 0, 0, 0.05);
        .section__title {
          @include heading-05(700, $secondary-600);
        }
      }
      .description__section {
        .description__text {
          @include paragraph-01(400, $secondary-600);
        }

        .details__text {
          color: $secondary-500;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .condition__section {
        .condition-item {
          padding-left: 12px;
          border-left: 2px solid var(--Secondary-100, #e1e7ea);
          .item-title {
            @include caption(400, $secondary-500);
          }
          .item-value {
            @include paragraph-01(600, $secondary-600);
          }
        }
      }

      .result__section {
        .result-item {
          padding: 0 12px;
          .item-title {
            @include paragraph-01(600, $secondary-500);
          }
          .item-value {
            @include paragraph-01(600, $secondary-800);
          }
        }
        .result-item:first-child {
          padding-left: 0;
          border-right: 2px solid var(--Secondary-100, #e1e7ea);
        }

        .result-timestamp {
          .timestamp__title {
            @include paragraph-01(600, $secondary-500);
          }
          .timestamp__text {
            @include paragraph-01(600, $secondary-800);
          }
        }

        .result-log {
          .log__title {
            color: $secondary-500;
            font-size: 14px;
            font-weight: 600;
            text-decoration-line: underline;
          }

          .arrow__container {
            :deep(svg path) {
              fill: #a5b7c0;
            }
          }

          .log__container {
            padding: 0;
            border-radius: 10px;
            background: rgba(240, 243, 244, 0.5);
            position: relative;
            max-height: 0px;
            overflow: hidden;
            .log__text {
              @include paragraph-01(400, $secondary-500);
            }
            .copy-btn {
              position: absolute;
              right: 10px;
            }
          }
          .log__expanded {
            padding: 10px 100px 10px 20px;
            max-height: unset;
          }
        }
      }

      .history__section {
        @media screen and (max-width: 1500px) {
          overflow: hidden;
          overflow-x: scroll;
        }
        button {
          background: transparent !important;
          color: $secondary-500 !important;
          border: 1px solid $secondary-500;
          text-transform: none;
        }
      }
    }
  }
}

.test-right {
  width: 25%;
  height: fit-content;
  border-radius: 5px;
  padding: 20px;
  margin-top: 60px;
  background: $white;
  position: relative;

  @media screen and (max-width: 1050px) {
    width: 26%;
  }

  .top-actions__container {
    position: absolute;
    right: 0;
    top: -60px;
    button {
      text-transform: unset;
      border-radius: 5px;
    }
    .edit-btn {
      background: transparent !important;
      color: $secondary-500 !important;
      border: 1px solid $secondary-500;
    }
    .test-btn {
      background: $secondary-500 !important;
      color: $white;
    }
  }

  .details__title {
    @include heading-04(600, $secondary-600);
  }

  .select__title {
    @include caption(400, $secondary-500);
  }

  .status__container {
    .status__text {
      color: $secondary-600;
      font-size: 14px;
      font-weight: 400;
    }
    :deep(.q-gutter-x-sm) {
      margin-left: -16px !important;
    }
    :deep(.q-toggle__inner) {
      color: $secondary-700 !important;
    }
  }

  .q-select .q-field__inner {
    border: none !important;
    padding: 0 !important;
  }

  hr {
    border: 1px solid $secondary-100;
  }

  .q-btn-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.24);

    :deep(.q-focus-helper) {
      display: none;
    }

    :deep(span) {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    :deep(i) {
      color: $common-3 !important;
    }
  }

  .q-btn-dropdown:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }
  :deep(.q-btn__content) {
    span {
      text-transform: none;
    }
  }
  .text-black {
    @include paragraph-01(400, $common-4);
  }

  .test__risks,
  .test__controls {
    .associated__title {
      @include caption(400, #8c8c8c);
    }
    .actions__container {
      button {
        height: 40px;
        color: $secondary-500;
        background: transparent !important;
        border: 1px solid $secondary-500;
        text-transform: none;
        border-radius: 5px;
      }
      :deep(.q-field__label) {
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
    .controls__container {
      .q-badge:hover {
        span {
          opacity: 0.7;
        }
      }
      .control-badge {
        flex-direction: row;
      }
    }
  }
}

.test__tags {
  .tags__title {
    @include caption(400, $common-2);
  }
  .tags__container {
    max-height: 30px;
    overflow: hidden;
  }
  .tags__expanded {
    max-height: unset;
  }
  .btn-create {
    height: 40px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    border-radius: 5px;
  }
}

:deep(.q-tabs) {
  margin: 0;

  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .q-tab {
    padding: 0;
    .q-tab__content {
      padding: 4px 10px 15px;
      .q-tab__indicator {
        height: 4px;
      }
      .full-width {
        span {
          @include heading-03(600, $secondary-600);
        }
      }
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  .q-tabs__arrow--left,
  .q-tabs__arrow--right {
    display: none !important;
  }
}

:deep(.q-item) {
  padding: 0 20px 0 10px;

  .q-item__section {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}

.content-wrapper {
  padding: 16px 0 !important;
}
</style>
