import initApi from '@/client_heplers/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { ProjectsApi, RisksApi, V1Api } from '@/client/api';

import type {
  Control,
  Risk,
  RiskAggregator,
  RiskTreatment,
  RiskTreatmentStrategy,
  ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest,
  RisksApiGetRiskApiRisksRiskIdGetRequest,
  RisksApiUpdateRiskApiRisksRiskIdPatchRequest,
  RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest,
  RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest,
  RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest,
  RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest,
  RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest,
  RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest,
  RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest,
  RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest,
  RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest,
  RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest,
  V1ApiGetRiskApiV1RisksRiskIdGetRequest,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import { AxiosError } from 'axios';

interface Strategy extends RiskTreatmentStrategy {
  default: boolean;
}

export const useRisksStore = defineStore('risks', () => {
  const router = useRouter();
  const currentRisk = ref<RiskAggregator | null>(null);
  const riskLight = ref<Risk | null>(null);
  const isLoading = ref(false);
  const riskTreatment = ref<RiskTreatment | null>(null);
  const riskTreatmentStrategies = ref<Strategy[]>([]);
  const riskControls = ref<Control[] | null>(null);

  const createRisk = async (params: ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest) => {
    try {
      const projectApi = await initApi(ProjectsApi);
      const response = await projectApi?.createRiskApiProjectsProjectIdRisksPost(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getRisk = async (
    params: RisksApiGetRiskApiRisksRiskIdGetRequest,
    reassign: boolean = true,
  ) => {
    isLoading.value = true;
    try {
      const risksApi = await initApi(RisksApi);
      const response = await risksApi?.getRiskApiRisksRiskIdGet(params);
      if (!response) return;

      if (reassign) {
        currentRisk.value = response.data;
      }

      return response.data;
    } catch (error) {
      router.push({ name: 'project-risks' });

      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const getLightRisk = async (params: V1ApiGetRiskApiV1RisksRiskIdGetRequest) => {
    try {
      const v1Api = await initApi(V1Api);
      const response = await v1Api?.getRiskApiV1RisksRiskIdGet(params);
      if (!response) return;

      riskLight.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateRisk = async (params: RisksApiUpdateRiskApiRisksRiskIdPatchRequest) => {
    isLoading.value = true;
    try {
      const risksApi = await initApi(RisksApi);
      const response = await risksApi?.updateRiskApiRisksRiskIdPatch(params);
      if (!response) return;

      currentRisk.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateRiskAssignee = async (
    params: RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest,
  ) => {
    isLoading.value = true;

    try {
      const risksApi = await initApi(RisksApi);
      const response = await risksApi?.updateRiskAssigneeApiRisksRiskIdAssigneePatch(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const createRiskTreatment = async (
    params: RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest,
  ) => {
    isLoading.value = true;
    try {
      const risksApi = await initApi(RisksApi);
      await risksApi?.createRiskTreatmentApiRisksRiskIdTreatmentPost(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const getRiskTreatment = async (
    params: RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest,
  ) => {
    if (!currentRisk.value) throw new Error('Risk not found');

    const errorMessage = 'Risk treatment not found';

    try {
      const risksApi = await initApi(RisksApi);

      const response = await risksApi?.getRiskTreatmentApiRisksRiskIdTreatmentGet(params, {
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status == 404;
        },
      });

      if (!response) return;

      if (response.status === 404) {
        throw new Error(errorMessage);
      }

      riskTreatment.value = response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        handleApiError(error as AxiosError);
      }

      if (error instanceof Error && error.message === errorMessage) {
        const riskProps = {
          conclusion: null,
          selected_strategy_id: null,
        };

        const newParams = {
          riskId: params.riskId,
          riskTreatmentProps: riskProps,
        };

        await createRiskTreatment(newParams);
        await getRiskTreatment({ riskId: newParams.riskId });
      }
    }
  };

  const updateRiskTreatment = async (
    params: RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest,
  ) => {
    try {
      const risksApi = await initApi(RisksApi);
      const response = await risksApi?.updateRiskTreatmentApiRisksRiskIdTreatmentPatch(params);
      if (!response) return;

      riskTreatment.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createRiskTreatmentStrategy = async (
    params: RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest,
  ) => {
    isLoading.value = true;

    try {
      const risksApi = await initApi(RisksApi);
      const response =
        await risksApi?.createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const getRiskTreatmentStrategy = async (
    params: RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest,
  ) => {
    try {
      const risksApi = await initApi(RisksApi);
      const response =
        await risksApi?.getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(params);
      if (!response) return;

      riskTreatmentStrategies.value = response.data as Strategy[];
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateRiskTreatmentStrategy = async (
    params: RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest,
  ) => {
    try {
      const risksApi = await initApi(RisksApi);
      const response =
        await risksApi?.updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(
          params,
        );
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteRiskTreatmentStrategy = async (
    params: RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest,
  ) => {
    try {
      const risksApi = await initApi(RisksApi);

      await risksApi?.deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(
        params,
      );
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getRiskControls = async (
    params: RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest,
  ) => {
    try {
      const controlsApi = await initApi(RisksApi);

      const response = await controlsApi?.getControlsByRiskApiRisksRiskIdControlsGet(params);

      if (!response) return;

      riskControls.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const removeControlFromRisk = async (
    params: RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest,
  ) => {
    try {
      const controlsApi = await initApi(RisksApi);

      await controlsApi?.removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const resetRisk = () => {
    currentRisk.value = null;
    riskTreatment.value = null;
  };

  return {
    createRisk,
    getRisk,
    currentRisk,
    updateRisk,
    updateRiskAssignee,
    isLoading,
    createRiskTreatment,
    getRiskTreatment,
    updateRiskTreatment,
    createRiskTreatmentStrategy,
    getRiskTreatmentStrategy,
    updateRiskTreatmentStrategy,
    deleteRiskTreatmentStrategy,
    riskTreatment,
    riskTreatmentStrategies,
    resetRisk,
    getRiskControls,
    riskControls,
    removeControlFromRisk,
    getLightRisk,
    riskLight,
  };
});
