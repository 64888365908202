<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

import { formatDate } from '@/composables/utils';

import { useProjectsStore } from '@/stores/ProjectsStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { hasRights, isAdmin } from '@/composables/Auth';

import type { PropType } from 'vue';
import type { Comment, Concept } from '@/client/api';
import type { TipTapContent } from '@/interfaces/models/Editor';

import Icon from '@/components/atoms/Icon.vue';
import MComment from '@/components/molecules/MComment.vue';
import TipTapEditor from '@/components/organisms/TipTap/TipTapEditor.vue';

interface TimestampedEntry {
  created_at: string;
  created_by: {
    firstname: string;
    lastname: string;
  };
  action_name?: string;
  content?: string;
  object_id?: string;
  icon?: string;
}

const props = defineProps({
  objectId: {
    type: String,
    required: true,
  },
  objectType: {
    type: String as PropType<Concept>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  reviewer: {
    type: Boolean,
    default: false,
  },
  inReview: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updateConcept', 'reassignReviewer', 'updateComments']);

const editor = ref<TipTapContent>({
  content: '',
  json_content: {},
});

const projectsStore = useProjectsStore();
const isLoading = ref(false);

async function postComment() {
  if (!projectsStore.project || !editor.value) return;

  const params = {
    objectId: props.objectId,
    projectId: projectsStore.project.id,
    objectType: props.objectType,
    createComment: editor.value,
  };
  try {
    isLoading.value = true;

    await projectsStore.postComment(params);

    successMessage('Comment created successfully');

    editor.value = {
      content: '',
      json_content: {},
    };
    updateCommentsAndLogs();
  } catch (error) {
    captureException(error, {
      message: 'Component: OComments, Function: postComment',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

const combinedEntries = computed(() => {
  if (!projectsStore.comments || !projectsStore.projectLogs) return [] as TimestampedEntry[];

  let combined: TimestampedEntry[] = [
    ...(projectsStore.comments as TimestampedEntry[]),
    ...(projectsStore.projectLogs as TimestampedEntry[]),
  ];

  combined.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

  return combined;
});

function updateEditor(value: TipTapContent) {
  editor.value = value;
}

async function updateCommentsAndLogs() {
  if (projectsStore.project) {
    const params = {
      objectId: props.objectId,
      projectId: projectsStore.project.id,
      objectType: props.objectType,
    };

    try {
      await projectsStore.getComments(params);

      const logParams = {
        objectId: props.objectId,
        projectId: projectsStore.project.id,
      };

      await projectsStore.getProjectLogs(logParams);
    } catch (error) {
      captureException(error, {
        message: 'Component: OComments, Hook: onMounted',
      });
    }
  }
}

onMounted(async () => {
  updateCommentsAndLogs();
});
</script>

<template>
  <div class="wrapp full-width">
    <div class="list__wrapp col-12">
      <div v-for="item in combinedEntries" :key="item.created_at" class="comment-log__container">
        <MComment v-if="item.content" :comment="item as Comment" />
        <div v-if="item.action_name" class="log__wrapp row items-center q-mb-md">
          <div class="log__type row justify-center items-center q-mr-md" :class="item.icon">
            <Icon v-if="item.icon" :icon-name="item.icon" icon-folder="comments">
              <template v-if="item.icon === 'person'" #tooltip>
                <q-tooltip anchor="top middle" self="center middle" max-width="200px">
                  {{ item.created_by.firstname + ' ' + item.created_by.lastname }}
                </q-tooltip>
              </template>
            </Icon>
            <Icon v-else icon-name="person" icon-folder="comments" />
          </div>
          <q-avatar class="q-mr-md">
            <span>
              {{
                item.created_by.firstname.charAt(0).toUpperCase() +
                item.created_by.lastname.charAt(0).toUpperCase()
              }}
            </span>
          </q-avatar>
          <span class="log__action q-mr-md col-8" v-html="item.action_name"></span>
          <span class="log__time">{{ formatDate(item.created_at) }}</span>
        </div>
      </div>
      <MComment
        v-if="
          (projectsStore.project &&
            hasRights(projectsStore.project.id, 'set_review_decision') &&
            inReview) ||
          (isAdmin() && inReview)
        "
        :object-id="objectId"
        :object-type="objectType"
        @update-concept="emit('updateConcept')"
        @reassign-reviewer="emit('reassignReviewer')"
      />
    </div>
    <div class="row col-12">
      <div class="col-12 relative-position">
        <TipTapEditor :editor-content="editor" @update-editor="updateEditor" />
      </div>
    </div>
    <div class="row col-12 justify-end q-mt-md">
      <q-btn
        :disable="!editor || isLoading"
        :loading="isLoading"
        label="Comment"
        unelevated
        @click="postComment"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  padding: 20px;
  border-radius: 5px;
  button {
    background: $secondary-500 !important;
    color: $white;
    border-radius: 5px;
    text-transform: none;
    padding: 4px 24px;
  }
  .comment-log__container {
    position: relative;
    .log__wrapp {
      padding: 1px 58px;
      position: relative;
      z-index: 10;
      .log__type {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
      }
      .q-avatar {
        width: 30px;
        height: 30px;
        background: #eaddff;
        span {
          color: #6750a4;
          font-size: 14px;
        }
      }
      span,
      b {
        color: $secondary-500;
        font-size: 12px;
      }
    }
  }
  .comment-log__container::after {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 72px;
    width: 2px;
    height: 24px;
    background: $secondary-200;
    z-index: 1;
  }
  .q-editor {
    position: relative;
    z-index: 10;
  }
}
</style>
