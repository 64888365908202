<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted } from 'vue';

import type { RiskAggregator, RiskComponent, UserDisplay } from '@/client/api';
import type { PropType } from 'vue';

import { captureException } from '@/composables/Sentry';

import { useProjectsStore } from '@/stores/ProjectsStore';

const props = defineProps({
  risk: {
    type: Object as PropType<RiskAggregator>,
    required: true,
  },
  riskIsLoading: {
    type: Boolean,
  },
  reviewers: {
    type: Array as PropType<UserDisplay[]>,
    default: () => [],
  },
  isAllowed: {
    type: Boolean,
    default: false,
  },
});

const Components = {
  evidence: defineAsyncComponent(() => import(`@/components/Risks/RisksComponents/evidence.vue`)),
  matrix: defineAsyncComponent(() => import(`@/components/Risks/RisksComponents/matrix.vue`)),
};

const projectStore = useProjectsStore();

type ComponentType = keyof typeof Components;

const components = computed(() => {
  return (
    props.risk?.components.map((component: RiskComponent) => {
      const componentType = component.component_type as ComponentType;
      const editable = !props.risk?.is_locked && props.isAllowed;

      return {
        component: Components[componentType],
        props: { component, editable: !!editable },
        key: component.id,
      };
    }) ?? []
  );
});

onMounted(async () => {
  if (!props.risk || !projectStore.project) return;

  try {
    await projectStore.getProjectsEvidenceList({ projectId: projectStore.project.id });
  } catch (error) {
    captureException(error, { message: 'Component: RiskAssessment, Hook: onMounted' });
  }
});
</script>

<template>
  <div v-if="risk && !riskIsLoading">
    <component
      :is="comp.component"
      v-for="comp in components"
      :key="comp.key"
      v-bind="comp.props"
      class="q-md-md"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
</style>
