<script lang="ts" setup>
import { debounce } from 'lodash';
import { onMounted, ref, watch } from 'vue';

import type { QInput } from 'quasar';

import { useAuthStore } from '@/stores/AuthStore';

import { successMessage } from '@/composables/Notify';
import { captureException } from '@/composables/Sentry';
import { MAX_NAME_SIZE } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';
import PageRoute from '@/components/atoms/PageRoute.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import DToken from '@/components/dialogs/DToken.vue';
import OTable from '@/components/organisms/Table/OTable.vue';

interface DurationOption {
  label: string;
  value: number;
}

const authStore = useAuthStore();
const name = ref(null);
const duration = ref<DurationOption | null>(null);
const nameInput = ref<QInput | null>(null);
const showDialog = ref(false);
const isLoading = ref(false);
const durationOptions = [
  {
    label: '1 hour',
    value: 1,
  },
  {
    label: '1 day',
    value: 24,
  },
  {
    label: '1 week',
    value: 168,
  },
  {
    label: '1 month',
    value: 720,
  },
  {
    label: '1 year',
    value: 8760,
  },
];

const columnsNames = {
  name: 'Name',
  access_token: 'Token',
  token_type: 'Type',
  expires_at: 'Expires At',
  revoke: 'Revoke',
};

const debouncedValidation = debounce(() => {
  if (!nameInput.value) return;

  nameInput.value.validate();
}, 100);

async function createToken() {
  if (!name.value || !duration.value) return;
  try {
    isLoading.value = true;

    await authStore.createToken({
      createToken: { name: name.value, duration_in_hours: duration.value.value },
    });

    successMessage('Token created successfully');
  } catch (error) {
    captureException(error, {
      message: 'Component: TokenPage, Function: createToken',
      data: {
        name: name.value,
        duration_in_hours: duration.value.value,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  try {
    await authStore.getTokens();
  } catch (error) {
    captureException(error, {
      message: 'Component: TokenPage, Hook: onMounted, Method: getTokens',
    });
  }
});

watch(
  () => authStore.tokenData,
  (val) => {
    if (val) {
      showDialog.value = true;
    }
  },
);
</script>

<template>
  <div class="content-wrapper">
    <PageRoute class="q-mb-md" />
    <div class="row items-center">
      <PageTitle title="API Tokens" />
    </div>
    <div class="new-token__container row q-mb-md">
      <div class="row col-12 items-center q-mb-md">
        <Icon icon-name="key" icon-folder="colored" />
        <span class="q-ml-sm">New Token</span>
      </div>
      <q-input
        ref="nameInput"
        v-model="name"
        outlined
        dense
        placeholder="Token Name*"
        :rules="[
          (val: string) => (val && val.length > 0) || 'Please type something',
          (val: string) =>
            (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
        ]"
        class="q-mr-md col-3"
        no-error-icon
        @update:model-value="debouncedValidation"
      />
      <q-select
        ref="durationInput"
        v-model="duration"
        outlined
        :options="durationOptions"
        dense
        class="col-2 q-mr-md"
        :rules="[(val: any) => val !== null || 'Please select something']"
        label="Duration*"
      />
      <q-btn
        :loading="isLoading"
        label="Create Token"
        :disable="!duration || !name || isLoading"
        unelevated
        @click="createToken"
      />
    </div>

    <OTable
      v-if="authStore.allTokens"
      :data="authStore.allTokens"
      :columns-names="columnsNames"
      :search-filter="false"
      :row-clickable="false"
      :show-pagination="false"
      store="authStore"
      action="getTokens"
    >
    </OTable>
    <DToken v-if="showDialog && authStore.tokenData" @hide="showDialog = false" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

.new-token__container {
  padding: 20px 20px 10px;
  background: $white;
  border-radius: 5px;
  span {
    @include paragraph-02(700, $secondary-600);
  }
  button {
    max-height: 40px;
    background: $secondary-500 !important;
    color: $white;
    border-radius: 5px;
    text-transform: none;
    padding: 4px 24px;
  }
}

.btn__container {
  a {
    min-height: 41.6px;
    background: $secondary-500 !important;
    border-radius: 5px;
    text-transform: none;
  }
}

:deep(button) {
  i {
    color: $primary-500;
  }
}

:deep(.q-btn__content) {
  display: flex !important;
}
</style>
