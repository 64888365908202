<script setup lang="ts">
import { ref } from 'vue';

import Icon from '@/components/atoms/Icon.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';

interface Message {
  message: string;
  role: 'user' | 'assistant';
}

const input = ref('');
const messages = ref<Message[]>([]);

const sendMessage = async () => {
  if (!input.value.trim()) return;

  messages.value.push({
    message: input.value,
    role: 'user',
  });

  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/api/experimental/llm/chat/completions?model=gpt-3.5-turbo&stream=true`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add other necessary headers
        },
        body: JSON.stringify(messages.value),
      },
    );

    messages.value.push({
      message: '',
      role: 'assistant',
    });

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('No reader');
    }

    reader.read().then(function processText({ done, value }) {
      if (done) {
        return;
      }

      const chunkText = new TextDecoder().decode(value);
      const chunkData = chunkText.replace(/data: /g, '');

      if (chunkData) {
        messages.value[messages.value.length - 1].message += chunkData;
      }

      // Read the next chunk
      reader.read().then(processText);
    });
  } catch (error) {
    console.error('Error sending message:', error);
  }

  input.value = ''; // Clear input field after sending
};
</script>
<template>
  <div class="chat-container">
    <div v-if="messages.length > 0" class="q-pa-md row justify-center">
      <div class="messages">
        <q-chat-message
          v-for="message in messages"
          :key="message.message"
          :text="[message.message]"
          :sent="message.role === 'user'"
          :name="message.role === 'user' ? 'Me' : 'Modulos'"
          :bg-color="message.role === 'user' ? 'indigo-3' : 'teal-3'"
        >
          <template #avatar>
            <q-avatar v-if="message.role !== 'user'">
              <Icon icon-name="modulos-logo" icon-folder="drawer" icon-size="26px" />
            </q-avatar>
            <q-avatar v-else>
              <Icon icon-name="icon-user" icon-folder="task" icon-size="26px" />
            </q-avatar>
          </template>
        </q-chat-message>
      </div>
    </div>
    <div v-else class="q-pa-md row justify-center items-center" style="height: 50%">
      <AEmptyData
        icon-name="chat-2"
        header="Welcome to Modulos Chat!"
        text="Type your message below to get started."
        class="full-width items-center empty__content"
      />
    </div>
    <div class="row justify-center q-chat-container">
      <q-input v-model="input" class="q-chat-input" rounded outlined @keyup.enter="sendMessage">
        <template #append>
          <q-avatar>
            <Icon icon-name="modulos-logo" icon-folder="drawer" icon-size="26px" />
          </q-avatar>
        </template>
      </q-input>
    </div>
  </div>
</template>

<style scoped lang="scss">
.chat-container {
  position: relative;
  height: calc(100vh - 176px);
  overflow-y: auto;
}

.messages {
  padding-bottom: 60px; /* Space for the input */
  max-width: 600px;
}

.q-chat-container {
  background-color: #f0f3f4;
  position: fixed;
  width: 100%;

  bottom: 50px;
  z-index: 100;
  box-shadow: 0 -20px 10px rgba(255, 255, 255, 0.6);
}
:deep(.q-chat-input) {
  width: 80%;
}
:deep(.q-message-name) {
  color: black;
}
</style>
