import { ref } from 'vue';
import { defineStore } from 'pinia';
import initApi from '@/client_heplers/utils';

import { AssetCardsApi } from '@/client/api';

import type {
  AssetCard,
  AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest,
  AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest,
  AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { AxiosError } from 'axios';

export const useAssetsStore = defineStore('assets', () => {
  const currentAsset = ref<AssetCard | null>(null);
  const isLoading = ref(false);

  const getAsset = async (
    params: AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest,
    reassign: boolean = true,
  ) => {
    isLoading.value = true;
    try {
      const assetsApi = await initApi(AssetCardsApi);
      const response = await assetsApi?.getAssetCardApiAssetCardsAssetCardIdGet(params);

      if (!response) return;

      if (reassign) {
        currentAsset.value = response.data;
      }

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateAsset = async (
    params: AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest,
  ) => {
    isLoading.value = true;
    try {
      const assetApi = await initApi(AssetCardsApi);
      const response = await assetApi?.updateAssetCardApiAssetCardsAssetCardIdPatch(params);

      if (!response) return;

      currentAsset.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateAssetAssignee = async (
    params: AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest,
  ) => {
    isLoading.value = true;

    try {
      const assetApi = await initApi(AssetCardsApi);
      const response =
        await assetApi?.updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(params);
      if (!response) return;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    getAsset,
    currentAsset,
    isLoading,
    updateAsset,
    updateAssetAssignee,
  };
});
