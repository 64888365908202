<script setup lang="ts">
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import type { QInput } from 'quasar';

import type { TestingMetric, TestingSource } from '@/client/api';

import { useAuthStore } from '@/stores/AuthStore';
import { useProjectsStore } from '@/stores/ProjectsStore';

import { errorMessage, successMessage } from '@/composables/Notify';
import {
  operatorFloatOptions,
  operatorIntegerOptions,
  operatorStringOptions,
} from '@/composables/Vendors';

import { captureException } from '@/composables/Sentry';
import { MAX_DESCRIPTION_SIZE, MAX_NAME_SIZE } from '@/composables/utils';

import Icon from '@/components/atoms/Icon.vue';

interface Operator {
  type: string;
  name: string;
  value: string;
}

const emit = defineEmits(['closeDialog', 'updateList']);

const authStore = useAuthStore();
const projectsStore = useProjectsStore();
const router = useRouter();
const name = ref('');
const description = ref('');
const source = ref<TestingSource | null>(null);
const attribute = ref<TestingMetric | null>(null);
const operator = ref<Operator | null>(null);
const value = ref<string | null | number>(null);
const sourceOptions = ref<TestingSource[]>([]);
const attributeOptions = ref<TestingMetric[]>([]);

const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);
const disableSave = ref(true);
const isManual = ref(false);
const isLoading = ref(false);

function onReset() {
  name.value = '';
}

const isNameValid = computed(() => nameInput.value?.validate());
const isDescriptionValid = computed(() => descriptionInput.value?.validate());

const debouncedValidation = debounce(() => {
  validate();
}, 100);

function validate() {
  if (
    isNameValid.value &&
    isDescriptionValid.value &&
    source.value &&
    attribute.value &&
    operator.value &&
    value.value
  ) {
    disableSave.value = false;
  } else {
    disableSave.value = true;
  }
}

async function getSourceAttributes() {
  if (!source.value || !projectsStore.project) return;

  attribute.value = null;
  operator.value = null;
  value.value = null;

  try {
    if (!source.value) {
      throw new Error('Source is not selected');
    }

    await projectsStore.getSingleSourceAttributes({
      projectId: projectsStore.project.id,
      testingSourceId: source.value.id,
    });

    attributeOptions.value = projectsStore.currentSourceAttributes
      ? projectsStore.currentSourceAttributes.items
      : [];

    validate();
  } catch (error) {
    captureException(error, {
      message: 'Component: DCreateTest, Function: getSourceAttributes',
    });
  }
}

function updateAttribute() {
  operator.value = null;
  value.value = null;
  validate();
}

function updateOperator() {
  value.value = null;
  validate();
}

function switchState(state: boolean) {
  isManual.value = state;
}

function preventInvalidInput(event: KeyboardEvent) {
  if (!operator.value || operator.value.type !== 'integer') return;

  const invalidChars = ['.', ','];
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
}

async function newTest() {
  if (!projectsStore.project || !authStore.user) return;

  const conditions = {
    source_id: source.value ? source.value.id : '',
    metric_id: attribute.value ? attribute.value.id : '',
    operator: operator.value ? operator.value.value : '',
    value:
      attribute.value && attribute.value.type !== 'string'
        ? Number(value.value)
        : value.value || '',
  };

  const params = {
    projectId: projectsStore.project.id,
    createTestingTest: {
      name: name.value,
      description: description.value,
      conditions: conditions,
      assigned_to_id: authStore.user.id,
      status: true,
    },
  };

  try {
    isLoading.value = true;

    await projectsStore.createTestingTest(params);

    if (!projectsStore.currentTest) return;

    successMessage(`${name.value} created successfully`);

    router.push(
      `/projects/project-detail/${projectsStore.project.id}/test-detail/${projectsStore.currentTest.id}`,
    );

    emit('closeDialog');
  } catch (error) {
    errorMessage('Failed to create test');

    captureException(error, {
      message: 'Component: DCreateTest, Function: newTest',
      data: {
        params: params,
      },
    });
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  if (!projectsStore.project) return;

  try {
    await projectsStore.getProjectTestingSources({
      projectId: projectsStore.project.id,
    });

    sourceOptions.value = projectsStore.testingSources ? projectsStore.testingSources.items : [];
  } catch (error) {
    captureException(error, {
      message: 'Component: DCreateTest, Hook: onMounted',
    });
  }
});
</script>

<template>
  <div class="wrapp column">
    <div class="row header__row q-mb-md items-center">
      <Icon icon-name="bar_chart" icon-folder="testing" class="q-mr-sm" />
      <h5 class="q-ma-none">Create New Test</h5>
      <q-space />
      <q-btn icon="close" text-color="secondary" unelevated dense @click="emit('closeDialog')" />
    </div>
    <div class="section general__section row q-mb-md">
      <span class="section__title q-mb-lg">General</span>
      <q-form class="row col-12" @submit.prevent="newTest" @reset="onReset">
        <span class="q-mb-sm">Test Name*</span>
        <q-input
          ref="nameInput"
          v-model="name"
          outlined
          dense
          class="col-12"
          placeholder="Insert Name"
          :rules="[
            (val: string) => (val && val.length > 0) || 'Please type something',
            (val: string) =>
              (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
          ]"
          no-error-icon
          @update:model-value="debouncedValidation"
        />
        <span class="col-12 q-mb-sm">Description</span>
        <q-input
          ref="descriptionInput"
          v-model="description"
          outlined
          placeholder="Insert description"
          no-error-icon
          dense
          class="col-12"
          type="textarea"
          input-style="height: 90px"
          :rules="[
            (val: string) =>
              val.length <= MAX_DESCRIPTION_SIZE ||
              `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
          ]"
          @update:model-value="debouncedValidation"
        />
      </q-form>
    </div>
    <div class="section condition__section row q-mb-md">
      <span class="section__title q-mb-lg">Condition</span>
      <q-form class="row col-12 q-col-gutter-sm" @submit.prevent="newTest" @reset="onReset">
        <span class="q-mb-sm">Source*</span>
        <q-select
          v-model="source"
          outlined
          label="Please Select"
          :options="
            sourceOptions.map((item) => ({
              id: item.id,
              label: item.name,
              mode: item.mode,
            }))
          "
          dense
          class="col-12"
          :rules="[(val: string) => val || 'Please select something']"
          @update:model-value="getSourceAttributes"
        />
        <div class="row col-4">
          <span class="col-12 q-mb-sm">Attribute*</span>
          <q-select
            v-model="attribute"
            outlined
            label="Please Select"
            :options="
              attributeOptions.map((item) => ({
                id: item.id,
                label: item.name,
                type: item.type,
              }))
            "
            dense
            :disable="!source"
            class="col-12"
            :rules="[(val: string) => val || 'Please select something']"
            @update:model-value="updateAttribute"
          />
        </div>
        <div class="row col-5">
          <span class="col-12 q-mb-sm">Operator*</span>
          <q-select
            v-model="operator"
            outlined
            label="Please Select"
            :options="
              attribute && attribute.type === 'integer'
                ? operatorIntegerOptions.map((item) => ({
                    value: item.value,
                    label: item.name,
                    type: item.type,
                  }))
                : attribute && attribute.type === 'string'
                  ? operatorStringOptions.map((item) => ({
                      value: item.value,
                      label: item.name,
                      type: item.type,
                    }))
                  : operatorFloatOptions.map((item) => ({
                      value: item.value,
                      label: item.name,
                      type: item.type,
                    }))
            "
            :disable="!attribute"
            dense
            class="col-12"
            :rules="[(val: string) => val || 'Please select something']"
            @update:model-value="updateOperator"
          />
        </div>
        <div class="row col-3">
          <span class="col-12 q-mb-sm">Value*</span>
          <q-input
            v-if="operator && operator.type === 'string'"
            ref="valueInput"
            v-model="value"
            outlined
            dense
            type="text"
            :disable="!operator"
            placeholder="Insert Value"
            :rules="[
              (val: string) => (val && val.length > 0) || 'Please type something',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
          />
          <q-input
            v-else
            ref="valueInput"
            v-model="value"
            outlined
            dense
            type="number"
            :disable="!operator"
            placeholder="Insert Value"
            :rules="[
              (val: string) => (val && val.length > 0) || 'Please type something',
              (val: string) =>
                (val && val.length <= MAX_NAME_SIZE) ||
                `Maximum ${MAX_NAME_SIZE} characters allowed`,
            ]"
            no-error-icon
            @update:model-value="debouncedValidation"
            @keypress="preventInvalidInput"
          />
        </div>
      </q-form>
    </div>
    <div class="section schedule__section row q-mb-md">
      <span class="section__title q-mb-lg">Schedule</span>
      <div class="full-width q-mb-md">
        <q-btn-group unelevated spread>
          <q-btn
            flat
            unelevated
            disable
            label="Schedule Testing"
            :class="[!isManual ? 'active' : '']"
            @click="switchState(false)"
          />
          <q-btn
            flat
            unelevated
            disable
            label="Run it manually"
            :class="[isManual ? 'active' : '']"
            @click="switchState(true)"
          />
        </q-btn-group>
      </div>
      <div v-if="!isManual" class="row col-4">
        <span class="col-12 q-mb-sm">Frequency</span>
        <span class="text-bold">Daily</span>
      </div>
    </div>
    <div class="row action__row full-width justify-between q-mt-sm">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="emit('closeDialog')" />
      <q-btn
        class="btn-save"
        label="Create Test"
        unelevated
        :class="[disableSave ? 'disable' : '']"
        :loading="isLoading"
        :disable="disableSave || isLoading"
        @click="newTest"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

.wrapp {
  background: $secondary-50;
  span {
    @include caption(400, $secondary-500);
  }

  .text-bold {
    font-size: 16px;
    font-weight: 400;
    color: $secondary-600;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: $secondary-600;
  }

  .disabled {
    opacity: 0.5;
  }

  .section {
    padding: 20px;
    background: $white;
    border-radius: 5px;
    .section__title {
      color: $secondary-600;
      font-size: 14px;
      font-weight: 600;
    }
  }

  form {
    :deep(input::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field__label) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(textarea::placeholder) {
      @include paragraph-01(400, #d9d9d9);
    }
    :deep(.q-field .q-field__native span) {
      @include paragraph-01(400, #262626);
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn-cancel {
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
  }
  .btn-save {
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
