<script setup lang="ts">
import { debounce } from 'lodash';
import { useQuasar } from 'quasar';
import { onMounted, ref } from 'vue';

import type { UserInOrgList } from '@/client/api';
import type { QInput } from 'quasar';
import type { PropType } from 'vue';

import { MAX_NAME_SIZE } from '@/composables/utils';

import ATooltip from '@/components/atoms/ATooltip.vue';

const emit = defineEmits(['validate']);

const props = defineProps({
  editedUser: {
    type: Object as PropType<UserInOrgList>,
    default: null,
  },
});

const $q = useQuasar();
const firstName = ref('');
const lastName = ref('');
const jobTitle = ref('');
const nameInput = ref<QInput | null>(null);
const lastNameInput = ref<QInput | null>(null);
const orgAdmin = ref(false);

function onSubmit() {
  $q.notify({
    icon: 'cloud_done',
    message: 'Submitted',
  });
}

function onReset() {
  firstName.value = '';
  lastName.value = '';
}

const debouncedValidation = debounce(() => {
  if (!nameInput.value || !lastNameInput.value) return;

  const isNameValid = nameInput.value.validate();
  const isLastNameValid = lastNameInput.value.validate();

  const user = {
    firstname: firstName.value,
    lastname: lastName.value,
    email: props.editedUser.email,
    is_org_admin: orgAdmin.value,
    job_title: jobTitle.value,
  };

  if (isNameValid && isLastNameValid) {
    emit('validate', true, user);
  } else {
    emit('validate', false);
  }
}, 100);

onMounted(() => {
  if (!props.editedUser) return;

  firstName.value = props.editedUser.firstname || '';
  lastName.value = props.editedUser.lastname || '';
  orgAdmin.value = props.editedUser.is_org_admin || false;
  jobTitle.value = props.editedUser.job_title || '';

  debouncedValidation();
});
</script>

<template>
  <q-form class="q-col-gutter-md row" @submit="onSubmit" @reset="onReset">
    <div class="col-12 row">
      <div class="col-6 q-pr-sm">
        <div class="input-title">Name</div>
        <q-input
          ref="nameInput"
          v-model="firstName"
          outlined
          dense
          placeholder="Insert Name"
          :rules="[
            (val: string) => (val && val.length > 0) || 'Please type something',
            (val: string) =>
              (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
          ]"
          no-error-icon
          @update:model-value="debouncedValidation"
        />
      </div>
      <div class="col-6 q-pl-sm">
        <div class="input-title">Last Name</div>
        <q-input
          ref="lastNameInput"
          v-model="lastName"
          outlined
          dense
          placeholder="Insert Last Name"
          :rules="[
            (val: string) => (val && val.length > 0) || 'Please type something',
            (val: string) =>
              (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
          ]"
          no-error-icon
          @update:model-value="debouncedValidation"
        />
      </div>
    </div>
    <div class="col-12 row q-mb-md">
      <div class="col-6 q-pr-sm">
        <div class="input-title">Email</div>
        <span>{{ editedUser.email }}</span>
      </div>
      <div class="col-6 q-pl-sm">
        <div class="input-title">Job title (optional)</div>
        <q-input
          ref="jobInput"
          v-model="jobTitle"
          outlined
          dense
          placeholder="Insert job title"
          @update:model-value="debouncedValidation"
        />
      </div>
    </div>
    <div class="col-12 row role">
      <q-checkbox v-model="orgAdmin" size="16px" @update:model-value="debouncedValidation" />
      <span class="role__text q-mx-sm">Organization Admin</span>
      <ATooltip
        icon-folder="settings"
        icon-name="info-medium"
        tooltip-text="Organization Admin role"
      />
    </div>
  </q-form>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

form {
  .input-title {
    @include paragraph-01(400, $common-2);
    margin-bottom: 10px;
    span {
      margin-right: 5px;
    }
  }
  :deep(input::placeholder) {
    @include paragraph-01(400, $common-1);
  }
  :deep(.q-field__label) {
    @include paragraph-01(400, $common-1);
  }
  :deep(textarea::placeholder) {
    @include paragraph-01(400, $common-1);
  }
  :deep(.q-field .q-field__native span) {
    @include paragraph-01(400, $common-4);
  }
  .role {
    span {
      font-size: 14px;
      font-weight: 400;
      color: $secondary-600;
    }
    .q-checkbox {
      :deep(.q-checkbox__bg) {
        width: 100%;
        height: 100%;
        position: unset;
      }

      :deep(.q-checkbox__inner--falsy) {
        .q-checkbox__bg {
          color: $common-1;
        }
      }
      :deep(.q-checkbox__inner--truthy) {
        .q-checkbox__bg {
          color: $secondary-800;
        }
      }
      :deep(.q-checkbox__label) {
        @include paragraph-02(600, $common-5);
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
</style>
