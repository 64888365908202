import { ref } from 'vue';
import { defineStore } from 'pinia';
import initApi from '@/client_heplers/utils';

import { UsersApi } from '@/client/api';
import type {
  UsersApiGetUserApiUsersUserIdGetRequest,
  ResponseGetUserApiUsersUserIdGet,
} from '@/client/api';

import { handleApiError } from '@/composables/ApiError';
import type { AxiosError } from 'axios';

export const useUsersStore = defineStore('user', () => {
  const currentUser = ref<ResponseGetUserApiUsersUserIdGet | null>(null);

  const getUser = async (params: UsersApiGetUserApiUsersUserIdGetRequest) => {
    try {
      const usersApi = await initApi(UsersApi);

      const response = await usersApi?.getUserApiUsersUserIdGet(params);

      if (!response) return;
      currentUser.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getUser,
    currentUser,
  };
});
