<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useAssetsStore } from '@/stores/AssetsStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useRisksStore } from '@/stores/RisksStore';
import { useVendorStore } from '@/stores/VendorStore';

defineProps({
  activeName: {
    type: String,
    default: '',
  },
});

const route = useRoute();
const projectStore = useProjectsStore();
const risksStore = useRisksStore();
const assetsStore = useAssetsStore();
const vendorStore = useVendorStore();

interface RouteItem {
  to: {
    path: string;
    query?: {
      p: string;
      s: string;
    };
  };
  id?: string;
  text?: string | null;
  textActive?: string | null;
  notClickcable?: boolean;
}

let routeItems = ref<RouteItem[]>([
  {
    to: {
      path: '/projects',
    },
    text: route.name !== 'projects' ? 'Projects' : null,
    textActive: route.name === 'projects' ? 'Projects' : null,
  },
]);

function constructRoute() {
  routeItems.value = [
    {
      to: {
        path: '/projects',
      },
      text: route.name !== 'projects' ? 'Projects' : null,
      textActive: route.name === 'projects' ? 'Projects' : null,
    },
  ];
  const name = route.name;
  switch (name) {
    case 'new-project':
      routeItems.value.push({
        to: {
          path: `/projects`,
        },
        text: ' /  ',
        notClickcable: true,
        textActive: 'New Project',
      });
      break;
    case 'tokens':
      routeItems.value.push({
        to: {
          path: `/tokens`,
        },
        text: ' /  ',
        notClickcable: true,
        textActive: 'API Tokens',
      });
      break;
    case 'project-dashboard':
    case 'project-frameworks':
    case 'project-requirements':
    case 'project-controls':
    case 'project-settings':
    case 'project-risks':
    case 'project-evidence':
    case 'project-assets':
    case 'project-testing':
      if (!projectStore.project) return;
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}`,
        },
        text: ' / ',
        notClickcable: true,
        textActive: projectStore.project.name,
      });
      break;
    case 'risk-detail':
      if (!projectStore.project || !risksStore.currentRisk) return;
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}`,
        },
        text: ` / ${projectStore.project.name}`,
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/risks`,
        },
        text: ' / Risks /',
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/risk-detail/${risksStore.currentRisk?.id}`,
        },
        text: '',
        notClickcable: true,
        textActive: `${risksStore.currentRisk?.name}`,
      });
      break;
    case 'asset-detail':
      if (!projectStore.project || !assetsStore.currentAsset) return;
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}`,
        },
        text: ` / ${projectStore.project.name}`,
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/assets`,
        },
        text: ' / Assets /',
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/asset-detail/${assetsStore.currentAsset?.id}`,
        },
        text: '',
        notClickcable: true,
        textActive: `${assetsStore.currentAsset?.name}`,
      });
      break;
    case 'test-detail':
      if (!projectStore.project || !projectStore.currentTest) return;

      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}`,
        },
        text: ` / ${projectStore.project.name}`,
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/testing`,
        },
        text: ' / Testing /',
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/projects/project-detail/${projectStore.project?.id}/test-detail/${projectStore.currentTest?.id}`,
        },
        text: '',
        notClickcable: true,
        textActive: `${projectStore.currentTest?.name}`,
      });
      break;
    case 'organization':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/organization`,
        },
        text: '',
        notClickcable: true,
        textActive: 'Organization',
      });
      break;
    case 'organization-users':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/organization/users`,
        },
        text: 'Organization / ',
        notClickcable: true,
        textActive: 'User Management',
      });
      break;
    case 'organization-templates':
    case 'template-detail':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/organization/templates`,
        },
        text: 'Organization / ',
        notClickcable: true,
        textActive: 'Frameworks',
      });
      break;
    case 'organization-customization':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/organization/customization`,
        },
        text: 'Organization / ',
        notClickcable: true,
        textActive: 'Customization',
      });
      break;
    case 'organization-settings':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/organization/settings`,
        },
        text: 'Organization / ',
        notClickcable: true,
        textActive: 'Settings',
      });
      break;
    case 'vendors':
    case 'vendors-overview':
      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/`,
        },
        text: 'Home / ',
        notClickcable: false,
        textActive: '',
      });
      routeItems.value.push({
        to: {
          path: `/vendors`,
        },
        text: '',
        notClickcable: true,
        textActive: 'Vendors (beta)',
      });
      break;
    case 'vendor-detail':
      if (!vendorStore.currentVendor) return;

      routeItems.value = [];
      routeItems.value.push({
        to: {
          path: `/vendors`,
        },
        text: `Vendors / `,
        notClickcable: false,
      });
      routeItems.value.push({
        to: {
          path: `/vendors/vendor-detail/${route.params.id}`,
        },
        text: '',
        textActive: `${vendorStore.currentVendor.name}`,
        notClickcable: true,
      });
      break;
  }
}

watch(
  () => route.name,
  () => {
    constructRoute();
  },
  { immediate: true },
);

watch(
  () => projectStore.project,
  () => {
    constructRoute();
  },
  { immediate: true },
);

watch(
  () => risksStore.currentRisk,
  () => {
    constructRoute();
  },
  { immediate: true },
);

watch(
  () => assetsStore.currentAsset,
  () => {
    constructRoute();
  },
  { immediate: true },
);

watch(
  () => projectStore.currentTest,
  () => {
    constructRoute();
  },
  { immediate: true },
);

watch(
  () => vendorStore.currentVendor,
  () => {
    constructRoute();
  },
  { immediate: true },
);
</script>

<template>
  <div class="items__container row">
    <div v-for="item in routeItems" :key="item.to.path">
      <q-btn
        v-if="item.text"
        flat
        class="path"
        :to="!item.notClickcable ? item.to : null"
        :class="[item.notClickcable ? 'not-clickable' : '']"
        >{{ item.text }}</q-btn
      >

      <q-btn
        v-if="item.textActive"
        flat
        class="active-path"
        :to="!item.notClickcable ? item.to : null"
        :class="[item.notClickcable ? 'not-clickable' : '']"
      >
        {{ item.textActive }}</q-btn
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';

a,
button {
  @include paragraph-01(400, $black);
  text-transform: unset;
  padding: 0 !important;
  margin: 0 2px;
}
.not-clickable:hover {
  cursor: default;
  :deep(.q-focus-helper) {
    background: transparent !important;
  }
}
.q-btn--active {
  :deep(.q-riple) {
    background: transparent !important;
  }
}
.path {
  opacity: 0.5;
}
</style>
