<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  maxHeight: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: String,
    default: '',
  },
  minHeight: {
    type: String,
    default: '',
  },
  minWidth: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['hide']);

const dialog = ref(false);

watch(
  () => props.showDialog,
  (value) => {
    dialog.value = value;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <q-dialog v-model="dialog" no-refocus @hide="emit('hide')">
    <q-card
      :style="{
        'max-height': maxHeight,
        'max-width': maxWidth,
        'min-height': minHeight,
        'min-width': minWidth,
      }"
    >
      <q-card-section>
        <slot />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';
.q-card {
  min-width: 88vw;
  min-height: 92vh;
  max-width: 88vw !important;
  max-height: 92vh !important;
  background: $background;
  :deep(.q-card__section) {
    padding: 30px 24px;
  }
}
</style>
