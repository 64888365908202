import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Concept } from '@/client/api';
import { DataHandler } from '@/components/organisms/TipTap/extensions/dataHandler';
const dataHandler = new DataHandler();

export const TransformPasteExtension = Extension.create({
  name: 'transformPaste',

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey('transformPaste');

    return [
      new Plugin({
        key: pluginKey,
        state: {
          init() {
            return { transformPending: false };
          },
          apply(tr, value) {
            if (tr.getMeta('transformPaste')) {
              return { transformPending: false };
            }

            if (tr.docChanged) {
              return { transformPending: true };
            }

            return value;
          },
        },
        view() {
          return {
            update(view) {
              const state = view.state;
              const pluginState = pluginKey.getState(state);

              if (!pluginState.transformPending) {
                return;
              }

              const urlRegex =
                /https?:\/\/[^\s/$.?#].[^\s]*|(?<=\]\()https?:\/\/[^\s/$.?#].[^\s]*(?=\))/g;

              state.doc.descendants((node, pos) => {
                if (node.isTextblock) {
                  const text = node.textBetween(0, node.nodeSize - 2, ' ', ' ');
                  const urls = text.match(urlRegex);

                  if (!urls) return;

                  const patterns = [
                    {
                      type: Concept.Framework,
                      regex: /https?:\/\/[^/]+\/organization\/template-detail\/([\w-]+)/,
                    },
                    {
                      type: Concept.Control,
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/controls\?control=([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                    {
                      type: Concept.Requirement,
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/requirements\?requirement=([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                    {
                      type: 'evidence',
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/evidence\?evidence=([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                    {
                      type: Concept.Risk,
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/risk-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                    {
                      type: Concept.AssetCard,
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/asset-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                    {
                      type: Concept.TestingTest,
                      regex:
                        /https?:\/\/[^/]+\/projects\/project-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})\/test-detail\/([\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12})/,
                    },
                  ];

                  let tr = view.state.tr;

                  for (const { type, regex } of patterns) {
                    const match = text.match(regex);
                    if (match) {
                      let url: string;
                      let projectId: string;
                      let conceptId: string;

                      if (type === Concept.Framework) {
                        url = match[0];
                        conceptId = match[1];
                        projectId = '';
                      } else {
                        url = match[0];
                        projectId = match[1];
                        conceptId = match[2];
                      }
                      dataHandler.getData(type, conceptId, projectId).then((data) => {
                        if (!data) return;
                        const label =
                          type === Concept.Control
                            ? data.control_code
                            : type === Concept.Requirement
                              ? data.requirement_code
                              : type === Concept.Framework
                                ? data.code
                                : data.unique_code;

                        const start = text.indexOf(url) + pos + 1;
                        const end = start + url.length;

                        const resolvedStart = view.state.doc.resolve(start);
                        const resolvedEnd = view.state.doc.resolve(end);

                        tr = tr.deleteRange(resolvedStart.pos, resolvedEnd.pos);

                        const linkChipNode = view.state.schema.nodes.linkChip.create({
                          type,
                          projectId,
                          conceptId,
                          label,
                        });

                        tr = tr.insert(resolvedStart.pos, linkChipNode);
                        tr.setMeta('transformPaste', true);
                        view.dispatch(tr);
                      });

                      return;
                    }
                  }
                }
              });
            },
          };
        },
      }),
    ];
  },
});
