<script setup lang="ts">
import { ref, onMounted } from 'vue';

import type { PropType } from 'vue';
import type { FrameworkDisplay, FrameworkDetailed, FrameworkOverview } from '@/client/api';

const props = defineProps({
  framework: {
    type: Object as PropType<FrameworkDisplay | FrameworkDetailed | FrameworkOverview>,
    required: true,
  },
  tooltip: {
    type: Boolean,
    default: true,
  },
});

const frameworkImage = ref<string | null>(null);

onMounted(() => {
  if (props.framework.icon_type === 'image' && props.framework.icon_base64) {
    frameworkImage.value = `data:image/svg+xml;base64,${props.framework.icon_base64}`;
  }
});
</script>

<template>
  <div>
    <span v-if="framework.icon_type === 'flag'" class="flag fi" :class="`fi-${framework.icon}`" />
    <img
      v-if="framework.icon_type === 'image' && framework.icon_base64 && frameworkImage"
      :src="frameworkImage"
    />
    <q-tooltip v-if="tooltip" anchor="top middle" self="center middle" max-width="200px">
      {{ framework.name }}
    </q-tooltip>
  </div>
</template>

<style lang="scss" scoped>
img {
  min-width: 32px;
  min-height: 32px;
  max-width: 42px;
  max-height: 42px;
  width: auto;
  height: auto;
  display: block;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

:deep(img) {
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.flag {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  background-size: cover;
}
</style>
