<script setup lang="ts">
import { debounce } from 'lodash';
import { useQuasar } from 'quasar';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { AiProjectCreationAllowed, OrgProjectCreationAllowed } from '@/composables/Config';
import { MAX_DESCRIPTION_SIZE, MAX_NAME_SIZE } from '@/composables/utils';

import { useAuthStore } from '@/stores/AuthStore';

import type { QInput } from 'quasar';

import { ProjectType } from '@/client/api';

interface Select {
  value: string;
  label: string;
  disable: boolean;
}

const emit = defineEmits(['onInput']);

const $q = useQuasar();
const route = useRoute();
const authStore = useAuthStore();
const name = ref('');
const description = ref('');
const nameInput = ref<QInput | null>(null);
const descriptionInput = ref<QInput | null>(null);

const type = ref<Select | null>(null);

const typeOptions = ref<Select[]>([]);

function onSubmit() {
  $q.notify({
    icon: 'cloud_done',
    message: 'Submitted',
  });
}

function onReset() {
  name.value = '';
}

const isValidType = computed(() => type.value && type.value.value !== '');

const debouncedValidation = debounce(() => {
  if (!nameInput.value || !descriptionInput.value || !type.value) return;

  const isNameValid = nameInput.value.validate();
  const isDescriptionValid = descriptionInput.value.validate();

  if (isNameValid && isDescriptionValid && isValidType) {
    emit('onInput', 'name', name.value);
    emit('onInput', 'description', description.value);
    emit('onInput', 'type', type.value.value);
  } else {
    emit('onInput', 'name', '');
    emit('onInput', 'description', '');
    emit('onInput', 'type', '');
  }
}, 100);

watch(
  () => authStore.user,
  (newV) => {
    if (!newV) return;

    if (newV.is_org_admin) {
      typeOptions.value = [
        {
          label: 'AI Application',
          value: ProjectType.AiApplication,
          disable: !AiProjectCreationAllowed(),
        },
        {
          label: 'Organization',
          value: ProjectType.Organization,
          disable: !OrgProjectCreationAllowed(),
        },
      ];
    } else {
      typeOptions.value = [
        {
          label: 'AI Application',
          value: ProjectType.AiApplication,
          disable: !AiProjectCreationAllowed(),
        },
      ];
    }
  },
  {
    immediate: true,
  },
);

onMounted(() => {
  if (route.query.type) {
    type.value = {
      label: route.query.type === ProjectType.AiApplication ? 'AI Application' : 'Organization',
      value: route.query.type as string,
      disable: false,
    };
  }
});
</script>

<template>
  <q-form class="q-col-gutter-md row" @submit="onSubmit" @reset="onReset">
    <div class="col-12 row">
      <div class="col-6 q-mr-lg">
        <div class="input-title">Project Name</div>
        <q-input
          ref="nameInput"
          v-model="name"
          outlined
          dense
          placeholder="Project name"
          :rules="[
            (val: string) => (val && val.length > 0) || 'Please type something',
            (val: string) =>
              (val && val.length <= MAX_NAME_SIZE) || `Maximum ${MAX_NAME_SIZE} characters allowed`,
          ]"
          no-error-icon
          @update:model-value="debouncedValidation"
        />
      </div>
      <div class="col-3 row">
        <span class="select__title q-mb-sm col-12">Project Type</span>
        <q-select
          v-model="type"
          outlined
          :options="typeOptions"
          label="Select type"
          dense
          class="col-12"
          :error="false"
          @update:model-value="debouncedValidation"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="input-title">Description</div>
      <q-input
        ref="descriptionInput"
        v-model="description"
        outlined
        placeholder="Type the description"
        no-error-icon
        type="textarea"
        :rules="[
          (val: string) =>
            val.length <= MAX_DESCRIPTION_SIZE ||
            `Maximum ${MAX_DESCRIPTION_SIZE} characters allowed`,
        ]"
        @update:model-value="debouncedValidation"
      />
    </div>
  </q-form>
</template>

<style scoped lang="scss">
@import '@/assets/styles/style';

:deep(.q-field) {
  padding: 0;
}
:deep(.q-field__bottom) {
  display: none;
}

form {
  .input-title {
    @include paragraph-01(400, $common-2);
    margin-bottom: 10px;
    span {
      margin-right: 5px;
    }
  }
  :deep(input::placeholder) {
    @include paragraph-01(400, $common-1);
  }
  :deep(.q-field__label) {
    @include paragraph-01(400, $common-1);
  }
  :deep(textarea::placeholder) {
    @include paragraph-01(400, $common-1);
  }
  :deep(.q-field .q-field__native span) {
    @include paragraph-01(400, $common-4);
  }
}
</style>
