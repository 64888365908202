<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted, computed } from 'vue';

import {
  VendorRiskLevel,
  type UserInOrgList,
  type ResponseGetUserApiUsersUserIdGet,
} from '@/client/api';

import { useVendorStore } from '@/stores/VendorStore';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useOrganizationStore } from '@/stores/OrganizationStore';
import { useConfigStore } from '@/stores/ConfigStore';
import { useUsersStore } from '@/stores/UsersStore';

import { captureException } from '@/composables/Sentry';
import { successMessage, errorMessage } from '@/composables/Notify';
import { OrganizationCurrency } from '@/composables/Config';
import { BytesToMb, FileRejected, MAX_FILE_SIZE, formatDate } from '@/composables/utils';
import { ContentTooLargeError } from '@/composables/ApiError';

import { DEFAULT_PAGINATION } from '@/interfaces/models/Pagination';

import ADialog from '@/components/atoms/ADialog.vue';
import AEmptyData from '@/components/atoms/AEmptyData.vue';
import PageTitle from '@/components/atoms/PageTitle.vue';
import OTabPanels from '@/components/organisms/OTabPanels.vue';
import OTable from '@/components/organisms/Table/OTable.vue';
import DEditVendor from '@/components/dialogs/DEditVendor.vue';
import DConfirm from '@/components/dialogs/DConfirm.vue';

import countriesList from '@/utils/countries.json';

const route = useRoute();
const router = useRouter();

const vendorStore = useVendorStore();
const projectStore = useProjectsStore();
const organizationStore = useOrganizationStore();
const configStore = useConfigStore();
const usersStore = useUsersStore();
const activeTab = ref('asset');
const assignee = ref<UserInOrgList | ResponseGetUserApiUsersUserIdGet | null>(null);
const filter = ref<string | null>(null);
const document = ref(null);
const selectedDocument = ref<string | null>(null);

const vendorsIsLoading = ref(false);
const confirmationIsLoading = ref(false);
const isUploading = ref(false);
const documentsLoading = ref(false);
const actionInProgress = ref(false);
const showEditVendor = ref(false);
const showConfirmDialog = ref(false);
const showDocumentConfirmDialog = ref(false);

const columnsNames = {
  name: 'Name',
  size: 'Size',
  created_at: 'Created At',
  uploaded_by: 'Uploaded By',
  vendor_actions: '',
};

function changeTab(value: string) {
  activeTab.value = value;
}

async function getUser(id: string) {
  try {
    await usersStore.getUser({
      userId: id,
    });

    if (!organizationStore.usersData || !usersStore.currentUser) return;

    organizationStore.usersHashMap[id] = usersStore.currentUser;
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: getUser',
      data: {
        userId: id,
      },
    });
  }
}

async function setAssignee() {
  if (!vendorStore.currentVendor) return;
  const id = vendorStore.currentVendor.assigned_to;
  const user = organizationStore.usersHashMap[id];

  if (!user) {
    await getUser(id);
  }

  assignee.value = organizationStore.usersHashMap[id];
}

// async function updateCommentsAndLogs() {
//   if (!projectStore.project || !vendorStore.currentVendor) return;

//   try {
//     const params = {
//       objectId: vendorStore.currentVendor.id,
//       projectId: projectStore.project.id,
//       objectType: Concept.AssetCard,
//     };

//     await projectStore.getComments(params);

//     const logParams = {
//       objectId: vendorStore.currentVendor.id,
//       projectId: projectStore.project.id,
//     };

//     await projectStore.getProjectLogs(logParams);
//   } catch (error) {
//     captureException(error, {
//       message: 'Component: AssetDetailPage, Function: updateCommentsAndLogs',
//     });
//   }
// }

async function filterBySearch(value: string) {
  filter.value = value;
  updateDocumentList();
}

function closeDialog() {
  showEditVendor.value = false;
}

function closeConfirm() {
  showConfirmDialog.value = false;
  showDocumentConfirmDialog.value = false;
}

async function updateVendor() {
  try {
    vendorsIsLoading.value = true;
    await vendorStore.getVendor({
      vendorId: route.params.vendorId as string,
    });

    setAssignee();
    successMessage('Vendor updated successfully');
  } catch (error) {
    errorMessage('Failed to update vendor');
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: updateVendor',
    });
  } finally {
    vendorsIsLoading.value = false;
  }
}

async function deleteVendor() {
  try {
    confirmationIsLoading.value = true;
    await vendorStore.deleteVendor({
      vendorId: route.params.vendorId as string,
    });

    successMessage('Vendor deleted successfully');
    router.push('/vendors');
  } catch (error) {
    errorMessage('Failed to delete vendor');
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: deleteVendor',
    });
  } finally {
    confirmationIsLoading.value = false;
  }
}

async function updateDocumentList() {
  try {
    documentsLoading.value = true;

    await vendorStore.getVendorDocumentsList({
      vendorId: route.params.vendorId as string,
      name: filter.value || null,
      ...DEFAULT_PAGINATION,
    });
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: updateDocumentList',
    });
  } finally {
    documentsLoading.value = false;
  }
}

async function uploadDocument(file: File) {
  isUploading.value = true;

  const params = {
    vendorId: route.params.vendorId as string,
    file: file,
    fileName: file.name,
  };

  try {
    await vendorStore.uploadVendorDocument(params);

    successMessage('Document successfully uploaded');
    document.value = null;

    updateDocumentList();
  } catch (error) {
    if (error instanceof ContentTooLargeError) {
      errorMessage('File is too large.');
    }
    captureException(error, { message: 'Component: OrganizationPage, Function: uploadDocument' });
  } finally {
    isUploading.value = false;
  }
}

async function downloadDocument(documentId: string, fileName: string) {
  if (!vendorStore.currentVendor) return;

  const params = {
    vendorId: vendorStore.currentVendor.id,
    documentId: documentId,
  };

  try {
    actionInProgress.value = true;

    await vendorStore.downloadVendorDocument(params, fileName);
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: downloadDocument',
      data: {
        params,
      },
    });
  } finally {
    actionInProgress.value = false;
  }
}

function selectDocument(documentId: string) {
  selectedDocument.value = documentId;
  showDocumentConfirmDialog.value = true;
}

async function deleteDocument() {
  if (!vendorStore.currentVendor || !selectedDocument.value) return;

  const params = {
    vendorId: vendorStore.currentVendor.id,
    documentId: selectedDocument.value,
  };

  try {
    actionInProgress.value = true;
    await vendorStore.deleteVendorDocument(params);
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorDetailPage, Function: deleteDocument',
      data: {
        params,
      },
    });
  } finally {
    actionInProgress.value = false;
    showDocumentConfirmDialog.value = false;
    updateDocumentList();
  }
}

const vendorAddress = computed(() => {
  if (!vendorStore.currentVendor || !vendorStore.currentVendor.address) return '-';

  const address = vendorStore.currentVendor.address;

  if (
    !address.street_name &&
    !address.city &&
    !address.state &&
    !address.country &&
    !address.zip_code
  )
    return '-';

  const filterCountry = countriesList.find((country) => country.code === address.country);

  return `${address?.street_name ? address.street_name + ', ' : ''} ${
    address?.city ? address.city + ', ' : ''
  } ${address?.state ? address.state + ', ' : ''} ${filterCountry ? filterCountry.name : ''} ${
    address?.zip_code ? ', ' + address.zip_code : ''
  }`;
});

onMounted(async () => {
  try {
    vendorsIsLoading.value = true;
    await organizationStore.getOrganizationUsers({
      ...DEFAULT_PAGINATION,
      size: 50,
    });
    await vendorStore.getVendor({
      vendorId: route.params.vendorId as string,
    });

    await vendorStore.getVendorDocumentsList({
      vendorId: route.params.vendorId as string,
      ...DEFAULT_PAGINATION,
    });

    setAssignee();
  } catch (error) {
    captureException(error, {
      message: 'Component: VendorDetailPage, Hook: onMounted',
    });
  } finally {
    vendorsIsLoading.value = false;
  }
});
</script>
<template>
  <div class="row q-mb-sm q-mt-md items-center">
    <PageTitle
      :title="vendorStore.currentVendor ? vendorStore.currentVendor.name : ''"
      :class="vendorsIsLoading ? 'skeleton-v2' : ''"
    />
  </div>
  <div
    class="col-12 row vendor__info q-mb-lg q-mt-sm"
    :class="vendorsIsLoading ? 'skeleton-v2' : ''"
  >
    <div class="row col-8">
      <div class="row col-3 vendor-item">
        <span class="item-title col-12 q-mb-xs">Type</span>
        <span class="item-value">{{
          vendorStore.currentVendor && vendorStore.currentVendor.type
            ? vendorStore.currentVendor.type.charAt(0).toUpperCase() +
              vendorStore.currentVendor.type.slice(1)
            : '-'
        }}</span>
      </div>
      <div class="row col-3 vendor-item">
        <span class="item-title col-12 q-mb-xs">Status</span>
        <span class="item-value">{{
          vendorStore.currentVendor
            ? vendorStore.currentVendor.status.replaceAll('_', ' ').charAt(0).toUpperCase() +
              vendorStore.currentVendor.status.replaceAll('_', ' ').slice(1)
            : '-'
        }}</span>
      </div>
      <div class="row col-3 vendor-item">
        <span class="item-title col-12 q-mb-xs">Risk Level</span>
        <q-badge
          v-if="vendorStore.currentVendor && vendorStore.currentVendor.risk_level"
          :class="
            vendorStore.currentVendor.risk_level === VendorRiskLevel.VeryHigh
              ? 'very-high-badge'
              : vendorStore.currentVendor.risk_level === VendorRiskLevel.High
                ? 'high-badge'
                : vendorStore.currentVendor.risk_level === VendorRiskLevel.Medium
                  ? 'medium-badge'
                  : vendorStore.currentVendor.risk_level === VendorRiskLevel.VeryLow
                    ? 'very-low-badge'
                    : 'low-badge'
          "
          :label="vendorStore.currentVendor.risk_level?.replaceAll('_', ' ')"
        />
        <span v-else class="item-value">{{ '-' }}</span>
      </div>
      <div class="row col-3 vendor-item">
        <span class="item-title col-12 q-mb-xs">Next Review Date</span>
        <span class="item-value">{{
          vendorStore.currentVendor && vendorStore.currentVendor.review_date
            ? formatDate(vendorStore.currentVendor.review_date, false) || ''
            : '-'
        }}</span>
      </div>
    </div>
    <div class="row col-4 actions__container justify-end">
      <q-btn
        flat
        unelevated
        label="Delete"
        icon="delete"
        class="btn-delete q-mr-md"
        @click="showConfirmDialog = true"
      />
      <q-btn
        flat
        unelevated
        label="Edit"
        icon="edit"
        class="btn-edit"
        @click="showEditVendor = true"
      />
    </div>
  </div>
  <div class="vendors__wrapp row justify-between">
    <OTabPanels
      :active-tab="activeTab"
      default-tab="overview"
      :default-size="true"
      class="full-width"
      @change-tab="changeTab"
    >
      <template #tabs>
        <q-tab
          content-class="my-tab"
          name="overview"
          label="Overview"
          :disable="vendorsIsLoading"
        />
        <q-tab
          content-class="my-tab"
          name="documents"
          label="Documents"
          :disable="vendorsIsLoading"
        />
        <q-tab content-class="my-tab" name="comments_logs" label="Comments and Logs" disable>
          <span
            v-if="
              (projectStore.projectLogs.length > 0 || projectStore.comments.length > 0) &&
              !vendorsIsLoading
            "
            class="total-badge q-ml-sm"
          >
            {{
              projectStore.projectLogs.length + projectStore.comments.length < 10
                ? '0' + (projectStore.projectLogs.length + projectStore.comments.length)
                : projectStore.projectLogs.length + projectStore.comments.length
            }}</span
          >
        </q-tab>
      </template>
      <template #panels>
        <q-tab-panel name="overview" class="q-px-none">
          <div class="content-wrapp row col-12 justify-between">
            <div class="row vendor-left" :class="vendorsIsLoading ? 'skeleton-v2' : ''">
              <div class="row title col-12 q-mb-lg">Vendor Details</div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Name</div>
                <div class="col-8 item-value text-break">
                  {{ vendorStore.currentVendor ? vendorStore.currentVendor.name : '-' }}
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Description</div>
                <div class="col-8 item-value text-break">
                  {{
                    vendorStore.currentVendor && vendorStore.currentVendor.description
                      ? vendorStore.currentVendor.description
                      : '-'
                  }}
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Website</div>
                <div class="col-8 item-value text-break">
                  <a
                    v-if="vendorStore.currentVendor && vendorStore.currentVendor.website_url"
                    :href="vendorStore.currentVendor.website_url"
                    target="_blank"
                  >
                    {{ vendorStore.currentVendor.website_url }}
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Privacy Policy URL</div>
                <div class="col-8 item-value text-break">
                  <a
                    v-if="vendorStore.currentVendor && vendorStore.currentVendor.privacy_policy_url"
                    :href="vendorStore.currentVendor.privacy_policy_url"
                    target="_blank"
                  >
                    {{ vendorStore.currentVendor.privacy_policy_url }}
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Terms of Service URL</div>
                <div class="col-8 item-value text-break">
                  <a
                    v-if="
                      vendorStore.currentVendor && vendorStore.currentVendor.terms_of_service_url
                    "
                    :href="vendorStore.currentVendor.terms_of_service_url"
                    target="_blank"
                  >
                    {{ vendorStore.currentVendor.terms_of_service_url }}
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="row col-12 content-item">
                <div class="col-4 item-key">Address</div>
                <div class="col-8 item-value text-break">
                  {{ vendorAddress }}
                </div>
              </div>
            </div>
            <div class="row vendor-right" :class="vendorsIsLoading ? 'skeleton-v2' : ''">
              <div class="row title col-12 q-mb-lg">Assessment</div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Status</div>
                <div class="col-8 item-value text-break">
                  {{
                    vendorStore.currentVendor
                      ? vendorStore.currentVendor.status
                          .replaceAll('_', ' ')
                          .charAt(0)
                          .toUpperCase() +
                        vendorStore.currentVendor.status.replaceAll('_', ' ').slice(1)
                      : '-'
                  }}
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Risk Level</div>
                <div class="col-8 item-value text-break">
                  <q-badge
                    v-if="vendorStore.currentVendor && vendorStore.currentVendor.risk_level"
                    :class="
                      vendorStore.currentVendor.risk_level === VendorRiskLevel.VeryHigh
                        ? 'very-high-badge'
                        : vendorStore.currentVendor.risk_level === VendorRiskLevel.High
                          ? 'high-badge'
                          : vendorStore.currentVendor.risk_level === VendorRiskLevel.Medium
                            ? 'medium-badge'
                            : vendorStore.currentVendor.risk_level === VendorRiskLevel.VeryLow
                              ? 'very-low-badge'
                              : 'low-badge'
                    "
                    :label="vendorStore.currentVendor.risk_level?.replaceAll('_', ' ')"
                  />
                  <span v-else class="item-value text-break">{{ '-' }}</span>
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Review Date</div>
                <div class="col-8 item-value text-break">
                  {{
                    vendorStore.currentVendor && vendorStore.currentVendor.review_date
                      ? formatDate(vendorStore.currentVendor.review_date, false)
                      : '-'
                  }}
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Responsible Person</div>
                <div class="col-8 item-value text-break">
                  {{ assignee ? assignee.firstname + ' ' + assignee.lastname : '-' }}
                </div>
              </div>
              <div class="row col-12 content-item q-mb-md">
                <div class="col-4 item-key">Annual Contract Value</div>
                <div class="col-8 item-value text-break">
                  {{
                    vendorStore.currentVendor && vendorStore.currentVendor.annual_contract_value
                      ? OrganizationCurrency(
                          configStore.configData ? configStore.configData.general.currency : '',
                        ) +
                        ' ' +
                        vendorStore.currentVendor.annual_contract_value
                      : '-'
                  }}
                </div>
              </div>
              <div class="row col-12 content-item">
                <div class="col-4 item-key">Subprocessor</div>
                <div class="col-8 item-value text-break">
                  {{
                    vendorStore.currentVendor
                      ? vendorStore.currentVendor.is_subprocessor
                        ? 'Yes'
                        : 'No'
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </q-tab-panel>
        <q-tab-panel name="documents" class="q-px-none">
          <OTable
            :data="vendorStore.vendorDocumentsData?.items"
            :pagination="vendorStore.vendorDocumentsData!"
            :columns-names="columnsNames"
            :is-loading="vendorsIsLoading || documentsLoading"
            :skeleton-size="5"
            :row-clickable="false"
            :action-in-progress="actionInProgress"
            search-placeholder="Search for a Document"
            store="vendorsStore"
            action="getVendorDocuments"
            @filter-by-search="filterBySearch"
            @download-vendor-document-file="downloadDocument"
            @delete-vendor-document-file="selectDocument"
          >
            <template #header-button>
              <div class="upload__container row justify-end">
                <q-file
                  v-model="document"
                  class="file-uploader q-mb-sm"
                  outlined
                  dense
                  label=""
                  label-slot
                  :loading="isUploading"
                  :disable="isUploading"
                  :max-file-size="MAX_FILE_SIZE"
                  @rejected="FileRejected"
                  @update:model-value="uploadDocument"
                >
                  <template #prepend>
                    <q-icon name="upload" />
                  </template>
                  <slot name="label">
                    <div class="file-label">
                      <span>New Document</span>
                    </div>
                  </slot>
                </q-file>
                <div class="details__container row col-12 justify-end">
                  <span>{{
                    `Maximum file size ${BytesToMb(MAX_FILE_SIZE)}MB. Accepted formats pdf, doc, txt and html.`
                  }}</span>
                </div>
              </div>
            </template>
          </OTable>
          <div
            v-if="
              vendorStore.vendorDocumentsData &&
              vendorStore.vendorDocumentsData.items.length === 0 &&
              !vendorsIsLoading &&
              !documentsLoading
            "
            class="empty__wrapp row items-center q-mt-md"
          >
            <AEmptyData
              v-if="!filter"
              icon-name="folder"
              :header="`You don’t have any Document attached to this Vendor.`"
              text="Start by uploading new Document."
              class="full-width items-center"
            />
            <AEmptyData
              v-else
              icon-name="search"
              :header="`No results for your search.`"
              text="Try changing your search."
              class="full-width items-center"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel name="comments_logs">
          <!-- <OComments
              v-if="vendorStore.currentVendor && authStore.user"
              :object-type="'asset_card'"
              :reviewer="
                reviewers &&
                reviewers.length > 0 &&
                reviewers.map((user: User) => user.id).includes(authStore.user.id) &&
                vendorStore.currentVendor.status === Status.IN_REVIEW
              "
              :object-id="vendorStore.currentVendor.id"
              :editable="!vendorStore.currentVendor.is_locked"
              class="coomments__wrapp"
              @update-concept="updateConcept"
            /> -->
        </q-tab-panel>
      </template>
    </OTabPanels>
  </div>
  <ADialog
    :show-dialog="showEditVendor"
    max-width="650px !important"
    min-width="650px !important"
    min-height="auto"
    class="dialog-create-vendor"
    @hide="closeDialog"
  >
    <DEditVendor @close-dialog="closeDialog" @update-vendor="updateVendor" />
  </ADialog>
  <ADialog
    v-if="vendorStore.currentVendor"
    :show-dialog="showConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showConfirmDialog = false"
  >
    <DConfirm
      :title="`Would you like to permanently delete the Vendor '${vendorStore.currentVendor.name}' ?`"
      description="This action can’t be undone."
      button-text="Confirm"
      :loading="confirmationIsLoading"
      @close-dialog="closeConfirm"
      @confirm="deleteVendor"
    />
  </ADialog>
  <ADialog
    v-if="vendorStore.currentVendor"
    :show-dialog="showDocumentConfirmDialog"
    max-height="auto !important"
    max-width="660px !important"
    min-height="auto !important"
    min-width="660px !important"
    @hide="showDocumentConfirmDialog = false"
  >
    <DConfirm
      :title="`Would you like to permanently delete this Document ?`"
      description="This action can’t be undone."
      button-text="Confirm"
      :loading="actionInProgress"
      @close-dialog="closeConfirm"
      @confirm="deleteDocument"
    />
  </ADialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
span {
  @include heading-04(600, $secondary-600);
}

.vendor__info {
  background: $white;
  border-radius: 5px;
  padding: 20px;
  .vendor-item {
    padding-left: 12px;
    border-left: 2px solid var(--Secondary-100, #e1e7ea);
    .item-title {
      @include caption(400, $secondary-500);
    }
    .item-value {
      @include paragraph-01(600, $secondary-600);
    }
  }
  .actions__container {
    button {
      height: 40px;
      border-radius: 5px;
    }
    .btn-delete {
      background: transparent;
      text-transform: none;
      color: #f96f7f;
      border: 1px solid #f96f7f;
    }
    .btn-edit {
      background: transparent;
      text-transform: none;
      color: $secondary-500;
      border: 1px solid $secondary-500;
    }
  }
}

:deep(.q-tabs) {
  margin: 0;

  .total-badge {
    border-radius: 10px;
    padding: 2px 8px;
    background: #509af4;
    @include caption(400, $white);
  }
  .q-tab {
    padding: 0;
    .q-tab__content {
      padding: 4px 10px 15px;
      .q-tab__indicator {
        height: 4px;
      }
      .full-width {
        span {
          @include heading-03(600, $secondary-600);
        }
      }
    }
  }
  :deep(.q-panel) {
    overflow-y: hidden;
  }
  .q-tabs__arrow--left,
  .q-tabs__arrow--right {
    display: none !important;
  }
}

.content-wrapp {
  .vendor-left,
  .vendor-right {
    width: 49%;
    background: $white;
    border-radius: 5px;
    padding: 20px;
    .title {
      @include heading-04(600, $secondary-600);
    }
    .content-item {
      .item-key {
        font-size: 14px;
        font-weight: 700;
        color: $secondary-500;
      }
      .item-value {
        @include paragraph-01(400, $secondary-600);

        a {
          @include paragraph-01(600, $secondary-600);
        }
      }
    }
  }
}

.upload__container {
  .file-uploader {
    max-width: 200px;
    cursor: pointer;
    :deep(.q-field__control-container) {
      padding-top: 10px !important;
      flex-direction: column;
      align-items: center;
    }

    :deep(.q-field__inner) {
      background: $secondary-500;
      border-radius: 5px;
    }

    :deep(.q-field__control::before) {
      border: none !important;
    }

    :deep(.q-field__control::after) {
      border: none !important;
    }

    :deep(.q-field__native) {
      display: none;
    }

    i {
      color: $white;
    }

    .file-label {
      span {
        @include paragraph-01(400, $white);
      }
    }
  }

  .details__container {
    span {
      @include caption(400, $secondary-400);
    }
  }
}
</style>
