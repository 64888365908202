<script setup lang="ts"></script>

<template>
  <div class="plan__container relative-position row full-width">
    <div class="plan__header row justify-center col-12">
      <q-badge class="strategy-badge justify-center" label="Implementation" />
    </div>
    <slot name="content-slot" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/style';
.plan__container {
  height: 166px;
  padding: 18px 16px 20px;
  border-radius: 5px;
  border: 1px solid $secondary-400;
  background: $white;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.15) inset;
  cursor: pointer;
}
.default__container {
  box-shadow: unset;
}
</style>
